import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import axios from 'axios';
import AlertMessage from '../alert/alert';
import { Helmet } from "react-helmet";
import Autocomplete from '@mui/material/Autocomplete';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { ContainerLoader } from 'components/common/ContainerLoader';

class EditVbdAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                user: '',
                user_select: '',
                vbdaccount: '',
                vbdaccount_select: ''
            },
            users: [],
            vbdaccounts: [],
            loader: true,
            showform: false,
            message: '',
            formSubmit: false,
            error: [],
            openalert: false,
            alertseverity: '',
            lowerEmailCheck: false,
            lowerEmailMsg: '',
        }
        this.inputRef = React.createRef();
    }

    async componentDidMount() {
        this.getDetailAttachVbdUser();
        this.getAllUsers();
    }

    getDetailAttachVbdUser = async () => {
        const data = {
            id: this.props.match.params.id
        }
        const account_api_url = process.env.REACT_APP_API_URL + "/api/admin/detailAttachVbdUser";
        await axios.post(account_api_url, data, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    message: 'Something went wrong while accessing users list',
                    alertseverity: 'error',
                    openalert: true
                });
            } else {
                const formData = {
                    id: data.data._id,
                    user: data.data.user._id,
                    user_select: data.data.user,
                    vbdaccount: data.data.vbdaccount._id,
                    vbdaccount_select: data.data.vbdaccount,
                };
                this.setState({
                    loader: false,
                    formData
                });
            }
        }).catch((error) => {
            this.setState({
                loader: false,
                message: 'Something went wrong while accessing users list',
                alertseverity: 'error',
                openalert: true
            });
        })
    }

    getAllUsers = async () => {
        const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
        await axios.post(userList_api_url, '', {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    message: 'Something went wrong while accessing users list',
                    alertseverity: 'error',
                    openalert: true
                });
            }
            else {
                const userList_api_url = process.env.REACT_APP_API_URL + "/api/admin/listVbdAccount";
                axios.get(userList_api_url, {
                    headers: {
                        authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
                    }
                }).then((response1) => {
                    const data1 = response1.data;
                    if (data1.error) {
                        this.setState({
                            loader: false,
                            message: 'Something went wrong while accessing vbd account list',
                            alertseverity: 'error',
                            openalert: true
                        });
                    } else {
                        this.setState({
                            loader: false,
                            users: data.data,
                            vbdaccounts: data1.data,
                            showform: true
                        });
                    }
                }).catch((error) => {
                    this.setState({
                        loader: false,
                        message: 'Something went wrong while accessing vbd account list',
                        alertseverity: 'error',
                        openalert: true
                    });
                })

                this.setState({
                    loader: false,
                    users: data.data,
                    showform: true
                });
            }
        }).catch((error) => {
            this.setState({
                loader: false,
                message: 'Something went wrong while accessing users list',
                alertseverity: 'error',
                openalert: true
            });
        })
    }

    handleChange = (e) => {
        const { formData } = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    }

    handleUserList = (e, newValue) => {
        const { formData } = this.state;
        if (newValue) {
            formData['user'] = newValue._id;
            formData['user_select'] = newValue;
        } else {
            formData['user'] = '';
            formData['user_select'] = newValue;
        }
        this.setState({ formData });
    }

    handleVBDList = (e, newValue) => {
        const { formData } = this.state;
        if (newValue) {
            formData['vbdaccount'] = newValue._id;
            formData['vbdaccount_select'] = newValue;
        } else {
            formData['vbdaccount'] = newValue;
            formData['vbdaccount_select'] = '';
        }
        this.setState({ formData });
    }

    handleSubmit = async (e) => {
        this.setState({ formSubmit: true });
        const addUser_api_url = process.env.REACT_APP_API_URL + "/api/admin/editAttachVbdUser"
        axios.post(addUser_api_url, this.state.formData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    formSubmit: false,
                    message: data.message,
                    alertseverity: 'error',
                    openalert: true
                })
            } else {
                this.setState({
                    formSubmit: false,
                    message: data.message,
                    alertseverity: 'success',
                    openalert: true
                });
                this.props.history.push("/vbdaccounts/lists");
            }
        }).catch((error) => {
            this.setState({
                formSubmit: false,
                message: 'Something went wrong while submitting form',
                alertseverity: 'error',
                openalert: true
            });
        })
    }

    handleClose = (event, reason) => {
        this.setState({
            openalert: false
        })
    };

    render() {
        const { formData, loader, showform } = this.state;
        return (
            <>
                <Helmet>
                    <title>Edit VBD Account to User</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ padding: "30px" }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ justifyContent: "space-between" }}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                width: "100%",
                                                maxWidth: "365px",
                                                color: "#000059!important",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.6rem",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            {" "}
                                            Edit VBD Account to User
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                justifyContent: {
                                                    xs: "flex-start",
                                                    sm: "flex-start",
                                                    md: "flex-end",
                                                    lg: "flex-end",
                                                    xl: "flex-end",
                                                },
                                            }}
                                        >
                                            <Grid item sx={{paddingLeft:"8px!important"}} >
                                                <Button
                                                    onClick={() => this.props.history.push(`/vbdaccounts/lists`)}
                                                    size="large"
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#002986!important",
                                                        color: "white!important",
                                                        textTransform: "none!important",
                                                        fontWeight: "normal",
                                                        padding: "7px 22px!important",
                                                        fontFamily: "Barlow!important",
                                                        marginLeft: "9px !important"
                                                    }}
                                                    startIcon={<ArrowBack />}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                {!showform || loader ? (
                                    <ContainerLoader />
                                ) : (
                                    <ValidatorForm onSubmit={this.handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <div className="form-group select-outer">
                                                    <label>User</label>
                                                    <Autocomplete
                                                        name="user_select"
                                                        options={this.state.users}
                                                        getOptionLabel={(option) => option.email}
                                                        getOptionSelected={(option) => {
                                                            return (option._id === formData.user_select._id)
                                                        }}
                                                        defaultValue={formData.user_select}
                                                        selected={formData.user_select}
                                                        onChange={this.handleUserList}
                                                        renderInput={(params) => (
                                                            <TextValidator
                                                                {...params}
                                                                variant="outlined"
                                                                name="user"
                                                                placeholder="Select User"
                                                                value={formData.user_select.email}
                                                                validators={['required']}
                                                                errorMessages={('This field is required')}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="form-group select-outer">
                                                    <label>VBD Account</label>
                                                    <Autocomplete
                                                        name="vbdaccount"
                                                        options={this.state.vbdaccounts}
                                                        getOptionLabel={(option) => option.username}
                                                        getOptionSelected={(option) => {
                                                            return (option._id === formData.vbdaccount_select._id)
                                                        }}
                                                        defaultValue={formData.vbdaccount_select}
                                                        selected={formData.vbdaccount_select}
                                                        onChange={this.handleVBDList}
                                                        renderInput={(params) => (
                                                            <TextValidator
                                                                {...params}
                                                                variant="outlined"
                                                                name="vbdaccount"
                                                                placeholder="Select VBD Account"
                                                                value={formData.vbdaccount_select.username}
                                                                validators={['required']}
                                                                errorMessages={('This field is required')}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item sx={12}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="theme-btn-submit"
                                                    size="large">
                                                    Update Account
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* alert message  */}
                <AlertMessage
                    open={this.state.openalert}
                    message={this.state.message}
                    severity={this.state.alertseverity}
                    closeCall={this.handleClose}
                />
            </>
        )
    }
}
export default EditVbdAccounts;