import { ActionTypes } from "../types/ActionTypes"; 

const SetPageLimt = (data) => (dispatch)=>{
    dispatch({
        type : ActionTypes.PAGELIMIT,
        payload  : data

    })
}

export default SetPageLimt