
const initialState={
    username:''
}

const UserReducer = (state=initialState ,  { type, ...rest })=>{
    switch(type){
        case 'setusername':
            return {...state, ...rest }
        default:
            return state
    }
} 

export default UserReducer;