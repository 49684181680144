// reducers/timeReducer.js

const initialState = {
   scanDate: null,
   scaningTime: 0,

};

const TimeDurationReducer = (state = initialState, action) => {  
  switch (action.type) {
    case "STORE_TOTALTIME":
      return {
        ...state,
        [action.payload.name]: action.payload.time,
      };
    default:
      return state;
  }
};

export default TimeDurationReducer;