import React from 'react';
import { Route, Redirect } from 'react-router-dom';


const CommuinicationRoute = ({ component: Component, layout: Layout, ...rest}) => {
  return (
    <Route 
      {...rest} 
      render={(props) => {
      
        if (localStorage.getItem('accessTokenCommunication')) {
         
          return <Layout><Component {...props} /></Layout>;
        } 
        return <Redirect to='/communication-login'/>;
      }} 
    />
  );
};

export default CommuinicationRoute;