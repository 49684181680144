import React, { Component } from "react";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import "./instructions.scss";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CustomDataTable from "utils/customDataTable";
import CustomPagination from "utils/customPagination";
import { StatusChip } from "utils/statusChip";
import ConfirmationModal from "utils/confirmationModal";

export default class InstructionPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      section: "",
      open: false,
      message: "",
      successError: "",
      video: "",
      page: "",
      sectionList: [],
      openadd: false,
      pagename: "",
      pagestatus: true,
      openalert: false,
      alertseverity: "",
      allPages: [],
      openedit: false,
      editpagename: "",
      editpagestatus: true,
      editpageid: "",
      opendelete: false,
      loader: true,
      deleteid: "",
      totalRecords: 0,
      pagination: {
        searchPage: 0,
        skip: 0,
        pageLimit: 10,
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleToggleAdd = this.handleToggleAdd.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleAddPage = this.handleAddPage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getPages = this.getPages.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleToggleEdit = this.handleToggleEdit.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleDeletePage = this.handleDeletePage.bind(this);
    this.handleToggleDelete = this.handleToggleDelete.bind(this);
  }

  handleClose = () => {
    this.setState({
      openalert: false,
    });
  };

  handleOpenEdit = (item) => {
    this.setState({
      openedit: true,
      editpagename: item.name,
      editpagestatus: item.is_active ? true : false,
      editpageid: item._id,
    });
  };

  handleUpdatePage = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.editpagename,
      is_active: this.state.editpagestatus ? 1 : 0,
      _id: this.state.editpageid,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/updatePage", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            editpagename: "",
            editpagestatus: true,
            openedit: false,
          });
          this.getPages(this.state.pagination.skip, this.state.pagination.pageLimit);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleAddPage = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.pagename,
      is_active: this.state.pagestatus ? 1 : 0,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/addPage", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            pagename: "",
            pagestatus: true,
            openadd: false,
          });
          this.getPages(this.state.pagination.skip, this.state.pagination.pageLimit);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getPages(this.state.pagination.skip, this.state.pagination.pageLimit);
  }

  handleToggleDelete = (item) => {
    console.log(item, 'fjk');
    this.setState({ opendelete: !this.state.opendelete });
    if (item) {
      this.setState({ deleteid: item._id });
    } else {
      this.setState({ deleteid: "" });
    }
  };

  handleDeletePage = () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/admin/deletePage",
        { _id: this.state.deleteid },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            opendelete: false,
            deleteid: "",
          });
          this.getPages(this.state.pagination.skip, this.state.pagination.pageLimit);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPages = (skip = 0, limit = 10) => {
    this.setState({ loader: true });
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/listPages", {
        skip: skip ?? 0,
        limit: limit ?? 10
      }, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        this.setState({ loader: false });
        this.setState({
          allPages: res.data.data ?? [],
          totalRecords: res.data.count ?? 0
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
      });
  };

  handleToggleEdit = () => {
    this.setState({
      openedit: !this.state.openedit,
      editpagename: "",
      editpagestatus: true,
      editpageid: "",
    });
  };

  handleToggleAdd = () => {
    this.setState({
      openadd: !this.state.openadd,
      pagename: "",
      pagestatus: true,
    });
  };

  handleChangeStatus = (event) => {
    console.log(event.target.checked);
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSelectPage = (event) => {
    this.setState({ page: event.target.value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      content: this.state.content,
      video: this.state.video,
    };
  };

  changePageLimit = (e) => {
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        searchPage: 1,
        pageLimit: parseInt(e.target.value)
      }
    }), () => {
      this.getPages(0, parseInt(e.target.value))
    });
  };

  onPageChanged = (data) => {
    const { currentPage } = data;
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        skip: (currentPage - 1) * prevState.pagination.pageLimit
      }
    }), () => {
      this.getPages((currentPage - 1) * this.state.pagination.pageLimit, this.state.pagination.pageLimit)
    });
  };

  SearchDisable = (value) => {
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        searchPage: value,
      }
    }));
  };

  render() {
    const modalstyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: 600,
      minWidth: 100,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 3,
    };
    const Android12Switch = styled(Switch)(({ theme }) => ({
      padding: 8,
      "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
          content: '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: 16,
          height: 16,
        },
        "&:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          left: 12,
        },
        "&:after": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M19,13H5V11H19V13Z" /></svg>')`,
          right: 12,
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
      },
    }));

    const columns = [
      {
        field: "sno",
        headerName: "S.no",
      },
      {
        field: "name",
        headerName: "Page Name",
      },
      {
        field: "status",
        headerName: "Status",
      },
    ];

    const actions = [
      { label: "Edit", callback: this.handleOpenEdit },
      { label: "Delete", callback: this.handleToggleDelete },
    ];

    return (
      <>
        <Helmet>
          <title>Manage Instruction Pages</title>
        </Helmet>
        <Card
          className="instruction-box"
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 1,
            borderRadius: 1,
            p: 2,
            mb: 5,
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
              <Typography
                variant="p"
                component="h4"
                gutterBottom
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: indigo[900],
                  textAlign: "left",
                  mb: 2,
                  fontFamily: "Barlow  , sans-serif!important",
                }}
              >
                Manage Instruction Pages
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
              <Button
                variant="contained"
                startIcon={<AddIcon sx={{ fontSize: "1.5rem!important" }} />}
                onClick={() => this.handleToggleAdd()}
                size="medium"
                sx={{
                  fontSize: "0.9rem!important",
                  fontWeight: "bold",
                  color: "#fff",
                  bgcolor: indigo[900],
                  float: "right",
                  textTransform: "capitalize",
                  py: 1,
                  fontFamily: "Barlow  , sans-serif!important",
                  "&:hover": {
                    bgcolor: indigo[900],
                    color: "#fff",
                  },
                }}
              >
                Add Page
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ pt: "5" }}>
              <CustomDataTable
                columns={columns}
                actions={actions}
                isLoading={this.state.loader}
                data={this.state.allPages.length > 0
                  ? this.state.allPages.map((item) => {
                    return {
                      ...item,
                      id: item.id,
                      sno: this?.state?.allPages.indexOf(item) + 1,
                      name: item.name,
                      status: <StatusChip status={item.is_active === 1 ? "active" : "inactive"} />
                    };
                  })
                  : []}
              />
              <br />
              {this.state.allPages && this.state.allPages.length > 0 && (
                <>
                  {this.state.totalRecords > 0 && (
                    <CustomPagination
                      key="paginationnew"
                      searchPage={this.state.pagination?.searchPage}
                      SearchDisable={this.SearchDisable}
                      totalRecords={this.state.totalRecords}
                      pageLimit={this.state.pagination?.pageLimit}
                      onPageChanged={this.onPageChanged}
                    />
                  )}
                  <div
                    style={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <label>Items per page :</label>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 50,
                      }}
                      size="small"
                    >
                      <Select
                        defaultValue={this.state.pagination?.pageLimit}
                        value={this.state.pagination?.pageLimit}
                        name="limit"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "4px 34px 4px 14px!important",
                            fontFamily: "Barlow",
                          },
                        }}
                        onChange={(e) => this.changePageLimit(e)}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                        <MenuItem value="100">100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
              {/* Add Page */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.openadd}
                onClose={() => this.handleToggleAdd()}
                closeAfterTransition
              >
                <Fade in={this.state.openadd}>
                  <Box sx={modalstyle}>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.handleToggleAdd()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <Typography
                      id="transition-modal-title"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#1A237E",
                        textAlign: "left",
                        mb: 2,
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                      variant="h6"
                      component="h2"
                    >
                      Add Page
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ValidatorForm
                          ref="form"
                          onSubmit={this.handleAddPage}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <TextValidator
                                label="Page Name"
                                onChange={this.handleChange}
                                name="pagename"
                                value={this.state.pagename}
                                variant="outlined"
                                fullWidth
                                validators={["required"]}
                                errorMessages={["Page name is required"]}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <FormControlLabel
                                // value={this.state.pagestatus}
                                checked={this.state.pagestatus}
                                name="pagestatus"
                                onChange={(e) => this.handleChangeStatus(e)}
                                control={<Android12Switch />}
                                label="Status"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Button
                                variant="contained"
                                startIcon={<SaveIcon />}
                                type="submit"
                                size="medium"
                                sx={{
                                  fontSize: "0.9rem!important",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  bgcolor: indigo[900],
                                  float: "left",
                                  textTransform: "capitalize",
                                  py: 1,
                                  fontFamily:
                                    "Barlow  , sans-serif!important",
                                  "&:hover": {
                                    bgcolor: indigo[900],
                                    color: "#fff",
                                  },
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
              {/* Edit Page  */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.openedit}
                onClose={() => this.handleToggleEdit()}
                closeAfterTransition
              >
                <Fade in={this.state.openedit}>
                  <Box sx={modalstyle}>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.handleToggleEdit()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <Typography
                      id="transition-modal-title"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#1A237E",
                        textAlign: "left",
                        mb: 2,
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                      variant="h6"
                      component="h2"
                    >
                      Edit Page
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ValidatorForm
                          ref="form"
                          onSubmit={this.handleUpdatePage}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <TextValidator
                                label="Page Name"
                                onChange={this.handleChange}
                                name="editpagename"
                                value={this.state.editpagename}
                                variant="outlined"
                                fullWidth
                                validators={["required"]}
                                errorMessages={["Page name is required"]}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <FormControlLabel
                                // value={this.state.pagestatus}
                                checked={this.state.editpagestatus}
                                name="editpagestatus"
                                onChange={(e) => this.handleChangeStatus(e)}
                                control={<Android12Switch />}
                                label="Status"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Button
                                variant="contained"
                                startIcon={<SaveIcon />}
                                type="submit"
                                size="medium"
                                sx={{
                                  fontSize: "0.9rem!important",
                                  fontWeight: "bold",
                                  color: "#fff",
                                  bgcolor: indigo[900],
                                  float: "left",
                                  textTransform: "capitalize",
                                  py: 1,
                                  fontFamily:
                                    "Barlow  , sans-serif!important",
                                  "&:hover": {
                                    bgcolor: indigo[900],
                                    color: "#fff",
                                  },
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
            </Grid>
            {/* delete comfimation modal */}
            <ConfirmationModal
              handleAPIRequest={this.handleDeletePage}
              isOpen={this.state.opendelete}
              handleClose={this.handleToggleDelete}
              message={"Are you sure you want to delete this page?"}
              title={"Delete License"}
              buttonText={"Yes"}
              buttonText2={"No"}
            />
            {/* alert messages */}
            <AlertMessage
              open={this.state.openalert}
              message={this.state.message}
              severity={this.state.alertseverity}
              closeCall={this.handleClose}
            />
          </Grid>
        </Card>
      </>
    );
  }
}
