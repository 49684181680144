import React, { Component } from "react";
import axios from "axios";
import AlertMessage from "../../alert/alert";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    styled,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { HiOutlineSearch } from "react-icons/hi";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { ContainerLoader } from "components/common/ContainerLoader";
import { LoadingButton } from "@mui/lab";
import { SaveAlt } from "@mui/icons-material";

const moment = extendMoment(originalMoment);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontFamily: "Barlow !important",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default class LinksUsed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: "",
            successError: "",
            openalert: false,
            alertseverity: "",
            loader: false,
            UserValues: [],
            selectedUsers: [
                {
                    email: "All",
                },
            ],
            fixedOptions: [],
            value: [],
            disableValue: "All",
            crossCheck: true,
            selectedDate: new Date(),
            formData: {
                startDate: "",
                email: "",
            },
            linksData: [],
            totalAmount: null,
            totalLinks: null,
            totalTicketsDelivered: null
        };

        this.getAllUsers = this.getAllUsers.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearDate = this.clearDate.bind(this);
        this.handleGetLinksData = this.handleGetLinksData.bind(this);
    }

    getAllUsers = () => {
        const userList_api_url =
            process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
        axios
            .post(userList_api_url, "", {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            .then((response) => {
                const { data } = response;

                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: "Something went wrong",
                        successError: "error",
                    });
                } else {
                    const sorted = data?.data?.sort((a, b) => {
                        if (a?.email?.toLowerCase().trim() < b?.email?.toLowerCase().trim())
                            return -1;
                        if (a?.email?.toLowerCase().trim() > b?.email?.toLowerCase().trim())
                            return 1;
                        return 0;
                    });
                    sorted.unshift({
                        email: "All",
                    });
                    this.setState({
                        UserValues: sorted,
                        open: false,
                        message: "",
                        successError: "success",
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                });
            });
    };

    componentDidMount() {
        this.getAllUsers();
        this.handleGetLinksData();
    }

    handleGetLinksData = () => {
        this.setState({
            loader: true,
        });
        const linksData_api_url = process.env.REACT_APP_API_URL + "/api/admin/manageLinksUsed";
        axios.post(linksData_api_url, {
            users: [],
            year: moment(this.state.selectedDate).format("YYYY"),
        }, {
            headers: {
                authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                });
            } else {
                const totalAmount = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.amount), 0);
                const links = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.links), 0);
                const ticketsDelivered = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.ticketsDelivered), 0);
                this.setState({
                    totalAmount: totalAmount,
                    totalTicketsDelivered: ticketsDelivered,
                    totalLinks: links
                })
                this.setState({
                    loader: false,
                    linksData: data.data,
                    open: false,
                    message: "",
                    successError: "success",
                });
            }
        }).catch((error) => {
            this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
            });
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loader: true });
        const api_url = process.env.REACT_APP_API_URL + "/api/admin/manageLinksUsed";
        axios.post(api_url, {
            year: moment(this.state.selectedDate).format("YYYY"),
            users: this.state.selectedUsers[0]?.email === "All" ? [] : this.state?.selectedUsers?.map((item) => {
                return item?._id
            }),
        }, {
            headers: {
                authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    loader: false,
                    openalert: true,
                    message: "Something went wrong",
                    alertseverity: "error",
                });
            } else {
                const totalAmount = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.amount), 0);
                const links = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.links), 0);
                const ticketsDelivered = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.ticketsDelivered), 0);
                this.setState({
                    totalAmount: totalAmount,
                    totalTicketsDelivered: ticketsDelivered,
                    totalLinks: links
                })
                this.setState({
                    loader: false,
                    linksData: data.data,
                });
            }
        }).catch((error) => {
            this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
            });
        });
    };

    handleSelectUser = (event, value) => {
        if (value.length === 0) {
            this.setState({ disableValue: "", selectedUsers: [] });
        }
        value?.map((item) => {
            if (item?.email === "All") {
                this.setState({
                    disableValue: "All",
                    selectedUsers: [
                        {
                            email: "All",
                        },
                    ],
                });
            } else {
                this.setState({
                    selectedUsers: value,
                });
            }
            return item;
        });
    };

    clearDate = () => {
        this.setState({ selectedDate: null, crossCheck: true });
    };

    setStartDate = (date) => {
        this.setState({ selectedDate: date });
        const { formData } = this.state;
        const datestart = moment(date).format("YYYY");
        formData["startDate"] = datestart;
        this.setState({ formData, crossCheck: false });
    };

    handleClose = () => {
        this.setState({
            openalert: false,
        });
    };

    exportToCSV = (data) => {
        const csv = this.convertToCSV(data);
        const file = new Blob([csv], { type: "text/csv" });
        saveAs(file, "data.csv");
    };

    formatMonthValue = (monthData) => {
        return typeof monthData === ('number' || "string") ? monthData ?? 0 : 0;
    }

    convertToCSV = (data) => {
        const filterdata = data.map((item, index) => {
            const srNo = this.formatMonthValue(index + 1);
            const links = this.formatMonthValue(item.links);
            const ticketsDelivered = this.formatMonthValue(item.ticketsDelivered);
            return {
                "S.no": '\t' + srNo,
                "Months": '\t' + moment(item.month, "MM").format("MMMM"),
                "Links": '\t' + links,
                "Tickets Delivered": '\t' + ticketsDelivered,
            }
        })
        const footer = [{
            "S.no": "Total",
            "Links": '\t' + this.formatMonthValue(this.state.totalLinks),
            "Tickets Delivered": '\t' + this.formatMonthValue(this.state.totalTicketsDelivered),
        }];
        const combinedData = filterdata.concat(footer);
        return Papa.unparse(combinedData);
    };

    render() {
        const year = moment(this.state.selectedDate).format("YYYY");
        return (
            <>
                <Helmet>
                    <title>Links Used</title>
                </Helmet>
                <Card
                    className="instruction-box"
                    sx={{
                        width: "100%",
                        height: "100%",
                        bgcolor: "background.paper",
                        boxShadow: 1,
                        borderRadius: 1,
                        p: 2,
                        mb: 5,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh",
                        maxWidth: "100%",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                            <Typography
                                variant="p"
                                component="h4"
                                gutterBottom
                                sx={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    color: indigo[900],
                                    textAlign: "left",
                                    mb: 2,
                                    fontFamily: "Barlow  , sans-serif!important",
                                }}
                            >
                                Links Used
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ pt: "5" }}
                        >
                            <ValidatorForm
                                onSubmit={this.handleSubmit}
                                className="filter-form mb-5"
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                        container
                                        spacing={{ xs: 2, md: 2, lg: 2 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        <Grid item xs={12} sm={4} md={5} lg={5}>
                                            <Autocomplete
                                                multiple
                                                fullWidth
                                                id="checkboxes-tags-demo"
                                                options={this.state.UserValues}
                                                disableCloseOnSelect
                                                onChange={this.handleSelectUser}
                                                value={this.state.selectedUsers}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.email === value.email
                                                }
                                                getOptionLabel={(option) => option.email}
                                                renderOption={(props, option, { selected }) => (
                                                    <List
                                                        disablePadding
                                                        {...props}
                                                        sx={{
                                                            pointerEvents:
                                                                this.state.disableValue === "All" &&
                                                                    option.email !== "All"
                                                                    ? "none"
                                                                    : "auto",
                                                            width: "100%",
                                                            bgcolor: "background.paper",
                                                        }}
                                                    >
                                                        <ListItem
                                                            disablePadding
                                                            disabled={
                                                                this.state.disableValue === "All" &&
                                                                    option.email !== "All"
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    icon={
                                                                        <CheckBoxOutlineBlankIcon fontSize="medium" />
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxIcon fontSize="medium" />
                                                                    }
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText primary={option.email} />
                                                        </ListItem>
                                                    </List>
                                                )}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        validators={["required"]}
                                                        errorMessages={["This field is required"]}
                                                        {...params}

                                                        sx={{
                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                                color: "#C4C4C4"
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        label="Select Users"
                                                        value={this.state.selectedUsers}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    height: "100%",
                                                },
                                            }}
                                        >
                                            <DatePicker
                                                placeholderText="Select Year"
                                                selected={this.state.selectedDate}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                isClearable={true}
                                                yearItemNumber={9}
                                                customInput={
                                                    <TextValidator
                                                        validators={["required"]}
                                                        sx={{
                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                                color: "#C4C4C4"
                                                            },
                                                        }}
                                                        errorMessages={["This field is required"]}
                                                        variant="outlined"
                                                        className="filter-input"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    className="date-icon"
                                                                >
                                                                    <div className="d-flex">
                                                                        {this.state?.crossCheck ? (
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                edge="end"
                                                                            >
                                                                                <EventIcon className="text-dark" />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <IconButton
                                                                                edge="end"
                                                                                onClick={() => this.clearDate()}
                                                                            >
                                                                                <ClearIcon className="text-dark" />
                                                                            </IconButton>
                                                                        )}
                                                                    </div>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                }
                                                onChange={(dates) => this.setStartDate(dates)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2} lg={2}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="filter-btn "
                                                startIcon={<HiOutlineSearch />}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ValidatorForm>
                        </Grid>
                    </Grid>
                    {!this.state?.loader ? (
                        <>
                            <Grid
                                contaier
                                spacing={1}
                                className="usage-tbl-header  mt-3"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className="usage-tbl-header text-right"
                                >
                                    <Grid item>
                                        <LoadingButton
                                            onClick={() =>
                                                this.exportToCSV(this.state?.linksData)
                                            }
                                            size="large"
                                            endIcon={<SaveAlt />}
                                            loading={false}
                                            disabled={false}
                                            loadingPosition="end"
                                            sx={{
                                                fontFamily: "Bevan, cursive !important",
                                                marginBottom: "10px"
                                            }}
                                            variant="contained"
                                        >
                                            <span>Export to CSV</span>
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>S.no</StyledTableCell>
                                            <StyledTableCell align="left">Months</StyledTableCell>
                                            <StyledTableCell align="left">Links</StyledTableCell>
                                            <StyledTableCell align="left">Tickets Delivered</StyledTableCell>
                                            <StyledTableCell align="left">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state?.linksData && this.state?.linksData?.length > 0 ? (
                                            <>
                                                {this.state?.linksData && this.state?.linksData.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {moment(row?.month, "MM").format("MMMM")}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.links}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.ticketsDelivered}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left" >
                                                            <Button
                                                                component={Link}
                                                                size="small"
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: "#002986!important",
                                                                    color: "white!important",
                                                                    padding: "2px 5px!important",
                                                                    textTransform: "none!important",
                                                                    fontWeight: "bold",
                                                                    fontFamily: "Barlow!important",
                                                                    margin: "0 5px",
                                                                }}
                                                                to={`/links-used-detail/${row.month}/${year}`}
                                                            >
                                                                View
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                                <StyledTableRow >
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">Total</StyledTableCell>
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left"></StyledTableCell>
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{this.state.totalLinks ?? 0.00}</StyledTableCell>
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{this.state.totalTicketsDelivered ?? 0.00}</StyledTableCell>
                                                    {/* <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">${this.state.totalAmount ?? 0.00}</StyledTableCell> */}
                                                    <StyledTableCell align="left"></StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    colSpan={5}
                                                    align="center"
                                                >
                                                    No Data Found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <ContainerLoader />
                    )}
                </Card>
                <AlertMessage
                    open={this.state?.openalert}
                    message={this.state?.message}
                    severity={this.state?.alertseverity}
                    closeCall={this.handleClose}
                />
            </>
        );
    }
}
