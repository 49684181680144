/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// mui  icons 
import { ArrowBack } from '@mui/icons-material';

// mui  component
import { Autocomplete, Button, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';

// Component
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ContainerLoader } from 'components/common/ContainerLoader';
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    &:focus-visible {
      outline: 0;
    }
  `,
);

const AddAffiliates = ({
    setIsAddAffiliatesForm,
    isLoading,
    singleAffiliate,
    getAllAffiliate,
    isEediting,
    setIsEediting,
    errorMessage,
}) => {
    const {
        getAllAffiliateCompanyCategoryAPI,
        addAffiliateAPI,
        updateAffiliateAPI
    } = useAdminAPICollection();
    const [companyCategory, setCompanyCategory] = useState([]);
    const [image, setImage] = useState(null);
    const [error, setError] = useState("")
    const [formData, setFormData] = useState({
        companyName: "",
        text: "",
        link: "",
        categoryId: null
    });

    const handleOnchangeforInputText = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const validateUrl = (value) => {
        const pattern = /^https:\/\/.*/;
        if (!pattern.test(value)) {
            setError('URL must start with "https://"');
            return true;
        } else {
            setError('');
            return false;
        }
    };

    const handleChangeUrlLink = (e) => {
        const { name, value } = e.target;
        validateUrl(value);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlefileUpload = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setImage(base64)
    }

    const handleChangeVendor = (newVal) => {
        if (newVal) {
            setFormData({ ...formData, categoryId: newVal });
        } else {
            setFormData({ ...formData, categoryId: null });
        }
    };

    const handleAddAffiliate = async () => {
        const isValidUrl = validateUrl(formData.link);
        if (!isValidUrl) {
            const parmas = {
                ...formData,
                image: image,
                categoryId: formData?.categoryId?._id
            }
            try {
                const res = await addAffiliateAPI(parmas);
                const { data } = res;
                if (data.error) {
                    errorMessage(data?.message ?? "", "error");
                } else {
                    errorMessage("Affiliate company Added successfully!", "success");
                    setIsAddAffiliatesForm(false);
                    getAllAffiliate();
                }
            } catch (error) {
                errorMessage(error.response.data.message ?? "", "error");
            }
        } else {
            errorMessage("Please enter valid link!", "error");
        }
    }

    const handleUpdateAffiliate = async () => {
        try {
            const parmas = {
                ...formData,
                image: image,
                categoryId: formData?.categoryId?._id
            }
            const parmasImage = {
                ...formData,
                categoryId: formData?.categoryId?._id
            }
            const res = await updateAffiliateAPI(image ? parmas : parmasImage);
            const { data } = res;
            if (data.error) {
                errorMessage(data?.message ?? "", "error");
            } else {
                errorMessage("Affiliate company update successfully!", "success");
                setIsEediting(false);
                setIsAddAffiliatesForm(false);
                getAllAffiliate();
            }
        } catch (error) {
            errorMessage(error.response.data.message ?? "", "error");
        }
    }

    const getAllAffiliateCompanyCategory = async () => {
        try {
            const res = await getAllAffiliateCompanyCategoryAPI();
            const { data } = res;
            if (data.error) {
                errorMessage();
            } else {
                setCompanyCategory(data?.data);
            }
        } catch (error) {
            errorMessage();
        }
    }

    useEffect(() => {
        if (!singleAffiliate) {
            return;
        }
        const category = companyCategory.find(option =>
            option._id === singleAffiliate.categoryId?._id
        );
        const updatedFormData = {
            ...formData,
            id: singleAffiliate._id,
            companyName: singleAffiliate.companyName,
            text: singleAffiliate.text,
            link: singleAffiliate.link,
            categoryId: category,
        };
        setFormData(updatedFormData);
    }, [singleAffiliate, companyCategory]);

    useEffect(() => {
        getAllAffiliateCompanyCategory();
    }, [])

    return (
        <>
            <Card>
                <CardHeader
                    title={isEediting ? "Update Affiliates" : "Add Affiliates"}
                    action={
                        <>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                    paddingX: "1rem",
                                    marginRight: "0.8rem",
                                }}
                                startIcon={<ArrowBack />}
                                onClick={() => setIsAddAffiliatesForm(false)}
                            >
                                Back
                            </Button>
                        </>
                    }
                />
                <CardContent>
                    {isLoading ? (
                        <ContainerLoader />
                    ) : (
                        <ValidatorForm
                            className="filter-form1 mb-5"
                            onSubmit={() => isEediting ? handleUpdateAffiliate() : handleAddAffiliate()}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <label>Company Name</label>
                                            <TextValidator
                                                required
                                                variant="outlined"
                                                fullWidth
                                                value={formData.companyName}
                                                type="text"
                                                onChange={handleOnchangeforInputText}
                                                name="companyName"
                                                validators={["required"]}
                                                errorMessages={[
                                                    "Company Name is required"
                                                ]}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label>Category</label>
                                            <Autocomplete
                                                onChange={(event, newValue) => handleChangeVendor(newValue)}
                                                fullWidth
                                                value={formData.categoryId}
                                                className="filter-input"
                                                id="combo-box-demo"
                                                name="categoryId"
                                                isOptionEqualToValue={(option, value) => option._id === value?._id}
                                                options={companyCategory}
                                                getOptionLabel={(option) => option.category ?? null}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        value={formData?.categoryId?.category ?? null}
                                                        placeholder="category"
                                                        variant="outlined"
                                                        required
                                                        validators={["required"]}
                                                        errorMessages={[
                                                            "This field is required"
                                                        ]}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <label>Link </label>
                                            <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                                value={formData.link}
                                                required
                                                onChange={handleChangeUrlLink}
                                                name="link"
                                                validators={["required"]}
                                                errorMessages={[
                                                    "This field is required",
                                                ]}
                                            />
                                            <span style={{ color: "red", fontSize: "15px" }}>{error}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label>Image</label>
                                            <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                type='file'
                                                onChange={(e) => handlefileUpload(e)}
                                                name="file"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Text </label>
                                    <Textarea
                                        fullWidth
                                        name='text'
                                        required
                                        value={formData.text}
                                        onChange={handleOnchangeforInputText}
                                        validators={['required']}
                                        errorMessages={[('This field is required')]}
                                        sx={{ height: "140px!important" }}
                                    />
                                </Grid>
                                <Grid item xs={4} style={{ margin: "0 auto" }}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        sx={{ paddingY: "0.9rem" }}
                                        variant="contained"
                                        type="submit"
                                    >
                                        {isEediting ? "Update" : "Submit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    )}
                </CardContent>
            </Card>
        </>
    )
}

AddAffiliates.propTypes = {
    setIsAddAffiliatesForm: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    singleAffiliate: PropTypes.object.isRequired,
    getAllAffiliate: PropTypes.func.isRequired,
    isEediting: PropTypes.bool.isRequired,
    setIsEediting: PropTypes.func.isRequired,
    errorMessage: PropTypes.func.isRequired,
};

AddAffiliates.defaultProps = {
    setIsAddAffiliatesForm: () => { },
    isLoading: false,
    singleAffiliate: {},
    getAllAffiliate: () => { },
    isEediting: false,
    setIsEediting: () => { },
    errorMessage: () => { },
};

export default AddAffiliates;