// reducers/timeReducer.js

const initialState = {
  time:0
};

const timeReducer = (state = initialState, action) => {  
  switch (action.type) {
    case "SAVE_TIME":
      return {
        ...state,
        [action.payload.name]: action.payload.time,
      };
    case "RESET_TIME":
      return {
        ...state,
        [action.payload.name]: action.payload.time,
      };
    default:
      return state;
  }
};

export default timeReducer;