import React, { useState, useEffect, } from "react";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import axios from "axios";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import SuccessImage from "../../images/successattend.jpg"
import customDateFormat from "utils/customDateFormat"

// mui style components
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

// import scss file
import "./Attendance.scss"
import {
    Box,
    Paper,
    Grid,
    Typography,
    Button,
    CardHeader
} from "@mui/material";

// mui icons
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import BarChartIcon from '@mui/icons-material/BarChart';
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        width: "100%",
        maxWidth: "265px",
        color: "#000059!important",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "1.6rem",
        fontFamily: "Barlow!important",

    },
    wrapper: {
        display: "flex",
        flex: " 1 1",
        flexDirection: "column",
        minWidth: "0",
        minHeight: "100vh",
        transition: "margin 0.3s",
        padding: "3rem 1.6rem 2.8rem 1.6rem",
    },
    filterfield: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff!important",
        padding: "0.7rem 1rem",
        border: "1px solid #CDCDCE",
        borderRadius: "0.2rem",

        fontSize: "1rem",
        fontFamily: "Barlow",
        color: "#999CA4E",
        "&:focus": {
            outline: "none",
        },
    },
    filterlabel: {
        fontSize: "1rem",
        fontFamily: "Barlow",
        color: "#000059",
        fontWeight: "500",
        float: "left!important",
    },

}));

const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    height: "100%!important",
    maxHeight: "70rem!important",
    color: theme.palette.text.secondary,
}));

const AttendanceDetails = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const [allAttendenceDetails, setAllAttendenceDetails] = useState([]);
    const [allTicketDetails, setAllTicketDetails] = useState([]);
    const [details, setDetails] = useState(false)
    const userId = useParams();

    const ticketDetails = (ticketId) => {
        const ticket_details_api_url = process.env.REACT_APP_API_URL + "/api/account/getTicketsAttended"
        axios.post(ticket_details_api_url, { _id: ticketId }, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })
            .then((response) => {
                setAllTicketDetails(response?.data);
            }).catch((error) => {
                console.log(error);
            })
        setLoading(false);
    }
    const getAllAccounts = () => {
        const attendance_details_api_url = process.env.REACT_APP_API_URL + "/api/account/getEventsAttendanceDetail"
        axios.post(attendance_details_api_url, { _id: userId?.id }, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })
            .then((response) => {
                setAllAttendenceDetails(response?.data?.data);
            }).catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getAllAccounts();
    }, [])

    return (
        <div className="user-dashboard-wrapper attendance-page">
            <Helmet>
                <title>Attendance details</title>
            </Helmet>
            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
                <Usersidebar />
                <Grid className={classes.wrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Card
                                sx={{ maxHeight: '100% !important' }}
                            >
                                <div className="vendortable-header">
                                    <Typography
                                        variant="h6"
                                        className={classes.heading}
                                    >
                                        {" "}
                                        Attendance Details
                                    </Typography>
                                    <Grid
                                        container
                                        spacing={{ xs: 1, md: 1, lg: 1 }}
                                        columns={{ xs: 4, sm: 8, md: 8 }}
                                        className="button-align"
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={() => history.goBack()}
                                                type="button"
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#002986!important",
                                                    color: "white!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                }}
                                                startIcon={<ArrowBack />}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                {!details ?
                                    (<Grid container spacing={2} className="mt-3" sx={{ margin: "10px 0 0 0!important", width: "100%", }}>
                                        <Grid item xs={12} md={12} className="attendance-data details">
                                            {allAttendenceDetails.map((item, index) => {
                                                return (
                                                    <Box key={index} sx={{ marginTop: '30px' }}>
                                                        <Grid container spacing={2} sx={{ padding: '15px', borderRadius: '4px', boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }}>

                                                            <Grid xs={12} sm={9} md={10} className="textBox">
                                                                <Typography sx={{ color: '#002986', fontSize: '20px', fontFamily: 'Barlow', fontWeight: '500' }}>
                                                                    {item.event_name}
                                                                </Typography>
                                                                <Typography sx={{ paddingTop: 1, color: '#000' }}><InsertInvitationIcon />{isNaN(item.date) ? customDateFormat(item.date) : 'Not Available'}</Typography>

                                                                <Typography sx={{ paddingTop: 1, color: '#000' }}> {item.ticketsAttended > 0 ? <img src={SuccessImage} alt="attended" style={{ width: "1.2rem", height: "1.2rem", margin: "0 2px" }} /> : <WarningAmberIcon />} Attendance: {item.ticketsAttended}/{item.totalTickets} Tickets Attended</Typography>

                                                            </Grid>
                                                            <Grid xs={12} sm={3} md={2} className="detail-button">
                                                                <Button

                                                                    variant="outlined" onClick={() => {
                                                                        setDetails(!details);
                                                                        ticketDetails(item?._id);
                                                                    }} sx={{

                                                                        padding: "2px 5px!important",
                                                                        textTransform: "none!important",
                                                                        fontWeight: "bold",
                                                                        fontFamily: "Barlow!important",

                                                                    }}
                                                                    disabled={item.totalTickets === 0 ? 'disabled' : null}>View Details</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })}

                                        </Grid>
                                    </Grid>)
                                    :
                                    (<>
                                        {loading ? (
                                            <ContainerLoader />
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: 2 }} className="event-details">
                                                <span>
                                                    <Typography sx={{ color: '#002986', fontSize: '20px', fontFamily: 'Barlow', fontWeight: '500', marginBottom: 1, textAlign: 'left' }}> {allTicketDetails?.vbdevent?.event_name}</Typography>
                                                    <Typography sx={{ textAlign: 'left', color: '#000' }}><InsertInvitationIcon /> Event Details: {customDateFormat(allTicketDetails?.vbdevent?.date)}</Typography>
                                                </span>

                                                <Button variant="outlined" onClick={() => setDetails(!details)} sx={{

                                                    padding: "2px 5px!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                }}
                                                >Close</Button>
                                            </Box>
                                        )}
                                        {allTicketDetails?.data?.map((itm, idex) => {
                                            return (
                                                <Box key={idex} className="ticket-details" sx={{ border: '1px solid #c5c1c1', borderRadius: '4px', marginBottom: 1, marginTop: 1, display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Typography sx={{ borderBottom: '', flex: 1, width: '100%', color: '#000' }} className="row-seat-cell">Row: {itm.row}, Seat: {itm.seat}, Section: {itm.section}</Typography>
                                                    <span style={{ flex: 1, display: 'flex', justifyContent: 'space-around', paddingTop: '17px', paddingBottom: '17px', width: '100%' }}>
                                                        <span>
                                                            <Typography sx={{ display: 'inline-block', color: '#000' }}>
                                                                <BarChartIcon />Event Attendance:
                                                                {itm.attended ?
                                                                    <Typography sx={{ color: '#0a6a0e', display: 'inline-block' }}>
                                                                        Attended
                                                                    </Typography>
                                                                    :
                                                                    <Typography sx={{ color: '#d50000', display: 'inline-block' }}> Not Attended
                                                                    </Typography>}
                                                            </Typography>

                                                        </span>


                                                    </span>
                                                </Box>
                                            )
                                        })}
                                    </>)
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    );
};

export default AttendanceDetails;