import Moment from 'moment';

export default function detailTimeFormat(datestring) {
    if (datestring.includes("undefined||undefined") || datestring === "undefined||undefined") {
        return "Invalid date";
      }
    
    if(!datestring){
        return '';
    }
    if(datestring === ''){
        return '';
    }
    if(datestring.includes("TBD")){
        return "TBD"
    }else{
    var correctDatestring = datestring.replace("||", "T");
    correctDatestring = correctDatestring.replace(" OFFSALE", "");
    correctDatestring = correctDatestring.replace(" CANCELED", "");
    correctDatestring = correctDatestring.replace(" ONSALE", "");
    correctDatestring = correctDatestring.replace(" RESCHEDULED", "");

  
    var datetime = correctDatestring.split("T");
    var retDate = '';

    // set date time format to MM/DD/yy hh:mm:ss
    var date = datetime[0].split("-");
    var year =  Moment(datetime[0]).format('YY');
    var day = date[2];
    var time = "00"
    var hour = "00";
    var month = date[1];
    var minute = "00";
    var second = "00";
    if(datetime[1] !== undefined){
     time = datetime[1].split(":");
     hour = time[0] ?? ""
     minute = time[1] ?? ""
     second = time[2] ?? ""
    }


    retDate = month + "/" + day + "/" + year + " " + hour + ":" + minute + ":" + second + " "

   


    /*
    var lastZ = correctDatestring.charAt(correctDatestring.length-1);
    if(lastZ !== "Z") {
        correctDatestring = correctDatestring + "Z";
    }
    var stringtoDate = new Date(correctDatestring);
    var retDate = Moment(stringtoDate).format('YYYY-MM-DD h:mma Z');
    */

    return retDate;
}
}