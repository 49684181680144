import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import Autocomplete from "@mui/material/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import PasswordField from "components/passwordfield/passwordfield";
import PageLoader from "components/PageLoader/PageLoader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import Cookies from "js-cookie";
import { ArrowBack } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

class Addaccount extends React.Component {
  state = {
    open: false,
    message: "",
    successError: "",
    vendorType: "",
    email: "",
    password: "",
    teamName: "",
    skybox_date: null,
    skybox_internal_notes: "scanall",
    accountTypeVal: "",
    vendorTypeVal: "",
    teamNameVal: "",
    posTypeVal: "",
    marketTypeVal: "",
    apitoken: "",
    error: [],
    internalNoteValue: "",
    lowerEmailCheck: false,
    posSelect: false,
    lowerEmailMsg: "",
    accountType: [{ title: "POS" }, { title: "Marketplace" }],
    emptyArr: [
      {
        title: "",
      },
    ],
    posType: [
      {
        title: "Skybox",
      },
      // {
      //   title: "Manual/None",
      // },
      {
        title: "TV",
      },
      {
        title: "TA",
      },
      // {
      //   title: "Other",
      // },
    ],
    marketplace: [
      {
        title: "VividSeats",
      },
      {
        title: "TickPick",
      },
      {
        title: "SeatGeek",
      },
      {
        title: "Gametime",
      },
      {
        title: "Manual/None",
      },
      {
        title: "Other",
      },
    ],
    internalnotes: [],
    showInternalNotes: false,
  };

  componentDidMount() {
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }

    const listinternalnoted_api =
      process.env.REACT_APP_API_URL + "/api/getAllInternalnotes";
    axios
      .get(listinternalnoted_api, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (!data.error) {
          this.setState({ internalnotes: data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChangeNotes = (e, newVal) => {
    if (newVal !== null) {
      this.setState({ internalNoteValue: newVal.name });
    } else {
      this.setState({ internalNoteValue: newVal });
    }
  };
  handleChangeAccount = (e, newVal) => {
    this.setState({
      accountTypeVal: newVal,
    });
    if (this.state.posSelect) {
      this.setState({ posSelect: false, posTypeVal: "" });
    }
  };
  handleChangePOS = (e, newVal) => {
    this.setState({
      posTypeVal: newVal,
      posSelect: true,
    });
  };
  handleChangeMarket = (e, newVal) => {
    this.setState({
      marketTypeVal: newVal,
    });
  };
  handleChange = (e) => {
    if (e.target.value !== e.target.value.toLowerCase()) {
      this.setState({
        lowerEmailCheck: true,
        lowerEmailMsg: "Please enter email in lower case",
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        lowerEmailCheck: false,
        lowerEmailMsg: "",
      });
    }
  };
  setDate(date) {
    this.setState({ skybox_date: date.$d });
  }
  handleChangePass = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  openInternalNotes = () => {
    this.setState({ showInternalNotes: true });
  };
  closeInternalNotes = () => {
    this.setState({ showInternalNotes: false });
  };
  handleSubmit = async () => {
    this.setState({
      loader: true,
    });

    const data = {
      account_type: this.state.accountTypeVal.title,
      pos: this.state.posTypeVal.title,
      marketplace: this.state.marketTypeVal.title,
      username: this.state.email,
      password: this.state.password,
      apitoken: this.state.apitoken,
      skybox_date: this.state.skybox_date,
      skybox_internal_notes: this.state.skybox_internal_notes,
      internalNote:
        this.state.skybox_internal_notes === "scanonly"
          ? this.state.internalNoteValue
          : "",
    };

    console.log("data-=", data);
    const addAccount_api_url =
      process.env.REACT_APP_API_URL + "/api/account/add";
    await axios
      .post(addAccount_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            eventList: data.data,
            open: true,
            message: data.message,
            successError: "success",
          });
          this.props.history.push("/market-pos-list");
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  render() {
    const {
      accountType,
      posType,
      email,
      password,
      accountTypeVal,
      marketplace,
      loader,
      apitoken,
    } = this.state;
    const labelStyle = {
      fontWeight: "500",
      fontFamily: "Barlow",
      color: "#002986",
    };
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Add Marketplace & POS</title>
          </Helmet>
          <Header />

          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />

            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {loader && <PageLoader />}
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs="12"
                      lg="12"
                      md="12"
                      sm="12"
                      className="space-top-col"
                    >
                      <Card>
                        <CardHeader
                          title="Add Accounts"
                          action={
                            <Grid
                              container
                              spacing={{ xs: 1, md: 1, lg: 1 }}
                              columns={{ xs: 4, sm: 8, md: 8 }}
                              className="button-align"
                            >
                              <Grid item>
                                <Button
                                  onClick={() => this.props.history.goBack()}
                                  type="button"
                                  size="large"
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                  }}
                                  startIcon={<ArrowBack />}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </Grid>
                          }
                        />

                        <AlertMessage
                          open={this.state.open}
                          message={this.state.message}
                          severity={this.state.successError}
                          closeCall={this.handleClose}
                        />
                        {!loader ?
                          <>
                            <CardContent>
                              <ValidatorForm onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                      <label style={labelStyle}>Account type</label>
                                      <Autocomplete
                                        onChange={this.handleChangeAccount}
                                        fullWidth
                                        name="accountTypeVal"
                                        autoComplete='accountTypeVal'
                                        options={accountType}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                          <TextValidator
                                            placeholder="Select Account Type"
                                            value={this.state.accountTypeVal}
                                            {...params}
                                            variant="outlined"
                                            validators={["required"]}
                                            errorMessages={"This field is required"}
                                          />
                                        )}
                                      />
                                    </div>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    {typeof accountTypeVal != "undefined" &&
                                      accountTypeVal != null &&
                                      accountTypeVal.title === "POS" && (
                                        <div className="form-group">
                                          <label style={labelStyle}>
                                            Choose POS
                                          </label>
                                          <Autocomplete
                                            onChange={this.handleChangePOS}
                                            fullWidth
                                            name="posTypeVal"
                                            options={posType}
                                            getOptionLabel={(option) =>
                                              option.title
                                            }
                                            renderInput={(params) => (
                                              <TextValidator
                                                placeholder="Select POS"
                                                value={this.state.posTypeVal}
                                                {...params}
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={
                                                  "This field is required"
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      )}
                                    {typeof accountTypeVal != "undefined" &&
                                      accountTypeVal != null &&
                                      accountTypeVal.title === "Marketplace" && (
                                        <div className="form-group">
                                          <label style={labelStyle}>
                                            Choose Marketplace
                                          </label>
                                          <Autocomplete
                                            onChange={this.handleChangeMarket}
                                            fullWidth
                                            name="marketTypeVal"
                                            options={marketplace}
                                            getOptionLabel={(option) =>
                                              option.title
                                            }
                                            renderInput={(params) => (
                                              <TextValidator
                                                value={this.state.marketTypeVal}
                                                placeholder="Select Marketplace"
                                                {...params}
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={
                                                  "This field is required"
                                                }
                                              />
                                            )}
                                          />
                                        </div>
                                      )}
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    {typeof accountTypeVal != "undefined" &&
                                      accountTypeVal != null &&
                                      this.state.posTypeVal !== null ? (
                                      (accountTypeVal.title === "POS" &&
                                        this.state.posTypeVal.title !== "TV" && 
                                        this.state.posTypeVal.title !== "TA") ||
                                        accountTypeVal.title === "Marketplace" ? (
                                        <div
                                          style={{
                                            textAlign: "left",
                                            paddingBottom: "14px",
                                          }}
                                        >
                                          <label style={labelStyle}>Email</label>
                                          <TextValidator
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.handleChange}
                                            placeholder="Enter Email"
                                            name="email"
                                            value={email}
                                            validators={["required", "isEmail"]}
                                            errorMessages={[
                                              "This field is required",
                                              "email is not valid",
                                            ]}
                                          />
                                          {this.state.error.email && (
                                            <div className="error-msg">
                                              {this.state.error.email}
                                            </div>
                                          )}
                                          {this.state.lowerEmailCheck ? (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {" "}
                                              {this.state.lowerEmailMsg}{" "}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    {typeof accountTypeVal != "undefined" &&
                                      accountTypeVal != null &&
                                      this.state.posTypeVal !== null ? (
                                      (accountTypeVal.title === "POS" &&
                                        this.state.posTypeVal.title !== "TV"  && 
                                        this.state.posTypeVal.title !== "TA") ||
                                        accountTypeVal.title === "Marketplace" ? (
                                        <div
                                          style={{
                                            textAlign: "left",
                                            paddingBottom: "14px",
                                          }}
                                        >
                                          <label style={labelStyle}>Password</label>
                                          <PasswordField
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.handleChangePass}
                                            placeholder="Enter Password"
                                            name="password"
                                            value={password}
                                            validators={[
                                              "required",
                                              "PasswordStrength",
                                            ]}
                                            errorMessages={[
                                              "This field is required",
                                              "password must be at least 6 character",
                                            ]}
                                            eyestyle={{
                                              position: "absolute",
                                              right: "20px",
                                              top: "44px",
                                            }}
                                            inputProps={{
                                              autocomplete: "new-password",
                                            }}
                                          />
                                          {this.state.error.password && (
                                            <div className="error-msg">
                                              {this.state.error.password}
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </Grid>

                                  {this.state.marketTypeVal && (
                                    <>
                                      {(this.state.marketTypeVal.title ===
                                        "VividSeats" ||
                                        this.state.marketTypeVal.title ===
                                        "Gametime" ||
                                        this.state.marketTypeVal.title ===
                                        "SeatGeek") && (this.state.posTypeVal.title !== "TV"  && 
                                        this.state.posTypeVal.title !== "TA") &&  (
                                          <Grid item xs={12} sm={6}>
                                            <div className="form-group">
                                              <label style={labelStyle}>
                                                API Token
                                              </label>
                                              <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                name="apitoken"
                                                onChange={(e) =>
                                                  this.setState({
                                                    apitoken: e.target.value,
                                                  })
                                                }
                                                value={apitoken}
                                                validators={["required"]}
                                                errorMessages={
                                                  "This field is required"
                                                }
                                              />
                                            </div>
                                          </Grid>
                                        )}
                                    </>
                                  )}
                                  {this.state.posTypeVal && (
                                    <>
                                      {this.state.posTypeVal.title === "Skybox" && (
                                        <>
                                          <Grid item xs={12} sm={6}>
                                            <div className="form-group">
                                              <label style={labelStyle}>
                                                Scan From Date
                                              </label>
                                              <DatePicker
                                                inputFormat="MM/DD/YYYY"
                                                placeholder="MM/DD/YYYY"
                                                defaultValue={new Date()}
                                                maxDate={new Date()}
                                                value={this.state.skybox_date}
                                                onChange={(newValue) => {
                                                  if (newValue !== null) {
                                                    this.setDate(newValue);
                                                  } else {
                                                    this.setState({
                                                      skybox_date: null,
                                                    });
                                                  }
                                                }}
                                                renderInput={(params) => (
                                                  <TextValidator
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="MM/DD/YYYY"
                                                    validators={["required"]}
                                                    value={this.state.skybox_date}
                                                    errorMessages={[
                                                      "This field is required",
                                                    ]}
                                                  />
                                                )}
                                              />
                                              {/* <DateValidatorElement
                                                selected={
                                                  this.state.skybox_date
                                                }
                                                value={this.state.skybox_date}
                                                dateFormat="MM/dd/yyyy"
                                                maxDate={new Date()}
                                                onChange={(date) =>
                                                  this.setDate(date)
                                                }
                                                validators={["required"]}
                                                errorMessages={[
                                                  "This field is required",
                                                ]}
                                              /> */}
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <div className="form-group login-wrapper">
                                              <label style={labelStyle}>
                                                Skybox Internal Notes
                                              </label>
                                              <RadioGroup
                                                aria-label="skybox_internal_notes"
                                                defaultValue="scanall"
                                                value={
                                                  this.state.skybox_internal_notes
                                                }
                                                name="skybox_internal_notes"
                                                className="scan-condition-opts"
                                                onChange={this.handleChangePass}
                                              >
                                                <FormControlLabel
                                                  value="scanall"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="Scan All"
                                                />
                                                <FormControlLabel
                                                  value="scanonly"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="Scan only Internal Notes"
                                                />
                                              </RadioGroup>
                                            </div>
                                          </Grid>
                                          {this.state.skybox_internal_notes ===
                                            "scanonly" &&
                                            this.state.posTypeVal !== "" && (
                                              <Grid item xs={12} sm={6}>
                                                <div style={{ textAlign: "left" }}>
                                                  <label style={labelStyle}>
                                                    Internal Notes
                                                  </label>
                                                  <Autocomplete
                                                    onChange={
                                                      this.handleChangeNotes
                                                    }
                                                    fullWidth
                                                    name="internalNotes"
                                                    options={
                                                      this.state.internalnotes
                                                    }
                                                    getOptionLabel={(option) =>
                                                      option.name
                                                    }
                                                    renderInput={(params) => (
                                                      <TextValidator
                                                        placeholder="Select Internal Notes"
                                                        value={
                                                          this.state
                                                            .internalNoteValue
                                                        }
                                                        {...params}
                                                        variant="outlined"
                                                        validators={["required"]}
                                                        errorMessages={
                                                          "This field is required"
                                                        }
                                                      />
                                                    )}
                                                  />
                                                </div>
                                              </Grid>
                                            )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  <Grid item xs={12} sm={12}
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Button
                                      sx={{ marginTop: "15px" }}
                                      variant="contained"
                                      type="submit"
                                      size="large"
                                      className="theme-btn"
                                    >
                                      Add Account
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            </CardContent>
                          </>
                          :
                          <ContainerLoader />
                        }
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </LocalizationProvider>
    );
  }
}
export default Addaccount;