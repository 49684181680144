import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// mui components
import styled from '@emotion/styled';
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Editor } from '@tinymce/tinymce-react';

// import components
import AlertMessage from 'components/message/message';
import { ContainerLoader } from 'components/common/ContainerLoader';
import axios from 'axios';
import { Cancel } from '@mui/icons-material';
import { Col } from 'react-bootstrap';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

export default function WhatsNew() {
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        status: "",
        text: "",
        id: ""
    })
    const [message, setMessage] = useState({
        error: "",
        open: false,
        message: ""
    })
    const [isVisiableModal, setIsVisiableModal] = useState(false)
    const getAllWhatsNew = async () => {
        try {
            setIsLoading(true)
            const addUser_api_url = process.env.REACT_APP_API_URL + "/api/setting/detail";
            const response = await axios.post(addUser_api_url, { "id": "60633a028f70d97537accede" }, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    error: "error",
                    open: true,
                    message: "Somthing went wrong"
                })
                setIsLoading(false)
            } else {
                setFormData({
                    ...formData,
                    status: data && data?.data?.whats_new_lat_status,
                    text: data && data?.data?.whats_new_lat_text,
                    id: data && data?.data?._id
                })
                setIsLoading(false)
            }
        } catch (error) {
            setMessage({
                ...message,
                error: "error",
                open: true,
                message: "Somthing went wrong"
            })
            setIsLoading(false)
        }
    }


    const updateWhatsNewData = async () => {
        const parmas = {
            whats_new_lat_status: formData.status,
            whats_new_lat_text: formData.text,
            id: formData.id
        }
        try {
            const addUser_api_url = process.env.REACT_APP_API_URL + "/api/setting/update";
            const response = await axios.post(addUser_api_url, parmas, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    error: "error",
                    open: true,
                    message: "Somthing went wrong"
                })
            } else {
                await getAllWhatsNew()
                setMessage({
                    ...message,
                    error: "success",
                    open: true,
                    message: "What's new data updated successfully!"
                })
            }
        } catch (error) {
            setMessage({
                ...message,
                error: "error",
                open: true,
                message: "Somthing went wrong"
            })
        }
    }

    const handleStatusChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked,
        });
    };

    const hideAlert = () => {
        setMessage({
            ...message,
            open: false
        })
    }

    const handleCloseModal = () => {
        setIsVisiableModal(false)
    }

    const handleModalFormShow = () => {
        setIsVisiableModal(true)
    }


    useEffect(() => {
        getAllWhatsNew()
    }, [])


    return (
        <>
            <Helmet>
                <title>What's new</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="What's new"
                            action={
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                        paddingX: "1rem",
                                        marginRight: "0.8rem",
                                    }}
                                    onClick={() => handleModalFormShow()}
                                >
                                    Test Modal
                                </Button>
                            }
                        />
                        <CardContent>
                            {isLoading ? (
                                <ContainerLoader />
                            ) : (
                                <ValidatorForm
                                    className="filter-form1 mb-5"
                                    onSubmit={() => updateWhatsNewData()}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                What's new Status
                                            </Typography>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Android12Switch
                                                            name="status"
                                                            defaultChecked
                                                            checked={formData.status}
                                                            onChange={handleStatusChange}
                                                        />}
                                                    label={formData.status ? "Active" : "Inactive"}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                What's new Text
                                            </Typography>
                                            <div style={{ zIndex: 0 }}>
                                                <Editor
                                                    apiKey="ox4jp745eo6k4whfbfxfk0675klim6a6i0vhupfcdmsshjcr"
                                                    onEditorChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            text: e
                                                        })
                                                    }}
                                                    value={formData.text}
                                                    init={{
                                                        height: 500,
                                                        anchor_top: false,
                                                        anchor_bottom: false,
                                                        automatic_uploads: true,
                                                        file_picker_types: "file image media",
                                                        paste_data_images: true,
                                                        image_advtab: true,
                                                        file_picker_callback: function (
                                                            callback,
                                                            value,
                                                            meta
                                                        ) {
                                                            if (meta.filetype == "image") {
                                                                var input = document.createElement("input");
                                                                input.setAttribute("type", "file");
                                                                input.setAttribute("accept", "image/*");
                                                                input.onchange = function () {
                                                                    var file = this.files[0];
                                                                    var reader = new FileReader();
                                                                    reader.onload = function () {
                                                                        callback(reader.result, {
                                                                            alt: file.name,
                                                                        });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                };
                                                                input.click();
                                                            }
                                                        },
                                                        advlist_bullet_styles: "square",
                                                        advlist_number_styles:
                                                            "lower-alpha,lower-roman,upper-alpha,upper-r",
                                                        toolbar_mode: "sliding",
                                                    }}
                                                    menubar={[
                                                        "file edit view insert format tools table help",
                                                    ]}
                                                    plugins={[
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste code help wordcount",
                                                        "textpattern",
                                                        "image",
                                                        "imageupload",
                                                        "link",
                                                        "help",
                                                        "wordcount",
                                                        "code",
                                                        "preview",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "paste",
                                                        "charmap",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "emoticons",
                                                        "directionality",
                                                        "visualchars",
                                                        "noneditable",
                                                        "nonbreaking",
                                                        "quickbars",
                                                        "toc",
                                                        "anchor",
                                                        "pagebreak",
                                                        "importcss",
                                                        "autosave",
                                                        "save",
                                                        "template",
                                                        "help",
                                                    ]}
                                                    toolbar={[
                                                        " undo redo | bullist numlist | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons ",
                                                    ]}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} style={{ margin: "0 auto" }}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                sx={{ paddingY: "0.9rem" }}
                                                variant="contained"
                                                type="submit"
                                            // className={`${isDisableButton ? "theme-btn-submit-disablebutton" : "theme-btn-submit"}`}
                                            // disabled={isDisableButton}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                maxWidth="sm"
                open={isVisiableModal}
                onClose={handleCloseModal}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "16px 33px" }}>What's new</DialogTitle>
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                        }}
                    >
                        <Cancel />
                    </IconButton>
                    <DialogContentText>
                        <Col lg={12} md={12} className='text-justify'>
                            <p dangerouslySetInnerHTML={{ __html: formData.text }} />
                        </Col>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseModal}
                        sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertMessage
                open={message.open}
                message={message.message}
                severity={message.error}
                closeCall={hideAlert}
            />
        </>
    )
}
