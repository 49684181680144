import { Box } from "@mui/material";
import React from "react";
const TheLayouts = ({ children }) => {
  return (
    <Box>
      <div> {children}</div>
    </Box>
  );
};

export default TheLayouts;
