import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { decodeBase64ToParams } from 'utils/base64';

const AdminRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const token = localStorage.getItem('accessTokenAdmin');
  const lastToken = localStorage.getItem('lat');
  const decodeToken = JSON?.parse(lastToken);
  const [isTokenExpiredState, setIsTokenExpiredState] = useState(false);
  let lastTokenAdmin;

  try {
    if (lastToken) {
      const decodedParams = decodeBase64ToParams(decodeToken);
      lastTokenAdmin = JSON.parse(decodedParams);
    } else {
      localStorage.removeItem('lat');
      localStorage.removeItem('accessTokenAdmin');
    }
  } catch (error) {
    localStorage.removeItem('lat')
    lastTokenAdmin = null;
  }

  function isTokenExpired(creationDate) {
    const tokenCreationDate = new Date(creationDate);
    const currentDate = new Date();
    const differenceInMs = currentDate - tokenCreationDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    if (differenceInDays > 7) {
      return true; // Token has expired
    } else {
      return false; // Token is still valid
    }
  }

  useEffect(() => {
    if (token) {
      const isExpired = isTokenExpired(lastTokenAdmin?.expriedToken);
      setIsTokenExpiredState(isExpired);
    }
  }, [lastTokenAdmin, token]);

  if (!token) {
    return <Redirect to="/admin-login" />;
  }

  if (isTokenExpiredState) {
    localStorage.removeItem('accessTokenAdmin')
    return <Redirect to="/admin-login" />;
  }

  if (lastTokenAdmin?.lastToken !== token) {
    localStorage.removeItem('accessTokenAdmin')
    return <Redirect to="/admin-login" />;
  }


  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};


export default AdminRoute;
