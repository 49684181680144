import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { connect } from "react-redux";

// scss file
import "./addvendor.scss";

// mui icons
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";

// mui components
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Card,
  Button,
  CardContent,
  Autocomplete,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  FormControlLabel,
  Switch,
  FormControl,
  // FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Cookies from "js-cookie";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import Usersidebar from "components/user-sidebar/user-sidebar";
import PasswordField from "components/passwordfield/passwordfield";
// import { ContainerLoader } from 'components/common/ContainerLoader'
import UserProfileAction from "redux/actions/UserProfielAction";
import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))


class Addvendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      open: false,
      isbuttonLoader: false,
      message: "",
      successError: "",
      vendorType: "",
      email: "",
      password: "",
      logincreds: [{ email: "", password: "" }],
      deliverymsg_time: "immediate",
      teamName: "",
      accountTypeVal: "Vendor",
      isAccountScanAllFor: false,
      vendorTypeVal: "",
      vendorTypeID: null,
      eventScanCount: 'tenEvents',
      leagueVal: "",
      leagueID: null,
      teamNameVal: "",
      teamNameID: null,
      posTypeVal: "",
      marketTypeVal: "",
      apitoken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      lowerEmailCheck: false,
      lowerEmailMsg: "",
      vendors: [],
      leagues: [],
      teams: [],
      error: [],
      isScanAllForTicketChecked: "off",
      auto_scan_events: 'off',
      emailExist: null,
      modalArchiveShow: false,
      currentIndex: null,
      scanEventName: "",
      accountType: [
        { title: "Vendor" },
        { title: "POS" },
        { title: "Marketplace" },
      ],
      chooseVendor: [
        { title: "Vendor" },
        { title: "POS" },
        { title: "Marketplace" },
      ],
      posType: [
        {
          title: "Skybox",
        },
        {
          title: "Manual/None",
        },
        {
          title: "Other",
        },
      ],
      marketplace: [
        {
          title: "VividSeats",
        },
        {
          title: "SeatGeek",
        },
        {
          title: "Manual/None",
        },
        {
          title: "Other",
        },
      ],
      emptyArr: [{ title: "" }],
    };
  }

  componentDidMount() {
    // if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
    //     ValidatorForm.addValidationRule('isPasswordMatch', (value) => {

    //         if (value !== this.state.password) {
    //             return false;
    //         }
    //         return true;
    //     });
    // }
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        return re.test(value);
      });
    }

    const terms_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllTerms";
    axios
      .get(terms_api_url, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        const sortingVendorsData = data.vendors.sort((a, b) => a.name.localeCompare(b.name));
        if (data.error) {
          this.setState({
            open: true,
            message: "Something went wrong in backend",
            successError: "error",
          });
        } else {
          const data1 = this.props.UserProfile;
          if (!data1.error) {
            var deliverymsg_time = "immediate";
            var auto_scan_events = "off";

            if (data1.data.deliverymsg_time) {
              deliverymsg_time = data1.data.deliverymsg_time;
            }
            if (data1.data.auto_scan_events) {
              auto_scan_events = data1.data.auto_scan_events;
            }

            this.setState({
              loader: false,
              vendors: sortingVendorsData,
              leagues: data.leagues,
              teams: data.teams,
              deliverymsg_time: deliverymsg_time,
              auto_scan_events: auto_scan_events,
            });
          } else {
            this.setState({
              loader: false,
              vendors: sortingVendorsData,
              leagues: data.leagues,
              teams: data.teams,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });
  }

  OpenRemoveModal = (index) => {
    this.setState({ currentIndex: index, modalArchiveShow: true });
  };

  handleChangeAutoScanForTicket = (event) => {
    this.setState({ auto_scan_events: event.target.checked ? "on" : "off" });
  }

  handleChangeScanAllForAcount = (event) => {
    this.setState({ isAccountScanAllFor: event.target.checked })
    this.setState({ auto_scan_events: event.target.checked ? "on" : "off" })
  }


  handleChangeScanAllForTicket = (event) => {
    this.setState({ eventScanCount: event.target.value });
  };

  onArchivePopupHide = () => {
    this.setState({ modalArchiveShow: false });
  };

  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChangeAccount = (e) => {
    this.setState({ accountTypeVal: e.target.innerText });
  };
  handleChangeVendor = (e, newVal) => {
    if (newVal) {
      // Destructure newVal to simplify code
      const { name, _id } = newVal;
      if (name === "Ticketmaster") {
        // Simplify code by extracting league from state and sorting teams in one line
        const league = this.state.leagues.find((l) => l.vendor === _id);
        const team = this.state.teams
          .filter((t) => t.league === league._id)
          .sort((a, b) => a.name.localeCompare(b.name));
        // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
        // Update state with new league and team
        this.setState({
          leagueVal: league.name,
          leagueID: league._id,
          teamNameVal: team[0].name,
          teamNameID: team[0]._id,
        });
        this.setState({
          auto_scan_events: this.state.auto_scan_events,
          scanEventName: this.state.scanEventName
        })
      } else if (name === "AXS") {
        // Reset league and team if vendor type is AXS
        this.setState({
          teamNameVal: "AXS",
          teamNameID: null,
          leagueID: null,
          leagueVal: "AXS",

        });
      } else {
        // Reset league and team if vendor type is neither Ticketmaster nor AXS
        this.setState({
          leagueID: null,
          leagueVal: null,
          teamNameVal: null,
          scanEventName: '',
          teamNameID: null,
          auto_scan_events: 'off'
        });
      }
      // Always update vendor type in state
      this.setState({
        vendorTypeVal: name,
        vendorTypeID: _id,
        scanEventName: ''
      });
    } else {
      // Reset all fields if newVal is null
      this.setState({
        vendorTypeVal: null,
        vendorTypeID: null,
        leagueID: null,
        leagueVal: null,
        teamNameVal: null,
        teamNameID: null,
        scanEventName: '',
        auto_scan_events: 'off'
      });
    }
  };

  handleChangeLeague = (e, newVal) => {
    if (newVal) {
      this.setState({
        leagueVal: newVal.name,
        leagueID: newVal._id,
        teamNameVal: null,
        teamNameID: null,
      });
    } else {
      this.setState({
        leagueVal: null,
        leagueID: null,
        teamNameVal: null,
        teamNameID: null,
      });
    }
  };

  handleChangeTeam = (e, newVal) => {
    if (newVal) {
      this.setState({ teamNameVal: newVal.name, teamNameID: newVal._id });
    } else {
      this.setState({ teamNameVal: null, teamNameID: null });
    }
  };

  handleChangePOS = (e) => {
    this.setState({ posTypeVal: e.target.innerText });
  };

  handleChangeMarket = (e) => {
    this.setState({ marketTypeVal: e.target.innerText });
  };

  handleChange = (e) => {
    if (e.target.value !== e.target.value.toLowerCase()) {
      this.setState({
        lowerEmailCheck: true,
        lowerEmailMsg: "Please enter email in lower case",
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        lowerEmailCheck: false,
        isbuttonLoader: false,
        lowerEmailMsg: "",
      });
    }
  };

  handleChangePass = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.setState({ open: false, message: "" });
  };

  handleCred = (e, index) => {
    const { logincreds } = this.state;
    logincreds[index][e.target.name] = e.target.value;
    this.setState(logincreds);
  };

  handleSubmit = async () => {
    this.setState({ isbuttonLoader: true })
    var temp = [];
    var finalArr = [];
    this.state.logincreds.forEach(function myFunction(item, index) {
      temp.forEach(function myFunction(itemT, indexT) {
        if (itemT.email?.trim() === item.email?.trim()) {
          finalArr.push(item);
        }
      });
      temp.push(item);
    });
    if (finalArr.length > 0) {
      this.setState({
        isbuttonLoader: false,
        open: true,
        message:
          "Entered records have duplicate emails with the same team, Please remove and try again!",
        successError: "error",
      });
    } else if (finalArr.length === 0) {
      const data = {
        account_type: this.state.accountTypeVal,
        vendor: this.state.vendorTypeVal,
        league: this.state.leagueVal,
        team: this.state.teamNameVal,
        logincreds: this.state.logincreds,
        scanEventName: this.state.scanEventName,
        auto_scan_events: this.state.auto_scan_events,
        eventScanCount: this.state.eventScanCount,
      };
      const addAccount_api_url = process.env.REACT_APP_API_URL + "/api/account/addbulk";
      await axios
        .post(addAccount_api_url, data, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;

          if (data.error) {
            this.setState({
              loader: false,
              isbuttonLoader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });

            setTimeout(() => {
              this.props.history.push("/vendor-list");
              this.setState({ isbuttonLoader: false })
            }, 1000);
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            isbuttonLoader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    }
  };


  addLogincred = () => {
    console.log("this", this.state.message, this.state.open)
    const { logincreds } = this.state;
    if (logincreds.length !== 10 && logincreds.length < 10) {
      logincreds.push({ email: "", password: "" });
      this.setState(logincreds);
    } else {
      console.log("isError")
      this.setState({
        open: true,
        message: "You can add maximum 10 accounts at a time",
        successError: "error",
      });
    }
  };

  removeLoginCred = () => {
    const { logincreds } = this.state;
    logincreds.splice(this.state.currentIndex, 1);
    this.setState(logincreds);
    this.setState({ modalArchiveShow: false, currentIndex: null });
  };

  handelEventNameOnChange = (event) => {
    this.setState({ scanEventName: event.target.value });
  }

  render() {
    const { vendors, leagues, teams } = this.state;
    // Destructure the state object
    const { vendorTypeID, vendorTypeVal, leagueID, leagueVal, teamNameVal } = this.state;
    // Check if the vendor type is not Ticketmaster or AXS
    const shouldRenderLeagueSelect =
      vendorTypeID !== null &&
      vendorTypeVal !== "Ticketmaster" &&
      vendorTypeVal !== "AXS";
    // Check if the league select should be rendered
    const shouldRenderTeamSelect =
      leagueID !== null &&
      vendorTypeVal !== "Ticketmaster" &&
      vendorTypeVal !== "AXS";
    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Add vendor</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          title="Add Accounts"
                          action={
                            <Grid
                              container
                              spacing={{ xs: 1, md: 1, lg: 1 }}
                              columns={{ xs: 4, sm: 8, md: 8 }}
                              className="button-align"
                            >
                              <Grid item>
                                <Button
                                  onClick={() => this.props.history.goBack()}
                                  type="button"
                                  size="large"
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                  }}
                                  startIcon={<ArrowBack />}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </Grid>
                          }
                        />
                        <CardContent>
                          <ValidatorForm onSubmit={() => this.handleSubmit()}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group select-outer">
                                  <label className="Account_details_box">
                                    Account type
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="accountTypeVal"
                                    value={this.state.accountTypeVal}
                                  />
                                </div>
                              </Grid>

                              <Grid item xs={12} sm={5}>
                                <div className="form-group select-outer">
                                  <label className="Account_details_box">
                                    Choose Vendor
                                  </label>
                                  <Autocomplete
                                    onChange={this.handleChangeVendor}
                                    fullWidth
                                    name="vendorTypeVal"
                                    options={vendors}
                                    getOptionLabel={(option) => option.name}
                                    getOptionDisabled={(option) => option.is_active === 0
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        value={this.state.vendorTypeVal}
                                        placeholder="Select Vendor"
                                        {...params}
                                        variant="outlined"
                                        validators={["required"]}
                                        errorMessages={
                                          "This field is required"
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>

                              {this.state.vendorTypeVal === "AXS" && (
                                <Grid item xs={12} sm={6}>
                                  <div className="form-group select-outer">
                                    <div
                                      style={{
                                        textAlign: "left",
                                        paddingBottom: "5px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <label className="Account_details_box">
                                        League
                                      </label>
                                    </div>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      name="leagueVal"
                                      value="AXS"
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>
                              )}
                              {this.state.vendorTypeVal === "Ticketmaster" && (
                                <Grid item xs={12} sm={6}>
                                  <div className="form-group select-outer">
                                    <Grid item xs={12} sm={6} sx={{ marginLeft: '2px' }} >
                                      <FormControl>
                                        <FormControlLabel
                                          className="Account_details_box"
                                          control={
                                            <Android12Switch
                                              checked={this.state.auto_scan_events === "on" ? true : false}
                                              onChange={(e) => this.handleChangeAutoScanForTicket(e)}
                                              inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                          }
                                          label="Auto Scan"
                                        />
                                      </FormControl>
                                    </Grid>
                                  </div>
                                </Grid>
                              )}

                              {this.state.vendorTypeVal === "Ticketmaster" && this.state.auto_scan_events === "on" ? (
                                <Grid item xs={12} sm={5}>
                                  <div className="form-group select-outer">
                                    <div
                                      style={{
                                        textAlign: "left",
                                      }}
                                    >
                                      <label className="Account_details_box">
                                        Event Name
                                      </label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        name="scanEventName"
                                        placeholder="Event Name"
                                        value={this.state.scanEventName}
                                        onChange={this.handelEventNameOnChange}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              ) : (
                                null
                              )}

                              {this.state.vendorTypeVal === "AXS" && (
                                <Grid item xs={12} sm={5}>
                                  <div className="form-group select-outer">
                                    <div
                                      style={{
                                        textAlign: "left",
                                        paddingBottom: "5px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <label className="Account_details_box">
                                        Team
                                      </label>
                                    </div>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      name="leagueVal"
                                      value="AXS"
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>
                              )}

                              {shouldRenderLeagueSelect && (
                                <>
                                  <Grid item xs={12} sm={6}>
                                    <div className="form-group select-outer">
                                      <div
                                        style={{
                                          textAlign: "left",
                                          paddingBottom: "5px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <label className="Account_details_box">
                                          League
                                        </label>
                                      </div>
                                      <Autocomplete
                                        onChange={this.handleChangeLeague}
                                        fullWidth
                                        id="combo-box-demo"
                                        name="leagueVal"
                                        options={leagues.filter(
                                          (l) => l.vendor === vendorTypeID
                                        )}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                          <TextValidator
                                            value={leagueVal}
                                            placeholder="Select League"
                                            {...params}
                                            variant="outlined"
                                            validators={["required"]}
                                            errorMessages={
                                              "This field is required"
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                    <br />
                                    <Grid item xs={12} sm={12} sx={{ marginLeft: '4px', }}>
                                      <FormControlLabel
                                        className="Account_details_box"
                                        sx={{
                                          height: '47px',
                                        }}
                                        control={
                                          <Android12Switch
                                            checked={this.state.isAccountScanAllFor}
                                            onChange={this.handleChangeScanAllForAcount}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                          />
                                        }
                                        label="Auto Scan"
                                      />
                                      {this.state.isAccountScanAllFor &&
                                        <FormControl sx={{ marginTop: '3px' }}>
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={this.state.eventScanCount}
                                            onChange={(e) => this.handleChangeScanAllForTicket(e)}
                                          >
                                            <FormControlLabel value="tenEvents" control={<Radio />} label="First 10 Events (Recommended)" />
                                            <FormControlLabel disabled={true} value="all" control={<Radio />} label="All Events" />
                                          </RadioGroup>
                                        </FormControl>
                                      }
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              {/* Render the team select if it should be rendered */}
                              {shouldRenderTeamSelect && (
                                <Grid item xs={12} sm={5}>
                                  <div className="form-group select-outer">
                                    <div
                                      style={{
                                        textAlign: "left",
                                        paddingBottom: "5px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <label className="Account_details_box">
                                        Team
                                      </label>
                                    </div>
                                    <Autocomplete
                                      onChange={this.handleChangeTeam}
                                      fullWidth
                                      id="combo-box-demo"
                                      name="teamNameVal"
                                      options={teams
                                        .filter((t) => t.league === leagueID)
                                        .sort((a, b) =>
                                          a.name.localeCompare(b.name)
                                        )}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextValidator
                                          value={teamNameVal}
                                          placeholder="Select Team"
                                          {...params}
                                          variant="outlined"
                                          validators={["required"]}
                                          errorMessages={
                                            "This field is required"
                                          }
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>
                              )}

                              {this.state.logincreds.map((cred, index) => (
                                <React.Fragment key={index}>
                                  <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={6}>
                                        <div
                                          style={{
                                            textAlign: "left",
                                            paddingBottom: "14px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            {index === 0 && (
                                              <label className="Account_details_box">
                                                Email
                                              </label>)}
                                          </div>
                                          <TextValidator
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) =>
                                              this.handleCred(e, index)
                                            }
                                            placeholder="Enter Email"
                                            name="email"
                                            value={cred.email}
                                            validators={[
                                              "required",
                                              "isEmail",
                                            ]}
                                            errorMessages={[
                                              "This field is required",
                                              "Email is not valid",
                                            ]}
                                          />

                                          {this.state.error.email && (
                                            <div className="error-msg">
                                              {this.state.error.email}
                                            </div>
                                          )}
                                          {this.state.lowerEmailCheck && (
                                            <div
                                              style={{
                                                color: "red",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {" "}
                                              {this.state.lowerEmailMsg}{" "}
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item xs={12} sm={5}>
                                        <div className="">
                                          <div
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            {index === 0 && (
                                              <label className="Account_details_box">
                                                Password
                                              </label>
                                            )}
                                          </div>
                                          <PasswordField
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) =>
                                              this.handleCred(e, index)
                                            }
                                            placeholder="Enter Password"
                                            name="password"
                                            value={cred.password}
                                            validators={[
                                              "required",
                                              "PasswordStrength",
                                            ]}
                                            errorMessages={[
                                              "This field is required",
                                              "password must be at least 6 character",
                                            ]}
                                            eyestyle={{
                                              position: "absolute",
                                              right: "20px",
                                              top: "13px",
                                            }}
                                            autoComplete="new-password"
                                          />
                                          {this.state.error.password && (
                                            <div className="error-msg">
                                              {this.state.error.password}
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={1}
                                        className="sub_add"
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {this.state.logincreds &&
                                          index === 0 ? (
                                          ""
                                        ) : (
                                          <IconButton
                                            aria-label="delete"
                                            type="button"
                                            sx={{
                                              color: "#BF3E3E",
                                              fontSize: "30px",
                                            }}
                                            onClick={() =>
                                              this.OpenRemoveModal(index)
                                            }
                                          >
                                            <RemoveCircleOutlineIcon
                                              sx={{
                                                fontSize: "1.8rem",
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              ))}
                              <Grid item xs={12} className="plus_add">
                                <button
                                  type="button"
                                  onClick={() => this.addLogincred()}
                                  className="add-more"
                                >
                                  <AddCircleOutlineIcon /> Add More Accounts
                                </button>
                              </Grid>
                              <br />
                            </Grid>
                            <Grid item xs={12} sm={12}
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <LoadingButton
                                type="submit"
                                size="large"
                                loading={this.state.isbuttonLoader}
                                endIcon={this.state.isbuttonLoader ? <CircularProgress color="inherit" size={20} /> : ''}
                                disabled={this.state.isbuttonLoader}
                                className={`${this.state.isbuttonLoader ? 'theme-disabled' : "theme-btn"}`}
                                loadingPosition="end"
                                variant="contained"
                              >
                                <span>Add Account</span>
                              </LoadingButton>
                            </Grid>
                          </ValidatorForm>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                </main>
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={this.state.modalArchiveShow}
                  onClose={this.onArchivePopupHide}
                >
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onArchivePopupHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to remove this ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.removeLoginCred}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={this.onArchivePopupHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </div >
            </div >
          </div >
          <Footer />
        </div >
      </>
    );
  }
}
// to dispatch user name

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addvendor);
