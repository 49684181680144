import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//mui  component
import { Card, CardContent, Container, Grid } from '@mui/material'

// Component
import Commonheader from 'components/common-header/common-headre'
import Footer from 'components/footer/footer'
import Header from 'components/header/header'
import useAlertMessage from 'Hooks/useAlertMessage'
import { useUserAPICollection } from 'Hooks/useUserAPICollection'
import AlertMessage from 'components/message/message'
import { ContainerLoader } from 'components/common/ContainerLoader'
import Cookies from 'js-cookie'
import { useHistory } from "react-router-dom";

const EndUserLicenseAgreementUserPanel = () => {
    const history = useHistory()
    const { getEndUserLicenseAgreementAPI } = useUserAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const getEULA = async () => {
        try {
            setIsLoading(true);
            const res = await getEndUserLicenseAgreementAPI();
            const { data } = res;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setContent(data?.data && data?.data?.text)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getEULA();
    }, [])

    return (
        <>
            <Helmet>
                <title>End User License Agreement</title>
            </Helmet>
            <Header />
            {!isLoading ? (
                <Container>
                    <div className="common-header-padding">
                        <Commonheader title="End User License Agreement" description="" />
                        {!content ? (
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} className="center">
                                    <Card>
                                        <CardContent
                                            sx={{
                                                p: 30,
                                            }}
                                        >
                                            <h4 style={{ marginTop: "-119px" }}>
                                            (EULA)Content Not Found
                                            </h4>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} className="center">
                                    <Card>
                                        <CardContent
                                            sx={{
                                                p: 5,
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: content,
                                            }}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Container>
            ) : (
                <ContainerLoader />
            )}
            <Footer />
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default EndUserLicenseAgreementUserPanel;