import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import AlertMessage from "components/message/message";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { HiOutlineSearch } from "react-icons/hi";
import Pagination from "components/pagination/pagination";
import { ContainerLoader } from "components/common/ContainerLoader";
import { Cancel } from "@mui/icons-material";

class Profiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {
        name: '',
        reported: 'all',
        skip: 0,
        limit: 10
      },
      pageLimit: 10,
      isReoprtVisiableConfirmationPopup: false,
      totalrecords: 0,
      searchPage: 0,
      users: [],
      lists: [],
      modalShow: false,
      profiledetail: null,
      loader: true,
      userId: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.handleCloseReportModel = this.handleCloseReportModel.bind(this)
    this.handleOpenReportModel = this.handleOpenReportModel.bind(this)
  }

  componentDidMount() {
    this.getlists();
  }

  handleCloseReportModel = () => {
    this.setState({ isReoprtVisiableConfirmationPopup: false })
  }

  handleOpenReportModel = (id) => {
    this.setState({ isReoprtVisiableConfirmationPopup: true, userId: id })
  }

  getlists = () => {
    this.setState({ loader: true })
    const browser_profiles_api_url =
      process.env.REACT_APP_API_URL + "/api/broswerprofile/admin/getAll";
    axios
      .post(browser_profiles_api_url, this.state.filterData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
            totalrecords: data.total
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleReportUser = (id) => {
    this.setState({ isReoprtVisiableConfirmationPopup: false, loader: true })
    const params = { _id: id }
    const browser_profiles_api_url = process.env.REACT_APP_API_URL + "/api/browserprofile/admin/report";
    axios
      .post(browser_profiles_api_url, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
            // isReoprtVisiableConfirmationPopup: false
          }, () => {
            this.getlists();
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  setModalShow = (item) => {
    this.setState({ modalShow: true, profiledetail: item });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  handleClosemessage = () => {
    this.setState({ message: "", open: false });
  };
  handleChange(e) {
    const { filterData } = this.state;
    filterData[e.target.name] = e.target.value;
    this.setState({ filterData });
  }

  handleSubmit() {
    const { filterData } = this.state;
    filterData.skip = 0;
    this.setState({ searchPage: 1, loader: true, filterData }, () => {
      this.getlists();
    });
  }

  onPageChanged(data) {
    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    filterData.skip = (currentPage - 1) * pageLimit;
    this.setState({ loader: true, filterData }, () => {
      this.getlists();
    });
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  changePageLimit = (e) => {
    const { filterData } = this.state;
    filterData.limit = parseInt(e.target.value);
    filterData.skip = 0;
    this.setState(
      {
        searchPage: 1,
        loader: true,
        filterData,
        pageLimit: parseInt(e.target.value),
      },
      () => {
        this.getlists();
      }
    );
  };


  render() {
    const { loader, lists, profiledetail, modalShow, filterData, totalrecords, userId } = this.state;
    const fields = [
      { key: "user", label: "User" },
      { key: "profile_name", label: "Profile Name" },
      { key: "operating_system", label: "Operating System" },
      { key: "reported", label: "Reported" },
      { key: "action", label: "Action" },
    ];
    const actionbutton = {
      backgroundColor: "#002986!important",
      color: "white!important",
      padding: "2px 5px!important",
      textTransform: "none!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
    };
    return (
      <>
        <Helmet>
          <title>Profiles</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Profiles" />
              <CardContent className="plan-tbl">
                <ValidatorForm
                  onSubmit={() => this.handleSubmit()}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 2, lg: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={2}
                        sx={{ textAlign: "left" }}
                      >
                        <label>Profile Name</label>
                        <TextValidator
                          variant="outlined"
                          onChange={this.handleChange}
                          name="name"
                          value={filterData.name}
                          placeholder="Profile Name"
                          className="filter-input"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={2}
                        sx={{ textAlign: "left" }}
                      >
                        <FormControl
                          fullWidth
                          sx={{ textAlign: "left" }}
                        >
                          <label>Report</label>
                          <Select
                            name="reported"
                            onChange={(e) => this.handleChange(e)}
                            placeholder="Report"
                            size="small"
                            defaultValue="all"
                            value={filterData.reported}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        md={1.4}
                        lg={2}
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                          paddingTop: "44px!important",
                        }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="filter-btn"
                          startIcon={<HiOutlineSearch />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
                {!loader ? (
                  <>
                    <DataTable
                      items={lists}
                      itemsPerPage={this.state.pageLimit ?? 10}
                      fields={fields}
                      scopedSlots={{
                        user: (item) => {
                          return item?.user?.email ?? null;
                        },
                        profile_name: (item) => {
                          return item?.profile_name ?? null;
                        },
                        operating_system: (item) => {
                          return item?.operating_system?.name ?? null;
                        },
                        reported: (item) => {
                          return item?.reported ?? null;
                        },
                        action: (item) => {
                          return (
                            <div className="flex">
                              <Button
                                size="small"
                                variant="contained"
                                sx={actionbutton}
                                onClick={() => this.setModalShow(item)}
                              >
                                View
                              </Button>
                              <Button
                                onClick={() => this.handleOpenReportModel(item._id)}
                                size="small"
                                variant="contained"
                                sx={actionbutton}
                              >
                                Report
                              </Button>
                            </div>
                          );
                        },
                      }}
                    />
                  </>
                ) : (<ContainerLoader />)}
                {lists && lists.length > 0 ? (
                  <>
                    {totalrecords > 0 && (
                      <Pagination
                        totalRecords={totalrecords}
                        searchPage={this.state.searchPage ?? 0}
                        SearchDisable={this.SearchDisable}
                        pageLimit={this.state.pageLimit ?? 10}
                        onPageChanged={this.onPageChanged}
                      />
                    )}
                    <div
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <label>Items per page :</label>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 50,
                        }}
                        size="small"
                      >
                        <Select
                          defaultValue={filterData.limit}
                          value={filterData.limit}
                          name="limit"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "4px 14px",
                              fontFamily: "Barlow",
                            },
                          }}
                          onChange={(e) => this.changePageLimit(e)}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                ) : (null)}
                <Dialog
                  fullWidth={true}
                  scroll="body"
                  maxWidth="md"
                  open={modalShow}
                  onClose={this.onHide}
                >
                  <DialogContent sx={{ padding: "5px 24px" }}>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    {profiledetail && (
                      <>
                        <DialogTitle sx={{ padding: "15px 0px 0px 0px", fontSize: "17px" }}>
                          Profile Details
                        </DialogTitle>
                        <Divider />
                        <div style={{ padding: "2px 3px 8px" }}>
                          <strong>Profile Name: </strong>
                          {profiledetail?.profile_name ?? null}
                          <br />
                          <strong>Operating System: </strong>
                          {profiledetail?.operating_system?.name ?? null}
                          <br />
                          <strong>IP Address: </strong>
                          {profiledetail?.ip_address ?? null}
                          <br />
                          <strong>Proxy: </strong>
                          {profiledetail.profile_detail.proxy &&
                            profiledetail.profile_detail.proxy.host ? (
                            <>
                              {profiledetail.profile_detail.proxy.host}:
                              {profiledetail.profile_detail.proxy.port}:
                              {profiledetail.profile_detail.proxy.username}:
                              {profiledetail.profile_detail.proxy.password}
                            </>
                          ) : (
                            <>False</>
                          )}
                          <br />
                          <strong>Geolocation: </strong>
                          {profiledetail.profile_detail.geolocation
                            ? "True"
                            : "False"}
                          <br />
                          <strong>webgl: </strong>
                          {profiledetail.profile_detail.webgl
                            ? "True"
                            : "False"}
                          <br />
                          {profiledetail.profile_detail.navigator && (
                            <>
                              {" "}
                              <strong>User Agent: </strong>
                              {
                                profiledetail.profile_detail.navigator
                                  .user_agent
                              }
                              <br />
                              <strong>Hardware Concurrency: </strong>
                              {
                                profiledetail.profile_detail.navigator
                                  .hardware_concurrency
                              }
                              <br />
                              <strong>Device Memory: </strong>
                              {
                                profiledetail.profile_detail.navigator
                                  .device_memory
                              }
                              <br />
                              <strong>Screen Resolution: </strong>
                              {
                                profiledetail.profile_detail.navigator
                                  .screen_resolution
                              }
                              <br />
                            </>
                          )}

                          {profiledetail.profile_detail.hardware && (
                            <>
                              <strong>WebGL Unmark Vender: </strong>
                              {
                                profiledetail.profile_detail.hardware
                                  .WebGL_unmark_vender
                              }
                              <br />
                              <strong>WebGL Unmark Renderor: </strong>
                              {
                                profiledetail.profile_detail.hardware
                                  .WebGL_unmark_renderor
                              }
                              <br />
                            </>
                          )}
                        </div>
                        <DialogTitle sx={{ padding: "1px 0px!important", fontSize: "17px" }}>
                          License Details
                        </DialogTitle>
                        <Divider />
                        <div style={{ padding: "2px 3px 8px" }}>
                          <strong>NickName: </strong>
                          {profiledetail?.nickname ?? null}
                          <br />
                          <strong>Email: </strong>
                          {profiledetail?.user?.email ?? null}
                          <br />
                          <strong>License Key: </strong>
                          {profiledetail?.licId?.license_key ?? null}
                        </div>
                        <DialogTitle sx={{ padding: "1px 0px!important", fontSize: "17px" }}>
                          Report Details
                        </DialogTitle>
                        <Divider />
                        <div style={{ padding: "2px 3px 17px" }}>
                          <strong>Reported: </strong>
                          {profiledetail?.reported ?? null}
                          <br />
                          <strong>Report Note: </strong>
                          {profiledetail?.report_note ?? null}
                          <br />
                          <strong>Report Browser Version: </strong>
                          {profiledetail?.report_bversion ?? null}
                        </div>
                      </>
                    )}
                  </DialogContent>
                </Dialog>
                {/* {report  confimation model} */}
                <Dialog
                  maxWidth="sm"
                  open={this.state.isReoprtVisiableConfirmationPopup}
                  onClose={this.handleCloseReportModel}
                  sx={{
                    "& .MuiDialog-paper": {
                      width: "100%",
                    },
                  }}
                >
                  <DialogTitle>Report</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={this.handleCloseReportModel}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <Cancel />
                    </IconButton>
                    <DialogContentText
                      sx={{
                        fontFamily: "Barlow",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "black",
                      }}
                    >
                      Are you sure you want to Report ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleCloseReportModel}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => this.handleReportUser(userId)}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Report
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AlertMessage
          open={this.state.open}
          message={this.state.message}
          severity={this.state.successError}
          closeCall={this.handleClosemessage}
        />
      </>
    );
  }
}
export default Profiles;
