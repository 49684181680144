import React from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Select from '@mui/material/Select';
import axios from "axios"
import Button from '@mui/material/Button';
import { CCol, CRow } from "@coreui/react";
import "./Message.css";
import Group from "../../../images/Group.png"
import moment from "moment";
import arrowdown2 from "../../../images/arrow-down2.png"
import { FormControl, MenuItem } from "@mui/material";

class Message extends React.Component {

constructor(props) {
  super(props)
  this.state = {
    eventData: [],
    successError: "",
    loader: false,
    sendmessagedate: {
      id:this.props.location?.pathname?.split("/")[2],
      template: '',
      message: '',
    },
    sendMessageModal: false,
    openMenu:false
  }
  this.handleChangeSendmessage=this.handleChangeSendmessage.bind(this)
  this.sendMessageSubmit=this.sendMessageSubmit.bind(this)
  this.changeStatus=this.changeStatus.bind(this)
  this.handleOpenMenu = this.handleOpenMenu.bind(this)
}

  

  componentDidMount() {
    this.setState({ loader: true },
      this.getSoldInventoryDetail())
  }

  // componentDidUpdate(){
  //   this.setState({loader:true} , 
  //     ()=>{this.getSoldInventoryDetail()})
  // }
  handleOpenMenu= () =>{
    this.setState({openMenu:!this.state.openMenu})
  }

  getSoldInventoryDetail() {
    const sendmessage_api_url = process.env.REACT_APP_API_URL + "/api/commpanel/ProblemOrderDetail"
    axios.post(sendmessage_api_url, { id: this.props.location?.pathname?.split("/")[2] }, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenCommunication') } })
      .then(response => {
        const { data } = response
        if (data.data) {
          this.setState({ eventData: data.data, loader: false })
        }
      })
      .catch(err => {
        console.log(err.response)
        this.setState({ loader: false })
      })
  }

  sendMessageSubmit = () => {
    const { sendmessagedate } = this.state;
    sendmessagedate.submit = true;
    this.setState({ sendmessagedate }, () => {
      const sendmessage_api_url = process.env.REACT_APP_API_URL + "/api/commpanel/sendMessageCommunication"
      axios.post(sendmessage_api_url, this.state.sendmessagedate, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenCommunication') } }).then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({ loader: false, open: true, message: data.message, successError: 'error' })
        }
        else {
          this.getSoldInventoryDetail()
          this.state.sendmessagedate.template=""
          this.state.sendmessagedate.message=""
          this.setState({ loader: false, open: false, message: 'Send message successfully', successError: 'success'  ,sendmessagedate});
          this.handleSubmit();
        }
      }).catch((error) => {
        this.setState({ loader: false, open: true, message: "Something went wrong", successError: 'error' })
      });
    });
  }

  changeStatus(e){
    const sendmessage_api_url = process.env.REACT_APP_API_URL + "/api/commpanel/updateStatus";
    axios.post(sendmessage_api_url, {_id:this.props.location?.pathname?.split("/")[2] ,status:e.target.value}, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenCommunication') } })
    .then(response=>{
      if(response.data){
        this.getSoldInventoryDetail()
      }
      else{
        console.log(response)
      }
    })
    .catch(err=>{
      console.log(err)
    })
  }

  opensendMessageModel = (data) => {
    console.log(data);
    const { sendmessagedate } = this.state;
    sendmessagedate.id = data.id;
    sendmessagedate.customerphone = data.customerPhone;
    sendmessagedate.template = 'sold';
    this.setState({ sendMessageModal: true, sendmessagedate })
  }

  handleChangeSendmessage(e){
    const { sendmessagedate } = this.state;
    sendmessagedate[e.target.name] = e.target.value;
    this.setState({ sendmessagedate } , ()=>{console.log(sendmessagedate)})
  }

  sendMessageModalHide = () => {
    const sendmessagedate = { id: '', template: '', message: '', customerphone: '' };
    this.setState({ sendMessageModal: false, sendmessagedate })
  }



  render() {
    const { sendmessagedate, loader, eventData } = this.state
    eventData?.twiliomessages?.sort(function (a, b) {
      var keyA = new Date(a.sentat),
        keyB = new Date(b.sentat);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return (
      <div>
        {!loader &&
          //  Array.isArray(this.state.eventData) && this.state.eventData.length &&
          <div>
            <CRow style={{ padding: "15px", background: "#ffff" }}>
              <CCol lg="4" md="4" sm="6">
                <div className="sold_inventory_box">
                  <h3>Sold Inventory Details</h3>
                </div>
              </CCol>
              <CCol lg="5" md="5">
               
              </CCol>
              <CCol lg="3" md="3" className="btn_box text-right arrow_box">
                <div className="arrow_down"><img src={arrowdown2} alt=""/></div>
                <select className="form-select" aria-label="Default select example" onChange={this.changeStatus}>
                  <option selected disabled>Change Status</option>
                  <option value="mannual">Need Manul Review</option>
                  <option value="confirmed">Confirmed Order</option>
                </select>
              </CCol>
            </CRow>

            <CRow >

              <CCol style={{ overflow: "auto" }} lg="5" md="4" className="Inventory_details">
                <div className="Description_box">
                  <h6>
                    {console.log(eventData.event)}
                    Event:<span>{eventData?.event !== undefined && eventData.event[0].name}</span>
                  </h6>
                  <h6>
                    Event Date:<span>{eventData?.event !== undefined && eventData.event[0].date}</span>
                  </h6>
                  <h6>
                    Marketplace:<span>{eventData?.event !== undefined && eventData.customerDisplayName}</span>
                  </h6>
                  <h6>
                    External Ref:<span> {eventData?.invoiceExternalRef !== undefined && eventData.invoiceExternalRef}</span>
                  </h6>
                  <h6>
                    Team:<span>{eventData?.event !== undefined && eventData.event[0].performer.name}</span>
                  </h6>
                  <h6>
                    Date:<span>{eventData?.invoiceDate !== undefined && eventData.invoiceDate}</span>
                  </h6>
                  <h6>
                    Section:<span>{eventData?.event !== undefined && eventData?.section}</span>
                  </h6>
                  <h6>
                    Row:<span>{eventData?.event !== undefined && eventData?.row}</span>
                  </h6>
                  <h6>
                    Seat:<span>{eventData?.event !== undefined && eventData?.seatNumbers}</span>
                  </h6>
                  <h6>
                    Phone Number:<span>{eventData?.event !== undefined && eventData?.customerPhone}</span>
                  </h6>
                  <h6>Status:<span>
                  {eventData?.delivery_status === 0 && "No message sent"}
                {eventData?.delivery_status === 1 &&  "Sold message sent"}
                {eventData?.delivery_status === 2 && "Delivery message sent"}
                {eventData?.delivery_status === 3 && "Confirmation message sent"}
                {eventData?.delivery_status === 4 && "Order Confirmed"}
                {eventData?.delivery_status === 5 && "Problem in Order"}
                {eventData?.delivery_status === 6 && "Order Confirmed"}
                {eventData?.delivery_status === 7 && "Need Manual Review"}
                {eventData?.delivery_status === 8 && "Phone number Blacklisted"}
                {eventData?.delivery_status === 9 && "Phone number Invalid"}
                {eventData?.delivery_status === 10 && "No ticket found"}
                {eventData?.delivery_status === 11 && "Manually Transferred"}
                {eventData?.delivery_status === 12 && "Delivery Revoked"}
                {eventData?.delivery_status === 13 && "Ticket Uploaded"}
                {eventData?.delivery_status === 14 && "Link Generated"}
                {eventData?.delivery_status === 15 && ">Event Reminder"}
                {eventData?.delivery_status === 16 && ">Ticket Not Uploaded"}
               
                                                                                     

                    </span></h6>
                </div>
              </CCol>

              <CCol lg="7" md="8" className="chat_wrap" >
                <div style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}>
                  {eventData?.twiliomessages?.map(e =>
                    <div className="" >
                      {e.sms_type === "delivered" &&
                        <CRow> 
                          <CCol lg="3" md="3" className="Reciever_box"></CCol>
                          <CCol lg="9" md="9" className="Reciever_box">
                            <div>{e?.message_body}</div>
                            <section><p>{moment(e?.sentat).format("DD-MM-YYYY")} {moment(e?.sentat).format("HH:mm")}</p></section>
                          </CCol>
                         
                        </CRow>
                      }
                      {e.sms_type === "received" &&
                        <CRow>
                          <CCol lg="9" md="9" className="Chat_box">
                            <div>{e.message_body}</div>
                            <p>{moment(e?.sentat).format("DD-MM-YYYY")} {moment(e?.sentat).format("HH:mm")}</p>
                          </CCol>
                          <CCol lg="3" md="3" className="Chat_box"></CCol>
                        </CRow>
                      }
                    </div>
                  )}
                </div>

                <ValidatorForm onSubmit={this.sendMessageSubmit}>
                {/* <CRow>
                <CCol className="bgg" md="10" lg="10" sm="10"></CCol>
                jkj
                </CRow> */}
                  <CRow className="Input_icons_box  message_box">
                    <CCol className="" md="10" lg="10" sm="10">
                      <div className="custom-pop-over-row">
                        <div className="form-group login-wrapper">
                        <FormControl onClick={this.handleOpenMenu} sx={{
                                    }} size="large">
                                        <Select
                                            fullWidth
                                            open={this.state.openMenu}
                                            onOpen={this.handleOpenMenu}
                                            name="template"
                                            defaultValue='sold'
                                            sx={{
                                               cursor:"pointer",
                                                "& .MuiSelect-select": {
                                                    
                                                    fontFamily: "Barlow",

                                                },
                                            }}
                                            onChange={(e) => this.handleChangeSendmessage(e)}
                                        >

                                            <MenuItem value="sold">Sold Message Template</MenuItem>
                                            <MenuItem value="delivery">Delivery Message Templete</MenuItem>
                                            <MenuItem value="confirmation">Confirmation Message Templete</MenuItem>
                                            <MenuItem value="custom">Custom Message</MenuItem>
                                            <MenuItem value="event_reminder">Event Reminder</MenuItem>
                                        </Select>
                                    </FormControl>
                      
                        </div>
                      </div>
                      {sendmessagedate.template === "custom" &&
                        <div className="custom-pop-over-row message_wrapper">
                          <div className="form-group message_wrapper">
                            <label>Message</label>
                            <TextValidator
                            className="message_wrapper"
                              variant="outlined"
                              name="message"
                              value={sendmessagedate.message}
                              onChange={(e) => this.handleChangeSendmessage(e)}
                              validators={['required']}
                              errorMessages={['this field is required']}
                            />
                          </div>
                        </div>
                      }
                    </CCol>
                    <CCol md="2" lg="2" sm="2" className="Send_" style={
                      {
                        display:"flex",
                        justifyContent:"center",
                        alignContent:"center",
                        alignItems:"baseline",
                        paddingTop:"13px"
                      }
                    }>
                      <img src={Group} style={{cursor:"pointer"}} alt="no images" onClick={this.sendMessageSubmit} className="Send_icon" />
                    </CCol>
                  </CRow>
                </ValidatorForm>
              </CCol>
            </CRow>
          </div>}
      </div>
    );
  }
}

export default Message;
