import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { ContainerLoader } from 'components/common/ContainerLoader';
import styled from '@emotion/styled';

// custom style  for data table of the Table Cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const CustomDataTable = ({
    isLoading,
    columns,
    data,
    actions,
    addBottomCell,
    totalRecords
}) => {
    return (
        <>
            {isLoading ? (
                <ContainerLoader />
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        {columns && columns.length > 0 && (
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell key={column?.field}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    minWidth: "max-content",
                                                }}
                                            >
                                                {column?.headerName}
                                            </div>
                                        </StyledTableCell>
                                    ))}
                                    {actions.length > 0 ? actions && <StyledTableCell>Actions</StyledTableCell> : null}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {data?.length > 0 ? (
                                data.map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        {columns.map((column) => (
                                            <StyledTableCell key={column?.field}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        minWidth: "max-content",
                                                    }}
                                                >
                                                    {row[column?.field] ?? "-"}
                                                </div>
                                            </StyledTableCell>
                                        ))}
                                        {actions.length > 0 ? actions && (
                                            <StyledTableCell>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        minWidth: "max-content",
                                                    }}
                                                >
                                                    {actions?.map((action, actionIndex) => (
                                                        <Button
                                                            key={actionIndex}
                                                            onClick={() => action.callback(row)}
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{
                                                                backgroundColor: "#002986!important",
                                                                color: "white!important",
                                                                padding: "2px 5px!important",
                                                                textTransform: "none!important",
                                                                fontWeight: "bold",
                                                                fontFamily: "Barlow!important",
                                                                margin: "0 5px",
                                                            }}
                                                        >
                                                            {action?.label ?? "-"}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </StyledTableCell>
                                        ) : null}
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        colSpan={columns.length + (actions ? 1 : 0)}
                                        align="left"
                                    >
                                        <h4>No Data Found</h4>
                                    </TableCell>
                                </TableRow>
                            )}
                            {data?.length > 0 && addBottomCell && (
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                        Total
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left"/>
                                    <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                        {totalRecords ?? 0}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};


CustomDataTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string.isRequired,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            callback: PropTypes.func.isRequired,
        })
    ),
    addBottomCell: PropTypes.bool.isRequired,
    totalRecords: PropTypes.number.isRequired,
};

CustomDataTable.defaultProps = {
    columns: [],
    data: [],
    actions: [],
    addBottomCell: false,
    totalRecords: 0
};

export default CustomDataTable;



