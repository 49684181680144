import React, { useState } from "react";
import Logo from "images/landingPageLogo.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { PersonOutlineOutlined, Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Drawer, List, styled,  } from "@mui/material";
import "./index.scss";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Cookies from "js-cookie";
// const pages = ["Home", "Features", "Roadmap", "Contact Us"];
const pages = ["Home", "Features", "Contact Us"];

const Header = ({ handleScrollDiv, isActive, handleClose }) => {
  const islogin = Cookies.get('accessToken');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }));
  
  const handleOpenNavMenu = (e) => {
    e.preventDefault();
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#24203F",
        height: "96px",
        display: "flex",
        justifyContent: "center",
        zIndex: 40,
        position: "relative",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className="header-wrapper">
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontSize: "1.5rem",
              fontWeight: 800,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div className="wrapper">
              <div className="header_logo">
                <img src={Logo} alt="logo" className="m-image" />
              </div>
            </div>
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
            className="hamburger-icon"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            className="logo-wrapper"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div className="wrapper">
              <div className="header_logo">
                <img src={Logo} alt="logo" className="m-image" />
              </div>
            </div>
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
            }}
            className="new_menu"
          >
            <ul className="list-items-btn" onClick={handleClose}>
              {pages.map((page, index) => (
                <li
                  onClick={(e) => handleScrollDiv(e, index)}
                  className={isActive === index ? `active` : ""}
                >
                  <Link to="#">{page}</Link>
                </li>
              ))}
            </ul>
          </Box>

          <Button
            size="sm"
            component={Link}
            startIcon={<PersonOutlineOutlined />}
            className="login-button"
            to={islogin ? `/user-dashboard` : `/login`}
          >
            {islogin ? "My Account" : "Login"}
          </Button>
        </Toolbar>
      </Container>

      <Drawer
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#241F3E", // Replace with your desired color value
          },
        }}
        open={isDrawerOpen}
        ModalProps={{ disableRestoreFocus: true }}
        disableRestoreFocus
        onClose={(e) => handleOpenNavMenu(e)}
      >
          <DrawerHeader>
          <IconButton onClick={(e) => handleOpenNavMenu(e)} >
             <ChevronLeftIcon sx={{
              color:"white"
             }} />
          </IconButton>
        </DrawerHeader>
        <List sx={{ width: 150 }} onClick={handleOpenNavMenu}>
          <ul className="list-items-btn-sider">
            {pages.map((page, index) => (
              <li
                onClick={(e) => handleScrollDiv(e, index)}
                className={isActive === index ? `active` : ""}
              >
                <Link to="#">{page}</Link>
              </li>
            ))}
          </ul>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Header;
