import React from 'react'
import {
  CHeader,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CLink
} from '@coreui/react'


import Logo from '../images/logo.png';
import './containerCommon.css';

import CommunicationHeader from './CommunicationHeader'

const ComHeader = () => {




  return (
    <CHeader withSubheader>
      <CLink href="/communication-panel">
        <img src={Logo} className="img-fluid sidebar-logo" alt=''/>
      </CLink>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/communication-panel">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <CommunicationHeader/>
      </CHeaderNav>
    </CHeader>
  )
}

export default ComHeader
