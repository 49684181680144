import { useAxios } from "./useAdminAxios";

export const useAdminAPICollection = () => {
    const { axiosRequest } = useAxios();

    const getManageBrowserApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/admin/manageBrowser", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getAllUsersApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/getAllUsers?accessbrowser=yes", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getAllUsersAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/getAllUsers", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getAllSettingDetail = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/setting/detail", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const updateAdminDetailApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/setting/update", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getManageBrowserDetailAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/admin/manageBrowserDayWise", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // for Affiliate company Category API
    const getAllAffiliateCompanyCategoryAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompanyCategory/getAll", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getSingleAffiliateCompanyCategoryAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompanyCategory/getSingle", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const addAffiliateCompanyCategoryAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompanyCategory/add", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const updateAffiliateCompanyCategoryAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompanyCategory/update", "patch", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const deleteAffiliateCompanyCategoryAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompanyCategory/delete", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }


    // Affiliate Company API
    const addAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/add", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getAllAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/getAll", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const deleteCompanyAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/delete", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getSingleAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/getSingle", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const updateAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/update", "patch", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    //renewlicense API
    const renewalLicenseAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/admin/renewLicenses", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }
    // Terms & Condition API
    const getAllTermsConsitionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/getAll", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getSingleTermsConditionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/getSingle", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const updateTermsConditionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/update", "patch", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const addTermsConditionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/add", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }


    const getAllLatestTermsConditionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/getLatest", "get", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getTermsConditionLogsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/getTncLogs", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // End User License Agreement api
    const getAllEulaAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/getAll", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getSingleEulaAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/getSingle", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const addEulaAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/add", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const updateEulaAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/update", "patch", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getLatestEulaAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/user/getLatestEula", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const getEulaLogsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/user/getEulaLogs", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    //package api
    const getAllPackagesAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/getAll", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }


    const getSinglePakagesAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/single", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const addPackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/add", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const upadtePackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/update", "patch", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const deletePackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/delete", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // users packages
    const allUserPackagesAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/allUserPackages", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    //getBrowserSessionsAPI
    const getBrowserSessionsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/admin/browserSessions", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    //user sessions  API
    const getUsersSessionsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/admin/listUserSessions", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }
    
    const deleteAllSessionsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/admin/userSessionLogout", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    return {
        getManageBrowserApi,
        getAllUsersApi,
        getAllSettingDetail,
        updateAdminDetailApi,
        getManageBrowserDetailAPI,
        // company affiliate
        addAffiliateAPI,
        getAllAffiliateAPI,
        getSingleAffiliateAPI,
        deleteCompanyAffiliateAPI,
        updateAffiliateAPI,
        // affiliate company categories api
        getAllAffiliateCompanyCategoryAPI,
        addAffiliateCompanyCategoryAPI,
        deleteAffiliateCompanyCategoryAPI,
        getSingleAffiliateCompanyCategoryAPI,
        updateAffiliateCompanyCategoryAPI,
        //Renewal api
        renewalLicenseAPI,
        // Terms & Condition API
        getAllTermsConsitionAPI,
        getSingleTermsConditionAPI,
        updateTermsConditionAPI,
        addTermsConditionAPI,
        getAllLatestTermsConditionAPI,
        getTermsConditionLogsAPI,
        // eula
        getAllEulaAPI,
        addEulaAPI,
        updateEulaAPI,
        getSingleEulaAPI,
        getLatestEulaAPI,
        getEulaLogsAPI,
        //package
        getAllPackagesAPI,
        addPackageAPI,
        upadtePackageAPI,
        getSinglePakagesAPI,
        deletePackageAPI,
        getAllUsersAPI,
        allUserPackagesAPI,
        // browser sessions
        getBrowserSessionsAPI,
        // user sessions
        getUsersSessionsAPI,
        deleteAllSessionsAPI
    };
};
