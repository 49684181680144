import React, { Component } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer'
import './welcome.css';
class Welcome extends React.Component {
    state={
        email:'',
        paid:'',
        payerID:'',
        paymentID:'',
        paymentToken:'',
        returnUrl:''
    }
    componentDidMount(){
        if(this.props.history.location.state.response.paid){
            const data = this.props.history.location.state.response;
            this.setState({
                email:data.email,
                paid:data.paid,
                payerID:data.payerID,
                paymentID:data.paymentID,
                paymentToken:data.paymentToken,
                returnUrl:data.returnUrl
            })
        }
        else{

        }
      
    }
    render() {
        const { email, paid, payerID, paymentID, paymentToken, returnUrl} = this.state;
        return (
            <>
                <Helmet>
                    <title>Welcome</title>
                    <body className="Welcome-body" />
                </Helmet>

                <Header />

                <Container>
                    <div className="welcome-wrapper welcome-center">
                        <Row style={{ marginTop: '80px' }}>
                            <Col lg="12" md="12">
                                <h1 className="">Package Is Selected Successfully</h1>

                                {/* <Button
                                    variant="contained"
                                    type="submit"
                                    className="common-btn"

                                // disabled={submitted}
                                >
                                    <Link to="/login">Continue Login....</Link>
                                </Button> */}
                                <div>
                                    <ul style={{listStyle:'none', }}>
                                        <li>
                                       Email:     {email}
                                        </li>
                                        <li>
                                          {paid} 
                                        </li>
                                        <li>
                                        paidID:   {payerID}   
                                        </li>
                                        <li>
                                        PaymentID:    {paymentID}
                                        </li>
                                        <li>
                                        PaymentToken:    {paymentToken}
                                        </li>
                                        <li>
                                        Url:    {returnUrl}
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>

                <Footer className="welcome-footer" />

            </>
        )
    }
}
export default Welcome;