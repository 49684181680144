import React from 'react';
import { Row, Col, Container} from 'react-bootstrap'; 
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer'
import '../welcome/welcome.css';
import { Box, CircularProgress } from '@mui/material';


class Thanks extends React.Component{
    state={
        price:'',
        paymentId:'',
        dateCreated:'',
        loader:false
    }
    componentDidMount(){
        this.setState({
            loader:true
        })
        if(this.props.history.location.state === undefined) {
            console.log("Not getting data from success payment");
            this.setState({
                loader:false
            })
        }
        else{
            this.setState({
                loader:false,
                price:this.props.location.state.response.payment.price,
                paymentId:this.props.history.location.state.response.payment.payment_id,
                dateCreated:this.props.history.location.state.response.payment.created
            })
            localStorage.removeItem("package_Id")
        }

    }
    render(){
        const {price, paymentId, loader, dateCreated} = this.state;
        if(!loader){return(
            <div>
            <Header />
            <Container>
               <div className="welcome-wrapper welcome-center">
                   <Row style={{ marginTop: '30px', marginBottom:'100px' }}>
                       <Col md={{ span: 6, offset: 3 }}>
                        <div className="thanks-outer">
                            <div className="text-center">
                                <h1 className="">Thanks!</h1>
                              </div>  
                            <div>
                                <ul style={{listStyle:'none', }}>
                                    <li>
                                        <label>
                                            Price:
                                        </label> 
                                       <div className="value-width">
                                        ${price}
                                            
                                       </div>
                                           
                                    </li>
                                    <li>
                                    <label>Payment Id:</label>
                                        <div className="value-width"> {paymentId}</div>
                                            
                                    </li>
                                    <li>
                                        <label>
                                            Date:
                                        </label>
                                        <div className="value-width">
                                            
                                            {
                                                dateCreated
                                            }
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                            
                       </Col>
                       
                   </Row>
               </div>
           
            </Container>
           

            <Footer className="welcome-footer" />
            </div>
        )}
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default Thanks