import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTimeFormat } from 'utils/dateTimeFormatOptions';

const RealTimePacakages = ({ targetDate, timeZone = "America/New_York" }) => {
    const targetDateInUS = new Date(targetDate).toLocaleString('en-US', { timeZone });
    const currentDateInUS = new Date().toLocaleString('en-US', { timeZone });

    const targetDateObj = new Date(targetDateInUS);
    const currentDateObj = new Date(currentDateInUS);
    const difference = targetDateObj - currentDateObj;

    const calculateTimeLeft = () => {
        const difference = targetDateObj - currentDateObj;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const addLeadingZeros = value => {
        if (value < 10) {
            return `0${value}`;
        }
        return value;
    };

    return (
        <>
            {difference > 0 ? (
                <>
                    {timeLeft.days}:{addLeadingZeros(timeLeft.hours)}:{addLeadingZeros(timeLeft.minutes)}:{addLeadingZeros(timeLeft.seconds)}
                </>
            ) : (
                <DateTimeFormat date={targetDate ?? null} />)}
        </>
    );
};

RealTimePacakages.propTypes = {
    targetDate: PropTypes.string.isRequired,
    timeZone: PropTypes.string
};

RealTimePacakages.defaultProps = {
    timeZone: "America/New_York",
    targetDate: null,
}

export default RealTimePacakages;
