import React from 'react';
import { Link } from 'react-router-dom';
import {
    CDataTable,
} from '@coreui/react';
import axios from 'axios';
import '../common.css';
import { Helmet } from "react-helmet";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import AlertMessage from 'components/message/message';
import { ContainerLoader } from 'components/common/ContainerLoader';
const fields = ['name', 'price', 'free_credit', 'package_order', 'action']
class Creditpackage extends React.Component {
    state = {
        usersData: [],
        userList: [],
        modalShow: false,
        deleteId: '',
        index: '',
        newUserData: [],
        loader: false
    }
    async componentDidMount() {
        this.setState({
            loader: true
        })
        const packageList_api_url = process.env.REACT_APP_API_URL + "/api/package/getAllCreditPackages"
        await axios.post(packageList_api_url, '', { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: data.message ?? 'Something went wrong',
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        usersData: data.data,
                        open: false,
                        message: '',
                        successError: 'success'
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })
    }
    setModalShow = (id, indexId) => {
        this.setState({
            modalShow: true,
            deleteId: id,
            index: indexId
        })
    }
    onHide = () => {
        this.setState({
            modalShow: false
        })
    }
    callDelete = async () => {
        this.setState({
            modalShow: false
        })
        const data = {
            id: this.state.deleteId
        }
        const packageDelete_api_url = process.env.REACT_APP_API_URL + "/api/package/deleteCreditPackage"
        await axios.post(packageDelete_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: data.data.message,
                        successError: 'error'
                    })
                }
                else {
                    if (data.success) {
                        const packageList_api_url = process.env.REACT_APP_API_URL + "/api/package/getAllCreditPackages"
                        axios.post(packageList_api_url, '', { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
                            .then((response) => {
                                const { data } = response;
                                if (data.error) {
                                    this.setState({
                                        loader: false,
                                        open: true,
                                        message: data.message ?? 'Something went wrong',
                                        successError: 'error'
                                    })
                                }
                                else {
                                    this.setState({
                                        loader: false,
                                        usersData: data.data,
                                        open: false,
                                        message: '',
                                        successError: 'success'
                                    })
                                }
                            }).catch((error) => {
                                this.setState({
                                    loader: false,
                                    open: true,
                                    message: "Something went wrong",
                                    successError: 'error',
                                })
                            })
                    }
                    else {
                        this.setState({
                            loader: false,
                            open: true,
                            message: "Something went wrong",
                            successError: 'error',
                        })
                    }
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })
    }
    render() {
        const { usersData, modalShow, loader } = this.state
        return (
            <>
                <Helmet>
                    <title>Credit Packages</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ padding: "30px" }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ justifyContent: "space-between" }}
                                >
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                width: "100%",
                                                maxWidth: "265px",
                                                color: "#000059!important",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.6rem",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            {" "}
                                            Credit Packages
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                justifyContent: {
                                                    xs: "flex-start",
                                                    sm: "flex-start",
                                                    md: "flex-end",
                                                    lg: "flex-end",
                                                    xl: "flex-end",
                                                },
                                            }}
                                        >
                                            <Grid item>
                                                <Button
                                                    component={Link}
                                                    size="large"
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: "#002986!important",
                                                        color: "white!important",
                                                        textTransform: "none!important",
                                                        fontWeight: "bold",
                                                        fontFamily: "Barlow!important",
                                                    }}
                                                    to="/add-credit-packages"
                                                    startIcon={<AddIcon />}
                                                >
                                                    Add Credit Package
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {loader ? (
                                    <ContainerLoader />
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CDataTable
                                                responsive
                                                items={usersData}
                                                fields={fields}
                                                itemsPerPage={10}
                                                tableFilter
                                                pagination
                                                scopedSlots={{
                                                    'action':
                                                        (item, index) => {

                                                            return (
                                                                <td className="py-2 ">
                                                                    <div className="flex">
                                                                        <IconButton aria-label="edit" sx={{
                                                                            color: "#002986",
                                                                            "&:hover": {
                                                                                color: "#B90F0F"
                                                                            }
                                                                        }} component={Link} to={`/credit-package-view/${item._id}`}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                        <IconButton aria-label="edit" sx={{
                                                                            color: "#002986",
                                                                            "&:hover": {
                                                                                color: "#B90F0F"
                                                                            }
                                                                        }} component={Link} to={`/credit-package-edit/${item._id}`}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton aria-label="delete" onClick={() => this.setModalShow(item._id, index)} sx={{
                                                                            color: "#002986",
                                                                            "&:hover": {
                                                                                color: "#B90F0F"
                                                                            }
                                                                        }}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </div>

                                                                </td>
                                                            )
                                                        }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* Delete modal */}
                <Dialog
                    fullWidth={true}
                    scroll='body'
                    maxWidth="sm"
                    open={modalShow}
                    onClose={this.onHide}
                >
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={() => this.onHide()}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                        <DialogContentText>
                            Are you sure you want to Delete, this action can not be revert ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onHide}
                            sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none"
                            }}
                        >Cancel</Button>
                        <Button onClick={this.callDelete}
                            sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none"
                            }}
                        >Delete</Button>
                    </DialogActions>
                </Dialog>
                {/* alert message  */}
                <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                />
            </>
        )
    }
}
export default Creditpackage;