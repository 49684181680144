import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";

import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class League extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      vendors: [],
      modalShow: false,
      deleteId: "",
      deleting: false,
      loader: true,
      loadervendor: true,
      open: false,
      message: "",
      alertcolor: "",
      modalform: {
        show: false,
        id: "",
        title: "",
        name: "",
        vendor: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
      },
    };
  }

  componentDidMount() {
    this.getListsVendor();
    this.getLists();
  }

  getListsVendor = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllVendors";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.data) {
          this.setState({
            loadervendor: false,
            vendors: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllLeagues";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false, deleteId: "" });
  };

  callDelete = () => {
    this.setState({ deleting: true });
    const data = { _id: this.state.deleteId };
    const delete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteLeague";
    axios
      .post(delete_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            alertcolor: "error",
          });
        } else {
          if (data.success) {
            this.getLists();
            this.setState({
              modalShow: false,
              open: true,
              message: "Deleted successfully!",
              alertcolor: "success",
              deleting: false,
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  setModalFormShow = (id, title, name, vendor) => {
    const modalform = {
      show: true,
      id: id,
      title: title,
      name: name,
      vendor: vendor,
      submitting: false,
    };
    this.setState({ modalform });
  };

  onHideForm = () => {
    const modalform = {
      show: false,
      id: "",
      title: "",
      name: "",
      vendor: "",
      submitting: false,
    };
    this.setState({ modalform });
  };

  handleSubmit = () => {
    const { modalform } = this.state;
    modalform.submitting = true;
    this.setState({ modalform }, () => {
      let form_data = { name: modalform.name, vendor: modalform.vendor };
      let form_api_url = process.env.REACT_APP_API_URL + "/api/admin/addLeague";
      let successmsg = "Added successfully!";
      if (modalform.id !== "") {
        form_api_url =
          process.env.REACT_APP_API_URL + "/api/admin/updateLeague";
        form_data = {
          name: modalform.name,
          _id: modalform.id,
          vendor: modalform.vendor,
        };
        successmsg = "Updated successfully!";
      }

      axios
        .post(form_api_url, form_data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            modalform.submitting = false;
            modalform.formalert = true;
            modalform.formmessage = data.data.message;
            modalform.formalertcolor = "error";
            this.setState({ modalform });
          } else {
            if (data.success) {
              this.getLists();
              const modalform_new = {
                show: false,
                id: "",
                title: "",
                name: "",
                vendor: "",
                submitting: false,
                formalert: false,
                formmessage: "",
                formalertcolor: "",
              };
              this.setState({
                modalform: modalform_new,
                open: true,
                message: successmsg,
                alertcolor: "success",
              });
            } else {
              this.setState({
                formalert: true,
                formmessage: data.data.message,
                formalertcolor: "error",
              });
            }
          }
        })
        .catch((error) => {
          modalform.submitting = false;
          modalform.formalert = true;
          modalform.formmessage = "Something went wrong";
          modalform.formalertcolor = "error";
          this.setState({ modalform });
        });
    });
  };

  handleChange = (e) => {
    const { modalform } = this.state;
    modalform.name = e.target.value;
    this.setState({ modalform });
  };

  handleChangeVendor = (e, newVal) => {
    const { modalform } = this.state;
    if (newVal) {
      modalform.vendor = newVal._id;
    } else {
      modalform.vendor = "";
    }
    this.setState({ modalform });
  };

  render() {
    const { lists, modalShow, loader, loadervendor, modalform } = this.state;
    const fields = [
      { key: "name" , label:"Name" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
      },
    ];

    if (!loader && !loadervendor) {
      return (
        <>
          <Helmet>
            <title>League List</title>
          </Helmet>
          <Collapse in={this.state.open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={this.state.alertcolor}
              sx={{ mb: 2 }}
            >
             {this.state.message}
            </Alert>
          </Collapse>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title="League List"
                  
                  action={
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        this.setModalFormShow("", "Add League", "", "")
                      }
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add League
                    </Button>
                  }
                />
                <Divider />

                <CardContent>
                  <DataTable
                    tableFilter
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      action: (item, index, val) => {
                        return (
                      
                            <div className="flex">
                              <IconButton
                                aria-label="edit"
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                                onClick={() =>
                                  this.setModalFormShow(
                                    item._id,
                                    "Update League",
                                    item.name,
                                    item.vendor
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => this.setModalShow(item._id)}
                                aria-label="delete"
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                        
                        );
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalShow}
            onClose={this.onHide}
          >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={() => this.onHide()}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogContentText>
                Are you sure you want to Delete, this action can not be revert ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onHide}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.callDelete}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalform.show}
            onClose={this.onHideForm}
          >
            <DialogTitle>{modalform.title}</DialogTitle>
            <ValidatorForm onSubmit={this.handleSubmit}>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.onHideForm()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>

                <div style={{ marginBottom: "16px" }}>
                  <label>League name</label>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    name="names"
                    onChange={this.handleChange}
                    value={modalform.name}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
                <div className="">
                  <label>Vendor</label>
                  <Autocomplete
                    onChange={this.handleChangeVendor}
                    id="tags-outlined"
                    options={this.state.vendors}
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      this.state.vendors.filter(
                        (v) => v._id === modalform.vendor
                      )[0]
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        value={modalform.vendor}
                        variant="outlined"
                        placeholder="Select Vendor"
                        validators={["required"]}
                        errorMessages={"This field is required"}
                      />
                    )}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onHideForm}
                  type="button"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={modalform.submitting}
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }

    if (loadervendor) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "13rem 0",
          }}
        >
          <CircularProgress sx={{ color: "#002986" }} />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default League;
