import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';

// mui component
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Typography
} from '@mui/material';

// Component
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import Usersidebar from 'components/user-sidebar/user-sidebar';
import { useUserAPICollection } from 'Hooks/useUserAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import { ContainerLoader } from 'components/common/ContainerLoader';
import "./affiliates.scss"
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
// import Logo from "images/ticket_liberty_logo.png";


const section = {
  height: "100%",
  overflowY: "auto",
  paddingTop: 5,
};

const section2 = {
  paddingTop: 5,
};

const summary = {
  height: "100%",
};

const Affiliates = () => {
  const history = useHistory()
  const { getAllAffiliateAPI, affiliateLinkClickStateAPI } = useUserAPICollection();
  const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
  const [affiliatesData, setAffiliatesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [idForLoadMore, setIdForLoadMore] = useState(null);

  const getAllAffiliate = async () => {
    try {
      setIsLoading(true)
      const res = await getAllAffiliateAPI();
      const { data } = res;
      if (data.tokenExpired && data.error) {
        errorMessage(data?.message, "error");
        Cookies.remove('at');
        Cookies.remove('accessToken');
        history.push("/login");
      } else if (data.error) {
        errorMessage();
        setIsLoading(false);
      } else {
        setAffiliatesData(data?.data ?? []);
        setIsLoading(false);
      }
    } catch (error) {
      errorMessage();
      setIsLoading(false)
    }
  }

  const handleLinkClickOpen = async (e, item) => {
    try {
      const res = await affiliateLinkClickStateAPI({ id: item._id });
      const { data } = res;
      if (data.error) {
        errorMessage();
      } else {
        console.log("count");
      }
    } catch (error) {
      errorMessage();
    }
  }

  const handleloadMoreItems = (id) => {
    setIdForLoadMore(id)
    setIsLoadMore(true);
  };

  const handleloadLese = (id) => {
    setIdForLoadMore(id)
    setIsLoadMore(false);
  };

  useEffect(() => {
    getAllAffiliate()
  }, [])

  return (
    <>
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>Affiliates</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                <Grid container spacing={2}>
                  <Grid item xs="12" className="space-top-col">
                    <Card sx={{ p: 1 }}>
                      <CardHeader
                        title="Affiliates"
                        sx={{ px: 3 }}
                      />
                      <CardContent>
                        <Grid container direction="column" spacing={3} sx={summary}>
                          {/* <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <div style={section}>
                              <Link to="/">
                                <img src={Logo} alt="logo"
                                  style={{
                                    width: "100%",
                                    maxWidth: "260px"
                                  }} />
                              </Link>
                            </div>
                          </Grid> */}
                          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <div style={section}>
                              <Typography variant="h3">
                                Ticket Liberty was established in 2019 to help free ticket owners from oppressive ticketing
                                restrictions. As we continue developing technologies to allow you to do what you do best,
                                we’ve partnered with other trusted companies along the way that have exceptional
                                technology and services that we would highly recommend that you try for yourself. See
                                below our top picks and contact us with any recommendations.
                              </Typography>
                            </div>
                          </Grid>
                          {isLoading ? (
                            <ContainerLoader />
                          ) : (
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <div className='scrollable-container-affilites'>
                                {affiliatesData.length > 0 ? affiliatesData && affiliatesData.map((items, index) => {
                                  return (
                                    <Grid sx={section2} key={index}>
                                      <Typography sx={{ paddingBottom: "21px" }} variant="h3">
                                        {items?.category}
                                      </Typography>
                                      <Grid item xs={12}>
                                        <div className="App">
                                          <Container maxWidth="xl">
                                            <Grid container spacing={3}>
                                              {items?.affiliateCompanyData && items?.affiliateCompanyData.map((item, index) => {
                                                return (
                                                  <Grid item xs={12} sm={6} md={6} lg={3}
                                                    key={index}
                                                    className='card-content-affiliate'
                                                  >
                                                    <Card
                                                      sx={{
                                                        borderRadius: 4,
                                                        padding: "20px!important",
                                                        margin: "5px!important"
                                                      }}>
                                                      <a
                                                        style={{ textDecoration: "none" }}
                                                        href={item.link}
                                                        target="_blank"
                                                        onClick={(e) => handleLinkClickOpen(e, item)}
                                                      >
                                                        <Box
                                                          sx={{
                                                            mb: 4,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "space-between"
                                                          }}
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              flexDirection: "row",
                                                              alignItems: "center"
                                                            }}
                                                          >
                                                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                                              {item.companyName ?? ""}
                                                            </Typography>
                                                          </Box>
                                                        </Box>
                                                        <Box sx={{ position: "relative", mb: 2 }}>
                                                          <CardMedia
                                                            sx={{ borderRadius: 3, objectFit: "contain!important" }}
                                                            component="img"
                                                            height="140"
                                                            image={item?.image}
                                                            alt="green iguana"
                                                          />
                                                        </Box>
                                                      </a>
                                                      <CardContent sx={{ p: 0, mb: 0, height: " 100%", minHeight: "150px !important" }}>
                                                        <Typography variant="body1">
                                                          {idForLoadMore === item._id ? (
                                                            <>
                                                              {!isLoadMore ? (item.text.length > 200 ? `${item.text.slice(0, 200)}` : item.text) : (item.text)}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {item.text.length > 200 ? (`${item.text.slice(0, 200)}`) : (item.text)}
                                                            </>
                                                          )}
                                                        </Typography>
                                                        {item.text.length > 200 ? (idForLoadMore === item._id ? (
                                                          <>
                                                            {!isLoadMore ? (
                                                              <span
                                                                style={{
                                                                  color: "#002583",
                                                                  fontWeight: 'bold',
                                                                  borderBottom: "1px"
                                                                }}
                                                                onClick={() => handleloadMoreItems(item._id)}>
                                                                show more...
                                                              </span>
                                                            ) : (<span
                                                              style={{
                                                                color: "#002583",
                                                                fontWeight: 'bold',
                                                                borderBottom: "1px"
                                                              }}
                                                              onClick={() => handleloadLese(item._id)}>
                                                              show less
                                                            </span>)}
                                                          </>
                                                        ) : (
                                                          <span
                                                            style={{
                                                              color: "#002583",
                                                              fontWeight: 'bold',
                                                              borderBottom: "1px"
                                                            }}
                                                            onClick={() => handleloadMoreItems(item._id)}>
                                                            show more...
                                                          </span>
                                                        )) : (null)}
                                                      </CardContent>
                                                    </Card>
                                                  </Grid>
                                                )
                                              })}
                                            </Grid>
                                          </Container>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )
                                }) : (
                                  <td
                                    colSpan={13}
                                    style={{ padding: "2.6rem 1rem" }}
                                  >
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: "1.6rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      Data not Found
                                    </Typography>
                                  </td>
                                )}
                              </div>
                            </Grid>)}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </main>
            </div>
          </div>
        </div>
        <Footer />
      </div >
      {/* Alert Message */}
      < AlertMessage
        open={alertMessage.openAlert}
        message={alertMessage.message}
        severity={alertMessage.alertSeverity}
        closeCall={hideAlert}
      />
    </>
  )
}
export default Affiliates;