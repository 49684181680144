import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Helmet } from "react-helmet";
import { BottomScrollListener } from 'react-bottom-scroll-listener';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import '../../userdashboard/userdashboard.scss';
import './billing.css';
import AlertMessage from '../../../components/message/message';
import axios from 'axios';
import Usersidebar from '../../../components/user-sidebar/user-sidebar';
import PageLoader from '../../../components/PageLoader/PageLoader'
import Autocomplete from '@mui/material/Autocomplete';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CCard } from '@coreui/react';
import MuiPhoneNumberValidatorElement from '../../../components/phoneValidator/phoneValidator';
import Textarea from '@mui/material/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import apple from '../../../images/user-dashboard/billing-icons/apple-pay-icon.svg';
import Bitcoin from '../../../images/user-dashboard/billing-icons/bitcoin-icon.svg';
import Googlepay from '../../../images/user-dashboard/billing-icons/google-pay-icon.svg';
import Paypal from '../../../images/user-dashboard/billing-icons/paypal-icon.svg';
import Stripe from '../../../images/user-dashboard/billing-icons/stripe-icon.png';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import UserProfileAction from '../../../redux/actions/UserProfielAction';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

const countryList = [
    { title: 'USA' },
    { title: 'Cayman Islands' },
    { title: 'Denmark' },
    { title: 'Germany' }
];
const stateList = [
    { title: 'Alabama' },
    { title: 'Alaska' },
    { title: 'California' }
];
var cityList = [
    { title: 'a' },
    { title: 'b' },
    { title: 'c' }
];

class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            address: '',
            country: [],
            stateData: [],
            countryVal: '',
            stateVal: '',
            city: '',
            postalCode: '',
            check: false,
            save_billing: false,
            selectedValue: 'paypal',
            error: [],
            addressCheck: false,
            addressError: '',
            open: false,
            message: '',
            successError: '',
            packageName: '',
            packagePrice: '',
            packageId: '',
            modalShow: false,
            loader: false,
            comingSoon: false,
            messageData: '',
            postalError: '',
            arr: [],
            termsandcondition: '',
            scrollTerm: false
        }
    }

    async componentDidMount() {

        this.setState({
            loader: true
        })

        if (!ValidatorForm.hasValidationRule('isPhone')) {
            ValidatorForm.addValidationRule('isPhone', (value) => {
                if (value === null || value.trim() === "" || value.trim() === "+") {
                    return false;
                }
                return true;
            });
        }

        this.setState({
            packageName: localStorage.getItem('package_name'),
            packagePrice: localStorage.getItem('package_price'),
            packageId: localStorage.getItem('package_id')
        })


        const data = this.props.UserProfile;

        if (data.error) {
            this.setState({
                loader: false,
                open: true,
                message: 'Something went wrong',
                successError: 'error'
            })
        }
        else {
            this.setState({
                loader: false,
                firstName: data.data.name,
                email: data.data.email,
                lastName: data.data.lastName,
                address: data.data.address,
                phoneNumber: data.data.phone_number,
                postalCode: data.data.postal_code,
                city: data.data.city,
                stateVal: data.data.state,
                countryVal: data.data.country,
                arr: data.data,
                open: false,
                message: 'Success',
                successError: 'success'
            })
            countryList.filter(data => {
                if (data.title === this.props.UserProfile.data.country) {
                    return this.setState({ country: data })
                }
                return null;
            })
            stateList.filter(data => {
                if (data.title === this.props.UserProfile.data.state) {
                    return this.setState({ stateData: data })
                }
                return null;
            })
            // cityList.filter(data=>{
            //     if(data.title === response.data.data.city){
            //         this.setState({
            //             city:data
            //         })
            //     }
            // })

        }

    }
    componentWillUnmount() {
        // if (ValidatorForm.hasValidationRule('PhoneStrength')) {
        //     ValidatorForm.removeValidationRule('PhoneStrength');
        // }
        if (ValidatorForm.hasValidationRule('isPhone')) {
            ValidatorForm.removeValidationRule('isPhone');
        }
    }
    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == "postalCode") {
            if (e.target.value.length < 5) {
                this.setState({
                    postalError: 'min length 5'
                })
            }
            else {
                this.setState({
                    [e.target.name]: e.target.value,
                    postalError: ''
                })
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    handlePhoneChange = (phone) => {
        this.setState({
            phoneNumber: phone
        })

    }
    handleClose = (event, reason) => {
        this.setState({
            comingSoon: false,
            open: false
        })
    };

    handleChangeRadio = (event) => {
        if (event.target.value !== "paypal") {
            this.setState({
                selectedValue: event.target.value,
                comingSoon: true,
                messageData: 'Coming Soon'
            })
        }
        else {
            this.setState({
                selectedValue: event.target.value
            })
        }

    };

    handleChangeCountry = (e, newVal) => {
        this.setState({
            country: newVal
        })
    }
    handleChangeState = (e, newVal) => {
        this.setState({
            stateData: newVal
        })
    }
    handleChangeCity = (e) => {

        this.setState({
            city: e.target.value
        })
    }
    handleChangeSaveCheck = (e) => {

        this.setState({
            save_billing: !this.state.save_billing
        })
    }
    handleChangeCheck = (e) => {

        this.setState({
            check: !this.state.check,

        })
    }
    handleChangeAddress = (e) => {

        if (e.target.value) {
            this.setState({
                addressError: '',
                address: e.target.value,
            })
        }
        else {
            this.setState({
                addressError: 'please enter address',
                address: '',
            })
        }
    }

    handleSubmit = async () => {
        if (this.state.check) {
            this.setState({
                modalShow: false,
                loader: true
            })
            if (this.state.address) {
                //Call Submit api Here
                const data = {
                    name: this.state.firstName,
                    phone_number: this.state.phoneNumber,
                    address: this.state.address,
                    country: this.state.countryVal,
                    state: this.state.stateVal,
                    city: this.state.city,
                    postal_code: this.state.postalCode,
                    package_name: this.state.packageName,
                    package_id: this.state.packageId,
                    price: this.state.packagePrice,
                    save_billing: this.state.save_billing,
                    payment_method: this.state.selectedValue
                }
                const createPayment_api_url = process.env.REACT_APP_API_URL + "/api/payment/createPayment"
                await axios.post(createPayment_api_url, data, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })

                    .then((response) => {

                        const { data } = response;
                        if (data.error) {
                            this.setState({
                                loader: false,
                                open: true,
                                message: data.message,
                                successError: 'error'
                            })

                        }
                        else {
                            this.props.getUserProfile();
                            this.setState({
                                loader: false,
                                open: true,
                                message: data.message,
                                successError: 'success'
                            })

                            window.location.href = data.url;

                        }

                    }).catch((error) => {
                        this.setState({
                            loader: false,
                            open: true,
                            message: "Something went wrong, please try again",
                            successError: 'error',
                        })
                    })
            }
            else {
                this.setState({
                    addressError: 'please enter address',
                    loader: false,

                })
            }
        }
        else {
            this.setState({
                modalShow: true
            })

        }

    }
    onHide = () => {
        this.setState({
            modalShow: false
        })
    }
    onShow = () => {
        document.addEventListener('scroll', this.handleScroll);
        this.setState({
            modalShow: true
        })
    }
    handleBottomTerms = () => {
        console.log("handleBottomTerms")
        this.setState({ scrollTerm: true })
    }

    confirmHide = () => {
        this.setState({ check: true, modalShow: false })
        const term_accept_api = process.env.REACT_APP_API_URL + "/api/user/acceptTerms"
        axios.post(term_accept_api, { check: true }, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } }).then((response) => {
            const { data } = response;
            if (data.error) {
                console.log("error")
            } else {
                console.log("success")
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    render() {
        return (
            <>
                <div className="user-dashboard-wrapper">
                    <Helmet>
                        <title>
                            billing
                        </title>

                    </Helmet>
                    <Header />
                    <div className="c-app c-default-layout user-dashboard-body">
                        {this.state.loader && <PageLoader />}
                        <Usersidebar />
                        <div className="c-wrapper">
                            <AlertMessage open={this.state.comingSoon} message={this.state.messageData} severity={this.state.successError} closeCall={this.handleClose} />
                            <div className="c-body">
                                <main className="c-main">
                                    {/* <CRow> */}

                                    {/* <CCol xs="12" lg="12" md="12" sm="12" className="space-top-col"> */}
                                    <div className="notification-outer">
                                        <CCard>
                                            <div className="usage-tbl-header">
                                                <h3> Billing Info</h3>
                                            </div>
                                            <ValidatorForm onSubmit={this.handleSubmit}>
                                                <Row>
                                                    <Col sm={6}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <div className="form-group login-wrapper">
                                                                    <label>First Name</label>
                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        placeholder="Enter First Name"
                                                                        onChange={this.handleChange}
                                                                        name="firstName"
                                                                        value={this.state.firstName}
                                                                        validators={['required']}
                                                                        errorMessages={[('This field is required')]}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {/* <Col md={12}>
                                                                                    <div className="form-group login-wrapper">
                                                                                        <label>Last Name</label>
                                                                                        <TextValidator
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                            placeholder="Enter Last Name"
                                                                                            onChange={this.handleChange}
                                                                                            name="lastName" 
                                                                                        value={this.state.lastName}
                                                                                            validators={['required']}
                                                                                            errorMessages={[('This field is required')]}
                                                                                        />
                                                                                    
                                                                                    </div>
                                                                                </Col> */}
                                                            <Col md={12}>
                                                                <div className="form-group login-wrapper">
                                                                    <label>Email Address</label>
                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        fullWidth

                                                                        name="email"
                                                                        value={this.state.email}                                                                                  // value={email}

                                                                    />
                                                                    {this.state.error.email &&
                                                                        <div className="error-msg">{this.state.error.email}</div>
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="form-group login-wrapper">
                                                                    <label>Phone number</label>

                                                                    {/*<div className="w-100 phone_number_f">
                                                                                            <MuiPhoneNumberValidatorElement
                                                                                                   
                                                                                                    name="phoneNum"
                                                                                                    className="phone_number"
                                                                                                    value={this.state.phoneNumber}
                                                                                                    onChange={this.handlePhoneChange}
                                                                                                    fullWidth 
                                                                                                    variant="outlined"
                                                                                                    validators={['isPhone']}
                                                                                                    errorMessages={('This field is required')}
                                                                                            />
                                                                                    </div>*/}

                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name="phoneNumber"
                                                                        className="phone_number"
                                                                        placeholder="+1 (702) 123-4567"
                                                                        value={this.state.phoneNumber}
                                                                        onChange={this.handleChange}
                                                                        validators={['isPhone']}
                                                                        errorMessages={('This field is required')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="form-group login-wrapper">
                                                                    <div className="form-group select-outer">
                                                                        <label>Address</label>
                                                                        <Textarea value={this.state.address} name="address" className="text-box-height"
                                                                            onChange={this.handleChangeAddress} />
                                                                    </div>
                                                                    <p style={{ color: 'red' }}>{this.state.addressError}</p>
                                                                </div>
                                                            </Col>


                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="form-group select-outer">
                                                                    <label>Country</label>
                                                                    {/*<Autocomplete
                                                                                            onChange={this.handleChangeCountry}
                                                                                            fullWidth
                                                                                            id="combo-box-demo"
                                                                                            name="country"
                                                                                            options={countryList}
                                                                                            value={this.state.country}
                                                                                            getOptionLabel={(option) => option.title}
                                                                                            renderInput={(params) =>
                                                                                                 <TextValidator placeholder="Select Country" value={this.state.country} {...params} variant="outlined" 
                                                                                                 validators={['required']}
                                                                                                 errorMessages={('This field is required')} />
                                                                                                }
                                                                                        />*/}

                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name="countryVal"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.countryVal}
                                                                        validators={['required']}
                                                                        errorMessages={('This field is required')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="form-group select-outer">
                                                                    <label>State</label>
                                                                    {/*<Autocomplete
                                                                                            onChange={this.handleChangeState}
                                                                                            fullWidth
                                                                                            id="combo-box-demo"
                                                                                            name="stateData"
                                                                                            options={stateList}
                                                                                            value={this.state.stateData}
                                                                                            getOptionLabel={(option) => option.title}
                                                                                            renderInput={(params) => <TextValidator value={this.state.stateData} placeholder="Select State" {...params} variant="outlined"
                                                                                            validators={['required']}
                                                                                            errorMessages={('This field is required')}
                                                                                            />}
                                                                                        />*/}

                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        name="stateVal"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.stateVal}
                                                                        validators={['required']}
                                                                        errorMessages={('This field is required')}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="form-group select-outer">

                                                                    {/* <Autocomplete
                                                                                            onChange={this.handleChangeCity}
                                                                                            fullWidth
                                                                                            id="combo-box-demo"
                                                                                            name="city"
                                                                                            options={cityList}
                                                                                            value={this.state.city}
                                                                                            getOptionLabel={(option) => option.title}
                                                                                            renderInput={(params) => <TextValidator value={this.state.city} placeholder="Select City" {...params} variant="outlined" 
                                                                                            validators={['required']}
                                                                                            errorMessages={('This field is required')} />
                                                                                        }
                                                                                        /> */}
                                                                    <div className="form-group login-wrapper">
                                                                        <label>City</label>
                                                                        <TextValidator
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            name="city"
                                                                            onChange={this.handleChangeCity}
                                                                            value={this.state.city}
                                                                            validators={['required']}
                                                                            errorMessages={('This field is required')}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="form-group login-wrapper">
                                                                    <label>Postal Code</label>
                                                                    <TextValidator
                                                                        variant="outlined"
                                                                        placeholder="Enter Postal Code"
                                                                        fullWidth
                                                                        onChange={this.handleChange}
                                                                        type="number"
                                                                        name="postalCode"
                                                                        value={this.state.postalCode}
                                                                        validators={['required']}
                                                                        errorMessages={[('This field is required', 'Only number')]}
                                                                    />
                                                                    <p style={{ color: 'red' }}>{this.state.postalError}</p>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} className="text-left">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={this.state.save_billing}
                                                                            onChange={this.handleChangeSaveCheck}
                                                                            name="save_billing"
                                                                        />
                                                                    }
                                                                    label="Save billing info for future use"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <div className="billing-ryt-outer">
                                                            <Row className="border-bottom">
                                                                <Col className="col-6 billing-info-left-lbl-outer">
                                                                    <span>
                                                                        Package Name
                                                                    </span>
                                                                </Col>

                                                                <Col className="col-6 billing-info-ryt-lbl-outer">
                                                                    <span>
                                                                        {this.state.packageName}
                                                                    </span>

                                                                </Col>
                                                            </Row>
                                                            <Row className="border-bottom">
                                                                <Col className="col-6 billing-info-left-lbl-outer">
                                                                    <span>
                                                                        Price
                                                                    </span>
                                                                </Col>

                                                                <Col className="col-6 billing-info-ryt-lbl-outer">
                                                                    <span>
                                                                        ${this.state.packagePrice}
                                                                    </span>

                                                                </Col>
                                                            </Row>
                                                            <Row className="border-bottom">
                                                                <Col className="billing-info-left-lbl-outer">
                                                                    <span>
                                                                        Tax
                                                                    </span>
                                                                </Col>

                                                                <Col className="col-6 billing-info-ryt-lbl-outer">
                                                                    <span>
                                                                        $0.00
                                                                    </span>

                                                                </Col>
                                                            </Row>
                                                            <Row className="border-bottom">
                                                                <Col md={12} className="billing-info-left-lbl-outer">

                                                                    <div className="d-flex">

                                                                        <div className="form-group login-wrapper promo-code-input">
                                                                            <label>Promotion Code</label>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                name="postalCode"
                                                                            />

                                                                        </div>
                                                                        <div className="apply-btn-outer">
                                                                            <Button className="apply-btn">Apply</Button>

                                                                        </div>
                                                                    </div>

                                                                </Col>

                                                            </Row>
                                                            <Row className="border-bottom">
                                                                <Col className="col-6 billing-info-left-lbl-outer">
                                                                    <span>
                                                                        Grand Total
                                                                    </span>
                                                                </Col>

                                                                <Col className="col-6 billing-info-ryt-lbl-outer">
                                                                    <span className="price-bold">
                                                                        ${this.state.packagePrice}
                                                                    </span>

                                                                </Col>
                                                            </Row>

                                                            <Row className="border-bottom">

                                                                <div className="billing-info-left-lbl-outer">
                                                                    <span>
                                                                        Select Payment Method
                                                                    </span>
                                                                </div>

                                                                <Col md={12} className="billing-option-padding">
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup aria-label="Payoption" onChange={this.handleChangeRadio} defaultValue="Paypal" name="Payoption" className="payment-option" >
                                                                            <FormControlLabel value="paypal" control={<Radio color="primary" />} label={<img src={Paypal} />} />
                                                                            <FormControlLabel value="stripe" control={<Radio color="primary" />} label={<img src={Stripe} />} />
                                                                            <FormControlLabel value="bitcoin" control={<Radio color="primary" />} label={<img src={Bitcoin} />} />
                                                                            <FormControlLabel value="googlepay" control={<Radio color="primary" />} label={<img src={Googlepay} />} />
                                                                            <FormControlLabel value="apple" control={<Radio color="primary" />} label={<img src={apple} />} />
                                                                            <FormControlLabel value="wireTrans" control={<Radio color="primary" />} label="Wire Transfer" />
                                                                            <FormControlLabel value="contact Us" control={<Radio color="primary" />} label="Contact Us" />

                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={12} className="text-left pl-0">
                                                                    <div className="term-condition" onClick={() => this.onShow()}>
                                                                        {/*<Checkbox
                                                                                            defaultChecked
                                                                                            name="check"
                                                                                            checked={this.state.check}
                                                                                            onChange={this.handleChangeCheck}
                                                                                        />*/}
                                                                        <label><span>Please read and agree to the current Terms and Conditions</span></label>
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        className="contact-submit"
                                                                        style={{ margin: "50px 0px", padding: "0px 10px" }}
                                                                    >
                                                                        Place Order
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose} />
                                            </ValidatorForm>
                                            <Modal
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                                show={this.state.modalShow}
                                                className="Modal_Box"
                                                onHide={this.onHide}
                                            >
                                                <Modal.Body>
                                                    <h5>Please scroll and accept Terms and Conditions</h5>
                                                    <BottomScrollListener onBottom={this.handleBottomTerms}>
                                                        {(scrollRef) =>
                                                            <div ref={scrollRef} style={{ height: "200px", overflowY: "scroll" }}>
                                                                <div className="left term-outer">
                                                                    <h3>1. Acceptance of Terms</h3>
                                                                    <p>
                                                                        <strong>
                                                                            Ticket Liberty Corp.
                                                                        </strong> a corporation organized under the laws of the State of New York and its Affiliates (the “Company”, “us”, or “we”), provides certain bar code scanning and electronic ticket processing services (the "Service" or “Services”) to the user (“You” or “Your”) through a software application, a Website and/or mobile phone application (collectively, the “Application”), subject to Your compliance with the following Terms and Conditions of Use (“Terms”), as well as any other written agreement(s) between us and You.
                                                                    </p>
                                                                    <p>
                                                                        We reserve the right to change these Terms and Conditions from time to time, with or without notice to You. You acknowledge and agree that it is Your responsibility to periodically review these Terms. Your continued use of the Application and Service after such modifications will constitute acknowledgement and acceptance of the modified Terms.
                                                                    </p>
                                                                    <p>
                                                                        As used in these Terms, references to our “Affiliates” include our owners, licensees, assigns, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and includes (without limitation) all parties involved in creating, producing, and/or delivering the Service and/or contents available through the Application.
                                                                    </p>
                                                                    <p>
                                                                        You affirm that You are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms. In any case, You affirm that You are over the age of 18, as THE APPLICATION IS NOT INTENDED FOR CHILDREN UNDER 18 THAT ARE UNACCOMPANIED BY HIS OR HER PARENT OR LEGAL GUARDIAN.
                                                                    </p>
                                                                    <p>
                                                                        BY USING THE APPLICATION AND THE SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS, PLEASE EXIT THE APPLICATION. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS APPLICATION, PRODUCTS, OR SERVICE, OR THESE TERMS IS TO CEASE USING THE APPLICATION AND/OR THOSE PARTICULAR PRODUCTS, OR SERVICE. THESE TERMS ARE EFFECTIVE ONCE YOU CHECK THE 'I AGREE' BOX AT THE BOTTOM OF THIS AGREEMENT.
                                                                    </p>
                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>2. Services, Licenses </h3>
                                                                    <p>
                                                                        <strong>
                                                                            2.1.  Limited License from Company.
                                                                        </strong>   Subject to these Terms, the Company hereby grants to You a personal, nontransferable, and nonexclusive license to install and use the Application in the Territory and expressly restricted to Field of Use, on Your smart phone or mobile device and/or a desktop or laptop computer for the own personal use only, and not to offer or use the Services or Application for any business purposes for other third parties without first obtaining an express permission from us for such use.  The “Territory” includes and is limited to the geographic territory of the United States of America.  No other geographic area is covered by this license. The “Field of Use” means the field of dynamic bar code scanning and use of the dynamic bar code technology in connection with the sales, delivery, verification and processing of electronic and other tickets and event-related tickets, items and rights.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.2.  Pricing.
                                                                        </strong>
                                                                        You agree to use our Application and Website based on the licensed fees applicable to your use, as shall be set up through our Application and Website (either as annual subscription fees, transaction fees, or a combination of both).   We reserve the right to increase the current fees in the future, and shall provide you with an advance notice of any planned increase at least three (3) months before expiration of your annual subscription fees term, and/or prior to the actual price increase becoming effective.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.3. 	No Guarantee of Results or Uninterrupted Functionality.
                                                                        </strong>
                                                                        <span>Although Company works hard to provide high quality Service, You understand and acknowledge that we cannot promise or guarantee specific results from using the Application or Service available on the Application.  You understand and agree that temporary interruptions of the Service may occur as normal events that are out of our control. You also understand and agree that we have no control over the third-party networks or service(s) that we may use to provide You with the Service, and that we are not responsible for any malicious software or unauthorized access or other actions of the third parties. You agree that the Service available on this Application is provided “AS IS” and that we assume no responsibility for the timeliness, deletion, non-delivery, misdelivery or failure to store any user communications or personalization settings or User Content (as defined below).</span>
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.4.  User Equipment.
                                                                        </strong>
                                                                        You are solely responsible for providing, at Your own expense, all equipment necessary to use the services, including a smart phone or other mobile device, a desktop or laptop computer, and Your own Internet access (including payment of service fees associated with such access).
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.5.  International Use.
                                                                        </strong>
                                                                        Although this Application may be accessible worldwide, we make no representation that materials on this Application are appropriate or available for use in locations outside the Territory. Those who choose to access this Application from other locations do so on their own initiative and at their own risk. If You choose to access this Application from outside the Territory, You are responsible for compliance with local laws in Your jurisdiction, including but not limited to, the taxation of products purchased over the Internet. Any offer for any product, Service, and/or information made in connection with this Application is void where prohibited.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.6.  Company Content and User Content.
                                                                        </strong>
                                                                        For purposes of these Terms, “Company Content” is defined as any software, data, information, communications, published works, photos, video, graphics, music, sounds, or other material that can be viewed by users on our Application and is owned by Company or its Affiliates.  Company Content does not include the data, photographs, videos, or other personal information or content that the user uploads or stores on the company server or makes available to the Application on or through the user’s own computer device (“User Content”).
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.7.  Ownership of Company Content.
                                                                        </strong>
                                                                        By accepting these Terms, You agree that all Company Content presented to You on this Application is protected by any and all intellectual property and/or other proprietary rights available within the United States, and is the sole property of Company or its Affiliates. All custom graphics, icons, logos and service names are registered trademarks, trademarks or service marks of Company or its Affiliates. All other trademarks or service marks are property of their respective owners. Nothing in these Terms grants You any right to use any trademark, service mark, logo, and/or the name of Company or its Affiliates. Certain ideas, software, and processes incorporated into the Service available on this Application may be protected by patent applications, or protected as trade secrets or proprietary information of the Company.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.8.  Limitations on Use of Company Content
                                                                        </strong>
                                                                        Except for a copy made for personal use, or a temporary copy that is made as part of the operation, You may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any Company Content, or reverse engineer any Company Content (including Web pages, applets, etc.) from this Application in any form or by any means whatsoever without prior written permission from us. Any unauthorized use of Application Content violates our intellectual property interests and could result in criminal or civil penalties, in addition to the immediate termination of services.

                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.9.  License to Access and Copy User Content.
                                                                        </strong>
                                                                        You hereby grant the Company a limited license to make copies, reproduce and store the User Content on the server(s) used or operated by or for the Company, make copies for the backup, and make temporary copies for the uploading, downloading and sharing of the User Content as part of the operation and use of the Application by the user and user authorized persons, who may be allowed by the user to access, view, copy, share or distribute and/or make copies of the User Content.   Company shall not own or obtain ownership rights to the User Content, beyond the license granted herein.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            2.10.  Warranty That User Content Does Not Violate Third Party Rights or Laws.
                                                                        </strong>
                                                                        You hereby warrant that any User Content that you have placed, uploaded or made available to anyone through the Application, and any of your actions that you take using Application, do not violate the third party’s rights, including without limitation any copyright, trademark, trade secret, property, privacy right or any other personal or proprietary right or interest, and do not violate any applicable laws, regulations of the applicable jurisdiction that may govern or be applicable to the User Content and you activities.  You further warranty that the User Content and your activities using Application do not violate any agreement or contractual obligation with any other third party.
                                                                    </p>

                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>3. Third Party Websites  </h3>
                                                                    <p>
                                                                        <strong>
                                                                            3.1.  Third-Party Sites and Information.
                                                                        </strong>
                                                                        This Application may redirect or link to other websites on the Internet, or may otherwise include references to information, products, or services made available by unaffiliated third parties. While we make every effort to work with trusted, reputable providers, from time to time such sites may contain information, material or policies that some may find inappropriate or personally objectionable. You understand that we are not responsible for the accuracy, completeness, decency or legality of content hosted by third party websites, nor are we responsible for errors or omissions in any references made on those websites. The inclusion of such a link or reference is provided merely as a convenience and does not imply endorsement of, association with the Application, or any warranty of any kind, either express or implied by the Company.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            3.2.  Promotions.
                                                                        </strong>
                                                                        From time to time, this Application may include advertisements offered by third parties. You may enter into correspondence with or participate in promotions of the advertisers showing their products on our Application. Any such correspondence or promotions, including the delivery of and the payment for goods and services by those third parties, and any other terms, conditions, warranties or representations associated therewith, are solely between You and the advertiser. We assume no liability, obligation or responsibility for any part of any such correspondence or promotion.
                                                                    </p>


                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>4. Privacy and Security</h3>
                                                                    <p>
                                                                        <strong>
                                                                            4.1.  Login Required.
                                                                        </strong>
                                                                        In order to access the Service on this Application, You may be asked to set up an account and password or use Your existing Facebook or other social media platform username and password. Our account registration page may request certain personal information from You (“Registration Information”). You will have the ability to maintain and periodically update Your Registration Information as You see fit. By registering, You agree that all information provided by You as Registration Information is true and accurate and that You will maintain and update this information as required in order to keep it current, complete, and accurate.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            4.2.  Passwords and Security.
                                                                        </strong>
                                                                        You agree that You are responsible for maintaining the security and confidentiality of Your password, and that You are fully responsible for all activities that are carried on under Your account. Therefore, You must take reasonable steps to ensure that others do not gain unauthorized access to Your password and account. If you authorize any other person or persons to have access to Your account, you agree to assume all the risks that may be associated with the unauthorized use or access being given to your account by the persons to whom you grant or permit access.  You agree that the Company shall not be responsible for such unauthorized access.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            4.3.  Disclosure to Third Party Affiliates.
                                                                        </strong>
                                                                        You hereby grant us the right to disclose to third parties certain Registration Information about You, although we will not disclose any personally identifiable information. The information we obtain through Your use of this Application, including Your Registration Information, is subject to our Privacy Policy, as may be modified from time to time, which is specifically incorporated by reference into these Terms of Use.
                                                                    </p>

                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>5. Disclaimer, Indemnification and Limitations on Damages </h3>
                                                                    <p>
                                                                        <strong>
                                                                            5.1. No Warranty.
                                                                        </strong>
                                                                        ALL CONTENT AND OFFERINGS ON THIS APPLICATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A) THE CONTENT OR SERVICE WILL MEET YOUR REQUIREMENTS, (B) THE CONTENT, SERVICE, OR APPLICATION WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, FREE FROM MALICIOUS SOFTWARE OR UNAUTHORIZED ACCESS AND OTHER UNAUTHORIZED ACRTIONS OF THE THIRD PARTIES; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE CONTENT OR SERVICE WILL BE EFFECTIVE, ACCURATE, OR RELIABLE, OR (D) THE QUALITY OF ANY CONTENT OR SERVICE OBTAINED BY YOU THROUGH THE APPLICATION FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS, OR DEFECTS. THIS 5.1. APPLICATION COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES, OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE CONTENT AND SERVICE AT ANY TIME WITHOUT NOTICE.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.2. Use of Application on Device.
                                                                        </strong>
                                                                        The use of the Service or the downloading or other acquisition of any products or Content through this Application is done at Your own discretion and risk and with Your agreement that You will be solely responsible for any damage to Your smart phone or mobile device, or loss of data that results from such activities.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.3.  No Warranty for Third-Party Infringement.
                                                                        </strong>
                                                                        Neither we or our Affiliates warrant or represent that Your use of materials displayed on, or obtained through this Application will not infringe the rights of third parties, or that the software used in Application is free of any malicious software of others.

                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.4.  Transactions with Third Parties.
                                                                        </strong>
                                                                        Through Your use of the Application, You may have the opportunities to engage in commercial transactions with other users and vendors. You acknowledge that all transactions relating to any products or offerings provided by any third party, including, but not limited to the purchase terms, payment terms, warranties, guarantees relating to such transactions, are agreed to solely between the seller of such merchandise and You. We make no warranty regarding any transactions executed through a third party, or in connection with this Application, and You understand and agree that such transactions are conducted entirely at Your own risk. Any warranty that is provided in connection with any offerings or content available on or through the Application from a third party is provided solely by such third party, and not by us or any other of our affiliates.  Further, we shall not be liable in any way for third party promises or representations regarding our Service or Content or for assistance in conducting commercial transactions with the third party through this Application, including without limitation the processing of orders.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.5.  Limitation on Damages.
                                                                        </strong>
                                                                        In no event shall we or our Affiliates, officers or management be liable to You or any third party for any special, punitive, incidental, indirect or consequential damages of any kind, or any damages whatsoever, including, without limitation, those resulting from loss of use, data or profit loss, whether or not we have been advised of the possibility of such damages, and on any theory of liability, arising out of or in connection with the use of the Application or of any website referenced or linked to from the Application. <b> In any event, neither party shall be entitled to any damages that exceed the cost of services for either the full year or the actual amount paid in the course of the prior year.</b>
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.6.  Applicability.
                                                                        </strong>
                                                                        Some jurisdictions prohibit the exclusion or limitation of liability for consequential or incidental damages, so the above limitations may not apply to You. Please consult the laws in Your jurisdiction.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            5.7.  Indemnification.
                                                                        </strong>
                                                                        YOU AGREE TO DEFEND, INDEMNIFY AND HOLD COMPANY AND ITS AFFILIATES, MANAGEMENT AND OFFICERS HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DEMANDS, SUITS, PROCEEDINGS, LIABILITIES, JUDGMENTS, LOSSES, DAMAGES, EXPENSES AND COSTS (INCLUDING WITHOUT LIMITATION REASONABLE ATTORNEYS' FEES) ASSESSED OR INCURRED BY COMPANY, DIRECTLY OR INDIRECTLY, WITH RESPECT TO OR ARISING OUT OF: (I) YOUR FAILURE TO COMPLY WITH THESE TERMS; (II) YOUR BREACH OF YOUR OBLIGATIONS UNDER THESE TERMS; (III) YOUR USE OF THE RIGHTS GRANTED HEREUNDER, INCLUDING WITHOUT LIMITATION ANY CLAIMS MADE BY ANY THIRD PARTIES; AND/OR (IV) YOUR VIOLATION OF ANY THIRD PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY COPYRIGHT, TRADEMARK, TRADE SECRET, PROPERTY, PRIVACY RIGHT OR ANY OTHER PERSONAL OR PROPRIETARY RIGHT OR INTEREST. AND VIOLATION OF ANY LAW OR REGULATION OF THE APPLICABLE JURISDICTION THAT MAY GOVERN OR BE APLICABLE TO THE USER CONTENT.
                                                                    </p>


                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>6. Termination of Use and Services </h3>
                                                                    <p>
                                                                        <strong>
                                                                            6.1.  Grounds for Termination.
                                                                        </strong>
                                                                        You agree that we may, at our sole discretion, terminate or suspend Your access to all or part of the Service and Application with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected fraudulent, abusive, or illegal activity may be grounds for barring Your access to this Application, and reporting You to the proper authorities, if necessary. Upon termination and regardless of the reason(s) motivating such termination, Your right to use the Service available on this Application will immediately cease. We shall not be liable to You or any third party for any claims for damages arising out of any termination or suspension or any other actions taken by us in connection therewith. All of the foregoing paragraphs shall survive any termination.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            6.2.  Right to Modify or Discontinue Services.
                                                                        </strong>
                                                                        WE RESERVE THE SOLE RIGHT TO EITHER MODIFY OR DISCONTINUE THE APPLICATION, INCLUDING ANY SERVICE OR FEATURES THEREIN, AT ANY TIME WITH OR WITHOUT NOTICE TO YOU. WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY SHOULD WE EXERCISE SUCH RIGHT. MODIFICATIONS MAY INCLUDE, BUT ARE NOT LIMITED TO, THE ADDITION OF FREE OR FEE-BASED SERVICES, OR CHANGES TO LIMITATIONS ON ALLOWABLE CONTENT. ANY NEW FEATURES THAT AUGMENT OR ENHANCE THE THEN-CURRENT SERVICE OR CONTENT ON THIS APPLICATION SHALL ALSO BE SUBJECT TO THESE TERMS OF USE.
                                                                    </p>

                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>7. Support and Failed Transactions  </h3>
                                                                    <p>
                                                                        <strong>
                                                                            7.1.  Support.
                                                                        </strong>
                                                                        We offer to provide support for the software offered through our Application and Services pursuant to a separate Support Agreement, which may be entered into by the parties.  If there are any problems encountered by you with the Application or Services, you shall inform us about such problems, and provide sufficient or requested information in order to resolve the problem in a reasonable time.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            7.2.  Failed Transactions.
                                                                        </strong>
                                                                        In the rare events that a particular transaction and/or the dynamic bar code scanning shall not happen, or may not be properly processed by our Application or Services, we will indicate the failure and the failed transaction to you at least 2 hours prior to the date of the event (“Timely Notification of Failure”). Upon Timely Notification of Failure, You shall assume all responsibility for the completion of the failed transaction without reliance or use of the Application or Services, or any other software, technology or assistance from the Company or its Affiliates.  You agree not to raise or assert any claims, either by itself or jointly with any other party, or seek indemnity or contribution based on or in connection with any failed transaction that has been identified us to You with a Timely Notification of Failure, as provided herein.
                                                                    </p>
                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>8. Force Majeure </h3>
                                                                    <p>
                                                                        <strong>
                                                                            8.1.  Force Majeure.
                                                                        </strong>
                                                                        In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery of products or Service available through our Application arising from any event beyond our reasonable control, whether or not foreseeable by either party, including but not limited to: labor disturbance, war, fire, epidemic, accident, computer viruses or malware, Internet operational disturbances, adverse weather, inability to secure transportation, governmental act or regulation, and other causes or events beyond our reasonable control, whether or not similar to those which are enumerated above.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            8.2.  Exclusive Remedy for Delays and Termination Caused by Force Majeure.
                                                                        </strong>
                                                                        The Company agrees to make reasonable and commercially practicable efforts to restore operations and/or backup the data for use of the Application within reasonable time after any Force Majeure occurrence. In the event that restoring such operation by the Company is not possible or commercially feasible, then the only available remedy shall be termination of services and pro rata refund of any fees associated with the Company Services for the remainder of the term.  Upon issuance of such refund, the Company shall be excused from any further operations and you shall not assert any claims based on the terminated services or damages related to the Force Majeure events, termination of services or delays caused by such events.
                                                                    </p>
                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>9. Dispute Resolution</h3>
                                                                    <p>
                                                                        <strong>
                                                                            9.1. Governing Law and Arbitration.
                                                                        </strong>
                                                                        This Agreement shall be governed and construed in accordance with the laws of the United States and the state of New York without reference to conflict of laws principles.  Both parties agree that any controversy or claim between the parties based on this Agreement shall, on a written request of either party, be served by registered mail on the other party (at its last known business or residential address). The proceedings under this Agreement shall be brought exclusively before the American Arbitration Association for Commercial Disputes, and shall be limited to not more than two (2) days of proceedings, unless the parties stipulate otherwise. The attorneys’ fees and costs of arbitration shall be borne by the losing party, or in such proportions, as the arbitrator shall decide.  Both parties agree to abide by the procedural, interim and final decisions of the arbitrator(s) and agree not to challenge the arbitration decisions, or enforcement of arbitration decisions in any court of another jurisdiction.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            9.2. Remedies.
                                                                        </strong>
                                                                        The Parties acknowledge that monetary damages may not be a sufficient remedy for unauthorized use of the Application or Software and that aggrieved party may be entitled, without waiving any other rights or remedies, to seek such injunctive or equitable relief as may be deemed proper by a court of competent jurisdiction. Such injunctive relief shall be in addition to any other remedies available hereunder, whether at law or in equity. In the event of legal proceedings relating to this Agreement, the prevailing party shall be entitled to recover its reasonable attorney’s fees and legal expenses.
                                                                    </p>
                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h3>10. General Provisions</h3>
                                                                    <p>
                                                                        <strong>
                                                                            10.1. Independent Contractors.
                                                                        </strong>
                                                                        Parties agree that their relationship is that of independent contractors, and that no employment, partnership or agency relationship is formed or contemplated by this Agreement.  Neither Party has any authority to act as an agent for or to bind the other Party to this Agreement in any other way, nor shall make any contrary representations to any third party.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.2. No Resale Right.
                                                                        </strong>
                                                                        You agree not to sell, resell, reproduce, duplicate, distribute, copy or use for any commercial purposes any portion of this Application, or use of or access to the Service provided through this Application, beyond the limited rights granted to You under these Terms.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.3. Non-Competition.
                                                                        </strong>
                                                                        You agree that you will not, for yourself or on behalf of any other person or entity, in any way, use or reverse-engineer the Application or Services offered by the use to compete with the business, products or services of the Company now or for a period of two (2) years after termination of your use of our Application or Services.

                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.4. Savings Clause.
                                                                        </strong>
                                                                        If any part of these Terms is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.5. No Waiver.
                                                                        </strong>
                                                                        Any failure by us to enforce or exercise any provision of these Terms or related rights shall not constitute a waiver of that right or provision.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.6. Entire Agreement.
                                                                        </strong>
                                                                        These terms and conditions constitute the entire agreement and understanding between the parties concerning the subject matter hereof and supersedes all prior agreements and understandings of the parties with respect thereto. These Terms may NOT be altered, supplemented, or amended by the use of any other document(s). To the extent that anything in or associated with the Application is in conflict or inconsistent with these Terms, these Terms shall take precedence.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.7. Notices.
                                                                        </strong>
                                                                        You may direct any questions, complaints, or claims with respect to the Application or Services to:
                                                                        <b>Email: </b>sales@ticketliberty.com
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            10.8. No Third Party Rights.
                                                                        </strong>
                                                                        This Agreement does not create any rights in any third parties, except assigns, successors, heirs or as otherwise expressly permitted herein.
                                                                    </p>


                                                                </div>
                                                                <div className="left term-outer">
                                                                    <h4>I agree to and accept these terms, conditions, notices
                                                                        and other provisions contained herein.</h4>
                                                                </div>
                                                            </div>}
                                                    </BottomScrollListener>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {this.state.scrollTerm &&
                                                        <div onClick={this.confirmHide} className="cancelbillingModal">I Accept</div>
                                                    }
                                                </Modal.Footer>
                                            </Modal>
                                        </CCard>

                                    </div>
                                    {/* </CCol> */}
                                    {/* </CRow> */}
                                </main>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        UserProfile: state.UserProfile
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUserProfile: () => dispatch(UserProfileAction())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Billing);