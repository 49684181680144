
export const getPublicIpAddress = () => {
    return new Promise((resolve, reject) => {
        const peerConnection = new RTCPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
        });
        peerConnection.createDataChannel("");
        peerConnection
            .createOffer()
            .then((offer) => peerConnection.setLocalDescription(offer))
            .catch((error) => {
                console.error("Error creating offer:", error);
                reject(error);
            });
        peerConnection.onicecandidate = (event) => {
            if (event?.candidate) {
                const ipRegex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g;
                const ipAddress = (event.candidate.candidate.match(ipRegex) ?? [])[0];
                if (ipAddress) {
                    localStorage.setItem("publicIPAddress", ipAddress);
                    resolve(ipAddress);
                }
            }
        };
    });
};


export const getInternetPublicIpAddress = () => {
    const publicIPAddress = localStorage.getItem('publicIPAddress');
    if (!publicIPAddress || publicIPAddress !== null) {
        const peerConnection = new RTCPeerConnection({ iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] });
        peerConnection.createDataChannel('');
        peerConnection.createOffer()
            .then((offer) => peerConnection.setLocalDescription(offer))
            .catch((error) => console.log(error));
        peerConnection.onicecandidate = (event) => {
            if (event?.candidate && event?.candidate?.candidate) {
                if (event?.candidate && event?.candidate?.candidate !== null) {
                    try {
                        const ipRegex = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/g;
                        const ipAddress = (event?.candidate?.candidate?.match(ipRegex) ?? [])[0]; // Added nullish coalescing and array destructuring
                        if (ipAddress) {
                            localStorage.setItem('publicIPAddress', ipAddress);
                        } else {
                            console.error("Invalid IP address extracted.");
                        }
                    } catch (error) {
                        console.error("Error extracting IP address:", error);
                    }
                }
            }
        }
    }
}