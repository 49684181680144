import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import PasswordField from "components/passwordfield/passwordfield";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";

class Editvendor extends React.Component {
  state = {
    loader: true,
    open: false,
    message: "",
    successError: "",
    vendorType: "",
    email: "",
    password: "",
    teamName: "",
    accountTypeVal: "Vendor",
    deliverymsg_time: "",
    auto_scan_events: "",
    leagueVal: [],
    teamNameVal: [],
    posTypeVal: null,
    marketTypeVal: "",
    error: [],
    editEmail: false,
  };

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }

    const data = {
      id: this.props.match.params.id,
    };

    const vendorEdit_api_url =
      process.env.REACT_APP_API_URL + "/api/account/detailAccount";
    await axios
      .post(vendorEdit_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: false,
            message: "",
            successError: "success",
            email: data.data.username,
            deliverymsg_time: data.data.deliverymsg_time,
            auto_scan_events: data.data.auto_scan_events,
            vendorTypeVal: data.data.vendor,
            leagueVal: data.data.vendor === "AXS" ? "AXS" : data.data.league,
            teamNameVal: data.data.vendor === "AXS" ? "AXS" : data.data.team,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });
  }

  handleClickEditEmail = () => {
    this.setState({ editEmail: !this.state.editEmail });
  };

  componentWillUnmount() {
    // if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
    //     ValidatorForm.removeValidationRule('isPasswordMatch');
    // }
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChangeAccount = (e) => {
    this.setState({
      accountTypeVal: e.target.innerText,
    });
  };
  handleChangeVendor = (event, newValue) => {
    this.setState({
      vendorTypeVal: newValue,
    });
  };
  handleChangeLeague = (event, newValue) => {
    this.setState({
      leagueVal: newValue,
      teamNameVal: [],
    });
  };
  handleChangeTeam = (event, newValue) => {
    this.setState({
      teamNameVal: newValue,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSubmit = async () => {
    this.setState({
      loader: true,
    });

    const data = {
      id: this.props.match.params.id,
      account_type: this.state.accountTypeVal,
      pos: this.state.posTypeVal,
      marketplace: this.state.marketTypeVal,
      vendor: this.state.vendorTypeVal,
      league: this.state.vendorTypeVal === "AXS" ? "AXS" : this.state.leagueVal,
      team: this.state.vendorTypeVal === "AXS" ? "AXS" : this.state.teamNameVal,
      username: this.state.email,
      password: this.state.password,
    };

    // username: this.state.editEmail ? this.state.email :"",

    const addAccount_api_url =
      process.env.REACT_APP_API_URL + "/api/account/updateAccount";
    await axios
      .post(addAccount_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
       

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            eventList: data.data,
            open: true,
            message: data.message,
            successError: "success",
          });
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  render() {
    const inputStyle = { textAlign: "left", paddingTop: "20px " };
    const { email, password, loader } = this.state;
    const labelStyle = {
      fontWeight: "500",
      fontFamily: "Barlow",
      color: "#002986",
    };
    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Vendor Edit</title>
          </Helmet>
          <Header />

          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />

            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {!loader ? 
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card>
                          <CardHeader 
                          title=" Edit Accounts"
                          action={
                            <Grid
                              container
                              spacing={{ xs: 1, md: 1, lg: 1 }}
                              columns={{ xs: 4, sm: 8, md: 8 }}
                              className="button-align"
                            >
                              <Grid item>
                                <Button
                                  type="button"
                                  onClick={()=> this.props.history.goBack()}
                                  size="large"
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                  }}
                                  startIcon={<ArrowBack />}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </Grid>
                          }
                           />
                          <CardContent>
                            <ValidatorForm onSubmit={this.handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>
                                      Account type
                                    </label>

                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      name="accountTypeVal"
                                      value={this.state.accountTypeVal}
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>
                                      Choose Vendor
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Enter Email"
                                      name="vendorTypeVal"
                                      value={this.state.vendorTypeVal}
                                      disabled={true}
                                    />
                                  </div>
                                </Grid>

                                {this.state.leagueVal !== "" && (
                                  <Grid item xs={12} sm={6}>
                                    <div style={inputStyle}>
                                      <label style={labelStyle}>League</label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        name="leagueVal"
                                        value={this.state.leagueVal}
                                        disabled={true}
                                      />
                                    </div>
                                  </Grid>
                                )}

                                {this.state.teamNameVal !== "" && (
                                  <Grid item xs={12} sm={6}>
                                    <div style={inputStyle}>
                                      <label style={labelStyle}>Team</label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        name="teamNameVal"
                                        value={this.state.teamNameVal}
                                        disabled={true}
                                      />
                                    </div>
                                  </Grid>
                                )}

                                {/* <Grid item xs={12} sm={6}>
                                                                        <div style={inputStyle}>
                                                                             <label style={labelStyle}>Send Delivery message</label>
                                                                            <RadioGroup 
                                                                                aria-label="deliverymsg_time"
                                                                                defaultValue="immediate"
                                                                                name="deliverymsg_time"
                                                                                className="scan-condition-opts"
                                                                            >
                                                                            <FormControlLabel value="immediate" control={<Radio disabled color="primary"/>} label="Immediate" />
                                                                            <FormControlLabel value="48 hours before" control={<Radio disabled color="primary" />} label="48 Hours Before" />
                                                                            </RadioGroup>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <div style={inputStyle}>
                                                                             <label style={labelStyle}>Auto Scan</label>
                                                                            <RadioGroup 
                                                                                aria-label="auto_scan_events"
                                                                                defaultValue={this.state.auto_scan_events}
                                                                                name="auto_scan_events"
                                                                                className="scan-condition-opts"
                                                                                disabled="disabled"
                                                                            >
                                                                            <FormControlLabel value="on" control={<Radio disabled color="primary"/>} label="On" />
                                                                            <FormControlLabel value="off" control={<Radio disabled color="primary"/>} label="Off" />
                                                                            </RadioGroup>
                                                                        </div>
                                                                    </Grid> */}

                                <Grid item xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>Email</label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      placeholder="Enter Email"
                                      name="email"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={
                                                this.handleClickEditEmail
                                              }
                                              // onMouseDown={handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {this.state.editEmail ? (
                                                <CancelIcon />
                                              ) : (
                                                <EditIcon />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      value={email}
                                      disabled={
                                        this.state.editEmail ? false : true
                                      }
                                      validators={["required", "isEmail"]}
                                      errorMessages={[
                                        "This field is required",
                                        "email is not valid",
                                      ]}
                                    />
                                    {this.state.error.email && (
                                      <div className="error-msg">
                                        {this.state.error.email}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>Password </label>
                                    <PasswordField
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="password"
                                      value={password}
                                      placeholder="Enter Password"
                                      eyestyle={{
                                        position: "absolute",
                                        right: "20px",
                                        top: "44px",
                                      }}
                                    />
                                    <small>
                                      <i>
                                        ( * Please enter password if you want to
                                        change otherwise leave it blank)
                                      </i>
                                    </small>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} sx={{
                                  textAlign: "center",
                                }}>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="theme-btn"
                                    size="large"
                                    sx={{ margin: "17px auto " }}
                                  >
                                    Edit Account
                                  </Button>
                                </Grid>
                              </Grid>

                            </ValidatorForm>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    : 
                    <ContainerLoader />
                  }
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
export default Editvendor;
