import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import customDateFormat from "utils/customDateFormat";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class AdminVendorEventslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      open: false,
      message: "",
      alertcolor: "",
      lists: [],
      vendor: {},
      filterlists: [],
    };
  }

  componentDidMount() {
    this.getLists();
  }

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/account/getAccountEvents";
    axios
      .post(
        list_api_url,
        { id: this.props.match.params.id },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
            vendor: data.vendorinfo,
            filterlists: data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  render() {
    const { filterlists, loader, vendor } = this.state;
    const fields = [
      { key: "event_name", label: "Title" },
      { key: "date", label: "Date/Time" },
      { key: "location", label: "Location" },
      { key: "status", label: "Status" },
      { key: "Actions", label: "Actions" },
    ];
    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Vendor Events List</title>
          </Helmet>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Vendor Events" />
                  <Divider />
                  <CardContent className="plan-tbl">
                    <div>
                      {vendor.league && (
                        <span style={{ paddingRight: "20px" }}>
                          <strong>League: </strong>
                          {vendor.league}
                        </span>
                      )}

                      {vendor.team && (
                        <span style={{ paddingRight: "20px" }}>
                          <strong>Team: </strong>
                          {vendor.team}
                        </span>
                      )}

                      {vendor.username && (
                        <span style={{ paddingRight: "20px" }}>
                          <strong>Email: </strong>
                          {vendor.username}
                        </span>
                      )}

                      {vendor.event && (
                        <span>
                          {vendor.event.status.includes(
                            "Scan Completed: Events"
                          ) && vendor.fetchingevent_scheduler !== "finished" ? (
                            <>
                              <strong>Status: </strong>Scan Completed:{" "}
                              {filterlists.length > 0
                                ? "loading more events"
                                : "loading events"}
                            </>
                          ) : (
                            <>
                              <strong>Status: </strong>
                              {vendor.event.status}
                            </>
                          )}
                        </span>
                      )}
                    </div>

                    <DataTable
                      responsive
                      items={filterlists}
                      fields={fields}
                      hover
                      className="vendor-td"
                      scopedSlots={{
                        date: (item) => {
                          return customDateFormat(item.date);
                        },
                        status: (item) => {
                          return (
                            <>
                              {item.status.includes("Scan Completed:") &&
                              item.fetch_tickets_scheduler === "start" ? (
                                <>Scan Completed: loading tickets</>
                              ) : (
                                <>
                                  {item.status === "loaded" &&
                                  item.num_tickets > 0 ? (
                                    <>
                                      Scan Completed: Tickets Added:{" "}
                                      {item.num_tickets}
                                    </>
                                  ) : (
                                    <>{item.status}</>
                                  )}
                                </>
                              )}
                            </>
                          );
                        },
                        Actions: (item) => {
                          return (
                            <div className="icon-outer-font">
                              <div className="flex">
                                <div className="icon-space-left">
                                  {item.status.includes("tickets Added") ||
                                  item.status.includes("ticket Added") ||
                                  item.status.includes("Tickets Added") ||
                                  item.status.includes("loaded") ? (
                                    <Button
                                      component={Link}
                                      size="small"
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        padding: "2px 5px!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                      }}
                                      to={`/admin-vendor-event-tickets/${item._id}`}
                                    >
                                      View
                                    </Button>
                                  ) : (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      disabled={true}
                                      sx={{
                                        backgroundColor: "#002986b5!important",
                                        color: "white!important",
                                        padding: "2px 5px!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                      }}
                                    >
                                      View
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        },
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default AdminVendorEventslist;
