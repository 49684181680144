import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "components/pagination/pagination";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import DataTable from "components/DataTable/DataTable";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import Cookies from "js-cookie";

class UserMessageslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      searchPage: 0,
      messages: [],
      filterData: {
        datefrom: null,
        dateto: null,
        limit: 10,
        skip: 0,
      },
      filterloaded: true,
      totalrecords: 0,
    };

    this.getList = this.getList.bind(this);
    this.setDate = this.setDate.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  getList() {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/messages/getUser";
    axios
      .post(list_api_url, this.state.filterData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({ loader: false, messages: [], totalrecords: 0 });
        } else {
          this.setState({
            loader: false,
            messages: data.msgs,
            totalrecords: data.totalrecord,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong");
      });
  }

  setDate(date, fieldname) {
    const { filterData } = this.state;
    filterData[fieldname] = date;
    this.setState({ filterData });
  }

  clearDate(fieldname) {
    const { filterData } = this.state;
    filterData[fieldname] = null;
    this.setState({ filterData });
  }

  handleSubmit() {
    const { filterData } = this.state;
    filterData.skip = 0;
    this.setState({ searchPage: 1, loader: true, filterData }, () => {
      this.getList();
    });
  }

  onPageChanged(data) {
    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    filterData.skip = (currentPage - 1) * pageLimit;
    this.setState({ searchPage: 1, loader: true, filterData }, () => {
      this.getList();
    });
  }

  render() {
    const { loader, messages, filterData, filterloaded, totalrecords } =
      this.state;

    const fields = [
      { key: "phone_number", label: "Phone Number", sort: true },
      { key: "message_body", label: "Message", sort: true },
      { key: "sentat", label: "Sent At", sort: true },
    ];

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>User Dashboard</title>
          </Helmet>

          <Header />

          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />

            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs="12" lg="12">
                        <Card>
                          <CardHeader title="Messages" />
                          <CardContent className="plan-tbl">
                            {filterloaded && (
                              <ValidatorForm
                                onSubmit={this.handleSubmit}
                                className="filter-form mb-5"
                              >
                                <Grid
                                  container
                                  spacing={2}
                                  className="interactive-filter-row sold-outer-admin"
                                >
                                  <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <div className="">
                                      <Typography
                                        sx={{
                                          textAlign: "left",
                                          paddingBottom: "10px!important",
                                        }}
                                      >
                                        From Date
                                      </Typography>
                                      <DatePicker
                                        selected={filterData.datefrom}
                                        onChange={(date) =>
                                          this.setDate(date, "datefrom")
                                        }
                                        isClearable={true}
                                        customInput={
                                          <TextField
                                            variant="outlined"
                                            className="filter-input"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  className="date-icon"
                                                >
                                                  <div className="d-flex">
                                                    {!filterData.datefrom ? (
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                      >
                                                        <EventIcon className="text-dark" />
                                                      </IconButton>
                                                    ) : (
                                                      <IconButton
                                                        edge="end"
                                                        onClick={() =>
                                                          this.clearDate(
                                                            "datefrom"
                                                          )
                                                        }
                                                      >
                                                        <ClearIcon className="text-dark" />
                                                      </IconButton>
                                                    )}
                                                  </div>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        }
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <div className="form-group">
                                      <label>To Date</label>
                                      <DatePicker
                                        selected={filterData.dateto}
                                        onChange={(date) =>
                                          this.setDate(date, "dateto")
                                        }
                                        isClearable={true}
                                        customInput={
                                          <TextField
                                            variant="outlined"
                                            className="filter-input"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  className="date-icon"
                                                >
                                                  <div className="d-flex">
                                                    {!filterData.dateto ? (
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                      >
                                                        <EventIcon className="text-dark" />
                                                      </IconButton>
                                                    ) : (
                                                      <IconButton
                                                        edge="end"
                                                        onClick={() =>
                                                          this.clearDate(
                                                            "dateto"
                                                          )
                                                        }
                                                      >
                                                        <ClearIcon className="text-dark" />
                                                      </IconButton>
                                                    )}
                                                  </div>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        }
                                      />
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    sm={6}
                                    lg={3}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "left",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      type="submit"
                                      sx={{
                                        marginTop: "30px!important",
                                      }}
                                      className="filter-btn "
                                      startIcon={<HiOutlineSearch />}
                                    >
                                      Search
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            )}

                            {loader ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "13rem 0",
                                }}
                              >
                                <CircularProgress sx={{ color: "#002986" }} />
                              </Box>
                            ) : (
                              <>
                                <DataTable
                                  items={messages}
                                  fields={fields}
                                  itemsPerPage={10}
                                  sorter
                                  scopedSlots={{
                                    phone_number: (item) => {
                                      return (
                                        <div
                                          style={{
                                            width: "100%",
                                            minWidth: "150px",
                                          }}
                                        >
                                          {item.phone_number}
                                        </div>
                                      );
                                    },
                                  }}
                                />
                              </>
                            )}
                            {totalrecords > filterData.limit && (
                              <Pagination
                                totalRecords={totalrecords}
                                searchPage={this.state.searchPage}
                                SearchDisable={this.SearchDisable}
                                pageLimit={filterData.limit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                              />
                            )}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                </main>
              </div>
              <AlertMessage
                open={this.state.open}
                message={this.state.message}
                severity={this.state.successError}
                closeCall={this.handleClose}
              />
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
export default UserMessageslist;
