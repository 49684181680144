import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import { Helmet } from "react-helmet";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Switch
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class Vendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      modalShow: false,
      deleteId: "",
      deleting: false,
      loader: true,
      open: false,
      message: "",
      alertcolor: "",
      modalform: {
        show: false,
        id: "",
        title: "",
        name: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
        is_active: 0
      },
    };
  }

  componentDidMount() {
    this.getLists();
  }

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllVendors";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false, deleteId: "" });
  };

  callDelete = () => {
    this.setState({ deleting: true });
    const data = { _id: this.state.deleteId };
    const delete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteVendor";
    axios
      .post(delete_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            alertcolor: "error",
          });
        } else {
          if (data.success) {
            this.getLists();
            this.setState({
              modalShow: false,
              open: true,
              message: "Deleted successfully!",
              alertcolor: "success",
              deleting: false,
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  setModalFormShow = (id, title, name,is_active) => {
    const modalform = {
      show: true,
      id: id,
      title: title,
      name: name,
      submitting: false,
      is_active: is_active
    };
    this.setState({ modalform });
    // this.setState({ })
  };

  onHideForm = () => {
    const modalform = {
      show: false,
      id: "",
      title: "",
      name: "",
      submitting: false,
    };
    this.setState({ modalform });
  };

  handleSubmit = () => {
    const { modalform } = this.state;
    modalform.submitting = true;
    this.setState({ modalform }, () => {
      let form_data = { name: modalform.name };
      let form_api_url = process.env.REACT_APP_API_URL + "/api/admin/addVendor";
      let successmsg = "Added successfully!";
      if (modalform.id !== "") {
        form_api_url =
          process.env.REACT_APP_API_URL + "/api/admin/updateVendor";
        form_data = { name: modalform.name, _id: modalform.id, is_active: modalform.is_active };
        successmsg = "Updated successfully!";
      }

      axios
        .post(form_api_url, form_data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            modalform.submitting = false;
            modalform.formalert = true;
            modalform.formmessage = data.data.message;
            modalform.formalertcolor = "error";
            this.setState({ modalform });
          } else {
            if (data.success) {
              this.getLists();
              const modalform_new = {
                show: false,
                id: "",
                title: "",
                name: "",
                submitting: false,
                formalert: false,
                formmessage: "",
                formalertcolor: "",
              };
              this.setState({
                modalform: modalform_new,
                open: true,
                message: successmsg,
                alertcolor: "success",
              });
            } else {
              this.setState({
                formalert: true,
                formmessage: data.data.message,
                formalertcolor: "error",
              });
            }
          }
        })
        .catch((error) => {
          modalform.submitting = false;
          modalform.formalert = true;
          modalform.formmessage = "Something went wrong";
          modalform.formalertcolor = "error";
          this.setState({ modalform });
        });
    });
  };

  handleChange = (e) => {
    const { modalform } = this.state;
    modalform.name = e.target.value;
    this.setState({ modalform });
  };

  handleChangeStatus = (e) => {
    const { modalform } = this.state;
    modalform.is_active = e.target.checked ? 1 : 0
    this.setState({ modalform });
  };

  render() {
    const { lists, modalShow, loader, modalform } = this.state;
    const dataLists = lists.sort((a, b) => a.name.localeCompare(b.name))
    const fields = [
      { key: "name", label: "Name" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
      },
    ];

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Vendor List</title>
          </Helmet>
          <Collapse in={this.state.open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={this.state.alertcolor}
              sx={{ mb: 2 }}
            >
              {this.state.message}
            </Alert>
          </Collapse>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title="Vendor List"

                  action={
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        this.setModalFormShow("", "Add Vendor", "")
                      }
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Package 
                    </Button>
                  }
                />
                <Divider />

                <CardContent>
                  <DataTable
                    responsive
                    tableFilter
                    items={dataLists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      action: (item) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                this.setModalFormShow(
                                  item._id,
                                  "Update Vendor",
                                  item.name,
                                  item.is_active
                                )
                              }
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.setModalShow(item._id)}
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalShow}
            onClose={this.onHide}
          >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={() => this.onHide()}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogContentText>
                Are you sure you want to Delete, this action can not be revert ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onHide}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.callDelete}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalform.show}
            onClose={this.onHideForm}
          >
            <DialogTitle>{modalform.title}</DialogTitle>
            <ValidatorForm onSubmit={this.handleSubmit}>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.onHideForm()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>

                <div>
                  <label>Vendor name</label>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    name="names"add-vendor
                    onChange={this.handleChange}
                    value={modalform.name}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
                <div>
                  <label>Status: </label>
                  <Switch
                    checked={modalform.is_active === 1}
                    onChange={this.handleChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onHideForm}
                  type="button"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default Vendor;
