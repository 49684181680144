import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import loaderGif from "images/GIF-Loader-3.gif";
import Google from "images/g.png";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Moment from "moment";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import PageLoader from "components/PageLoader/PageLoader";
import Usersidebar from "components/user-sidebar/user-sidebar";
import customDateFormat from "utils/customDateFormat";
import { connect } from "react-redux";
import UserProfileAction from "redux/actions/UserProfielAction";
import { grey, indigo } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link as MUiLink } from "@mui/material";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import detailTimeFormat from "utils/detailTimeForm";
import PropTypes from "prop-types";
import { ProgressAction } from "redux/actions/ProgressAction";
import DataTable from "components/DataTable/DataTable";
import AlertMessage from "components/message/message";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      sx={{
        Transition: "all 0.3s ease-in-out",
      }}
      ref={ref}
      {...props}
    />
  );
});

// make styled Paper 

const StyledBox = styled("Paper")(({ theme }) => ({
  backgroundColor: '#f7f7f7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  border: "1px solid  #d9d8d8",
  marginRight: "10px",
  borderRadius: "5px",
  [theme.breakpoints.down('sm')]: {
    margin: "4px 6px",
    width: "100%",
  },
  [theme.breakpoints.down('lg')]: {
    margin: "4px 7px"
  },
}))

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value || 0} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          isNaN(props.value) ? 0 : props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "Barlow !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class VendorEventTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      user_deliverymsg_time: "immediate",
      open: false,
      message: "",
      alertcolor: "success",
      event: {},
      lists: [],
      vendorAccountInfo: {},
      filterlists: [],
      vendor: "",
      generateTicketMsg: "",
      generateTicketMsgAlertColor: "success",
      generateTicketMsgOpen: false,
      submttingticketform: false,
      generateTicketData: {
        customerDisplayName: "",
        performer_name: "",
        invoiceExternalRef: "",
        uploadtovivid: false,
        custfromvivid: false,
        invoiceDate: "",
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        deliverymsg_time: "immediate",
        ticketData: [],
        ticketIds: [],
        // skipExistCondition: false,
      },
      generateTicketModal: false,
      generatedTicketDetailModal: false,
      copiedTicketUrl: false,
      generatedTicketUrl: null,
      generateopen: false,
      generateresponsemsg: "",
      errorInFulfillingOrder: false,
      bulkGeneratedData: [],
      posType: null,
      generateTicketModalTV: false,
      generatedModal: false,
      copiedIndex: null,
      copiedUrl: false,
      progressActive: false,
      newGeneratedTicket: {},
      axsLink: "",
      axsSuccess: false,
    };
    this.chooseticket = this.chooseticket.bind(this);
    this.submitGenerateTicketTV = this.submitGenerateTicketTV.bind(this);
    this.submitGenerateTicket = this.submitGenerateTicket.bind(this);
    this.submitGenerateTicketAXS = this.submitGenerateTicketAXS.bind(this);
    this.serverSideProgress = this.serverSideProgress.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    this.eventSource = null;
  }

  componentDidMount() {
    this.getEventTickets();
    const data = this.props.UserProfile;
    if (!data.error) {
      if (data.data.deliverymsg_time) {
        const { generateTicketData } = this.state;
        generateTicketData.deliverymsg_time = data.data.deliverymsg_time;
        this.setState({
          generateTicketData,
          user_deliverymsg_time: data.data.deliverymsg_time,
        });
      }
    }
  }
  handleToggleGenerate = () => {
    this.setState({ generateopen: !this.state.generateopen });
  };

  handleClosemessage = () => {
    this.setState({ message: "", messageopen: false });
  };
  // handleRegenerateTicket = () => {
  //   const { generateTicketData } = this.state;
  // generateTicketData.skipExistCondition = true;
  //   this.setState({ generateTicketData, generateopen: false }, () => {
  //     this.state.posType && this.state.vendor === "AXS"
  //       ? this.submitGenerateTicketAXS()
  //       : this.submitGenerateTicket();
  //   });
  // };

  serverSideProgress = (id, generateType) => {
    this.eventSource.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      const totalEntries = data.data.totalEntries;
      const completedEntries = data.data.completedEntries;

      const progress = Math.round((completedEntries / totalEntries) * 100);
      this.setState({ progress });
      if (data.data.status === "completed" && this.state.progressActive) {
        const updatedData = data?.data?.response?.data.map((item) => ({
          event_name: item.event_name,
          date: item.date,
          account: item.account,
          vendor: item.vendor,
          section: item.section,
          game_id: item?.game_id,
          barcode_id: item?.barcode_id,
          order_id: item.order_id,
          location: item?.location,
          row: item.row,
          seat: item.seat,
          ticketUrl: item.ticketUrl,
          team: item.team,
        }));
        this.setState(
          {
            progressActive: false,
            bulkGeneratedData:
              generateType === "singleGenerate"
                ? updatedData
                : data?.data?.response?.data,
          },
          () => {
            this.eventSource.close();
            this.setState({
              bulkConfirm: false,
              generatedModal: true,
              loader: false,
              progress: 0,
            });

            this.deleteProgressTask(id);
          }
        );
      } else if (data.data.status === "failed" && this.state.progressActive) {
        this.setState({ progressActive: false });

        if (data.data.response.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            generateTicketModalTV: false,
            generateresponsemsg: data.data.response.message,
            generateopen: true,
            loader: false,
          });
        } else {
          this.setState({
            messageopen: true,
            message: data.data.response.message,
            messagetype: "error",
            progress: 0,
          });
        }
        this.eventSource.close();
        this.getEventTickets();
      } else if (data.data.message === "Error in getting progress!") {
        this.setState({
          messageopen: true,
          message: "Something went wrong!",
          messagetype: "error",
          progress: 0,
        });
        this.eventSource.close();
        this.getEventTickets();
      }
    };
  };

  deleteProgressTask = (id) => {
    const deleteTaskApi = `${process.env.REACT_APP_API_URL}/api/user/deleteTask`;

    axios
      .post(
        deleteTaskApi,
        { _id: id },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('accessToken')}`,
          },
        }
      )
      .then((res) => {
        this.getEventTickets();
      })
      .catch((err) => {
        console.log("deleteTask", err);
      });
  };
  getEventTickets = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getAccountEventTickets";
    axios
      .post(
        list_api_url,
        { id: this.props.match.params.id },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.taskId) {
          this.setState({ progressActive: true, loader: false });
          this.props.addId(data.taskId);
          this.eventSource = new EventSource(
            process.env.REACT_APP_API_URL +
            `/api/user/bulkGenerate/status/${data.taskId}`
          );
          this.serverSideProgress(data.taskId, "getEvents");
        } else {
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          } else {
            this.setState({ vendorAccountInfo: data.vbdevent.account_id })
            var datalist = data.data
              .map((item) => {
                return {
                  ...item,
                  status: item.soldinventory
                    ? "Sold"
                    : item.delivery_revoked
                      ? "Revoked/Available"
                      : item.is_verified
                        ? "Available"
                        : "Not Available",
                  vendor: data.vbdevent.account_id.vendor,
                };
              })
              .sort(
                (a, b) =>
                  a.section.localeCompare(b.section) ||
                  a.row.localeCompare(b.row) ||
                  parseInt(a.seat) - parseInt(b.seat)
              );

            // Use reduce() to group the tickets by username and row
            const groupedTickets = datalist.reduce((acc, ticket) => {
              const key = ticket.username + "-" + ticket.row;
              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(ticket);
              return acc;
            }, {});

            // Create pairs of rows based on matching username and row values
            const pairs = [];
            Object.values(groupedTickets).forEach((userRowTickets) => {
              const row = userRowTickets[0].row;
              const matchingUserRowTickets = Object.values(groupedTickets).find(
                (otherUserRowTickets) => {
                  return (
                    otherUserRowTickets[0].username !==
                    userRowTickets[0].username &&
                    otherUserRowTickets[0].row === row
                  );
                }
              );
              if (matchingUserRowTickets) {
                pairs.push([userRowTickets, matchingUserRowTickets]);
              }
            });

            // Check each row for both sold and available tickets with vendor Axs
            pairs.forEach(([tickets1, tickets2]) => {
              const axsSoldTickets = tickets1
                .concat(tickets2)
                .filter((ticket) => {
                  return ticket.vendor === "AXS" && ticket.status === "Sold";
                });
              const axsAvailableTickets = tickets1
                .concat(tickets2)
                .filter((ticket) => {
                  return (
                    ticket.vendor === "AXS" && ticket.status === "Available"
                  );
                });
              if (axsSoldTickets.length > 0 && axsAvailableTickets.length > 0) {
                axsSoldTickets.forEach((ticket) => {
                  if (ticket.vendor === "AXS") {
                    ticket.soldstatus = true;
                  }
                });
                axsAvailableTickets.forEach((ticket) => {
                  if (ticket.vendor === "AXS") {
                    ticket.soldstatus = true;
                  }
                });
              }
            });
            // Check each row for sold tickets with vendor Axs
            Object.values(groupedTickets).forEach((rowTickets) => {
              const hasSold = rowTickets.some(
                (ticket) => ticket.status === "Sold" && ticket.vendor === "AXS"
              );

              if (hasSold) {
                rowTickets.forEach((ticket) => {
                  if (ticket.vendor === "AXS" && ticket.status !== "Sold") {
                    ticket.soldstatus = true;
                    ticket.status = "Not Available";
                  }
                });
              }
            });
            const finalData = datalist.sort((a, b) => {
              const statusOrder = {
                Available: 1,
                "Not Available": 2,
                Sold: 3,
              };
              const aStatus = statusOrder[a.status] || 0;
              const bStatus = statusOrder[b.status] || 0;

              if (aStatus !== bStatus) {
                return aStatus - bStatus;
              } else {
                return 0;
              }
            });

            this.setState({
              posType: data.pos,
              loader: false,
              lists: data.data,
              filterlists: finalData,
              event: data.vbdevent,
              vendor: data.vbdevent.account_id.vendor,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };
  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close(); // close the EventSource instance before unmounting
    }
  }

  chooseticket = (t, evt) => {
    const { generateTicketData } = this.state;
    var validChoice = true;
    if (generateTicketData.ticketData.length > 0) {
      if (
        generateTicketData.ticketData[0].section !== t.section ||
        generateTicketData.ticketData[0].row !== t.row
      ) {
        this.setState({
          message: "You must select ticket from same row and section",
          messageopen: true,
          messagetype: "error",
        });
        validChoice = false;
      }
    }

    if (validChoice === true) {
      if (evt.target.checked) {
        const { ticketIds, ticketData } = generateTicketData;
        generateTicketData.ticketIds = [...ticketIds, t.id];
        generateTicketData.ticketData = [...ticketData, t];
        this.setState({ generateTicketData });
      } else {
        if (generateTicketData.ticketIds.length > 0) {
          const { ticketIds, ticketData } = generateTicketData;
          var index = ticketIds.indexOf(t.id);
          ticketIds.splice(index, 1);
          ticketData.splice(index, 1);
          if (ticketIds.length > 0) {
            generateTicketData.ticketData = ticketData;
            generateTicketData.ticketIds = ticketIds;
            this.setState({ generateTicketData });
          } else {
            generateTicketData.ticketData = [];
            generateTicketData.ticketIds = [];
            this.setState({ generateTicketData });
          }
        }
      }
    }
  };

  convertToCSV = (data) => {
    const dataFiltered = data.map((item, index) => {
      const rowValue = typeof item.row === 'number' ? item.row ?? "" : item.row ?? "";
      const seatNumbers = typeof item.seat === 'number' ? item.seat ?? "" : item.seat ?? "";
      const section = typeof item.section === 'number' ? item.section ?? "" : item.section ?? "";
      return {
        "Performer Name":
          item.vendor === "Ticketmaster Account Manager"
            ? item.team.replace(/[^a-zA-Z ]/g, "")
            : item.event_name.replace(/[^a-zA-Z ]/g, "") ?? "",
        "Event Title": item.event_name.replace(/[^a-zA-Z ]/g, ""),
        EventDateTime: " " + detailTimeFormat(item.date) + " ",
        Section: '\t' + section,
        Row: '\t' + rowValue,
        Seat: '\t' + seatNumbers,
        Barcode: "",
        "Ticket Url": item.ticketUrl,
      }
    });

    const csv = Papa.unparse(dataFiltered);

    return csv;
  };

  exportToCSV = (data) => {
    const csv = this.convertToCSV(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "generatedLinks.csv");
  };

  generateModal = (val) => {
    if (this.state.posType === "TV" || this.state.posType === "TA") {
      this.setState({ generateTicketModalTV: val });
    } else {
      this.setState({ generateTicketModal: val });
    }
  };

  generatedDetailModalHide = () => {
    var generateTicketData = {
      invoiceExternalRef: "",
      invoiceDate: "",
      customerEmail: "",
      customerPhone: "",
      customerName: "",
      uploadtovivid: false,
      custfromvivid: false,
      ticketData: [],
      ticketIds: [],
      deliverymsg_time: this.state.user_deliverymsg_time,
    };

    this.setState({
      generateTicketData,
      generateTicketModal: false,
      generatedTicketUrl: null,
      errorInFulfillingOrder: false,
      copiedTicketUrl: false,
      generateTicketEvent: null,
      generatedTicketDetailModal: false,
      generateTicketMsg: null,
      generateTicketMsgOpen: false,
    });
  };

  generatedDetailModalHideTV = () => {
    var generateTicketData = {
      invoiceExternalRef: "",
      invoiceDate: "",
      customerEmail: "",
      customerPhone: "",
      customerName: "",
      uploadtovivid: false,
      custfromvivid: false,
      ticketData: [],
      ticketIds: [],
      deliverymsg_time: this.state.user_deliverymsg_time,
    };

    this.setState({
      generateTicketModalTV: false,
      expanded: false,
      openedevent: null,
      generateTicketData,
      generateTicketModal: false,
      generatedTicketUrl: null,
      errorInFulfillingOrder: false,
      copiedTicketUrl: false,
      generateTicketEvent: null,
      generateTicketMsg: null,
      generateTicketMsgOpen: false,
    });
  };
  copyMutipleUrl = (text, index) => {
    navigator.clipboard.writeText(text);
    this.setState({ copiedUrl: true, copiedIndex: index });
  };

  submitGenerateTicket = () => {
    this.setState({ submttingticketform: true });
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, this.state.generateTicketData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error && !data.alreadyExists) {
          console.log("ErrorExists");
          this.setState({
            // skipExistCondition: false,
            generateTicketMsg: data.message
              ? data.message
              : "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
            submttingticketform: false,
          });
        } else if (data.error && data.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            submttingticketform: false,
            generateresponsemsg: data.message,
            generateopen: true,
            loader: false,
          });

          this.setState({ submttingticketform: false, loader: false });
        } else {
          this.setState(
            {
              submttingticketform: false,
              // skipExistCondition: false,
              loader: true,
            },
            () => {
              this.getEventTickets(true);
            }
          );
          if (data.errorInFulfillingOrder) {
            this.setState({ errorInFulfillingOrder: true });
          } else {
            this.setState({ errorInFulfillingOrder: false });
          }

          this.setState({
            generatedTicketDetailModal: true,
            submttingticketform: false,
            generatedTicketUrl: data.ticketUrl,
          });
        }

        // this.setState({ submttingticketform: false, });
        // this.generatedDetailModalHide();
      });
  };
  submitGenerateTicketTV = async () => {
    this.setState({ submttingticketform: true });

    const vendorData = { ...this.state.generateTicketData };
    vendorData.customerDisplayName =
      (this.state.vendor === "AXS" || vendorData.customerDisplayName === "")
        ? this.state.posType
        : vendorData.customerDisplayName;
    vendorData.ticketData = await this.state.generateTicketData.ticketData.map(
      (ticket) => ({
        ...ticket,
        account: this.state.event.account_id.username && this.state.event.account_id.username,
        event_name: this.state.event.event_name && this.state.event.event_name,
        date: this.state.event.date && this.state.event.date,
        game_id: this.state.event.game_id && this.state.event.game_id,
        order_id: this.state.event.order_id && this.state.event.order_id,
        location: this.state.event.location && this.state.event.location,
      })
    );

    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrlTVuser";
    axios
      .post(ticket_api_url, vendorData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.data !== undefined) {
          if (data.error && !data.alreadyExists) {
            console.log("ErrorExists");
            this.generatedDetailModalHideTV();
            this.setState({
              // skipExistCondition: false,
              generateTicketMsg: data.message
                ? data.message
                : "There is issue in generating ticket",
              generateTicketMsgAlertColor: "error",
              generateTicketMsgOpen: true,
              generateTicketModalTV: false,
            });
          } else if (data.error && data.alreadyExists) {
            console.log("AlreadyExists");
            this.setState({
              loader: false,
              generateTicketModalTV: false,
              generateresponsemsg: data.message,
              generateopen: true,
            });
          } else {
            this.generatedDetailModalHideTV();
            const updatedData = data.data.map((item) => ({
              event_name: item.event_name,
              date: item.date,
              section: item.section,
              row: item.row,
              game_id: item?.game_id,
              barcode_id: item?.barcode_id,
              order_id: item.order_id,
              location: item?.location,
              seat: item.seat,
              ticketUrl: item.ticketUrl,
              vendor: item.vendor,
              team: item.team,
            }));
            this.setState({
              bulkGeneratedData: updatedData,
              generatedModal: true,
            });

            this.setState(
              {
                submttingticketform: false,
                // skipExistCondition: false,
                loader: true,
              },
              () => {
                this.getEventTickets(true);
              }
            );
            if (data.errorInFulfillingOrder) {
              this.setState({ errorInFulfillingOrder: true });
            } else {
              this.setState({ errorInFulfillingOrder: false });
            }

            this.setState({
              generatedTicketDetailModal: true,
              submttingticketform: false,
              generatedTicketUrl: data.ticketUrl,
            });
          }
        } else if (
          response.data.taskId !== "" &&
          response.data.taskId !== undefined &&
          response.data.taskId !== null &&
          response.data.taskInProgress
        ) {
          this.setState({
            generateTicketModalTV: false,
            progressActive: true,
            submttingticketform: false,
          });
          this.props.addId(response.data.taskId);

          this.eventSource = new EventSource(
            process.env.REACT_APP_API_URL +
            `/api/user/bulkGenerate/status/${response.data.taskId}`
          );
          this.serverSideProgress(response.data.taskId, "singleGenerate");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  submitGenerateTicketAXS = async () => {
    this.setState({ submttingticketform: true });
    var vendorData = { ...this.state.generateTicketData };
    // vendorData.customerDisplayName =
    //   this.state.vendor === "AXS"
    //     ? this.state.posType
    //     : vendorData.customerDisplayName;
    vendorData.customerDisplayName =
      (this.state.vendor === "AXS" || vendorData.customerDisplayName === "")
        ? this.state.posType
        : vendorData.customerDisplayName;

    vendorData.ticketData = await this.state.generateTicketData.ticketData.map(
      (ticket) => ({
        ...ticket,
        event_name:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].event_name,
        date:
          this.state.generateTicketEvent?.events &&
          this.state.generateTicketEvent.events[0].date,
      })
    );

    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, vendorData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error && !data.alreadyExists) {
          console.log("ErrorExists");
          this.setState({
            // skipExistCondition: false,
            generateTicketMsg: data.message
              ? data.message
              : "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
            submttingticketform: false,
          });
        } else if (data.error && data.alreadyExists) {
          console.log("AlreadyExists");
          this.setState({
            submttingticketform: false,
            generateresponsemsg: data.message,
            generateopen: true,
            loader: false,
          });
        } else {
          this.setState(
            {
              submttingticketform: false,
              // skipExistCondition: false,
              loader: true,
              axsSuccess: true,
              axsLink: data.ticketUrl,
            },
            () => {
              this.getEventTickets(true);
            }
          );
          if (data.errorInFulfillingOrder) {
            this.setState({ errorInFulfillingOrder: true });
          } else {
            this.setState({ errorInFulfillingOrder: false });
          }
          this.setState({
            generatedTicketDetailModal: true,
            submttingticketform: false,
            generatedTicketUrl: data.ticketUrl,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleModelChange = (e) => {
    const { generateTicketData } = this.state;
    generateTicketData[e.target.name] = e.target.value;
    this.setState({ generateTicketData });
  };
  handleModelChangeCheckbox = (e) => {
    const { generateTicketData } = this.state;
    generateTicketData[e.target.name] = e.target.checked;
    this.setState({ generateTicketData });
  };

  handleModelChangeSelect = (evt, val, name) => {
    const { generateTicketData } = this.state;
    if (val) {
      generateTicketData[name] = val.title;
    } else {
      generateTicketData[name] = null;
    }
    generateTicketData.uploadtovivid = false;
    generateTicketData.custfromvivid = false;

    this.setState({ generateTicketData });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  copyUrl = () => {
    this.setState({ copiedTicketUrl: true });
    navigator.clipboard.writeText(this.state.generatedTicketUrl);
  };

  generateBulkHide = () => {
    this.setState({
      generatedModal: false,
      copiedUrl: false,
      copiedIndex: null,
    });
    this.generatedDetailModalHideTV();
  };

  validationOftheformatValue = (value) => {
    return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
  }

  convertToCSVACTA = (data) => {
    const dataFiltered = data.map((item, index) => {
      const date = item && item.date ? " " + detailTimeFormat(item?.date) + " " : "";
      const rowValue = this.validationOftheformatValue(item?.row);
      const seatNumbers = this.validationOftheformatValue(item?.seat);
      const section = this.validationOftheformatValue(item?.section);
      const orderId = this.validationOftheformatValue(item?.order_id);
      const barcode_id = this.validationOftheformatValue(item?.barcode_id);
      return {
        "Vendor eMail": item?.account ?? "",
        "Performer Name": item?.team ?? "",
        "Game Title": item.event_name.replace(/[^a-zA-Z ]/g, "") ?? "",
        "Game ID": item.vendor === "Ticketmaster" ? '\t' + orderId ?? "" : '\t' + item?.game_id ?? "",
        "Venue": item?.location ?? "",
        "Event Date": '\t' + Moment(date).format("MM-DD-YYYY") ?? "",
        "Event Time": '\t' + Moment(date).format("HH:mm:ss") ?? "",
        "Section": '\t' + section ?? "",
        "Row": '\t' + rowValue ?? "",
        "Seat": '\t' + seatNumbers ?? "",
        "Barcode": '\t' + barcode_id ?? "",
        "Status": "Available",
        "Ticket Url": item.ticketUrl ?? "",
      };
    });
    return Papa.unparse(dataFiltered);
  }

  exportToCSVACTA = (data) => {
    const csv = this.convertToCSVACTA(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "generatedLinks.csv");
  };

  render() {
    const { filterlists, event, loader, generateTicketData, vendorAccountInfo } = this.state;
    const fields = [
      { key: "section", label: "Section" },
      { key: "row", label: "Row" },
      { key: "seat", label: "Seat" },
      { key: "is_verified", label: "Verified" },
      { key: "status", label: "Status" },
      { key: "google_wallet_link", label: "Google Wallet Link" },
      { key: "checked", label: "Checked" },
      { key: "view", label: "View" },
    ];
    const marketplace = [
      { title: "VividSeats" },
      { title: "SeatGeek" },
      { title: "Gametime" },
      { title: "Ticket Evolution" },
      { title: "Ticket Network" },
      { title: "TickPick" },
      { title: "StubHub" },
      { title: "Manual/None" },
      { title: "Other" },
    ];

    var externalRefProps = {};

    if (
      generateTicketData.customerDisplayName === "VividSeats" ||
      generateTicketData.customerDisplayName === "Gametime"
    ) {
      if (
        generateTicketData.uploadtovivid ||
        generateTicketData.custfromvivid
      ) {
        externalRefProps = {
          validators: ["required"],
          errorMessages: ["this field is required"],
        };
      }
    }

    var today = new Date();
    today.setDate(today.getDate() - 7);

    if (!this.state.progressActive) {
      return (
        <React.Fragment>
          <div className="user-dashboard-wrapper">
            <Helmet>
              <title>Events Tickets</title>
            </Helmet>

            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
              <Usersidebar />

              <div className="c-wrapper">
                <div className="c-body">
                  <main className="c-main">
                    {!loader ?
                      <>
                        <Collapse in={this.state.open}>
                          <Alert
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setState({ open: false });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            severity={this.state.alertcolor}
                            sx={{ mb: 2 }}
                          >
                            {this.state.message}
                          </Alert>
                        </Collapse>

                        {!loader && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} className="space-top-col">
                              <Card
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                className="vendorlist"
                              >
                                <CardHeader
                                  title="Events Tickets"
                                  action={
                                    <Grid
                                      container
                                      spacing={{ xs: 1, md: 1, lg: 1 }}
                                      columns={{ xs: 4, sm: 8, md: 8 }}
                                      className="button-align"
                                    >
                                      <Grid item>
                                        <Button
                                          onClick={() => this.props.history.goBack()}
                                          type="button"
                                          size="large"
                                          variant="contained"
                                          sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important",
                                          }}
                                          startIcon={<ArrowBack />}
                                        >
                                          Back
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                                <CardContent>
                                  <Box sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}>
                                    <span style={{
                                      fontWeight: "bold",
                                      marginRight: "10px",
                                    }}>Vendor Info:- </span>
                                    {vendorAccountInfo.league && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>League: </strong>
                                          {vendorAccountInfo.league}
                                        </span>
                                      </StyledBox>
                                    )}

                                    {vendorAccountInfo.team && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>Team: </strong>
                                          {vendorAccountInfo.team}
                                        </span>
                                      </StyledBox>
                                    )}


                                    {vendorAccountInfo.username && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>Email: </strong>
                                          {vendorAccountInfo.username}
                                        </span>
                                      </StyledBox>
                                    )}
                                    <br />
                                    {vendorAccountInfo.event && (
                                      <StyledBox>
                                        <span>
                                          <strong>Status: </strong>
                                          {vendorAccountInfo.event.status.includes(
                                            "Scan Completed: Events"
                                          ) ? <>
                                            {vendorAccountInfo.event.status}
                                          </>
                                            :
                                            <>
                                              Scan Completed:{" "}
                                              {filterlists.length > 0
                                                ? "loading more events"
                                                : "loading events"}
                                            </>
                                          }
                                        </span>
                                      </StyledBox>
                                    )}
                                  </Box>
                                  <Box sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginTop: "10px",
                                  }}>
                                    <span style={{
                                      fontWeight: "bold",
                                      marginRight: "10px",
                                    }}>Event Info:- </span>
                                    &nbsp; &nbsp;
                                    {event.event_name && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>Event Title: </strong>
                                          {event.event_name}
                                        </span>
                                      </StyledBox>
                                    )}

                                    {event.location && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>Location: </strong>
                                          {event.location}
                                        </span>
                                      </StyledBox>
                                    )}

                                    {event.date && (
                                      <StyledBox>
                                        <span style={{ paddingRight: "20px" }}>
                                          <strong>Date: </strong>
                                          {customDateFormat(event.date)}
                                        </span>
                                      </StyledBox>
                                    )}
                                    <br />
                                    {event.status && (
                                      <StyledBox>
                                        <span>
                                          {event.status.includes(
                                            "Scan Completed: "
                                          ) &&
                                            event.fetch_tickets_scheduler ===
                                            "start" ? (
                                            <>
                                              <strong>Status: </strong>Scan Completed:{" "}
                                              {filterlists.length > 0
                                                ? "loading more tickets"
                                                : "loading tickets"}
                                            </>
                                          ) : (
                                            <>
                                              <strong>Status: </strong>
                                              {event.status}
                                            </>
                                          )}
                                        </span>
                                      </StyledBox>
                                    )}
                                  </Box>
                                  {generateTicketData.ticketIds.length > 0 && (
                                    <Button
                                      size="medium"
                                      sx={{
                                        float: "right",
                                        marginTop: "15px",
                                        bottom: "10px",
                                      }}
                                      variant="contained"
                                      onClick={() => this.generateModal(true)}
                                    >
                                      Generate Ticket
                                    </Button>
                                  )}
                                  <div className="data-table-icon">
                                    <DataTable
                                      responsive
                                      items={filterlists}
                                      fields={fields}
                                      hover
                                      pagination

                                      className="vendor-td"
                                      scopedSlots={{
                                        is_verified: (item, index) => {
                                          return item.is_verified ? "Yes" : "No";
                                        },
                                        status: (item, index) => {
                                          return item.status;
                                        },
                                        google_wallet_link: (item, index) => {
                                          return (
                                            <>
                                              {item.android_jwt &&
                                                item.android_jwt !== null &&
                                                item.android_jwt !== undefined &&
                                                item.android_jwt !== "" ? (
                                                <>
                                                  {item?.android_jwt?.includes(
                                                    "https://pay.google.com"
                                                  ) ? (
                                                    <a
                                                      style={{
                                                        textDecoration: "none",
                                                        pointer: "cursor",
                                                      }}
                                                      href={item.android_jwt}
                                                      target="_blank"
                                                    >
                                                      <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img src={Google} alt="no image" />
                                                        <p style={{
                                                          marginLeft: "2px",
                                                          width: "100%",
                                                          minWidth: "162px",
                                                          marginTop: "0px",
                                                          marginBottom: "0px",
                                                          color:"#373240"
                                                        }}>This is not a ticket</p>
                                                      </div>
                                                    </a>
                                                  ) : (
                                                    <a
                                                      style={{
                                                        textDecoration: "none",
                                                        pointer: "cursor",
                                                      }}
                                                      href={
                                                        "https://pay.google.com/gp/v/a/save/" +
                                                        item.android_jwt
                                                      }
                                                      target="_blank"
                                                    >
                                                      <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img src={Google} alt="no image" />
                                                        <p style={{
                                                          marginLeft: "2px",
                                                          width: "100%",
                                                          minWidth: "162px",
                                                          marginTop: "0px",
                                                          marginBottom: "0px",
                                                          color:"#373240"
                                                        }}>This is not a ticket</p>
                                                      </div>
                                                    </a>
                                                  )}
                                                </>
                                              ) : (
                                                <Tooltip title="Link not Found">
                                                  <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img
                                                      disabled={true}
                                                      style={{ pointer: "context-menu" }}
                                                      src={Google}
                                                      alt="no image"
                                                    />
                                                    <p style={{
                                                      marginLeft: "2px",
                                                      width: "100%",
                                                      minWidth: "162px",
                                                      marginTop: "0px",
                                                      marginBottom: "0px"
                                                    }}>This is not a ticket</p>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </>
                                          )
                                        },
                                        checked: (item, index) => {
                                          return (
                                            <Checkbox
                                              size="small"
                                              id={`ticket${item.id}`}
                                              name="choosetickets"
                                              checked={generateTicketData.ticketIds.includes(
                                                item.id
                                              )}
                                              disabled={
                                                item.status === "Sold" ||
                                                item.status ===
                                                  "Not Available" ||
                                                item?.vendor === "AXS" ||
                                                vendorAccountInfo?.team ===
                                                  "AXS"
                                              }
                                              onChange={(evt) =>
                                                this.chooseticket(item, evt)
                                              }
                                            />
                                          );
                                        },
                                        view: (item, index) => {
                                          return (
                                            <>
                                              {item.sold_detail && (
                                                <Button
                                                  component="a"
                                                  size="small"
                                                  variant="contained"
                                                  sx={{
                                                    backgroundColor:
                                                      "#002986!important",
                                                    color: "white!important",
                                                    padding: "2px 5px!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                    marginRight: "5px",
                                                  }}
                                                  href={item.sold_detail.ticketurl}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  Ticket
                                                </Button>
                                              )}

                                              {item.soldinventory && (
                                                <Button
                                                  component="a"
                                                  size="small"
                                                  variant="contained"
                                                  sx={{
                                                    backgroundColor:
                                                      "#002986!important",
                                                    color: "white!important",
                                                    padding: "2px 5px!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                  }}
                                                  href={`/sold-inventory?refId=${item.soldinventory}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  Sale
                                                </Button>
                                              )}
                                            </>
                                          );
                                        },
                                      }}
                                    /></div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        )}
                      </>
                      :
                      <ContainerLoader />
                    }
                  </main>
                </div>
              </div>
            </div>

            <Dialog
              fullWidth={true}
              maxWidth="sm"
              scroll="body"
              closeAfterTransition
              TransitionComponent={Transition}
              open={this.state.generateTicketModal}
              className="Modal_Box custom-pop-over"
              onClose={() => this.generatedDetailModalHide()}
            >
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.generatedDetailModalHide()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <div className="user-info">
                  <ValidatorForm onSubmit={this.submitGenerateTicket}>
                    <div className="custom-pop-over-row">
                      <div className="form-group login-wrapper">
                        {event.event_name && (
                          <div>
                            <strong>Event Title: </strong>
                            {event.event_name}
                          </div>
                        )}

                        {event.date && (
                          <div>
                            <strong>Date: </strong>
                            {customDateFormat(event.date)}
                          </div>
                        )}
                        <div>
                          <strong>Tickets: </strong>
                          {generateTicketData.ticketData.map((t, index) => (
                            <div key={index}>
                              <span
                                style={{
                                  paddingRight: "15px",
                                  paddingLeft: "5px",
                                }}
                              >
                                Section: {t.section}
                              </span>
                              <span style={{ paddingRight: "15px" }}>
                                Row: {t.row}
                              </span>
                              Seat: {t.seat}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} sm={12} lg={6}>
                        <div className="custom-pop-over-row">
                          <div className="form-group select-outer">
                            <label>Marketplace</label>
                            <Autocomplete
                              onChange={(evt, val) =>
                                this.handleModelChangeSelect(
                                  evt,
                                  val,
                                  "customerDisplayName"
                                )
                              }
                              fullWidth
                              name="customerDisplayName"
                              disabled={this.state.generatedTicketDetailModal}
                              options={marketplace}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextValidator
                                  value={generateTicketData.marketTypeVal}
                                  placeholder="Select Marketplace"
                                  {...params}
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} sm={12} lg={6}>
                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>External Reference</label>
                            <TextValidator
                              variant="outlined"
                              onChange={this.handleModelChange}
                              name="invoiceExternalRef"
                              disabled={this.state.generatedTicketDetailModal}
                              value={generateTicketData.invoiceExternalRef}
                              {...externalRefProps}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {(generateTicketData.customerDisplayName === "VividSeats" ||
                      generateTicketData.customerDisplayName === "TickPick" ||
                      generateTicketData.customerDisplayName === "SeatGeek" ||
                      generateTicketData.customerDisplayName ===
                      "Gametime") && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.handleModelChangeCheckbox}
                                  name="uploadtovivid"
                                  color="primary"
                                />
                              }
                              label={`Upload the link to ${generateTicketData.customerDisplayName}`}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={this.handleModelChangeCheckbox}
                                  name="custfromvivid"
                                  color="primary"
                                />
                              }
                              label={`Get customer details from ${generateTicketData.customerDisplayName}`}
                            />
                          </Grid>
                        </Grid>
                      )}
                    {!generateTicketData.custfromvivid && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="custom-pop-over-row">
                              <div className="form-group login-wrapper">
                                <label>Customer Name</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleModelChange}
                                  name="customerName"
                                  disabled={
                                    this.state.generatedTicketDetailModal
                                  }
                                  value={generateTicketData.customerName}
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="custom-pop-over-row">
                              <div className="form-group login-wrapper">
                                <label>Customer Email</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleModelChange}
                                  name="customerEmail"
                                  disabled={
                                    this.state.generatedTicketDetailModal
                                  }
                                  value={generateTicketData.customerEmail}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {generateTicketData.customerDisplayName !==
                          "Ticket Evolution" && (
                            <div className="custom-pop-over-row">
                              <div className="form-group login-wrapper">
                                <label>Customer Phone</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleModelChange}
                                  name="customerPhone"
                                  disabled={this.state.generatedTicketDetailModal}
                                  value={generateTicketData.customerPhone}
                                />
                              </div>
                            </div>
                          )}
                      </>
                    )}
                    {!generateTicketData.uploadtovivid && (
                      <div className="custom-pop-over-row">
                        <div className="form-group login-wrapper">
                          <label>Send Delivery message</label>
                          <RadioGroup
                            aria-label="deliverymsg_time"
                            defaultValue="immediate"
                            name="deliverymsg_time"
                            className="scan-condition-opts"
                            onChange={this.handleModelChange}
                          >
                            <FormControlLabel
                              value="immediate"
                              control={<Radio color="primary" />}
                              label="Immediate"
                            />
                            {/* <FormControlLabel value="48 hours before" control={<Radio color="primary" disabled={true}/>} label="48 Hours Before" /> */}
                          </RadioGroup>
                        </div>
                      </div>
                    )}
                    <Alert
                      severity="warning"
                      variant="outlined"
                    >
                      <strong>Please note: </strong>This link has been verified by our system, but we are not liable for any issues that may occur. By proceeding, you accept full responsibility.
                    </Alert>
                    <Grid container spacing={2} className="custom-pop-over-row">
                      <Grid item xs={12}>
                        {this.state.generatedTicketUrl && (
                          <p
                            className={
                              this.state.errorInFulfillingOrder
                                ? "alert alert-danger"
                                : "alert alert-success "
                            }
                          >
                            <strong>
                              {this.state.errorInFulfillingOrder
                                ? "Sale successfully generated but failed to upload link to marketplace and ticket Url is"
                                : "Sale successfully generated and ticket Url is "}{" "}
                            </strong>
                            <br />
                            <MUiLink
                              component="a"
                              href={this.state.generatedTicketUrl}
                            >
                              {this.state.generatedTicketUrl}
                            </MUiLink>
                            &nbsp;&nbsp;
                            <Button
                              variant="contained"
                              startIcon={<ContentCopyIcon />}
                              sx={{
                                backgroundColor: "#011C58!important",
                                color: "white!important",
                                textTransform: "none",
                                fontWeight: "bold!important",
                                borderRadius: "2px!important",
                                fontFamily: "Barlow!important",
                                marginTop: "5px",
                              }}
                              type="button"
                              onClick={() => this.copyUrl()}
                            >
                              {this.state.copiedTicketUrl ? "Copied" : "Copy"}
                            </Button>
                          </p>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="custom-pop-over-row">
                      {!this.state.generatedTicketDetailModal && (
                        <Grid item xs={5} sm={3} md={6} className="text-right">
                          {this.state.submttingticketform ? (
                            <Button
                              variant="contained"
                              type="button"
                              disabled="disabled"
                              className="theme-btn mx-2 "
                            >
                              Updating...
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              type="submit"
                              className="theme-btn "
                            >
                              Update
                            </Button>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={5} sm={3} md={6}>
                        <Button
                          variant="contained"
                          type="button"
                          className="theme-btn ml-align"
                          onClick={() => this.generatedDetailModalHide()}
                        >
                          {this.state.generatedTicketDetailModal
                            ? "Exit"
                            : "Cancel"}
                        </Button>
                      </Grid>
                    </Grid>

                  </ValidatorForm>

                  <Collapse in={this.state.generateTicketMsgOpen}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({ generateTicketMsgOpen: false });
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      severity={this.state.generateTicketMsgAlertColor}
                      sx={{ mb: 2 }}
                    >
                      {this.state.generateTicketMsg}
                    </Alert>
                  </Collapse>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.generateopen}
              scroll="body"
              onClose={() => this.handleToggleGenerate()}
              closeAfterTransition
            >
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleToggleGenerate()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <Typography
                  id="transition-modal-title"
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#1A237E",
                    textAlign: "left",
                    mb: 2,
                    fontFamily: "Barlow  , sans-serif!important",
                  }}
                  variant="h6"
                  component="h2"
                >
                  Link Already Generated
                </Typography>
                <Grid container spacing={2}>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        fontSize: "1.1rem",
                        textAlign: "left",
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                    >
                      {this.state.generateresponsemsg}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DialogActions>
                      {/* <Button
                        sx={{
                          fontSize: "0.9rem!important",
                          fontWeight: "bold",
                          color: "#fff",
                          bgcolor: indigo[900],
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: indigo[900],
                            color: "#fff",
                          },

                          fontFamily: "Barlow  , sans-serif!important",
                        }}
                        autoFocus
                        onClick={() => this.handleRegenerateTicket()}
                      >
                        Yes
                      </Button> */}
                      <Button
                        sx={{
                          fontSize: "0.9rem!important",
                          fontWeight: "bold",
                          color: "#fff",
                          bgcolor: indigo[900],
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: indigo[900],
                            color: "#fff",
                          },

                          fontFamily: "Barlow  , sans-serif!important",
                        }}
                        onClick={() => {
                          this.handleToggleGenerate();
                          this.generatedDetailModalHide();
                          this.getEventTickets();
                          this.generatedDetailModalHideTV();

                        }}
                        autoFocus
                      >
                        {/* No */}
                        Exit
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

            {/* Update Tv Modal */}

            <Dialog
              scroll="body"
              fullWidth={true}
              maxWidth="sm"
              centered
              open={this.state.generateTicketModalTV}
              onClose={() => this.generatedDetailModalHideTV()}
            >
              <DialogContent
                sx={{
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => this.generatedDetailModalHideTV()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <div>
                  <ValidatorForm
                    onSubmit={
                      this.state.vendor === "AXS"
                        ? this.submitGenerateTicketAXS
                        : this.submitGenerateTicketTV
                    }
                  >
                    <div className="custom-pop-over-row">
                      <div className="form-group login-wrapper">
                        {event.event_name && (
                          <div>
                            <strong>Event Title: </strong>
                            {event.event_name}
                          </div>
                        )}

                        {event.date && (
                          <div>
                            <strong>Date: </strong>
                            {customDateFormat(event.date)}
                          </div>
                        )}

                        <div>
                          <strong>Tickets: </strong>
                          {generateTicketData.ticketData.map((t, index) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "initial",
                              }}
                              key={index}
                            >
                              <Typography
                                style={{
                                  paddingRight: "15px",
                                  paddingLeft: "5px",
                                }}
                              >
                                Section: {t.section}
                              </Typography>
                              <Typography style={{ paddingRight: "15px" }}>
                                Row: {t.row}
                              </Typography>
                              Seat: {t.seat}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <Alert
                      severity="warning"
                      variant="outlined"
                    >
                      <strong>Please note: </strong>This link has been verified by our system, but we are not liable for any issues that may occur. By proceeding, you accept full responsibility.
                    </Alert>

                    {this.state.axsSuccess && (
                      <p className="alert alert-success">
                        <strong>
                          "Sale successfully generated and ticket Url is
                        </strong>
                        <br />
                        <MUiLink component="a" href={this.state.axsLink}>
                          {this.state.axsLink}
                        </MUiLink>
                        <Button
                          variant="contained"
                          startIcon={<ContentCopyIcon />}
                          sx={{
                            backgroundColor: "#011C58!important",
                            color: "white!important",
                            textTransform: "none",
                            fontWeight: "bold!important",
                            borderRadius: "2px!important",
                            fontFamily: "Barlow!important",
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                          type="button"
                          onClick={() => this.copyUrl()}
                        >
                          {this.state.copiedTicketUrl ? "Copied" : "Copy"}
                        </Button>
                      </p>
                    )}

                    <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <DialogActions item={true}>
                        {!this.state.axsSuccess && (
                          <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{
                              fontSize: "0.9rem!important",
                              fontWeight: "bold",
                              color: "#fff",
                              bgcolor: this.state.submttingticketform
                                ? grey[400]
                                : "#002986",
                              textTransform: "capitalize",
                              fontFamily: "Barlow, sans-serif!important",
                              "&:hover": {
                                bgcolor: this.state.submttingticketform
                                  ? grey[400]
                                  : "#002986",
                                color: "#fff",
                              },
                            }}
                            disabled={
                              this.state.submttingticketform ? true : false
                            }
                            autoFocus
                          >
                            {this.state.submttingticketform
                              ? "Updating.."
                              : "Update"}
                          </Button>
                        )}

                        <Button
                          type="button"
                          size="large"
                          sx={{
                            fontSize: "0.9rem!important",
                            fontWeight: "bold",
                            padding: "7px 20px 8px 18px",
                            color: "#fff",
                            bgcolor: "#002986",
                            textTransform: "capitalize",
                            fontFamily: "Barlow, sans-serif!important",
                            "&:hover": {
                              bgcolor: "#002986",
                              color: "#fff",
                            },
                          }}
                          onClick={() => this.generatedDetailModalHideTV()}
                          autoFocus
                        >
                          {this.state.generatedTicketDetailModal
                            ? "Exit"
                            : "Cancel"}
                        </Button>
                      </DialogActions>
                    </Grid>
                  </ValidatorForm>
                </div>
              </DialogContent>
            </Dialog>

            {/* Tv genrated Modal  */}

            <Dialog
              fullWidth={true}
              scroll="body"
              maxWidth="lg"
              open={this.state.generatedModal}
              onClose={this.generateBulkHide}
            >
              <DialogTitle>Generated Data</DialogTitle>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.generateBulkHide()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <div style={{ textAlign: "right", padding: "10px 0px" }}>
                  {this.state.posType === "TA" ? (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.exportToCSVACTA(
                          this.state.bulkGeneratedData
                        )
                      }
                      sx={{
                        backgroundColor: "#011C58!important",
                        color: "white!important",
                        textTransform: "none",
                        fontWeight: "bold!important",
                        fontSize: "0.9rem",
                      }}
                      startIcon={<FileDownloadIcon />}
                    >
                      Download As Csv
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() =>
                        this.exportToCSV(this.state.bulkGeneratedData)
                      }
                      sx={{
                        backgroundColor: "#011C58!important",
                        color: "white!important",
                        textTransform: "none",
                        fontWeight: "bold!important",
                        fontSize: "0.9rem",
                      }}
                      startIcon={<FileDownloadIcon />}
                    >
                      Download As Csv
                    </Button>
                  )}
                </div>

                <TableContainer component={Paper}>
                  <MuiTable
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Event Name</StyledTableCell>
                        <StyledTableCell align="left">Date</StyledTableCell>
                        <StyledTableCell align="left">Section</StyledTableCell>
                        <StyledTableCell align="left">Row</StyledTableCell>
                        <StyledTableCell align="left">Seat</StyledTableCell>
                        <StyledTableCell align="left">
                          Ticket Url
                        </StyledTableCell>
                        <StyledTableCell align="left">Copy Url</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.bulkGeneratedData.map((row, index) => (
                        <StyledTableRow key={index * Math.random()}>
                          <StyledTableCell component="th" scope="row">
                            {row.event_name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {customDateFormat(row.date)}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.section}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.row}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.seat}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Link
                              href={row.ticketUrl}
                              target="_blank"
                              underline="none"
                              sx={{ color: "#202020" }}
                            >
                              {row.ticketUrl}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {this.state.copiedUrl &&
                              this.state.copiedIndex === index ? (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#919191C2!important",
                                  color: "white!important",
                                  textTransform: "none",
                                  fontWeight: "bold!important",
                                  padding: "6px 8px",
                                  fontSize: "14px!important",
                                  ".css-y6rp3m-MuiButton-startIcon": {
                                    marginRight: "3px",
                                    marginLeft: "3px",
                                  },
                                  ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                  {
                                    fontSize: "20px",
                                  },
                                }}
                                size="small"
                                startIcon={<LibraryAddCheckIcon />}
                              >
                                Copied
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#011C58!important",
                                  color: "white!important",
                                  textTransform: "none",
                                  fontWeight: "bold!important",
                                }}
                                onClick={() =>
                                  this.copyMutipleUrl(row.ticketUrl, index)
                                }
                                startIcon={<ContentCopyIcon />}
                              >
                                Copy
                              </Button>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </DialogContent>
            </Dialog>
            <AlertMessage
              open={this.state.messageopen}
              message={this.state.message}
              severity={this.state.messagetype}
              closeCall={this.handleClosemessage}
            />
            <Footer />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Events Tickets</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {loader && <PageLoader />}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          padding: "30px",
                          width: "100%",
                          height: "100%",
                          minHeight: "100vh",
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <CardHeader title="Events Tickets" />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ width: "100%" }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ display: "inline" }}
                                >
                                  Task in progress, please wait
                                </Typography>
                                <LinearProgressWithLabel
                                  value={this.state.progress}
                                />
                                <div
                                  style={{
                                    marginTop: "3rem",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    src={loaderGif}
                                    alt="loader"
                                    style={{
                                      width: "170px",
                                      height: "170px",
                                    }}
                                  />
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
    ProgressId: state.Progress,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
    addId: (id) => dispatch(ProgressAction(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorEventTickets);