import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { decodeBase64ToParams, encodeParamsToBase64 } from 'utils/base64';
import { useSelector } from 'react-redux';
import { useUserAPICollection } from 'Hooks/useUserAPICollection';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { verifiedSessionAPI } = useUserAPICollection();
  const [isTokenExpiredState, setIsTokenExpiredState] = useState(false);
  const created = useSelector((state) => state.UserProfile?.data?.created);
  const isDiabledAddCreditButton = useSelector((state) => state.UserProfile?.add_credit_btn_status);
  const isAccessbrowser = useSelector((state) => state.UserProfile?.data?.access_browser);
  const isVbdAccattached = useSelector((state) => state.UserProfile?.vbdaccattached)
  const token = Cookies.get("accessToken");
  const lastToken = Cookies.get("at");
  const accountCreatedDate = new Date(created);
  const currentDate = new Date();
  const differenceInMilliseconds = currentDate - accountCreatedDate;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  const onlyBrowserAccessHideTab = [
    "/vendor-list", "/inventory", "/sold-inventory", "/attendance", "/verifiedticket", "/instruction-guide",
    "/history-usage", "/inventory-past", "/history-logs", "/history-notifications", "/market-pos-list",
    "/event-management", "/tm-password-manager", "/packages"
  ];

  const onlyVbdAccessHideTab = [
    "/licenses-summary", "/release-notes", "/end-user-license-agreement"
  ]

  const ishideforAddCreditButton = [
    "/purchase-credit"
  ]

  const diabledAllAccess = [
    "/vendor-list", "/inventory", "/sold-inventory", "/attendance", "/verifiedticket", "/instruction-guide",
    "/history-usage", "/inventory-past", "/history-logs", "/history-notifications", "/market-pos-list",
    "/event-management", "/tm-password-manager", "/licenses-summary", "/release-notes", "/end-user-license-agreement", "/packages"
  ]

  let lastTokenUser;

  try {
    if (lastToken) {
      const decodedParams = decodeBase64ToParams(lastToken);
      lastTokenUser = JSON.parse(decodedParams);
    } else {
      Cookies.remove('at')
      Cookies.remove('accessToken')
    }
  } catch (error) {
    Cookies.remove('at')
    lastTokenUser = null;
  }

  const verifiedToken = async () => {
    try {
      const res = await verifiedSessionAPI()
      const { data } = res;
      if (data.error) {
        setIsTokenExpiredState(true);
      } else {
        const extendedExpirationTime = new Date(lastTokenUser.expriedToken).getTime() + 5 * 60 * 1000; // Extending by 5 minutes
        const updatedToken = {
          ...lastToken,
          expriedToken: new Date(extendedExpirationTime).toISOString()
        };
        const encodedTokenData = encodeParamsToBase64(JSON.stringify(updatedToken));
        Cookies.set('at', encodedTokenData);
        setIsTokenExpiredState(false);
      }
    } catch (error) {
      setIsTokenExpiredState(true);
    }
  }

  function isTokenExpired(creationDate) {
    const tokenCreationDate = new Date(creationDate);
    const currentDate = new Date();
    const differenceInMs = currentDate - tokenCreationDate;
    const differenceInMinutes = differenceInMs / (1000 * 60); //  5 minutes
    if (differenceInMinutes > 5) {
      verifiedToken()
    }
  }

  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        isTokenExpired(lastTokenUser?.expriedToken);
      }, 5 * 60 * 1000); // 5 minutes in milliseconds
      return () => clearInterval(intervalId);
    }
  }, [lastTokenUser, token]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  if (isTokenExpiredState) {
    Cookies.remove('at')
    Cookies.remove('accessToken')
    Cookies.remove("user_login_Id");
    localStorage.removeItem("user_login_Id")
    return <Redirect to="/login" />;
  }

  if (lastTokenUser?.lastToken !== token) {
    Cookies.remove('accessToken')
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const { pathname } = props.location;
        const isDisabledAddCreditPage = isDiabledAddCreditButton === false && ishideforAddCreditButton.includes(pathname);
        const isDisabledDueToTime = differenceInHours < 48 && ishideforAddCreditButton.includes(pathname);
        if (
          isAccessbrowser === "Yes" &&
          isVbdAccattached === false &&
          onlyBrowserAccessHideTab.includes(pathname) ||
          isDisabledAddCreditPage || isDisabledDueToTime
        ) {
          return (
            <Layout>
              <div className="text-center mt-top">
                {isDisabledDueToTime && isDisabledAddCreditPage ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledAddCreditPage) ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledDueToTime) ? (
                  <h1>This page is currently disabled for 48 hours.</h1>
                ) : (
                  <h1>Unauthorized Page</h1>
                )}
              </div>
            </Layout>
          );
        }
        if (
          isAccessbrowser === "No" &&
          isVbdAccattached === true &&
          onlyVbdAccessHideTab.includes(pathname) ||
          isDisabledAddCreditPage || isDisabledDueToTime
        ) {
          return (
            <Layout>
              <div className="text-center mt-top">
                {isDisabledDueToTime && isDisabledAddCreditPage ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledAddCreditPage) ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledDueToTime) ? (
                  <h1>This page is currently disabled for 48 hours.</h1>
                ) : (
                  <h1>Unauthorized Page</h1>
                )}
              </div>
            </Layout>
          );
        }
        if (
          isAccessbrowser === "No" &&
          isVbdAccattached === false &&
          diabledAllAccess.includes(pathname) ||
          isDisabledAddCreditPage || isDisabledDueToTime
        ) {
          return (
            <Layout>
              <div className="text-center mt-top">
                {isDisabledDueToTime && isDisabledAddCreditPage ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledAddCreditPage) ? (
                  <h1>This page is currently disabled from admin.</h1>
                ) : (isDisabledDueToTime) ? (
                  <h1>This page is currently disabled for 48 hours.</h1>
                ) : (
                  <h1>Unauthorized Page</h1>
                )}
              </div>
            </Layout>
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

};

export default PrivateRoute;
