import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import "../common.css";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { ArrowBack } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

class VbdOrigAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      modalShow: false,
      deleteId: "",
      deleting: false,
      loader: true,
      open: false,
      message: "",
      alertcolor: "",
      modalform: {
        show: false,
        id: "",
        username: "",
        password: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
      },
    };
  }

  componentDidMount() {
    this.getLists();
  }

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/listVbdAccount";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false, deleteId: "" });
  };

  callDelete = () => {
    this.setState({ deleting: true });
    const data = { _id: this.state.deleteId };
    const delete_api_url = `${process.env.REACT_APP_API_URL}/api/admin/deleteVbdAccount`;
    axios
      .post(delete_api_url, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessTokenAdmin")}`,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            alertcolor: "error",
            deleting: false,
          });
        } else {
          if (data.success) {
            this.getLists();
            this.setState({
              modalShow: false,
              open: true,
              message: "Deleted successfully!",
              alertcolor: "success",
              deleting: false,
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
              deleting: false,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
          deleting: false,
        });
      });
  };

  setModalFormShow = (id, title, username) => {
    const modalform = {
      show: true,
      id: id,
      username: username,
      password: "",
      submitting: false,
      formalert: false,
      formmessage: "",
      formalertcolor: "",
    };
    this.setState({ modalform });
  };

  onHideForm = () => {
    this.setState({
      modalform: {
        show: false,
        id: "",
        username: "",
        password: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
      },
    });
  };

  handleSubmit = () => {
    const { modalform } = this.state;
    this.setState({ modalform: { ...modalform, submitting: true } }, () => {
      const form_data = {
        username: modalform.username,
        password: modalform.password,
        id: modalform.id || undefined,
      };
      const form_api_url = modalform.id
        ? `${process.env.REACT_APP_API_URL}/api/admin/editVbdAccount`
        : `${process.env.REACT_APP_API_URL}/api/admin/addVbdAccount`;
      const successmsg = modalform.id ? "Updated successfully!" : "Added successfully!";

      axios
        .post(form_api_url, form_data, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessTokenAdmin")}`,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              modalform: {
                ...modalform,
                submitting: false,
                formalert: true,
                formmessage: data.data.message,
                formalertcolor: "error",
              },
            });
          } else {
            if (data.success) {
              this.getLists();
              this.setState({
                modalform: {
                  show: false,
                  id: "",
                  username: "",
                  password: "",
                  submitting: false,
                  formalert: false,
                  formmessage: "",
                  formalertcolor: "",
                },
                open: true,
                message: successmsg,
                alertcolor: "success",
              });
            } else {
              this.setState({
                modalform: {
                  ...modalform,
                  submitting: false,
                  formalert: true,
                  formmessage: data.data.message,
                  formalertcolor: "error",
                },
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            modalform: {
              ...modalform,
              submitting: false,
              formalert: true,
              formmessage: "Something went wrong",
              formalertcolor: "error",
            },
          });
        });
    });
  };

  handleCloseAlert = () => {
    this.setState({ open: false });
  };

  handleChange = (e) => {
    this.setState({
      modalform: {
        ...this.state.modalform,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { lists, modalShow, loader, deleting, modalform } = this.state;
    const fields = [
      { key: "username", label: "Username" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
      },
    ];

    return (
      <>
        <Helmet>
          <title>VBD Accounts List</title>
        </Helmet>
        <Box sx={{ width: "100%" }}>
          <Collapse in={this.state.open}>
            <Alert
              severity={this.state.alertcolor}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.handleCloseAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.message}
            </Alert>
          </Collapse>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "100%",
                          maxWidth: "265px",
                          color: "#000059!important",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "1.6rem",
                          fontFamily: "Barlow!important",
                        }}
                      >
                        {" "}
                        VBD Account
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        sx={{
                          justifyContent: {
                            xs: "flex-start",
                            sm: "flex-start",
                            md: "flex-end",
                            lg: "flex-end",
                            xl: "flex-end",
                          },
                        }}
                      >
                        <Grid item>
                          <Button
                            onClick={() =>
                              this.setModalFormShow("", "Add Account", "")
                            }
                            size="large"
                            variant="contained"
                            sx={{
                              backgroundColor: "#002986!important",
                              color: "white!important",
                              textTransform: "none!important",
                              fontWeight: "bold",
                              fontFamily: "Barlow!important",
                            }}
                          >
                            Add VBD Accounts
                          </Button>
                        </Grid>
                        <Grid item sx={{ paddingLeft: "7px!important" }}>
                          <Button
                            onClick={() => this.props.history.push(`/vbdaccounts/lists`)}
                            size="large"
                            variant="contained"
                            sx={{
                              backgroundColor: "#002986!important",
                              color: "white!important",
                              textTransform: "none!important",
                              fontWeight: "normal",
                              padding: "8px 22px",
                              fontFamily: "Barlow!important",
                              marginLeft: "9px !important"
                            }}
                            startIcon={<ArrowBack />}
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
              {loader ? (
                <ContainerLoader />
              ) : (
                <CardContent className="plan-tbl">
                  <DataTable
                    responsive
                    tableFilter
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    paginationLimit
                    scopedSlots={{
                      action: (item) => (
                        <div className="flex">
                          <IconButton
                            color="primary"
                            aria-label="edit"
                            type="button"
                            onClick={() =>
                              this.setModalFormShow(item._id, "Update Account", item.username)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            type="button"
                            color="primary"
                            disabled={deleting}
                            onClick={() => this.setModalShow(item._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ),
                    }}
                  />
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
        {/* Delete Confmation modal */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={modalShow}
          onClose={this.onHide}
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <IconButton
              aria-label="close"
              type="button"
              onClick={this.onHide}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: (theme) => theme.palette.grey[500],
                p: 1,
              }}
            >
              <CancelIcon />
            </IconButton>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onHide}
              sx={{
                backgroundColor: "#002986!important",
                color: "white!important",
                fontWeight: "bold",
                fontFamily: "Barlow",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.callDelete}
              sx={{
                backgroundColor: "#002986!important",
                color: "white!important",
                fontWeight: "bold",
                fontFamily: "Barlow",
                textTransform: "none",
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* add and update modal */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={modalform.show}
          onClose={this.onHideForm}
        >
          <DialogTitle
            sx={{
              fontFamily: "Barlow!important",
              fontSize: "1rem!important",
              color: "#696969!important",
            }}
          >
            {modalform.id ? "Update Account" : "Add Account"}
          </DialogTitle>
          <Divider />
          <ValidatorForm onSubmit={this.handleSubmit}>
            <DialogContent>
              <IconButton
                aria-label="close"
                type="button"
                onClick={this.onHideForm}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogContent sx={{ padding: "0px!important" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>Username</label>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="username"
                      placeholder="Username"
                      onChange={this.handleChange}
                      value={modalform.username}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Password</label>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={this.handleChange}
                      value={modalform.password}
                      validators={modalform.id ? [] : ["required"]}
                      errorMessages={modalform.id ? [] : ["This field is required"]}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onHideForm}
                type="button"
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </>
    );
  }
}

export default VbdOrigAccounts;
