import React from 'react';

// mui components
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Helmet } from "react-helmet";
import 'react-quill/dist/quill.snow.css';
import Autocomplete from '@mui/material/Autocomplete';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import styled from '@emotion/styled';

// custom components
import { ContainerLoader } from 'components/common/ContainerLoader';
import AlertMessage from '../alert/alert';
import '../common.css';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    height:100%!important;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#F1F1F1'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    &:hover {
      border-color: ${blue[400]};
    }
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

class SendMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            userlists: [],
            formData: {
                user: null,
                msg: '',
                to: ''
            },
            submit: false,
            open: false,
            message: '',
            successError: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.getAllUsers();
    }

    getAllUsers = async () => {
        const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers"
        await axios.post(userList_api_url, {}, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((result) => {
            const res = result.data;
            if (!res.error) {
                const sorted = res.data.sort((a, b) => {
                    if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim()) return -1;
                    if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim()) return 1;
                    return 0;
                });
                this.setState({
                    loader: false,
                    userlists: sorted
                });
            }
        });
    }

    handleChange = (e) => {
        const { formData } = this.state;
        formData[e.target.name] = e.target.value
        this.setState({ formData });
    }

    handleChangeUser(e, newVal) {
        const { formData } = this.state;
        if (newVal) {
            formData.user = newVal._id;
            if (newVal.phone_number) {
                formData.to = newVal.phone_number;
            } else {
                formData.to = '';
            }
        } else {
            formData.user = null;
            formData.to = '';
        }
        this.setState({ formData });
    }

    handleClose() {
        this.setState({ open: false })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submit: true });
        const addCustomCredit_api_url = process.env.REACT_APP_API_URL + "/api/message/sendNew"
        axios.post(addCustomCredit_api_url, this.state.formData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response;
            if (data.error) {
                this.setState({
                    submit: false,
                    open: true,
                    message: data.message,
                    successError: 'error'
                })
            } else {
                this.setState({
                    submit: false,
                    open: true,
                    message: data.message,
                    successError: 'success'
                })
            }
        }).catch((error) => {
            this.setState({
                submit: false,
                open: true,
                message: "Something went wrong, please try again",
                successError: 'error'
            });
        })
    }

    render() {
        const {
            loader,
            userlists,
            formData,
            submit,
            open,
            message,
            successError
        } = this.state;
        const divStyle = { textAlign: "left" }
        return (
            <>
                <Helmet>
                    <title>Send Message</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ padding: "30px" }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ justifyContent: "space-between" }}
                                >
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                width: "100%",
                                                maxWidth: "265px",
                                                color: "#000059!important",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.6rem",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            {" "}
                                            Send Message
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                                {loader ? (
                                    <ContainerLoader />
                                ) : (
                                    <ValidatorForm onSubmit={this.handleSubmit}>
                                        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center" }}>
                                            <Grid item xs={12}>
                                                <div style={divStyle}>
                                                    <label>User</label>
                                                    <Autocomplete
                                                        onChange={this.handleChangeUser}
                                                        id="tags-outlined"
                                                        options={userlists}
                                                        getOptionLabel={(option) => option.email}
                                                        renderInput={(params) => (
                                                            <TextValidator
                                                                {...params}
                                                                value={formData.user}
                                                                variant="outlined"
                                                                placeholder="Select User"
                                                                validators={['required']}
                                                                errorMessages={('This field is required')}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={divStyle}>
                                                    <label>To</label>
                                                    <TextValidator
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                        name="to"
                                                        fullWidth
                                                        type="text"
                                                        value={formData.to}
                                                        validators={['required']}
                                                        errorMessages={'this field is required'}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={divStyle}>
                                                    <label>Message</label>
                                                    <Textarea
                                                        cols={12}
                                                        rows={8}
                                                        fullWidth
                                                        sx={{ height: "200px !important" }}
                                                        variant="outlined"
                                                        onChange={this.handleChange}
                                                        name="msg"
                                                        type="text"
                                                        value={formData.msg}
                                                        validators={['required']}
                                                        errorMessages={'this field is required'}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {submit ? (
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        type="button"
                                                        className="theme-btn-submit"
                                                        size='large'
                                                        disabled={true}
                                                    >
                                                        Submitting...
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        type="submit"
                                                        className="theme-btn-submit"
                                                        sx={{ paddingY: "0.8rem" }}
                                                        size='large'
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* alert message  */}
                <AlertMessage
                    open={open}
                    message={message}
                    severity={successError}
                    closeCall={this.handleClose}
                />
            </>
        )
    }

}

export default SendMessage;