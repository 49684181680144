import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./releaseNote.scss"
// components
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from "components/message/message";

// npm packages also  editer  tinymic
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";

// mui components
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Add, ArrowBack, Cancel, Clear, Event } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TableHead,
    TextField,
    Typography,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Moment from "moment";
import CustomDataTable from "utils/customDataTable";
import CustomPagination from "utils/customPagination";
import useAlertMessage from "Hooks/useAlertMessage";
import ConfirmationModal from "utils/confirmationModal";

// custom style  for data table of the Table Cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


const AddReleaseNotes = () => {
    // states
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(true);
    const [isCrossCheck, setIsCrossCheckState] = useState(false);
    const [isOpenAddReleaseNote, setIsOpenAddReleaseNote] = useState(false);
    const [isOpenDeleteConfirmationModel, setIsOpenDeleteConfirmationModel] = useState(false);
    const [isDisableButton, setIsDisableButton] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [releaseNotesLists, setReleaseNotesLists] = useState([]);
    const [startDate, setStartDateState] = useState(new Date());
    const [notes, setNotes] = useState('')
    const [releaseId, setReleaseId] = useState('')
    const [totalRecords, setTotalRecords] = useState(0);
    const [formData, setFormData] = useState({
        version: '',
        releasedAt: null,
        notes: ''
    });
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    });

    const handleOpenAddReleaseNote = () => {
        setIsOpenAddReleaseNote(true);
    }

    const handleCloseAddReleaseNote = () => {
        setIsOpenAddReleaseNote(false);
        setFormData((prevState) => ({
            ...prevState,
            version: '',
            releasedAt: '',
            notes: ""
        }));
        setNotes('')
        setStartDateState(new Date())
        setIsEdit(false)
    }

    const handleOnChange = (evt) => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value
        });
    };

    const handleSetStartDate = async (date) => {
        setStartDateState(date)
    }

    const handleClearDate = async () => {
        if (startDate != null) {
            setStartDateState(new Date())
            setIsCrossCheckState(false)
        } else {
            setIsCrossCheckState(true)
        }
    };

    const handleOpenDeleteConfirmationModel = (item) => {
        setIsOpenDeleteConfirmationModel(true)
        setReleaseId(item.id)
    }

    const handleCloseDeleteConfirmationModel = () => {
        setIsOpenDeleteConfirmationModel(false)
        setReleaseId('')
    }

    const changePageLimit = (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        getAllReleaseNotesLists(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setPagination({
            ...pagination,
            skip: (currentPage - 1) * pagination.pageLimit
        })
        getAllReleaseNotesLists((currentPage - 1) * pagination.pageLimit, pagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        })
    };

    /**
     *  hit  get release notes api
     *  get  all release notes list
     */
    const getAllReleaseNotesLists = async (pageskip = 0, limitpage) => {
        const get_release_Notes = process.env.REACT_APP_API_URL + "/api/admin/getReleaseNotes";
        try {
            setIsLoading(true);
            const res = await axios.post(get_release_Notes, {
                skip: pageskip,
                limit: limitpage,
            },
                {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                    }
                });
            const { data } = res;
            if (data.error) {
                errorMessage()
                setIsLoading(false);
            } else {
                setReleaseNotesLists(data.data);
                setTotalRecords(data?.count);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    };

    /**
     * hitting  add  release notes api  
     */
    const handleSubmitAddReleaseNotes = async () => {
        setIsDisableButton(true)
        const add_release_Notes = process.env.REACT_APP_API_URL + "/api/admin/addEditReleaseNotes";
        try {
            const params = {
                ...formData,
                releasedAt: startDate,
                notes: notes,
            }
            setIsLoading(true);
            const res = await axios.post(add_release_Notes, params,
                {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                    }
                });
            const { data } = res;
            if (data.error) {
                errorMessage(data.message, "error");
                setIsLoading(false);
                setIsDisableButton(false);
            } else {
                errorMessage(data.message, "success");
                getAllReleaseNotesLists(pagination?.skip, pagination?.pageLimit)
                setReleaseNotesLists(data.data);
                setFormData((prevState) => ({
                    ...prevState,
                    version: '',
                    releasedAt: '',
                    notes: ""
                }));
                setNotes('')
                setStartDateState(new Date())
                setIsLoading(false);
                setIsOpenAddReleaseNote(false);
                setIsDisableButton(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
            setIsDisableButton(false);
        }
    };

    /**
     * hitting edit/update  release notes api  
     */
    const handleUpdateReleaseNotes = async () => {
        setIsDisableButton(true);
        const delete_release_Notes = process.env.REACT_APP_API_URL + "/api/admin/addEditReleaseNotes";
        try {
            setIsLoading(true);
            const params = {
                ...formData,
                _id: releaseId,
                notes: notes,
                releasedAt: startDate,
            }
            const res = await axios.post(delete_release_Notes, params,
                {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                    }
                });
            const { data } = res;
            if (data.error) {
                errorMessage()
                setIsLoading(false);
            } else {
                errorMessage(data.message, "success")
                setFormData((prevState) => ({
                    ...prevState,
                    version: '',
                    releasedAt: '',
                    notes: ""
                }));
                setNotes('')
                setStartDateState(new Date())
                setIsEdit(false)
                getAllReleaseNotesLists(pagination?.skip, pagination?.pageLimit)
                setIsOpenAddReleaseNote(false);
                setIsDisableButton(false);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    }

    /**
     * get  single  release notes
     */
    const handleSingleReleaseNotes = async (item) => {
        setReleaseId(item.id)
        const single_release_Notes = process.env.REACT_APP_API_URL + "/api/browser/getReleaseNotes";
        try {
            setIsLoading(true);
            const res = await axios.post(single_release_Notes, { _id: item.id },
                {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                    }
                });
            const { data } = res;
            if (data.error) {
                errorMessage()
                setIsLoading(false);
            } else {
                setFormData((prevState) => ({
                    ...prevState,
                    version: data?.data[0]?.version,
                }));
                setNotes(data?.data[0]?.notes)
                const date = Moment(data?.data[0]?.releasedAt)?.format("MM/DD/YYYY")
                setStartDateState(date)
                setIsEdit(true)
                setIsOpenAddReleaseNote(true);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    }

    /**
     * hitting delete release  notes api 
     */
    const handleDeleteReleseNotes = async () => {
        const delete_release_Notes = process.env.REACT_APP_API_URL + "/api/admin/delReleaseNotes";
        try {
            setIsOpenDeleteConfirmationModel(false)
            setIsLoading(true);
            const res = await axios.post(delete_release_Notes, { _id: releaseId },
                {
                    headers: {
                        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                    }
                });
            const { data } = res;
            if (data.error) {
                errorMessage()
                setIsLoading(false);
            } else {
                errorMessage(data?.message, "success");
                getAllReleaseNotesLists(pagination?.skip, pagination?.pageLimit)
                setReleaseId('')
                setIsOpenDeleteConfirmationModel(false)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false);
        }
    }

    const columns = [
        { field: 'version', headerName: 'Version' },
        { field: 'releasedAt', headerName: 'Released At' },
        { field: 'notes', headerName: 'Notes' },
    ];

    const actions = [
        { label: "Edit", callback: handleSingleReleaseNotes },
        { label: "Delete", callback: handleOpenDeleteConfirmationModel },
    ];
    
    useEffect(() => {
        getAllReleaseNotesLists(pagination?.skip, pagination?.pageLimit)
    }, []);

    return (<>
        <Helmet>
            <title>Release Notes</title>
        </Helmet>
        {!isOpenAddReleaseNote ? (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Release Notes"
                                action={
                                    <Button
                                        type="button"
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important",
                                        }}
                                        startIcon={<Add />}
                                        onClick={(e) => handleOpenAddReleaseNote(e)}
                                    >
                                        Add
                                    </Button>
                                }
                            />
                            <CardContent className="plan-tbl">
                                <CustomDataTable
                                    actions={actions}
                                    columns={columns}
                                    isLoading={isLoading}
                                    data={releaseNotesLists.length > 0
                                        ? releaseNotesLists.map((item) => {
                                            return {
                                                ...item,
                                                id: item._id,
                                                version: item.version,
                                                releasedAt: new Date(item.releasedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
                                                notes: <p dangerouslySetInnerHTML={{ __html: item.notes }} />
                                            };
                                        })
                                        : []}
                                />
                                <br />
                                {releaseNotesLists && releaseNotesLists.length > 0 && (
                                    <>
                                        {totalRecords > 0 && (
                                            <CustomPagination
                                                key="paginationnew"
                                                searchPage={pagination?.searchPage}
                                                SearchDisable={SearchDisable}
                                                totalRecords={totalRecords}
                                                pageLimit={pagination?.pageLimit}
                                                onPageChanged={onPageChanged}
                                            />
                                        )}
                                        <div
                                            style={{
                                                textAlign: "right",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <label>Items per page :</label>
                                            <FormControl
                                                sx={{
                                                    m: 1,
                                                    minWidth: 50,
                                                }}
                                                size="small"
                                            >
                                                <Select
                                                    defaultValue={pagination?.pageLimit}
                                                    value={pagination?.pageLimit}
                                                    name="limit"
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            padding: "4px 34px 4px 14px!important",
                                                            fontFamily: "Barlow",
                                                        },
                                                    }}
                                                    onChange={(e) => changePageLimit(e)}
                                                >
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="20">20</MenuItem>
                                                    <MenuItem value="50">50</MenuItem>
                                                    <MenuItem value="100">100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        ) : (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Add Release Notes"
                                action={
                                    <Button
                                        type="button"
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important",
                                        }}
                                        startIcon={<ArrowBack />}
                                        onClick={(e) => handleCloseAddReleaseNote(e)}
                                    >
                                        Back
                                    </Button>
                                }
                            />
                            <CardContent>
                                <ValidatorForm
                                    className="filter-form1 mb-5"
                                    onSubmit={() => isEdit ? handleUpdateReleaseNotes() : handleSubmitAddReleaseNotes()}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                Version
                                            </Typography>
                                            <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Version"
                                                onChange={(e) => handleOnChange(e)}
                                                name="version"
                                                value={formData.version}
                                                validators={["required"]}
                                                errorMessages={["Version is required"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                Released At
                                            </Typography>
                                            <DatePicker
                                                placeholderText="Released At"
                                                selected={new Date(startDate)}
                                                startDate={new Date(startDate)}
                                                onChange={(dates) => handleSetStartDate(dates)}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                customInput={
                                                    <TextField
                                                        validators={["required"]}
                                                        errorMessages={["this field is required"]}
                                                        variant="outlined"
                                                        className="filter-input"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    className="date-icon"
                                                                >
                                                                    <div className="d-flex">
                                                                        {isCrossCheck ? (
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                edge="end"
                                                                            >
                                                                                <Event className="text-dark" />
                                                                            </IconButton>
                                                                        ) : (
                                                                            <IconButton
                                                                                edge="end"
                                                                                onClick={() =>
                                                                                    handleClearDate()
                                                                                }
                                                                            >
                                                                                <Clear className="text-dark" />
                                                                            </IconButton>
                                                                        )}
                                                                    </div>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                Notes
                                            </Typography>
                                            <div style={{ zIndex: 0 }}>
                                                <Editor
                                                    apiKey="ox4jp745eo6k4whfbfxfk0675klim6a6i0vhupfcdmsshjcr"
                                                    onEditorChange={(e) => {
                                                        setNotes(e)
                                                    }}
                                                    value={notes}
                                                    init={{
                                                        height: 500,
                                                        anchor_top: false,
                                                        anchor_bottom: false,
                                                        automatic_uploads: true,
                                                        file_picker_types: "file image media",
                                                        paste_data_images: true,
                                                        image_advtab: true,
                                                        file_picker_callback: function (
                                                            callback,
                                                            value,
                                                            meta
                                                        ) {
                                                            if (meta.filetype == "image") {
                                                                var input = document.createElement("input");
                                                                input.setAttribute("type", "file");
                                                                input.setAttribute("accept", "image/*");
                                                                input.onchange = function () {
                                                                    var file = this.files[0];
                                                                    var reader = new FileReader();
                                                                    reader.onload = function () {
                                                                        callback(reader.result, {
                                                                            alt: file.name,
                                                                        });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                };
                                                                input.click();
                                                            }
                                                        },
                                                        advlist_bullet_styles: "square",
                                                        advlist_number_styles:
                                                            "lower-alpha,lower-roman,upper-alpha,upper-r",
                                                        toolbar_mode: "sliding",
                                                    }}
                                                    menubar={[
                                                        "file edit view insert format tools table help",
                                                    ]}
                                                    plugins={[
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste code help wordcount",
                                                        "textpattern",
                                                        "image",
                                                        "imageupload",
                                                        "link",
                                                        "help",
                                                        "wordcount",
                                                        "code",
                                                        "preview",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "paste",
                                                        "charmap",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "emoticons",
                                                        "directionality",
                                                        "visualchars",
                                                        "noneditable",
                                                        "nonbreaking",
                                                        "quickbars",
                                                        "toc",
                                                        "anchor",
                                                        "pagebreak",
                                                        "importcss",
                                                        "autosave",
                                                        "save",
                                                        "template",
                                                        "help",
                                                    ]}
                                                    toolbar={[
                                                        " undo redo | bullist numlist | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons ",
                                                    ]}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} style={{ margin: "0 auto" }}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                sx={{ paddingY: "0.9rem" }}
                                                variant="contained"
                                                type="submit"
                                                className={`${isDisableButton ? "theme-btn-submit-disablebutton" : "theme-btn-submit"}`}
                                                disabled={isDisableButton}
                                            >
                                                {isEdit ? "Update" : "Submit"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        )}
        {/* {delete conmation modal} */}
        <ConfirmationModal
            handleAPIRequest={handleDeleteReleseNotes}
            isOpen={isOpenDeleteConfirmationModel}
            handleClose={handleCloseDeleteConfirmationModel}
            message={"Are you sure you want to Delete Release Note ?"}
            title={"Release Note"}
            buttonText={"Delete"}
            buttonText2={"Cancel"}
        />
        {/* AlertMessage  */}
        <AlertMessage
            open={alertMessage.openAlert}
            message={alertMessage.message}
            severity={alertMessage.alertSeverity}
            closeCall={hideAlert}
        />
    </>);
};
export default AddReleaseNotes;