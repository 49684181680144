import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "../common.css";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { ContainerLoader } from "components/common/ContainerLoader";

class ListCommunicationUser extends React.Component {
  state = {
    usersData: [],
    modalShow: false,
    deleteId: "",
    loader: true,
  };

  componentDidMount() {
    this.setState({ loader: true }, () => {
      this.getlists();
    });
  }

  getlists = () => {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getCommunicationUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        console.log("UserList", response);
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            usersData: data.data,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };
  onHide = () => {
    this.setState({ modalShow: false });
  };

  callDelete = () => {
    this.setState({ loader: true }, () => {
      const data = { id: this.state.deleteId };
      const userDelete_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/deleteUser";

      axios
        .post(userDelete_api_url, data)
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ modalShow: false });
            this.setState({
              loader: false,
              open: true,
              message: data.data.message,
              successError: "error",
            });
          } else {
            this.setState({ modalShow: false });
            if (data.success) {
              this.getlists();
            } else {
              this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { usersData, modalShow, loader } = this.state;

    const fields = [
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "created", label: "Created" },
      { key: "action", label: "Action" },
    ];

    return (
      <>
        <Helmet>
          <title>Communication Employees</title>
        </Helmet>
        <div className="smallButton">
          {" "}
          <Button
            component={Link}
            to="/add-communication-user"
            className="theme-btn"
          >
            Add Employee
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Communication Employees" />
              <Divider />
              <CardContent>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <DataTable
                    tableFilter
                    items={usersData}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      action: (item, index) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              component={Link}
                              to={`/edit-communication-user/${item._id}`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.setModalShow(item._id)}
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    }}
                  />
                )}
                <Dialog
                  fullWidth={true}
                  scroll="body"
                  maxWidth="sm"
                  open={modalShow}
                  onClose={this.onHide}
                >
                  <DialogTitle>Delete</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to Delete, this action can not be
                      revert ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.onHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.callDelete}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default ListCommunicationUser;
