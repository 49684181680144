import React, { useState, useEffect } from "react";
import { Card, CardContent, Container, Grid } from "@mui/material";
import Helmet from "react-helmet";

// components
import "./term.css";

// components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Commonheader from "components/common-header/common-headre";
import { useUserAPICollection } from "Hooks/useUserAPICollection";
import useAlertMessage from "Hooks/useAlertMessage";
import AlertMessage from "components/message/message";
import { ContainerLoader } from "components/common/ContainerLoader";

const Term = () => {
  const { getTermsCondition } = useUserAPICollection()
  const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState("");

  const getAllTermsCondition = async () => {
    try {
      setIsLoading(true)
      const response = await getTermsCondition();
      const { data } = response;
      if (data.error) {
        errorMessage()
        setIsLoading(false)
      } else {
        setTerms(data.data.terms ?? "")
        setIsLoading(false)
      }
    } catch (error) {
      errorMessage()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllTermsCondition()
  }, [])

  return (
    <>
      <Helmet>
        <title>Terms and Condition of Use</title>
      </Helmet>
      <Header pathname={"/terms-and-conditions"} />
      <Container>
        <div className="common-header-padding">
          <Commonheader title="Terms and Condition of Use" description="" />
          {isLoading ? (
            <ContainerLoader />
          ) : (
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} className="center">
                <Card>
                  <CardContent
                    sx={{
                      p: 5,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: terms,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
      <Footer />
      {/* alert message */}
      <AlertMessage
        open={alertMessage.openAlert}
        message={alertMessage.message}
        severity={alertMessage.alertSeverity}
        closeCall={hideAlert}
      />
    </>
  );
};

export default Term;
