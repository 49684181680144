import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// mui components
import { Footer } from 'components/LandingPage';
import { ContainerLoader } from 'components/common/ContainerLoader';
import Header from 'components/header/header';
import Usersidebar from "components/user-sidebar/user-sidebar";
import { useUserAPICollection } from 'Hooks/useUserAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import { useHistory } from "react-router-dom";

// mui components
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import Cookies from 'js-cookie';

const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const HistoryofNotification = () => {
    const history = useHistory()
    const { getAllHostoryofNotificationList } = useUserAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoadering, setIsLoadering] = useState(true);
    const [notificationList, setNotificationList] = useState([]);

    const getHostoryofNotification = async () => {
        try {
            setIsLoadering(true)
            const response = await getAllHostoryofNotificationList()
            const { data } = response;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                setIsLoadering(false)
                errorMessage()
            } else {
                setNotificationList(data?.data ?? [])
                setIsLoadering(false)
            }
        } catch (error) {
            setIsLoadering(false)
            errorMessage()
        }
    }

    useEffect(() => {
        getHostoryofNotification()
    }, [])

    return (
        <>
            <div className="user-dashboard-wrapper">
                <Helmet>
                    <title>History of Notifications</title>
                </Helmet>
                <Header />
                <div className="c-app c-default-layout user-dashboard-body">
                    <Usersidebar />
                    <div className="c-wrapper">
                        <div className="c-body">
                            <main className="c-main">
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                        className="space-top-col"
                                    >
                                        <Card className="card-custom">
                                            <CardHeader title="History of Notifications" />
                                            {!isLoadering ? (
                                                <>
                                                    <CardContent sx={{ padding: "0px 12px 0px 12px" }}>
                                                        <div
                                                            style={{
                                                                overflowX: "auto",
                                                                height: "100%",
                                                                maxHeight: "650px",
                                                            }}
                                                        >
                                                            <TableContainer>
                                                                <Table stickyHeader aria-label="sticky table">
                                                                    <TableBody>
                                                                        {notificationList.length > 0 &&
                                                                            notificationList !== undefined ? (
                                                                            notificationList.map((data) => {
                                                                                return (
                                                                                    <>
                                                                                        <TableRow
                                                                                            className="notification-row"
                                                                                            sx={{ position: "relative" }}
                                                                                        >
                                                                                            <TableCell
                                                                                                className="text-left notification-data"
                                                                                                sx={{
                                                                                                    border: "none!important",
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: "1rem",
                                                                                                        color: "#3220D6",
                                                                                                    }}
                                                                                                >
                                                                                                    {data.title}
                                                                                                </span>
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: "#888",
                                                                                                        fontSize: "14px",
                                                                                                        fontFamily:
                                                                                                            "Barlow!important",
                                                                                                        textAlign: "left",
                                                                                                    }}
                                                                                                >
                                                                                                    {data.description}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <div
                                                                                                style={{
                                                                                                    fontSize: "13px",
                                                                                                    position: "absolute",
                                                                                                    right: "60px",
                                                                                                    top: "17px",
                                                                                                    color: "#888",
                                                                                                }}
                                                                                            >
                                                                                                {new Intl.DateTimeFormat('en', options).format(new Date(data.created ?? null))}
                                                                                            </div>
                                                                                        </TableRow>
                                                                                        <Divider
                                                                                            sx={{
                                                                                                borderColor: "#D8DBE0",
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    fontSize: "20px",
                                                                                    fontWeight: "bolder",
                                                                                    textAlign: "center",
                                                                                    height: "100%",
                                                                                    minHeight: "400px",
                                                                                }}
                                                                            >
                                                                                Notifications not Found
                                                                            </div>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                    </CardContent>
                                                </>
                                            ) : (
                                                <div style={{ position: 'relative', top: '-50px' }}>
                                                    <ContainerLoader />
                                                </div>
                                            )}
                                        </Card>
                                    </Grid>
                                </Grid>
                            </main>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}
export default HistoryofNotification;