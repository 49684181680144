import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { saveTime } from "redux/actions/saveTime";
import { resetTime } from "redux/actions/resetTime";
import { UploadFile } from "@mui/icons-material";

const UploadCSVTimerButton = ({
    date: initialDate,
    name,
    apiCall,
    bulkuploading,
    item,
    id,
    size,
    styles,
    htmlFor,
    time,
    component,
    buttonType,
    timeinseconds,
    status,
    variant,
}) => {
    const [open, setOpen] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const timerRef = useRef(null);
    const dispatch = useDispatch();
    const currentTime = useSelector((state) => state.time);
    const reduxKey = useMemo(() => `${id}-${name}`, [id, name]
    );

    const isTimer =
        currentTime[reduxKey] !== undefined &&
        currentTime[reduxKey] !== null &&
        currentTime[reduxKey] > 0;

    const NextClickedDate = useMemo(() => {
        const dateCopy = new Date(initialDate);
        return dateCopy;
    }, [initialDate]);


    const handleClose = () => {
        setOpen(false);
    }

    const handleButtonClick = useCallback(async () => {
        if (isTimer) {
            setOpen(true);
            return;
        } else {
            await apiCall(item);
            setRemainingTime(0);
        }
    }, [apiCall, isTimer, item])

    const updateTimer = useCallback(() => {
        setRemainingTime((prevRemainingTime) => {
            if (prevRemainingTime > 0) {
                dispatch(saveTime(reduxKey, prevRemainingTime - 1));
                return prevRemainingTime - 1;
            }
            return 0;
        });
    }, [dispatch, reduxKey]);

    const handleResetTimer = useCallback(() => {
        if (new Date() > NextClickedDate) {
            dispatch(resetTime(`${id}-${name}`, 0));
            setOpen(false);
            return;
        }
    }, [dispatch, NextClickedDate, name, id])

    const minutes = useMemo(() => Math.floor(remainingTime / 60), [remainingTime]);
    const seconds = useMemo(() => Math.floor(remainingTime % 60), [remainingTime]);

    useEffect(() => {
        handleResetTimer()
        const differenceInSeconds = Math.round((NextClickedDate - new Date()) / 1000)
        setRemainingTime(differenceInSeconds);
    }, [NextClickedDate, handleResetTimer]);

    useEffect(() => {
        const intervalId = setInterval(() => updateTimer(), 1000);
        updateTimer()
        return () => {
            clearInterval(intervalId);
        };
    }, [dispatch, reduxKey, updateTimer]);

    useEffect(() => {
        if (remainingTime === 0) {
            clearInterval(timerRef.current);
            setOpen(false);
        }
    }, [remainingTime]);
    return (
        <>
            {buttonType === "uploadcsv" && (
                <Button
                    htmlFor={bulkuploading || isTimer ? null : htmlFor}
                    component={bulkuploading || isTimer ? null : component}
                    type="button"
                    size="large"
                    name={name}
                    startIcon={<UploadFile />}
                    id={id}
                    color={isTimer ? "error" : "primary"}
                    variant={variant}
                    onClick={handleButtonClick}
                    sx={isTimer ? styles.diabledbutton : styles.actionbutton}
                >
                    {isTimer
                        ? name === "Upload CSV"
                            ?
                            <> {bulkuploading
                                ? "Uploading"
                                : name}
                            </>
                            : <> {bulkuploading
                                ? "Uploading"
                                : name}
                            </>
                        : <>
                            {bulkuploading
                                ? "Uploading"
                                : name}
                        </>}
                </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {name} Countdown
                </DialogTitle>
                <DialogContent>
                    <p>
                        You have to wait for {minutes} minutes and{" "}
                        {seconds.toString().padStart(2, "0")} seconds to perform this action
                        again.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" size="small">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

UploadCSVTimerButton.propTypes = {
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    apiCall: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    size: PropTypes.string,
    styles: PropTypes.object.isRequired,
    time: PropTypes.number.isRequired,
    buttonType: PropTypes.string,
    variant: PropTypes.string,
    status: PropTypes.string
};

UploadCSVTimerButton.defaultProps = {
    date: new Date(),
    name: "Action",
    apiCall: () => { },
    item: {},
    id: "",
    size: "small",
    styles: {
        actionbutton: {
            backgroundColor: "#002986!important",
            color: "white!important",
            textTransform: "none!important",
            fontWeight: "bold",
            fontFamily: "Barlow!important",
        },
        diabledbutton: {
            color: "white!important",
            textTransform: "none!important",
            fontWeight: "bold",
            fontFamily: "Barlow!important",
        },
    },
    time: 15,
    timeinseconds: 0,
    buttonType: "button",
    variant: "contained",
    status: "n/a"
};

export default React.memo(UploadCSVTimerButton);

