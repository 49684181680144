import React from 'react';
import loaderGif from "images/GIF-Loader-3.gif"


class PageLoader extends React.Component {
    render() {
        
        return (
            <div className="userdashboard-loader-min" ><div><img className="loader-size" src={loaderGif} alt="no loader"/></div></div>
        )
    }
}

export default PageLoader;