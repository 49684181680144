import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import TimeAgo from "react-timeago";

// import components 
import "./userdashboard.scss";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";

// mui icon
import CloseIcon from "@mui/icons-material/Close";

// mui components
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Table,
} from "@mui/material";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
    };
  }

  async componentDidMount() {
    this.setState({
      loader: true,
    });
    //Notification List api
    const userNotifications_api =
      process.env.REACT_APP_API_URL + "/api/user/userNotifications";
    await axios
      .post(userNotifications_api, "", {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const today = new Date();
          // Filter the array and update notifications with expired titles
          const updatedNotificationList = data?.data?.map((item) => {
            if (item.expireAt) {
              const expiryDate = new Date(item.expireAt);
              if (expiryDate < today) {
                // If the event has expired, extend the duration by 2 days
                expiryDate.setDate(expiryDate.getDate() + 2);
                // Remove the item from the array if it's expired for more than 2 days
                if (expiryDate < today) {
                  return null;
                }
                item.expireAt = expiryDate.toISOString();
              }
            }
            return item;
          });
          // Filter out null (expired) items from the array
          const extendDurationEventNotificationList = updatedNotificationList.filter((item) => item !== null);
          this.setState({
            loader: false,
            notificationList: extendDurationEventNotificationList,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  clearDate = async (datas) => {
    const data = {
      ids: [datas._id],
    };
    const notificationDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/user/readNotification";
    await axios
      .post(notificationDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            //Get All Notification List api
            const userNotifications_api =
              process.env.REACT_APP_API_URL + "/api/user/userNotifications";
            axios
              .post(userNotifications_api, "", {
                headers: {
                  authorization:
                    "Bearer " + Cookies.get("accessToken"),
                },
              })
              .then((response) => {
                const { data } = response;

                if (data.error) {
                  this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                  });
                } else {
                  this.setState({
                    loader: false,
                    notificationList: data.data,
                    open: false,
                    message: "",
                    successError: "success",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loader: false,
                  open: true,
                  message: "Something went wrong",
                  successError: "error",
                });
              });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  render() {
    const { notificationList } = this.state;
    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Notification</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs="12"
                      lg="12"
                      md="12"
                      sm="12"
                      className="space-top-col"
                    >
                      <Card className="card-custom">
                        <CardHeader title="Notifications" />
                        {!this.state.loader ? (
                          <>
                            <CardContent>
                              <div
                                style={{
                                  overflowX: "auto",
                                  height: "100%",
                                  maxHeight: "550px",
                                }}
                              >
                                <TableContainer>
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                      {notificationList.length > 0 &&
                                        notificationList !== undefined ? (
                                        notificationList.map((data) => {
                                          return (
                                            <>
                                              <TableRow
                                                className="notification-row"
                                                sx={{ position: "relative" }}
                                              >
                                                <IconButton
                                                  aria-label="close"
                                                  onClick={() =>
                                                    this.clearDate(data)
                                                  }
                                                  sx={{
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                    color: (theme) =>
                                                      theme.palette.grey[500],
                                                    p: 1,
                                                  }}
                                                >
                                                  <CloseIcon
                                                    sx={{ fontSize: "14px" }}
                                                  />
                                                </IconButton>
                                                <TableCell
                                                  className="text-left notification-data"
                                                  sx={{
                                                    border: "none!important",
                                                  }}
                                                >
                                                  {data.refId ? (
                                                    <a
                                                      href={`${data.link}`}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {data.title}
                                                    </a>
                                                  ) : (
                                                    <>
                                                      {data.title ===
                                                        "Credit balance is low" ? (
                                                        <Link
                                                          to="/add-credit"
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                        >
                                                          {data.title}
                                                        </Link>
                                                      ) : (
                                                        <span
                                                          sx={{
                                                            fontSize: "1rem",
                                                          }}
                                                        >
                                                          {data.title}
                                                        </span>
                                                      )}
                                                    </>
                                                  )}
                                                  <Typography
                                                    sx={{
                                                      color: "#888",
                                                      fontSize: "14px",
                                                      fontFamily:
                                                        "Barlow!important",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {data.description}
                                                  </Typography>
                                                </TableCell>
                                                <TimeAgo
                                                  date={new Date(data.created)}
                                                  style={{
                                                    fontSize: "13px",
                                                    position: "absolute",
                                                    right: "60px",
                                                    top: "17px",
                                                    color: "#888",
                                                  }}
                                                />
                                              </TableRow>
                                              <Divider
                                                sx={{
                                                  borderColor: "#D8DBE0",
                                                }}
                                              />
                                            </>
                                          );
                                        })
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "20px",
                                            fontWeight: "bolder",
                                            textAlign: "center",
                                            height: "100%",
                                            minHeight: "400px",
                                          }}
                                        >
                                          No Notifications
                                        </div>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </CardContent>
                          </>
                        ) : (
                          <div style={{ position: 'relative', top: '-50px' }}>
                            <ContainerLoader />
                          </div>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div >
      </>
    );
  }
}
export default Notification;
