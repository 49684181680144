import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';
import Papa from "papaparse";
import { Link } from "react-router-dom";

// icon componets 
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";

// mui components
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";

// custom components
import DataTable from "components/DataTable/DataTable";
import AlertMessage from "components/message/message";
import { ContainerLoader } from "components/common/ContainerLoader";
import ConfirmationModal from "utils/confirmationModal";

class ProxyManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersData: [],
      modalShow: false,
      updateModalShow: false,
      deleteId: "",
      updateId: "",
      updateName: "",
      updatePassword: "",
      updateHost: "",
      updatePort: "",
      updateUser: "",
      updateUserselection: "all",
      open: false,
      message: "",
      successError: "",
      loader: true,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.getlists();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  uploadBulkCsv = async (event) => {
    var commonConfig = { delimiter: "," };
    Papa.parse(event.target.files[0], {
      ...commonConfig,
      header: true,
      download: true,
      complete: (result) => {
        var temp = [];
        var finalArr = [];
        // check ii duplicate available exclude the name
        result.data.forEach(function myFunction(item, index) {
          temp.forEach(function myFunction(itemT, indexT) {
            if (
              itemT?.Username?.trim() === item?.Username?.trim() ||
              itemT?.Host?.trim() === item?.Host?.trim() ||
              itemT?.Port?.trim() === item?.Port?.trim() ||
              itemT?.Password?.trim() === item?.Password?.trim()
            ) {
              finalArr?.push(item);
            }
          });
          temp?.push(item);
        });

        if (finalArr.length > 0) {
          console.log("Duplicate available");
          console.log(finalArr);
          this.setState({
            open: true,
            message:
              " There are some duplicate records in your file. Please remove and try again",
            successError: "error",
          });
        } else {
          const selectedFile = event.target.files[0];
          if (
            selectedFile.type === "application/vnd.ms-excel" ||
            selectedFile.type === "text/csv"
          ) {
            const formData = new FormData();
            formData.append("File", selectedFile);

            const bulk_api_url =
              process.env.REACT_APP_API_URL + "/api/admin/addBulkBrowserProxy";
            this.setState({ loader: true });
            axios
              .post(bulk_api_url, formData, {
                headers: {
                  authorization:
                    "Bearer " + Cookies.get("accessToken"),
                },
              })
              .then(async (response) => {
                const { data } = response;
                if (data.error) {
                  this.setState({
                    open: true,
                    message: data?.message,
                    successError: "error",
                    loader: false,
                  });
                } else {
                  await this.getlists();
                  var message = "";
                  if (data?.total > 0) {
                    message += "Total proxies in CSV: " + data?.total + "<br />";
                  }
                  if (data?.alreadyexist > 0) {
                    message +=
                      "Total existing proxies: " + data?.alreadyexist + "<br />";
                  }
                  if (data?.invalidentry > 0) {
                    message +=
                      "Total invalid proxies: " + data?.invalidentry + "<br />";
                  }
                  if (data?.issueinsaving > 0) {
                    message +=
                      "Total proxies having issue in saving: " +
                      data.issueinsaving +
                      "<br />";
                  }
                  if (data?.totalsaved > 0) {
                    message +=
                      "Total newly created proxies: " +
                      data.totalsaved +
                      "<br />";

                  }
                  this.setState({
                    open: true,
                    message: message,
                    successError: "success",
                    loader: false,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  open: true,
                  message: "Something went wrong, please try again",
                  successError: "error",
                  loader: false,
                });
              });
          } else {
            this.setState({
              open: true,
              message: "Only csv file allowed",
              successError: "error",
              bulkuploading: false,
            });
          }
        }
      },
    });
  };

  getlists = () => {
    const browser_proxys_api_url =
      process.env.REACT_APP_API_URL + "/api/browser/getBrowserProxys";
    axios
      .get(browser_proxys_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            usersData: data?.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  callDelete = () => {
    this.setState({ loader: true }, () => {
      const data = { _id: this.state.deleteId };
      const userDelete_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/deleteBrowserProxy";

      axios
        .post(userDelete_api_url, data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ modalShow: false });
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({ modalShow: false });
            if (data.success) {
              this.getlists();
              this.setState({
                open: true,
                message: data.message,
                successError: "success",
              });
            } else {
              this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { usersData, modalShow, loader } = this.state;
    const fields = [
      { key: "userselection", label: "Share With" },
      { key: "user", label: "User" },
      { key: "hostname_val", label: "Hostname" },
      { key: "port_val", label: "Port" },
      { key: "name", label: "Name" },
      { key: "username_val", label: "Username" },
      { key: "password_val", label: "Password" },
      { key: "action", label: "Action" },
    ];

    return (
      <>
        <Helmet>
          <title>Proxy Management</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12} className="space-top-col-manage-license">
            <Card
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "#000059!important",
                textAlign: "left",
                padding: "30px",
                backgroundColor: "#fbfbfb;",
              }}
            >
              <div className="vendortable-header-manage-license">
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    maxWidth: "14rem",
                    color: "#000059!important",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "1.6rem",
                    fontFamily: "Barlow!important",
                  }}
                >
                  {" "}
                  Proxies
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, lg: 1 }}
                  columns={{ xs: 4, sm: 8, md: 8 }}
                  className="button-align-manage-license"
                >
                  <Grid item>
                    <Button
                      component={Link}
                      to="/add-proxy"
                      type="button"
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Proxy
                    </Button>
                  </Grid>
                  <Grid item>
                    <input
                      style={{ display: "none" }}
                      id="actual-csvuploader"
                      type="file"
                      onChange={this?.uploadBulkCsv}
                    />

                    <Button
                      component="label"
                      htmlFor="actual-csvuploader"
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<UploadFileIcon />}
                    >
                      {" "}
                      {this?.state?.bulkuploading ? "Uploading" : "Upload CSV"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component="a"
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      href={require("images/bulkSample.csv")}
                      download="Sample.csv"
                      startIcon={<DownloadIcon />}
                    >
                      Download Sample CSV
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <CardContent
                sx={{
                  padding: "0px!important",
                  marginTop: "30px!important",
                }}
                className="plan-tbl vendor-outer"
              >
                {!loader ? (
                  <DataTable
                    responsive
                    items={usersData ?? []}
                    fields={fields ?? []}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      action: (item, index) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              component={Link}
                              to={`/edit-proxy/${item?._id}`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.setModalShow(item?._id)}
                              aria-label="delete"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                      user: (item) => {
                        return (
                          <>
                            {item?.userselection === "all" && "All"}
                            {item?.userselection === "particular" &&
                              (item?.user && item?.user[0]?.email)}
                          </>
                        );
                      },
                      userselection: (item) => {
                        return (
                          <>
                            {item?.userselection === "all" && "All"}
                            {item?.userselection === "particular" &&
                              "Particular User"}
                          </>
                        );
                      },
                    }}
                  />
                ) : (
                  <ContainerLoader />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* delete confrm modal */}
        <ConfirmationModal
          handleAPIRequest={this.callDelete}
          isOpen={modalShow}
          handleClose={this.onHide}
          message={"Are you sure you want to Delete, this action can not be revert?"}
          title={"Delete"}
          buttonText={"Delete"}
          buttonText2={"Cancel"}
        />
        {/*  AlertMessage */}
        <AlertMessage
          key={`alertbtn`}
          open={this.state.open}
          message={this.state.message}
          severity={this.state.successError}
          closeCall={this.handleClose}
        />
      </>
    );
  }
}

export default ProxyManagement;
