import React, { useEffect, useState } from 'react'

// npm packages
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { Link } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import { SaveAlt } from "@mui/icons-material";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";

// mui components 
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    styled,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    Typography,
    List,
    ListItem,
    ListItemText,
    Table,
    TableContainer
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { indigo } from "@mui/material/colors";

// mui  icons components
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { HiOutlineSearch } from "react-icons/hi";

// imported components 
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from 'components/message/message';
import useAlertMessage from 'Hooks/useAlertMessage';
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import { changeDollarAmountformat } from 'utils/changeDollarFormat';

const moment = extendMoment(originalMoment);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontFamily: "Barlow !important",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const ManageBrowser = () => {
    const { getManageBrowserApi, getAllUsersApi } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [manageBrowserData, setManageBrowserData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [crossCheck, setCrossCheck] = useState(true);
    const [countData, setCountData] = useState({
        active: 0,
        inactive: 0,
        totalAmount: 0
    });
    const [filterUser, setFilterUser] = useState({
        selectedUsers: [
            {
                email: "All",
            },
        ],
        disableValue: "All"
    })
    const [formData, setFormData] = useState({
        startDate: "",
        email: "",
    });
    const year = moment(selectedDate).format("YYYY");

    const getManageBrowser = async () => {
        const params = {
            year: moment(selectedDate).format("YYYY"),
            users: filterUser.selectedUsers[0].email === "All" ? [] : filterUser.selectedUsers.map((item) => {
                return item._id
            }),
        }
        try {
            setIsLoading(true)
            const respones = await getManageBrowserApi(params);
            const { data } = respones;
            if (data.error) {
                errorMessage()
                setIsLoading(false)
            } else {
                const totalAmount = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.amount), 0);
                const active = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.active), 0);
                const inActive = data?.data && data?.data?.reduce((acc, item) => acc + parseFloat(item?.inactive), 0);
                setCountData({
                    ...countData,
                    active: active,
                    inactive: inActive,
                    totalAmount: totalAmount
                })
                setManageBrowserData(data.data)
                setIsLoading(false)
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false)
        }
    }

    const getAllUsers = async () => {
        try {
            const respones = await getAllUsersApi()
            const { data } = respones;
            if (data.error) {
                errorMessage()
            } else {
                const sorted = data.data.sort((a, b) => {
                    if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
                        return -1;
                    if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
                        return 1;
                    return 0;
                });
                sorted.unshift({
                    email: "All",
                });
                setUserList(sorted ?? [])
            }
        } catch (error) {
            errorMessage()
        }
    }

    const handleSelectUser = (event, value) => {
        if (value.length === 0) {
            setFilterUser({
                ...filterUser,
                selectedUsers: [],
                disableValue: ""
            })
        }
        value.map((item) => {
            if (item.email === "All") {
                setFilterUser({
                    ...filterUser,
                    disableValue: "All",
                    selectedUsers: [
                        {
                            email: "All",
                        },
                    ],
                })
            } else {
                setFilterUser({
                    ...filterUser,
                    selectedUsers: value,
                })
            }
            return item;
        });
    };

    const clearDate = () => {
        setSelectedDate(null);
        setCrossCheck(true);
    };

    const setStartDate = (date) => {
        setSelectedDate(date);
        const updatedFormData = { ...formData };
        const datestart = moment(date).format("YYYY");
        updatedFormData["startDate"] = datestart;
        setFormData(updatedFormData);
        setCrossCheck(false);
    };

    const handleSubmitFilter = async () => {
        await getManageBrowser()
    }

    const handleExportToCSV = (data) => {
        const csv = convertToCSV(data);
        const file = new Blob([csv], { type: "text/csv" });
        saveAs(file, "data.csv");
    };

    const formatMonthValue = (monthData) => {
        return typeof monthData === ('number' || "string") ? monthData ?? 0 : 0;
    }

    const changeDollarAmountformatCSV = (amount = null) => {
        if (amount) {
            const formattedAmount = parseFloat(amount).toFixed(2);
            const [dollars, cents] = formattedAmount.split(".");
            const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${formattedDollars}.${cents}`;
        }
    };

    const convertToCSV = (data) => {
        const filterdata = data.map((item, index) => {
            const srNo = formatMonthValue(index + 1);
            const links = formatMonthValue(item.active);
            const ticketsDelivered = formatMonthValue(item.inactive);
            return {
                "S.No": '\t' + srNo,
                "Months": '\t' + moment(item.month, "MM").format("MMMM"),
                "Active Licenses": '\t' + links,
                "Inactive Licenses": '\t' + ticketsDelivered,
                "Amount": '\t' + `${changeDollarAmountformatCSV(item.amount)}`
            }
        })
        const footer = [{
            "S.No": "Total",
            "Active Licenses": '\t' + formatMonthValue(countData.active),
            "Inactive Licenses": '\t' + formatMonthValue(countData.inactive),
            "Amount": '\t' + `${changeDollarAmountformatCSV(countData.totalAmount)}`,
        }];
        const combinedData = filterdata.concat(footer);
        return Papa.unparse(combinedData);
    };

    useEffect(() => {
        getManageBrowser()
        getAllUsers()
    }, [])

    return (
        <>
            <Helmet>
                <title>Manage Browser</title>
            </Helmet>
            <Card
                className="instruction-box"
                sx={{
                    width: "100%",
                    height: "100%",
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    maxWidth: "100%",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                        <Typography
                            variant="p"
                            component="h4"
                            gutterBottom
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                color: indigo[900],
                                textAlign: "left",
                                mb: 2,
                                fontFamily: "Barlow  , sans-serif!important",
                            }}
                        >
                            Manage Browser
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ pt: "5" }}
                    >
                        <ValidatorForm
                            onSubmit={handleSubmitFilter}
                            className="filter-form mb-5"
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                    <Grid item xs={12} sm={4} md={5} lg={5}>
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            id="checkboxes-tags-demo"
                                            options={userList}
                                            disableCloseOnSelect
                                            onChange={handleSelectUser}
                                            value={filterUser.selectedUsers}
                                            isOptionEqualToValue={(option, value) =>
                                                option.email === value.email
                                            }
                                            getOptionLabel={(option) => option.email}
                                            renderOption={(props, option, { selected }) => (
                                                <List
                                                    disablePadding
                                                    {...props}
                                                    sx={{
                                                        pointerEvents:
                                                            filterUser.disableValue === "All" &&
                                                                option.email !== "All"
                                                                ? "none"
                                                                : "auto",
                                                        width: "100%",
                                                        bgcolor: "background.paper",
                                                    }}
                                                >
                                                    <ListItem
                                                        disablePadding
                                                        disabled={
                                                            filterUser.disableValue === "All" &&
                                                                option.email !== "All"
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                icon={
                                                                    <CheckBoxOutlineBlankIcon fontSize="medium" />
                                                                }
                                                                checkedIcon={
                                                                    <CheckBoxIcon fontSize="medium" />
                                                                }
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={option.email} />
                                                    </ListItem>
                                                </List>
                                            )}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                    {...params}

                                                    sx={{
                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                            color: "#C4C4C4"
                                                        },
                                                    }}
                                                    variant="outlined"
                                                    label="Select Users"
                                                    value={filterUser.selectedUsers}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        lg={3}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                            },
                                        }}
                                    >
                                        <DatePicker
                                            placeholderText="Select Year"
                                            selected={selectedDate}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            isClearable={true}
                                            yearItemNumber={9}
                                            customInput={
                                                <TextValidator
                                                    validators={["required"]}
                                                    sx={{
                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                            color: "#C4C4C4"
                                                        },
                                                    }}
                                                    errorMessages={["This field is required"]}
                                                    variant="outlined"
                                                    className="filter-input"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className="date-icon"
                                                            >
                                                                <div className="d-flex">
                                                                    {crossCheck ? (
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            edge="end"
                                                                        >
                                                                            <EventIcon className="text-dark" />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton
                                                                            edge="end"
                                                                            onClick={() => clearDate()}
                                                                        >
                                                                            <ClearIcon className="text-dark" />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            }
                                            onChange={(dates) => setStartDate(dates)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className="filter-btn "
                                            startIcon={<HiOutlineSearch />}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ValidatorForm>
                    </Grid>
                </Grid>
                {!isLoading ? (
                    <>
                        <Grid
                            contaier
                            spacing={1}
                            className="usage-tbl-header  mt-3"
                        >
                            <Grid
                                item
                                xs={12}
                                className="usage-tbl-header text-right"
                            >
                                <Grid item>
                                    <LoadingButton
                                        onClick={() =>
                                            handleExportToCSV(manageBrowserData)
                                        }
                                        size="large"
                                        endIcon={<SaveAlt />}
                                        loading={false}
                                        disabled={false}
                                        loadingPosition="end"
                                        sx={{
                                            fontFamily: "Bevan, cursive !important",
                                            marginBottom: "10px"
                                        }}
                                        variant="contained"
                                    >
                                        <span>Export to CSV</span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>S.No</StyledTableCell>
                                        <StyledTableCell align="left">Months</StyledTableCell>
                                        <StyledTableCell align="left">Active Licenses</StyledTableCell>
                                        <StyledTableCell align="left">Inactive Licenses</StyledTableCell>
                                        <StyledTableCell align="left">Amount</StyledTableCell>
                                        <StyledTableCell align="left">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {manageBrowserData && manageBrowserData.length > 0 ? (
                                        <>
                                            {manageBrowserData.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {moment(row.month, "MM").format("MMMM")}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.active}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.inactive}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {changeDollarAmountformat(row?.amount)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" >
                                                        <Button
                                                            component={Link}
                                                            size="small"
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#002986!important",
                                                                color: "white!important",
                                                                padding: "2px 5px!important",
                                                                textTransform: "none!important",
                                                                fontWeight: "bold",
                                                                fontFamily: "Barlow!important",
                                                                margin: "0 5px",
                                                            }}
                                                            to={`/manage-browser-detail/${row.month}/${year}`}
                                                        >
                                                            View
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            <StyledTableRow >
                                                <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">Total</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }} align="left"></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{countData?.active ?? 0.00}</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{countData?.inactive ?? 0.00}</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">{changeDollarAmountformat(countData?.totalAmount.toFixed(2)) ?? 0.00}</StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    ) : (
                                        <StyledTableRow>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                colSpan={5}
                                                align="center"
                                            >
                                                No Data Found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <ContainerLoader />
                )}
            </Card>
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default ManageBrowser;