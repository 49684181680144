import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Pagination from "components/pagination/pagination";
import LoadPassword from "images/load_password.svg";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { HiDotsHorizontal } from "react-icons/hi";
import DataTable from "components/DataTable/DataTable";
import AlertMessage from "components/message/message";

class AdminVendorLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      currentPage: 1,
      userlists: [],
      scanvendors: [],
      open: false,
      message: "",
      successError: "",
      lists: [],
      filterData: {
        account_type: ["Vendor"],
        user: null,
        username: null,
        league: null,
        team: null,
        status: "all",
        limit: 10,
        skip: 0,
      },
      filterloaded: false,
      totalrecords: 0,
      pageLimit: 10,
      hidepassword: false,
      loadpass: false,
      indexNum: "",
      searchPage: 0,
      userValue: null,
      buttonHide: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.hiddenPassword = this.hiddenPassword.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
  }

  componentDidMount() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(
        userList_api_url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((result) => {
        const res = result.data;
        if (!res.error) {
          const sorted = res.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });
          this.setState({ filterloaded: true, userlists: sorted });
        }
        this.setState({ filterloaded: true });
      });

    this.getList();
  }
  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  changePageLimit = (e) => {
    const { filterData } = this.state;
    filterData.limit = parseInt(e.target.value);
    filterData.skip = 0;
    this.setState(
      {
        searchPage: 1,
        loader: true,
        filterData,
        pageLimit: parseInt(e.target.value),
      },
      () => {
        this.getList();
      }
    );
    this.setState({ buttonHide: false })
  };

  async getList(msg, type = "success") {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/account/getAllAccounts";
    axios
      .post(list_api_url, this.state.filterData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({ loader: false, lists: [], totalrecords: 0 });
        } else {
          const user = data.data.map((item) => {
            return {
              ...item,
              user: item.user[0].email || "",
            };
          });
          this.setState({
            loader: false,
            lists: user,
            totalrecords: data.totalrecord,
            open: !msg ? false : true,
            message: msg,
            successError: type,
          })
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong");
      });
  }

  handleChange(e) {
    const { filterData } = this.state;
    filterData[e.target.name] = e.target.value;
    this.setState({ filterData });
  }

  handleChangeUser(e, newVal) {
    const { filterData } = this.state;
    if (newVal) {
      filterData.user = newVal._id;
    } else {
      filterData.user = null;
    }
    this.setState({ filterData });

    this.setState({ userValue: newVal });

  }

  handleSubmit() {
    const { filterData } = this.state;
    filterData.skip = 0;
    this.setState({ searchPage: 1, loader: true, filterData }, () => {
      this.getList();
    });
  }


  onPageChanged(data) {

    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    if (currentPage === 1 && this.state.buttonHide !== false && this.state.buttonHide == true) {
      this.setState({ buttonHide: false });
    } else {
      this.setState({ buttonHide: true });
    }
    filterData.skip = (currentPage - 1) * pageLimit;
    this.setState({ loader: true, filterData }, () => {
      this.getList();
    });
  }

  hiddenPassword = (itemval) => {
    if (this.state.hidepassword && this.state.indexNum === itemval._id) {
      this.setState({ hidepassword: false, indexNum: "", loadpass: false });
    } else {
      this.setState(
        { hidepassword: true, loadpass: true, indexNum: itemval._id },
        () => {
          const pass_api_url =
            process.env.REACT_APP_API_URL +
            "/api/admin/account/accountDetailWithPlainPass";
          axios
            .post(
              pass_api_url,
              { password: itemval.password },
              {
                headers: {
                  authorization:
                    "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
              }
            )
            .then((response) => {
              const { data } = response;

              if (data.error) {
                this.setState({
                  hidepassword: false,
                  indexNum: "",
                  loadpass: false,
                });
              } else {
                const { lists } = this.state;
                var index = lists
                  .map(function (v) {
                    return v._id;
                  })
                  .indexOf(itemval._id);

                lists[index]["password"] = data.password;
                this.setState({ loadpass: false, lists });
              }
            })
            .catch((error) => {
              this.setState({
                hidepassword: false,
                indexNum: "",
                loadpass: false,
              });
            });
        }
      );
    }
  };

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  chooseAllVendorsForScan = (evtVal) => {
    // console.log(this.state.scanvendors)
    if (evtVal) {
      var selectedVendors = [];
      document.querySelectorAll("tbody tr").forEach((row) => {
        // select the checkbox of each row
        var checkbox = row.querySelector("input[type='checkbox']");
        // check the checkbox
        checkbox.checked = true;
        // get the id of the checkbox
        var id = checkbox.getAttribute("id");
        // push the id to the array
        selectedVendors.push(id);
      });
      this.setState({ scanvendors: selectedVendors });
      this.setState({ buttonHide: false })
    } else {
      this.setState({ scanvendors: [] });
    }
  };

  chooseVendorForScan = (item, evt) => {
    const { scanvendors } = this.state;
    if (evt.target.checked) {
      scanvendors.push(item._id);
      this.setState({ scanvendors })
      this.setState({ buttonHide: false })
    } else {
      if (scanvendors.length > 0) {
        var index = scanvendors.indexOf(item._id);
        scanvendors.splice(index, 1);
        if (scanvendors.length > 0) {
          this.setState({ scanvendors });
        } else {
          this.setState({ scanvendors: [] });
        }
      }
    }
  };


  scanVendorsSubmit = () => {
    const { scanvendors } = this.state;
    const data = {
      ids: scanvendors,
    };

    if (scanvendors.length > 0) {
      this.setState({ loader: true }, () => {
        const selectedVendorEventScan_api_url =
          process.env.REACT_APP_API_URL +
          "/api/admin/account/scanMultipleVendorAdmin";
        axios
          .post(selectedVendorEventScan_api_url, data, {
            headers: {
              authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
          })
          .then((response) => {
            const { data } = response;
            this.setState({
              scanvendors: [],
            });
            const message =
              data.total === 0
                ? "All accounts are synced recently please try after some time"
                : `Total ${data.total} ${data.message}`;

            var msgtype = data.total === 0 ? "error" : "success";
            // // Get All data
            // const success_message = "Vendor Data has been updated";
            this.getList(message, msgtype);
          })
          .catch((error) => {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          });
      });
    } else {
      this.setState({
        open: true,
        message: "Please select event to scan",
        alertcolor: "error",
      });
    }
  };
  refreshVendorsSubmit = () => {
    const { scanvendors } = this.state;
    const data = {
      ids: scanvendors,
    };

    if (scanvendors.length > 0) {
      this.setState({ loader: true }, () => {
        const selectedVendorEventScan_api_url =
          process.env.REACT_APP_API_URL +
          "/api/admin/account/refreshMultipleVendorAdmin";
        axios
          .post(selectedVendorEventScan_api_url, data, {
            headers: {
              authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
          })
          .then((response) => {
            const { data } = response;
            this.setState({
              scanvendors: [],
            });
            const message =
              data.total === 0
                ? "All accounts refreshed recently please try after some time"
                : `Total ${data.total} ${data.message}`;

            var msgtype = data.total === 0 ? "error" : "success";
            this.getList(message, msgtype);
          })
          .catch((error) => {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          });
      });
    } else {
      this.setState({
        open: true,
        message: "Please select event to scan",
        alertcolor: "error",
      });
    }
  };


  render() {
    const {
      loader,
      userlists,
      lists,
      filterData,
      filterloaded,
      totalrecords,
      hidepassword,
      indexNum,
      loadpass,
      scanvendors
    } = this.state;
    const actionbutton = {
      backgroundColor: "#002986!important",
      color: "white!important",
      padding: "2px 5px!important",
      textTransform: "none!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
    };
    const fields = [
      { key: "Select", lable: 'Select', sort: false },
      { key: "user", label: "User", sort: true },
      { key: "league", label: "League", sort: true },
      { key: "team", label: "Team", sort: true },
      { key: "username", label: "Username", sort: true },
      { key: "password", label: "Password", sort: false },
      { key: "status", label: "Status", sort: false },
      { key: "actions", label: "Actions", sort: false },
    ];

    return (
      <>
        <Helmet>
          <title>User's Vendors</title>
        </Helmet>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card
                className="w-100"
                sx={{
                  margin: "0px 0px 16px 0px",
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      align="left"
                      sx={{
                        padding: "10px 0px 0px 17px!important",
                        fontSize: "24px!important",
                        fontWeight: "700!important",
                        color: "#002986!important",
                      }}
                    >
                      User's Vendors
                    </Typography>
                  }
                  className="card-header"
                />
                <CardContent
                  className="plan-tbl"
                  sx={{
                    padding: "1.2rem",
                  }}
                >
                  {!loader ? (
                    <>
                      {filterloaded && (
                        <ValidatorForm
                          onSubmit={this.handleSubmit}
                          className="filter-form mb-5"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 2, lg: 2 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "left" }}
                              >
                                <label>Username</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="username"
                                  value={filterData.username}
                                  placeholder="Username"
                                  className="filter-input"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "left" }}
                              >
                                <label>League</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="league"
                                  value={filterData.league}
                                  placeholder="League"
                                  className="filter-input"
                                />
                              </Grid>
                              <Grid
                                item
                                className="team"
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "left" }}
                              >
                                <label>Team</label>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="team"
                                  value={filterData.team}
                                  placeholder="Team"
                                  className="filter-input"
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "left" }}
                              >
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <label>Status</label>
                                  <Select
                                    name="status"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder="Status"
                                    size="small"
                                    defaultValue="all"
                                    value={filterData.status}
                                  >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="error">Error</MenuItem>
                                    <MenuItem value="no_error">
                                      No Error
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={2}
                                lg={2}
                                sx={{ textAlign: "left" }}
                              >
                                <label>Select User</label>
                                <Autocomplete
                                  onChange={this.handleChangeUser}
                                  fullWidth
                                  id="combo-box-demo"
                                  value={this.state.userValue}
                                  name="user"
                                  options={userlists}
                                  getOptionLabel={(option) => option.email}
                                  renderInput={(params) => (
                                    <TextValidator
                                      value={filterData.user}
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={1.4}
                                lg={2}
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "center",
                                  paddingTop: "44px!important",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className="filter-btn"
                                  startIcon={<HiOutlineSearch />}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </ValidatorForm>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          marginBottom: "10px",
                          lineHeight: "38px",
                        }}
                      >
                        <div
                          style={{
                            padding: "4px 10px 10px 10px",
                            borderRadius: "4px",
                            width: "100%",
                            maxWidth: "26rem",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            flexDirection: "column",
                            boxShadow:
                              "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                          }}
                        >
                          <Typography
                            variant="p"
                            gutterBottom
                            sx={{
                              width: "100%",
                              fontSize: "18px",
                              fontWeight: "600",
                              color: "#000000",
                              textAlign: "center",
                              lineHeight: "1.5",
                            }}
                          >
                            Bulk Actions
                          </Typography>
                          <div
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                fontWeight: "semi-bold",
                                marginRight: "10px",
                              }}
                              onClick={() =>
                                this.chooseAllVendorsForScan(
                                  scanvendors.length > 0 ? false : true
                                )
                              }
                            >
                              {scanvendors.length > 0
                                ? "Un-Select All"
                                : "Select All"}
                            </Button>
                            {!this.state.buttonHide && scanvendors.length > 0 && (
                              <>
                                <Button
                                  variant="contained"
                                  sx={{
                                    marginRight: "10px",
                                  }}
                                  onClick={() =>
                                    this.scanVendorsSubmit()
                                  }
                                >
                                  Sync selected
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.refreshVendorsSubmit()
                                  }
                                >
                                  Refresh selected
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <DataTable
                        items={lists}
                        fields={fields}
                        itemsPerPage={filterData.limit}
                        scopedSlots={{
                          user: (item) => item.user,
                          status: (item) => item.event.status,
                          password: (item) => {
                            return (
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  flex: "wrap",
                                  wordBreak: "break-all",
                                }}
                              >
                                {indexNum === item._id ? (
                                  <>
                                    {loadpass ? (
                                      <img src={LoadPassword} alt="" />
                                    ) : (
                                      <>
                                        {hidepassword
                                          ? item.password
                                          : "*******"}
                                      </>
                                    )}
                                    <VisibilityOffIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "1.3rem",
                                        color: "#002986",
                                      }}
                                      onClick={() => this.hiddenPassword(item)}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <HiDotsHorizontal
                                      style={{
                                        margin: "0px",
                                        padding: "0px",
                                        position: "relative",
                                        left: "2px",
                                        fontSize: "30px",
                                      }}
                                    />
                                    <HiDotsHorizontal
                                      style={{ fontSize: "30px" }}
                                    />
                                    <VisibilityIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "1.3rem",
                                        color: "#002986",
                                      }}
                                      onClick={() => this.hiddenPassword(item)}
                                    />
                                  </>
                                )}
                              </div>
                            );
                          },
                          actions: (item) => (
                            <div className="icon-space-left">
                              <div className="vendor-btn">
                                <Button
                                  component={Link}
                                  size="small"
                                  variant="contained"
                                  sx={actionbutton}
                                  to={`/admin-vendor-events/${item._id}`}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          ),
                          Select: (item, index) => {
                            return (
                              <div className="flex">
                                <Checkbox
                                  color="primary"
                                  id={item._id}
                                  checked={scanvendors.includes(item._id)}
                                  onChange={(evt) =>
                                    this.chooseVendorForScan(item, evt)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </div>
                            );
                          },
                        }}
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "16rem 0",
                      }}
                    >
                      <CircularProgress sx={{ color: "#002986" }} />
                    </Box>
                  )}

                  {totalrecords > 0 && (
                    <Pagination
                      totalRecords={totalrecords}
                      searchPage={this.state.searchPage}
                      SearchDisable={this.SearchDisable}
                      pageLimit={this.state.pageLimit}
                      onPageChanged={this.onPageChanged}
                    />
                  )}
                  <div
                    style={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <label>Items per page :</label>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 50,
                      }}
                      size="small"
                    >
                      <Select
                        defaultValue={filterData.limit}
                        value={filterData.limit}
                        name="limit"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "4px 14px",
                            fontFamily: "Barlow",
                          },
                        }}
                        onChange={(e) => this.changePageLimit(e)}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                        <MenuItem value="100">100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <AlertMessage
            key={`alertbtn`}
            open={this.state.open}
            message={this.state.message}
            severity={this.state.successError}
            closeCall={this.handleClose}
          />
        </div >
      </>
    );
  }
}
export default AdminVendorLists;
