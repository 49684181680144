import { useAxios } from "./useUserAxios";

export const useUserAPICollection = () => {
    const { axiosRequest } = useAxios();

    const getSripSavedCardsAPi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/listStripeCustPayMethod", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getPreviousCreditPaymentAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/historyOfCreditPurchased", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getAvailableCredit = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/availableCredit", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const unsubscribeLicensesApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/browser/unsubscribeLicense", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const postReSubscribeLicenseApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/browser/resubscribeLicense", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const purchaseCreditApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/purchaseCredit", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const addStripeCustPayMethodApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/addStripeCustPayMethod", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const getBrowserSingleLicense = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/browser/singleLicense", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const updateLicenseApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/browser/editLicense", "patch", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const getAllLicensesApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/v2/browser/getAllLicenses", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }


    const getAllHostoryofNotificationList = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/historyOfNotifications", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    const deleteProgessTaskaApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/deleteTask", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }


    const deleteStripePaymentMethodApi = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/deleteStripeCustPayMethod", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }


    const getEndUserLicenseAgreementAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/eula/user/getLatestEula", "post", params);
            return response;
        } catch (error) {
            return error;
        }
    }

    // affiliate for user
    const getAllAffiliateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/user/getAll", "get");
            return response;
        } catch (error) {
            throw error;
        }
    }

    const affiliateLinkClickStateAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/affiliateCompany/user/linkClickStats", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }


    const getAllTermsConditionsAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/setting/terms", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // Get all terms conditions
    const getTermsCondition = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/tnc/getLatest", "get", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    // package
    const getAllPackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/user/all", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const purchasePackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/purchase", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const unsubscribePackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/unsubscribe", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const resubscribePackageAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/resubscribe", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const changePlanAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/package/changePackage", "post", params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    const verifiedSessionAPI = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/auth/checkSession", "get", params);
            return response;
        } catch (error) {
            throw error;
        }
    }


    const getHistoryOfPayments = async (params = {}) => {
        try {
            const response = await axiosRequest("/api/user/historyOfPayments", "post", params);
            return response;
        } catch (error) {
            console.error(error); 
            throw error;
        }
    }

    return {
        getSripSavedCardsAPi,
        getPreviousCreditPaymentAPI,
        getAvailableCredit,
        unsubscribeLicensesApi,
        postReSubscribeLicenseApi,
        purchaseCreditApi,
        addStripeCustPayMethodApi,
        getBrowserSingleLicense,
        updateLicenseApi,
        getAllLicensesApi,
        getAllHostoryofNotificationList,
        deleteProgessTaskaApi,
        deleteStripePaymentMethodApi,
        // end user license api
        getEndUserLicenseAgreementAPI,
        // affiliate api
        getAllAffiliateAPI,
        affiliateLinkClickStateAPI,
        // terms api
        getAllTermsConditionsAPI,
        getTermsCondition,
        //package
        getAllPackageAPI,
        purchasePackageAPI,
        unsubscribePackageAPI,
        resubscribePackageAPI,
        changePlanAPI,
        verifiedSessionAPI,
        //show password 
        getHistoryOfPayments
    };
};
