import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { memo } from "react";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Index(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={props.open}
        onClose={props.closeCall}
        severity={props.severity}
        autoHideDuration={props.autoHideDuration || 3000}
      >
        <Alert
          onClose={props.closeCall}
          severity={props.severity}
          sx={{
            color: "white",
          }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default memo(Index);
