import React, { useState, useCallback } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  styled,
  TableContainer,
  tableCellClasses,
  Paper,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import "./DataTable.scss";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { Pagination as MuiPagination } from "@mui/material";
import PropTypes from "prop-types";
import { AiOutlineStop } from "react-icons/ai";
import { useDispatch } from "react-redux";
import SetPageLimt from "redux/actions/SetPageLimit";

const DataTable = ({
  items,
  itemsPerPage,
  fields,
  scopedSlots,
  sorter,
  pagination,
  paginationLimit,
  pageList,
  pageName,
  tableFilter,
}) => {
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const [pageLimit, setPageLimit] = useState(itemsPerPage);
  const [filteredItems, setFilteredItems] = useState([...items]);

  React.useEffect(() => {
    setFilteredItems([...items]);
  }, [items]);

  !pagination && (itemsPerPage = items.length);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const dispatch = useDispatch();

  const handleSortClick = (field) => {
    if (sorter) {
      if (orderBy === field && order === "asc") {
        setOrder("desc");
      } else {
        setOrderBy(field);
        setOrder("asc");
      }
    }
  };
  const sortItems = (items) => {
    if (sorter && orderBy) {
      const field = fields.find((f) => f.key === orderBy);
      if (field) {
        const sortFn = field.sortFn || defaultSortFn;
        items.sort((a, b) => {
          const aVal = sortFn(a[orderBy]);
          const bVal = sortFn(b[orderBy]);
          if (aVal && bVal) {
            return (order === "asc" ? 1 : -1) * aVal.localeCompare(bVal);
          }
          return 0;
        });
      }
    }
    return items;
  };

  const defaultSortFn = (val) => {
    if (typeof val === "string") {
      return val.toLowerCase();
    }
    return val;
  };

  var sortedItems = sortItems([...items]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary["dark"],
      color: "#FFFFFF!important",
      fontFamily: "Barlow !important",
      fontWeight: "600",
      textAlign: "left!important",
      fontSize: "1rem!important",
      padding: "14px 10px 14px 10px",
      border: "2px solid ",
      borderColor: theme.palette.primary["dark"],
      width: "auto",
    },
    [`&.${tableCellClasses.body}`]: {
      fontFamily: "Barlow !important",
      fontSize: "1rem!important",
      padding: "10px",
      color: "#373240",
      width: "auto",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover + "!important",
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: "1px",
    backgroundColor: "#FFFFFF",
    margin: "1rem 0",
    boxShadow: "none"
  }));

  const StyledTable = styled(Table)(({ theme }) => ({
    borderCollapse: "collapse",
    border: "1px solid #CCCCCC",
  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderCollapse: "collapse",
    border: "1px solid #CCCCCC",
    textAlign: "left",
  }));

  const changePageLimit = (event) => {
    itemsPerPage = event.target.value;
    setPageLimit(event.target.value);
    dispatch(
      SetPageLimt({
        pageName: pageName,
        pageLimit: event.target.value,
      })
    );
    setPage(1);
  };

  const handleSearch = useCallback((e) => {
    if (e) {
      const searchValue = e.target.value.trim().toLowerCase();
      const searchResults = items.filter((item) => {
        return Object.values(item).some((value) => {
          const valueString = value.toString().toLowerCase();
          return valueString.includes(searchValue)
        });
      });
      setFilteredItems(searchResults);
    }
  }, [items]);




  return (
    <>
      {tableFilter && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <label>Filter</label>
            <TextField
              id="outlined-basic"
              placeholder="Type string ..."
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0px",
                  backgroundColor: "#F5F5F5",
                  padding: "5px 6px",
                },
              }}
              onChange={(e) => handleSearch(e)}
            />

          </Grid>
        </Grid>
      )}


      <StyledTableContainer component={Paper}>
        <StyledTable
          sx={{ minWidth: 750 }}
          aria-label="customized table"
          dense="true"
          table="true"
        >
          <StyledTableHead>
            <TableRow
              sx={{
                backgroundColor: "#3858A0",
              }}
            >
              {fields.map((field, i) => {
                const isSortable =
                  field.sort !== false && sortedItems.length > 0 && sorter;
                const isActive = orderBy === field.key;
                const direction = isActive ? order : "asc";
                const label = field.label || field.key;

                return (
                  <StyledTableCell
                    key={i}
                    sx={{
                      color: "#fff",
                    }}
                    sortDirection={isActive ? order : false}
                  >
                    {isSortable ? (
                      <TableSortLabel
                        IconComponent={HiOutlineArrowNarrowDown}
                        active={isActive}
                        direction={direction}
                        onClick={() => handleSortClick(field.key)}
                      >
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {sortItems([...filteredItems]).length < pageLimit
              ? sortItems([...filteredItems]).map((item, i) => (
                <StyledTableRow key={i}>
                  {fields.map((field, index) => (
                    <StyledTableCell key={index}>
                      {scopedSlots !== undefined ? scopedSlots[field.key]
                        ? scopedSlots[field.key](item)
                        : item[field.key]

                        : item[field.key]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
              : sortItems([...filteredItems])
                .slice(pageLimit * (page - 1), pageLimit * page)
                .map((item, i) => (
                  <StyledTableRow key={i}>
                    {fields.map((field, index) => (
                      <StyledTableCell key={index}>
                        {scopedSlots !== undefined ? scopedSlots[field.key]
                          ? scopedSlots[field.key](item)
                          : item[field.key]

                          : item[field.key]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}

            {sortItems([...filteredItems]).length === 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={fields.length} align="center">
                  <div
                    className="no-data-found"
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        margin: "0",
                      }}
                    >
                      No Items
                    </h3>
                    <AiOutlineStop
                      style={{
                        color: "rgb(227, 20, 20)",
                        marginLeft: "10px",
                        fontSize: "1.5rem",
                      }}
                    />
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      {pagination &&
        Math.ceil(sortItems([...filteredItems]).length / pageLimit) > 1 && (
          <MuiPagination
            onChange={(e, page) => handlePageChange(page)}
            count={Math.ceil(items.length / pageLimit)}
            page={page}
            color="primary"
            shape="rounded"
            sx={{
              marginTop: "20px",
            }}
          />
        )}

      {paginationLimit &&
        sortItems([...filteredItems]).length > pageList[0] && (
          <div
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <label>Items per page :</label>
            <FormControl
              sx={{
                m: 1,
                minWidth: 50,
              }}
              size="small"
            >
              <Select
                defaultValue={pageLimit}
                value={pageLimit}
                name="limit"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "4px 14px",
                    fontFamily: "Barlow",
                  },
                }}
                onChange={(e) => changePageLimit(e)}
              >
                {pageList.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
    </>
  );
};

DataTable.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  fields: PropTypes.array.isRequired,
  scopedSlots: PropTypes.object,
  sorter: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationLimit: PropTypes.bool,
  pageList: PropTypes.array,
  pageName: PropTypes.string,
  tableFilter: PropTypes.bool,
};

DataTable.defaultProps = {
  itemsPerPage: 10,
  sorter: false,
  pagination: false,
  paginationLimit: false,
  pageList: [10, 20, 50, 100],
  tableFilter: false,
};

export default React.memo(DataTable);
