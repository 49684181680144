import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Modal, Table as CustomTable } from "react-bootstrap";
import { CCol, CRow } from "@coreui/react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Cookies from 'js-cookie';
import { jsPDF } from "jspdf";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import customDateFormat from "utils/customDateFormat";
import customDateFormatLocal from "utils/customDateFormatLocal";
import invoiceDateFormat from "utils/invoiceDateFormat";
import CancelIcon from "@mui/icons-material/Cancel";
import Pagination from "components/pagination/pagination";
import PageLoader from "components/PageLoader/PageLoader";
import AlertMessage from "components/message/message";
import ArchiveIcon from "@mui/icons-material/Archive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled, alpha } from "@mui/material/styles";
import "../common.css";
import { saveAs } from "file-saver";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from "@mui/icons-material/Replay";
import "react-datepicker/dist/react-datepicker.css";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import DataLoader from "components/DataLoader/DataLoader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Papa from "papaparse";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  TableSortLabel,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { HiOutlineSearch, HiOutlineArrowNarrowDown } from "react-icons/hi";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import getStatus from "utils/getStatus";
import { LoadingButton } from "@mui/lab";
import { ContainerLoader } from "components/common/ContainerLoader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const getStyles = (label, filterstatusVal, theme) => {
  return {
    fontWeight:
      filterstatusVal?.indexOf(label) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}
const exportToCSV = (data) => {
  const dataFiltered = data.filter(
    (item) => item.delivery_status !== 11 && item.delivery_status !== 12
  );
  const csv = convertToCSV(dataFiltered);
  const file = new Blob([csv], { type: "text/csv" });
  saveAs(file, "data.csv");
};

const convertToCSV = (data) => {
  const dataFiltered = data.map((item, index) => {
    const RowValue = typeof item?.row === 'number' ? item?.row ?? "" : item?.row ?? "";
    const SeatNumbers = typeof item?.seatNumbers === 'number' ? item?.seatNumbers ?? "" : item?.seatNumbers ?? "";
    const Section = typeof item?.section === 'number' ? item?.section ?? "" : item?.section ?? "";
    const InvoiceDate = typeof item?.invoiceDate === 'number' ? Moment(item?.invoiceDate).format("MM/DD/YYYY") : Moment(item?.invoiceDate).format("MM/DD/YYYY");
    return {
      User: item?.user && item?.user[0]?.email,
      Date: '\t' + InvoiceDate,
      Team: item?.event[0]?.performer?.name ?? "",
      Vendor: item?.account_detail[0]?.username ?? "",
      Section: '\t' + Section,
      Row: '\t' + RowValue,
      Seat: '\t' + SeatNumbers,
      Marketplace: item?.customerDisplayName,
      "External Ref": '\t' + item?.invoiceExternalRef,
      Event: item?.event[0]?.name ?? "",
      "Event Date": customDateFormat(item?.event[0]?.date),
      Status: getStatus(item?.delivery_status),
      "Location/Venue": item?.event[0]?.venue?.name ?? "",
      "Ticket Url": item?.ticketurl,
    }
  });

  return Papa.unparse(dataFiltered);
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary["dark"],
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    width: "100%",
    minWidth: "max-content",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderLeft: "1px solid #CCCCCC",
    borderBottom: "1px solid #CCCCCC",
    width: "auto",
    padding: "5px 14px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

class Soldinventlist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      successError: "",
      totalCountries: "",
      soldList: [],
      loader: false,
      dataloader: false,
      allCountries: [],
      currentCountries: [],
      currentPage: 1,
      totalPages: 200,
      pageLimit: 20,
      total: "",
      index: 0,
      event: "",
      section: "",
      row: "",
      seat: "",
      dateVal: "",
      startDate: null,
      endDate: null,
      startEventDate: null,
      endEventDate: null,
      monthsEventShown: 2,
      startDate1: null,
      endDate1: null,
      refId: null,
      filterstatus: [],

      filterstatusVal: [],
      filterref: null,
      filterphone: null,
      sortBy: null,
      sortByOrder: null,
      crossCheck: true,
      crossEventCheck: true,
      endcrossCheck: true,
      modalShow: false,
      userData: "",
      userVal: "",
      email: "",
      phone: "",
      modelError: "",
      modelSuccess: "",
      id: "",
      ticketurl: null,
      twiliomessages: [],
      tmModel: false,
      tm1Model: false,
      timezone: null,
      currentRow: null,
      pos: [],
      invLogs: [],
      loadLogs: false,
      modalShow1: false,
      modalDeleteShow: false,
      deletingInv: false,
      deleteId: null,
      confirmId: null,
      confirmStatus: null,
      confirmStatusLabel: null,
      message: "",
      duration: 1,
      styleInactive: false,
      activeasc: false,
      activedesc: false,
      isDisableButtonExportAll: true,
      activestyle: { position: "absolute", right: 0, fontSize: "1.3rem" },
      formData: {
        customerName: "",
        customerEmail: "",
        ticketURL: "",
        customerPhone: "",
        invoiceDate: "",
        team: "",
        section: "",
        rows: "",
        seat: "",
        marketPlace: "",
        invoiceExternalRef: "",
        eventName: "",
        eventDate: "",
        tickets: "",
      },
      sendmessagedate: {
        id: "",
        template: "",
        message: "",
        customerphone: "",
        submit: false,
      },
      sendMessageModal: false,
      searchPage: 0,
      sortloading: false,
      openmenu: false,
      anchorEl: null,
      monthsShown: 2,
      direction: "asc",
      theme: "light"
    };

    this.getAllUsers = this.getAllUsers.bind(this);
    this.getLists = this.getLists.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleSearchSection = this.handleSearchSection.bind(this);
    this.handleSearchRow = this.handleSearchRow.bind(this);
    this.handleSeat = this.handleSeat.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleCustomerPhone = this.handleCustomerPhone.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartDate1 = this.setStartDate1.bind(this);

    this.setStartDateEvent = this.setStartDateEvent.bind(this);
    this.clearEventDate = this.clearEventDate.bind(this);

    this.clearDate = this.clearDate.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDeletePopupShow = this.onDeletePopupShow.bind(this);
    this.onDeletePopupHide = this.onDeletePopupHide.bind(this);
    this.deleteInventory = this.deleteInventory.bind(this);
    this.onStatusChangePopupHide = this.onStatusChangePopupHide.bind(this);
    this.onStatusChangePopupShow = this.onStatusChangePopupShow.bind(this);
    this.statusChangeInventory = this.statusChangeInventory.bind(this);
    this.handleSelectDuration = this.handleSelectDuration.bind(this);
    this.getTwilioMessages = this.getTwilioMessages.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleRotateIcon = this.handleRotateIcon.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }
    const search_params = this.props.location.search;
    const url_search_params = new URLSearchParams(search_params);
    const refId = url_search_params.get("refId");

    this.setState({ loader: true, refId: refId }, () => {
      this.getAllUsers();
      this.getLists();
    });
  }
  handleCloseMenu = () => {
    this.setState({ openmenu: false });
  };
  handleClickMenu = (event, index) => {
    this.setState({ openmenu: index, anchorEl: event.currentTarget });
  };
  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  getAllUsers = () => {
    const userDetail_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userDetail_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        let { data } = response;
        if (data.data) {
          this.setState({ usersData: data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    this.setState({
      open: true,
      message: "URL copied to clipboard",
      successError: "success",
    });
  };
  async handleRotateIcon(columnname) {
    if (this.state.currentColumn === null) {
      await this.setState({ currentColumn: columnname });
    }

    if (this.state.currentColumn !== columnname) {
      await this.setState({
        activeasc: false,
        activedesc: false,
        currentColumn: columnname,
        styleInactive: false,
        direction: "",
      });
    }

    if (this.state.currentColumn === columnname) {
      if (
        !this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        this.setState({
          styleInactive: true,
          currentColumn: columnname,
          direction: "asc",
        });
        console.log("1");
      }
      if (
        this.state.styleInactive &&
        !this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: false });
        await this.setState({
          styleInactive: true,
          activeasc: true,
          activedesc: false,
          direction: "desc",
        });
        console.log("2");
      } else if (
        this.state.styleInactive &&
        this.state.activeasc &&
        !this.state.activedesc &&
        this.state.currentColumn === columnname
      ) {
        this.customSorting({ column: columnname, asc: true });
        await this.setState({
          currentColumn: columnname,
          activeasc: false,
          activedesc: false,
          direction: "asc",
        });
        console.log("3");
      }
    }
  }
  getLists = () => {
    const params = {
      index: this.state.index,
      data_period: this.state.duration,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      user: this.state.userVal ? this.state.userVal.email : null,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      startDate: this.state.startEventDate != null
        ? Moment(this.state.startEventDate).format("YYYY-MM-DD")
        : "",

      endDate: this.state.endEventDate != null
        ? Moment(this.state.endEventDate).format("YYYY-MM-DD")
        : "",

      fromDate: this.state.startDate != null
        ? Moment(this.state.startDate).format("YYYY-MM-DD")
        : "",

      toDate: this.state.endDate != null
        ? Moment(this.state.endDate).format("YYYY-MM-DD")
        : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryAdmin";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            sortloading: false,
            dataloader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({ totalCountries: data.total });
          this.setState({
            soldList: data.data,
            open: false,
            message: "",
            successError: "success",
            loader: false,
            dataloader: false,
            sortloading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  onPageChanged = async (data) => {
    const { currentPage, pageLimit } = data;

    this.setState(
      { dataloader: true, index: (currentPage - 1) * pageLimit },
      () => {
        this.getLists();
      }
    );
  };

  handleExportAll = async () => {
    this.setState({ exportLoader: true });
    const params = {
      exportAll: true,
      index: this.state.index,
      data_period: this.state.duration,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      user: this.state.userVal ? this.state.userVal.email : null,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      startDate: this.state.startEventDate != null
        ? Moment(this.state.startEventDate).format("YYYY-MM-DD")
        : "",
      endDate: this.state.endEventDate != null
        ? Moment(this.state.endEventDate).format("YYYY-MM-DD")
        : "",
      fromDate: this.state.startDate != null
        ? Moment(this.state.startDate).format("YYYY-MM-DD")
        : "",
      toDate: this.state.endDate != null
        ? Moment(this.state.endDate).format("YYYY-MM-DD")
        : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };
    console.log(params, 'paramsparams')
    try {
      const inventoryList_api_url = process.env.REACT_APP_API_URL + "/api/inventory/getSoldInventoryAdmin";
      const response = await axios.post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data.error) {
        this.setState({
          loader: false,
          dataloader: false,
          open: true,
          message: data.message,
          exportLoader: false,
          successError: "error",
        });
      } else {
        exportToCSV(data.data)
        this.setState({ exportLoader: false });
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        exportLoader: false,
        message: "Something went wrong",
        successError: "error",
      });
    }
  };

  handleChangeEvent = (data) => {
    this.setState({ event: data.target.value });
  };
  handleSearchSection = (data) => {
    this.setState({ section: data.target.value });
  };
  handleSearchRow = (data) => {
    this.setState({ row: data.target.value });
  };
  handleSeat = (data) => {
    this.setState({ seat: data.target.value });
  };
  handleCustomerPhone = (data) => {
    this.setState({ filterphone: data.target.value });
  };
  handleRef = (data) => {
    this.setState({ filterref: data.target.value });
  };
  handleChangeDate = (data) => {
    this.setState({ dateVal: data.target.value });
  };

  setStartDate = async (dates) => {
    const [start, end] = dates;
    console.log(dates);
    const { formData } = this.state;
    formData.invoiceDate = dates[0];
    this.setState({
      startDate: start,
      formData: this.state.formData,
      endDate: end,
      crossCheck: false,
    });
  };

  setStartDate1 = async (dates) => {
    const [start, end] = dates;
    console.log(dates);
    const { formData } = this.state;
    formData.eventDate = dates[0];
    this.setState({
      startDate1: start,
      formData: this.state.formData,
      endDate1: end,
      crossCheck: false,
    });
  };

  setStartDateEvent = (dates) => {
    const [start, end] = dates;
    const { formData } = this.state;
    console.log(formData)
    formData.toDate = end
    formData.fromDate = start
    this.setState({
      startEventDate: start,
      formData: this.state.formData,
      endEventDate: end,
      crossEventCheck: false,
    });
  }


  clearEventDate = () => {
    if (this.state.startEventDate != null) {
      this.setState({ startEventDate: null, endEventDate: null, crossEventCheck: true });
    } else {
      this.setState({ crossEventCheck: true });
    }
  }

  clearDate = () => {
    if (this.state.startDate != null) {
      this.setState({ startDate: null, endDate: null, crossCheck: true });
    } else {
      this.setState({ crossCheck: true });
    }
  };

  handleModelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = (data) => {
    console.log(data);

    this.setState({
      modalShow: true,
      userData: data,
      modelSuccess: "",
      modalError: "",
      email: typeof data !== "undefined" ? data.customerEmail : "",
      phone: typeof data !== "undefined" ? data.customerPhone : "",
      id: typeof data !== "undefined" ? data._id : "",
      ticketurl: data.ticketurl ? data.ticketurl : null,
    });
  };

  handleSelectDuration(e) {
    this.setState({ dataloader: true, duration: e.target.value }, () => {
      this.handleSubmit();
    });
  }
  handleSubmitModal = async (data) => {
    const param = {
      id: this.state.id,
      customer_email: this.state.email,
      customer_phone: this.state.phone,
      ticketurl: this.state.ticketurl,
    };
    const updateSoldInventory_api_url =
      process.env.REACT_APP_API_URL +
      "/api/inventory/updateSoldInventoryCustomerInfo";
    await axios
      .post(updateSoldInventory_api_url, param, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: response.data.message,
            successError: "error",
            modelError: response.data.message,
          });
        } else {
          if (data.success) {
            this.getLists();
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  onHide = () => {
    this.setState({ modalShow: false });
  };

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleSubmitTicket = () => {
    this.setState({ loader: true }, () => {
      const addInventory =
        process.env.REACT_APP_API_URL + "/api/user/addInventory";
      axios
        .post(addInventory, this.state.formData, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            console.log(data);
            this.setState({
              loader: false,
              open: true,
              message: data.msg,
              successError: "error",
            });
          } else {
            console.log(data);
            this.setState({
              loader: false,
              open: true,
              message: data.msg,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
      // }
    });
  };

  handleChange(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  handleStatusFilter(event) {
    const { value } = event.target;
    if (value) {
      this.setState({
        filterstatus: value,
        filterstatusVal: typeof value === "string" ? value.split(",") : value
      });
    } else {
      this.setState({
        filterstatus: [],
        filterstatusVal: []
      });
    }
  }

  handleChangeUser = (e, newVal) => {
    this.setState({ userVal: newVal });
  };

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState(
      { index: 0, searchPage: 1, isDisableButtonExportAll: false, formData, dataloader: true, refId: null },
      () => {
        this.getLists();
      }
    );
  }

  changePageLimit = (e) => {
    this.setState(
      { searchPage: 1, pageLimit: parseInt(e.target.value) },
      () => {
        this.handleSubmit();
      }
    );
  };

  opentmModel = (data) => {
    const twiliomessagedownload = data.twiliomessages
      .sort((a, b) => new Date(b.sentat) - new Date(a.sentat))
      .map((tm) => {
        var ret = {};
        if (tm.sms_type) {
          ret.Status =
            tm.sms_type.charAt(0).toUpperCase() + tm.sms_type.slice(1);
        } else {
          ret.Status = "Delivered";
        }

        if (tm.sms_type === "received") {
          ret.PhoneNumber = tm.sentby;
        } else {
          ret.PhoneNumber = tm.phone_number;
        }

        ret.Message = tm.message_body;
        ret.SentDate = customDateFormat(tm.sentat);

        return ret;
      });
    console.log(data.twiliomessages);
    this.setState({
      tmModel: true,
      twiliomessages: data.twiliomessages,
      twiliomessagedownload,
    });
  };

  // Function to get Twilio Messages
  getTwilioMessages = (data) => {
    this.setState({ currentRow: data });
    const id = data._id;
    const getTwiliomsg =
      process.env.REACT_APP_API_URL +
      "/api/admin/inventory/getTiwilioMsgSoldInv";
    axios
      .post(
        getTwiliomsg,
        { _id: id },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          this.opentmModel(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getLogsByInventory(id) {
    this.setState({ tm1Model: true, loadLogs: true });
    const msg_by_twelio_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getInventoryStatuses";
    axios
      .post(
        msg_by_twelio_api_url,
        { inventory_id: id._id },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        if (response.data) {
          this.setState({
            invLogs: response.data.lists,
            loadLogs: false,
            currentRow: id,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onDeletePopupShow(inv) {
    this.setState({ deleteId: inv._id, modalDeleteShow: true });
  }

  onDeletePopupHide(inv) {
    this.setState({ deleteId: null, modalDeleteShow: false });
  }

  deleteInventory() {
    this.setState({ loader: true }, () => {
      const archive_api_url =
        process.env.REACT_APP_API_URL +
        "/api/inventory/deleteSoldInventoryByAdmin";
      axios
        .post(
          archive_api_url,
          { inventory_id: this.state.deleteId },
          {
            headers: {
              authorization:
                "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
          }
        )
        .then((response) => {
          this.setState({ deleteId: null, modalDeleteShow: false });
          this.getLists();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  onStatusChangePopupShow(inv, newStatus, statusLabel) {
    this.setState({
      confirmId: inv._id,
      modalConfirmShow: true,
      confirmStatus: newStatus,
      confirmStatusLabel: statusLabel,
    });
  }
  onStatusChangePopupHide() {
    this.setState({
      confirmId: null,
      modalConfirmShow: false,
      confirmStatus: null,
      confirmStatusLabel: null,
    });
  }
  statusChangeInventory() {
    this.setState({ loader: true }, () => {
      const archive_api_url =
        process.env.REACT_APP_API_URL +
        "/api/inventory/updateDeliveryStatusByAdmin";
      axios
        .post(
          archive_api_url,
          {
            inventory_id: this.state.confirmId,
            status: this.state.confirmStatus,
          },
          {
            headers: {
              authorization:
                "Bearer " + localStorage.getItem("accessTokenAdmin"),
            },
          }
        )
        .then((response) => {
          this.setState({
            confirmId: null,
            modalConfirmShow: false,
            confirmStatus: null,
            confirmStatusLabel: null,
          });
          this.getLists();
        })
        .catch((err) => {
          this.setState({
            confirmId: null,
            modalConfirmShow: false,
            confirmStatus: null,
            confirmStatusLabel: null,
          });
          console.log(err);
        });
    });
  }

  tmModelonHide = () => {
    this.setState({ tmModel: false, twiliomessages: [] });
  };

  tm1ModelonHide = () => {
    this.setState({ tm1Model: false, twiliomessages: [] });
  };

  add = () => {
    this.setState({ modalShow1: true });
  };

  onHide1 = () => {
    this.setState({ modalShow1: false });
  };

  handleModelChangeSelect = (evt, val, name) => {
    const { formData } = this.state;
    formData["marketPlace"] = val.title;
    this.setState({ formData });
  };

  downloadAsPdf = () => {
    const headers = [
      {
        id: "Status",
        name: "Status",
        prompt: "Status",
        width: 45,
        align: "center",
        padding: 0,
      },
      {
        id: "Message",
        name: "Message",
        prompt: "Message",
        width: 135,
        align: "center",
        padding: 0,
      },
      {
        id: "SentDate",
        name: "SentDate",
        prompt: "Sent Date",
        width: 65,
        align: "center",
        padding: 0,
      },
    ];

    /*
            { id: "PhoneNumber", name: "PhoneNumber", prompt: "Phone Number", width: 55, align: "center", padding: 0 },
        */

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "portrait" });

    doc.setFontSize(12);

    var phone_number =
      this.state.twiliomessages[0].sms_type === "received"
        ? this.state.twiliomessages[0].sentby
        : this.state.twiliomessages[0].phone_number;

    doc.setFont("courier", "bold");
    doc.text("Event: ", 10, 20);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.event[0].name, 27, 20);

    doc.setFont("courier", "bold");
    doc.text("Event Date: ", 10, 26);
    doc.setFont("courier", "normal");
    doc.text(customDateFormat(this.state.currentRow.event[0].date), 39, 26);

    doc.setFont("courier", "bold");
    doc.text("Marketplace: ", 10, 32);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.customerDisplayName, 42, 32);

    doc.setFont("courier", "bold");
    doc.text("External Ref: ", 10, 38);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.invoiceExternalRef, 45, 38);

    doc.setFont("courier", "bold");
    doc.text("Date: ", 10, 44);
    doc.setFont("courier", "normal");
    doc.text(customDateFormat(this.state.currentRow.invoiceDate), 25, 44);

    doc.setFont("courier", "bold");
    doc.text("Team: ", 10, 50);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.event[0].name, 25, 50);

    doc.setFont("courier", "bold");
    doc.text("Section: ", 10, 56);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.section, 32, 56);

    doc.setFont("courier", "bold");
    doc.text("Row: ", 55, 56);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.row, 67, 56);

    doc.setFont("courier", "bold");
    doc.text("Seat: ", 95, 56);
    doc.setFont("courier", "normal");
    doc.text(this.state.currentRow.seatNumbers, 109, 56);

    doc.setFont("courier", "bold");
    doc.text("Phone Number: ", 10, 62);
    doc.setFont("courier", "normal");
    doc.text(phone_number, 45, 62);

    doc.table(10, 72, this.state.twiliomessagedownload, headers, {
      autoSize: false,
    });

    if (this.state.currentRow.invoiceExternalRef !== "") {
      doc.save(this.state.currentRow.invoiceExternalRef + ".pdf");
    } else {
      doc.save("sold-inventory-messages.pdf");
    }
  };

  customSorting = (obj) => {
    console.log(obj);
    if (obj) {
      if (obj.column) {
        const { formData } = this.state;
        formData["skip"] = 0;
        this.setState(
          {
            sortloading: true,
            formData,
            sortBy: obj.column,
            sortByOrder: obj.asc ? "asc" : "desc",
          },
          () => {
            this.getLists();
          }
        );
      }
    }
  };

  opensendMessageModel = (data) => {
    console.log(data);
    const { sendmessagedate } = this.state;
    sendmessagedate.id = data._id;
    sendmessagedate.customerphone = data.customerPhone;
    sendmessagedate.template = "sold";
    this.setState({ sendMessageModal: true, sendmessagedate });
  };

  sendMessageModalHide = () => {
    const sendmessagedate = {
      id: "",
      template: "",
      message: "",
      customerphone: "",
    };
    this.setState({ sendMessageModal: false, sendmessagedate });
  };

  handleChangeSendmessage = (e) => {
    const { sendmessagedate } = this.state;
    sendmessagedate[e.target.name] = e.target.value;
    this.setState({ sendmessagedate });
  };

  sendMessageSubmit = () => {
    const { sendmessagedate } = this.state;
    sendmessagedate.submit = true;
    this.setState({ sendmessagedate }, () => {
      const sendmessage_api_url =
        process.env.REACT_APP_API_URL + "/api/inventory/sendMessageInventory";
      axios
        .post(sendmessage_api_url, this.state.sendmessagedate, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.sendMessageModalHide();
            this.setState({
              loader: false,
              open: false,
              message: "Send message successfully",
              successError: "success",
            });
            this.handleSubmit();
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  handleClearSelection = () => {
    this.setState({ filterstatusVal: [], filterstatus: [] });
  };

  render() {
    const { filterstatusVal } = this.state;
    const marketplace = [
      { title: "VividSeats" },
      { title: "SeatGeek" },
      { title: "Manual/None" },
      { title: "Other" },
    ];
    const {
      loader,
      totalCountries,
      pageLimit,
      usersData,
      sendmessagedate,
      modalDeleteShow,
    } = this.state;

    const durationField = [
      { label: "Last One Month", key: "1" },
      { label: "Last Three Months", key: "3" },
      { label: "Last Six Months", key: "6" },
      { label: "Last One Year", key: "12" },
      { label: "Last Five Years", key: "60" },
      { label: "All", key: "0" },
    ];

    const linkdesign = {

      color: "#002986!important",

      fontFamily: "Barlow",
      fontWeight: "500",
      svg: {
        color: "#002986!important",
        fontSize: "1.3rem!important",
      },
      ":hover": {
        color: "#b60000!important",
        svg: {
          color: "#b60000!important",
        },
      },
    };
    // const filterstatus
    const filterstatus = [
      { value: 0, label: "No message sent" },
      { value: 1, label: "Sold message sent" },
      { value: 2, label: "Delivery message sent" },
      { value: 3, label: "Confirmation message sent" },
      { value: 4, label: "Order Confirmed" },
      { value: 5, label: "Problem in Order" },
      { value: 7, label: "Need Manual Review" },
      { value: 8, label: "Phone number Blacklisted" },
      { value: 9, label: "Phone number Invalid" },
      { value: 10, label: "No ticket found" },
      { value: 11, label: "Manually Transferred" },
      { value: 12, label: "Delivery Revoked" },
      { value: 13, label: "Ticket Uploaded" },
      { value: 14, label: "Link Generated" },
      { value: 15, label: "Event Reminder" },
      { value: 16, label: "Ticket Not Uploaded" },
      { value: 17, label: "Account Deleted" },
    ];
    return (
      <>
        <Helmet>
          <title>Sold Inventory</title>
        </Helmet>
        {/* 
        {loader && (
          <div
            style={{
              position: "relative",
              backgroundColor: "#EBEDEF",
              height: "100vh",
            }}
          >
            {" "}
            <PageLoader />{" "}
          </div>
        )} */}
        <Grid container spacing={2}>
          <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{}}>
            <Card>
              <CardContent className="plan-tbl sold-invent-outer">
                <div className="usage-tbl-header">
                  <h3>Sold Inventory</h3>
                </div>

                <ValidatorForm
                  // ref="form"
                  onSubmit={this.handleSubmit}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 2, lg: 2 }}>
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <TextField
                          variant="outlined"
                          onChange={this.handleChangeEvent}
                          name="event_name"
                          value={this.state.event}
                          placeholder="Search Event"
                          className="filter-input"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <DatePicker
                          placeholderText="Search Date"
                          selected={this.state.startDate}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          monthsShown={this.state.monthsShown}
                          selectsRange
                          isClearable={true}
                          customInput={
                            <TextField
                              variant="outlined"
                              className="filter-input"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="date-icon"
                                  >
                                    <div className="d-flex">
                                      {this.state.crossCheck ? (
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          <EventIcon className="text-dark" />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          edge="end"
                                          onClick={() => this.clearDate()}
                                        >
                                          <ClearIcon className="text-dark" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                          onChange={(dates) => this.setStartDate(dates)}
                        // filterDate={(date) => {
                        //   var tdate = new Date();
                        //   tdate.setDate(tdate.getDate() - 1);
                        //   var dateString = tdate
                        //     .toISOString()
                        //     .split("T")[0];

                        //   return date >= new Date(dateString);
                        // }}
                        />
                      </Grid>

                      {/*  Event Date  */}
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <DatePicker
                          placeholderText="Event Date"
                          selected={this.state.startEventDate}
                          startDate={this.state.startEventDate}
                          endDate={this.state.endEventDate}
                          monthsShown={this.state.monthsEventShown}
                          selectsRange
                          isClearable={true}
                          customInput={
                            <TextField
                              variant="outlined"
                              className="filter-input"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="date-icon"
                                  >
                                    <div className="d-flex">
                                      {this.state.crossEventCheck ? (
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          <EventIcon className="text-dark" />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          edge="end"
                                          onClick={() => this.clearEventDate()}
                                        >
                                          <ClearIcon className="text-dark" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                          onChange={(dates) => this.setStartDateEvent(dates)}
                        // filterDate={(date) => {
                        //   var tdate = new Date();
                        //   tdate.setDate(tdate.getDate() - 1);
                        //   var dateString = tdate
                        //     .toISOString()
                        //     .split("T")[0];

                        //   return date >= new Date(dateString);
                        // }}
                        />
                      </Grid>



                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <TextValidator
                          variant="outlined"
                          className="filter-input"
                          onChange={this.handleSearchSection}
                          name="section"
                          placeholder="Search Section"
                          value={this.state.section}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <TextValidator
                          variant="outlined"
                          onChange={this.handleSearchRow}
                          className="filter-input"
                          name="rows"
                          placeholder="Search Row"
                          value={this.state.row}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <TextValidator
                          className="filter-input"
                          variant="outlined"
                          onChange={this.handleSeat}
                          name="seat"
                          placeholder="Search Seat"
                          value={this.state.seat}
                        />
                      </Grid>

                      {/* working this  */}
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel id="demo-multiple-name-label" sx={{ zIndex: 'inherit' }}>
                            Select Status
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            inputProps={{
                              IconComponent: () =>
                                filterstatusVal.length > 0 && (
                                  <ClearIcon
                                    onClick={this.handleClearSelection}
                                    sx={{
                                      cursor: 'pointer',
                                      position: 'relative',
                                      right: '10px',
                                    }}
                                  />
                                ),
                            }}
                            fullWidth
                            value={filterstatusVal}
                            onChange={this.handleStatusFilter}
                            input={<OutlinedInput label="Select Status" />}
                            MenuProps={MenuProps}
                          >
                            {filterstatus.map((name) => (
                              <MenuItem
                                key={name.value}
                                value={name.value}
                                style={getStyles(name.label, filterstatusVal, this.props.theme)}
                                sx={{
                                  fontWeight:
                                    filterstatusVal.indexOf(name.value) === -1
                                      ? 'normal'
                                      : 'bold',
                                }}
                              >
                                {name.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Autocomplete
                          onChange={this.handleChangeUser}
                          fullWidth
                          id="combo-box-demo"
                          name="userVal"
                          options={usersData}
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => (
                            <TextValidator
                              value={this.state.userVal}
                              placeholder="Select User"
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        className="team"
                        xs={12}
                        sm={4}
                        md={2}
                        lg={2}
                      >
                        <TextValidator
                          className="filter-input"
                          variant="outlined"
                          onChange={this.handleRef}
                          name="filterref"
                          value={this.state.filterref}
                          placeholder="Search Ref."
                        />
                      </Grid>
                      <Grid
                        item
                        className="team"
                        xs={12}
                        sm={4}
                        md={2}
                        lg={2}
                      >
                        <TextValidator
                          className="filter-input"
                          variant="outlined"
                          onChange={this.handleCustomerPhone}
                          name="filterphone"
                          placeholder="Search Phone"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={2} lg={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="filter-btn "
                          startIcon={<HiOutlineSearch />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
                {!loader && !this.state.dataloader ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {!this.state.isDisableButtonExportAll ?
                            (<LoadingButton
                              onClick={() => this.handleExportAll()}
                              size="large"
                              endIcon={<SaveAltIcon />}
                              loading={this.state.exportLoader || this.state.dataloader}
                              disabled={
                                !this.state.exportAllFilter &&
                                  this.state.soldList.length > 0
                                  ? false
                                  : true
                              }
                              loadingPosition="end"
                              sx={{
                                fontFamily: "Bevan, cursive !important",
                              }}
                              variant="contained"
                            >
                              <span>Export All</span>
                            </LoadingButton>)
                            :
                            (<LoadingButton
                              size="large"
                              endIcon={<SaveAltIcon />}
                              disabled={true}
                              sx={{
                                fontFamily: "Bevan, cursive !important",
                              }}
                              variant="contained"
                            >
                              <span>Export All</span>
                            </LoadingButton>)
                          }
                        </div>
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 60,
                          }}
                          size="md"
                        >
                          <Select
                            defaultValue={this.state.duration}
                            value={this.state.duration}
                            name="limit"
                            sx={{
                              "& .MuiSelect-select": {
                                paddingRight: "50px!important",
                                paddingTop: "13px",
                                paddingBottom: "13px",
                                fontFamily: "Barlow",
                              },
                            }}
                            onChange={(e) => this.handleSelectDuration(e)}
                          >
                            {durationField.map((item) => {
                              return (
                                <MenuItem value={item.key}>{item.label}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <div className="relative">
                      {this.state.dataloader ? (
                        <>
                          <DataLoader />
                        </>
                      ) : this.state.soldList.length > 0 ? (
                        <>
                          <TableContainer
                            component={Paper}
                            sx={{
                              marginBottom: "20px",
                              paddingBottom: "12px",
                            }}
                          >
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>User</StyledTableCell>
                                  <StyledTableCell sx={{ cursor: "pointer" }}>
                                    <TableSortLabel
                                      IconComponent={HiOutlineArrowNarrowDown}
                                      active={
                                        this.state.currentColumn === "invoiceDate"
                                      }
                                      direction={
                                        this.state.currentColumn === "invoiceDate"
                                          ? this.state.direction
                                          : "asc"
                                      }
                                      onClick={() =>
                                        this.handleRotateIcon("invoiceDate")
                                      }
                                    >
                                      Date
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell>Team</StyledTableCell>
                                  <StyledTableCell>Vendor</StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      cursor: "pointer",
                                      width: "4rem!important",
                                    }}
                                  >
                                    <TableSortLabel
                                      IconComponent={HiOutlineArrowNarrowDown}
                                      active={
                                        this.state.currentColumn === "section"
                                      }
                                      direction={
                                        this.state.currentColumn === "section"
                                          ? this.state.direction
                                          : "asc"
                                      }
                                      onClick={() =>
                                        this.handleRotateIcon("section")
                                      }
                                    >
                                      Section
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      cursor: "pointer",
                                      width: "4rem!important",
                                    }}
                                  >
                                    <TableSortLabel
                                      IconComponent={HiOutlineArrowNarrowDown}
                                      active={this.state.currentColumn === "row"}
                                      direction={
                                        this.state.currentColumn === "row"
                                          ? this.state.direction
                                          : "asc"
                                      }
                                      onClick={() => this.handleRotateIcon("row")}
                                    >
                                      Row
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      cursor: "pointer",
                                      width: "4rem!important",
                                    }}
                                  >
                                    <TableSortLabel
                                      IconComponent={HiOutlineArrowNarrowDown}
                                      active={
                                        this.state.currentColumn === "seatNumbers"
                                      }
                                      direction={
                                        this.state.currentColumn === "seatNumbers"
                                          ? this.state.direction
                                          : "asc"
                                      }
                                      onClick={() =>
                                        this.handleRotateIcon("seatNumbers")
                                      }
                                    >
                                      Seat
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    sx={{
                                      cursor: "pointer",
                                      width: "4rem!important",
                                    }}
                                  >
                                    <TableSortLabel
                                      IconComponent={HiOutlineArrowNarrowDown}
                                      active={
                                        this.state.currentColumn ===
                                        "customerDisplayName"
                                      }
                                      direction={
                                        this.state.currentColumn ===
                                          "customerDisplayName"
                                          ? this.state.direction
                                          : "asc"
                                      }
                                      onClick={() =>
                                        this.handleRotateIcon(
                                          "customerDisplayName"
                                        )
                                      }
                                    >
                                      Marketplace
                                    </TableSortLabel>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <div
                                      style={{
                                        width: "100%",
                                        minWidth: "max-content",
                                      }}
                                    >
                                      External Ref
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell>Event</StyledTableCell>
                                  <StyledTableCell>
                                    <div
                                      style={{
                                        width: "100%",
                                        minWidth: "max-content",
                                      }}
                                    >
                                      Event Date
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <div
                                      style={{
                                        width: "100%",
                                        minWidth: "max-content",
                                      }}
                                    >
                                      Internal Notes
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell>Status</StyledTableCell>
                                  <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody sx={{ position: "sticky" }}>
                                <Backdrop
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "rgba(255, 255, 255, 0.72)",
                                    position: "absolute!important",
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                  }}
                                  open={this.state.sortloading}
                                ></Backdrop>
                                {this.state.soldList.map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      {row.user && row.user[0].email}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      component="td"
                                      scope="row"
                                      onClick={() => this.openModal(row)}
                                    >
                                      {invoiceDateFormat(row.invoiceDate)}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {row.event !== "undefined"
                                          ? row.event[0].performer?.name
                                          : ""}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      onClick={() => this.openModal(row)}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {row.account_detail.length >= 1 &&
                                          row.account_detail[0].username}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      {row.section}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      {row.row}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {row.seatNumbers}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      {row.customerDisplayName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      onClick={() => this.openModal(row)}
                                      component="td"
                                      scope="row"
                                    >
                                      {row.invoiceExternalRef}
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {row.event !== "undefined"
                                          ? row.event[0].name
                                          : ""}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {row.event !== "undefined"
                                          ? customDateFormat(row.event[0].date)
                                          : ""}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell component="td" scope="row">
                                      {row.notes ? row.notes : ""}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                      <div
                                        style={{
                                          width: "100%",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        {!row?.delivery_status ? (
                                          <div>
                                            No message sent
                                            <br />
                                          </div>
                                        ) : (
                                          <div>
                                            {getStatus(row?.delivery_status)}
                                          </div>
                                        )}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                      <td className="py-2 icon-outer-font">
                                        <Tooltip title="Action List">
                                          <IconButton
                                            key={index}
                                            id={`demo-customized-button-${index}`}
                                            aria-controls={`demo-customized-button-${index}`}
                                            aria-haspopup="true"
                                            variant="contained"
                                            disableelevation
                                            onClick={(event) =>
                                              this.handleClickMenu(event, index)
                                            }
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <StyledMenu
                                          key={index + 1}
                                          id={`demo-customized-button-${index}`}
                                          anchorEl={this.state.anchorEl}
                                          keepMounted
                                          open={this.state.openmenu === index}
                                          onClose={this.handleCloseMenu}
                                        >
                                          {row.ticketurl ===
                                            (undefined || null) ? (
                                            ""
                                          ) : (
                                            <MenuItem
                                              sx={linkdesign}
                                              onClick={() => {
                                                this.copyUrl(row.ticketurl);

                                                this.handleCloseMenu();
                                              }}
                                              disableRipple
                                            >
                                              <ContentCopyIcon />
                                              Copy link
                                            </MenuItem>
                                          )}
                                          <MenuItem
                                            sx={linkdesign}
                                            onClick={() => {
                                              this.getTwilioMessages(
                                                row,
                                                "opentmModel"
                                              );

                                              this.handleCloseMenu();
                                            }}
                                            disableRipple
                                          >
                                            <VisibilityIcon />
                                            View
                                          </MenuItem>
                                          <MenuItem
                                            sx={linkdesign}
                                            onClick={() => {
                                              this.getLogsByInventory(row);
                                              this.handleCloseMenu();
                                            }}
                                            disableRipple
                                          >
                                            <ReceiptLongIcon />
                                            View Log
                                          </MenuItem>
                                          <MenuItem
                                            sx={linkdesign}
                                            onClick={() => {
                                              this.opensendMessageModel(row);
                                              this.handleCloseMenu();
                                            }}
                                            disableRipple
                                          >
                                            <SendToMobileIcon />
                                            Send
                                          </MenuItem>

                                          {row?.delivery_status !== 4 &&
                                            row?.delivery_status !== 6 && (
                                              <MenuItem
                                                sx={linkdesign}
                                                onClick={() => {
                                                  this.onStatusChangePopupShow(
                                                    row,
                                                    4,
                                                    "Confirm Manually"
                                                  );
                                                  this.handleCloseMenu();
                                                }}
                                                disableRipple
                                              >
                                                <TouchAppIcon />
                                                Manually Confirmed
                                              </MenuItem>
                                            )}
                                          {row?.delivery_status !== 11 && (
                                            <MenuItem
                                              sx={linkdesign}
                                              onClick={() => {
                                                this.onStatusChangePopupShow(
                                                  row,
                                                  11,
                                                  "Transfer Manually"
                                                );
                                                this.handleCloseMenu();
                                              }}
                                              disableRipple
                                            >
                                              <TouchAppIcon />
                                              Manually Transferred
                                            </MenuItem>
                                          )}
                                          {row?.delivery_status !== 12 && (
                                            <MenuItem
                                              sx={linkdesign}
                                              onClick={() => {
                                                this.onStatusChangePopupShow(
                                                  row,
                                                  12,
                                                  "Revoke Tickets"
                                                );
                                                this.handleCloseMenu();
                                              }}
                                              disableRipple
                                            >
                                              <ReplayIcon />
                                              Revoke Tickets
                                            </MenuItem>
                                          )}

                                          <MenuItem
                                            sx={linkdesign}
                                            onClick={() => {
                                              this.onDeletePopupShow(row);
                                              this.handleCloseMenu();
                                            }}
                                            disableRipple
                                          >
                                            <ArchiveIcon />
                                            Delete
                                          </MenuItem>
                                        </StyledMenu>
                                      </td>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : (
                        <>
                          <TableContainer
                            component={Paper}
                            sx={{
                              marginBottom: "20px",
                              paddingBottom: "12px",
                            }}
                          >
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell>Team</StyledTableCell>
                                  <StyledTableCell>Vendor</StyledTableCell>
                                  <StyledTableCell>Section</StyledTableCell>
                                  <StyledTableCell>Row</StyledTableCell>
                                  <StyledTableCell>Seat</StyledTableCell>
                                  <StyledTableCell>Marketplace</StyledTableCell>
                                  <StyledTableCell>External Ref</StyledTableCell>
                                  <StyledTableCell>Event</StyledTableCell>
                                  <StyledTableCell>Event Date</StyledTableCell>
                                  <StyledTableCell>
                                    Internal Notes
                                  </StyledTableCell>
                                  <StyledTableCell>Status</StyledTableCell>
                                  <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <StyledTableRow>
                                  <td
                                    colSpan={13}
                                    style={{ padding: "2.6rem 1rem" }}
                                  >
                                    <Typography variant="h6">
                                      No Records Found
                                    </Typography>
                                  </td>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <ContainerLoader />)
                }

                {totalCountries > 0 && (
                  <Pagination
                    totalRecords={totalCountries}
                    searchPage={this.state.searchPage}
                    SearchDisable={this.SearchDisable}
                    pageLimit={pageLimit}
                    onPageChanged={this.onPageChanged}
                  />
                )}
                <div
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <label>Items per page :</label>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 50,
                    }}
                    size="small"
                  >
                    <Select
                      defaultValue={pageLimit}
                      value={pageLimit}
                      name="limit"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "4px 14px",
                          fontFamily: "Barlow",
                        },
                      }}
                      onChange={(e) => this.changePageLimit(e)}
                    >
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="50">50</MenuItem>
                      <MenuItem value="100">100</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {/* Delete Modal  */}

                <Dialog
                  fullWidth="true"
                  scroll="body"
                  maxWidth="sm"
                  open={modalDeleteShow}
                  onClose={this.onDeletePopupHide}
                >
                  <DialogTitle>Delete</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onDeletePopupHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to Delete, this action can not be
                      revert ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.onDeletePopupHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.deleteInventory}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* Revoke Modal  */}
                <Dialog
                  fullWidth="true"
                  scroll="body"
                  maxWidth="sm"
                  open={this.state.modalConfirmShow}
                  onClose={this.onStatusChangePopupHide}
                >
                  <DialogTitle>Revoke </DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onStatusChangePopupHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to {this.state.confirmStatusLabel}
                      ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.onStatusChangePopupHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.statusChangeInventory}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      {this.state.confirmStatusLabel}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  scroll="body"
                  open={this.state.modalShow}
                  className="Modal_Box custom-pop-over"
                  onClose={this.onHide}
                >
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <div className="user-info">
                      <div style={{ color: "red", fontSize: "16px" }}>
                        {this.state.modelError}
                      </div>
                      <div
                        style={{
                          color: "green",
                          fontSize: "16px",
                        }}
                      >
                        {this.state.modelSuccess}
                      </div>

                      <ValidatorForm
                        // ref="form"
                        onSubmit={this.handleSubmitModal}
                      >
                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>Ticket: </label>
                            <TextValidator
                              variant="outlined"
                              onChange={this.handleModelChange}
                              name="ticketurl"
                              value={this.state.ticketurl}
                            />
                            {this.state.ticketurl && (
                              <a
                                href={this.state.ticketurl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                click to view ticket
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>Email</label>
                            <TextValidator
                              variant="outlined"
                              onChange={this.handleModelChange}
                              name="email"
                              value={this.state.email}
                            />
                          </div>
                        </div>

                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>Phone</label>
                            <TextValidator
                              variant="outlined"
                              onChange={this.handleModelChange}
                              name="phone"
                              value={this.state.phone}
                            />
                          </div>
                        </div>

                        <DialogActions>
                          <Button
                            variant="contained"
                            type="submit"
                            className="theme-btn"
                          >
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            type="button"
                            onClick={this.onHide}
                            className="theme-btn"
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </ValidatorForm>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog
                  scroll="body"
                  fullWidth={true}
                  maxWidth="md"
                  open={this.state.tmModel}
                  className="Modal_Box custom-pop-over"
                  onClose={this.tmModelonHide}
                >
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.tmModelonHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>

                    {this.state.twiliomessages.length > 0 ? (
                      <>
                        <Button
                          size="md"
                          className="theme-btn float-right"
                          sx={{
                            marginRight: 2,
                            marginTop: 2,
                            textTransform: "capitalize!important",
                            "&:hover": {
                              textTransform: "capitalize!important",
                            },
                          }}
                          onClick={() => this.downloadAsPdf()}
                        >
                          Download as PDF
                        </Button>
                        <br />
                        <br />
                        <div style={{ padding: "10px" }}>
                          <strong>Event: </strong>{" "}
                          {this.state.currentRow.event[0].name}
                          <br />
                          <strong>Event Date: </strong>{" "}
                          {customDateFormat(
                            this.state.currentRow.event[0].date
                          )}
                          <br />
                          <strong>Marketplace: </strong>{" "}
                          {this.state.currentRow.customerDisplayName} &nbsp;
                          &nbsp;
                          <strong>External Ref: </strong>{" "}
                          {this.state.currentRow.invoiceExternalRef}
                          <br />
                          <strong>Team: </strong>{" "}
                          {this.state.currentRow.event[0].name} &nbsp; &nbsp;
                          <strong>Date: </strong>{" "}
                          {this.state.currentRow.invoiceDate}
                          <br />
                          <strong>Section: </strong>{" "}
                          {this.state.currentRow.section} &nbsp; &nbsp;
                          <strong>Row: </strong> {this.state.currentRow.row}{" "}
                          &nbsp; &nbsp;
                          <strong>Seat: </strong>{" "}
                          {this.state.currentRow.seatNumbers}
                          <br />
                          {this.state.currentRow.customerName && (
                            <>
                              <strong>Customer Name: </strong>
                              {this.state.currentRow.customerName}
                              <br />
                            </>
                          )}
                          <strong>Phone Number: </strong>{" "}
                          {this.state.twiliomessages[0].sms_type ===
                            "received"
                            ? this.state.twiliomessages[0].sentby
                            : this.state.twiliomessages[0].phone_number}
                        </div>
                        <CustomTable>
                          <thead>
                            <tr>
                              <th>Status</th>
                              {/*<th>Phone Number</th>*/}
                              <th>Message</th>
                              <th>Sent Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.twiliomessages
                              .sort(
                                (a, b) =>
                                  new Date(b.sentat) - new Date(a.sentat)
                              )
                              .map((tm) => (
                                <tr>
                                  <td
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {tm.sms_type ? tm.sms_type : "delivered"}
                                  </td>
                                  {/*<td>{tm.sms_type === "received" ? tm.sentby : tm.phone_number}</td>*/}
                                  <td style={{ width: "50%" }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: tm.message_body.replace(
                                          "\n",
                                          "<br />"
                                        ),
                                      }}
                                    />
                                  </td>

                                  <td>
                                    {customDateFormatLocal(
                                      tm.sentat,
                                      this.state.timezone
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </CustomTable>
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          padding: "20px 5px",
                        }}
                      >
                        No message yet!
                      </p>
                    )}
                  </DialogContent>
                </Dialog>

                <Dialog
                  scroll="body"
                  open={this.state.tm1Model}
                  className="Modal_Box custom-pop-over"
                  onClose={this.tm1ModelonHide}
                >
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.tm1ModelonHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>

                    {this.state.loadLogs ? (
                      <div
                        style={{
                          height: "12rem",
                          width: "35rem",
                        }}
                      >
                        <PageLoader />
                      </div>
                    ) : (
                      <>
                        {this.state.invLogs.length > 0 ? (
                          <>
                            <div style={{ padding: "10px" }}>
                              <strong>Event: </strong>{" "}
                              {this.state.currentRow?.event[0].name}
                              <br />
                              <strong>Event Date: </strong>{" "}
                              {customDateFormat(
                                this.state.currentRow?.event[0].date
                              )}
                              <br />
                              <strong>Marketplace: </strong>{" "}
                              {this.state.currentRow?.customerDisplayName}{" "}
                              &nbsp; &nbsp;
                              <strong>External Ref: </strong>{" "}
                              {this.state.currentRow?.invoiceExternalRef}
                              <br />
                              <strong>Team: </strong>{" "}
                              {this.state.currentRow?.event[0]?.name} &nbsp;
                              &nbsp;
                              <strong>Date: </strong>{" "}
                              {this.state.currentRow?.invoiceDate}
                              <br />
                              <strong>Section: </strong>{" "}
                              {this.state.currentRow?.section} &nbsp; &nbsp;
                              <strong>Row: </strong>{" "}
                              {this.state.currentRow?.row} &nbsp; &nbsp;
                              <strong>Seat: </strong>{" "}
                              {this.state.currentRow?.seatNumbers}
                            </div>

                            <CustomTable>
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.invLogs.map((invlog) => (
                                  <tr>
                                    <td
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <div className="icon-space-left">
                                        {getStatus(invlog.status)}
                                        <br />
                                      </div>
                                    </td>
                                    <td>
                                      {customDateFormatLocal(
                                        invlog.created,
                                        this.state.timezone
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </CustomTable>
                          </>
                        ) : (
                          <p>No log yet!</p>
                        )}
                      </>
                    )}
                  </DialogContent>
                </Dialog>

                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.modalShow1}
                  className="Modal_Box custom-pop-over"
                  onHide={this.onHide1}
                >
                  <Modal.Body>
                    <ValidatorForm onSubmit={this.handleSubmitTicket}>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">
                              Customer Name
                            </label>
                            <TextField
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="customerName"
                              placeholder="Customer Name"
                              value={this.state.formData.customerName}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">
                              Customer Email
                            </label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="customerEmail"
                              placeholder="Customer Email"
                              value={this.state.formData.customerEmail}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Ticket URL</label>
                            <TextField
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="ticketURL"
                              placeholder="Ticket URL"
                              value={this.state.formData.ticketURL}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Tickets</label>
                            <TextField
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="tickets"
                              placeholder="Tickets"
                              value={this.state.formData.tickets}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper ">
                            <label className="Ticket_info">Date</label>
                            <DatePicker
                              className="Ticket_input_wrap"
                              selectsRange
                              selected={this.state.startDate}
                              placeholderText="Select Date"
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onChange={(dates) => this.setStartDate(dates)}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Team</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="team"
                              placeholder="Team"
                              value={this.state.formData.team}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="4" sm="12" lg="4" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Section</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="section"
                              placeholder="Section"
                              value={this.state.formData.section}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="4" sm="12" lg="4" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Row</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="rows"
                              placeholder="Row"
                              value={this.state.formData.rows}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="4" sm="12" lg="4" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Seat</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="seat"
                              placeholder="Seat"
                              value={this.state.formData.seat}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper Ticket_input ">
                            <label className="Ticket_info">Marketplace</label>
                            <Autocomplete
                              className="input_size"
                              onChange={(evt, val) =>
                                this.handleModelChangeSelect(
                                  evt,
                                  val,
                                  "customerDisplayName"
                                )
                              }
                              fullWidth
                              name="marketplace"
                              options={marketplace}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextValidator
                                  value={this.state.formData.marketplace}
                                  placeholder="Select Marketplace"
                                  {...params}
                                  variant="outlined"
                                />
                              )}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">
                              External Ref
                            </label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="invoiceExternalRef"
                              placeholder="External Ref"
                              value={this.state.formData.invoiceExternalRef}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      <CRow className="interactive-filter-row search-row">
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Event</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="eventName"
                              placeholder="Event"
                              value={this.state.formData.eventName}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                        <CCol md="6" sm="12" lg="6" className="search_box">
                          <div className="form-group login-wrapper Ticket_input">
                            <label className="Ticket_info">Event Date</label>
                            <DatePicker
                              selectsRange
                              selected={this.state.startDate1}
                              placeholderText="Select Date"
                              startDate={this.state.startDate1}
                              endDate={this.state.endDate1}
                              onChange={(dates) => this.setStartDate1(dates)}
                              validators={["required"]}
                              errorMessages={["this field is required"]}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      {/* <CRow className="interactive-filter-row search-row">
                                                                            <CCol md="12" sm="12" lg="12" className="search_box">
                                                                                <div className="form-group login-wrapper">
                                                                                    <label>Ticket URL</label>
                                                                                    <TextField
                                                                                        variant="outlined"
                                                                                        onChange={this.handleChange}
                                                                                        name="ticketURL"
                                                                                        placeholder="Ticket URL"
                                                                                        value={this.state.formData.ticketURL}
                                                                                    />
                                                                                </div>
                                                                            </CCol>
                                                                        </CRow> */}
                      {/* <CRow className="interactive-filter-row search-row">
                                                                            <CCol md="12" sm="12" lg="12" className="search_box">
                                                                                <div className="form-group login-wrapper">
                                                                                    <label>Customer Name</label>
                                                                                    <TextField
                                                                                        variant="outlined"
                                                                                        onChange={this.handleChange}
                                                                                        name="customerName"
                                                                                        placeholder="Customer Name"
                                                                                        value={this.state.formData.customerName}
                                                                                    />
                                                                                </div>
                                                                            </CCol>
                                                                        </CRow> */}

                      <CRow className="interactive-filter-row search-row">
                        <CCol md="12" sm="12" lg="12" className="search_box">
                          <div className="form-group login-wrapper">
                            <label className="Ticket_info">Phone</label>
                            <TextValidator
                              className="Ticket_input"
                              variant="outlined"
                              onChange={this.handleChange}
                              name="customerPhone"
                              placeholder="Customer Phone"
                              value={this.state.formData.customerPhone}
                            />
                          </div>
                        </CCol>
                      </CRow>
                      {/* <CRow className="interactive-filter-row search-row">
                                                                        <CCol md="12" sm="12" lg="12" className="search_box">
                                                                                <div className="form-group login-wrapper">
                                                                                    <label>Customer Email</label>
                                                                                    <TextValidator
                                                                                        variant="outlined"
                                                                                        onChange={this.handleChange}
                                                                                        name="customerEmail"
                                                                                        placeholder="Customer Email"
                                                                                        value={this.state.formData.customerEmail}
                                                                                    />
                                                                                </div>
                                                                            </CCol>
                                                                        </CRow> */}
                      <CCol
                        md="6"
                        sm="12"
                        lg="6"
                        className="search_box"
                        style={{ margin: "0px auto" }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          className="full-width login-btn btn-style"
                        >
                          Submit
                        </Button>
                      </CCol>
                    </ValidatorForm>
                  </Modal.Body>
                </Modal>
                <Dialog
                  fullWidth="true"
                  scroll="body"
                  maxWidth="sm"
                  open={this.state.sendMessageModal}
                  onClose={this.sendMessageModalHide}
                >
                  <DialogTitle>Send Message</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.sendMessageModalHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>

                    <ValidatorForm onSubmit={this.sendMessageSubmit}>
                      <div className="custom-pop-over-row">
                        <div className="form-group login-wrapper">
                          <label>Customer Phone</label>
                          <TextValidator
                            variant="outlined"
                            name="customerphone"
                            value={sendmessagedate.customerphone}
                            onChange={(e) => this.handleChangeSendmessage(e)}
                            validators={["required"]}
                            errorMessages={["Customer Phone  is required"]}
                          />
                        </div>
                      </div>

                      <div className="custom-pop-over-row">
                        <div className="form-group login-wrapper">
                          <label>Choose Template</label>
                          <FormControl sx={{}} size="large">
                            <Select
                              name="template"
                              defaultValue="sold"
                              sx={{
                                "& .MuiSelect-select": {
                                  padding: "14px 14px",
                                  fontFamily: "Barlow",
                                },
                              }}
                              onChange={(e) =>
                                this.handleChangeSendmessage(e)
                              }
                            >
                              <MenuItem value="sold">
                                Sold Message Template
                              </MenuItem>
                              <MenuItem value="delivery">
                                Delivery Message Templete
                              </MenuItem>
                              <MenuItem value="confirmation">
                                Confirmation Message Templete
                              </MenuItem>
                              <MenuItem value="custom">
                                Custom Message
                              </MenuItem>
                              <MenuItem value="event_reminder">
                                Event Reminder
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {sendmessagedate.template === "custom" && (
                        <div className="custom-pop-over-row">
                          <div className="form-group login-wrapper">
                            <label>Message</label>
                            <TextValidator
                              variant="outlined"
                              name="message"
                              value={sendmessagedate.message}
                              onChange={(e) =>
                                this.handleChangeSendmessage(e)
                              }
                              validators={["required"]}
                              errorMessages={["Message field is required"]}
                            />
                          </div>
                        </div>
                      )}

                      <DialogActions>
                        {sendmessagedate.submit ? (
                          <Button
                            variant="contained"
                            type="submit"
                            disabled="disabled"
                            className="theme-btn"
                          >
                            Send
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            type="submit"
                            className="theme-btn"
                          >
                            Send
                          </Button>
                        )}
                        <Button
                          type="button"
                          onClick={this.sendMessageModalHide}
                          className="theme-btn"
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </ValidatorForm>
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <AlertMessage
          open={this.state.open}
          message={this.state.message}
          severity={this.state.successError}
          closeCall={this.handleClose}
        />
      </>
    );
  }
}
export default Soldinventlist;
