import {
  Box,
  Grid,
  AppBar,
  Container,
  Button,
  InputAdornment,
  IconButton,
  styled,
  Paper,
  CircularProgress,

  Chip,
  CardContent,
  CardHeader,
  Card,
} from "@mui/material";
import React, { Component } from "react";
import Logo from "images/ticket_liberty_logo.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Helmet } from "react-helmet";
import customDateFormat from "utils/customDateFormat";
import SearchIcon from "@mui/icons-material/Search";
import { indigo } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import "./historyofTickets.scss";
import axios from "axios";
import AlertMessage from "containers/alert/alert";
import { Link } from "react-router-dom";

const Footer = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  boxShadow: "0px -2px 10px #00000029",
  position: "relative",
  bottom: 0,
  width: "100%",
}));

const MainLayout = styled("div")(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  color: theme.palette.common.white,
  fontFamily: "Barlow !important",
  height: "100%",
  minHeight: "100vh",
  paddingBottom: "50px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    padding: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
export default class historyofTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      link: "",
      messageopen: false,
      message: "",
      messagetype: "success",
      splitdone: false,
      copiedIndex: null,
      copiedUrl: false,
      parentIndex: null,
      findLink: false,
      allHistory: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
  }
  handleClosemessage() {
    this.setState({
      messageopen: false,
      message: "",
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  // __________________get The history of the splitted  Link ____________________
  handleSubmit() {
    this.setState({
      loader: true,
      parentIndex: null,
      copiedIndex: null,
      copiedUrl: false,
    });

    const { link } = this.state;
    const data = {
      link,
    };

    const getLinkDetails =
      process.env.REACT_APP_API_URL + "/api/getLinkDetails";

    axios
      .post(getLinkDetails, data)
      .then((res) => {
        const { data } = res;
        if (data.error) {
          this.setState({
            messageopen: true,
            message: data.message,
            messagetype: "error",
            loader: false,
          });
        } else if (data.success) {
          console.log(data, "data Success");
          this.setState({
            ticketsAvailable: data.success,
            messageopen: true,
            message: data.message,
            messagetype: "success",
            findLink: true,
            loader: false,
            allHistory: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
      });
  }

  copyUrl = (url, index2, index) => {
    navigator.clipboard.writeText(url);
    this.setState({ copiedUrl: true, copiedIndex: index2, parentIndex: index });
  };

  componentDidMount() {
    ValidatorForm.addValidationRule("mtchRegexp", (value) => {
      // regex to check url is valid or not
      const regex = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator

        "i" // fragment locator
      );
      if (regex.test(value)) {
        return true;
      }
      return false;
    });
  }
  render() {
    return (
      <>
        <MainLayout>
          <Helmet>
            <title>Verified Tickets</title>
          </Helmet>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      maxWidth: "fit-content",
                      margin: "0px auto",
                    }}
                  >
                    <div className="wrapper">
                      <div className="header_logo">
                        <Link to="/">
                        <img src={Logo} alt="logo" className="m-image" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </AppBar>

          <Grid
            container
            spacing={2}
            sx={{
              padding: "20px",
            }}
          >
            <Grid item xs={12}>
              <ValidatorForm
                onSubmit={this.handleSubmit}
                className="filter-form mb-5"
                style={{
                  width: "100%",
                  maxWidth: "700px",
                  margin: "0px auto",
                  marginTop: "50px",
                  padding: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      placeholder="Paste url to see history"
                      variant="outlined"
                      fullWidth
                      name="link"
                      value={this.state.link}
                      onChange={this.handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                color: indigo[900],
                              }}
                              aria-label="submit"
                              type="submit"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      validators={["required", "mtchRegexp:^[a-zA-Z0-9]+$"]}
                      errorMessages={["Url is required", "Url is not valid"]}
                    />
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>

            {this.state.loader ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5rem 0",
                  }}
                >
                  <CircularProgress sx={{ color: "#002986" }} />
                </Box>
              </Grid>
            ) : (
              <>
                {/* ----------All History of Links will be listed here---------- */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                      {this.state.allHistory[0] && (
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "#002986",
                            textAlign: "left",
                          }}
                        >
                          Original Link &nbsp;
                          {this.state.allHistory[0].original_link.url}
                        </Typography>
                    </Grid>
                      )}
                    {this.state.allHistory.map((item, index) => {
                      return (
                        <Grid item xs={12} sm={12} md={10} lg={10} key={index}>
                          <Card
                            key={index}
                            sx={{
                              marginBottom: "10px",
                            }}
                          >
                            <CardHeader
                              sx={{
                                backgroundColor: indigo[900],
                                color: "#fff",
                                fontWeight: "bold",
                                textAlign: "left",
                                fontFamily: "Barlow, sans-serif!important",
                                span: {
                                  fontSize: "1rem",
                                  fontFamily: "Barlow, sans-serif!important",
                                  fontWeight: "bold",
                                },
                              }}
                              action={
                                item.inventory.delivery_status === 11 ||
                                item.inventory.delivery_status === 12 ? (
                                  <Chip
                                    label="Revoked"
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                    color="error"
                                  />
                                ) : (
                                  ""
                                )
                              }
                              title={`Split Link  (${item.original_link.ticketIds.length})`}
                            />
                            <CardContent>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <StyledTableRow>
                                      <StyledTableCell align="right">
                                        Ticket Url
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        No of Tickets
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                       Time
                                      </StyledTableCell>

                                      {item.name !== "" && (
                                        <StyledTableCell align="right">
                                          Name
                                        </StyledTableCell>
                                      )}
                                      {item.email !== "" && (
                                        <StyledTableCell align="right">
                                          Email
                                        </StyledTableCell>
                                      )}
                                      {item.phone !== "" && (
                                        <StyledTableCell align="right">
                                          Phone
                                        </StyledTableCell>
                                      )}
                                      <StyledTableCell align="left">
                                        Copy Url
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item.split_links.map((row, index2) => (
                                      <StyledTableRow key={index2}>
                                        <StyledTableCell>
                                          {row.url}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {row.ticketIds.length}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {customDateFormat(item.createdAt)}
                                        </StyledTableCell>
                                        {item.name !== "" && (
                                          <StyledTableCell align="right">
                                            {item.name}
                                          </StyledTableCell>
                                        )}
                                        {item.email !== "" && (
                                          <StyledTableCell align="right">
                                            {item.email}
                                          </StyledTableCell>
                                        )}
                                        {item.phone !== "" && (
                                          <StyledTableCell align="right">
                                            {item.phone}
                                          </StyledTableCell>
                                        )}
                                        <StyledTableCell align="right">
                                          {this.state.copiedUrl &&
                                          this.state.copiedIndex === index2 &&
                                          this.state.parentIndex === index ? (
                                            <Button
                                              variant="contained"
                                              sx={{
                                                backgroundColor:
                                                  "#919191C2!important",
                                                color: "white!important",
                                                textTransform: "none",
                                                fontWeight: "bold!important",
                                                padding: "6px 8px",
                                                fontSize: "14px!important",
                                                ".css-y6rp3m-MuiButton-startIcon":
                                                  {
                                                    marginRight: "3px",
                                                    marginLeft: "3px",
                                                  },
                                                ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                                  {
                                                    fontSize: "20px",
                                                  },
                                              }}
                                              size="small"
                                              startIcon={
                                                <LibraryAddCheckIcon />
                                              }
                                            >
                                              Copied
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              sx={{
                                                backgroundColor:
                                                  "#011C58!important",
                                                color: "white!important",
                                                textTransform: "none",
                                                fontWeight: "bold!important",
                                              }}
                                              onClick={() =>
                                                this.copyUrl(
                                                  row.url,
                                                  index2,
                                                  index
                                                )
                                              }
                                              startIcon={<ContentCopyIcon />}
                                            >
                                              Copy
                                            </Button>
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>

          <AlertMessage
            open={this.state.messageopen}
            message={this.state.message}
            severity={this.state.messagetype}
            closeCall={this.handleClosemessage}
          />
        </MainLayout>
        <Footer>
          <Typography
            sx={{
              fontSize: "20px",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Contact Us:{" "}
            <a
              href="mailto:sales@ticketliberty.com"
              style={{ color: "#474747", textDecoration: "none" }}
            >
              sales@ticketliberty.com
            </a>
          </Typography>
        </Footer>
      </>
    );
  }
}
