export default function dateTimeFormat(datestring) {
    if (!datestring) {
        return "";
    }
    if (datestring === "") {
        return "";
    }
   
        var correctDatestring = "";
        // checking if datestring has Coordinated Universal Time or not
        if (datestring) {

        correctDatestring = datestring.replace("||", "T");
        correctDatestring = correctDatestring.replace("Z", "");
        correctDatestring = correctDatestring.replace("T", " ");
        correctDatestring = correctDatestring.replace(" OFFSALE", "");
        correctDatestring = correctDatestring.replace(" CANCELED", "");
        correctDatestring = correctDatestring.replace(" ONSALE", "");
        correctDatestring = correctDatestring.replace(" RESCHEDULED", "");
        }
    
      
    
        return correctDatestring;
    
    }