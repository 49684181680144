const getStatus = (status) => {
    switch (status) {
      case 0:
        return "No message sent";
      case 1:
        return "Sold message sent";
      case 2:
        return "Delivery message sent";
      case 3:
        return "Confirmation message sent";
      case 4:
        return "Order Confirmed";
      case 5:
        return "Problem in Order";
      case 6:
        return "Order Confirmed";
      case 7:
        return "Need Manual Review";
      case 8:
        return "Phone number Blacklisted";
      case 9:
        return "Phone number Invalid";
      case 10:
        return "No ticket found";
      case 11:
        return "Manually Transferred";
      case 12:
        return "Delivery Revoked";
      case 13:
        return "Ticket Uploaded";
      case 14:
        return "Link Generated";
      case 15:
        return "Event Reminder";
      case 16:
        return "Ticket Not Uploaded";
      case 17:  
        return "Account Deleted";
      default:
        return "";
    }
  };

  export default getStatus;