import React, { Component } from "react";
import { Helmet } from "react-helmet";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertMessage from "components/message/message";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";
import axios from "axios";
export default class editFont extends Component {
  constructor(props) {
    super(props);
    this.state = {
      osList: [],
      fonts: [
        {
          name: "",
        },
      ],
      loader: true,
      os: "",
      open: false,
      message: "",
      severity: "",
      singleFont: [],
    };
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeFont = (event, index) => {
    const { name, value } = event.target;
    const { fonts } = this.state;
    fonts[index][name] = value;
    this.setState({ fonts });
  };

  componentDidMount() {
    this.getOsList();
    this.getAllFonts();
  }

  getOsList = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/browser/getBrowserOS";

    axios
      .get(apiurl)
      .then((response) => {
        const data = response.data;

        if (!data.error) {
          this.setState({ osList: data.data });
        }

        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };

  handleAddFont = () => {
    this.setState({
      fonts: this.state.fonts.concat([{ name: "" }]),
    });
  };

  handleRemoveFont = (index) => {
    const { fonts } = this.state;
    fonts.splice(index, 1);
    this.setState({ fonts });
  };

  handleSubmit = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/admin/updateOSFonts";
    const { fonts, os } = this.state;

    const data = {
      _id: this.props.match.params.id,
      os: os,
      fonts: fonts.map((font) => font.name),
    };
    axios
      .post(apiurl, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const data = response.data;
        this.setState({ loader: false });
        if (!data.error) {
          this.setState({
            open: true,
            message: data.message,
            severity: "success",
          });
          setTimeout(() => {
            this.props.history.push("/font-management");
          }, 2000);
        } else {
          this.setState({
            open: true,
            message: data.message,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };
  getAllFonts = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/browser/getOSFonts";
    axios
      .get(apiurl)
      .then((response) => {
        const data = response.data;
        this.setState({ loader: false });
        if (!data.error) {
          const singledata = data.data.filter(
            (item) => item._id === this.props.match.params.id
          );
          this.setState({});
          this.setState({
            singleFont: singledata,
            os: singledata[0].os._id,
            fonts: singledata[0].fonts.map((item) => {
              return { name: item };
            }),
          });

          console.log(singledata, "singledata");
        } else {
          this.setState({
            open: true,
            message: data.message,
            severity: "error",
          });
        }
        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };

  render() {
    if (!this.state.loader) {
      return (
        <>
          <Helmet>
            <title>Add Font</title>
          </Helmet>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Edit Font" />
                <Divider />
                <CardContent className="plan-tbl">
                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                    onError={(errors) => console.log(errors)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontFamily: "Barlow",
                            textAlign: "left",
                            paddingBottom: "1rem",
                          }}
                        >
                          Operating System
                        </Typography>
                        <SelectValidator
                          fullWidth
                          onChange={this.handleChange}
                          name="os"
                          type="text"
                          value={this.state.os}
                          placeholder="Operating System"
                          sx={{
                            fontFamily: "Barlow",
                            textAlign: "left",
                          }}
                          validators={["required"]}
                          errorMessages={["Operating System is required"]}
                        >
                          {this.state.osList.map((os, index) => (
                            <MenuItem value={os._id}>{os.name}</MenuItem>
                          ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.fonts.map((font, index) => (
                          <Grid container spacing={2}>
                            <Grid item xs={11}>
                              <Typography
                                sx={{
                                  fontFamily: "Barlow",
                                  textAlign: "left",
                                  paddingBottom: "1rem",
                                  paddingTop: "1rem",
                                }}
                              >
                                Font Name
                              </Typography>
                              <TextValidator
                                fullWidth
                                placeholder="Font Name"
                                onChange={(event) =>
                                  this.handleChangeFont(event, index)
                                }
                                name="name"
                                type="text"
                                value={font.name}
                                validators={["required"]}
                                errorMessages={["Font Name is required"]}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                disabled={this.state.fonts.length === 1}
                                onClick={() => this.handleRemoveFont(index)}
                                sx={{
                                  color: "#002986",
                                  marginTop: "3.5rem",
                                  marginRight: "1rem",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          onClick={this.handleAddFont}
                          sx={{
                            color: "#002986",
                            float: "left",
                            trasition: "all 0.3s ease-in-out",
                            "&:hover": {
                              color: "#B90F0F",
                              borderColor: "#B90F0F",
                            },
                          }}
                        >
                          Add More Font
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{
                          margin: "0 auto",
                        }}
                      >
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{
                            fontFamily: "Barlow",
                            fontSize: "1rem",
                            textAlign: "left",
                            marginTop: "1.4rem",
                            marginBottom: "1rem",
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </CardContent>
              </Card>
            </Grid>
            <AlertMessage
              open={this.state.open}
              message={this.state.message}
              severity={this.state.severity}
              closeCall={this.handleClose}
            />
          </Grid>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
