import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useRef } from "react";
import moment from "moment";
import DataTable from "components/DataTable/DataTable";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { HiOutlineSearch } from "react-icons/hi";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import AlertMessage from "components/message/message";
import Pagination from "components/pagination/pagination";

const ManageLogs = () => {
  const [loader, setLoader] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    status: "",
    user: "",
    limit: 10,
    vendor: "",
  });
  const [accountLogs, setAccountLogs] = React.useState([]);

  const [userlists, setUserlists] = React.useState([]);
  const [userValue, setUserValue] = React.useState(null);
  const [vendorList, setVendorList] = React.useState([]);
  const [vendorValue, setVendorValue] = React.useState(null);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [searchPage, setSearchPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);

  const changePageLimit = (e) => {
    setSearchPage(1);
    setPageLimit(parseInt(e.target.value));
    getLogs();
    setSearchPage(1);
  };
  // Initialize the ref outside the component
  const prevFilterDataRef = useRef();
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setLoader(true);
      if (
        prevFilterDataRef.current &&
        prevFilterDataRef.current === filterData
      ) {
        // Filter data has not changed, skip API call
        setLoader(false);
        return;
      }

      // Update the previous filterData value
      prevFilterDataRef.current = filterData;

      const logsApiUrl = `${process.env.REACT_APP_API_URL}/api/admin/getAllAccountLog`;

      axios
        .post(
          logsApiUrl,
          {
            user: filterData.user,
            vendor: filterData.vendor,
            status: filterData.status,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "accessTokenAdmin"
              )}`,
            },
          }
        )
        .then((response) => {
          const { data } = response;
          setLoader(false);

          if (data.error) {
            setMessage(data.error);
            setAlertColor("error");
            setOpen(true);
          } else {
            setAccountLogs(
              data.data.map((item) => ({
                ...item,
                user: item.userId.email,
                vendor: item.accountId.username,
                team: item.accountId.team,
              }))
            );
            setTotalRecords(data.totalRecords);
          }
        });
    },
    [filterData]
  );
  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [message, setMessage] = React.useState("");
  const [alertColor, setAlertColor] = React.useState("");
  const [open, setOpen] = React.useState(false);

  // Function to close the alert message
  const handleClose = () => {
    setOpen(false);
  };

  // disable Search

  const SearchDisable = (value) => {
    setSearchPage(value);
  };

  const getLogs = (pageskip, limitpage) => {
    setLoader(true);
    const logsApiUrl = `${process.env.REACT_APP_API_URL}/api/admin/getAllAccountLog`;

    axios
      .post(
        logsApiUrl,
        {
          user: filterData.user,
          vendor: filterData.vendor,
          status: filterData.status,
          limit: limitpage,
          skip: pageskip,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessTokenAdmin")}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setLoader(false);

        if (data.error) {
          setMessage(data.message);
          setAlertColor("error");
          setOpen(true);
        } else {
          setAccountLogs(
            data.data.map((item) => ({
              ...item,
              user: item.userId.email,
              vendor: item.accountId.username,
              team: item.accountId.team,
            }))
          );
          setTotalRecords(data.totalrecords);
        }
      });
  };

  const onPageChanged = (data) => {
    const { currentPage } = data;
    setLoader(true);
    setSkip((currentPage - 1) * pageLimit);
    getLogs((currentPage - 1) * pageLimit, pageLimit);
  };

  // handleSelect Vendor

  const handleSelectVendor = (e, newVal) => {
    if (newVal) {
      setFilterData({ ...filterData, vendor: newVal.id });
      setVendorValue(newVal);
    } else {
      setFilterData({ ...filterData, vendor: null });
      setVendorValue(null);
    }
  };

  // DataTable fields configuration
  const fields = [
    {
      key: "user",
      label: "User",
    },
    {
      key: "vendor",
      label: "Vendor",
    },
    {
      key: "team",
      label: "Team",
    },

    {
      key: "status",
      label: "Status",
    },
    {
      key: "date",
      label: "Date/Time",
    },
  ];
  const handleChangeUser = (e, newVal) => {
    if (newVal) {
      setFilterData({ ...filterData, user: newVal._id });
      setUserValue(newVal);
    } else {
      setFilterData({ ...filterData, user: null });
      setUserValue(null);
    }
  };

  useEffect(() => {
    setLoader(true);
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response.data;

        if (data) {
          setUserlists(data);
        }
      })
      .catch((error) => {
        setMessage("Something went wrong while accessing users list");
        setAlertColor("error");
        setOpen(true);
        console.log(error);
      });

    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/account/listAllAccounts";
    axios
      .get(
        vendorList_api_url,

        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          setMessage(data.message);
          setAlertColor("error");
          setOpen(true);
        } else {
          const newdata = data.data
            .map((val) => {
              return {
                label: val.username,
                id: val._id,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          // const seen = new Set();
          // const filteredArr = newdata.filter((el) => {
          //   const duplicate = seen.has(el.label);
          //   seen.add(el.label);
          //   return !duplicate;
          // });
          setVendorList(newdata);
        }
      })
      .catch((error) => {
        setMessage("Something went wrong while accessing vendors list");
        setAlertColor("error");
        setOpen(true);
      });

    const logsApiUrl = `${process.env.REACT_APP_API_URL}/api/admin/getAllAccountLog`;

    axios
      .post(
        logsApiUrl,
        {
          limit: 10,
          skip: skip,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessTokenAdmin")}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setLoader(false);

        if (data.error) {
          setMessage(data.error);
          setAlertColor("error");
          setOpen(true);
        } else {
          const logs = data.data.map((item) => ({
            ...item,
            user: item.userId.email,
            vendor: item.accountId.username,
            team: item.accountId.team,
          }));

          setAccountLogs(logs);
          setTotalRecords(data.totalrecords);
        }
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Manage Logs</title>
      </Helmet>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card
              className="w-100"
              sx={{
                margin: "0px 0px 16px 0px",
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{
                      padding: "10px 0px 0px 17px!important",
                      fontSize: "24px!important",
                      fontWeight: "700!important",
                      color: "#002986!important",
                    }}
                  >
                    Manage Logs
                  </Typography>
                }
                className="card-header" />
              <CardContent
                className="plan-tbl"
                sx={{
                  padding: "1.2rem",
                }}
              >
                {!loader ? (
                  <>
                    <ValidatorForm
                      onSubmit={handleSubmit}
                      className="filter-form"
                    >
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={3}
                          sx={{ textAlign: "left" }}
                        >
                          <Autocomplete
                            onChange={handleChangeUser}
                            fullWidth
                            id="combo-box-demo"
                            value={userValue}
                            name="user"
                            options={userlists}
                            getOptionLabel={(option) => option.email}
                            renderInput={(params) => (
                              <TextValidator
                                value={filterData.user}
                                {...params}
                                variant="outlined"
                                placeholder="User"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Autocomplete
                            fullWidth
                            className="filter-input"
                            name="filterstatusVal"
                            value={vendorValue}
                            options={vendorList}
                            onChange={handleSelectVendor}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                value={vendorValue}
                                placeholder="Vendor"
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          lg={3}
                          sx={{ textAlign: "left" }}
                        >
                          <TextField
                            variant="outlined"
                            onChange={handleChange}
                            name="status"
                            value={filterData.status}
                            placeholder="Status"
                            className="filter-input"
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={1.4}
                          lg={1.5}
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                            // padding: "10px!important",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            className="filter-btn"
                            startIcon={<HiOutlineSearch />}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>

                    <DataTable
                      fields={fields}
                      items={accountLogs}
                      itemsPerPage={accountLogs.length}
                      pagination={false}
                      scopedSlots={{
                        status: (item) => <>{item.status}</>,
                        date: (item) => {
                          const date = new Date(item.createdAt);
                          const timeZone =
                            Intl.DateTimeFormat().resolvedOptions().timeZone;
                          return (
                            <>
                              {moment(date)
                                .tz(timeZone)
                                .format("MM/DD/YYYY hh:mm A")}
                            </>
                          );
                        },
                      }}
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "16rem 0",
                    }}
                  >
                    <CircularProgress sx={{ color: "#002986" }} />
                  </Box>
                )}

                <AlertMessage
                  key="alertbtn"
                  open={open}
                  message={message}
                  severity={alertColor}
                  closeCall={handleClose}
                />
              </CardContent>
              <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ paddingLeft: "20px" }}>
                    {totalRecords > 0 && (
                      <Pagination
                        key="paginationnew"
                        searchPage={searchPage}
                        SearchDisable={SearchDisable}
                        totalRecords={totalRecords}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box sx={{ paddingRight: "20px" }}>
                    <div
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <label>Items per page :</label>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 50,
                        }}
                        size="small"
                      >
                        <Select
                          defaultValue={pageLimit}
                          value={pageLimit}
                          name="limit"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "4px 34px 4px 14px!important",
                              fontFamily: "Barlow",
                            },
                          }}
                          onChange={(e) => changePageLimit(e)}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ManageLogs;
