import axios from 'axios';

const instance = axios.create();

instance.interceptors.request.use(
    (req) => {
       // Add configurations here
       return req;
    },
    (err) => {
       return Promise.reject(err);
    }
);
 
instance.interceptors.response.use(
    (res) => {
       // Add configurations here
       if (res.status === 201) {
         if(res.data.tokenExpired) {
            window.location.href = "/logout"
         }else {
            console.log('Posted Successfully');
         }
       }
       return res;
    },
    (err) => {
       return Promise.reject(err);
    }
);

export default instance