import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import "pages/userdashboard/userdashboard.scss";
import AlertMessage from "components/message/message";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import Autocomplete from "@mui/material/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PasswordField from "components/passwordfield/passwordfield";
import PageLoader from "components/PageLoader/PageLoader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";

class EditMarketPos extends React.Component {
  state = {
    open: false,
    message: "",
    successError: "",
    vendorType: "",
    email: "",
    password: "",
    accountTypeVal: "",
    vendorTypeVal: [],
    marketTypeVal: [],
    apitoken: "",
    skybox_date: null,
    skybox_internal_notes: "scanall",
    error: [],
    internalNoteValue: null,
    accountType: [{ title: "POS" }, { title: "Marketplace" }],
    posType: [
      {
        title: "Skybox",
      },
      {
        title: "TV",
      },
      {
        title: "TA",
      }
    ],
    marketplace: [
      {
        title: "VividSeats",
      },
      {
        title: "TickPick",
      },
      {
        title: "SeatGeek",
      },
      {
        title: "Gametime",
      },
      {
        title: "Manual/None",
      },
      {
        title: "Other",
      },
    ],
    internalnotes: [],
    showInternalNotes: false,
  };

  async componentDidMount() {
    this.setState({
      loader: true,
    });

    const data = {
      id: this.props.match.params.id,
    };

    const vendorEdit_api_url =
      process.env.REACT_APP_API_URL + "/api/account/detailAccount";
    await axios
      .post(vendorEdit_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          if (
            data.data.internalNote !== null &&
            data.data.internalNote !== ""
          ) {
            this.setState({
              internalNoteValue: { name: data.data.internalNote },
            });
          }

          this.setState({
            loader: false,
            open: false,
            message: "",
            successError: "success",
            email: data.data.username,
            accountTypeVal: data.data.account_type,
            apitoken: data.data.apitoken,
            skybox_date: data.data.skybox_date
              ? new Date(data.data.skybox_date)
              : null,
            skybox_internal_notes: data.data.skybox_internal_notes
              ? data.data.skybox_internal_notes
              : "scanall",
          });
          this.state.posType.map((data) => {
            if (data.title === response.data.data.pos) {
              this.setState({
                vendorTypeVal: data,
              });
            }

            return null;
          });

          this.state.marketplace.map((data) => {
            if (data.title === response.data.data.marketplace) {
              this.setState({
                marketTypeVal: data,
              });
            }
            return null;
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });

    const listinternalnoted_api =
      process.env.REACT_APP_API_URL + "/api/getAllInternalnotes";
    axios
      .get(listinternalnoted_api, {
        headers: {
          authorization: "Bearer " +Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (!data.error) {
          this.setState({ internalnotes: data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChangeNotes = (e, newVal) => {
    console.log(newVal);

    this.setState({ internalNoteValue: newVal });
  };
  handleChangeAccount = (e) => {
    this.setState({
      accountTypeVal: e.target.innerText,
    });
  };

  handleChangePOS = (e, newVal) => {
    this.setState({
      vendorTypeVal: newVal,
    });
  };
  handleChangeMarket = (e, newVal) => {
    this.setState({
      marketTypeVal: newVal,
    });
  };
  setDate(date) {
    this.setState({ skybox_date: date.$d });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  openInternalNotes = () => {
    this.setState({ showInternalNotes: true });
  };
  closeInternalNotes = () => {
    this.setState({ showInternalNotes: false });
  };
  handleSubmit = async () => {
    this.setState({
      loader: true,
    });

    const data = {
      id: this.props.match.params.id,
      account_type: this.state.accountTypeVal,
      pos: this.state.vendorTypeVal.title,
      marketplace: this.state.marketTypeVal.title,
      username: this.state.email,
      password: this.state.password,
      apitoken: this.state.apitoken,
      skybox_date: this.state.skybox_date,
      skybox_internal_notes: this.state.skybox_internal_notes,
      internalNote:
        this.state.skybox_internal_notes === "scanonly"
          ? this.state.internalNoteValue.name
          : "",
    };
    const addAccount_api_url =
      process.env.REACT_APP_API_URL + "/api/account/updateAccount";
    await axios
      .post(addAccount_api_url, data, {
        headers: {
          authorization: "Bearer " +Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            eventList: data.data,
            open: true,
            message: data.message,
            successError: "success",
          });
          this.props.history.push("/market-pos-list");
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  render() {
    const {
      posType,
      email,
      password,
      accountTypeVal,
      marketplace,
      loader,
      apitoken,
    } = this.state;
    const labelStyle = {
      fontWeight: "500",
      fontFamily: "Barlow",
      color: "#002986",
    };
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Add Marketplace & POS</title>
          </Helmet>
          <Header />

          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />

            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {!loader ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid itme
                          xs="12"
                        >

                          <Card >
                            <CardHeader
                              title={`Edit ${this.state.accountTypeVal ?? ""}`}
                              action={
                                <Button
                                  onClick={() => this.props.history.goBack()}
                                  type="button"
                                  size="large"
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                  }}
                                  startIcon={<ArrowBack />}
                                >
                                  Back
                                </Button>
                              }
                            />

                            <AlertMessage
                              open={this.state.open}
                              message={this.state.message}
                              severity={this.state.successError}
                              closeCall={this.handleClose}
                            />

                            <CardContent>
                              <ValidatorForm onSubmit={this.handleSubmit}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        paddingBottom: "14px",
                                      }}
                                    >
                                      <label style={labelStyle}>
                                        Account type
                                      </label>
                                      <TextValidator
                                        variant="outlined"
                                        fullWidth
                                        name="accountTypeVal"
                                        value={this.state.accountTypeVal}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    {accountTypeVal === "POS" ? (
                                      <div
                                        style={{
                                          textAlign: "left",
                                          paddingBottom: "14px",
                                        }}
                                      >
                                        <label style={labelStyle}>
                                          Choose POS
                                        </label>
                                        <Autocomplete
                                          onChange={this.handleChangePOS}
                                          fullWidth
                                          name="posTypeVal"
                                          options={posType}
                                          value={this.state.vendorTypeVal}
                                          disableClearable={true}
                                          getOptionLabel={(option) =>
                                            option.title
                                          }
                                          renderInput={(params) => (
                                            <TextValidator
                                              value={this.state.vendorTypeVal}
                                              placeholder="Select POS"
                                              {...params}
                                              variant="outlined"
                                              validators={["required"]}
                                              errorMessages={
                                                "This field is required"
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    ) : accountTypeVal === "Marketplace" ? (
                                      <div style={{ textAlign: "left" }}>
                                        <label style={labelStyle}>
                                          Choose Marketplace
                                        </label>
                                        <Autocomplete
                                          onChange={this.handleChangeMarket}
                                          fullWidth
                                          name="marketTypeVal"
                                          options={marketplace}
                                          value={this.state.marketTypeVal}
                                          getOptionLabel={(option) =>
                                            option.title
                                          }
                                          renderInput={(params) => (
                                            <TextValidator
                                              value={this.state.marketTypeVal}
                                              placeholder="Select Marketplace"
                                              {...params}
                                              variant="outlined"
                                              validators={["required"]}
                                              errorMessages={
                                                "This field is required"
                                              }
                                            />
                                          )}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    {(accountTypeVal === "POS" &&
                                      this.state.vendorTypeVal.title !==
                                      "TV" && this.state.vendorTypeVal.title !==
                                      "TA") ||
                                      accountTypeVal === "Marketplace" ? (
                                      <div style={{ textAlign: "left" }}>
                                        <label style={labelStyle}>Email</label>
                                        <TextValidator
                                          variant="outlined"
                                          fullWidth
                                          onChange={this.handleChange}
                                          placeholder="Enter Email"
                                          name="email"
                                          value={email}
                                          validators={["required", "isEmail"]}
                                          errorMessages={[
                                            "This field is required",
                                            "email is not valid",
                                          ]}
                                        />
                                        {this.state.error.email && (
                                          <div className="error-msg">
                                            {this.state.error.email}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    {(accountTypeVal === "POS" &&
                                      this.state.vendorTypeVal.title !==
                                      "TV" && this.state.vendorTypeVal.title !==
                                      "TA") ||
                                      accountTypeVal === "Marketplace" ? (
                                      <div style={{ textAlign: "left" }}>
                                        <label style={labelStyle}>
                                          Password{" "}
                                        </label>
                                        <PasswordField
                                          variant="outlined"
                                          fullWidth
                                          onChange={this.handleChange}
                                          placeholder="Enter Password"
                                          name="password"
                                          value={password}
                                          eyestyle={{
                                            position: "absolute",
                                            right: "20px",
                                            top: "44px",
                                          }}
                                          inputProps={{
                                            autocomplete: "new-password",
                                          }}
                                        />
                                        <small>
                                          <i>
                                            ( * Please enter password if you
                                            want to change otherwise leave it
                                            blank)
                                          </i>
                                        </small>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>

                                  {this.state.marketTypeVal && (
                                    <>
                                      {(this.state.marketTypeVal.title ===
                                        "VividSeats" ||
                                        this.state.marketTypeVal.title ===
                                        "SeatGeek" ||
                                        this.state.marketTypeVal.title ===
                                        "Gametime") && (
                                          <Grid item xs={12} sm={6}>
                                            <div className="form-group login-wrapper">
                                              <label style={labelStyle}>
                                                API Token
                                              </label>
                                              <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                name="apitoken"
                                                onChange={this.handleChange}
                                                value={apitoken}
                                              />
                                            </div>
                                          </Grid>
                                        )}
                                    </>
                                  )}

                                  {this.state.vendorTypeVal && (
                                    <>
                                      {this.state.vendorTypeVal.title ===
                                        "Skybox" && (
                                          <>
                                            <Grid item xs={12} sm={6}>
                                              <div className="form-group">
                                                <label style={labelStyle}>
                                                  Scan From Date
                                                </label>
                                                <DatePicker
                                                  inputFormat="MM/DD/YYYY"
                                                  placeholder="MM/DD/YYYY"
                                                  defaultValue={new Date()}
                                                  value={this.state.skybox_date}
                                                  maxDate={new Date()}
                                                  onChange={(newValue) => {
                                                    if (newValue !== null) {
                                                      this.setDate(newValue);
                                                    } else {
                                                      this.setState({
                                                        skybox_date: null,
                                                      });
                                                    }
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextValidator
                                                      {...params}
                                                      fullWidth
                                                      variant="outlined"
                                                      placeholder="MM/DD/YYYY"
                                                      validators={["required"]}
                                                      value={
                                                        this.state.skybox_date
                                                      }
                                                      errorMessages={[
                                                        "This field is required",
                                                      ]}
                                                    />
                                                  )}
                                                />
                                                {/* <DateValidatorElement 
                                                                                    selected={this.state.skybox_date}
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                    maxDate={new Date()}
                                                                                    value={this.state.skybox_date}
                                                                                    onChange={date => this.setDate(date)}
                                                                                    validators={['required']}
                                                                                    errorMessages={[('This field is required')]}
                                                                                /> */}
                                              </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <div className="form-group login-wrapper">
                                                <label style={labelStyle}>
                                                  Skybox Internal Notes
                                                </label>
                                                <RadioGroup
                                                  aria-label="skybox_internal_notes"
                                                  defaultValue={
                                                    this.state
                                                      .skybox_internal_notes
                                                  }
                                                  name="skybox_internal_notes"
                                                  className="scan-condition-opts"
                                                  onChange={this.handleChange}
                                                >
                                                  <FormControlLabel
                                                    value="scanall"
                                                    control={
                                                      <Radio color="primary" />
                                                    }
                                                    label="Scan All"
                                                  />
                                                  <FormControlLabel
                                                    value="scanonly"
                                                    control={
                                                      <Radio color="primary" />
                                                    }
                                                    label="Scan only Internal Notes"
                                                  />
                                                </RadioGroup>
                                              </div>
                                            </Grid>
                                            {this.state.skybox_internal_notes ===
                                              "scanonly" &&
                                              this.state.posTypeVal !== "" && (
                                                <Grid item xs={12} sm={6}>
                                                  <div
                                                    style={{ textAlign: "left" }}
                                                  >
                                                    <label style={labelStyle}>
                                                      Internal Notes
                                                    </label>
                                                    <Autocomplete
                                                      onChange={
                                                        this.handleChangeNotes
                                                      }
                                                      fullWidth
                                                      name="internalNotes"
                                                      options={
                                                        this.state.internalnotes
                                                      }
                                                      value={
                                                        this.state
                                                          .internalNoteValue
                                                      }
                                                      getOptionLabel={(option) =>
                                                        option.name
                                                      }
                                                      renderInput={(params) => (
                                                        <TextValidator
                                                          placeholder="Select Internal Notes"
                                                          value={
                                                            this.state
                                                              .internalNoteValue
                                                          }
                                                          {...params}
                                                          variant="outlined"
                                                          validators={[
                                                            "required",
                                                          ]}
                                                          errorMessages={
                                                            "This field is required"
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                </Grid>
                                              )}
                                          </>
                                        )}
                                    </>
                                  )}
                                  <Grid item xs={12} sx={{
                                    textAlign: "center",
                                  }} >
                                    <Button
                                      sx={{ marginTop: "10px" }}
                                      variant="contained"
                                      type="submit"
                                      size="large"
                                      className="theme-btn"
                                    >
                                      Edit Account
                                    </Button>
                                  </Grid>
                                </Grid>

                              </ValidatorForm>
                            </CardContent>
                          </Card>

                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <ContainerLoader />
                  )}
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </LocalizationProvider>
    );
  }
}
export default EditMarketPos;