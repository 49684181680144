import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

// mui icon and svg icon
import LoadPassword from "images/load_password.svg"
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { HiDotsHorizontal } from "react-icons/hi";
import { styled } from "@mui/material/styles";

// mui components
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import {
    CardContent,
    Button,
    Card,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
} from "@mui/material";

// import components
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from "components/message/message";
import { ArrowBack } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary["dark"],
        color: theme.palette.common.white,
        fontFamily: "Barlow !important",
        fontWeight: "bold",
        textAlign: "left",
        position: "relative",
        // width: "100%",
        minWidth: "max-content",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        fontFamily: "Barlow !important",
        textAlign: "left",
        color: "#3C4E6A",
        borderBottom: "1px solid #CCCCCC",
        width: "auto",
        padding: "12px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const TMPasswordManagerView = ({
    setVeiwListVisibleModel,
    singleUserDataList,
    setSingleUserDataList,
    isLoading,
    handleClose,
    handleGetUsers
}) => {
    const [message, setMessage] = useState({
        alertseverity: '',
        message: '',
        openalert: false
    })
    const [isHidePassword, setIsHidePassword] = useState(false);
    const [isLoadingPass, setLoadingPass] = useState(false);
    const [passIndexNumber, setPassIndexNumber] = useState('');

    const hiddenPassword = async (itemval) => {
        if (isHidePassword && passIndexNumber === itemval.id) {
            setIsHidePassword(false);
            setPassIndexNumber("");
            setLoadingPass(false);
        } else {
            try {
                setIsHidePassword(true);
                setPassIndexNumber(itemval.id);
                setLoadingPass(true);
                const pass_api_url = process.env.REACT_APP_API_URL + "/api/account/accountDetailWithPlainPass";
                const response = await axios.post(pass_api_url, { id: itemval.id }, {
                    headers: {
                        authorization:
                            "Bearer " + Cookies.get("accessToken"),
                    },
                });
                const { data } = response;
                if (data.error) {
                    setIsHidePassword(false);
                    setPassIndexNumber("");
                    setLoadingPass(false);
                    setMessage({
                        ...message,
                        message: 'Something went wrong',
                        openalert: true,
                        alertseverity: "error"
                    })
                } else {
                    var indexs = singleUserDataList.map((v) => { return v.id }).indexOf(itemval.id);
                    singleUserDataList[indexs]["password"] = data.data.password;
                    setSingleUserDataList(singleUserDataList)
                    setLoadingPass(false);
                }
            } catch (error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsHidePassword(false);
                setPassIndexNumber("");
                setLoadingPass(false);
            }
        }
    }
    useEffect(() => {
        handleGetUsers()
    }, []);

    const handleBacktoAllTMPassword = () => {
        setVeiwListVisibleModel(false)
    }

    return (
        <>
            <br />
            <div className="c-body">
                <main className="c-main">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="space-top-col">
                            <Card
                                sx={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    color: "#000059!important",
                                    textAlign: "left",
                                    padding: "30px",
                                    backgroundColor: "#fbfbfb;",
                                }}
                            >
                                <div className="vendortable-header">
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: "100%",
                                            maxWidth: "265px",
                                            color: "#000059!important",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            fontSize: "1.6rem",
                                            fontFamily: "Barlow!important",
                                        }}
                                    >
                                        {" "}
                                        TM Password Manager
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={{ xs: 1, md: 1, lg: 1 }}
                                        columns={{ xs: 4, sm: 8, md: 8 }}
                                        className="button-align"
                                    >
                                        <Grid item>
                                            <Button
                                                onClick={() => handleBacktoAllTMPassword()}
                                                type="button"
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#002986!important",
                                                    color: "white!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                }}
                                                startIcon={<ArrowBack />}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                {!isLoading ? (
                                    <CardContent
                                        sx={{
                                            padding: "0px!important",
                                            marginTop: "10px!important",
                                        }}
                                        className="plan-tbl vendor-outer"
                                    >
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 900 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            Team
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            UserName
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            Password
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {singleUserDataList && singleUserDataList.length > 0 ? (<>
                                                        {singleUserDataList && singleUserDataList?.map((user, index) => (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell component="td" scope="row">
                                                                    {user.Team}
                                                                </StyledTableCell>
                                                                <StyledTableCell component="td" scope="row">
                                                                    {user.UserName}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        <div className="flex">
                                                                            <div className="flex">
                                                                                <div
                                                                                    style={{
                                                                                        width: "fit-content",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        flex: "wrap",
                                                                                        wordBreak: "break-all",
                                                                                    }}
                                                                                >
                                                                                    {passIndexNumber === user?.id ? (
                                                                                        <>
                                                                                            {isLoadingPass ? (
                                                                                                <img src={LoadPassword} alt="" />
                                                                                            ) : (
                                                                                                <>
                                                                                                    {isHidePassword
                                                                                                        ? user?.password
                                                                                                        : "*******"}
                                                                                                </>
                                                                                            )}
                                                                                            <VisibilityOffIcon
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                    fontSize: "1.3rem",
                                                                                                    color: "#002986",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    hiddenPassword(user)
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <HiDotsHorizontal
                                                                                                style={{
                                                                                                    margin: "0px",
                                                                                                    padding: "0px",
                                                                                                    position: "relative",
                                                                                                    left: "2px",
                                                                                                    fontSize: "30px",
                                                                                                }}
                                                                                            />
                                                                                            <HiDotsHorizontal
                                                                                                style={{ fontSize: "30px" }}
                                                                                            />
                                                                                            <VisibilityIcon
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                    fontSize: "1.3rem",
                                                                                                    color: "#002986",
                                                                                                }}
                                                                                                onClick={() =>
                                                                                                    hiddenPassword(user)
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </>
                                                    ) : (
                                                        <StyledTableRow>
                                                            <td
                                                                colSpan={13}
                                                                style={{ padding: "2.6rem 1rem" }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "1.6rem",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    No Records Found
                                                                </Typography>
                                                            </td>
                                                        </StyledTableRow>
                                                    )}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                ) : (
                                    <ContainerLoader />
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </main>
            </div>
            <AlertMessage
                open={message.openalert}
                message={message.message}
                severity={message.alertseverity}
                closeCall={handleClose}
            />
        </>
    );
};
export default TMPasswordManagerView;