import React from 'react'
import ComHeader from './ComHeader'

import {
  TheContent,
  TheFooter,
} from './index'

const CommunicationLayout = ({children}) => {

  return (
    <div className="c-app c-default-layout">
      {/*<TheSidebar nav="communication" />*/}
      <div className="c-wrapper">
        <ComHeader/>
        <div className="c-body">
          <TheContent/>
          {/* {children} */}
        </div>
        <TheFooter/>
      </div>
    </div>
  )
}

export default CommunicationLayout
