import { ActionTypes } from "../types/ActionTypes";

const initialState = {
    progressId :  null
}

const ProgressReducer = (state= initialState, action) => {
    switch(action.type){
        case ActionTypes.PROGREESBAR:
            return {
                ...state,
                progressId : action.payload
            }
        default:
            return state
    }
}

export default ProgressReducer;