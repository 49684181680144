import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Container, } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './login.css';
import Logo from '../../images/logo.png';
import axios from 'axios';
import AlertMessage from '../alert/alert';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Helmet} from 'react-helmet';
import '../../common.css';
import { Box, CircularProgress } from '@mui/material';
import { encodeParamsToBase64 } from 'utils/base64';
export default class Adminlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loader:false,
      open:false,
      message:'',
      successError:'',
    } 
  }
  componentDidMount(){
    if(localStorage.getItem('accessTokenAdmin') !== null){
      this.props.history.push("/admin")
      
    }
    else{
      this.props.history.push("/admin-login")
    }
   
  }
  handleChange=(e)=>{
    this.setState({
      [e.target.name]:e.target.value
    })
  }
  handleSubmit = async () => {
    this.setState({
      loader:true
    })
    const publicIPAddress = localStorage.getItem('publicIPAddress');
    const formData = {
      email: this.state.email,
      password: this.state.password,
      ipAddress: publicIPAddress ?? null
    }
    const login_api_url = process.env.REACT_APP_API_URL + "/api/auth/adminSignin"
  
    await axios.post(login_api_url, formData)
        .then((response) => {
        
            const { data } = response;
            if (data.error) {
              
                this.setState({
                    loader: false,
                    open:true,
                    message:data.message,
                    successError:'error'
                })
              
            }
            else {
                this.setState({
                    loader: false,
                    open:true,
                    message:data.message,
                    successError:'success'
                })
                localStorage.setItem('accessTokenAdmin', data.accessToken);
                const lastToken = { lastToken: data.accessToken, expriedToken: new Date() }
                const encodedTokenData = encodeParamsToBase64(JSON.stringify(lastToken));
                localStorage.setItem('lat', JSON.stringify(encodedTokenData));
                this.props.history.push("/admin");
            }
        }).catch((error) => {
            
            this.setState({
                    loader: false,
                    open:true,
                    message:"Something went wrong, please try again",
                    successError:'error',
            })
        })
  }
  render() {
    const {email, password, loader} = this.state;
   if(!loader){ return (
            <>
              <div className="c-app c-default-layout flex-row align-items-center">
              
                <Helmet>
                        <title>Admin Login</title>
                        <body className="Login-Bg" />
                    </Helmet>
                
                <Container>
                  <div className="Login-Outer-Padding">
                    <Row className="vertical-center">
                      <Col md={6}>
                        <div className="Login-Left-Text center">
                          <Link to="/"><img src={Logo} className="logo-size" alt="" /></Link>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="Login-Right-Css">
                          <div className="center"> <h2 className="Login-Heading">Admin Login</h2></div>
                          <ValidatorForm
                            // ref="form"
                            onSubmit={this.handleSubmit}
                          >
                            <Row>
                              <Col md="12">
                                <div className="form-group login-wrapper error-msg-fix">
                                  <label>Email</label>
                                  <TextValidator
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="email"
                                    value={email}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['this field is required', 'email is not valid']}
                                  />

                                </div>
                              </Col>

                              <Col md="12">
                                <div className="form-group login-wrapper error-msg-fix">
                                  <label>Password</label>
                                  <TextValidator
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    type="password"
                                    name="password"
                                    value={password}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                  />

                                </div>
                              </Col>
                              <Col md="12">
                                <Row className="align-items-center keep-login-label">
                                  <Col md="12" className="text-left">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          // checked={state.checkedB}
                                          // onChange={this.handleChange}
                                          name="checkedB"
                                          color="primary"
                                        />
                                      }
                                      label="Keep me logged in"
                                    />
                                  </Col>
                                  {/* <Col md="6" sm="6">
                                          <div className="right forget"> <Link to="/forgetPassword">Forget a password</Link> </div>
                                        </Col> */}
                                </Row>
                              </Col>

                              <Col md="12">
                                <Button

                                  variant="contained"
                                  type="submit"
                                  // disabled={submitted}
                                  className="full-width login-btn"
                                >
                                  Login
                                        </Button>
                              </Col>
                            </Row>
                          </ValidatorForm>
                          {/* <div className="center"><p className="Login-blur-text">Dont have an account?</p><Link to="/register">SignUp</Link></div>
                          <div className="or-style"><span>OR</span></div> */}
                        </div>
                      </Col>
                    </Row>
                    {/* <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/> */}
                    <AlertMessage open={this.state.open} message={"Invalid Credentails"} severity={this.state.successError} closeCall={this.handleClose}/>
                  </div>
                </Container>
              </div>
            </>
    )}
    return (
      <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
          <CircularProgress sx={{ color: "#002986" }} />
      </Box>
  )
  }

}

