import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import themes from "themes";

const root = createRoot(document.getElementById("root"));
const customization = {
  isOpen: [],
  defaultId: "default",
  fontFamily: "'Barlow', sans-serif",
  borderRadius: 6,
  opened: false,
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={themes(customization)}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
