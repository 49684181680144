import {
  Box,
  Grid,
  AppBar,
  Container,
  Button,
  InputAdornment,
  IconButton,
  styled,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { Component } from "react";
import Logo from "images/ticket_liberty_logo.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import customDateFormat from "utils/customDateFormat";
import SearchIcon from "@mui/icons-material/Search";
import { indigo } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import "./verifiedTickets.scss";

import axios from "axios";
import AlertMessage from "containers/alert/alert";

const Footer = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  boxShadow: "0px -2px 10px #00000029",
  position: "relative",
  bottom: 0,
  width: "100%",
}));

const MainLayout = styled("div")(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  color: theme.palette.common.white,
  fontFamily: "Barlow !important",
  height: "100%",
  minHeight: "100vh",
  paddingBottom: "50px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    padding: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
export default class verifiedTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      link: "",
      linkdetails: [],
      userdata: null,
      messageopen: false,
      message: "",
      messagetype: "success",
      linkTickets: [],
      selected: [],
      pairedTickets: [],
      listofPairTickets: [],
      inv: {},
      SearchUrl: "",
      splitdone: false,
      copiedIndex: null,
      copiedUrl: false,
      parentIndex: null,
      name: "",
      email: "",
      phone: "",
      ticketsAvailable: false,
      splitTicketsAll: false,
      allSplitedTickets: [],
      findLink: false,
      historylink: "",
      allHistory: [],
      findLinkHistory: false,
      splitOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    // this.makePair = this.makePair.bind(this);
    // this.handleSelectTickets = this.handleSelectTickets.bind(this);
    // this.splitLinks = this.splitLinks.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.splitAll = this.splitAll.bind(this);
    this.handleToggleSplit = this.handleToggleSplit.bind(this);
  }
  handleClosemessage() {
    this.setState({
      messageopen: false,
      message: "",
    });
  }

  handleToggleSplit = () => {
    this.setState({
      splitOpen: !this.state.splitOpen,
    });
  };
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  // ----------get The tickets from the Link --------------
  handleSubmit() {
    this.setState({
      splitdone: false,
      loader: true,
      listofPairTickets: [],
      linkTickets: [],
      selected: [],
      phone: "",
      name: "",
      email: "",
      allSplitedTickets: [],
      findLink: false,
      findLinkHistory: false,
      allHistory: [],
    });

    const { link } = this.state;
    const data = {
      link,
    };
    this.setState({
      SearchUrl: link,
    });

    const getLinkDetails =
      process.env.REACT_APP_API_URL + "/api/getLinkTickets";

    axios
      .post(getLinkDetails, data)
      .then((res) => {
        const { data } = res;
        if (data.error) {
          this.setState({
            messageopen: true,
            message: data.message,
            messagetype: "error",
            loader: false,
          });
        } else if (data.success) {
          console.log(data, "data Success");
          this.setState({
            ticketsAvailable: data.success,
            messageopen: true,
            inv: data.inv,
            message: data.message,
            messagetype: "success",
            findLink: true,
            loader: false,
            linkTickets: data.tickets.map((ticket) => {
              return {
                id: ticket.id,
                _id: ticket._id,
                row: ticket.row,
                seat: ticket.seat,
                section: ticket.section,
                event_name: ticket.vbdevent_id.event_name,
                date: customDateFormat(ticket.vbdevent_id.date),
              };
            }),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
      });
  }
  // __________________get The history of the splitted  Link ____________________

  splitAll = () => {
    this.setState({
      splitTicketsAll: true,
      listofPairTickets: [],
      copiedUrl: false,
      splitOpen: false,
      loader: true,
      linkTickets: [],
    });
    const data = {
      link: this.state.SearchUrl,
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
    };

   

    axios
      .post(process.env.REACT_APP_API_URL + "/api/splitAllTickets", data)
      .then((res) => {
        const { data } = res;
        if (data.error) {
          // console.log(data.error);
          this.setState({
            messageopen: true,
            message: data.message,
            messagetype: "error",
            loader: false,
          });
        } else if (data.success) {
          this.setState({
            messageopen: true,
            message: data.message,
            messagetype: "success",
            loader: false,
            findLink: true,
            splitTicketsAll: false,
            allSplitedTickets: data.data,
            splitdone: true,
            linkTickets: [], 
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handleSelectTickets = (e) => {
  //   this.setState({
  //     selected: e,
  //   });
  // };

  // splitLinks = () => {
  //   const datas = {
  //     link: this.state.SearchUrl,
  //     inv: this.state.inv,
  //     pairs: this.state.listofPairTickets,
  //     name: this.state.name,
  //     email: this.state.email,
  //     phone: this.state.phone,
  //   };

  //   this.setState({
  //     copiedUrl: false,
  //     splitdone: false,
  //     loader: true,
  //   });

  //   const splitLinkApi =
  //     process.env.REACT_APP_API_URL + "/api/splitLinkByPairs";
  //   axios
  //     .post(splitLinkApi, datas)
  //     .then((res) => {
  //       const { data } = res;
  //       if (data.error) {
  //         // console.log(data.error);
  //         this.setState({
  //           messageopen: true,
  //           message: data.message,
  //           messagetype: "error",
  //           loader: false,
  //         });
  //       } else if (data.success) {
  //         this.setState({
  //           splitdone: true,
  //           listofPairTickets: data.data,
  //           loader: false,
  //           linkTickets: [],
  //           messageopen: true,
  //           message: data.message,
  //           messagetype: "success",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  copyUrl = (url, index2, index) => {
    navigator.clipboard.writeText(url);
    this.setState({ copiedUrl: true, copiedIndex: index2, parentIndex: index });
  };

  // makePair = async () => {
  //   console.log(this.state.selected, "selected");
  //   if (this.state.selected.length > 0) {
  //     this.state.pairedTickets.push(this.state.selected);
  //     var pt = [];
  //     this.state.linkTickets.map((ticket) => {
  //       if (this.state.selected.includes(ticket.id)) {
  //         pt.push({
  //           id: ticket.id,
  //           _id: ticket._id,
  //           row: ticket.row,
  //           seat: ticket.seat,
  //           section: ticket.section,
  //           event_name: ticket.event_name,
  //           date: ticket.date,
  //         });
  //       }

  //       return pt;
  //     });

  //     await this.setState({
  //       listofPairTickets: [...this.state.listofPairTickets, pt],
  //     });

  //     // remove selected tickets from linkTickets array
  //     const filtered = this.state.linkTickets.filter(
  //       (ticket) => !this.state.selected.includes(ticket.id)
  //     );
  //     this.setState({
  //       linkTickets: filtered,
  //     });
  //   }
  // };

  componentDidMount() {
    ValidatorForm.addValidationRule("mtchRegexp", (value) => {
      // regex to check url is valid or not
      const regex = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator

        "i" // fragment locator
      );
      if (regex.test(value)) {
        return true;
      }
      return false;
    });
  }
  render() {
    const columns = [
      {
        field: "event_name",
        headerName: "Event Name",
        width: 300,
        editable: false,
        filterable: false,
        headerAlign: "left",
        cellAlign: "left",
        headerClassName: "theme--header",
      },
      {
        field: "date",
        headerName: "Date",
        width: 180,
        editable: false,
        filterable: false,
        headerAlign: "left",
        cellAlign: "left",
        headerClassName: "theme--header",
      },
      {
        field: "section",
        headerName: "Section",
        headerAlign: "left",
        width: 110,
        filterable: false,
        editable: false,
        headerClassName: "theme--header",
      },
      {
        field: "row",
        headerName: "Row",
        width: 100,
        editable: false,
        filterable: false,
        headerAlign: "left",
        headerClassName: "theme--header",
      },
      {
        field: "seat",
        headerName: "Seat",
        width: 110,
        filterable: false,
        editable: false,
        headerAlign: "left",
        cellAlign: "left",
        headerClassName: "theme--header",
      },
    ];

    if (!this.state.loader) {
      return (
        <>
          <MainLayout>
            <Helmet>
              <title>Verified Tickets</title>
            </Helmet>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <Container maxWidth="xl">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        maxWidth: "fit-content",
                        margin: "0px auto",
                      }}
                    >
                      <div className="wrapper">
                        <div className="header_logo">
                          <Link to="/">
                          <img src={Logo} alt="logo" className="m-image" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </AppBar>

            <Grid
              container
              spacing={2}
              sx={{
                padding: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="medium"
                  to="/historyofTickets"
                  sx={{
                    fontFamily: "Barlow!important",
                    fontSize: "14px!important",
                    "&:hover": {
                      fontFamily: "Barlow!important",
                      fontSize: "14px!important",
                    },
                  }}
                  className="theme-btn-submit"
                  startIcon={<LinkIcon />}
                >
                  View History
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ValidatorForm
                  onSubmit={this.handleSubmit}
                  className="filter-form"
                  style={{
                    width: "100%",
                    maxWidth: "700px",
                    margin: "0px auto",
                    marginTop: "4rem",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextValidator
                        placeholder="Paste url to split tickets"
                        variant="outlined"
                        fullWidth
                        name="link"
                        value={this.state.link}
                        onChange={this.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{
                                  color: indigo[900],
                                }}
                                aria-label="submit"
                                type="submit"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        validators={["required", "mtchRegexp:^[a-zA-Z0-9]+$"]}
                        errorMessages={["Url is required", "Url is not valid"]}
                      />
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>

              {this.state.linkTickets.length > 0 && !this.state.loader && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={7}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        flexDirection: "column",
                      }}
                    >
                      {/* <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={this.makePair}
                          className="theme-btn-submit"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          Split Tickets
                        </Button>
                      </div> */}

                      <DataGrid
                        rows={this.state.linkTickets}
                        columns={columns}
                        autoHeight
                        pageSize={this.state.linkTickets.length}
                        rowsPerPageOptions={[this.state.linkTickets.length]}
                        onSelectionModelChange={this.handleSelectTickets}
                        disableColumnFilter={true}
                        // checkboxSelection
                        localeText={{
                          footerRowSelected: (count) =>
                            `${count.toLocaleString()} ${
                              count > 1 ? "Tickets" : "Ticket"
                            } Selected`,
                          toolbarDensity: "Size",
                        }}
                        disableSelectionOnClick
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* /* ------------------ Paired Tickets ------------------ */}
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={8}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.ticketsAvailable &&
                      this.state.findLink &&
                      !this.state.loader && (
                        <>
                          <ValidatorForm >
                            {!this.state.splitdone ? (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography
                                    variant="h6"
                                    component="h6"
                                    sx={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Enter your details
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextValidator
                                    placeholder="Enter Name (optional)"
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{
                                              color: indigo[900],
                                            }}
                                            aria-label="submit"
                                            type="button"
                                          >
                                            <AccountCircleIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextValidator
                                    placeholder="Enter Phone (optional)"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    name="phone"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{
                                              color: indigo[900],
                                            }}
                                            aria-label="submit"
                                            type="button"
                                          >
                                            <SmartphoneIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    validators={["isNumber"]}
                                    errorMessages={[
                                      "Phone Number is not valid",
                                    ]}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextValidator
                                    placeholder="Enter Email (optional)"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{
                                              color: indigo[900],
                                            }}
                                            aria-label="submit"
                                            type="button"
                                          >
                                            <EmailIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    validators={["isEmail"]}
                                    errorMessages={["Email is not valid"]}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  marginBottom: "10px",
                                }}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography
                                    variant="h6"
                                    component="h6"
                                    sx={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Link Split Successfully !
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}

                            {/* {this.state.listofPairTickets.map((item, index) => {
                              return (
                                <Card
                                  key={index}
                                  sx={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <CardHeader
                                    sx={{
                                      backgroundColor: indigo[900],
                                      color: "#fff",
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      fontFamily:
                                        "Barlow, sans-serif!important",
                                      span: {
                                        fontSize: "1rem",
                                        fontFamily:
                                          "Barlow, sans-serif!important",
                                        fontWeight: "bold",
                                      },
                                    }}
                                    title={`New Link (${item.length})`}
                                  />
                                  <CardContent>
                                    <TableContainer component={Paper}>
                                      <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <StyledTableRow>
                                            <StyledTableCell>
                                              Event Name
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Date
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              Section
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              Row
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              Seat
                                            </StyledTableCell>
                                            {this.state.splitdone && (
                                              <>
                                                <StyledTableCell align="right">
                                                  Ticket Url
                                                </StyledTableCell>

                                                <StyledTableCell align="left">
                                                  Copy Url
                                                </StyledTableCell>
                                              </>
                                            )}
                                          </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                          {item.map((row, index2) => (
                                            <StyledTableRow key={row.id}>
                                              <StyledTableCell>
                                                {row.event_name}
                                              </StyledTableCell>
                                              <StyledTableCell>
                                                {row.date}
                                              </StyledTableCell>
                                              <StyledTableCell align="right">
                                                {row.section}
                                              </StyledTableCell>
                                              <StyledTableCell align="right">
                                                {row.row}
                                              </StyledTableCell>
                                              <StyledTableCell align="right">
                                                {row.seat}
                                              </StyledTableCell>
                                              {row.ticketurl && (
                                                <>
                                                  <StyledTableCell align="right">
                                                    {row.ticketurl}
                                                  </StyledTableCell>

                                                  <StyledTableCell align="right">
                                                    {this.state.copiedUrl &&
                                                    this.state.copiedIndex ===
                                                      index2 &&
                                                    this.state.parentIndex ===
                                                      index ? (
                                                      <Button
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#919191C2!important",
                                                          color:
                                                            "white!important",
                                                          textTransform: "none",
                                                          fontWeight:
                                                            "bold!important",
                                                          padding: "6px 8px",
                                                          fontSize:
                                                            "14px!important",
                                                          ".css-y6rp3m-MuiButton-startIcon":
                                                            {
                                                              marginRight:
                                                                "3px",
                                                              marginLeft: "3px",
                                                            },
                                                          ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                                            {
                                                              fontSize: "20px",
                                                            },
                                                        }}
                                                        size="small"
                                                        startIcon={
                                                          <LibraryAddCheckIcon />
                                                        }
                                                      >
                                                        Copied
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#011C58!important",
                                                          color:
                                                            "white!important",
                                                          textTransform: "none",
                                                          fontWeight:
                                                            "bold!important",
                                                        }}
                                                        onClick={() =>
                                                          this.copyUrl(
                                                            row.ticketurl,
                                                            index2,
                                                            index
                                                          )
                                                        }
                                                        startIcon={
                                                          <ContentCopyIcon />
                                                        }
                                                      >
                                                        Copy
                                                      </Button>
                                                    )}
                                                  </StyledTableCell>
                                                </>
                                              )}
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </CardContent>
                                </Card>
                              );
                            })} */}

                            {!this.state.splitdone && this.state.findLink && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                }}
                              >
                                {/* <Button
                                  variant="contained"
                                  type="submit"
                                  size="large"
                                  disabled={
                                    this.state.linkTickets.length === 0 &&
                                    this.state.listofPairTickets.length > 0
                                      ? false
                                      : true
                                  }
                                  className="theme-btn-submit"
                                  sx={{
                                    marginBottom: "10px",
                                    background:
                                      this.state.linkTickets.length === 0 &&
                                      this.state.listofPairTickets.length > 0
                                        ? "#002986!important"
                                        : "#5f6b88!important",
                                  }}
                                >
                                  Generate Links
                                </Button> */}
                                <Button
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  onClick={this.handleToggleSplit}
                                  className="theme-btn-submit"
                                  sx={{
                                    marginBottom: "10px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Split All
                                </Button>
                              </div>
                            )}
                          </ValidatorForm>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>

              {/* ----------All splitted Tickets will be listed here---------- */}
              {this.state.allSplitedTickets.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={12} sm={10} md={10} lg={10}>
                      {this.state.allSplitedTickets.length > 0 && (
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell>Event Name</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell align="right">
                                  Section
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Row
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Seat
                                </StyledTableCell>

                                <StyledTableCell align="right">
                                  Ticket Url
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  Copy Url
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.allSplitedTickets.map(
                                (row, index2) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                      {row.vbdevent_id[0].event_name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {customDateFormat(
                                        row.vbdevent_id[0].date
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.section}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.row}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.seat}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.ticketurl}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {this.state.copiedUrl &&
                                      this.state.copiedIndex === index2 ? (
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              "#919191C2!important",
                                            color: "white!important",
                                            textTransform: "none",
                                            fontWeight: "bold!important",
                                            padding: "6px 8px",
                                            fontSize: "14px!important",
                                            ".css-y6rp3m-MuiButton-startIcon": {
                                              marginRight: "3px",
                                              marginLeft: "3px",
                                            },
                                            ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                              {
                                                fontSize: "20px",
                                              },
                                          }}
                                          size="small"
                                          startIcon={<LibraryAddCheckIcon />}
                                        >
                                          Copied
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundColor:
                                              "#011C58!important",
                                            color: "white!important",
                                            textTransform: "none",
                                            fontWeight: "bold!important",
                                          }}
                                          onClick={() =>
                                            this.copyUrl(row.ticketurl, index2)
                                          }
                                          startIcon={<ContentCopyIcon />}
                                        >
                                          Copy
                                        </Button>
                                      )}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <AlertMessage
              open={this.state.messageopen}
              message={this.state.message}
              severity={this.state.messagetype}
              closeCall={this.handleClosemessage}
            />
          </MainLayout>
          <Footer>
            <Typography
              sx={{
                fontSize: "20px",
                letterSpacing: "2px",
                fontWeight: "bold",
              }}
            >
              Contact Us:{" "}
              <a
                href="mailto:sales@ticketliberty.com"
                style={{ color: "#474747", textDecoration: "none" }}
              >
                sales@ticketliberty.com
              </a>
            </Typography>

            <Dialog
              fullWidth={true}
              size="medium"
              open={this.state.splitOpen}
              onClose={() => this.handleToggleSplit()}
            >
              <DialogTitle>Split Tickets</DialogTitle>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleToggleSplit()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <DialogContentText>
                  You are about to split {this.state.linkTickets.length}{" "}
                  tickets. Are you sure you want to continue?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.handleToggleSplit()}
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.splitAll}
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Split
                </Button>
              </DialogActions>
            </Dialog>
          </Footer>
        </>
      );
    }

    return (
      <>
        <MainLayout>
          <Helmet>
            <title>Verified Tickets</title>
          </Helmet>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      maxWidth: "fit-content",
                      margin: "0px auto",
                    }}
                  >
                    <div className="wrapper">
                      <div className="header_logo">
                        <img src={Logo} alt="logo" className="m-image" />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </AppBar>

          <Grid
            container
            spacing={2}
            sx={{
              padding: "20px",
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5rem 0",
                }}
              >
                <CircularProgress sx={{ color: "#002986" }} />
              </Box>
            </Grid>
          </Grid>
        </MainLayout>
        <Footer>
          <Typography
            sx={{
              fontSize: "20px",
              letterSpacing: "2px",
              fontWeight: "bold",
            }}
          >
            Contact Us:{" "}
            <a
              href="mailto:sales@ticketliberty.com"
              style={{ color: "#474747", textDecoration: "none" }}
            >
              sales@ticketliberty.com
            </a>
          </Typography>
        </Footer>
      </>
    );
  }
}
