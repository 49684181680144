/* eslint no-undef: 0, no-underscore-dangle: 0 */
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import history from "./../history";
import signUpReduc from "./reducers/Signup";
import loginReduc from "./reducers/Login";
import UserReducer from "./reducers/userReducer";
import toggleSidebar from "./reducers/sidebarReducer";
import { ScanApiReducer } from "./reducers/ScanApiReducer";
import { RefreshVendorReducer } from "./reducers/RefreshVendorReducer";
import UserProfileReducer from "./reducers/UserProfileReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ProgressReducer from "./reducers/ProgressReducer";
import PageLimit from "./reducers/PageLimit";
import timeReducer from "./reducers/timeReducer";
import TimeDurationReducer from "./reducers/TimeDurationReducer";

const reducers = combineReducers({
  signUp: signUpReduc,
  logIn: loginReduc,
  SideBar: toggleSidebar,
  User: UserReducer,
  ScanApi: ScanApiReducer,
  RefreshVendor: RefreshVendorReducer,
  UserProfile: UserProfileReducer,
  Progress: ProgressReducer,
  Page: PageLimit,
  time: timeReducer,
  timerDuration: TimeDurationReducer,

  /*router: connectRouter(history),*/
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "User",
    "UserProfile",
    "Page",
    "SideBar",
    "time",
    "timerDuration",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = [thunk, routerMiddleware(history)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

// const store = createStore(reducers, /* preloadedState, */ composeEnhancers(

//   applyMiddleware(thunk, routerMiddleware(history))
// ));

export { store, persistor };
