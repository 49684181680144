import Moment from "moment";

export default function customDateFormatLocal(datestring, timeZone) {
  if (!datestring) {
    return "";
  }
  if (datestring === "") {
    return "";
  }
  var correctDatestring = datestring.replace("||", "T");
  correctDatestring = correctDatestring.replace(" OFFSALE", "");
  correctDatestring = correctDatestring.replace(" CANCELED", "");
  correctDatestring = correctDatestring.replace(" ONSALE", "");
  correctDatestring = correctDatestring.replace(" RESCHEDULED", "");

  var lastZ = correctDatestring.charAt(correctDatestring.length - 1);
  if (lastZ !== "Z" && correctDatestring.length > 10) {
    correctDatestring = correctDatestring + "Z";
  }
  var stringtoDate = new Date(correctDatestring);
  // var offset = stringtoDate.getTimezoneOffset();
  var retDate = "";
  if (timeZone && timeZone !== "") {
    retDate = Moment(stringtoDate).tz(timeZone).format("MM-DD-YYYY h:mma");
  } else {
    retDate = Moment(stringtoDate).tz("Eastern").format("MM-DD-YYYY h:mma");
  }

  return retDate;
}
