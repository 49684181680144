import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Commonheader from "components/common-header/common-headre";
import "./term.css";
import { Card, CardContent, Container, Grid } from "@mui/material";

class Privacypolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>

        <Header />
        <Container>
          <div className="common-header-padding">
            <Commonheader
              title="PRIVACY POLICY"
              description="We Are Very Serious About Your Privacy "
            />
            <Grid container spacing={1} style={{ marginTop: "60px" }}>
              <Grid item lg={12} md={12} className="center">
                <Card
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <CardContent
                    sx={{
                      p: {
                        xs: 3,
                        sm: 4,
                        md: 5,
                      },
                    }}
                  >
                    <div className="left term-outer">
                      <p>
                        <strong>Ticket Liberty Corp.,</strong>a corporation
                        organized under the laws of the State of New York and
                        its Affiliates (“We”, “Us” or “Company”) are firmly
                        committed to protecting and respecting your privacy,
                        including any information your share with other users.
                        This Privacy Policy describes our information gathering,
                        use and sharing practices for our software application
                        and a website, including any mobile access or
                        applications, and the websites of our Affiliates
                        (collectively “Website” and “Application”) and use of
                        our services. As used in this Privacy Policy, references
                        to our “Affiliates” include our owners, licensees,
                        assigns, subsidiaries, other affiliated companies,
                        officers, directors, suppliers, partners, sponsors, and
                        includes (without limitation) all parties involved in
                        creating, producing, and/or delivering services or
                        contents available through the Website and Application.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        By accessing the Website and using the Application, you
                        accept this Privacy Policy and agree to the terms,
                        conditions and notices contained in it.{" "}
                      </h5>
                    </div>

                    <div className="left term-outer">
                      <h4>
                        What Information is Covered by This Privacy Policy?
                      </h4>
                      This Privacy Policy privacy covers all Personally
                      Identifiable Information (“PII”) that you may provide and
                      we may collect when you use our Application, Website and
                      our services. This policy also covers our treatment of any
                      PII that our Affiliates share with us or we share with
                      them.
                    </div>
                    <div className="left term-outer">
                      <h4> What Information Do We Collect?</h4>
                    </div>

                    <div className="left term-outer">
                      <h5>A. Information Related to Our Services </h5>
                      <p>
                        When you subscribe to our Application, services and/or
                        register with our Website, you may asked to provide
                        certain personal information, including your name,
                        address, email address, phone numbers, occupation,
                        hobbies, interests, your likes and dislikes, personal
                        profile data and credit card or other financial
                        information for the conduct of transactions with us and
                        payments for services. Our Application and Website also
                        automatically receives and records information on our
                        server logs from your browser, including your IP
                        address, browser type and cookie information. We use
                        this information to personalize the content you see, to
                        fulfill your service requests, to inform you of updates
                        about your activities and changes to our services, to
                        alert you to news and activities about your account,
                        communications with other users of the Application and
                        Website and to offer any suggestions or other products
                        and services we believe may be of interest to you. We
                        may also collect information about your activities on
                        our Application and Website, communications and
                        interactions with us and with other users of our
                        services, as well as the feedback information that you
                        may volunteer to leave about us, other users, your
                        overall or other experiences. We may also collect
                        feedback from you or other users and regarding any
                        issues, problems or disputes that were reported to us.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>B. Aggregate Information</h5>
                      <p>
                        We may collect certain information about all users of
                        our Application and Website, such as the number of
                        visits to our Website or access out Application on any
                        computer or mobile device, what services of features
                        users access the most, what type of communications and
                        contacts are most popular among users, what type of
                        dining establishments or other venues are most
                        frequented by our users. We only use such data
                        anonymously and in the aggregate. This information helps
                        us create a better overall user experience for all users
                        of our Application, Website and services. We may share
                        this information with our Affiliates, but only in the
                        aggregate, so that they may also understand how our
                        visitors use our Application, website and services and
                        also to fix any problems or errors.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        C. Information and Data You have Uploaded and Saved{" "}
                      </h5>
                      <p>
                        We keep and maintain information and data, including
                        images, audio-visual data and information you have
                        uploaded through our Application or our Website (“Your
                        Content”). We keep Your Content with password and other
                        protections you have set up and will share Your Content
                        based on the privacy and access settings you provide
                        through our Application and/or Website for the access,
                        sharing, copying and distribution of Your Content to
                        others. We may make copies of Your Content for the
                        purposes of maintaining, upgrading, modifying or fixing
                        our Application and Website, and for the purposes of
                        providing and maintaining back-ups.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        D. Information and Data You Authorized Us to Access{" "}
                      </h5>
                      <p>
                        We keep and maintain information and data, including
                        selection of images, audio-visual data and information
                        you may have identified and selected from Your Content
                        and that you have allowed us to access, or allowed
                        others that use our Application and Website to access
                        (“Your Allowed Access Content”). For example, as part of
                        Your Allowed Access Content, you may identify and select
                        another person or company to have access to your data
                        and information through our Application and Website, or
                        that you requested us to access on your behalf.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        E. Authorization to Use Information and Data of Third
                        Parties
                      </h5>
                      <p>
                        If you place any information or data of a third party on
                        our Website or Application, or provide us with such
                        third party information or data for use a part of our
                        Application, Website or related services, you expressly
                        authorize us to access and use the third party
                        information and data in accordance with this Privacy
                        Policy and the accompanying Terms and Conditions (or End
                        User) Agreement. You also warranty and represent that
                        you have the authority to use the third party
                        information and data with our Application, Website or
                        related services and that you are fully responsible for
                        any complaints or claims by the third party whose
                        information and data you have used or allowed us or any
                        other party to use with, or as part of our Application,
                        Website or services in accordance with this Privacy
                        Policy and the accompanying Terms and Conditions (or End
                        User) Agreement.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h4>
                        What Information We Keep and Under What Circumstances?
                      </h4>
                    </div>
                    <div className="left term-outer">
                      <h5>General Disclosure</h5>
                      <p>
                        We do not sell or provide personally identifiable
                        information (PII) from our users to unrelated third
                        parties (which excludes our Affiliates and consultants)
                        without your consent, unless otherwise stated in this
                        Privacy Policy.
                      </p>
                      <p>
                        We may use personal information that you provide to us
                        as part of our services to you and to improve your
                        experience and enjoyment of the Application, Website and
                        provided services. For example, we may use SSL (“Secure
                        Socket Layer”) encryption, or other encryption
                        strategies to safeguard your credit card data when used
                        in E-commerce transactions, and we may utilize
                        encryption techniques to safeguard your username and
                        password used in connection with the Application and
                        Website.
                      </p>
                      <p>
                        We may record and share aggregate information with our
                        Affiliates.
                      </p>
                      <p>
                        We may also disclose your PII or aggregated information
                        about our users pursuant to a law, regulation or written
                        request from a governmental agency, or pursuant to a
                        subpoena or court order demanding and requiring such
                        disclosure. Furthermore, we may use any PII or aggregate
                        information about users in any action or lawsuit
                        involving us, our Application, Website or provided
                        services. We will make full effort to keep any legal
                        disclosures protected from the broad public
                        dissemination and subject to confidentiality obligations
                        protecting private and personal information.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        Sharing Information with our Affiliates and Consultants
                      </h5>
                      <p>
                        We may employ other companies or consultants to assist
                        us in performing services and functions on our behalf
                        (Affiliates). For example, companies or individuals may
                        provide or assist with hosting our Website, backup,
                        servers, verification of the users, analyzing data,
                        providing marketing services, processing credit card
                        payments and other business and financial transactions
                        and related services. These Affiliates may be granted
                        access to your PII and aggregate information as
                        necessary to perform their functions, but we will make
                        full effort to assure that your PII remains safe.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>Disclosure of Financial Transactions</h5>
                      <p>
                        It is your duty to make sure that your keep your
                        account(s) in good standing with us. If you have an
                        outstanding balance, or if you dispute you payment to us
                        with your credit card or financial institution for any
                        reason, or if you dispute any transaction carried out as
                        part of the provided services, please note that all
                        information collected from your activity on our Website,
                        your use of our software and related services may be
                        disclosed to the bank, collections agency we work with,
                        or to our authorized agents for the purposes of
                        establishing proof of user’s identify, proof of use,
                        proof that the service was provided, resolving dispute
                        and/or collecting any outstanding debt owed to us or
                        payments for the rendered services.
                      </p>
                      <p>
                        We may utilize third party services, such as for example
                        PayPal or credit card processing transaction servers for
                        any financial operations or similar operations, as part
                        of our services. To the extent necessary, your PII may
                        be shared with such third party Affiliates when
                        necessary to perform the intended transaction or
                        service, and to maintain the record of past processed
                        transactions.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>Cookies</h5>
                      <p>
                        To enhance your experience with the Application and
                        Website, and enable better services through the
                        Application and Website, we may use a feature on your
                        Internet browser called a “cookie.” Cookies are small
                        hidden files that your web browser places on your
                        computer’s hard drive. We use cookies for remembering
                        your preferences, profile data, communications, access
                        dates and time, tracking visits to understand better
                        visitor behavior, and planning for increased loads on
                        our servers. Because of our use of cookies, we can
                        deliver faster services, more efficient services through
                        our Application and Website, safer and quicker
                        transactions and a more personalized Application and
                        Website experience. You have the option of setting your
                        browser to reject cookies. However, doing so may hinder
                        your ability to logon or use member services offered on
                        our Application and Website.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        Limited Use of Information and Data After Termination of
                        Services
                      </h5>
                      <p>
                        We may keep certain PII you have given us for as long as
                        you remain an active user or member of our Application
                        and Website, or utilize our services. We may also keep
                        your PII for a certain limited time after the
                        termination of your services, primarily for accounting,
                        resolution of any pending disputes between you and any
                        other users of our services, for tax-related purposes
                        and to resolve any technical issues. We do not allow
                        your personal information, profile data or other PII
                        that you have not shared with others to be visible to
                        other users of our Application and Website once you have
                        terminated your services with us.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>Updating Account Information</h5>
                      <p>
                        You may update, modify or add personal information
                        through the Application and Website at any time. To do
                        so, connect through the Application or simply logon to
                        the Application and Website with your username and
                        password, and you will be able to update or modify the
                        information you have submitted. Please make sure that
                        your username and password are secured and protected,
                        and do not share or disclose it to anyone other than
                        yourself or your authorized persons.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h4>Do We Allow Unsolicited Contacts (ex. SPAM)?</h4>
                      <p>
                        As a general policy, we do not send unsolicited email,
                        texts or other communications containing offers of
                        products or services at any time. However, you may
                        consent to receive email messages, texts or other
                        communications regarding product or services that may be
                        interesting to you based on the PII you have provided to
                        us. You may opt-out of receiving such communications at
                        any time by sending an email or written request to the
                        provided contact on our Website or Application.
                      </p>
                      <p>
                        You may also choose to be notified by email, text or
                        other communications with any updates regarding your
                        activities through our Application and on our Website.
                        Such communications are not considered SPAM. However,
                        you may opt out of receiving any emails, text or other
                        communications unless you have connected through our
                        Application or our Website.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h4>Privacy Policy Changes</h4>
                      <p>
                        If we decide to change our privacy policy, we will post
                        those changes on our Website, as our Privacy Policy, so
                        that you will always have full disclosure about what PII
                        and other information we collect, how it might be used,
                        and under what circumstances we may disclose it to
                        anyone.
                      </p>
                      <p>
                        We use reasonable precautions to keep personal
                        information disclosed to us secure. However, we are not
                        responsible for any breach of security or wrongful acts
                        of third parties, or any disclosures you may make on
                        your own.
                      </p>
                      <p>
                        This privacy policy is not intended to -- and does not
                        -- create any contractual or other legal rights in or on
                        behalf of any party to this Agreement or any third
                        party. The terms of this Privacy Policy are subject to
                        the terms and conditions contained in the Terms and
                        Conditions (or End User) Agreement, governing the
                        relationship with and conduct of services with our
                        users.
                      </p>
                    </div>
                    <div className="left term-outer">
                      <h5>
                        We reserve the right to make changes to this Privacy
                        Statement at any time without prior notice and such
                        changes become effective once a revised Privacy Policy
                        is posted for our Application and on our Website. If you
                        continue use of our Application and/or Website after the
                        revisions are made to the Privacy Policy, it shall
                        constitute your acceptance of such changes.
                      </h5>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Footer />
      </HelmetProvider>
    );
  }
}
export default Privacypolicy;
