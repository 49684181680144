import React from 'react';
import MuiPhoneNumber from "material-ui-phone-number";
import { ValidatorComponent } from 'react-material-ui-form-validator';
 
class MuiPhoneNumberValidatorElement extends ValidatorComponent {

    renderValidatorComponent() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            containerProps,
            ...rest
        } = this.props;
        
    
        const { isValid } = this.state;
        return (
            <MuiPhoneNumber
                defaultCountry={'us'} 
                {...rest}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                
            />
        );
    }
}
 
export default MuiPhoneNumberValidatorElement;