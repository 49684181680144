import React from 'react';


class Logoutadmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        if(localStorage.getItem('accessTokenAdmin') !== null){
            localStorage.removeItem("accessTokenAdmin");
        }
        this.props.history.push("/admin-login")
       
    }
  
    render() {
      
        return (
            
            <div className="loader-min"><div className="loader"></div></div>
        );
    }
}
export default Logoutadmin;
