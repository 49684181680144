import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Welcome to admin dashboard
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
