import { useCallback, useState } from 'react';

const useAlertMessage = () => {
    const [alertMessage, setAlertMessage] = useState({
        openAlert: false,
        alertSeverity: '',
        message: '',
    });

    const showAlert = useCallback(({ message, alertSeverity }) =>
        setAlertMessage(prevState => ({
            ...prevState,
            message: message,
            alertSeverity: alertSeverity,
            openAlert: true,
        })), [setAlertMessage]);

    const hideAlert = useCallback(() =>
        setAlertMessage(prevState => ({
            ...prevState,
            openAlert: false,
            alertSeverity: '',
            message: '',
        })), [setAlertMessage]);

    const errorMessage = useCallback((message = "Something went wrong!, Please try again.", alertSeverity = "error") => {
        showAlert({
            message: message || 'Something went wrong',
            alertSeverity: alertSeverity || "error",
        });
    }, [showAlert]);

    return {
        alertMessage,
        showAlert,
        hideAlert,
        errorMessage,
    };
};

export default useAlertMessage;
