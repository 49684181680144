// Reducer for refreshing vendor Event
import { ActionTypes } from "../types/ActionTypes";
const initialState = {
    RefreshvendorEvent: [],
}

export const RefreshVendorReducer = (state= initialState, action) => {
    switch (action.type) {
        case ActionTypes.REFRESHVENDOREVENT:
            return {
                ...state,
                RefreshvendorEvent: action.payload
            }
        default:
            return state;
    }
}
