export const saveTime = (name, time) => {
  return {
      type: "SAVE_TIME",
      payload: {
        name: name,
        time: time,
      },
    };
  };

