import React from "react";
import { Helmet } from "react-helmet";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import customDateFormat from "utils/customDateFormat";
import "./vendorevents.scss";
import { connect } from "react-redux";
import { ScanVendorEvent } from "redux/actions/ScanApiCall";
import { RefreshVendorEvent } from "redux/actions/RefreshApiCall";
import { HiOutlineSearch } from "react-icons/hi";
import AddIcon from "@mui/icons-material/Add";
import AlertMessage from "components/message/message";
import { SaveTotalTimeAction } from "redux/actions/SaveTotalTimeAction";
import {
  Checkbox,
  Card,
  CardContent,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "react-datepicker";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import DataTable from "components/DataTable/DataTable";
import ExpiredTag from "components/ExpiredTag";
import { vendorDateFormat } from "utils/vendorDateFormat";
import Cookies from "js-cookie";
import TimerButton from "utils/TimerButton";
import AddEvent from "./addEvent";
import { ContainerLoader } from "components/common/ContainerLoader";
import moment from "moment";
import { ArrowBack } from "@mui/icons-material";

const moments = extendMoment(originalMoment);

class VendorEventslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      open: false,
      message: "",
      alertcolor: "error",
      lists: [],
      vendor: {},
      filterlists: [],
      scanevents: [],
      scanmodalshow: false,
      deleteModalShow: false,
      crossCheck: true,
      deleteId: "",
      selectedDate: null,
      selectedendDate: null,
      refreshConfirm: false,
      refreshAutoScanConfirm: false,
      refreshAutoScanRefresh: false,
      formData: {
        title: "",
        startDate: null,
        endDate: null,
        skip: 0,
        status: "",
      },
      scanEventName: '',
      monthsShown: 2,
      scaningTime: 0,
      scanDate: null,
      scanDisabled: false,
      isAddEventVisiable: false
    };
    this.ScanDisabledEventsShow = this.ScanDisabledEventsShow.bind(this);
    this.onScanModalHide = this.onScanModalHide.bind(this);
    this.scanDispatchEvent = this.scanDispatchEvent.bind(this);
    this.refreshDispatchEvent = this.refreshDispatchEvent.bind(this);
    this.refreshConfirmHide = this.refreshConfirmHide.bind(this);
    this.onDeleteModalToggle = this.onDeleteModalToggle.bind(this);
    this.callDelete = this.callDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.divRef = React.createRef();
    this.timerRef = React.createRef();
  }

  handlepageMove = () => {
    this.setState({ pagedisable: true })
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }
    this.getLists();
  }
  refreshConfirmHide = () => {
    this.setState({
      refreshConfirm: false,
      refreshAutoScanConfirm: false,
      refreshAutoScanRefresh: false,
      scanEventName: ''
    });
  };

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getAccountEvents";
    axios
      .post(
        list_api_url,
        { id: this.props.match.params.id },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          const { vendorinfo } = response.data;
          const { nextRefresh } = vendorinfo;
          const lastRefreshTime = new Date(nextRefresh);
          const seconds = Math.floor(lastRefreshTime % 60)
          this.props.setTimerLimit("scaningTime", seconds);
          this.setState({ scaningTime: seconds })
          this.setState({ scanDate: nextRefresh });
          const formatData = data.data.map((item) => {
            const dateValid = item.date ? vendorDateFormat(item.date) : null;
            return {
              ...item,
              dateValid,
            };
          });

          const emptydateEvents = formatData.filter((item) => {
            return item.dateValid === "";
          });

          const today = new Date();
          today.setDate(today.getDate() - 2);

          const filteredData = formatData.filter((item) => {
            return (
              item.dateValid === null ||
              item.dateValid.valueOf() >= today.valueOf()
            );
          });

          const expiredData = formatData.filter((item) => {
            return (
              item.dateValid !== null &&
              item.dateValid.valueOf() < today.valueOf() &&
              item.dateValid !== ""
            );
          });

          this.setState(
            {
              scanDate: vendorinfo.nextRefresh,
            },
            () => {
              this.props.setTimerLimit(
                "scanDate",
                vendorinfo.nextRefresh
              );
              setTimeout(() => {
                this.setState({ loader: false });
              }, 1000);
            }
          );
          this.setState({
            lists: [...filteredData, ...emptydateEvents, ...expiredData],
            vendor: data.vendorinfo,
            filterlists: [...filteredData, ...emptydateEvents, ...expiredData],
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { title, startDate, endDate, status } = formData;
    const getTimezoneOffset = new Date().getTimezoneOffset() / 60;

    const filterlists = await this.state.lists.filter((item) => {
      const { dateValid, event_name } = item;
      const eventDate =
        getTimezoneOffset > 0
          ? moments(dateValid).subtract(getTimezoneOffset, "hours")
          : moments(dateValid).add(getTimezoneOffset, "hours");

      const searchDate = moments(this.state.selectedDate);
      if (startDate !== null && endDate === null) {
        eventDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return (
          event_name.toLowerCase().includes(title.toLowerCase()) &&
          eventDate.isSame(searchDate, "day") &&
          item.status.includes(status)
        );
      }
      if (startDate !== null && endDate !== null) {
        return (
          event_name.toLowerCase().includes(title.toLowerCase()) &&
          eventDate.isSameOrAfter(searchDate, "day") &&
          eventDate.isSameOrBefore(moments(this.state.selectedendDate), "day")
        );
      }
      if (startDate === null && endDate === null) {
        return event_name.toLowerCase().includes(title.toLowerCase());
      }
      return false;
    });

    const scanStatus = await filterlists.filter((item) => {
      return status === "others"
        ? !item.status.includes("Scan for Tickets") &&
        !item.status.includes("Scan Completed")
        : status === "all" || item.status.includes(status);
    });
    this.setState({ filterlists: scanStatus });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  chooseEventForScan = (item, evt) => {
    const { scanevents } = this.state;

    if (evt.target.checked) {
      if (scanevents.length < 10) {
        const updatedEvents = [...scanevents, item._id];
        this.setState({ scanevents: updatedEvents });
      } else {
        this.setState({
          open: true,
          message: "You can select a maximum of 10 events at a time",
          successError: "error",
        });
      }
    } else {
      const updatedEvents = scanevents.filter((event) => event !== item._id);
      this.setState({ scanevents: updatedEvents });
    }
  };

  chooseAllEventsForScan = (evtVal) => {
    if (evtVal) {
      const selectedEvents = [];
      const filteredData = [];
      const scanFailedEvents = [];
      const scanCompletedAndTotalTicketsEvents = [];

      document.querySelectorAll("tbody tr").forEach((row, index) => {
        const checkbox = row.querySelector("input[type='checkbox']");

        const status = JSON.parse(checkbox.getAttribute("name"));
        const id = checkbox.getAttribute("id");

        if (status && status.status === "Scan for Tickets" && id !== null) {
          filteredData.push(status._id);
        } else if (
          status &&
          status.status !== "Scan for Tickets" &&
          !status.status.includes("Scan Completed") &&
          id !== null
        ) {
          scanFailedEvents.push(status._id);
        } else if (
          status &&
          status.status.includes("Scan Completed") && // Check for "Scan Completed" status
          status.num_tickets === 0 && // Check for 0 total tickets
          id !== null
        ) {
          scanCompletedAndTotalTicketsEvents.push(status._id); // Prioritize "Scan Completed" with 0 total tickets
        } else if (
          status &&
          status.status !== "Scan for Tickets" &&
          id !== null
        ) {
          selectedEvents.push(status._id);
        }
      });
      const remainingRecords = Math.max(0, 10 - filteredData.length);
      const additionalEvents = selectedEvents.slice(0, remainingRecords);
      const updatedEvents = [
        ...filteredData,
        ...scanFailedEvents,
        ...scanCompletedAndTotalTicketsEvents, // Include prioritized "Scan Completed" events
        ...additionalEvents,
      ];

      this.setState({ scanevents: updatedEvents.slice(0, 10) });
    } else {
      this.setState({ scanevents: [] });
    }
  };


  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleChangeRefreshAutoScan = (event) => {
    this.setState({ scanEventName: event.target.value });
  };

  handlePostRefreshAutoScan = async (id) => {
    const refresh_auto_scan_api = process.env.REACT_APP_API_URL + "/api/account/refreshAndAutoscanVendor";
    try {
      const params = {
        id: id,
        ...(this.state.vendor.vendor === "Ticketmaster" && { scanEventName: this.state.scanEventName }),
      };
      const response = await axios.post(refresh_auto_scan_api, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        }
      })
      const { data } = response;
      if (data.error) {
        this.setState({
          loader: false,
          open: true,
          message: data.message,
          alertcolor: "error",
        });
      } else {
        this.setState({
          loader: false,
          open: true,
          message: data.message,
          alertcolor: "success",
        });
        this.getLists();
      }
    } catch (error) {
      this.setState({
        loader: false,
        open: true,
        message: "Something went wrong",
        alertcolor: "error",
      });
    }

  }

  scanEventSubmit = (type) => {
    const { scanevents } = this.state;

    if (scanevents.length > 0) {
      this.setState({ loader: true }, () => {
        const scan_api_url =
          process.env.REACT_APP_API_URL + "/api/account/scanAccountEvents";
        axios
          .post(
            scan_api_url,
            { scanevents: scanevents },
            {
              headers: {
                authorization: "Bearer " + Cookies.get("accessToken"),
              },
            }
          )
          .then((response) => {
            const { data } = response;

            if (data.error) {
              this.setState({
                loader: false,
                open: true,
                message: data.message,
                alertcolor: "error",
              });
            } else {
              const message =
                data.total === 0
                  ? "Accounts Scan Recently please try again after some time"
                  : `Total ${data.total} ${data.message}`;
              this.setState({
                open: true,
                message: type === "single" ? data.message : message,
                alertcolor:
                  type === "multiple" && data.total === 0 ? "error" : "success",
              });
              this.setState({ scanevents: [] });
              this.getLists();
            }
          })
          .catch((error) => {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          });
      });
    } else {
      this.setState({
        open: true,
        message: "Please select event to scan",
        alertcolor: "error",
      });
    }
  };
  clearDate = () => {
    const { formData } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    formData["startDate"] = null;
    formData["endDate"] = null;
    this.setState({ formData, crossCheck: true });
  };
  ScanDisabledEventsShow = (id) => {
    this.setState({ scanmodalshow: true });
    const { scanevents } = this.state;
    scanevents.push(id);
    this.setState({ scanevents });
  };
  onScanModalHide = () => {
    this.setState({ scanmodalshow: false, scanevents: [] });
  };

  // Scan Event
  scanDispatchEvent = async (id) => {
    this.setState({ loader: true });
    this.props.VendorEventScan({
      id: id,
    })
      .then(() => {
        this.setState({
          open: true,
          message: "Scan Event Successfully",
          alertcolor: "success",
        });
        this.getLists();
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };

  setStartDate = (dates) => {
    const { formData } = this.state;
    const [start, end] = dates;
    // const getstart = start.valueOf()+hours+mins
    this.setState({ selectedendDate: end, selectedDate: start });

    // fomating date to isoString
    const datestart = moments(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moments(end).format("YYYY-MM-DDTHH:mm:ss");
    formData["startDate"] = datestart + ".000Z";

    if (end) {
      formData["endDate"] = dateend + ".000Z";
    } else {
      formData["endDate"] = null;
    }

    this.setState({ formData, crossCheck: false });
  };

  // Refresh Event

  refreshDispatchEvent = (id) => {
    this.setState({ loader: true });
    this.props
      .VendorEventRefresh({
        id: id,
      })
      .then(() => {
        this.setState({
          open: true,
          message: "Refresh Event Successfully",
          alertcolor: "success",
        });
        this.getLists();
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };

  onDeleteModalToggle = (msg, id) => {
    const { deleteModalShow } = this.state;
    this.setState({ deleteModalShow: !deleteModalShow });
    if (msg === "delete") {
      this.setState({ deleteId: id });
    } else {
      this.setState({ deleteId: "" });
    }
  };

  callDelete = async () => {
    this.setState({
      modalShow: false,
    });
    const data = {
      id: this.state.deleteId,
    };
    const userDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/account/deleteAccount";
    await axios
      .post(userDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          this.props.history.push("/vendor-list");
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleAddEventPageVisiable = () => {
    this.setState({ isAddEventVisiable: true })
  }

  handleAddEventPageUnVisiable = () => {
    this.setState({ isAddEventVisiable: false })
  }
  render() {
    const { filterlists, loader, scanevents, vendor, formData, crossCheck } = this.state;
    const fields = [
      { key: "event_name", label: "Title" },
      { key: "date", label: "Date/Time" },
      { key: "location", label: "Location" },
      { key: "available", label: "Available" },
      { key: "sold", label: "Sold", },
      { key: "status", label: "Status" },
      { key: "Actions", label: "Actions" },
      { key: "Scan", label: "Scan" },
    ];

    if (vendor.vendor === "AXS") {
      fields.pop({ key: "Scan", label: "Scan" });
    }
    var today = new Date();
    today.setDate(today.getDate() - 2);

    const btnStyle = {
      backgroundColor: (theme) => theme.palette.primary.main,
      color: "white!important",
      textTransform: "none!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
      borderRadius: "4px!important",
    };
    const timerStyles = {
      actionbutton: {
        backgroundColor: (theme) => theme.palette.primary.main,
        color: "white!important",
        textTransform: "none!important",
        fontWeight: "bold",
        fontFamily: "Barlow!important",
        margin: "0 5px",
        borderRadius: "4px!important",
      },
      diabledbutton: {
        color: "white!important",
        textTransform: "none!important",
        fontWeight: "bold",
        fontFamily: "Barlow!important",
        margin: "0 5px",
        borderRadius: "4px!important",
        border: "none!important",
      },
    };

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Vendor Events List</title>
          </Helmet>

          <Header />
          <div className="c-app c-default-layout user-dashboard-body vendorlist">
            <Usersidebar />
            {!this.state.isAddEventVisiable ? (
              <div className="c-wrapper">
                <div className="c-body">
                  <main className="c-main">
                    {!loader ? (
                      <>
                        <AlertMessage
                          key={`alertbtn`}
                          open={this.state.open}
                          message={this.state.message}
                          severity={this.state.alertcolor}
                          closeCall={this.handleClose}
                        />
                        <Grid container spacing={3}>
                          <Grid item={true} xs={12} className="space-top-col">
                            <Card
                              sx={{
                                width: "100%",
                                boxShadow:
                                  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                borderRadius: "4px",
                                height: "100%",
                                padding: "30px",
                              }}
                            >
                              <Grid container spacing={3}>
                                <Grid
                                  item={true}
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "24px",
                                      fontWeight: "700",
                                      color: "#000059",
                                      textAlign: "left",
                                    }}
                                  >
                                    {" "}
                                    Vendor Events
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  className="align-buttons"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "10px 10px",
                                      borderRadius: "4px",
                                      boxShadow:
                                        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                    }}
                                  >
                                    <Typography
                                      variant="p"
                                      gutterBottom
                                      align="center"
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "#000000",
                                      }}
                                    >
                                      Account Settings
                                    </Typography>
                                    <ButtonGroup
                                      variant="contained"
                                      aria-label="Account Settings"
                                      sx={{
                                        boxShadow: "none",
                                        "& button": {
                                          marginTop: {
                                            xs: "20px",
                                            sm: "20px",
                                            md: "20px",
                                            height: "max-content",
                                          },
                                        },
                                        "& a": {
                                          marginTop: {
                                            xs: "20px",
                                            sm: "20px",
                                            md: "20px"
                                          },
                                          maxHeight: "37px"
                                        },
                                        flexWrap: {
                                          xs: "wrap",
                                          sm: "wrap",
                                          md: "wrap",
                                          lg: "nowrap",
                                        }
                                      }}
                                    >
                                      <Button
                                        component={Link}
                                        to={`/edit-vendor/${this.props.match.params.id}`}
                                        sx={btnStyle}
                                      >
                                        Edit
                                      </Button>
                                      <TimerButton
                                        apiCall={() => this.scanDispatchEvent(this.props.match.params.id)}
                                        date={vendor.nextScan}
                                        name="Sync"
                                        item={this.props.match.params.id}
                                        id={`scan-${this.props.match.params.id}`}
                                        styles={timerStyles}
                                        size="medium"
                                        time={0}
                                        timeinseconds={0}
                                      />
                                      <TimerButton
                                        date={vendor.nextRefresh}
                                        name="Refresh"
                                        item={this.props.match.params.id}
                                        id={`refresh-${this.props.match.params.id}`}
                                        apiCall={() =>
                                          this.setState({ refreshConfirm: true })
                                        }
                                        time={this.state.scaningTime}
                                        timeinseconds={0}
                                        styles={timerStyles}
                                        size="medium"
                                      />
                                      {this.state.vendor.vendor !== "AXS" && (
                                        <TimerButton
                                          item={this.props.match.params.id ?? null}
                                          id={`refresh-${this.props.match.params.id ?? null}`}
                                          date={vendor.nextRefresh ?? null}
                                          time={this.state.scaningTime ?? null}
                                          name="Refresh & Auto Scan"
                                          apiCall={() =>
                                            this.state.vendor.vendor === "Ticketmaster" ?
                                              this.setState({ refreshAutoScanConfirm: true })
                                              :
                                              this.setState({ refreshAutoScanRefresh: true })
                                          }
                                          timeinseconds={0}
                                          styles={timerStyles}
                                          size="medium"
                                        />
                                      )}
                                      <Button
                                        sx={btnStyle}
                                        onClick={() =>
                                          this.onDeleteModalToggle(
                                            "delete",
                                            this.props.match.params.id
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                      <Button
                                        sx={btnStyle}
                                        component={Link}
                                        to={`/account-logs/${this.props.match.params.id}`}
                                      >
                                        Logs
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </Grid>
                                <Grid
                                  item={true}
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  sx={{
                                    textAlign: "right",
                                  }}
                                >
                                  {this.state.vendor.vendor !== "AXS" && (
                                    <Button
                                      size="large"
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                      }}
                                      type="button"
                                      startIcon={<AddIcon />}
                                      onClick={this.handleAddEventPageVisiable}
                                    >
                                      Add Event
                                    </Button>
                                  )}
                                  &nbsp;&nbsp;
                                  <Button
                                    type="button"
                                    onClick={()=> this.props.history.goBack()}
                                    size="large"
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#002986!important",
                                      color: "white!important",
                                      textTransform: "none!important",
                                      fontWeight: "bold",
                                      fontFamily: "Barlow!important",
                                    }}
                                    startIcon={<ArrowBack />}
                                  >
                                    Back
                                  </Button>
                                </Grid>
                              </Grid>
                              <div
                                className="row"
                                style={{
                                  marginTop: "30px",
                                }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <div>
                                    {vendor.league && (
                                      <span style={{ paddingRight: "20px" }}>
                                        <strong>League: </strong>
                                        {vendor.league}
                                      </span>
                                    )}

                                    {vendor.team && (
                                      <span style={{ paddingRight: "20px" }}>
                                        <strong>Team: </strong>
                                        {vendor.team}
                                      </span>
                                    )}

                                    {vendor.username && (
                                      <span style={{ paddingRight: "20px" }}>
                                        <strong>Email: </strong>
                                        {vendor.username}
                                      </span>
                                    )}

                                    {vendor.event && (
                                      <span>
                                        {vendor.event.status.includes(
                                          "Scan Completed: Events"
                                        ) &&
                                          vendor.fetchingevent_scheduler !==
                                          "finished" ? (
                                          <>
                                            <strong>Status: </strong>Scan Completed:{" "}
                                            {filterlists.length > 0
                                              ? "loading more events"
                                              : "loading events"}
                                          </>
                                        ) : (
                                          <>
                                            <strong>Status: </strong>
                                            {vendor.event.status}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <CardContent
                                className="plan-tbl vendor-outer"
                                sx={{ padding: "0px" }}
                              >
                                <ValidatorForm
                                  onSubmit={this.handleSubmit}
                                  className="filter-form my-4"
                                >
                                  <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                      container
                                      spacing={{ xs: 2, md: 2, lg: 2 }}
                                      columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                      <Grid item xs={12} sm={4} md={3} lg={3}>
                                        <TextField
                                          variant="outlined"
                                          onChange={(e) => this.handleChange(e)}
                                          name="title"
                                          placeholder="Search Title"
                                          value={formData?.title}
                                          className="filter-input"
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={2.6} lg={2.6}>
                                        <DatePicker
                                          placeholderText="Search Date"
                                          selected={this.state.selectedDate}
                                          startDate={this.state.selectedDate}
                                          endDate={this.state.selectedendDate}
                                          monthsShown={this.state.monthsShown}
                                          selectsRange
                                          isClearable={true}
                                          customInput={
                                            <TextField
                                              variant="outlined"
                                              className="filter-input"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    className="date-icon"
                                                  >
                                                    <div className="d-flex">
                                                      {crossCheck ? (
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          edge="end"
                                                        >
                                                          <EventIcon className="text-dark" />
                                                        </IconButton>
                                                      ) : (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() =>
                                                            this.clearDate()
                                                          }
                                                        >
                                                          <ClearIcon className="text-dark" />
                                                        </IconButton>
                                                      )}
                                                    </div>
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          }
                                          onChange={(dates) =>
                                            this.setStartDate(dates)
                                          }
                                        //  filterDate={(date) => {
                                        //      return date >= new Date();
                                        //  }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                          <InputLabel
                                            id="demo-simple-select-label"
                                            className="select-status"
                                          >
                                            {formData?.status === "" && "Status"}
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="status"
                                            onChange={(e) => {
                                              this.handleChange(e);
                                            }}
                                            placeholder="Status"
                                            size="small"
                                            value={formData?.status}
                                            defaultValue=""
                                            sx={{
                                              textAlign: "left",
                                            }}
                                          >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="Scan for Tickets">
                                              Scan for Tickets
                                            </MenuItem>
                                            <MenuItem value="Scan Completed">
                                              Scan Completed
                                            </MenuItem>
                                            <MenuItem value="others">Others</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid>

                                      <Grid item xs={12} sm={4} md={2} lg={2}>
                                        <Button
                                          variant="contained"
                                          type="submit"
                                          className="filter-btn"
                                          startIcon={<HiOutlineSearch />}
                                        >
                                          Search
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </ValidatorForm>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginBottom: "5px",
                                    lineHeight: "38px",
                                  }}
                                >
                                  {scanevents.length > 0 && (
                                    <TimerButton
                                      apiCall={() => this.scanEventSubmit("multiple")}
                                      date={this.props.scanDate ?? 0}
                                      id={`event-scan${this.props.match.params.id}`}
                                      name="Scan Selected"
                                      timeinseconds={0}
                                      time={this.state.scaningTime}
                                      buttonType="button"
                                      variant="contained"
                                      size="medium"
                                      styles={{
                                        actionbutton: {
                                          backgroundColor: "#002986!important",
                                          color: "white!important",
                                          textTransform: "none!important",
                                          fontFamily: "Barlow!important",
                                          margin: "0 5px",
                                        },
                                        diabledbutton: {
                                          backgroundColor: "#B60000!important",
                                          textTransform: "none!important",
                                          color: "white!important",
                                          fontFamily: "Barlow!important",
                                          margin: "0 5px",
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                  )}
                                  {this.state.vendor.vendor !== "AXS" && (
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        this.chooseAllEventsForScan(
                                          scanevents.length > 0 ? false : true
                                        )
                                      }
                                      disabled={filterlists.length === 0}
                                    >
                                      {scanevents.length > 0
                                        ? "Un-Select All"
                                        : "Select All"}
                                    </Button>
                                  )}
                                </div>
                                <DataTable
                                  items={filterlists}
                                  fields={fields}
                                  id="vendor-table"
                                  itemsPerPage={this.props.pageLimit}
                                  paginationLimit
                                  pagination
                                  pageName="vendorEvents"
                                  scopedSlots={{
                                    event_name: (item) => {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {this.state.vendor.vendor !== "AXS" && (
                                            <TimerButton
                                              apiCall={() =>
                                                this.ScanDisabledEventsShow(item._id)
                                              }
                                              date={this.props.scanDate ?? ""}
                                              item={item}
                                              id={`event-scan${this.props.match.params.id}`}
                                              name="Scan"
                                              time={this.state.scaningTime}
                                              buttonType="Icon"
                                              timeinseconds={0}
                                            />
                                          )}
                                          <Typography
                                            sx={{
                                              fontFamily: "Barlow",
                                              marginLeft: "15px",
                                            }}
                                          >
                                            {item.event_name}
                                          </Typography>
                                        </Box>
                                      );
                                    },
                                    available: (item) => {
                                      const totalTickets = parseInt(item.num_tickets) || 0;
                                      const soldTickets = parseInt(item.sold_tickets) || 0;
                                      return totalTickets - soldTickets;
                                    },
                                    sold: (item) => item.sold_tickets ?? 0,
                                    date: (item) => {
                                      return (
                                        <div className="align-scan">
                                          <div
                                            style={{
                                              width: "100%",
                                              minWidth: "max-content",
                                            }}
                                          >
                                            {item.dateValid === ""
                                              ? item.date
                                              : customDateFormat(item.date)}
                                          </div>
                                          {today > new Date(item.dateValid) && (
                                            <ExpiredTag />
                                          )}
                                        </div>
                                      );
                                    },
                                    status: (item) => {
                                      return item.status.includes(
                                        "Scan Completed:"
                                      ) &&
                                        item.fetch_tickets_scheduler === "start" ? (
                                        <>Scan Completed: loading tickets</>
                                      ) : (
                                        <>
                                          {item.status === "loaded" &&
                                            item.num_tickets > 0 ? (
                                            <>
                                              Scan Completed: Tickets Added:{" "}
                                              {item.num_tickets}
                                            </>
                                          ) : (
                                            <>{item.status}</>
                                          )}
                                        </>
                                      );
                                    },
                                    Actions: (item) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            maxWidth: "max-content",
                                          }}
                                        >
                                          <Button
                                            component={Link}
                                            size="small"
                                            variant="contained"
                                            sx={{
                                              backgroundColor: "#002986!important",
                                              color: "white!important",
                                              padding: "2px 5px!important",
                                              textTransform: "none!important",
                                              fontWeight: "bold",
                                              fontFamily: "Barlow!important",
                                              marginRight: "5px",
                                            }}
                                            to={`/vendor-event-tickets/${item._id}`}
                                          >
                                            View
                                          </Button>
                                          {this.state.vendor.vendor !== "AXS" && (
                                            <Button
                                              component={Link}
                                              size="small"
                                              variant="contained"
                                              sx={{
                                                backgroundColor: "#002986!important",
                                                color: "white!important",
                                                padding: "2px 5px!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                              }}
                                              to={`/edit-event/${item._id}`}
                                            >
                                              Edit
                                            </Button>
                                          )}
                                        </div>
                                      );
                                    },
                                    Scan: (item) => {
                                      return (
                                        <Checkbox
                                          ref={this.divRef}
                                          size="small"
                                          status={item.status}
                                          id={
                                            today > new Date(item.dateValid)
                                              ? null
                                              : `event${item._id}`
                                          }
                                          name={
                                            today >= new Date(item.dateValid)
                                              ? null
                                              : JSON.stringify(item)
                                          }
                                          checked={scanevents.includes(item._id)}
                                          disabled={
                                            today > new Date(item.dateValid)
                                              ? true
                                              : this.state.scanDisabled
                                          }
                                          onChange={(evt) =>
                                            this.chooseEventForScan(item, evt)
                                          }
                                        />
                                      );
                                    },
                                  }}
                                />
                              </CardContent>
                            </Card>
                            {/* Refresh & Auto Scan send Event Name confirmation box start   */}
                            <Dialog
                              maxWidth="sm"
                              fullWidth={true}
                              open={this.state.refreshAutoScanConfirm}
                              onClose={this.refreshConfirmHide}
                            >
                              <ValidatorForm onSubmit={() => {
                                this.handlePostRefreshAutoScan(
                                  this.props.match.params.id
                                );
                                this.refreshConfirmHide();
                              }}>
                                <DialogTitle
                                  sx={{
                                    color: (theme) => theme.palette.primary.main,
                                    fontWeight: "600",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                    fontSize: "20px",
                                  }}
                                >
                                  Refresh & Auto Scan
                                </DialogTitle>
                                <DialogContent>
                                  <IconButton
                                    aria-label="close"
                                    onClick={this.refreshConfirmHide}
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      color: (theme) => theme.palette.grey[500],
                                      p: 1,
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                  <DialogContentText
                                    sx={{
                                      fontFamily: "Barlow",
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      color: "black",
                                    }}
                                  >
                                    <label>Event Name</label>
                                  </DialogContentText>
                                  <TextValidator
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleChangeRefreshAutoScan}
                                    name="scanEventName"
                                    value={this.state.scanEventName}
                                    validators={['required']}
                                    errorMessages={['Event Name is required']}
                                  />
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={this.refreshConfirmHide}
                                    sx={{
                                      backgroundColor: "#002986!important",
                                      color: "white!important",
                                      fontWeight: "bold",
                                      fontFamily: "Barlow",
                                      textTransform: "none",
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type="submit"
                                    sx={{
                                      backgroundColor: "#002986!important",
                                      color: "white!important",
                                      fontWeight: "bold",
                                      fontFamily: "Barlow",
                                      textTransform: "none",
                                    }}
                                  >
                                    Continue
                                  </Button>
                                </DialogActions>
                              </ValidatorForm>
                            </Dialog>
                            {/* Refresh & Auto Scan send Event Name confirmation box end */}
                            {/* Refresh & Auto Scan confirm */}
                            <Dialog
                              maxWidth="sm"
                              fullWidth={true}
                              open={this.state.refreshAutoScanRefresh}
                              onClose={this.refreshConfirmHide}
                            >
                              <DialogTitle
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  fontWeight: "600",
                                  fontFamily: "Barlow",
                                  textTransform: "none",
                                  fontSize: "20px",
                                }}
                              >
                                Refresh & Auto Scan
                              </DialogTitle>
                              <DialogContent>
                                <IconButton
                                  aria-label="close"
                                  onClick={this.refreshConfirmHide}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                                <DialogContentText
                                  sx={{
                                    fontFamily: "Barlow",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    color: "black",
                                  }}
                                >
                                  Are you sure you want to Refresh and Auto Scan ?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.refreshConfirmHide}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.handlePostRefreshAutoScan(
                                      this.props.match.params.id
                                    );
                                    this.refreshConfirmHide();
                                  }}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Continue
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Refresh & Auto Scan confirm */}

                            {/* Scan Confirmation Modal */}
                            <Dialog
                              maxWidth="sm"
                              fullWidth={true}
                              open={this.state.scanmodalshow}
                              onClose={this.onScanModalHide}
                            >
                              <DialogTitle>Scan</DialogTitle>
                              <DialogContent>
                                <IconButton
                                  aria-label="close"
                                  onClick={this.onScanModalHide}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                                <DialogContentText
                                  sx={{
                                    fontFamily: "Barlow",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    color: "black",
                                  }}
                                >
                                  Are you sure you want to Scan
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.onScanModalHide}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.scanEventSubmit("single");
                                    this.onScanModalHide();
                                  }}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Scan
                                </Button>
                              </DialogActions>
                            </Dialog>
                            {/* Refresh Confirmation Modal */}
                            <Dialog
                              maxWidth="sm"
                              fullWidth={true}
                              open={this.state.refreshConfirm}
                              onClose={this.refreshConfirmHide}
                            >
                              <DialogTitle
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  fontWeight: "600",
                                  fontFamily: "Barlow",
                                  textTransform: "none",
                                  fontSize: "20px",
                                }}
                              >
                                Refresh
                              </DialogTitle>
                              <DialogContent>
                                <IconButton
                                  aria-label="close"
                                  onClick={this.refreshConfirmHide}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                                <DialogContentText
                                  sx={{
                                    fontFamily: "Barlow",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    color: "black",
                                  }}
                                >
                                  Are you sure you want to Refresh ?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.refreshConfirmHide}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>

                                <Button
                                  onClick={() => {
                                    this.refreshDispatchEvent(
                                      this.props.match.params.id
                                    );
                                    this.refreshConfirmHide();
                                  }}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Refresh
                                </Button>
                              </DialogActions>
                            </Dialog>

                            {/* Delete Modal  */}

                            <Dialog
                              maxWidth="sm"
                              open={this.state.deleteModalShow}
                              onClose={() => this.onDeleteModalToggle("cancel", null)}
                            >
                              <DialogTitle>Delete</DialogTitle>
                              <DialogContent>
                                <IconButton
                                  aria-label="close"
                                  onClick={() =>
                                    this.onDeleteModalToggle("cancel", null)
                                  }
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                                <DialogContentText>
                                  Are you sure you want to Delete, it can cause all
                                  the links generated from that account to become
                                  invalid ?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() =>
                                    this.onDeleteModalToggle("cancel", null)
                                  }
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={this.callDelete}
                                  sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none",
                                  }}
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <ContainerLoader />
                    )}
                  </main>
                </div>
              </div>
            ) : (
              <AddEvent
                handleAddEventPageUnVisiable={this.handleAddEventPageUnVisiable}
                paramsId={this.props.match.params.id}
              />)
            }
          </div>
          <Footer />
        </div >
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageLimit: state.Page.vendorEvents,
    scanDate: state.timerDuration.scanDate,
    scaningTime: state.timerDuration.scaningTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    VendorEventScan: (data) => dispatch(ScanVendorEvent(data)),
    VendorEventRefresh: (data) => dispatch(RefreshVendorEvent(data)),
    setTimerLimit: (name, time) => dispatch(SaveTotalTimeAction(name, time)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(VendorEventslist));
