import React from 'react';
import { Helmet } from "react-helmet";
import Header from 'components/header/header';
import Footer from 'components/footer/footer'
import 'pages/userdashboard/userdashboard.scss';
import Usersidebar from 'components/user-sidebar/user-sidebar';
import {CCard, CCardBody, CCol, CRow} from '@coreui/react';

class Addcredit extends React.Component{
    render(){
        return(
            <div className="user-dashboard-wrapper">
                <Helmet>
                    <title>
                       Add Credit
                    </title>
                </Helmet>
                <Header />

                <div className="c-app c-default-layout user-dashboard-body">
                    <Usersidebar />
                    
                    <div className="c-wrapper">
                        <div className="c-body">
                            <CRow>
                                    <CCol xs="12" lg="12">
                                    <CCard>
                                        <CCardBody>
                                           Add Credit
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Addcredit;