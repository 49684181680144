import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import PageLoader from "components/PageLoader/PageLoader";
import customDateFormatLocal from "utils/customDateFormatLocal";
import UserProfileAction from "redux/actions/UserProfielAction";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import Cookies from "js-cookie";

class SkyboxScanJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      loader: true,
      open: false,
      message: "",
      crossCheck: true,
      successError: "success",
      pageLimit: 20,
      timezone: null,
    };
  }

  componentDidMount() {
    // //Api Code
    this.getTimezone();
    this.getData();
  }

  getTimezone = () => {
    if (this.props.UserProfile.data !== undefined) {
      this.setState({
        pageLimit: this.props.UserProfile.data.per_page_limit,
        timezone: this.props.UserProfile.data.timezone,
      });
    }
  };

  getData = () => {
    const api_url = process.env.REACT_APP_API_URL + "/api/user/getScanjobs";
    const filterData = {
      scanid: this.props.match.params.id,
      scantype: "Skybox",
    };
    axios
      .post(api_url, filterData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({ loader: false, lists: data.data });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.pageLimit },
          {
            headers: {
              authorization: "Bearer " +Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  changePageLimit(evt) {
    this.setState({ pageLimit: parseInt(evt.target.value) }, () => {
      this.updateProfile();
    });
  }

  render() {
    const { lists, loader, timezone } = this.state;

    const fields = [
      { label: "Date", key: "Date", sort: true },
      { label: "Status", key: "jobStatus", sort: true },
      { label: "New Orders", key: "new_orders", sort: true },
      {
        label: "Orders With Customer Info",
        key: "order_with_customerinfo",
        sort: true,
      },
      { label: "Orders Auto Delivered", key: "order_autodeliver", sort: true },
    ];

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Skybox Scan Job Lists</title>
          </Helmet>

          <Header />

          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />

            <div className="c-wrapper history-payment-outer">
              <div className="c-body">
                <main className="c-main">
                  {loader && <PageLoader />}
                  {!loader && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card sx={{ p: 1 }}>
                          <CardHeader
                            title="Skybox Scan Job Lists"
                             
                          />
                          <CardContent className="plan-tbl history-payment">
                            <DataTable
                              items={lists}
                              fields={fields}
                              sorter
                              pagination
                              itemsPerPage={this.state.pageLimit}
                              scopedSlots={{
                                Date: (item) => {
                                  return customDateFormatLocal(
                                    item.created,
                                    timezone
                                  );
                                },
                              }}
                            />

                            <div
                              style={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <label>Items per page :</label>
                              <FormControl
                                sx={{ m: 1, minWidth: 50 }}
                                size="small"
                              >
                                <Select
                                  defaultValue={this.state.pageLimit}
                                  name="limit"
                                  sx={{
                                    "& .MuiSelect-select": {
                                      padding: "4px 14px",
                                      fontFamily: "Barlow",
                                    },
                                  }}
                                  onChange={(e) => this.changePageLimit(e)}
                                >
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="20">20</MenuItem>
                                  <MenuItem value="50">50</MenuItem>
                                  <MenuItem value="100">100</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkyboxScanJobs);
