import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@mui/material/Autocomplete";
import Pagination from "components/pagination/pagination";
import customDateFormat from "utils/customDateFormat";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import DataTable from "components/DataTable/DataTable";

class Messageslist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      currentPage: 1,
      userlists: [{ name: "Loading..." }],
      msgtype: [
        {
          label: "Incoming",
          name: "received",
        },
        {
          label: "Outgoing",
          name: "delivered",
        },
      ],
      messages: [],
      searchPage: 0,
      filterData: {
        user: null,
        datefrom: null,
        dateto: null,
        limit: 10,
        skip: 0,
        msg_type: null,
      },
      filterloaded: false,
      totalrecords: 0,
      pageLimit: 10,
    };

    this.getList = this.getList.bind(this);
    this.setDate = this.setDate.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSelectMsg = this.handleSelectMsg.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
  }

  componentDidMount() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(
        userList_api_url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((result) => {
        const res = result.data;

        if (!res.error) {
          const sorted = res.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });
          this.setState({ filterloaded: true, userlists: sorted });
        }
        this.setState({ filterloaded: true });
      });

    this.getList();
  }
  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  changePageLimit = (e) => {
    const { filterData } = this.state;
    filterData.limit = parseInt(e.target.value);
    filterData.skip = 0;
    this.setState(
      {
        searchPage: 1,
        loader: true,
        filterData,
        pageLimit: parseInt(e.target.value),
      },
      () => {
        this.getList();
      }
    );
  };

  getList() {
    const list_api_url = process.env.REACT_APP_API_URL + "/api/messages/getAll";
    axios
      .post(list_api_url, this.state.filterData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({ loader: false, messages: [], totalrecords: 0 });
        } else {
          this.setState({
            loader: false,
            messages: data.msgs,
            totalrecords: data.totalrecord,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Something went wrong");
      });
  }

  setDate(date, fieldname) {
    const { filterData } = this.state;
    filterData[fieldname] = date;
    this.setState({ filterData });
  }

  clearDate(fieldname) {
    const { filterData } = this.state;
    filterData[fieldname] = null;
    this.setState({ filterData });
  }

  handleChangeUser(e, newVal) {
    const { filterData } = this.state;
    if (newVal) {
      filterData.user = newVal._id;
    } else {
      filterData.user = null;
    }
    this.setState({ filterData });
  }

  handleSubmit() {
    const { filterData } = this.state;
    filterData.skip = 0;
    this.setState({ searchPage: 1, loader: true, filterData }, () => {
      this.getList();
    });
  }

  handleSelectMsg(e, messagetype) {
    console.log(messagetype);
    const { filterData } = this.state;
    if (messagetype) {
      if (messagetype.name === "received" || messagetype.name === "delivered") {
        filterData.msg_type = messagetype.name;
      }
    } else {
      filterData.msg_type = null;

      console.log("null");
    }

    console.log(filterData);
  }

  onPageChanged(data) {
    console.log(data);
    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    filterData.skip = (currentPage - 1) * pageLimit;
    this.setState({ loader: true, filterData }, () => {
      this.getList();
    });
  }

  render() {
    const { loader, userlists, msgtype, messages, filterData, totalrecords } =
      this.state;

    console.log(this.state.pageLimit);

    const fields = [
      { key: "user_name", label: "User" },
      { key: "phone_number", label: "Phone Number" },
      { key: "message_body", label: "Message" },
      { key: "sentat", label: "Sent At" },
      { key: "sentby", label: "Sent By" },
      { key: "sms_type", label: "Status" },
    ];

    console.log(totalrecords);

    return (
      <>
        <Helmet>
          <title>Messages</title>
        </Helmet>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title=" Messages"
                   
                />
                <Divider />
                <CardContent>
                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                    className="filter-form mb-5"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 2, lg: 2 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={2.5}
                          lg={2.5}
                          sx={{ textAlign: "left" }}
                        >
                          <label>From Date</label>
                          <DatePicker
                            selected={filterData.datefrom}
                            onChange={(date) => this.setDate(date, "datefrom")}
                            customInput={
                              <TextField
                                variant="outlined"
                                className="filter-input"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      className="date-icon"
                                    >
                                      <div className="d-flex">
                                        {filterData.datefrom ? (
                                          <IconButton
                                            edge="end"
                                            onClick={() =>
                                              this.clearDate("datefrom")
                                            }
                                          >
                                            <ClearIcon className="text-dark" />{" "}
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                          >
                                            <EventIcon className="text-dark" />
                                          </IconButton>
                                        )}
                                      </div>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={2.5}
                          lg={2.5}
                          sx={{ textAlign: "left" }}
                        >
                          <label>To Date</label>

                          <DatePicker
                            selected={filterData.dateto}
                            onChange={(date) => this.setDate(date, "dateto")}
                            customInput={
                              <TextField
                                variant="outlined"
                                className="filter-input"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      className="date-icon"
                                    >
                                      <div className="d-flex">
                                        {filterData.dateto ? (
                                          <IconButton
                                            edge="end"
                                            onClick={() =>
                                              this.clearDate("dateto")
                                            }
                                          >
                                            <ClearIcon className="text-dark" />{" "}
                                          </IconButton>
                                        ) : (
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                          >
                                            <EventIcon className="text-dark" />
                                          </IconButton>
                                        )}
                                      </div>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={2.5}
                          lg={2.5}
                          sx={{ textAlign: "left" }}
                        >
                          <label>Select User</label>
                          <Autocomplete
                            onChange={this.handleChangeUser}
                            fullWidth
                            id="combo-box-demo"
                            name="user"
                            className="filter-input"
                            options={userlists}
                            getOptionLabel={(option) => option.email}
                            renderInput={(params) => (
                              <TextValidator
                                value={filterData.user}
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          className="team"
                          xs={12}
                          sm={4}
                          md={2.5}
                          lg={2.5}
                          sx={{ textAlign: "left" }}
                        >
                          <label>Message Type</label>
                          <Autocomplete
                            onChange={this.handleSelectMsg}
                            fullWidth
                            className="filter-input"
                            id="combo-box-demo"
                            name="user"
                            options={msgtype}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextValidator
                                value={filterData.user}
                                {...params}
                                variant="outlined"
                                readOnly
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} md={2} lg={2}>
                          <label>&nbsp;</label>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            className="theme-btn "
                            startIcon={<HiOutlineSearch />}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </ValidatorForm>
                  {loader ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "13rem 0",
                      }}
                    >
                      <CircularProgress sx={{ color: "#002986" }} />
                    </Box>
                  ) : (
                    <>
                      <DataTable
                        items={messages}
                        fields={fields}
                        itemsPerPage={this.state.pageLimit}
                        scopedSlots={{
                          user_name: (item) => (
                            <>
                              {item.user_detail[0] && (
                                <span>{item.user_detail[0].name}</span>
                              )}
                            </>
                          ),
                          message_body: (item) => (
                            <>
                              {item.message_body}
                              {item.inventory && (
                                <>
                                  <br />
                                  <Button
                                    component="a"
                                    size="small"
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "#002986!important",
                                      color: "white!important",
                                      padding: "2px 5px!important",
                                      textTransform: "none!important",
                                      fontWeight: "bold",
                                      fontFamily: "Barlow!important",
                                    }}
                                    href={`/sold-inventory-management?refId=${item.inventory}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View Sale
                                  </Button>
                                </>
                              )}
                            </>
                          ),
                          sentat: (item) => (
                            <>{customDateFormat(item.sentat)}</>
                          ),
                          sms_type: (item) => (
                            <div style={{ textTransform: "capitalize" }}>
                              {item.sms_type ? item.sms_type : "delivered"}
                            </div>
                          ),
                        }}
                      />
                    </>
                  )}
                  {totalrecords > 0 && (
                    <Pagination
                      totalRecords={totalrecords}
                      searchPage={this.state.searchPage}
                      SearchDisable={this.SearchDisable}
                      pageLimit={this.state.pageLimit}
                      onPageChanged={this.onPageChanged}
                    />
                  )}

                  <div
                    style={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <label>Items per page :</label>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 50,
                      }}
                      size="small"
                    >
                      <Select
                        defaultValue={filterData.limit}
                        value={filterData.limit}
                        name="limit"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "4px 14px",
                            fontFamily: "Barlow",
                          },
                        }}
                        onChange={(e) => this.changePageLimit(e)}
                      >
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="50">50</MenuItem>
                        <MenuItem value="100">100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
export default Messageslist;
