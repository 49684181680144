import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
import "react-quill/dist/quill.snow.css";
import * as ReactQuill from "react-quill";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

class Addcreditpackage extends React.Component {
  state = {
    user: [],
    userList: [],
    name: "",
    price: "",
    free_credit: "",
    description: "",
    package_order: "",
    image: null,
    formdata: {
      image: "",
      files: null,
    },
    loader: false,

    UserValues: [],
    fixedOptions: [],
    fileError: "",
  };
  async componentDidMount() {
    // User List Id
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    await axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const sorted = data.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });

          this.setState({
            loader: false,
            UserValues: sorted,
            open: false,
            message: "",
            successError: "success",
          });

          let my_object = {};
          my_object.email = "All";
          my_object._id = "1";
          if (this.state.UserValues.indexOf(my_object.email) === -1) {
            this.state.UserValues.push(my_object);
          } else {
            console.log("No Getting All");
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  handleUserList = (e, newValue) => {
    this.setState({
      value: [
        ...this.state.fixedOptions,
        ...newValue.filter(
          (option) => this.state.fixedOptions.indexOf(option) === -1
        ),
      ],
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeQuill = (value) => {
    this.setState({ description: value });
  };
  onChanges = (e) => {
    var files = e.target.files;
    var kk = files[0];
    // console.log("kkkk", kk);
    // const reader = new FileReader();
    // console.log("reader", reader);
    // reader.onload =(event)=>{
    //     console.log("ttttttt", event.target.result);
    //     const {formdata} = this.state
    //     formdata['photo'] = event.target.result;
    // };
    // reader.readAsDataURL(kk);
    if (!files.length) {
      return;
    } else {
      this.setState({
        fileError: "",
      });
      if (
        kk.type === "image/png" ||
        kk.type === "image/jpg" ||
        kk.type === "image/jpeg"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.setState({
            image: event.target.result,
          });
        };
        reader.readAsDataURL(kk);
      } else {
        alert("Only image can be uploaded");
      }
    }
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };
  handleSubmit = async () => {
    if (this.state.image) {
      const arr = [];
      this.state.value.map((data) => {
        arr.push(data._id);
        return null;
      });
      this.setState({
        loader: true,
        fileError: "",
      });
      const data = {
        user: arr,
        name: this.state.name,
        price: this.state.price,
        free_credit: this.state.free_credit,
        description: this.state.description,
        package_order: this.state.package_order,
        image: this.state.image,
      };
      const addplan_api_url =
        process.env.REACT_APP_API_URL + "/api/package/addCreditPackage";
      await axios
        .post(addplan_api_url, data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
            this.props.history.push("/credit-packages");
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong, please try again",
            successError: "error",
          });
        });
    } else {
      this.setState({
        fileError: "Please Select Image",
      });
    }
  };

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  render() {
    const { loader } = this.state;

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Add Credit Packages</title>
          </Helmet>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      Add Credit Packages 
                    </Typography>
                  }
                />
                <Divider />
                <CardContent>
                  <ValidatorForm
                    // ref="form"
                    onSubmit={this.handleSubmit}
                  >
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontFamily: "Barlow !important",
                            paddingBottom: "0.7rem",
                          }}
                        >
                          User
                        </Typography>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          name="user"
                          options={this.state.UserValues}
                          getOptionLabel={(option) => option.email}
                          onChange={this.handleUserList}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={option.email}
                                {...getTagProps({ index })}
                                disabled={
                                  this.state.fixedOptions.indexOf(option) !== -1
                                }
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              variant="outlined"
                              value={this.state.value}
                              // label="filterSelectedOptions"
                              placeholder="Select User"
                              validators={["required"]}
                              errorMessages={"User is required"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontFamily: "Barlow !important",
                            paddingBottom: "0.7rem",
                          }}
                        >
                          Name
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth={true}
                          onChange={this.handleChange}
                          name="name"
                          value={this.state.name}
                          validators={["required"]}
                          errorMessages={["Name is required"]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontFamily: "Barlow !important",
                            paddingBottom: "0.7rem",
                          }}
                        >
                          Price
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth={true}
                          onChange={this.handleChange}
                          name="price"
                          type="text"
                          value={this.state.price}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "Price is required",
                            "only number allowed",
                          ]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontFamily: "Barlow !important",
                            paddingBottom: "0.7rem",
                          }}
                        >
                          Enter Credit
                        </Typography>
                        <TextValidator
                          fullWidth={true}
                          variant="outlined"
                          onChange={this.handleChange}
                          name="free_credit"
                          type="text"
                          value={this.state.free_credit}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "Credit is required",
                            "only number allowed",
                          ]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <div className="form-group select-outer">
                          <lTypography
                            sx={{
                              textAlign: "left",
                              fontFamily: "Barlow !important",
                              paddingBottom: "0.7rem",
                            }}
                            abel
                          >
                            Notes
                          </lTypography>
                          <ReactQuill
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChangeQuill}
                            modules={this.modules}
                            formats={this.formats}
                          />
                        </div>
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontFamily: "Barlow !important",
                            paddingBottom: "0.7rem",
                          }}
                        >
                          Package Order
                        </Typography>
                        <TextValidator
                         fullWidth={true}
                          variant="outlined"
                          onChange={this.handleChange}
                          name="package_order"
                          type="text"
                          value={this.state.package_order}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "This field is required",
                            "only number allowed",
                          ]}
                        />
                      </Grid>

                      <Grid
                        item={true}
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          marginBottom: "3rem",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #ccc",
                            padding: " 5px 5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontFamily: "Barlow !important",
                            }}
                          >
                            Select Image
                          </Typography>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              name="image"
                              accept="image/jpg, image/png"
                              type="file"
                              onChange={this.onChanges}
                            />
                            <PhotoCamera />
                          </IconButton>
                        </div>
                        {this.state.image && (
                          <img
                            style={{
                              width: "14rem",
                              height: "14rem",
                              objectFit: "contain",
                              margin: "15px 0px ",
                            }}
                            src={this.state.image}
                            alt=""
                          />
                        )}

                        <div style={{ color: "red" }}>
                          {this.state.fileError}
                        </div>
                      </Grid>
                      <Grid item={true} xs={4} sx={{ margin: "0 auto" }}>
                        <Button
                          size="large"
                          variant="contained"
                          sx={{ paddingY: "0.9rem" }}
                          type="submit"
                          fullWidth
                          className="theme-btn-submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}

export default Addcreditpackage;
