// encode Base64 to URL parameters
export const encodeParamsToBase64 = (params) => {
    if (params) {
        const jsonString = JSON.stringify(params);
        return btoa(jsonString);
    } else {
        return null;
    }
};

// Decode Base64 to URL parameters
export const decodeBase64ToParams = (base64String) => {
    if (base64String) {
        const jsonString = atob(base64String);
        return JSON.parse(jsonString);
    } else {
        return null;
    }
};
