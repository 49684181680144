import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Helmet } from "react-helmet";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AlertMessage from "../alert/alert";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ContactView = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [messages, setMessage] = useState("");
  const [successError, setSuccessError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/contact/detail`,
          { id: match.params.id }
        );
        const { data } = response.data;
        if (data.error) {
          setOpen(true);
          setMessage(data.message);
          setSuccessError("error");
        } else {
          setData(data);

          setOpen(false);
          setMessage("");
          setSuccessError("success");
        }
      } catch (error) {
        setOpen(true);
        setMessage(error.message || "Something went wrong");
        setSuccessError("error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [match.params.id]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }

  const { company_name, first_name, last_name, phone_number, email, message } =
    data;

  const thclass = {
    fontWeight: "bold",
    width: '15rem',

  };

  return (
    <>
      <Helmet>
        <title>Contact Detail</title>
      </Helmet>
      <Card>
        <CardHeader title="Contact Detail" />
        <Divider />
        <AlertMessage open={open} message={messages} severity={successError} />
        <CardContent>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none!important",
            }}
          >
            <Table aria-label="contact view table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" sx={thclass}>
                    Company Name
                  </StyledTableCell>
                  <StyledTableCell>{company_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={thclass} component="th" scope="row">
                    First Name
                  </StyledTableCell>
                  <StyledTableCell>{first_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={thclass} component="th" scope="row">
                    Last Name
                  </StyledTableCell>
                  <StyledTableCell>{last_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={thclass} component="th" scope="row">
                    Phone
                  </StyledTableCell>
                  <StyledTableCell>{phone_number}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={thclass} component="th" scope="row">
                    Email
                  </StyledTableCell>
                  <StyledTableCell>{email}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell sx={thclass} component="th" scope="row">
                    Message
                  </StyledTableCell>
                  <StyledTableCell>{message}</StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default ContactView;
