import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class Userview extends React.Component {
  state = {
    name: "",
    email: "",
    created_at: "",
    market_place: "",
    pos: "",
    ref_code: "",
    twilio_price: "",
    loader: false,
    axs_price: "",
    business_email: "",
    business_name: "",
    user_details: {}
  };
  async componentDidMount() {
    console.log(
      "this.props.match.params.userid",
      this.props.match.params.userid
    );
    this.setState({
      loader: true,
    });
    const data = {
      user_id: this.props.match.params.userid,
    };
    const userView_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/userDetail";
    await axios
      .post(userView_api_url, data)
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            name: data.data.name,
            email: data.data.email,
            business_name: data?.data?.business_name,
            business_email: data.data.business_email,
            created_at: data.data.created,
            market_place: data.data.preferred_marketplace,
            pos: data.data.preferred_pos,
            ref_code: data.data.referral_code,
            ticket_price: data.data.ticket_price,
            axs_price: data.data.axs_link_price ?? "",
            open: false,
            message: "",
            successError: "success",
            user_details: data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  render() {
    const {
      name,
      email,
      created_at,
      ref_code,
      ticket_price,
      loader,
      business_email,
      business_name,
      user_details
    } =
      this.state;
    const thclass = {
      fontWeight: "bold",
      width: "20rem",
    };

    return (
      <>
        <Helmet>
          <title>User Details</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "#000059!important",
                textAlign: "left",
                padding: "30px",
                backgroundColor: "#fbfbfb;",
              }}
            >
              <div className="vendortable-header">
                <Typography
                  variant="h6"
                  sx={{
                    width: "100%",
                    maxWidth: "265px",
                    color: "#000059!important",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "1.6rem",
                    fontFamily: "Barlow!important",
                  }}
                >
                  {" "}
                  User Details
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, lg: 1 }}
                  columns={{ xs: 4, sm: 8, md: 8 }}
                  className="button-align"
                >
                  <Grid item>
                    <Button
                      component={Link}
                      to={`/add-custom-credit/${this.props.match.params.userid}`}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<AddCircleIcon />}
                    >
                      Add Credit
                    </Button>
                  </Grid>
                  <Grid item >
                    <Button
                      component={Link}
                      to={`/remove-custom-credit/${this.props.match.params.userid}`}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<RemoveCircleIcon />}
                    >
                      Remove Credit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => this.props.history.push("/users-management")}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<ArrowBack />}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </div>
              &nbsp;&nbsp;
              <Divider />
              <CardContent sx={{ padding: "0px!important" }}>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none!important",
                    }}
                  >
                    <Table aria-label="contact view table">
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Name
                          </StyledTableCell>
                          <StyledTableCell>{name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Email
                          </StyledTableCell>
                          <StyledTableCell>{email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Business Name
                          </StyledTableCell>
                          <StyledTableCell>{business_name ?? "-"}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Business Email
                          </StyledTableCell>
                          <StyledTableCell>{business_email ?? "-"}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Created
                          </StyledTableCell>
                          <StyledTableCell>{created_at}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Referral Code
                          </StyledTableCell>
                          <StyledTableCell>{ref_code ?? "-"}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Ticket Price
                          </StyledTableCell>
                          <StyledTableCell>{changeDollarAmountformat(ticket_price)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            AXS Link Price
                          </StyledTableCell>
                          <StyledTableCell>{changeDollarAmountformat(user_details?.axs_link_price)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Non-Transferable Ticket Price
                          </StyledTableCell>
                          <StyledTableCell>{changeDollarAmountformat(user_details?.nt_ticket_price)}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Browser Charge Date
                          </StyledTableCell>
                          <StyledTableCell>{user_details?.browser_charge_date}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Access Browser
                          </StyledTableCell>
                          <StyledTableCell>{user_details?.access_browser === "No" ? "No" : "Yes"}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Anonymous Browser Pricing
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Primary License
                          </StyledTableCell>
                          <StyledTableCell>{changeDollarAmountformat(user_details?.license_price?.primary)}/month</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Secondary License
                          </StyledTableCell>
                          <StyledTableCell>{changeDollarAmountformat(user_details?.license_price?.secondary)}/month</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={thclass}
                          >
                            Stripe Customer Id
                          </StyledTableCell>
                          <StyledTableCell>{user_details?.stripe_customer_id ?? "-"}</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Userview;
