import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import AlertMessage from "../alert/alert";
import {
  Select,
  Button,
  MenuItem,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  IconButton,
  Autocomplete,
  Typography,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Helmet } from "react-helmet";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ArrowBack } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import { ContainerLoader } from "components/common/ContainerLoader";


class Adduser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      names: "",
      email: "",
      password: "",
      c_password: "",
      Marketplace: "",
      businessName: "",
      businessEmail: "",
      POS: "",
      formData: {
        vbdaccount: "",
        vbdaccount_select: ""
      },
      testUser: "No",
      ref_Code: "",
      ticket_price: 0,
      access_browser: "No",
      selectedDate: new Date('2024-01-01'),
      crossCheck: true,
      roles: [],
      error: [],
      vbdaccounts: [],
      loader: false,
      open: false,
      message: "",
      successError: "",
      lowerEmailCheck: false,
      lowerEmailMsg: "",
      showPassword: false,
      showConfirmPassword: false,
      axs_price: 0,
      primaryLicense: 0,
      secondaryLicense: 0,
      nt_ticket_price: 0,
      defaultValueForVBD: {},
      isLoading: false,
      value: '0%'
    };
    this.inputRef = React.createRef();
  }

  getVBDAccountsList = () => {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/listVbdAccount";
    axios
      .get(userList_api_url, {
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.error) {
          this.setState({
            loader: false,
            message:
              "Something went wrong while accessing vbd account list",
            alertseverity: "error",
            openalert: true,
          });
        } else {
          const vbd = data?.data?.find((data) => data?.username === "ticketliberty")
          this.setState({
            defaultValueForVBD: vbd,
            loader: false,
            vbdaccounts: data.data,
            showform: true,
          });
          const { formData } = this.state;
          if (vbd) {
            formData["vbdaccount"] = vbd._id;
            formData["vbdaccount_select"] = vbd._id;
          } else {
            formData["vbdaccount"] = vbd;
            formData["vbdaccount_select"] = "";
          }
          this.setState({ formData });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          message:
            "Something went wrong while accessing vbd account list",
          alertseverity: "error",
          openalert: true,
        });
      });
  }
  componentDidMount() {
    this.getAllSettingDetails()
    this.getVBDAccountsList()
    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        if (value !== this.state.password) {
          return false;
        }
        return true;
      });
    }
    if (!ValidatorForm.hasValidationRule("isSmallCase")) {
      ValidatorForm.addValidationRule("isSmallCase", (value) => {
        var re = /^(?=.*[a-z]).+$/;
        return re.test(value);
      });
    }

    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }
  }
  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
    if (ValidatorForm.hasValidationRule("isSmallCase")) {
      ValidatorForm.removeValidationRule("isSmallCase");
    }
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleVBDList = (e, newValue) => {
    const { formData } = this.state;
    if (newValue) {
      formData["vbdaccount"] = newValue._id;
      formData["vbdaccount_select"] = newValue._id;
    } else {
      formData["vbdaccount"] = newValue;
      formData["vbdaccount_select"] = "";
    }
    this.setState({ formData });
  };;

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleChangebusinessEmail = (e) => {
    this.setState({
      businessEmail: e.target.value,
    });
  };
  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  handleShowConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleChangePos = (e, newVal) => {
    this.setState({
      POS: newVal,
    });
  };
  handleChangeMarket = (e, newVal) => {
    this.setState({
      Marketplace: newVal,
    });
  };

  handleSubmit = async (e) => {
    const discount_rate = parseFloat(this.state?.value.replace('%', ''));
    this.setState({
      loader: true,
    });
    const data = {
      name: this.state.names,
      email: this.state.email,
      password: this.state.password,
      business_email: this.state.businessEmail,
      business_name: this.state.businessName,
      referral_code: this.state.ref_Code,
      preferred_pos: this.state.POS.title,
      preferred_marketplace: this.state.Marketplace.title,
      ticket_price: this.state.ticket_price,
      access_browser: this.state.access_browser,
      roles: ["user"],
      axs_link_price: this.state.axs_price,
      vbdaccount: this.state.formData.vbdaccount,
      isTestUser: this.state.testUser === "Yes" ? true : false,
      nt_ticket_price: parseInt(this.state.nt_ticket_price),
      license_price: {
        primary: parseInt(this.state.primaryLicense),
        secondary: parseInt(this.state.secondaryLicense)
      },
      browser_charge_date: this.state?.selectedDate,
      browser_charge_discount: discount_rate
    };
    //    await this.props.signUpClick(data);
    const addUser_api_url = process.env.REACT_APP_API_URL +  "/api/admin/addUser";
    await axios
      .post(addUser_api_url, data)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
          });
          this.setState({
            names: "",
            email: "",
            password: "",
            c_password: "",
            ref_Code: "",
            POS: [],
            Marketplace: [],
            ticket_price: 0,
            vbdaccount: ''
          });
          this.props.history.push("/users-management");
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleChangeTestUser = (event) => {
    this.setState({ testUser: event.target.value });
  };

  setStartDate = async (dates) => {
    if (dates) {
      this.setState({ selectedDate: dates, crossCheck: false });
    } else {
      this.setState({ selectedDate: null, crossCheck: true });
    }
  };

  clearDate = () => {
    this.setState({ selectedDate: new Date('2024-01-01'), crossCheck: true });
  };


  getAllSettingDetails = async () => {
    this.setState({ isLoading: true });
    try {
      const parmas = {
        id: "60633a028f70d97537accede",
      };
      const account_api_url =
        process.env.REACT_APP_API_URL + "/api/setting/detail";
      const response = await axios.post(account_api_url, parmas, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data?.error) {
        this.setState({
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
          isLoading: false
        });
      } else {
        this.setState({
          axs_price: data?.data?.axs_link_price ?? 0,
          primaryLicense: data.data.license_price.primary ?? 0,
          secondaryLicense: data.data.license_price.secondary ?? 0,
          nt_ticket_price: data?.data?.nt_ticket_price ?? 0,
          ticket_price: data?.data?.ticket_price ?? 0,
          isLoading: false
        })
      }
    } catch (error) {
      this.setState({
        open: true,
        message: "Something went wrong, please try again",
        successError: "error",
        isLoading: false
      });
    }
  }

  handleChangeDiscount = (e) => {
    let inputValue = e.target.value.replace(/[^\d%]/g, '');
    if (inputValue.includes('%')) {
      inputValue = inputValue.replace('%', '');
    }
    if (inputValue.length > 1 && inputValue[0] === '0' && inputValue !== '0' && inputValue !== "") {
      inputValue = inputValue.replace(/^0+/, '');
    }
    if (
      inputValue === '' ||
      (/^\d{1,3}$/.test(inputValue) && parseInt(inputValue, 10) <= 100) && inputValue.length <= 3
    ) {
      if (inputValue === '') {
        this.setState({ value: '' });
      } else {
        this.setState({ value: inputValue + '%' }, this.handleCursorPositionDiscount(e));
      }
    }
  };

  handleBlurDiscount = () => {
    const { value } = this.state;
    if (value === '') {
      this.setState({ value: '0%' });
    } else if (!value.endsWith('%')) {
      this.setState({ value: value + '%' });
    }
  };

  handleCursorPositionDiscount = (e) => {
    const input = e.target;
    const position = input.selectionStart;
    const valueWithoutPercentage = this.state.value.replace('%', '');
    if (position >= valueWithoutPercentage.length) {
      input.setSelectionRange(valueWithoutPercentage.length, valueWithoutPercentage.length);
    }
  };

  render() {
    const {
      names,
      email,
      password,
      businessName,
      businessEmail,
      c_password,
      ref_Code,
      ticket_price,
      nt_ticket_price,
      testUser,
      loader,
      isLoading
    } = this.state;
    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Add User</title>
          </Helmet>
          {isLoading ? (
            <ContainerLoader />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Add User"
                    action={
                      <Grid
                        container
                        spacing={{ xs: 1, md: 1, lg: 1 }}
                        columns={{ xs: 4, sm: 8, md: 8 }}
                        className="button-align"
                      >
                        <Grid item>
                          <Button
                            onClick={() => this.props.history.goBack()}
                            size="large"
                            variant="contained"
                            sx={{
                              backgroundColor: "#002986!important",
                              color: "white!important",
                              textTransform: "none!important",
                              fontWeight: "bold",
                              fontFamily: "Barlow!important",
                            }}
                            startIcon={<ArrowBack />}
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <label>Name</label>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange}
                            name="names"
                            value={names}
                            validators={["required"]}
                            errorMessages={["Name is required"]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label>Email address</label>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChangeEmail}
                            name="email"
                            autoComplete="off"
                            value={email}
                            validators={["required", "isSmallCase", "isEmail"]}
                            errorMessages={[
                              "Email is required",
                              "Please enter email in lower case",
                              "Email is not valid",
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                              <label>Business Name</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                type="text"
                                name="businessName"
                                value={businessName}
                                validators={["required"]}
                                errorMessages={[
                                  "Business Name is required"
                                ]}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Business Email</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChangebusinessEmail}
                                name="businessEmail"
                                value={businessEmail}
                                validators={["required", "isSmallCase", "isEmail"]}
                                errorMessages={[
                                  "Business Email is required",
                                  "Please enter email in lower case",
                                  "Business Email is not valid",
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                              <label>Password</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                autoComplete="off"
                                name="password"
                                type={this.state.showPassword ? "text" : "password"}
                                value={password}
                                validators={["required", "PasswordStrength"]}
                                errorMessages={[
                                  "Password is required",
                                  "Password must be at least 6 character",
                                ]}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleShowPassword}
                                      edge="end"
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }}
                              />
                              {this.state.error.password && (
                                <div className="error-msg">
                                  {this.state.error.password}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <label>Confirm Password</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="c_password"
                                type={this.state.showConfirmPassword ? "text" : "password"}
                                value={c_password}
                                validators={["isPasswordMatch", "required"]}
                                errorMessages={[
                                  "password mismatch",
                                  "Confirm Password is required",
                                ]}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleShowConfirmPassword}
                                      edge="end"
                                    >
                                      {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }}
                              />
                              {this.state.error.c_password && (
                                <div className="error-msg">
                                  {this.state.error.c_password}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "10px",
                            }}
                          >
                            Attach Vbd account
                          </Typography>
                          <Autocomplete
                            fullwidth
                            id="tags-outlined-1"
                            name="vbdaccount"
                            getOptionLabel={(option) => option.username}
                            options={this.state.vbdaccounts ?? []}
                            onChange={this.handleVBDList}
                            value={this.state?.defaultValueForVBD ?? {}}
                            renderInput={(params) =>
                              <TextValidator
                                {...params}
                                placeholder="Select VBD Account"
                                name="vbdaccount_select"
                                variant="outlined"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                              <label>Referral code</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="ref_Code"
                                value={ref_Code}
                                validators={["required"]}
                                errorMessages={["Referal is required"]}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Test User</label>
                              <Grid item xs={6}>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={testUser}
                                    onChange={this.handleChangeTestUser}
                                  >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                              <label>Ticket Price</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                type="text"
                                name="ticket_price"
                                value={ticket_price}
                                validators={["required"]}
                                errorMessages={["Ticket Price is required"]}
                              />
                              {this.state.error.ticket_price && (
                                <div className="error-msg">
                                  {this.state.error.ticket_price}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <label>AXS Link Price</label>
                              <TextValidator
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                type="text"
                                name="axs_price"
                                value={this.state.axs_price}
                                validators={["required"]}
                                errorMessages={["AXs link price is required"]}
                              />
                              {this.state.error.ticket_price && (
                                <div className="error-msg">
                                  {this.state.error.ticket_price}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <label>Non-Transferable Ticket Price</label>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange}
                            type="text"
                            name="nt_ticket_price"
                            value={nt_ticket_price}
                            validators={["required"]}
                            errorMessages={["Non-Transferable Ticket Price is required"]}
                          />
                          {this.state.error.nt_ticket_price && (
                            <div className="error-msg">
                              {this.state.error.nt_ticket_price}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <span>Anonymous Browser Pricing</span>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                              <span>Primary</span> {" "}
                              <TextValidator
                                fullWidth
                                variant="outlined"
                                onChange={this.handleChange}
                                placeholder="Primary Price"
                                name="primaryLicense"
                                type="number"
                                value={this.state?.primaryLicense}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                    $
                                  </InputAdornment>,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <span>Secondary</span>{' '}
                              <TextValidator
                                fullWidth
                                placeholder="Secondary Price"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="secondaryLicense"
                                type="number"
                                value={this.state?.secondaryLicense}
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                    $
                                  </InputAdornment>,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                            <Grid item xs={6}>
                              <label>Access Browser</label>
                              <Select
                                style={{ width: "100%" }}
                                value={this.state.access_browser}
                                name="access_browser"
                                onChange={(e) => this.handleChange(e)}
                              >
                                <MenuItem value="Yes">Enable</MenuItem>
                                <MenuItem value="No">Disable</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={4} >
                              <label>Browser Charge Date</label>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={this.state.selectedDate}
                                customInput={
                                  <TextValidator
                                    variant="outlined"
                                    className="filter-input"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          className="date-icon"
                                        >
                                          <div className="d-flex">
                                            {this.state.crossCheck ? (
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                              >
                                                <EventIcon className="text-dark" />
                                              </IconButton>
                                            ) : (
                                              <IconButton
                                                edge="end"
                                                onClick={() =>
                                                  this.clearDate()
                                                }
                                              >
                                                <ClearIcon className="text-dark" />
                                              </IconButton>
                                            )}
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                }
                                onChange={(dates) =>
                                  this.setStartDate(dates)
                                }
                              />
                            </Grid>
                            <Grid item xs={2} >
                              <label>Discount Rate</label>
                              <TextValidator
                                fullWidth
                                name="value"
                                value={this.state.value ?? ""}
                                onChange={this.handleChangeDiscount}
                                onBlur={this.handleBlurDiscount}
                                onKeyUp={this.handleCursorPositionDiscount}
                                onClick={this.handleCursorPositionDiscount}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px auto" }}>
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            size="large"
                            sx={{ paddingY: "0.9rem" }}
                            className="theme-btn-submit"
                          >
                            Add User
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                    <AlertMessage
                      open={this.state.open}
                      message={this.state.message}
                      severity={this.state.successError}
                      closeCall={this.handleClose}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}

export default Adduser;
