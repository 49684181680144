import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// mui and bootstrap components
import {
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { LoadingButton } from '@mui/lab';

// custom components
import { changeDollarAmountformat } from './changeDollarFormat';
import { useUserAPICollection } from 'Hooks/useUserAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import { decodeBase64ToParams, encodeParamsToBase64 } from './base64';
import ChangePaymentMethod from 'pages/userdashboard/browser/licensesSummary/ChangePaymentMethod';
import ConfirmationModal from './confirmationModal';
import UserProfileAction from 'redux/actions/UserProfielAction';
import { useDispatch, useSelector } from 'react-redux';
import DataTableModal from './DataTableModal';
import customDateFormatLocal from './customDateFormatLocal';
import { TiInfoLarge } from 'react-icons/ti';
import ReceiptPDFGenerator from './receipt-pdf';
import Cookies from 'js-cookie';

const purchasebtnmodalStylePurchaeCredit = {
    maxWidth: "148px!important",
    backgroundColor: "#001D7D!important",
    color: "white!important",
    fontFamily: "Barlow!important",
    textTransform: "none!important",
    fontSize: "14px!important",
    width: "100%",
    marginTop: '11px',
    fontWeight: "bold",
    padding: "2px 0px 3px 0px !important",

};

const NegativeCreditModal = ({
    isVisiableModal,
    userAvailableCredit,
    userEnterAmount,
    getUserAvailableCredit,
    getUserDetails,
    getNotifications
}) => {    
    const columnsModal = [
        { field: "date", headerName: "Date" },
        { field: 'description', headerName: 'Description' },
        { field: 'debit', headerName: 'Debit' },
        { field: 'credit', headerName: 'Credit' },
        { field: 'ending_balance', headerName: 'Ending Balance' },
        { field: 'action', headerName: 'Action' },
    ]
    const userprofile = useSelector((state) => state?.UserProfile?.data);
    const dispatch = useDispatch()
    const history = useHistory();
    const {
        getSripSavedCardsAPi,
        addStripeCustPayMethodApi,
        deleteStripePaymentMethodApi,
        purchaseCreditApi,
        deleteProgessTaskaApi,
        getHistoryOfPayments
    } = useUserAPICollection();
    const { alertMessage, errorMessage, showAlert, hideAlert } = useAlertMessage();
    const urlParams = new URLSearchParams(window?.location?.search);
    const [formData, setFormData] = useState({
        amount: "",
        displayamount: ""
    });
    const [isModalStates, setIsModalStates] = useState({
        isDisabledPurchaseCreditButton: false,
        isButtonDisabled: false,
    });
    const [isLoadingSavedCard, setIsLoadingSavedCard] = useState({
        isLoadingSavedCard: true,
        isDisabledRefreshBtn: false,
    });
    const [queryStringRemovedByUser, setQueryStringRemovedByUser] = useState(false);
    const [isVisibilePaymentMethodModal, setIsVisibilePaymentMethodModal] = useState(false);
    const [stripeCardList, setStripeCardList] = useState([])
    const [duration, setDuration] = useState();
    const [selectedCardInfo, setSelectedCardInfo] = useState(null);
    const [cardId, setCardId] = useState("");
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [isTermsCondition, setIsTermsCondition] = useState(false);
    const [isCreditUsage, setIsCreditUsage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [creditUsageData, setCreditUsageData] = useState([]);
    const [totalRecordsModal, setTotalRecordsModal] = useState(0);
    const [modalPagination, setModalPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: userprofile?.per_page_limit ?? 10,
    });
    let eventSource = null;

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleAgreeTermsConditon = (e) => {
        setIsTermsCondition(e.target.checked)
    }

    const handleVisibileChoosePaymentModal = async () => {
        if (formData.amount) {
            setIsVisibilePaymentMethodModal(true);
           await getSavedStripeList()
        }
    }

    const hnadleCloseChangeCardInfo = () => {
        setIsVisibilePaymentMethodModal(false);
    }

    const handleOnChangeCardInfo = (id) => {
        setSelectedCardInfo(id);
    }

    const handleCloseDeleteModal = () => {
        setIsDeleteModalShow(false);
        setCardId("");
    }

    const handleVisableDeleteConfimationModal = (id) => {
        setIsDeleteModalShow(true);
        setCardId(id);
    }

    const handleOpenCreditUsageModal = () => {
        getCreditUsage();
        setIsCreditUsage(true);
    }

    const handlecloseCreditUsageModal = () => {
        setIsCreditUsage(false);
    }

    const changePageLimitModal = (e) => {
        setModalPagination({
            ...modalPagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        });
        getCreditUsage(0, parseInt(e.target.value));
    };

    const onPageChangedModal = (data) => {
        const { currentPage } = data;
        setModalPagination({
            ...modalPagination,
            skip: (currentPage - 1) * modalPagination.pageLimit
        });
        getCreditUsage((currentPage - 1) * modalPagination.pageLimit, modalPagination.pageLimit);
    };

    const SearchDisableModal = (value) => {
        setModalPagination({
            ...modalPagination,
            searchPage: value,
        });
    };

    const handleSetValue = (e, value) => {
        if (value) {
            const stringValue = value.toString();
            const numericValue = parseFloat(stringValue.replace(/[^0-9.]/g, ""));
            if (!isNaN(numericValue)) {
                const formattedValue = numericValue.toFixed(2);
                setFormData({ ...formData, amount: formattedValue, displayamount: formattedValue });
                setIsModalStates({ ...isModalStates, isButtonDisabled: false });
            }
        } else {
            setFormData({ ...formData, amount: '' });
        }
    };

    const removedNegativeBalance = (amount) => {
        return Math.abs(amount)?.toFixed(2)
    }

    const handleOnChangeAmounts = (e) => {
        const cursorPosition = e.target.selectionStart;
        const newValue = e.target.value;
        if (newValue !== '') {
            let cleanValue = newValue.replace(/[^0-9.]/g, "");
            const decimalIndex = cleanValue.indexOf('.');
            if (decimalIndex !== -1) {
                const decimalPart = cleanValue.slice(decimalIndex + 1, decimalIndex + 3);
                cleanValue = cleanValue.substring(0, decimalIndex + 1) + decimalPart.padEnd(2, '0');
            } else {
                cleanValue += '.00';
            }
            const amountValue = parseFloat(cleanValue.replace(/,/g, ""));
            const isButtonDisabled = amountValue < removedNegativeBalance(userAvailableCredit);
            setFormData({ ...formData, amount: cleanValue, displayamount: cleanValue });

            setIsModalStates({ ...isModalStates, isButtonDisabled });
        } else {
            setFormData({ ...formData, amount: '' });
        }
        // Restore cursor position
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    const getCreditUsage = async (skip = 0, limit = 10) => {
        const parmas = {
            skip: skip,
            limit: limit
        }
        try {
            setIsLoading(true);
            const response = await getHistoryOfPayments(parmas);
            const { data } = response;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                setIsLoading(false);
                errorMessage();
            } else {
                setIsLoading(false);
                setCreditUsageData(data?.data)
                setTotalRecordsModal(data?.total)
            }
        } catch (error) {
            setIsLoading(false);
            errorMessage();
        }
    }

    const getSavedStripeList = async () => {
        try {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: true,
                isDisabledRefreshBtn: true
            });
            const response = await getSripSavedCardsAPi();
            const { data } = response;
            if (data.tokenExpired && data.error) {
                errorMessage(data?.message, "error");
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                errorMessage()
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            } else {
                setStripeCardList(data?.payment_methods)
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            }
        } catch (error) {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: false,
                isDisabledRefreshBtn: false
            });
            errorMessage();
        }
    };

    const handleAddStripeCustomPayMethod = async () => {
        const url = window.location.href;
        const params = {
            amount: formData?.amount,
            displayamount: formData?.displayamount,
            isModal: true
        };
        const encodedParams = encodeParamsToBase64(params);
        if (encodedParams) {
            try {
                const response = await addStripeCustPayMethodApi({ success_url: `${url}?p=${encodedParams}` });
                const { data } = response;
                if (data.error) {
                    errorMessage();
                } else {
                    window.location.href = data?.checkout_url;
                }
            } catch (error) {
                errorMessage();
            }
        } else {
            showAlert({
                message: 'Something went wrong, Please try again ',
                alertSeverity: "error"
            });
        }
    }

    const handleDeleteStripePaymentMethod = async () => {
        try {
            setIsDeleteModalShow(false);
            const response = await deleteStripePaymentMethodApi({ payment_method_id: cardId });
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                errorMessage(data.message, "success");
                await getSavedStripeList();
            }
        } catch (error) {
            errorMessage();
        }
    }

    const handlePurchaseCredit = async () => {
        if (selectedCardInfo) {
            try {
                setIsModalStates({ ...isModalStates, isDisabledPurchaseCreditButton: true })
                const params = {
                    amount: parseFloat(formData.amount),
                    payment_method_id: selectedCardInfo
                };
                const response = await purchaseCreditApi(params);
                const { data } = response;
                if (data.error) {
                    errorMessage();
                    setIsVisibilePaymentMethodModal(false)
                    setIsModalStates({ ...isModalStates, isDisabledPurchaseCreditButton: false })
                } else {
                    setIsVisibilePaymentMethodModal(false)
                    setDuration(1000)
                    setSelectedCardInfo(null)
                    history.replace(window.location.pathname);
                    dispatch(UserProfileAction())
                    setTimeout(() => { errorMessage(data.message, "success"); }, 100)
                    eventSource = new EventSource(process.env.REACT_APP_API_URL + `/api/user/purchaseCredit/status/${data.taskId}`);
                    await serverSideProgress(data?.taskId, "purchaseCredit")
                }
            } catch (error) {
                errorMessage();
                setIsModalStates({ ...isModalStates, isDisabledPurchaseCreditButton: false })
                setIsVisibilePaymentMethodModal(false)
            }
        } else {
            errorMessage("Please Select a Payment Method!", "error");
        }
    }

    const serverSideProgress = (id, generateType) => {
        eventSource.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data.data.status === "completed") {
                setDuration(4000)
                setIsTermsCondition(false);
                getUserAvailableCredit();
                getUserDetails();
                getNotifications();
                setIsModalStates({ ...isModalStates, isDisabledPurchaseCreditButton: false });
                setFormData({
                    ...formData,
                    amount: ""
                });
                setTimeout(() => { errorMessage("Payment Successful", "success") }, 1000)
                eventSource.close();
                await handleDeleteProgessTask(id)
            } else if (data.data.status === "failed") {
                console.log(data.data && data.data.response && data.data.response.message);
                setDuration(4000)
                errorMessage(data.data && data.data.response && data.data.response.message, "error");
                setIsModalStates({ ...isModalStates, isDisabledPurchaseCreditButton: false })
                setIsTermsCondition(false)
                setFormData({
                    ...formData,
                    amount: ""
                });
                eventSource.close();
            }
        }
    }

    const handleDeleteProgessTask = async (id) => {
        try {
            const response = await deleteProgessTaskaApi({ _id: id })
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                console.log('sss')
            }
        } catch (error) {
            errorMessage();
        }
    }

    useEffect(() => {
        const unlisten = history?.listen((location, action) => {
            if (action === 'POP' && !queryStringRemovedByUser) {
                history.replace(location.pathname);
            }
            setQueryStringRemovedByUser(false);
        });
        return () => {
            unlisten();
        };
    }, [history, queryStringRemovedByUser]);

    useEffect(() => {
        const encodedParams = urlParams.get("p");
        const decodedParams = decodeBase64ToParams(encodedParams);
        if (decodedParams) {
            setIsVisibilePaymentMethodModal(true);
            setFormData({
                ...formData,
                amount: decodedParams?.amount,
                displayamount: decodedParams?.displayamount
            });
        }
    }, []);

    return (
        <>
            <Dialog
                maxWidth="sm"
                onClick={handleBackdropClick}
                open={isVisiableModal}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                    },
                }}
            >
                <ValidatorForm
                    onSubmit={() => handleVisibileChoosePaymentModal()}
                >
                    <DialogTitle id="customized-dialog-title" sx={{ padding: "10px 17px !important" }}>
                        <Grid container sx={{ alignContent: "center" }}>
                            <Grid item xs={6}>
                                Action Required
                            </Grid>
                            <Grid item xs={6} sx={{ justifyContent: "flex-end !important", alignItems: "center !important", display: "flex !important" }}>
                                <Typography variant="h5">
                                    <span className="Account_details_box-lic-neagiatve" >Available Credit:</span>
                                    <span style={{ fontWeight: "600", color: userAvailableCredit < 0 ? "red" : "rgb(0, 133, 93)", fontSize: "18px" }}>
                                        {' '}{changeDollarAmountformat(userAvailableCredit) ?? "$0.00"}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sx={{ marginTop: "-13px !important" }}>
                                <Typography className="product-text-btn" sx={{ display: "flex" }}>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={() => handleOpenCreditUsageModal()}
                                        size="small"
                                        sx={purchasebtnmodalStylePurchaeCredit}
                                    >
                                        Check Credit usage
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent sx={{ padding: '0px 17px !important' }}>
                        <Typography>
                            <span className="Account_details_box-lic  purchase-license-modal-text">
                                <span style={{ color: "red" }}>
                                    Your Ticket Liberty account has been temporarily locked due to a negative balance. To regain access, please add credit using the link below or contact our team at sales@ticketliberty.com for assistance.
                                </span>
                            </span>
                        </Typography>
                        <br />
                        <DialogContentText>
                            <Grid item xs={12} className="space-top-col">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className="space-top-col">
                                        <Card
                                            sx={{
                                                boxShadow: "none !important",
                                                width: "100%",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                            }}
                                        >
                                            <CardContent sx={{
                                                padding: "0px!important",
                                                marginTop: "30px!important",
                                            }}
                                                className="plan-tbl vendor-outer"
                                            >
                                                <Grid container spacing={2} justify="center">
                                                    <Grid item container direction="column" xs={12} sm={12} spacing={2}>
                                                        <Grid item>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sx={{ marginTop: "-31px" }}>
                                                                    <label className="Account_details_box">
                                                                        Amount
                                                                    </label>
                                                                    <TextValidator
                                                                        onChange={(e) => handleOnChangeAmounts(e)}
                                                                        value={formData?.amount ?? ""}
                                                                        variant="outlined"
                                                                        placeholder={`Enter an amount between ${changeDollarAmountformat(parseFloat(removedNegativeBalance((userAvailableCredit))))} and ${changeDollarAmountformat(parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(5000.00))}`}
                                                                        fullWidth
                                                                        type="text"
                                                                        id="currencyInput"
                                                                        name="credit"
                                                                        autoComplete="off"
                                                                        validators={["required"]}
                                                                        errorMessages={["This field is required"]}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                                                                {formData?.amount === "" ? "" : "$"}
                                                                            </InputAdornment>,
                                                                            endAdornment: parseInt(formData?.amount) ? (
                                                                                <InputAdornment
                                                                                    onClick={() => setFormData({ ...formData, amount: "" })}
                                                                                    position="start"
                                                                                    sx={{ marginRight: "-2px", cursor: "pointer" }}
                                                                                >
                                                                                    <Close />
                                                                                </InputAdornment>
                                                                            ) : null
                                                                        }}
                                                                    />
                                                                    <Stack direction="row" spacing={1} sx={{ paddingTop: "6px", display: "block" }}>
                                                                        <Stack direction="row" spacing={1} sx={{ paddingTop: "6px" }}>
                                                                            {[
                                                                                { label: removedNegativeBalance(userAvailableCredit), addition: removedNegativeBalance(userAvailableCredit) },
                                                                                { label: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(1000.00), addition: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(1000.00) },
                                                                                { label: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(2500.00), addition: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(2500.00) },
                                                                                { label: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(5000.00), addition: parseFloat(removedNegativeBalance(userAvailableCredit)) + parseFloat(5000.00) }
                                                                            ].map(({ label, addition }, index) => {
                                                                                return (
                                                                                    <Chip
                                                                                        key={index}
                                                                                        size="small"
                                                                                        label={`${changeDollarAmountformat(label)}`}
                                                                                        onClick={(e) => handleSetValue(e, addition)}
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </Stack>
                                                                        <Stack direction="row" spacing={1} className='chips-button-group'>
                                                                            <Chip sx={{ backgroundColor: "white", color: "white" }} label={removedNegativeBalance(userAvailableCredit)} size="small" />
                                                                            <Chip className='text-button-chip' label={`+${changeDollarAmountformat(1000)}`} size="small" />
                                                                            <Chip className='text-button-chip' label={`+${changeDollarAmountformat(2500)}`} size="small" />
                                                                            <Chip className='text-button-chip' label={`+${changeDollarAmountformat(5000)}`} size="small" />
                                                                        </Stack>
                                                                    </Stack>
                                                                    &nbsp;
                                                                    {parseFloat(formData.amount.replace(/,/g, "")) > (parseFloat(userEnterAmount) + parseFloat(removedNegativeBalance(userAvailableCredit))) &&
                                                                        <label className="Account_details_box" style={{ color: "red" }}>
                                                                            You are limited to $5000 additional credit per transaction. Reach out to sales@ticketliberty.com for more details.
                                                                        </label>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ padding: "11px !important" }}>
                        <LoadingButton
                            type="submit"
                            size="large"
                            disabled={(parseFloat(formData.amount.replace(/,/g, "")) > (parseFloat(userEnterAmount) + parseFloat(removedNegativeBalance(userAvailableCredit)))) || isModalStates?.isButtonDisabled}
                            className={`${(parseFloat(formData.amount.replace(/,/g, "")) > (parseFloat(userEnterAmount) + parseFloat(removedNegativeBalance(userAvailableCredit)))) || isModalStates?.isButtonDisabled ?
                                'theme-btn-generate-licenses-disabled-purchase-cerdit-modal' :
                                "theme-btn-generate-licenses-purchase-cerdit-modal"}`}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Continue
                        </LoadingButton>
                    </DialogActions>
                </ValidatorForm>
            </Dialog >
            {/* change payment method cardinfo  modal */}
            <Dialog
                className="purchase-license-modal"
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="customized-dialog-title"
                open={isVisibilePaymentMethodModal}
                onClick={handleBackdropClick}
                onClose={hnadleCloseChangeCardInfo}
            >
                <ChangePaymentMethod
                    isTermsCondition={isTermsCondition}
                    amount={formData?.amount}
                    isDisabledButton={isModalStates?.isDisabledPurchaseCreditButton}
                    isNeagtive={true}
                    handleAgreeTermsConditon={handleAgreeTermsConditon}
                    handleVisableDeleteConfimationModal={handleVisableDeleteConfimationModal}
                    hnadleCloseChangeCardInfo={hnadleCloseChangeCardInfo}
                    stripeCardList={stripeCardList}
                    getSavedStripeList={getSavedStripeList}
                    ispurchaseLoading={isLoadingSavedCard?.isDisabledRefreshBtn}
                    handleOnChangeCardInfo={handleOnChangeCardInfo}
                    selectedCardInfo={selectedCardInfo}
                    handleUpdateLicense={handlePurchaseCredit}
                    handleAddStripeCustomPayMethod={handleAddStripeCustomPayMethod}
                />
            </Dialog >
            {/* {delete conmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleDeleteStripePaymentMethod}
                isOpen={isDeleteModalShow}
                handleClose={handleCloseDeleteModal}
                message={"Are you sure you want to delete this payment method?"}
                title={"Delete"}
                buttonText={"Delete"}
                buttonText2={"Cancel"}
            />
            {/* paymant history modal */}
            <DataTableModal
                isOpen={isCreditUsage}
                item={creditUsageData.map((row) => {
                    return {
                        ...row,
                        date: customDateFormatLocal(row.created, userprofile?.timezone),
                        description:
                            <div className="container-tablecell" style={{ display: "flex" }}>
                                {row?.heading.length > 35 ? (
                                    <section style={{ width: "81%" }}>
                                        <div className="outer-wrapper-payment">
                                            <div className="inner-wrapper">
                                                {row?.heading}
                                            </div>
                                        </div>
                                    </section>
                                ) : (
                                    <>
                                        {row?.heading}
                                    </>
                                )}
                                {row?.description !== null &&
                                    row?.description ?
                                    <Tooltip
                                        title={row?.description}
                                        arrow
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    fontSize: "16px",
                                                    padding: "10px",
                                                    maxWidth: "350px",
                                                    width: "350px",
                                                },
                                            },
                                        }}
                                    >
                                        <IconButton sx={{ padding: "0px !important" }}>
                                            <TiInfoLarge />
                                        </IconButton>
                                    </Tooltip>
                                    : null}
                            </div>,
                        debit: row.entry_type === "debit"
                            ? (changeDollarAmountformat(Number(row.amount).toFixed(2)))
                            :
                            ("-"),
                        credit: row.entry_type === "debit"
                            ? ("-")
                            : (changeDollarAmountformat(Number(row.amount).toFixed(2))),
                        ending_balance: changeDollarAmountformat(Number(row.balance).toFixed(2)),
                        action: <ReceiptPDFGenerator item_data={row} />

                    }
                }) ?? []}
                closeModal={handlecloseCreditUsageModal}
                isLoading={isLoading}
                title={"Credit Usage"}
                pagination={modalPagination}
                changePageLimit={changePageLimitModal}
                onPageChanged={onPageChangedModal}
                totalRecords={totalRecordsModal}
                SearchDisable={SearchDisableModal}
                columns={columnsModal}
            />
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                duration={duration ?? 2000}
                closeCall={hideAlert}
            />
        </>
    )
}

NegativeCreditModal.propTypes = {
    isVisiableModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    userAvailableCredit: PropTypes.number.isRequired,
    userEnterAmount: PropTypes.number.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired
};

NegativeCreditModal.defaultProps = {
    isVisiableModal: false,
    handleCloseModal: () => { },
    userAvailableCredit: 0,
    userEnterAmount: 5000.00,
    getUserDetails: () => { },
    getNotifications: () => { }
};

export default NegativeCreditModal;
