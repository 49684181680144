import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "components/header/header";
import "./contact.css";
import Footer from "components/footer/footer";
import Commonheader from "components/common-header/common-headre";
import Support from "images/support.png";
import MailIcon from "images/mail-icon.png";
// import Pattern from "images/pattern.png";
import { ValidatorForm, 
  // TextValidator
 } from "react-material-ui-form-validator";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
// import AlertMessage from "components/message/message";
// import MuiPhoneNumberValidatorElement from "components/phoneValidator/phoneValidator";
import "react-phone-number-input/style.css";
import axios from "axios";
import { Box, Button, Card, CircularProgress, Grid } from "@mui/material";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      Marketplace: "",
      POS: "",
      textArea: "",
      error: [],
      loader: false,
      open: false,
      message: "",
      successError: "",
      subHeaderCheck: false,
      phoneNum: "",
      lowerEmailCheck: false,
      lowerEmailMsg: "",
    };
  }
  componentDidMount() {
    // if (!ValidatorForm.hasValidationRule('PhoneStrength')) {
    //     ValidatorForm.addValidationRule('PhoneStrength', (value) => {
    //         // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    //         var re = /^\d{10}$/;
    //         // @".{6,}"
    //         return re.test(value);
    //     });
    // }
    if (!ValidatorForm.hasValidationRule("isPhone")) {
      ValidatorForm.addValidationRule("isPhone", (value) => {
        if (value === null || value.trim() === "" || value.trim() === "+") {
          return false;
        }
        return true;
      });
    }

    if (this.props.location.pathname === "/contact/request") {
      this.setState({
        subHeaderCheck: true,
      });
    } else {
      this.setState({
        subHeaderCheck: false,
      });
    }
  }
  componentWillUnmount() {
    // if (ValidatorForm.hasValidationRule('PhoneStrength')) {
    //     ValidatorForm.removeValidationRule('PhoneStrength');
    // }
    if (ValidatorForm.hasValidationRule("isPhone")) {
      ValidatorForm.removeValidationRule("isPhone");
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeEmail = (e) => {
    if (e.target.value !== e.target.value.toLowerCase()) {
      this.setState({
        lowerEmailCheck: true,
        lowerEmailMsg: "Please enter email in lower case",
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        lowerEmailCheck: false,
        lowerEmailMsg: "",
      });
    }
  };

  handlePhoneChange = (value) => {
    this.setState({
      phoneNum: value,
    });
  };

  handleSubmit = async () => {
    this.setState({ loader: true });
    const data = {
      company_name: this.state.companyName,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone_number: this.state.phoneNum,
      message: this.state.textArea,
    };
    const contact_api_url = ''
    // process.env.REACT_APP_API_URL + "/api/contact/add";
    await axios
      .post(contact_api_url, data)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            textArea: "",

            loader: false,
            open: true,
            message: "your query has been send",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };
  render() {
    const {
      companyName,
      firstName,
      lastName,
      loader,
      email,
      phoneNum,
      textArea,
      subHeaderCheck,
    } = this.state;
    if (!loader) {
      return (
        <HelmetProvider>
          <Helmet>
            <title>Contact Us</title>
          </Helmet>

          <Header />
          <div className="container">
            <div className="common-header-padding">
              <Commonheader
                title="Contact Us"
                description="Let's get in touch"
              />
              <Grid container spacing={1} style={{ marginTop: "60px" }}>
                <Grid item={true} xs={12} className="center">
                  <Card className="contact-Us-Bg">
                    <Grid container spacing={1}>
                      <Grid item={true} xs={12} sm={6} className="contact-col">
                        <div className="plan-img-outer">
                          <img
                            src={Support}
                            className="plan-img"
                            alt="supportImg"
                          />
                        </div>
                        <div className="contact-call">
                          <span>Call now</span>
                          <div>
                            <a href="tel:1 (800) 914-5040">1 (800) 914-5040</a>
                          </div>
                        </div>
                      </Grid>
                      <Grid item={true} xs={12} sm={6} className="contact-col">
                        <div className="plan-img-outer">
                          <img
                            src={MailIcon}
                            className="plan-img"
                            alt="mailImg"
                          />
                        </div>
                        <div className="contact-call">
                          <span>Mail Info</span>
                          <div>
                            <a href="mailto:Sales@TicketLiberty.com">
                              Sales@TicketLiberty.com
                            </a>
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid
                        item={true}
                        xs={12}
                        lg={12}
                        md={12}
                        className="center "
                      >
                        <div className="common-header contact-ques-padding">
                        
                          {subHeaderCheck ? (
                            <div className="common-header">
                              <h2>I would like a demo</h2>
                            </div>
                          ) : (
                            <h2>
                              If you have any questions
                              <br />
                              please contact us
                            </h2>
                          )}

                          <img src={Pattern} alt="pattern" />
                        </div>
                      </Grid> */}
                      {/* <Grid item={true} lg={12} md={12}>
                        <ValidatorForm onSubmit={this.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item={true} xs={12} md={12}>
                              <div className="form-group login-wrapper">
                                <label>Company name</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChange}
                                  name="companyName"
                                  value={companyName}
                                  errorMessages={["This field is required"]}
                                />
                              </div>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                              <div className="form-group login-wrapper">
                                <label>First name</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChange}
                                  name="firstName"
                                  value={firstName}
                                  validators={["required"]}
                                  errorMessages={["This field is required"]}
                                />
                              </div>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                              <div className="form-group login-wrapper">
                                <label>Last name</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChange}
                                  name="lastName"
                                  value={lastName}
                                  validators={["required"]}
                                  errorMessages={["This field is required"]}
                                />
                              </div>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                              <div className="form-group login-wrapper">
                                <label>Email</label>
                                <TextValidator
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.handleChangeEmail}
                                  name="email"
                                  value={email}
                                  validators={["required", "isEmail"]}
                                  errorMessages={[
                                    "This field is required",
                                    "email is not valid",
                                  ]}
                                />
                                {this.state.error.email && (
                                  <div className="error-msg">
                                    {this.state.error.email}
                                  </div>
                                )}
                                {this.state.lowerEmailCheck ? (
                                  <div
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {" "}
                                    {this.state.lowerEmailMsg}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                              <div className="form-group login-wrapper">
                                <label>Phone number</label>
                                <div className="w-100 phone_number_f">
                                  <MuiPhoneNumberValidatorElement
                                    name="phoneNum"
                                    className="phone_number"
                                    data-cy="user-phone"
                                    value={phoneNum}
                                    onChange={this.handlePhoneChange}
                                    fullWidth
                                    variant="outlined"
                                    validators={["isPhone"]}
                                    errorMessages={"This field is required"}
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid item={true} xs={12}>
                              <div className="form-group select-outer">
                                <label>Want us to know anything else?</label>
                                <TextareaAutosize
                                  value={textArea}
                                  name="textArea"
                                  className="text-area-cls"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={4}
                              style={{ margin: "0 auto" }}
                            >
                              <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type="submit"
                                className="theme-btn"

                              // disabled={submitted}
                              >
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                        <AlertMessage
                          open={this.state.open}
                          message={this.state.message}
                          severity={this.state.successError}
                          closeCall={this.handleClose}
                        />
                      </Grid> */}



                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>

          <Footer />
        </HelmetProvider>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default Contact;
