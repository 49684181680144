import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import "react-quill/dist/quill.snow.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { ArrowBack, Cancel } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";
import styled from "@emotion/styled";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Label = (props) => (
  <Typography
    variant="body2"
    color="textSecondary"
    {...props}
    sx={{
      textAlign: "left",
      marginBottom: "10px",
      fontSize: "16px",
    }}
  />
);

class Addcustomcredit extends React.Component {
  state = {
    loader: false,
    UserValues: [],
    user: [],
    credit: "",
    notes: "",
    fixedOptions: [],
    value: [],
    isOpen: false,
    addCreditBuutonstatus: false,
  };
  async componentDidMount() {
    this.getAllSettings()
    this.setState({
      loader: true,
    });
    // console.log("this.props", this.props.match.params.id)
    // User List Id
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    await axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const sorted = data.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });

          this.setState({
            loader: false,
            UserValues: sorted,
            open: false,
            message: "",
            successError: "success",
          });

          var arr = [];
          if (this.props.match.params.id !== undefined) {
            this.state.UserValues.map((data) => {
              if (data._id === this.props.match.params.id) {
                arr.push(data);
              }
              return null;
            });
          }

          this.setState({
            value: arr,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  handleUserList = (event, newValue) => {
    this.setState({
      value: [
        ...this.state.fixedOptions,
        ...newValue.filter(
          (option) => this.state.fixedOptions.indexOf(option) === -1
        ),
      ],
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeNotes = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const arr = [];
    this.state.value.map((data) => {
      arr.push(data._id);
      return null;
    });

    this.setState({
      loader: true,
    });

    const data = {
      user: arr,
      credit: this.state.credit,
      notes: this.state.notes,
    };

    const addCustomCredit_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/addCredit";

    await axios
      .post(addCustomCredit_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };

  getAllSettings = async () => {
    try {
      const datas = {
        id: "60633a028f70d97537accede",
      };
      const account_api_url =
        process.env.REACT_APP_API_URL + "/api/setting/detail";
      const response = await axios.post(account_api_url, datas, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data?.error) {
        this.setState({
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      } else {
        this.setState({ addCreditBuutonstatus: data?.data?.add_credit_btn_status });
      }
    } catch (error) {
      this.setState({
        open: true,
        message: "Something went wrong, please try again",
        successError: "error",
      });
    }
  }


  handleUpdateSettingforButton = async () => {
    try {
      const datas = {
        id: "60633a028f70d97537accede",
        add_credit_btn_status: this.state.addCreditBuutonstatus
      };
      const account_api_url =
        process.env.REACT_APP_API_URL + "/api/setting/update";
      const response = await axios.post(account_api_url, datas, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = response;
      if (data?.error) {
        this.setState({
          isOpen: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      } else {
        this.getAllSettings();
        this.setState({
          isOpen: false,
          open: true,
          message: "Setting Updated successfully",
          successError: "success",
        });
      }
    } catch (error) {
      this.setState({
        isOpen: false,
        open: true,
        message: "Something went wrong, please try again",
        successError: "error",
      });
    }
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false })
  }

  handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  handleStatusChange = (event) => {
    this.setState({ addCreditBuutonstatus: event.target.checked });
  };


  render() {
    const { credit, loader } = this.state;
    return (
      <>
        <Helmet>
          <title>Add Credit</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Add Credit"
                action={
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, lg: 1 }}
                    columns={{ xs: 4, sm: 8, md: 8 }}
                    className="button-align"
                  >
                    <Grid item>
                      <Button
                        onClick={() => this.setState({ isOpen: true })}
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow!important",
                        }}
                      >
                        Manage Add Credit
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => this.props.history.push(`/user-management-view/${this.props.match.params.id}`)}
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow!important",
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Label>User</Label>
                        <Autocomplete
                          multiple
                          value={this.state.value}
                          onChange={this.handleUserList}
                          id="tags-outlined"
                          options={
                            this.state.UserValues !== undefined
                              ? this.state.UserValues
                              : ""
                          }
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              value={this.state.value}
                              variant="outlined"
                              placeholder="Add User"
                              validators={["required"]}
                              errorMessages={"This field is required"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label>Add Credit</Label>

                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="credit"
                          type="text"
                          value={credit}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "this field is required",
                            "only number allowed",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label>Note</Label>
                        <TextareaAutosize
                          name="notes"
                          className="text-area-cls"
                          onChange={this.handleChangeNotes}
                        />
                      </Grid>

                      {/* <Grid item xs={12}>
                                            <div className="form-group login-wrapper">
                                                <label>Package Order</label>
                                                <TextValidator
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    name="orderNum"
                                                    type="text"
                                                    value={orderNum}
                                                    validators={['required', 'isNumber']}
                                                    errorMessages={('this field is required'), ('only number allowed')}
                                                />

                                            </div>
                                        </Grid> */}

                      <Grid item xs={4} sx={{ margin: "10px auto" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          size="large"
                          // disabled={submitted}
                          sx={{ paddingY: "0.9rem" }}
                          className="theme-btn-submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                )}

                <Dialog
                  onClick={this.handleBackdropClick}
                  maxWidth="sm"
                  open={this.state.isOpen}
                  onClose={(e) => this.handleCloseModal(e)}
                  sx={{
                    "& .MuiDialog-paper": {
                      width: "100%",
                    },
                  }}
                >
                  <DialogTitle>Manage Add Credit Button</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={(e) => this.handleCloseModal(e)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <Cancel />
                    </IconButton>
                    <DialogContentText
                      sx={{
                        fontFamily: "Barlow",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "black",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left!important",
                              padding: "10px 0px",
                            }}
                          >
                            Status
                          </Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Android12Switch
                                  name="status"
                                  defaultChecked
                                  checked={this.state.addCreditBuutonstatus}
                                  onChange={this.handleStatusChange}
                                />}
                              label={this.state.addCreditBuutonstatus ? "Enabled" : "Disabled"}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.handleCloseModal()}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => this.handleUpdateSettingforButton()}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                <AlertMessage
                  open={this.state.open}
                  message={this.state.message}
                  severity={this.state.successError}
                  closeCall={this.handleClose}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Addcustomcredit;
