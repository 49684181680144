import { ActionTypes } from "../types/ActionTypes";
import Api_List  from '../Api-list';
import axios from 'axios';
import Cookies from "js-cookie";

export const RefreshVendorEvent = (data) => async (dispatch) => {

    try {
        const res = await axios.post(Api_List.RefreshVendorEvent, data , { headers: { authorization: 'Bearer '+ Cookies.get('accessToken')} });
        dispatch({
            type: ActionTypes.REFRESHVENDOREVENT,
            payload: res.data
        })
    }
    catch (error) {
        console.log(error);
    }
} 
