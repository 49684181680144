import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Papa from "papaparse";
import axios from "axios";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

// mui icon and svg icon
import DownloadIcon from "@mui/icons-material/Download";
import LoadPassword from "images/load_password.svg"
import { useDispatch, useSelector } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { HiDotsHorizontal, HiOutlineSearch } from "react-icons/hi";
import { styled } from "@mui/material/styles";

// mui components
import { ValidatorForm } from "react-material-ui-form-validator";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import {
    CardContent,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Button,
    Card,
    Grid,
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
} from "@mui/material";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from 'components/user-sidebar/user-sidebar'
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from "components/message/message";
import Pagination from "components/pagination/pagination";
import UserProfileAction from "redux/actions/UserProfielAction";
import TMPasswordManagerView from "./ViewSingleUserPass";
import EditTMPassword from "./EditTMPassword";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary["dark"],
        color: theme.palette.common.white,
        fontFamily: "Barlow !important",
        fontWeight: "bold",
        textAlign: "left",
        position: "relative",
        // width: "100%",
        minWidth: "max-content",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        fontFamily: "Barlow !important",
        textAlign: "left",
        color: "#3C4E6A",
        borderBottom: "1px solid #CCCCCC",
        width: "auto",
        padding: "12px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const actionbutton = {
    backgroundColor: "#002986!important",
    color: "white!important",
    padding: "2px 5px!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
};

const TMPasswordManager = (props) => {
    const history = useHistory()
    const [formData, setFormData] = useState({
        username: "",
        skip: 0,
        limit: 10,
    });
    const [message, setMessage] = useState({
        alertseverity: '',
        message: '',
        openalert: false
    })
    const [usersList, setUsersList] = useState([]);
    const [singleUserDataList, setSingleUserDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBulkupLoading, setIsBulkupLoading] = useState(false)
    const [isHidePassword, setIsHidePassword] = useState(false);
    const [isLoadingPass, setLoadingPass] = useState(false);
    const [veiwListVisibleModel, setVeiwListVisibleModel] = useState(false)
    const [isEditOpenModel, setIsEditOpenModel] = useState(false)
    const [passIndexNumber, setPassIndexNumber] = useState('');
    // pagention
    const getUserProfile = useSelector((state) => state.UserProfile);
    const [totalRecords, setTotalRecords] = React.useState(10);
    const [pageLimit, setPageLimit] = React.useState(parseInt(10));
    const [searchPage, setSearchPage] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    const [editid, setEditid] = useState('')
    const dispatch = useDispatch();

    const getUsers = async (skippage, limit, msg = "", type = "success") => {
        const paramas = {
            ...formData,
            limit: limit,
            skip: skippage
        }
        try {
            setIsLoading(true);
            const get_user_password = process.env.REACT_APP_API_URL + "/api/account/getTicketmasterAccs";
            const response = await axios.post(get_user_password, paramas, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsLoading(false);
            } else {
                setTotalRecords(data.count)
                if (data.data !== undefined && data.data !== null) {
                    const items = data.data.map((data, index) => ({
                        id: data._id,
                        UserName: data.username,
                        Password: data.password,
                    }));
                    setUsersList(items);
                } else {
                    console.log(data);
                }
                console.log(msg)
                setMessage({
                    ...message,
                    message: msg,
                    openalert: msg === "" ? false : true,
                    alertseverity: type
                })
                setIsLoading(false);
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            })
            setIsLoading(false);
        }
    };


    const hiddenPassword = async (itemval) => {
        if (isHidePassword && passIndexNumber === itemval.id) {
            setIsHidePassword(false);
            setPassIndexNumber("");
            setLoadingPass(false);
        } else {
            try {
                setIsHidePassword(true);
                setPassIndexNumber(itemval.id);
                setLoadingPass(true);
                const pass_api_url = process.env.REACT_APP_API_URL + "/api/account/accountDetailWithPlainPass";
                const response = await axios.post(pass_api_url, { id: itemval.id }, {
                    headers: {
                        authorization:
                            "Bearer " + Cookies.get("accessToken"),
                    },
                });
                const { data } = response;
                if (data.error) {
                    setIsHidePassword(false);
                    setPassIndexNumber("");
                    setLoadingPass(false);
                    setMessage({
                        ...message,
                        message: 'Something went wrong',
                        openalert: true,
                        alertseverity: "error"
                    })
                } else {
                    var index = usersList.map((v) => { return v.id }).indexOf(itemval.id);
                    usersList[index]["password"] = data.data.password;
                    setUsersList(usersList)
                    setLoadingPass(false);
                }
            } catch (error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsHidePassword(false);
                setPassIndexNumber("");
                setLoadingPass(false);
            }
        }
    }
    const updateProfile = async (limit) => {
        try {
            setIsLoading(true)
            const updateDetail_api_url = process.env.REACT_APP_API_URL + "/api/user/updateUser";
            const response = await axios.post(updateDetail_api_url, {
                per_page_limit: limit
            }, {
                headers: {
                    authorization:
                        "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
            } else {
                dispatch(UserProfileAction());
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            })
        }
    }
    const uploadBulkCsv = (event) => {
        var commonConfig = { delimiter: "," };
        Papa.parse(event.target.files[0], {
            ...commonConfig,
            header: true,
            download: true,
            complete: (result) => {
                var temp = [];
                var finalArr = [];

                result.data.forEach(function myFunction(item, index) {
                    temp.forEach(function myFunction(itemT, indexT) {
                        if (
                            itemT.Username?.trim() === item.Username?.trim() &&
                            item.Username !== ""
                        ) {
                            finalArr.push(item);
                        }
                    });
                    temp.push(item);
                });

                if (finalArr.length > 0) {
                    console.log("Duplicate available");
                    setMessage({
                        ...message,
                        message: 'There are some duplicate records in your file. Please remove and try again',
                        openalert: true,
                        alertseverity: "error"
                    })
                } else {
                    const selectedFile = event.target.files[0];
                    if (
                        selectedFile.type === "application/vnd.ms-excel" ||
                        selectedFile.type === "text/csv"
                    ) {
                        const formData = new FormData();
                        formData.append("File", selectedFile);

                        const bulk_api_url =
                            process.env.REACT_APP_API_URL + "/api/account/updateVendorPassCsv";
                        setIsBulkupLoading(true);
                        axios
                            .post(bulk_api_url, formData, {
                                headers: {
                                    authorization:
                                        "Bearer " + Cookies.get("accessToken"),
                                },
                            })
                            .then((response) => {
                                const { data } = response;
                                if (data.error) {
                                    setMessage({
                                        ...message,
                                        message: data.message,
                                        openalert: true,
                                        alertseverity: "error"
                                    })
                                    setIsBulkupLoading(false);
                                } else {
                                    var message = "";
                                    if (data.total > 0) {
                                        message +=
                                            "Total emails: " + data.total + "<br />";
                                    }
                                    if (data.updatedPass > 0) {
                                        message +=
                                            "Email password updated: " +
                                            data.updatedPass +
                                            "<br />";
                                    }
                                    if (data.passNotUpdated > 0) {
                                        message +=
                                            "Email password not updated: " +
                                            data.passNotUpdated +
                                            "<br />";
                                    }
                                    setIsBulkupLoading(false)
                                    getUsers(skip, pageLimit, message)
                                }
                            })
                            .catch((error) => {
                                setMessage({
                                    ...message,
                                    message: 'Something went wrong, please try again',
                                    openalert: true,
                                    alertseverity: "error"
                                })
                                setIsBulkupLoading(false)
                            });
                    } else {
                        setMessage({
                            ...message,
                            message: "Only csv file allowed",
                            openalert: true,
                            alertseverity: "error"
                        })
                        setIsBulkupLoading(false)
                    }
                }
            },
        });
    };

    const handleOnChangeInput = (event) => {
        setFormData({ ...formData, username: event.target.value })
    }

    const handleClose = () => {
        setMessage({
            ...message,
            openalert: true,
        })
    };

    const changepageLimit = (e) => {
        setPageLimit(parseInt(e.target.value));

        if (e.target.value) {
            updateProfile(parseInt(e.target.value));
        }
        setSkip(0);
        getUsers(0, parseInt(e.target.value));
        setSearchPage(1);
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setSearchPage(currentPage - 1);
        setSkip((currentPage - 1) * pageLimit);
        getUsers((currentPage - 1) * pageLimit, pageLimit);
    };

    const SearchDisable = () => {
        setSearchPage(0);
        setSkip(0);
    };

    const handleViewvisiableModel = (user) => {
        setIsHidePassword(false);
        setPassIndexNumber("");
        setLoadingPass(false);
        handleViweSigleUsers(user)
        setVeiwListVisibleModel(true)
    }


    const handleGetUsers = async (skippage, msg, type = "success") => {
        const limit = parseInt(getUserProfile.data.per_page_limit);
        setPageLimit(limit);
        const paramas = {
            ...formData,
            limit: limit,
            skip: skippage
        }
        try {
            setIsLoading(true);
            const get_user_password = process.env.REACT_APP_API_URL + "/api/account/getTicketmasterAccs";
            const response = await axios.post(get_user_password, paramas, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.tokenExpired && data.error) {
                setMessage({
                    ...message,
                    open: true,
                    message: data?.message,
                    alertColor: 'error'
                });
                Cookies.remove('at')
                Cookies.remove('accessToken')
                history.push("/login")
            } else if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsLoading(false);
            } else {
                setTotalRecords(data.count)
                if (data.data !== undefined && data.data !== null) {
                    const items = data.data.map((data, index) => ({
                        id: data._id,
                        UserName: data.username,
                        Password: data.password,
                    }));
                    setUsersList(items);
                    setMessage({
                        ...message,
                        message: msg,
                        openalert: msg === undefined || "" ? false : true,
                        alertseverity: type
                    })
                } else {
                    console.log(data);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            })
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleGetUsers()
    }, []);

    const handleViweSigleUsers = async (user) => {
        const paramas = {
            account_type: ["Vendor"],
            username: user,
            skip: 0,
            limit: 10,
        }
        try {
            setIsLoading(true);
            const get_user_password = process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
            const response = await axios.post(get_user_password, paramas, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsLoading(false);
            } else {
                setTotalRecords(data.count)
                if (data.data !== undefined && data.data !== null) {
                    const items = data.data
                        .filter((item) => item.account_type === "Vendor")
                        .map((data, index) => ({
                            id: data._id,
                            Team: data.team,
                            UserName: data.username,
                            Password: data.password,
                        }));
                    setSingleUserDataList(items);
                } else {
                    console.log(data);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            })
            setIsLoading(false);
        }
    };

    const handleEditVisiableModel = (id) => {
        setEditid(id);
        setIsEditOpenModel(true)
    }

    return (
        <>
            <div className="user-dashboard-wrapper">
                <Helmet>
                    <title> TMPasswordManager</title>
                </Helmet>
                <Header />
                <div className="c-app c-default-layout user-dashboard-body vendorlist">
                    <Usersidebar />
                    <div className="c-wrapper">
                        {!isEditOpenModel ? (
                            <>
                                {!veiwListVisibleModel ? (
                                    <div className="c-body">
                                        <main className="c-main">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="space-top-col">
                                                    <Card
                                                        sx={{
                                                            width: "100%",
                                                            maxWidth: "100%",
                                                            color: "#000059!important",
                                                            textAlign: "left",
                                                            padding: "30px",
                                                            backgroundColor: "#fbfbfb;",
                                                        }}
                                                    >
                                                        <div className="vendortable-header">
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    width: "100%",
                                                                    maxWidth: "265px",
                                                                    color: "#000059!important",
                                                                    textAlign: "left",
                                                                    fontWeight: "bold",
                                                                    fontSize: "1.6rem",
                                                                    fontFamily: "Barlow!important",
                                                                }}
                                                            >
                                                                {" "}
                                                                TM Password Manager
                                                            </Typography>

                                                            <Grid
                                                                container
                                                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                                                columns={{ xs: 4, sm: 8, md: 8 }}
                                                                className="button-align"
                                                            >
                                                                <Grid item>
                                                                    <Button
                                                                        component="a"
                                                                        size="large"
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: "#002986!important",
                                                                            color: "white!important",
                                                                            textTransform: "none!important",
                                                                            fontWeight: "bold",
                                                                            fontFamily: "Barlow!important",
                                                                        }}
                                                                        href={require("images/TM-password-Manager.csv")}
                                                                        download="Sample.csv"
                                                                        startIcon={<DownloadIcon />}
                                                                        to="/add-vendor"
                                                                    >
                                                                        Download Sample CSV
                                                                    </Button>
                                                                </Grid>

                                                                <Grid item>
                                                                    <input
                                                                        style={{ display: "none" }}
                                                                        id="actual-csvuploader"
                                                                        type="file"
                                                                        onChange={(e) => uploadBulkCsv(e)}
                                                                    />

                                                                    <Button
                                                                        component="label"
                                                                        htmlFor="actual-csvuploader"
                                                                        size="large"
                                                                        variant="contained"
                                                                        sx={{
                                                                            backgroundColor: "#002986!important",
                                                                            color: "white!important",
                                                                            textTransform: "none!important",
                                                                            fontWeight: "bold",
                                                                            fontFamily: "Barlow!important",
                                                                        }}
                                                                        startIcon={<UploadFileIcon />}
                                                                    >
                                                                        {" "}
                                                                        {isBulkupLoading
                                                                            ? "Uploading"
                                                                            : "Upload CSV"}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <ValidatorForm
                                                            onSubmit={() => getUsers(skip, pageLimit)}
                                                            className="filter-form mb-5"
                                                        >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    sx={{
                                                                        textAlign: "left",
                                                                        marginTop: "10px",
                                                                        marginBottom: "20px",
                                                                    }}
                                                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                                                >
                                                                    <Grid item xs={12} sm={8} md={4} className="Vendor-input1">
                                                                        <label>Username</label>
                                                                        <TextField
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            placeholder="Username"
                                                                            size="small"
                                                                            name="username"
                                                                            onChange={(event) => handleOnChangeInput(event)}
                                                                            value={formData.username}
                                                                            style={{ width: "100%", marginTop: "3px" }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={2.4} sx={{ position: 'relative', top: '36px' }} >
                                                                        <Button
                                                                            variant="contained"
                                                                            type="submit"
                                                                            className="filter-btn"
                                                                            startIcon={<HiOutlineSearch />}
                                                                        >
                                                                            Search
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </ValidatorForm>
                                                        {!isLoading ? (
                                                            <CardContent
                                                                sx={{
                                                                    padding: "0px!important",
                                                                    marginTop: "10px!important",
                                                                }}
                                                                className="plan-tbl vendor-outer"
                                                            >
                                                                <TableContainer component={Paper}>
                                                                    <Table sx={{ minWidth: 900 }} aria-label="customized table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell>
                                                                                    UserName
                                                                                </StyledTableCell>
                                                                                <StyledTableCell>
                                                                                    Password
                                                                                </StyledTableCell>
                                                                                <StyledTableCell>
                                                                                    Account Settings
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {usersList && usersList.length > 0 ? (<>
                                                                                {usersList && usersList?.map((user, index) => (
                                                                                    <StyledTableRow key={index}>
                                                                                        <StyledTableCell component="td" scope="row">
                                                                                            {user.UserName}
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell
                                                                                            component="th"
                                                                                            scope="row"
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    minWidth: "max-content",
                                                                                                }}
                                                                                            >
                                                                                                <div className="flex">
                                                                                                    <div className="flex">
                                                                                                        <div
                                                                                                            style={{
                                                                                                                width: "fit-content",
                                                                                                                display: "flex",
                                                                                                                alignItems: "center",
                                                                                                                flex: "wrap",
                                                                                                                wordBreak: "break-all",
                                                                                                            }}
                                                                                                        >
                                                                                                            {passIndexNumber === user?.id ? (
                                                                                                                <>
                                                                                                                    {isLoadingPass ? (
                                                                                                                        <img src={LoadPassword} alt="" />
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            {isHidePassword
                                                                                                                                ? user?.password
                                                                                                                                : "*******"}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                    <VisibilityOffIcon
                                                                                                                        style={{
                                                                                                                            cursor: "pointer",
                                                                                                                            fontSize: "1.3rem",
                                                                                                                            color: "#002986",
                                                                                                                        }}
                                                                                                                        onClick={() =>
                                                                                                                            hiddenPassword(user)
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <HiDotsHorizontal
                                                                                                                        style={{
                                                                                                                            margin: "0px",
                                                                                                                            padding: "0px",
                                                                                                                            position: "relative",
                                                                                                                            left: "2px",
                                                                                                                            fontSize: "30px",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <HiDotsHorizontal
                                                                                                                        style={{ fontSize: "30px" }}
                                                                                                                    />
                                                                                                                    <VisibilityIcon
                                                                                                                        style={{
                                                                                                                            cursor: "pointer",
                                                                                                                            fontSize: "1.3rem",
                                                                                                                            color: "#002986",
                                                                                                                        }}
                                                                                                                        onClick={() =>
                                                                                                                            hiddenPassword(user)
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="td" scope="row">
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    flexDirection: "row",
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    onClick={() => handleViewvisiableModel(user.UserName)}
                                                                                                    size="small"
                                                                                                    variant="contained"
                                                                                                    sx={actionbutton}
                                                                                                >
                                                                                                    View
                                                                                                </Button>
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    size="small"
                                                                                                    variant="contained"
                                                                                                    sx={actionbutton}
                                                                                                    onClick={() => {
                                                                                                        handleEditVisiableModel(user.id)
                                                                                                    }}
                                                                                                >
                                                                                                    Edit
                                                                                                </Button>
                                                                                            </div>
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                ))}
                                                                            </>
                                                                            ) : (
                                                                                <StyledTableRow>
                                                                                    <td
                                                                                        colSpan={13}
                                                                                        style={{ padding: "2.6rem 1rem" }}
                                                                                    >
                                                                                        <Typography
                                                                                            variant="h6"
                                                                                            sx={{
                                                                                                fontSize: "1.6rem",
                                                                                                textAlign: "center",
                                                                                            }}
                                                                                        >
                                                                                            No Records Found
                                                                                        </Typography>
                                                                                    </td>
                                                                                </StyledTableRow>
                                                                            )}

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </CardContent>
                                                        ) : (
                                                            <ContainerLoader />
                                                        )}
                                                        <br />
                                                        {totalRecords > 0 && (
                                                            <Pagination
                                                                key="paginationnew"
                                                                searchPage={searchPage ?? 0}
                                                                SearchDisable={SearchDisable}
                                                                totalRecords={totalRecords ?? 10}
                                                                pageLimit={pageLimit ?? 10}
                                                                onPageChanged={onPageChanged}
                                                            />
                                                        )}

                                                        {totalRecords > 0 && (
                                                            <div
                                                                style={{
                                                                    textAlign: "right",
                                                                    display: "flex",
                                                                    justifyContent: "end",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <label>Items per page :</label>
                                                                <FormControl
                                                                    sx={{
                                                                        m: 1,
                                                                        minWidth: 50,
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <Select
                                                                        defaultValue={pageLimit}
                                                                        value={pageLimit}
                                                                        name="limit"
                                                                        sx={{
                                                                            "& .MuiSelect-select": {
                                                                                padding: "4px 14px",
                                                                                fontFamily: "Barlow",
                                                                            },
                                                                        }}
                                                                        onChange={(e) => changepageLimit(e)}
                                                                    >
                                                                        <MenuItem value="10">10</MenuItem>
                                                                        <MenuItem value="20">20</MenuItem>
                                                                        <MenuItem value="50">50</MenuItem>
                                                                        <MenuItem value="100">100</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </main>
                                    </div>
                                ) : (
                                    <>
                                        <TMPasswordManagerView
                                            setVeiwListVisibleModel={setVeiwListVisibleModel}
                                            singleUserDataList={singleUserDataList}
                                            setSingleUserDataList={setSingleUserDataList}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            handleClose={handleClose}
                                            handleGetUsers={handleGetUsers}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <EditTMPassword
                                setIsEditOpenModel={setIsEditOpenModel}
                                id={editid}
                            />
                        )}
                    </div>
                </div>
                <Footer />
                <AlertMessage
                    open={message.openalert}
                    message={message.message}
                    severity={message.alertseverity}
                    closeCall={handleClose}
                />
            </div >

        </>
    );
};
export default TMPasswordManager;