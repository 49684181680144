import {SIGNUPREQUEST, SIGNUPLOADING, SIGNUPSUCCESS} from '../actions/Signup';

const signUpReduc =(state=[], action)=>{
    switch(action.type){
        case SIGNUPREQUEST:
        return{
            ...state,
            payload:action.payload,
        }
        case SIGNUPLOADING:
            return{
                ...state,
                payload:{}
            }
        case SIGNUPSUCCESS:
            return{
                ...state,
                payload:action.payload,
                isLoading:true
            }
        default :
            return state
    }
}
export default signUpReduc;