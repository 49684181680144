import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertMessage from "./alert/alert";
import { Helmet } from "react-helmet";
import {
  Card,
  Grid,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";

class VbdTicketPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        id: "",
        sold_message_templete: "",
        confimation_message_templete: "",
        followup_message_templete: "",
        ticket_price: 0,
        axs_link_price: 0,
        nt_ticket_price: 0,
      },
      loader: true,
      showform: false,
      message: "",
      formSubmit: false,
      error: [],
      openalert: false,
      alertseverity: "",
    };
    this.inputRef = React.createRef();
  }

  async componentDidMount() {
    const account_api_url =
      process.env.REACT_APP_API_URL + "/api/setting/detail";
    const data = {
      id: "60633a028f70d97537accede",
    };
    await axios
      .post(account_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            message: "Something went wrong while accessing users list",
            alertseverity: "error",
            openalert: true,
          });
        } else {
          const formData = {
            id: data.data._id,
            sold_message_templete: data.data.sold_message_templete,
            confimation_message_templete:
            data.data.confimation_message_templete,
            followup_message_templete: data.data.followup_message_templete,
            ticket_price: data.data.ticket_price,
            axs_link_price: data.data.axs_link_price,
            nt_ticket_price: data.data.nt_ticket_price
          };
          this.setState({ loader: false, formData, showform: true });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          message: "Something went wrong while accessing users list",
          alertseverity: "error",
          openalert: true,
        });
      });
  }

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  handleSubmit = async (e) => {
    this.setState({ formSubmit: true });

    const addUser_api_url =
      process.env.REACT_APP_API_URL + "/api/setting/update";
    axios
      .post(addUser_api_url, this.state.formData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            formSubmit: false,
            message: data.message,
            alertseverity: "error",
            openalert: true,
          });
        } else {
          this.setState({
            formSubmit: false,
            message: data.message,
            alertseverity: "success",
            openalert: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          formSubmit: false,
          message: "Something went wrong while submitting form",
          alertseverity: "error",
          openalert: true,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({
      openalert: false,
    });
  };

  render() {
    const { formData, loader, showform } = this.state;

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Settings</title>
          </Helmet>
          {showform && (
            <Grid container spacing={2}>
              <Grid item={true} xs={12}>
                <Card>
                  <CardHeader
                    title=" VBD Ticket Setting"
                    
                  />
                  <Divider />
                  <CardContent>
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "0.5rem",
                            }}
                          >
                            Ticket Price
                          </Typography>
                          <TextValidator
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            name="ticket_price"
                            type="number"
                            value={formData.ticket_price}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "0.5rem",
                            }}
                          >
                            AXS Link  Price
                          </Typography>
                          <TextValidator
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            name="axs_link_price"
                            type="number"
                            value={formData.axs_link_price}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "0.5rem",
                            }}
                          >
                            Non-Transferable Ticket Price
                          </Typography>
                          <TextValidator
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            name="nt_ticket_price"
                            type="number"
                            value={formData.nt_ticket_price}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ margin: "0 auto" }}>
                          <Button
                            size="large"
                            variant="contained"
                            sx={{ paddingY: "0.9rem" }}
                            type="submit"
                            fullWidth
                            className="theme-btn-submit"
                          >
                            Update Setting
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                    <AlertMessage
                      open={this.state.openalert}
                      message={this.state.message}
                      severity={this.state.alertseverity}
                      closeCall={this.handleClose}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default VbdTicketPrice;
