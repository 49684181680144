import React from "react";
import {
  CAlert,
} from "@coreui/react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import MomentTimezone from "moment-timezone";
import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";

import "../common.css";
import DataTable from "components/DataTable/DataTable";

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      leagues: [],
      modalShow: false,
      deleteId: "",
      deleting: false,
      loader: true,
      loaderleagues: true,
      open: false,
      message: "",
      alertcolor: "",
      modalform: {
        show: false,
        id: "",
        title: "",
        name: "",
        league: "",
        timezone: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
      },
    };
  }

  componentDidMount() {
    this.getListsLeagues();
    this.getLists();
  }

  getListsLeagues = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllLeagues";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.data) {
          this.setState({
            loaderleagues: false,
            leagues: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllTeams";
    axios
      .post(
        list_api_url,
        {},
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false, deleteId: "" });
  };

  callDelete = () => {
    this.setState({ deleting: true });
    const data = { _id: this.state.deleteId };
    const delete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteTeam";
    axios
      .post(delete_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            alertcolor: "error",
          });
        } else {
          if (data.success) {
            this.getLists();
            this.setState({
              modalShow: false,
              open: true,
              message: "Deleted successfully!",
              alertcolor: "success",
              deleting: false,
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  setModalFormShow = (id, title, name, league, timezone) => {
    const modalform = {
      show: true,
      id: id,
      title: title,
      name: name,
      league: league,
      timezone: timezone,
      submitting: false,
    };
    this.setState({ modalform });
  };

  onHideForm = () => {
    const modalform = {
      show: false,
      id: "",
      title: "",
      name: "",
      league: "",
      submitting: false,
    };
    this.setState({ modalform });
  };

  handleSubmit = () => {
    const { modalform } = this.state;
    modalform.submitting = true;
    this.setState({ modalform }, () => {
      let form_data = {
        name: modalform.name,
        league: modalform.league,
        timezone: modalform.timezone,
      };
      let form_api_url = process.env.REACT_APP_API_URL + "/api/admin/addTeam";
      let successmsg = "Added successfully!";
      if (modalform.id !== "") {
        form_api_url = process.env.REACT_APP_API_URL + "/api/admin/updateTeam";
        form_data = {
          name: modalform.name,
          _id: modalform.id,
          league: modalform.league,
          timezone: modalform.timezone,
        };
        successmsg = "Updated successfully!";
      }

      axios
        .post(form_api_url, form_data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            modalform.submitting = false;
            modalform.formalert = true;
            modalform.formmessage = data.data.message;
            modalform.formalertcolor = "error";
            this.setState({ modalform });
          } else {
            if (data.success) {
              this.getLists();
              const modalform_new = {
                show: false,
                id: "",
                title: "",
                name: "",
                league: "",
                submitting: false,
                formalert: false,
                formmessage: "",
                formalertcolor: "",
              };
              this.setState({
                modalform: modalform_new,
                open: true,
                message: successmsg,
                alertcolor: "success",
              });
            } else {
              this.setState({
                formalert: true,
                formmessage: data.data.message,
                formalertcolor: "error",
              });
            }
          }
        })
        .catch((error) => {
          modalform.submitting = false;
          modalform.formalert = true;
          modalform.formmessage = "Something went wrong";
          modalform.formalertcolor = "error";
          this.setState({ modalform });
        });
    });
  };

  handleChange = (e) => {
    const { modalform } = this.state;
    modalform.name = e.target.value;
    this.setState({ modalform });
  };

  handleChangeLeague = (e, newVal) => {
    const { modalform } = this.state;
    if (newVal) {
      modalform.league = newVal._id;
    } else {
      modalform.league = "";
    }
    this.setState({ modalform });
  };

  handleChangeTimezone = (e, newVal) => {
    const { modalform } = this.state;
    if (newVal) {
      modalform.timezone = newVal;
    } else {
      modalform.timezone = "";
    }
    this.setState({ modalform });
  };

  render() {
    const { lists, modalShow, loader, loaderleagues, modalform } = this.state;
    const fields = [
      { key: "name" ,label:"Name" },
      { key: "timezone" ,label:"Timezone" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
      },
    ];
    var timezones = MomentTimezone.tz.names();

    if (!loader && !loaderleagues) {
      return (
        <>
          <Helmet>
            <title>Team List</title>
          </Helmet>
          <CAlert
            color={this.state.alertcolor}
            closeButton
            show={this.state.open}
          >
            {this.state.message}
          </CAlert>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title="Team List"

                  action={
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        this.setModalFormShow("", "Add Team", "", "", null)
                      }
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add Team
                    </Button>
                  }
                />
                <Divider />

                <CardContent>
                  <DataTable
                    tableFilter
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      action: (item) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              onClick={() =>
                                this.setModalFormShow(
                                  item._id,
                                  "Update Team",
                                  item.name,
                                  item.league,
                                  item.timezone
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.setModalShow(item._id)}
                              aria-label="delete"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                      timezone: (item) => {
                        return item.timezone?.replace("US/", "");
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalShow}
            onClose={this.onHide}
          >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={() => this.onHide()}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogContentText>
                Are you sure you want to Delete, this action can not be revert ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onHide}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.callDelete}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalform.show}
            onClose={this.onHideForm}
          >
            <DialogTitle>{modalform.title}</DialogTitle>
            <ValidatorForm onSubmit={this.handleSubmit}>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.onHideForm()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <label>Team name</label>
                    <TextValidator
                      variant="outlined"
                      fullWidth
                      name="names"
                      placeholder="Team name"
                      onChange={this.handleChange}
                      value={modalform.name}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>League</label>
                    <Autocomplete
                      onChange={this.handleChangeLeague}
                      id="tags-outlined"
                      options={this.state.leagues}
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        this.state.leagues.filter(
                          (v) => v._id === modalform.league
                        )[0]
                      }
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          value={modalform.league}
                          variant="outlined"
                          placeholder="Select League"
                          validators={["required"]}
                          errorMessages={"This field is required"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Timezone</label>
                    <Autocomplete
                      onChange={this.handleChangeTimezone}
                      id="tags-outlined"
                      options={timezones.filter((tzz) => tzz.includes("US/"))}
                      getOptionLabel={(option) => option.replace("US/", "")}
                      defaultValue={modalform.timezone}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          value={modalform.timezone}
                          variant="outlined"
                          placeholder="Select Timezone"
                          validators={["required"]}
                          errorMessages={"This field is required"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onHideForm}
                  type="button"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={modalform.submitting}
                  type="submit"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }

    if (loaderleagues) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "13rem 0",
          }}
        >
          <CircularProgress sx={{ color: "#002986" }} />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default Teams;
