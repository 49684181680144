import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';

//mui and bootstrap @Component
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Button, Form, Row, Col } from 'react-bootstrap';

// components
import './Announcement.scss'
import AlertMessage from 'components/message/message';
export default class Announcement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            announcementText: "",
            status: false,
            open: false,
            message: '',
            successError: '',
            id: ""

        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        const data = {
            announcement_text: this.state.announcementText,
            announcement_status: this.state.status,
            id: this.state.id
        }
        const updateAnnouncement = process.env.REACT_APP_API_URL + "/api/setting/update"
        axios.post(updateAnnouncement, data, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response
            if (data.error) {
                this.setState({
                    open: true,
                    message: data.message,
                    successError: 'error'
                })
            } else {
                this.setState({
                    open: true,
                    message: data.message,
                    successError: 'success'
                })
            }
        }).catch((error) => {
            this.setState({
                open: true,
                message: "Something went wrong, please try again",
                successError: 'error',
            })
        })
    }

    handleChange(e) {
        this.setState({ announcementText: e.target.value })
        if (this.state.announcementText !== '') {
            this.setState({ validated: false })
        }
    }

    handleChecked(e) {
        this.setState({ status: !this.state.status })
    }

    handleClose(e) {
        this.setState({ open: false })
    }

    componentDidMount() {
        const account_api_url = process.env.REACT_APP_API_URL + "/api/setting/detail";
        const settingId = {
            "id": "60633a028f70d97537accede"
        };
        axios.post(account_api_url, settingId, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin')
            }
        }).then((response) => {
            const { data } = response;
            if (data && data.success) {
                this.setState({
                    announcementText: data.data.announcement_text,
                    status: data.data.announcement_status,
                    id: data.data._id
                })
            }
        }).catch((error) => {
            console.log(error, 'error')
        })
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Announcement</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ padding: "30px" }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ justifyContent: "space-between" }}
                                >
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop:"0px!important"}}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                width: "100%",
                                                maxWidth: "265px",
                                                color: "#000059!important",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                fontSize: "1.6rem",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            {" "}
                                            Announcement
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Form noValidate validated={this.state.status} onSubmit={this.handleSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label className="fw-bold ">Announcement status</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={this.state.status}
                                                        onChange={this.handleChecked}
                                                        label={this.state.status ? "Active" : "InActive"}

                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Form.Label className="fw-bold">Announcement Text</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        className='w-full'
                                                        required={this.state.status}
                                                        name='announcementText'
                                                        value={this.state.announcementText}
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        placeholder="Announcement Text"

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Announcement Text is Required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                            </Form.Group>
                                            <Button
                                                type="submit"
                                                className="form-btn text-start"
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* alert message  */}
                <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                />
            </>

        )
    }
}
