import { ActionTypes } from "../types/ActionTypes";


const UserProfileReducer = (state={}, { type, ...rest }) => {
    switch(type){
        case ActionTypes.USERPROFILE:
            return {
                ...state,
                ...rest.payload
            }
        default:
            return state
    }
}

export default UserProfileReducer;