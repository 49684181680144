export const resetTime = (name, time) => {
    return {
        type: "RESET_TIME",
        payload: {
          name: name,
          time: time,
        },
      };
    };
  
  