import React from "react";
import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Logo1 from "images/landingpage/anonymous browser wtihout shadow.svg";
import homepage from "images/landingpage/Home.jpg";

import "./index.scss";
import { memo } from "react";

const Hero = ({ scrollRef }) => {
  return (
    <Box className="HeroSection" ref={scrollRef}>
      <div style={{
        height: "100%",
        overflow: "hidden",
      }}>
        <span className="radial radial-left-bottom"></span>
        <span className="radial radial-top-center"></span>
        <span className="radial radial-right-bottom"></span>
        <span className="square square-left-bottom"></span>
        <span className="square square-right-top"></span>
      </div>

      <Container sx={{ padding: "40px" }}>
        <Box>
          <div className="header_icon">
            <img src={Logo1} alt="logo" className="herolocation" />
          </div>
          <Typography variant="h1" className="HeroTitle" gutterBottom>
            Anonymous Browser
          </Typography>
          <Typography variant="h5" className="HeroSubTitle" gutterBottom>
            Blend in with the crowd
          </Typography>

          <div className="video-container">
            <div className="videowrapper">
              {/* <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/xtPKH4uuxRY?autoplay=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              ></iframe> */}
              <img src={homepage} alt={homepage} style={{ width: "100%", height:"100%" }} />
            </div>
          </div>


        </Box>
      </Container>
    </Box>
  );
};

export default memo(Hero);
