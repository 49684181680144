import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CDataTable } from "@coreui/react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";

import customDateFormat from "utils/customDateFormat";
import invoiceDateFormat from "utils/invoiceDateFormat";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import Pagination from "components/pagination/pagination";
import PageLoader from "components/PageLoader/PageLoader";
import AlertMessage from "components/message/message";
import "../common.css";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import getStatus from "utils/getStatus";

class CommunicationPanelList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      successError: "",
      totalCountries: "",
      soldList: [],
      loader: false,
      dataloader: false,
      allCountries: [],
      currentCountries: [],
      currentPage: 1,
      totalPages: 200,
      pageLimit: 20,
      total: "",
      index: 0,
      event: "",
      section: "",
      row: "",
      seat: "",
      dateVal: "",
      startDate: null,
      endDate: null,
      startDate1: null,
      endDate1: null,
      refId: null,
      filterstatus: null,
      filterstatusVal: null,
      filterref: null,
      filterphone: null,
      sortBy: null,
      sortByOrder: null,
      crossCheck: true,
      endcrossCheck: true,
      modalShow: false,
      userData: "",
      userVal: "",
      email: "",
      phone: "",
      modelError: "",
      modelSuccess: "",
      id: "",
      ticketurl: null,
      twiliomessages: [],
      tmModel: false,
      tm1Model: false,
      timezone: null,
      currentRow: null,
      pos: [],
      invLogs: [],
      loadLogs: false,
      modalShow1: false,
      message: "",
      searchPage: 0,
      formData: {
        customerName: "",
        customerEmail: "",
        ticketURL: "",
        customerPhone: "",
        invoiceDate: "",
        team: "",
        section: "",
        rows: "",
        seat: "",
        marketPlace: "",
        invoiceExternalRef: "",
        eventName: "",
        eventDate: "",
        tickets: "",
      },
      monthsShown: 2,
    };

    this.getAllUsers = this.getAllUsers.bind(this);
    this.getLists = this.getLists.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleSearchSection = this.handleSearchSection.bind(this);
    this.handleSearchRow = this.handleSearchRow.bind(this);
    this.handleSeat = this.handleSeat.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleCustomerPhone = this.handleCustomerPhone.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartDate1 = this.setStartDate1.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleSubmitModal = this.handleSubmitModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStatusFilter = this.handleStatusFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }
    const search_params = this.props.location.search;
    const url_search_params = new URLSearchParams(search_params);
    const refId = url_search_params.get("refId");

    this.setState({ loader: true, refId: refId }, () => {
      this.getAllUsers();
    });
  }
  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  getAllUsers = () => {
    const userDetail_api_url =
      process.env.REACT_APP_API_URL + "/api/commpanel/getAllUsers";
    axios
      .post(userDetail_api_url, "", {
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("accessTokenCommunication"),
        },
      })
      .then((response) => {
        let { data } = response;
        if (data.data) {
          this.setState({ usersData: data.data }, () => {
            this.getLists();
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getLists = () => {
    const params = {
      index: this.state.index,
      totalRecord: this.state.pageLimit,
      event_name: this.state.event,
      user: this.state.userVal ? this.state.userVal._id : null,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      filterref: this.state.filterref,
      filterphone: this.state.filterphone,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
      refId: this.state.refId,
      status: this.state.filterstatus,
      sortBy: this.state.sortBy,
      sortByOrder: this.state.sortByOrder,
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/commpanel/getProblemOrders";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("accessTokenCommunication"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            dataloader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            soldList: data.data,
            totalCountries: data.total,
            open: false,
            message: "",
            successError: "success",
            loader: false,
            dataloader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  onPageChanged = async (data) => {
    const { currentPage, pageLimit } = data;

    this.setState(
      { dataloader: true, index: (currentPage - 1) * pageLimit },
      () => {
        this.getLists();
      }
    );
  };

  handleChangeEvent = (data) => {
    this.setState({ event: data.target.value });
  };
  handleSearchSection = (data) => {
    this.setState({ section: data.target.value });
  };
  handleSearchRow = (data) => {
    this.setState({ row: data.target.value });
  };
  handleSeat = (data) => {
    this.setState({ seat: data.target.value });
  };
  handleCustomerPhone = (data) => {
    this.setState({ filterphone: data.target.value });
  };
  handleRef = (data) => {
    this.setState({ filterref: data.target.value });
  };
  handleChangeDate = (data) => {
    this.setState({ dateVal: data.target.value });
  };

  setStartDate = async (dates) => {
    const [start, end] = dates;
    console.log(dates);
    const { formData } = this.state;
    formData.invoiceDate = dates[0];
    this.setState({
      startDate: start,
      formData: this.state.formData,
      endDate: end,
      crossCheck: false,
    });
  };

  setStartDate1 = async (dates) => {
    const [start, end] = dates;
    console.log(dates);
    const { formData } = this.state;
    formData.eventDate = dates[0];
    this.setState({
      startDate1: start,
      formData: this.state.formData,
      endDate1: end,
      crossCheck: false,
    });
  };

  clearDate = () => {
    if (this.state.startDate != null) {
      this.setState({ startDate: null, endDate: null, crossCheck: true });
    } else {
      this.setState({ crossCheck: true });
    }
  };

  handleModelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openModal = (data) => {
    console.log(data);

    this.setState({
      modalShow: true,
      userData: data,
      modelSuccess: "",
      modalError: "",
      email: typeof data !== "undefined" ? data.customerEmail : "",
      phone: typeof data !== "undefined" ? data.customerPhone : "",
      id: typeof data !== "undefined" ? data._id : "",
      ticketurl: data.ticketurl ? data.ticketurl : null,
    });
  };

  handleSubmitModal = async (data) => {
    const param = {
      id: this.state.id,
      customer_email: this.state.email,
      customer_phone: this.state.phone,
      ticketurl: this.state.ticketurl,
    };
    const updateSoldInventory_api_url =
      process.env.REACT_APP_API_URL +
      "/api/inventory/updateSoldInventoryCustomerInfo";
    await axios
      .post(updateSoldInventory_api_url, param, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: response.data.message,
            successError: "error",
            modelError: response.data.message,
          });
        } else {
          if (data.success) {
            this.getLists();
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  onHide = () => {
    this.setState({ modalShow: false });
  };

  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleChange(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  handleStatusFilter(e, newVal) {
    if (newVal) {
      this.setState({
        filterstatus: newVal.value,
        filterstatusVal: newVal.label,
      });
    } else {
      this.setState({ filterstatus: null, filterstatusVal: null });
    }
  }

  handleChangeUser = (e, newVal) => {
    this.setState({ userVal: newVal });
  };

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState(
      { searchPage: 1, formData, dataloader: true, refId: null },
      () => {
        this.getLists();
      }
    );
  }

  changePageLimit = (e) => {
    this.setState(
      { searchPage: 1, pageLimit: parseInt(e.target.value) },
      () => {
        this.handleSubmit();
      }
    );
  };

  customSorting = (obj) => {
    console.log(obj);
    if (obj) {
      if (obj.column) {
        const { formData } = this.state;
        formData["skip"] = 0;
        this.setState(
          {
            formData,
            dataloader: true,
            sortBy: obj.column,
            sortByOrder: obj.column ? "asc" : "desc",
          },
          () => {
            this.getLists();
          }
        );
      }
    }
  };

  render() {
    const {
      loader,
      dataloader,
      soldList,
      totalCountries,
      pageLimit,
      usersData,
    } = this.state;

    const fields = [
      { label: "User", key: "userName" },
      { label: "Date", key: "invoiceDate" },
      { label: "Team", key: "team" },
      { label: "Section", key: "section" },
      { label: "Row", key: "row" },
      { label: "Seat", key: "seatNumbers" },
      { label: "Marketplace", key: "customerDisplayName" },
      { label: "External Ref", key: "invoiceExternalRef" },
      { label: "Event", key: "eventName" },
      { label: "Event Date", key: "eventDate" },
      { label: "Internal Notes", key: "notes" },
      { label: "Status", key: "status" },
      { label: "Action", key: "action" },
    ];

    const filterstatus = [
      { value: 0, label: "No message sent" },
      { value: 1, label: "Sold message sent" },
      { value: 2, label: "Delivery message sent" },
      { value: 3, label: "Confirmation message sent" },
      { value: 4, label: "Order Confirmed" },
      { value: 5, label: "Problem in Order" },
      { value: 7, label: "Need Manual Review" },
      { value: 8, label: "Phone number Blacklisted" },
      { value: 9, label: "Phone number Invalid" },
      { value: 11, label: "Manually Transferred" },
      { value: 12, label: "Delivery revoked" },
      { value: 13, label: "Ticket Uploaded" },
      { value: 14, label: "Link Generated" },
      { value: 15, label: "Event Reminder" },
      { value: 16, label: "Ticket Not Uploaded" },
      { value: 17, label: "Account Deleted" },
    ];
    return (
      <>
        <div>
          <Helmet>
            <title>Sold Inventory</title>
          </Helmet>
          <div className="c-app c-default-layout user-dashboard-body">
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {loader && <PageLoader />}
                  {!loader && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card style={{ boxShadow: "none!important" }}>
                          <CardHeader title="Sold Inventory" />
                          <CardContent className="plan-tbl sold-invent-outer">
                            <ValidatorForm
                              // ref="form"
                              onSubmit={this.handleSubmit}
                              className="filter-form mb-5"
                            >
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                  container
                                  spacing={{ xs: 2, md: 2, lg: 2 }}
                                  columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextField
                                      variant="outlined"
                                      onChange={this.handleChangeEvent}
                                      name="event_name"
                                      value={this.state.event}
                                      placeholder="Search Event"
                                      className="filter-input"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <DatePicker
                                      placeholderText="Search Date"
                                      selected={this.state.startDate}
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      monthsShown={this.state.monthsShown}
                                      selectsRange
                                      isClearable={true}
                                      customInput={
                                        <TextField
                                          variant="outlined"
                                          className="filter-input"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                className="date-icon"
                                              >
                                                <div className="d-flex">
                                                  {this.state.crossCheck ? (
                                                    <IconButton
                                                      aria-label="toggle password visibility"
                                                      edge="end"
                                                    >
                                                      <EventIcon className="text-dark" />
                                                    </IconButton>
                                                  ) : (
                                                    <IconButton
                                                      edge="end"
                                                      onClick={() =>
                                                        this.clearDate()
                                                      }
                                                    >
                                                      <ClearIcon className="text-dark" />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      }
                                      onChange={(dates) =>
                                        this.setStartDate(dates)
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      variant="outlined"
                                      className="filter-input"
                                      onChange={this.handleSearchSection}
                                      name="section"
                                      placeholder="Search Section"
                                      value={this.state.section}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      variant="outlined"
                                      onChange={this.handleSearchRow}
                                      className="filter-input"
                                      name="rows"
                                      placeholder="Search Row"
                                      value={this.state.row}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <TextValidator
                                      className="filter-input"
                                      variant="outlined"
                                      onChange={this.handleSeat}
                                      name="seat"
                                      placeholder="Search Seat"
                                      value={this.state.seat}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className="team"
                                    xs={12}
                                    sm={4}
                                    md={2}
                                    lg={2}
                                  >
                                    <TextValidator
                                      className="filter-input"
                                      variant="outlined"
                                      onChange={this.handleRef}
                                      name="filterref"
                                      value={this.state.filterref}
                                      placeholder="Search Ref."
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <Autocomplete
                                      fullWidth
                                      id="combo-box-demo"
                                      name="filterstatusVal"
                                      options={filterstatus}
                                      onChange={this.handleStatusFilter}
                                      getOptionLabel={(option) => option.label}
                                      renderInput={(params) => (
                                        <TextField
                                          value={this.state.filterstatusVal}
                                          placeholder="Select Status"
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <Autocomplete
                                      onChange={this.handleChangeUser}
                                      fullWidth
                                      id="combo-box-demo"
                                      name="userVal"
                                      options={usersData}
                                      getOptionLabel={(option) => option.email}
                                      renderInput={(params) => (
                                        <TextValidator
                                          value={this.state.userVal}
                                          placeholder="Select User"
                                          {...params}
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className="team"
                                    xs={12}
                                    sm={4}
                                    md={2}
                                    lg={2}
                                  >
                                    <TextValidator
                                      className="filter-input"
                                      variant="outlined"
                                      onChange={this.handleCustomerPhone}
                                      name="filterphone"
                                      placeholder="Search Phone"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={2} lg={2}>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      className="filter-btn "
                                      startIcon={<HiOutlineSearch />}
                                    >
                                      Search
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </ValidatorForm>

                            <CDataTable
                              responsive
                              loading={dataloader}
                              items={soldList}
                              fields={fields}
                              onSorterValueChange={this.customSorting}
                              itemsPerPage={pageLimit}
                              hover
                              border
                              sorter
                              scopedSlots={{
                                status: (itemrow) => {
                                  return (
                                    <td className="py-2 icon-outer-font">
                                      <div>
                                        {!itemrow?.delivery_status ? (
                                          <div className="icon-space-left">
                                            No message sent
                                            <br />
                                          </div>
                                        ) : (
                                          <>
                                            <div className="icon-space-left">
                                              {getStatus(
                                                itemrow?.delivery_status
                                              )}
                                              <br />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  );
                                },
                                action: (itemrow) => {
                                  return (
                                    <td className="py-1 icon-outer-font">
                                      <Button
                                        component={Link}
                                        size="small"
                                        variant="contained"
                                        sx={{
                                          backgroundColor: "#002986!important",
                                          color: "white!important",
                                          padding: "5px 15px!important",
                                          textTransform: "none!important",
                                          fontWeight: "bold",
                                          fontFamily: "Barlow!important",
                                        }}
                                        to={`/communication-panel-order/${itemrow._id}`}
                                      >
                                        View
                                      </Button>
                                    </td>
                                  );
                                },
                                userName: (itemrow) => {
                                  return (
                                    <td>
                                      {itemrow.user && itemrow.user[0].name}
                                    </td>
                                  );
                                },
                                section: (itemrow) => {
                                  return <td>{itemrow.section}</td>;
                                },
                                row: (itemrow) => {
                                  return <td>{itemrow.row}</td>;
                                },
                                seatNumbers: (itemrow) => {
                                  return <td>{itemrow.seatNumbers}</td>;
                                },
                                customerDisplayName: (itemrow) => {
                                  return <td>{itemrow.customerDisplayName}</td>;
                                },
                                invoiceExternalRef: (itemrow) => {
                                  return <td>{itemrow.invoiceExternalRef}</td>;
                                },
                                team: (itemrow) => {
                                  return (
                                    <td>
                                      {itemrow.event !== "undefined"
                                        ? itemrow.event[0].performer?.name
                                        : ""}
                                    </td>
                                  );
                                },
                                eventName: (itemrow) => {
                                  return (
                                    <td>
                                      {itemrow.event !== "undefined"
                                        ? itemrow.event[0].name
                                        : ""}
                                    </td>
                                  );
                                },
                                eventDate: (itemrow) => {
                                  return (
                                    <td>
                                      {itemrow.event !== "undefined"
                                        ? customDateFormat(
                                            itemrow.event[0].date
                                          )
                                        : ""}
                                    </td>
                                  );
                                },
                                notes: (itemrow) => {
                                  return (
                                    <td>
                                      {itemrow.notes ? itemrow.notes : ""}
                                    </td>
                                  );
                                },
                                invoiceDate: (itemrow) => {
                                  return (
                                    <td>
                                      {invoiceDateFormat(itemrow.invoiceDate)}
                                    </td>
                                  );
                                },
                              }}
                            />

                            {totalCountries > 0 && (
                              <Pagination
                                totalRecords={totalCountries}
                                searchPage={this.state.searchPage}
                                SearchDisable={this.SearchDisable}
                                pageLimit={pageLimit}
                                onPageChanged={this.onPageChanged}
                              />
                            )}
                            <div
                              style={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <label>Items per page :</label>
                              <FormControl
                                sx={{
                                  m: 1,
                                  minWidth: 50,
                                }}
                                size="small"
                              >
                                <Select
                                  defaultValue={pageLimit}
                                  value={pageLimit}
                                  name="limit"
                                  sx={{
                                    "& .MuiSelect-select": {
                                      padding: "4px 14px",
                                      fontFamily: "Barlow",
                                    },
                                  }}
                                  onChange={(e) => this.changePageLimit(e)}
                                >
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="20">20</MenuItem>
                                  <MenuItem value="50">50</MenuItem>
                                  <MenuItem value="100">100</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </main>
                <AlertMessage
                  open={this.state.open}
                  message={this.state.message}
                  severity={this.state.successError}
                  closeCall={this.handleClose}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default CommunicationPanelList;
