
export const changeDollarAmountformat = (amount = 0) => {
    if (amount) {
        const formattedAmount = parseFloat(amount).toFixed(2);
        const [dollars, cents] = formattedAmount.split(".");
        const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${formattedDollars}.${cents}`;
    }
};
export const changeAmountformat = (amount = 0) => {
    if (amount) {
        const formattedAmount = parseFloat(amount).toFixed(2);
        const [dollars] = formattedAmount.split(".");
        const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${formattedDollars ?? 0}`;
    }
};