import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Checkbox,
} from "@mui/material";
import PropTypes from "prop-types";
import CachedIcon from "@mui/icons-material/Cached";
import { useDispatch, useSelector } from "react-redux";
import { saveTime } from "redux/actions/saveTime";
import { resetTime } from "redux/actions/resetTime";

const TimerButton = ({
  date: initialDate,
  name,
  apiCall,
  item,
  id,
  size,
  styles,
  time,
  buttonType,
  timeinseconds,
  status,
  variant,
}) => {
  const [open, setOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const timerRef = useRef(null);
  const dispatch = useDispatch();
  const currentTime = useSelector((state) => state.time);
  const reduxKey = useMemo(() => `${id}-${name !== "Scan Selected" ? name : "Scan"}`, [id, name]
  );

  const isTimer =
    currentTime[reduxKey] !== undefined &&
    currentTime[reduxKey] !== null &&
    currentTime[reduxKey] > 0;

  const NextClickedDate = useMemo(() => {
    const dateCopy = new Date(initialDate);
    return dateCopy;
  }, [initialDate]);


  const handleClose = () => {
    setOpen(false);
  }

  const handleButtonClick = useCallback(async () => {
    if (isTimer) {
      setOpen(true);
      return;
    } else {
      await apiCall(item);
      setRemainingTime(0);
    }
  }, [apiCall, isTimer, item])

  const updateTimer = useCallback(() => {
    setRemainingTime((prevRemainingTime) => {
      if (prevRemainingTime > 0) {
        dispatch(saveTime(reduxKey, prevRemainingTime - 1));
        return prevRemainingTime - 1;
      }
      return 0;
    });
  }, [dispatch, reduxKey]);

  const handleResetTimer = useCallback(() => {
    if (new Date() > NextClickedDate) {
      dispatch(resetTime(`${id}-${name}`, 0));
      setOpen(false);
      return;
    }
  }, [dispatch, NextClickedDate, name, id])

  const minutes = useMemo(() => Math.floor(remainingTime / 60), [remainingTime]);
  const seconds = useMemo(() => Math.floor(remainingTime % 60), [remainingTime]);

  useEffect(() => {
    handleResetTimer()
    const differenceInSeconds = Math.round((NextClickedDate - new Date()) / 1000)
    setRemainingTime(differenceInSeconds);
  }, [NextClickedDate, handleResetTimer]);

  useEffect(() => {
    const intervalId = setInterval(() => updateTimer(), 1000);
    updateTimer()
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, reduxKey, updateTimer]);

  useEffect(() => {
    if (remainingTime === 0) {
      clearInterval(timerRef.current);
      setOpen(false);
    }
  }, [remainingTime]);

  var today = new Date();
  today.setDate(today.getDate() - 2);

  return (
    <>
      {buttonType === "button" ? (
        <Button
          type="button"
          size={size}
          name={name}
          id={id}
          color={isTimer ? "error" : "primary"}
          variant={variant}
          onClick={handleButtonClick}
          sx={isTimer ? styles.diabledbutton : styles.actionbutton}
        >
          {isTimer
            ? name === "Sync"
              ? "Syncing"
              : name === "Refresh"
                ? "Refreshing" :
                name === "Refresh & Auto Scan"
                  ? "Auto Scanning"
                  : name === "Scan Selected"
                    ? "Scanning"
                    : name
            : name}
        </Button>
      ) : (buttonType === "Checkbox") ? (
        <Checkbox
          size="small"
          color="warning"
          checked={false} 
          id={id}
          onClick={handleButtonClick}
          inputProps={{
            "aria-label": "primary checkbox",
          }}
        />
      ) : (buttonType === "Scan") ? (
        <Button
          type="button"
          size={size}
          name={name}
          id={id}
          color={isTimer ? "error" : "primary"}
          variant={variant}
          onClick={handleButtonClick}
          sx={isTimer ? styles.diabledbutton : styles.actionbutton}
        >
          {isTimer
            ? name === "Scan"
              ? "Scanning"
              : name
            : name}
        </Button>
      ) : (
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          size={size}
          id={id}
          onClick={handleButtonClick}
          color={isTimer ? "error" : "primary"}
        >
          <CachedIcon
            sx={
              isTimer
                ? {
                  fontSize: "1.3rem",
                }
                : {
                  color: "#63A0F3",
                  fontSize: "1.3rem",
                }
            }
          />
        </IconButton>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {name === "Scan Selected" ? "Scan" : name} Countdown
        </DialogTitle>
        <DialogContent>
          <p>
            You have to wait for {minutes} minutes and{" "}
            {seconds.toString().padStart(2, "0")} seconds to perform this action
            again.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="small">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TimerButton.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  apiCall: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  styles: PropTypes.object.isRequired,
  time: PropTypes.number.isRequired,
  buttonType: PropTypes.string,
  variant: PropTypes.string,
  status: PropTypes.string
};

TimerButton.defaultProps = {
  date: new Date(),
  name: "Action",
  apiCall: () => { },
  item: {},
  id: "",
  size: "small",
  styles: {
    actionbutton: {
      backgroundColor: "#002986!important",
      color: "white!important",
      padding: "2px 5px!important",
      textTransform: "none!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
    },
    diabledbutton: {
      padding: "2px 5px!important",
      textTransform: "none!important",
      color: "white!important",
      fontWeight: "bold",
      fontFamily: "Barlow!important",
      margin: "0 5px",
    },
  },
  time: 5,
  timeinseconds: 0,
  buttonType: "button",
  variant: "contained",
  status: "n/a"
};

export default React.memo(TimerButton);

