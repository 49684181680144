import React from "react";
import { Divider, Typography } from "@mui/material";

const AdminFooter = () => {
  return (
    <div>
      <Divider />
      <Typography
        variant="h6"
        color="textSecondary"
        align="center"
        sx={{
          textAlign: "right",
          padding: "10px 20px 14px 10px",
          fontSize: "16px",
          color: "#534B8A",
        }}
      >
        Powered by TicketLiberty
      </Typography>
    </div>
  );
};

export default AdminFooter;
