import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// mui  component
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';

// mui  icons component
import { ArrowBack } from '@mui/icons-material';

// Component
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ContainerLoader } from 'components/common/ContainerLoader';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';


const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    height:54px;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const UpdatePackage = ({
    handleCloseAddModal,
    packageId,
    getAllPackage,
    packageEditData,
    setPackageId,
    isLoading,
    pagination,
    errorMessage
}) => {
    const {
        upadtePackageAPI,
        getAllUsersAPI
    } = useAdminAPICollection();
    const [isDisableButton, setisDisableButton] = useState(false);
    const [radioValue, setRadioValue] = useState("no");
    const [allUserList, setAllUserList] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        tickets_count: "",
        additional_ticket_price: "",
        price: "",
        price_period: "monthly",
        description: "",
        color: "#000000",
        user_ids: null,
        order: ""
    });

    const handleOnchange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleChange = (event) => {
        setFormData({
            ...formData,
            price_period: event.target.value
        });
    };

    const handleColorInputChange = (e) => {
        setFormData({ ...formData, color: e.target.value });
    };

    const handleColorChange = (e) => {
        setFormData({ ...formData, color: e.target.value });
    };

    const handleUserChange = (newVal) => {
        if (newVal) {
            setFormData({ ...formData, user_ids: newVal });
        } else {
            setFormData({ ...formData, user_ids: null });
        }
    };

    const handleRadioChange = (event) => {
        const value = event.target.value;
        setRadioValue(value);
        if (value === "no") {
            setFormData((prevData) => ({
                ...prevData,
                user_ids: null
            }));
        }
    };

    const handleUpdatePackage = async () => {
        setisDisableButton(true)
        try {
            const res = await upadtePackageAPI(formData)
            const { data } = res;
            if (data.error) {
                setisDisableButton(false);
                errorMessage(data?.message, "error");
            } else {
                setisDisableButton(false);
                handleCloseAddModal()
                await getAllPackage(pagination?.skip, pagination?.pageLimit)
                setPackageId(null)
                await errorMessage(data?.message, "success");
            }
        } catch (error) {
            setisDisableButton(false);
            errorMessage();
        }
    }

    const getAllPackages = async () => {
        try {
            const response = await getAllUsersAPI();
            const { data } = response;
            if (data.error) {
                errorMessage(data?.message, "error");
            } else {
                setAllUserList(data?.data);
            }
        } catch (error) {
            errorMessage();
        }
    };

    useEffect(() => {
        if (packageEditData && packageEditData !== null) {
            setFormData({
                ...formData,
                id: packageId,
                name: packageEditData?.name,
                tickets_count: packageEditData?.tickets_count,
                additional_ticket_price: packageEditData?.additional_ticket_price,
                price: packageEditData?.price,
                price_period: packageEditData?.price_period,
                auto_renewal: packageEditData?.auto_renewal,
                description: packageEditData?.description,
                color: packageEditData?.color,
                order: packageEditData?.order,
                user_ids: (allUserList && allUserList.find(user => user._id === packageEditData?.user_ids)) ??  null
            });
            setRadioValue(packageEditData && packageEditData?.isCustomPackage === true ? "yes" : "no")
        }
    }, [packageEditData]);

    useEffect(() => {
        getAllPackages();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={"Update Package"}
                            action={
                                <Button
                                    type="button"
                                    size="large"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                    }}
                                    startIcon={<ArrowBack />}
                                    onClick={(e) => handleCloseAddModal(e)}
                                >
                                    Back
                                </Button>
                            }
                        />
                        {isLoading ? (
                            <ContainerLoader />
                        ) : (
                            <CardContent>
                                <ValidatorForm
                                    className="filter-form1 mb-5"
                                    onSubmit={() => handleUpdatePackage()}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                                <Grid item xs={6}>
                                                    <div className="form-group select-outer">
                                                        <label className="Account_details_box">
                                                            Package Name
                                                        </label>
                                                        <TextValidator
                                                            fullWidth
                                                            required
                                                            name='name'
                                                            onChange={handleOnchange}
                                                            value={formData?.name}
                                                            placeholder="Package Name"
                                                            variant="outlined"
                                                            validators={["required"]}
                                                            errorMessages={
                                                                "This field is required"
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <label className="Account_details_box">
                                                        Price
                                                    </label>
                                                    <TextValidator
                                                        required
                                                        fullWidth
                                                        type='number'
                                                        name='price'
                                                        onChange={handleOnchange}
                                                        value={formData?.price}
                                                        placeholder="Price"
                                                        variant="outlined"
                                                        validators={["required"]}
                                                        errorMessages={
                                                            "This field is required"
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <label className="Account_details_box">
                                                        Price Period
                                                    </label>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            required
                                                            fullWidth
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={formData?.price_period}
                                                            validators={["required"]}
                                                            errorMessages={
                                                                "This field is required"
                                                            }
                                                            onChange={handleChange}
                                                            defaultValue='monthly'
                                                        >
                                                            <MenuItem value={"monthly"}>Monthly</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <label className="Account_details_box">
                                                Tickets Count
                                            </label>
                                            <TextValidator
                                                required
                                                fullWidth
                                                type='number'
                                                name='tickets_count'
                                                onChange={handleOnchange}
                                                value={formData?.tickets_count}
                                                placeholder="Tickets Count"
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={
                                                    "This field is required"
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <label className="Account_details_box">
                                                Additional Ticket Price
                                            </label>
                                            <TextValidator
                                                required
                                                type='number'
                                                fullWidth
                                                name='additional_ticket_price'
                                                onChange={handleOnchange}
                                                value={formData?.additional_ticket_price}
                                                placeholder="Additional Ticket Price"
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={
                                                    "This field is required"
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <label className="Account_details_box">
                                                Package Order
                                            </label>
                                            <TextValidator
                                                required
                                                type='number'
                                                fullWidth
                                                name='order'
                                                onChange={handleOnchange}
                                                value={formData?.order}
                                                placeholder="Enter Order"
                                                variant="outlined"
                                                validators={["required"]}
                                                errorMessages={
                                                    "This field is required"
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <label className="Account_details_box">
                                                Package  Color
                                            </label>
                                            <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                                                <Grid item xs={11}>
                                                    <TextValidator
                                                        required
                                                        validators={["required"]}
                                                        errorMessages={
                                                            "This field is required"
                                                        }
                                                        type="text"
                                                        value={formData.color}
                                                        onChange={handleColorInputChange}
                                                        fullWidth
                                                        name='color'
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={1} sx={{ paddingLeft: "0px!important" }}>
                                                    <input
                                                        style={{
                                                            borderRadius: "11%",
                                                            minHeight: "52px",
                                                            marginLeft: "0px",
                                                            border: "1px solid #C4C4C4",
                                                            cursor: "pointer"
                                                        }}
                                                        type='color'
                                                        fullWidth
                                                        value={formData?.color}
                                                        name='color'
                                                        onChange={handleColorChange}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <label className="Account_details_box">Select Custom</label>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="no"
                                                name="radio-buttons-group"
                                                value={radioValue}
                                                onChange={handleRadioChange}
                                            >
                                                <div className='flex'>
                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                                </div>
                                            </RadioGroup>
                                        </Grid>
                                        {radioValue === "yes" && (
                                            <Grid item xs={12} sm={6}>
                                                <label className="Account_details_box">Users</label>
                                                <Autocomplete
                                                    fullWidth
                                                    className="filter-input"
                                                    name="filterstatusVal"
                                                    value={formData?.user_ids}
                                                    options={allUserList ?? []}
                                                    onChange={(event, newValue) => handleUserChange(newValue)}
                                                    getOptionLabel={(option) => option.email}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Users"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <label className="Account_details_box">
                                                Description
                                            </label>
                                            <Textarea
                                                fullWidth
                                                sx={{ height: "200px!important" }}
                                                name="description"
                                                onChange={handleOnchange}
                                                value={formData?.description}
                                                placeholder=" Description"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: "0 auto", display: "flex", justifyContent: "center" }}>
                                            <Button
                                                size="large"
                                                sx={{ paddingY: "0.9rem", width: "100%", maxWidth: "500px" }}
                                                variant="contained"
                                                type="submit"
                                                className={`${isDisableButton ? "theme-btn-submit-disablebutton" : "theme-btn-submit"}`}
                                                disabled={isDisableButton}
                                            >
                                                Update Package
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

UpdatePackage.propTypes = {
    getAllPackage: PropTypes.func.isRequired,
    handleCloseAddModal: PropTypes.func.isRequired,
    packageId: PropTypes.string,
    packageEditData: PropTypes.object.isRequired,
    setPackageEditData: PropTypes.func.isRequired,
    setPackageId: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.arrayOf(PropTypes.object).isRequired,
}

UpdatePackage.defaultProps = {
    pagination: {
        skip: 0,
        pageLimit: 10
    },
    isLoading: false,
    setPackageEditData: () => { },
    setPackageId: () => { },
    packageEditData: {},
    getAllPackage: () => { },
    handleCloseAddModal: () => { },
    packageId: null
};

export default UpdatePackage;







