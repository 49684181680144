import "./index.scss";
import {  Container, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { memo } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";


const Footer = ({ scrollRef }) => {
  return (

    <footer className="footer-wrapper" ref={scrollRef}>
      <Container>
        <Grid item xs={12} md={12}>
          <h2 className="footer-title">
            <span>Contact Us</span>
          </h2>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} className="align-footer">
            <BsFillTelephoneFill style={{fontSize:"22px" , marginRight:"12px"}} /> 1(800)914-5040
          </Grid>
          <Grid item xs={12} md={6} className="align-footer">
          <EmailIcon sx={{fontSize:"22px" , marginRight:"12px"}} />
            sales@ticketliberty.com
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default memo(Footer);
