import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import navigation from "containers/_nav";
import navigation_comm from "containers/_navcomm";
import Logo from "images/logo.png";
import { Link, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Collapse, Typography, alpha } from "@mui/material";
import AdminFooter from "components/AdminDrawer/AdminFooter";

import "./AdminDrawer.scss";
import { useState } from "react";
import { useEffect } from "react";
const drawerWidth = 280;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 20,
  boxShadow: "none",
  transition: theme.transitions.create(
    ["margin", "width"],

    {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }
  ),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    boxShadow: "none",
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: "100%",

    paddingTop: window.innerWidth < 981 ? "8rem" : "calc(3rem + 49px)",
    maxWidth: "100%",

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: window.innerWidth < 768 ? 0 : `-${drawerWidth}px`,
    ...(open && {
      // smooth transition when drawer is opened
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),

      marginLeft: 0,
      maxWidth: window.innerWidth < 768 ? "100%" : "calc(100% - 280px)",
    }),
  })
);

const AdminDrawer = ({ childern, nav }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const show = useSelector((state) => state.SideBar.sidebarShow);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    dispatch({
      type: "set",
      sidebarShow: show === "responsive" ? false : "responsive",
    });
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const [openColumn, setOpenColumn] = React.useState(false);

  const handleClick = (column) => {
    if (openColumn === column) {
      setOpen(!open);
      setOpenColumn(column);
    } else {
      setOpen(true);
      setOpenColumn(column);
    }
  };
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setResolution({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={show === "responsive" && resolution.width > 768}
        sx={{
          backgroundColor: "#fff",
          color: "black",
          paddingRight: resolution.width < 450 ? "7px" : "",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Link className="mx-auto d-lg-none" to="/">
            <img src={Logo} className="img-fluid sidebar-logo" alt="" />
          </Link>
          <Button
            sx={{ float: "right" }}
            size={resolution.width < 768 ? "small" : "medium"}
            id="demo-customized-button"
            aria-controls={openMenu ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleOpenMenu}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Settings
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            className="stylemenu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={handleCloseMenu}
              disableripple="true"
              component={Link}
              to="/changepass"
              className="menu-item"
            >
              <LockResetIcon />
              Change Password
            </MenuItem>

            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={handleCloseMenu}
              disableripple="true"
              component={Link}
              to="/adminlogout"
              className="menu-item"
            >
              <LogoutIcon />
              Logout
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: resolution.width <= 768 ? "" : drawerWidth,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#3C4B64",
            color: "#fff",
          },
        }}
        onClose={handleDrawerOpen}
        variant={
          show === "responsive" && resolution.width <= 768
            ? "temporary"
            : "persistent"
        }
        anchor="left"
        open={show === "responsive" ? true : false}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "#303C54",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <Link to="/">
            <img src={Logo} className="img-fluid sidebar-logo" alt="" />
          </Link>
          <Typography variant="h3" gutterBottom sx={{color:"white", fontWeight:"900"}}>
            Admin Portal
          </Typography>
        </DrawerHeader>

        <List
          className="customScrollbar"
          sx={{
            height: "calc(100vh - 64px)",
            padding: "0px!important",
          }}
        >
          {nav === "communication"
            ? navigation_comm?.map((el, index) => {
              if (el._tag === "CSidebarNavDropdown") {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      key={index}
                      onClick={() => handleClick(el.name)}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText
                          primary={el.name}
                          sx={{ color: "white!important" }}
                        />
                      </ListItemButton>
                      {open && openColumn === el.name ? (
                        <ExpandMoreIcon
                          sx={{
                            color: "#fff",
                            fontSize: "14px",
                            opacity: "0.4",
                            marginRight: "8px",
                          }}
                        />
                      ) : (
                        <ChevronLeftIcon
                          sx={{
                            color: "#fff",
                            fontSize: "14px",
                            opacity: "0.4",
                            marginRight: "8px",
                          }}
                        />
                      )}
                    </ListItem>

                    <Collapse
                      in={open && openColumn === el.name}
                      timeout="auto"
                      unmountOnExit
                    >
                      {el._children?.map((el, index) => {
                        return (
                          <ListItem key={index} disablePadding>
                            <ListItemButton
                              component={Link}
                              to={el.to ?? ""}
                              sx={{ pl: 4 }}
                            >
                              <ListItemText
                                primary={el.name}
                                sx={{ color: "white!important" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <ListItem key={index}>
                    <ListItemButton component={Link} to={el.to ?? ""}>
                      <ListItemText
                        primary={el._ame}
                        sx={{ color: "white!important" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })
            : navigation?.map((el, index) => {
              if (el._tag === "CSidebarNavDropdown") {
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      key={index}
                      onClick={() => handleClick(el.name)}
                      disablePadding
                      disableripple="true"
                      className={
                        open && openColumn === el.name
                          ? "active-collapse"
                          : ""
                      }
                    >
                      <ListItemButton disableripple="true">
                        <ListItemText
                          primary={el.name}
                          sx={{ color: "white!important" }}
                        />

                        {open && openColumn === el.name ? (
                          <ExpandMoreIcon
                            sx={{
                              color: "#fff",
                              fontSize: "14px",
                              opacity: "0.4",
                              marginRight: "8px",
                            }}
                          />
                        ) : (
                          <ChevronLeftIcon
                            sx={{
                              color: "#fff",
                              fontSize: "14px",
                              opacity: "0.4",
                              marginRight: "8px",
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>

                    <Collapse
                      in={open && openColumn === el.name}
                      className={
                        open && openColumn === el.name
                          ? "active-collapse"
                          : ""
                      }
                      timeout="auto"
                      unmountOnExit
                      disableripple="true"
                    >
                      {el._children?.map((el, index) => {
                        return (
                          <ListItem
                            key={index}
                            disableripple="true"
                            className={
                              el.to === location.pathname ? "active-link" : ""
                            }
                          >
                            <ListItemButton
                              component={Link}
                              to={el.to ?? ""}
                              sx={{ pl: 3 }}
                            >
                              <ListItemText
                                primary={el.name}
                                sx={{ color: "white!important" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <ListItem
                    key={index}
                    disableripple="true"
                    className={
                      el.to === location.pathname ? "active-link" : ""
                    }
                  >
                    <ListItemButton
                      component={Link}
                      to={el.to ?? ""}
                      disableripple="true"
                    >
                      <ListItemText
                        primary={el.name}
                        sx={{ color: "white!important" }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
        </List>
      </Drawer>
      <Main open={show === "responsive" ? true : false}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "calc(100vh - 200px)",
            padding: (theme) =>
              resolution.width < 768 ? "10px" : theme.spacing(3),
          }}
        >
          {childern}
        </Box>
        <AdminFooter />
      </Main>
    </Box>
  );
};

export default React.memo(AdminDrawer);
