// Scan Api Action 

import { ActionTypes } from "../types/ActionTypes";
import Api_List from "../Api-list";
import axios from "axios";
import Cookies from "js-cookie";

export const ScanVendorEvent = (data) => async (dispatch) => {
    try {
        const res = await axios.post(Api_List.ScanVendorEvent, data , { headers: { authorization: 'Bearer '+ Cookies.get('accessToken')} });
        dispatch({
            type: ActionTypes.SCANVENDOREVENT,
            payload: res.data
        })
    } catch (error) {
        console.log(error);
    }
}