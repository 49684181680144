import axios from 'axios';
import Cookies from 'js-cookie';
export const LOGINREQUEST = 'LOGINREQUEST';
export const LOGINLOADING = 'LOGINLOADING';
export const LOGINSUCCESS = 'LOGINSUCCESS';

const login_api_url = process.env.REACT_APP_API_URL + "/api/auth/signin"

export default function LoginAct(data) {

    return async function (dispatch) {

        try {

            await axios.post(login_api_url, data)
                .then((res) => {

                    if (res.status == 200) {
                        Cookies.set('accessToken', res.data.accessToken, { expires: 30 });
                        dispatch(setType(res.data));
                        dispatch(setProps(LOGINSUCCESS, true));
                        // window.location="/Home"
                    }
                }).catch((error) => {
                    // alert("Something went wrong, please try again")
                })
        }
        catch (e) {
            console.log("error", e);
        }
    }
}

const setType = (data) => {
    return {
        type: LOGINREQUEST,
        payload: data
    }
}

const setProps = (type = LOGINLOADING, status = false) => {
    return {
        type: type,
        isLoading: status
    }
}