import {LOGINREQUEST, LOGINLOADING, LOGINSUCCESS} from '../actions/Login';

const loginReduc =(state=[], action)=>{
    switch(action.type){
        case LOGINREQUEST:
        return{
            ...state,
            payload:action.payload,
            isLoading:true
        }
        case LOGINLOADING:
            return{
                ...state,
                payload:{},
                isLoading:action.isLoading
            }
        case LOGINSUCCESS:
            return{
                ...state,
                payload:action.payload,
                isLoading:action.isLoading
            }
        default :
            return state
    }
}
export default loginReduc;