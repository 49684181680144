import * as React from 'react';
import { Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function ExpiredTag() {
  return (
    <Chip
      label="Expired"
      icon={<AccessTimeIcon fontSize="small" />}
      sx={{
        backgroundColor: "#B60000",
        marginLeft: '10px',
        color: 'white!important',
        "& .MuiSvgIcon-root":{
          color: 'white!important',
        },
        "& .MuiChip-label":{
          fontWeight: 600,
        }

        
      }}
      size="small"
    />
  );
}

export default ExpiredTag;