import React from 'react'
import PropTypes from "prop-types";

// mui icons component
import { Cancel } from '@mui/icons-material'

// mui components
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material'

const ConfirmationModal = ({
    handleAPIRequest,
    isOpen,
    handleClose,
    message,
    title,
    buttonText,
    buttonText2
}) => {

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    return (
        <Dialog
            onClick={handleBackdropClick}
            maxWidth="sm"
            open={isOpen}
            onClose={(e) => handleClose(e)}
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                },
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={(e) => handleClose(e)}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                    }}
                >
                    <Cancel />
                </IconButton>
                <DialogContentText
                    sx={{
                        fontFamily: "Barlow",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "black",
                    }}
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    {buttonText2 ?? "Cancel"}
                </Button>
                <Button
                    onClick={(e) => handleAPIRequest()}
                    sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonText2: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAPIRequest: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
    isOpen: false,
    message: "",
    buttonText: "Yes",
    buttonText2: "No",
    title: "",
    handleDelete: () => { },
    handleAPIRequest: () => { },
    handleClose: () => { },
};
export default ConfirmationModal;
