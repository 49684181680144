// return date format in "YYYY-MM-DDTHH:mm:ss.sss" format for vendor

export const vendorDateFormat = (date) => {
  if (!date) return "";


  if (date.includes("||undefined")) date = date.replace("||undefined", "");

  if (date.includes("undefined||undefined")) {
    return "";
  }

  // regex to check if the date in available in string
  const hasDate = /\d{4}-\d{2}-\d{2}/i.test(date);
  if (!hasDate) {
    return "";
  }

  // regex to check if date is in YYYY-MM-DD||00:00:00 format
  const hasNoTime = /^\d{4}-\d{2}-\d{2}(?![T\d])/i.test(date);
  if (hasNoTime) {
    return new Date(date.substring(0, 10) + "T00:00:00");
  }

  const formatedDate = date
    .replace("||", "T")
    .replace(" ONSALE", "")
    .replace(" OFFSALE", "")
    .replace(" CANCELED", "")
    .replace(" RESCHEDULED", "");

  return new Date(formatedDate);
};
