import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import AlertMessage from "../alert/alert";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const Label = (props) => (
  <Typography
    variant="body2"
    color="textSecondary"
    {...props}
    sx={{
      textAlign: "left",
      marginBottom: "10px",
    }}
  />
);
class AddCommunicationUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      c_password: "",
      error: [],
      loader: false,
      open: false,
      message: "",
      successError: "",
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        if (value !== this.state.password) {
          return false;
        }
        return true;
      });
    }
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }
  }
  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    this.setState({ loader: true }, () => {
      const data = {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        roles: ["communication"],
      };
      const addUser_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/addUser";
      axios
        .post(addUser_api_url, data)
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
              name: "",
              email: "",
              password: "",
              c_password: "",
            });
            this.props.history.push("/communication-users");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong, please try again",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { name, email, password, c_password, loader } = this.state;

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Add Communication Employee</title>
          </Helmet>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      Add Communication Employee
                    </Typography>
                  }
                  action={
                    <Grid
                      container
                      spacing={{ xs: 1, md: 1, lg: 1 }}
                      columns={{ xs: 4, sm: 8, md: 8 }}
                      className="button-align"
                    >
                      <Grid item>
                        <Button
                          onClick={() => this.props.history.push("/communication-users")}
                          size="large"
                          variant="contained"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            textTransform: "none!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow!important",
                          }}
                          startIcon={<ArrowBack />}
                        >
                          Back
                        </Button>
                      </Grid>
                    </Grid>
                  }
                />

                <Divider />
                <CardContent>
                  <ValidatorForm onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12}>
                        <Label>Name</Label>
                        <TextValidator
                          variant="outlined"
                          placeholder="Name"
                          fullWidth
                          onChange={this.handleChange}
                          name="name"
                          value={name}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Label>Email address</Label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="email"
                          value={email}
                          placeholder="Email Address"
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "email is not valid",
                          ]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Label>Password</Label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="password"
                          type="password"
                            placeholder="Password"
                          value={password}
                          validators={["required", "PasswordStrength"]}
                          errorMessages={[
                            "this field is required",
                            "password must be at least 6 character",
                          ]}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Label>Confirm Password</Label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="c_password"
                          type="password"
                          placeholder="Confirm Password"
                          value={c_password}
                          validators={["isPasswordMatch", "required"]}
                          errorMessages={[
                            "password mismatch",
                            "this field is required",
                          ]}
                        />
                      </Grid>
                      <Grid item={true} xs={4} 
                      sx={{
                        margin:"0 auto"
                      }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          className="theme-btn"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>

                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}

export default AddCommunicationUser;
