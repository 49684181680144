import { ActionTypes } from "../types/ActionTypes";
import  Api_List from "../Api-list";
import axios from "axios";
import Cookies from "js-cookie";

const UserProfileAction =  () => {
    return(dispatch) => {
        axios.post(Api_List.UserProfile, {} ,{ headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } }
        ).then((response) => {
            const { data } = response;
            dispatch({
                type: ActionTypes.USERPROFILE,
                payload: data
            })
            return data;
        }).catch((error) => {
            console.log(error,'error')
        }
        )


}
}

export default UserProfileAction;
