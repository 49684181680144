import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Select, Checkbox } from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class Fingerprints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      useragents: [],
      modalShow: false,
      modalFormShow: false,
      updateModalShow: false,
      deleteModalShow: false,
      fp: null,
      loader: true,
      formData: {
        os: "",
        geolocation: false,
        webgl: false,
        WebGL_unmark_vender: "",
        WebGL_unmark_renderor: "",
        user_agent: "",
        hardware_concurrency: "",
        device_memory: "",
        screen_resolution: "",
      },
      updateId: "",
      deleteId: "",
    };
  }

  componentDidMount() {
    this.setState({ loader: true }, () => {
      this.getlists();
      this.getuseragents();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updateId !== this.state.updateId) {
      this.setState({
        updateFormData: {
          os: this.state.updateId.fp.os,
          geolocation: this.state.updateId.fp.geolocation,
          webgl: this.state.updateId.fp.webgl,
          WebGL_unmark_renderor:
            this.state.updateId.fp.hardware.WebGL_unmark_renderor,
          WebGL_unmark_vender:
            this.state.updateId.fp.hardware.WebGL_unmark_vender,
          user_agent: this.state.updateId.fp.navigator.user_agent,
          hardware_concurrency:
            this.state.updateId.fp.navigator.hardware_concurrency,
          device_memory: this.state.updateId.fp.navigator.device_memory,
          screen_resolution: this.state.updateId.fp.navigator.screen_resolution,
        },
      });
    }
  }

  getlists = () => {
    const fp_api_url =
      process.env.REACT_APP_API_URL + "/api/getfingerprintsAdmin";
    axios
      .get(fp_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  getuseragents = () => {
    const api_url = process.env.REACT_APP_API_URL + "/api/getuseragents";
    axios
      .get(api_url)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          console.log(data.error);
        } else {
          this.setState({ useragents: data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setModalShow = (item) => {
    this.setState({ modalShow: true, fp: item });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  setUpdateModalShow = (_id) => {
    this.setState({ updateModalShow: true, updateId: _id });
  };

  onUpdateHide = () => {
    this.setState({ updateModalShow: false });
  };

  setDeleteModalShow = (_id) => {
    this.setState({ deleteModalShow: true, deleteId: _id });
  };

  onDeleteHide = () => {
    this.setState({ deleteModalShow: false });
  };

  setModalFormShow = () => {
    this.setState({ modalFormShow: true });
  };

  onFormHide = () => {
    this.setState({ modalFormShow: false });
  };

  handleChange = (e) => {
    const { formData } = this.state;
    const { name, value } = e.target;
    formData[name] = value;
    this.setState({ formData });
  };

  handleChecked = (e) => {
    const { formData } = this.state;
    const { name, checked } = e.target;
    formData[name] = checked;
    this.setState({ formData });
  };

  updateHandleChange = (e) => {
    const { updateFormData } = this.state;
    const { name, value } = e.target;
    updateFormData[name] = value;
    this.setState({ updateFormData });
  };

  updateHandleChecked = (e) => {
    const { updateFormData } = this.state;
    const { name, checked } = e.target;
    updateFormData[name] = checked;
    this.setState({ updateFormData });
  };

  handleSubmit = () => {
    const { formData } = this.state;
    this.setState({ loader: true }, () => {
      const fp_post_url = process.env.REACT_APP_API_URL + "/api/addFingerprint";
      const postdata = {
        fp: {
          os: formData.os,
          geolocation: formData.geolocation,
          webgl: formData.webgl,
          navigator: {
            user_agent: formData.user_agent,
            hardware_concurrency: formData.hardware_concurrency,
            device_memory: formData.device_memory,
            screen_resolution: formData.screen_resolution,
          },
          hardware: {
            WebGL_unmark_vender: formData.WebGL_unmark_vender,
            WebGL_unmark_renderor: formData.WebGL_unmark_renderor,
          },
        },
      };

      axios
        .post(fp_post_url, postdata)
        .then((response) => {
          console.log(response);
          this.getlists();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  updateHandleSubmit = () => {
    const { updateFormData } = this.state;
    this.setState({ loader: true }, () => {
      const update__url = process.env.REACT_APP_API_URL + "/api/addFingerprint";
      const postdata = {
        _id: this.state.updateId._id,
        fp: {
          os: updateFormData.os,
          geolocation: updateFormData.geolocation,
          webgl: updateFormData.webgl,
          navigator: {
            user_agent: updateFormData.user_agent,
            hardware_concurrency: updateFormData.hardware_concurrency,
            device_memory: updateFormData.device_memory,
            screen_resolution: updateFormData.screen_resolution,
          },
          hardware: {
            WebGL_unmark_vender: updateFormData.WebGL_unmark_vender,
            WebGL_unmark_renderor: updateFormData.WebGL_unmark_renderor,
          },
        },
      };

      axios
        .post(update__url, postdata)
        .then((response) => {
          console.log(response);
          this.setState({ updateModalShow: false });
          this.getlists();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  callDelete = () => {
    this.setState({ loader: true }, () => {
      const data = { _id: this.state.deleteId };
      console.log(data);
      const userDelete_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/deleteFingerprint";

      axios
        .post(userDelete_api_url, data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ deleteModalShow: false });
            this.setState({
              loader: false,
              open: true,
              message: data.data.message,
              successError: "error",
            });
          } else {
            this.setState({ deleteModalShow: false });
            if (data.success) {
              this.getlists();
            } else {
              this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  render() {
    const {
      loader,
      lists,
      fp,
      modalShow,
      modalFormShow,
      formData,
      updateModalShow,
      deleteModalShow,
      updateFormData,
      useragents,
    } = this.state;
    const fields = [
      { key: "os", label: "Operating System" },
      { key: "screen_resolution", label: "Screen Resolution" },
      { key: "action", label: "Action" },
    ];

    const os_options = ["Windows", "Linux", "Android", "IOS", "Mac"];
    const hardware_concurrency_options = [2, 4, 6, 8, 16];
    const device_memory_options = [0.25, 0.5, 1, 2, 4, 8]
    //  [1, 2, 4, 6, 8, 16];
    const screen_resolution_options = [
      "800x600",
      "1024x600",
      "1024x640",
      "1024x768",
      "1280x800",
      "1280x960",
      "1280x1024",
      "1360x768",
      "1366x768",
      "1400x1050",
      "1440x900",
      "1536x864",
      "1600x900",
      "1600x1200",
      "1680x1050",
      "1792x1120",
      "1920x1080",
      "2048x1152",
      "2304x1440",
      "2560x1440",
      "2560x1600",
      "2880x1800",
      "4096x2304",
      "5120x2880",
    ];

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Fingerprints</title>
          </Helmet>
          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title="Fingerprints"
                  action={
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                        marginRight: "10px",
                      }}
                      onClick={() => this.setModalFormShow()}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                  }
                />
                <Divider />

                <CardContent>
                  <DataTable
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      os: (item) => {
                        return item.fp.os;
                      },
                      screen_resolution: (item) => {
                        return item.fp.navigator.screen_resolution;
                      },
                      action: (item) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              onClick={() => this.setModalShow(item)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              onClick={() =>
                                this.setUpdateModalShow(item, item._id)
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.setDeleteModalShow(item._id)}
                              aria-label="delete"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    }}
                  />
                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={modalShow}
                    onClose={this.onHide}
                  >
                    <DialogTitle sx={{ textAlign: "center" }}>
                      Fingerprint Detail
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.onHide()}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: (theme) => theme.palette.grey[500],
                          p: 1,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                      <DialogContentText>
                        {fp && (
                          <>
                            <strong>Operating System: </strong>
                            {fp.os}
                            <br />
                            <strong>Geolocation: </strong>
                            {fp.geolocation ? "True" : "False"}
                            <br />
                            <strong>webgl: </strong>
                            {fp.webgl ? "True" : "False"}
                            <br />
                            <strong>User Agent: </strong>
                            {fp.fp.navigator.user_agent}
                            <br />
                            <strong>Hardware Concurrency: </strong>
                            {fp.fp.navigator.hardware_concurrency}
                            <br />
                            <strong>Device Memory: </strong>
                            {fp.fp.navigator.device_memory}
                            <br />
                            <strong>Screen Resolution: </strong>
                            {fp.fp.navigator.screen_resolution}
                            <br />
                            <strong>WebGL Unmark Vender: </strong>
                            {fp.fp.hardware.WebGL_unmark_vender}
                            <br />
                            <strong>WebGL Unmark Renderor: </strong>
                            {fp.fp.hardware.WebGL_unmark_renderor}
                            <br />
                          </>
                        )}
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={modalFormShow}
                    onClose={this.onFormHide}
                  >
                    <DialogTitle>Add Fingerprint</DialogTitle>
                    <Divider />
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <DialogContent>
                        <IconButton
                          aria-label="close"
                          onClick={() => this.onFormHide()}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <label>Operating System</label>
                            <Select
                              style={{ width: "100%" }}
                              name="os"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <MenuItem value=""></MenuItem>
                              {os_options.map((os) => (
                                <MenuItem value={os}>{os}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Geolocation</label>
                            <Checkbox
                              onChange={this.handleChecked}
                              name="geolocation"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>Web GL</label>
                            <Checkbox
                              onChange={this.handleChecked}
                              name="webgl"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>WebGL Unmark Vender</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              name="WebGL_unmark_vender"
                              value={formData.WebGL_unmark_vender}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>WebGL Unmark Renderor</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              name="WebGL_unmark_renderor"
                              value={formData.WebGL_unmark_renderor}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>User Agent</label>
                            <Select
                              style={{ width: "100%" }}
                              name="user_agent"
                              onChange={(e) => this.handleChange(e)}
                            >
                              {useragents.map((ua) => (
                                <MenuItem value={ua.agentdetail}>
                                  {ua.agentdetail}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Hardware Concurrency</label>
                            <Select
                              style={{ width: "100%" }}
                              name="hardware_concurrency"
                              onChange={(e) => this.handleChange(e)}
                            >
                              {hardware_concurrency_options.map((hc) => (
                                <MenuItem value={hc}>{hc}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Device Memory</label>
                            <Select
                              style={{ width: "100%" }}
                              name="device_memory"
                              onChange={(e) => this.handleChange(e)}
                            >
                              {device_memory_options.map((dm) => (
                                <MenuItem value={dm}>{dm}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Screen Resolution</label>
                            <Select
                              style={{ width: "100%" }}
                              name="screen_resolution"
                              onChange={(e) => this.handleChange(e)}
                            >
                              {screen_resolution_options.map((sr) => (
                                <MenuItem value={sr}>{sr}</MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              type="submit"
                              sx={{ paddingY: "0.7rem" }}
                              size="large"
                              className="theme-btn-submit"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </ValidatorForm>
                  </Dialog>

                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={updateModalShow}
                    onClose={this.onUpdateHide}
                  >
                    <DialogTitle>Update Fingerprint</DialogTitle>
                    <Divider />
                    <ValidatorForm onSubmit={this.updateHandleSubmit}>
                      <DialogContent>
                        <IconButton
                          aria-label="close"
                          onClick={() => this.onUpdateHide()}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <label>Operating System</label>
                            <Select
                              style={{ width: "100%" }}
                              native
                              name="os"
                              onChange={(e) => this.updateHandleChange(e)}
                            >
                              <option
                                value={updateFormData && updateFormData.os}
                              >
                                {updateFormData && updateFormData.os}
                              </option>
                              {os_options.map((os) => (
                                <option value={os}>{os}</option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Geolocation</label>
                            <Checkbox
                              onChange={this.updateHandleChecked}
                              name="geolocation"
                              checked={
                                updateFormData &&
                                  updateFormData.geolocation === true
                                  ? true
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>Web GL</label>
                            <Checkbox
                              onChange={this.updateHandleChecked}
                              name="webgl"
                              checked={
                                updateFormData && updateFormData.webgl === true
                                  ? true
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>WebGL Unmark Vender</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.updateHandleChange}
                              name="WebGL_unmark_vender"
                              value={
                                updateFormData &&
                                updateFormData.WebGL_unmark_vender
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>WebGL Unmark Renderor</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.updateHandleChange}
                              name="WebGL_unmark_renderor"
                              value={
                                updateFormData &&
                                updateFormData.WebGL_unmark_renderor
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <label>User Agent</label>
                            <Select
                              style={{ width: "100%" }}
                              native
                              name="user_agent"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value=""></option>
                              {useragents.map((ua) => (
                                <>
                                  {updateFormData &&
                                    updateFormData.user_agent ===
                                    ua.agentdetail ? (
                                    <option
                                      value={ua.agentdetail}
                                      selected={true}
                                    >
                                      {ua.agentdetail}
                                    </option>
                                  ) : (
                                    <option value={ua.agentdetail}>
                                      {ua.agentdetail}
                                    </option>
                                  )}
                                </>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Hardware Concurrency</label>
                            <Select
                              style={{ width: "100%" }}
                              native
                              name="hardware_concurrency"
                              onChange={(e) => this.updateHandleChange(e)}
                            >
                              <option
                                value={
                                  updateFormData &&
                                  updateFormData.hardware_concurrency
                                }
                              >
                                {updateFormData &&
                                  updateFormData.hardware_concurrency}
                              </option>
                              {hardware_concurrency_options.map((hc) => (
                                <option value={hc}>{hc}</option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Device Memory</label>
                            <Select
                              style={{ width: "100%" }}
                              native
                              name="device_memory"
                              onChange={(e) => this.updateHandleChange(e)}
                            >
                              <option
                                value={
                                  updateFormData && updateFormData.device_memory
                                }
                              >
                                {updateFormData && updateFormData.device_memory}
                              </option>
                              {device_memory_options.map((dm) => (
                                <option value={dm}>{dm}</option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <label>Screen Resolution</label>
                            <Select
                              style={{ width: "100%" }}
                              native
                              name="screen_resolution"
                              onChange={(e) => this.updateHandleChange(e)}
                            >
                              <option
                                value={
                                  updateFormData &&
                                  updateFormData.screen_resolution
                                }
                              >
                                {updateFormData &&
                                  updateFormData.screen_resolution}
                              </option>
                              {screen_resolution_options.map((sr) => (
                                <option value={sr}>{sr}</option>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              type="submit"
                              sx={{ paddingY: "0.7rem" }}
                              size="large"
                              className="theme-btn-submit"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </ValidatorForm>
                  </Dialog>

                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={deleteModalShow}
                    onClose={this.onDeleteHide}
                  >
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.onDeleteHide()}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: (theme) => theme.palette.grey[500],
                          p: 1,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                      <DialogContentText>
                        Are you sure you want to Delete, this action can not be
                        revert ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.onDeleteHide}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.callDelete}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default Fingerprints;
