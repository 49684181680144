import { Box, LinearProgress, Typography } from "@mui/material";
import { changeAmountformat } from "./changeDollarFormat";

export const LinearProgressBar = ({ data }) => {
    const count = parseInt(data?.userTicketsDelivered ?? 0)
    const maxCount = parseInt(data?.tickets_count ?? 0); // Set the maximum count
    let progressPercentage;
    if (maxCount <= count) {
        progressPercentage = 100;
    } else {
        progressPercentage = (count / maxCount) * 100;
    }
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel
                value={progressPercentage}
                count={count ?? 0}
                maxCount={maxCount ?? 0} />
        </Box>
    );
}

const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                    variant="determinate"
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: props.maxCount < props.count ? "#BC0000" : "#002583"
                        }
                    }}
                    {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {`${(props.count ?? 0)}/${changeAmountformat(props.maxCount ?? 0)}`}
                </Typography>
            </Box>
        </Box>
    );
}