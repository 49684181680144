import React, { useEffect, useState } from "react";

// react package and library
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import loaderGif from "images/GIF-Loader-3.gif";
import Stripe from "images/stripe.png"

// material-ui style components 
import {
    Card,
    CardContent,
    Grid,
    TableCell,
    TableRow,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Typography,
    tableCellClasses,
    Link as MuiLink,
    Paper,
    TableHead,
    TableBody,
    CircularProgress,
    DialogContent,
    Dialog,
    DialogActions,
    Button,
    InputAdornment,
    DialogTitle,
    Slide,
    Box,
    Chip,
    Stack,
    IconButton,
    DialogContentText,
    Tooltip,
    FormGroup,
    Checkbox,
} from "@mui/material";
import { Table } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

//custom css file
import "./purchaseCredit.scss";
import styled from "@emotion/styled";

// custom components
import Footer from "components/footer/footer";
import Header from "components/header/header";
import Usersidebar from 'components/user-sidebar/user-sidebar';
import { ContainerLoader } from "components/common/ContainerLoader";
import AlertMessage from "components/message/message";
import { useUserAPICollection } from "Hooks/useUserAPICollection";

//mui  icon  
import customDateFormatLocal from "utils/customDateFormatLocal";
import { Close, Delete, Loop } from "@mui/icons-material";
import { decodeBase64ToParams, encodeParamsToBase64 } from "utils/base64";
import useAlertMessage from "Hooks/useAlertMessage";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
import ConfirmationModal from "utils/confirmationModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Barlow !important",
        fontWeight: "bold",
        textAlign: "left",
        position: "relative",
        minWidth: "max-content",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        fontFamily: "Barlow !important",
        textAlign: "left",
        color: "#3C4E6A",
        borderBottom: "1px solid #CCCCCC",
        width: "auto",
        padding: "12px 15px",
    },
}));

const StyledTableRows = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginTop: "10px",
    color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PurchaseCredit = () => {
    const {
        getSripSavedCardsAPi,
        getPreviousCreditPaymentAPI,
        getAvailableCredit,
        purchaseCreditApi,
        addStripeCustPayMethodApi,
        deleteProgessTaskaApi,
        deleteStripePaymentMethodApi
    } = useUserAPICollection();
    const { alertMessage, errorMessage, showAlert, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(true);
    const [userPurchasePaymentHistory, setUserPurchasePaymentHistory] = useState([]);
    const [userAvailableCredit, setUserAvailableCredit] = useState(0);
    const [userEnterAmount, setUserEnterAmount] = useState(5000.00)
    const [formData, setFormData] = useState({
        amount: "",
        displayamount: ""
    });
    const [isLoadingSavedCard, setIsLoadingSavedCard] = useState({
        isLoadingSavedCard: true,
        isDisabledRefreshBtn: false,
        isPaymentFailed: false,
        isContentLoading: false,
    })
    const [queryStringRemovedByUser, setQueryStringRemovedByUser] = useState(false);
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const [stripeCardList, setStripeCardList] = useState([])
    const [selectedCard, setSelectedCard] = useState(null);
    const [isVisibileChooseBackupPaymentMethodModal, setIsVisibileChooseBackupPaymentMethodModal] = useState(false);
    const [isModalStates, setIsModalStates] = useState({
        isDisabledPurchaseCreditButton: false,
        isButtonDisabled: false,
    })
    const [isAnimationText, setIsAnimationText] = useState(false);
    const [duration, setDuration] = useState()
    const [cardId, setCardId] = useState("")
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
    const [isTermsCondition, setIsTermsCondition] = useState(false)
    let eventSource = null;

    const getUserAvailableCredit = async () => {
        try {
            const response = await getAvailableCredit();
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                setUserAvailableCredit(parseFloat(data?.data) ?? 0)
            }
        } catch (error) {
            errorMessage();
        }
    };

    const getUserPurchasePaymentHistory = async () => {
        try {
            const response = await getPreviousCreditPaymentAPI()
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsLoading(false)
            } else {
                setUserPurchasePaymentHistory(data?.data)
                setIsLoading(false)
            }
        } catch (error) {
            errorMessage()
            setIsLoading(false)
        }
    };

    const handlePurchaseCredit = async () => {
        if (selectedCard) {
            try {
                handleSetStates();
                const params = {
                    amount: parseFloat(formData.amount),
                    payment_method_id: selectedCard
                };
                const response = await purchaseCreditApi(params);
                const { data } = response;
                if (data.error) {
                    errorMessage();
                    handleReSetStates();
                    setIsVisibileChooseBackupPaymentMethodModal(false)
                } else {
                    setIsVisibileChooseBackupPaymentMethodModal(false)
                    setSelectedCard(null);
                    setDuration(1000)
                    history.replace(window.location.pathname);
                    setTimeout(() => { errorMessage(data.message, "success"); }, 100)
                    handleReSetStates();
                    eventSource = new EventSource(process.env.REACT_APP_API_URL + `/api/user/purchaseCredit/status/${data.taskId}`);
                    await serverSideProgress(data?.taskId, "purchaseCredit")
                }
            } catch (error) {
                errorMessage();
                handleReSetStates();
                setIsVisibileChooseBackupPaymentMethodModal(false)
            }
        } else {
            errorMessage("Please Select a Payment Method!", "error");
        }
    };


    const handleDeleteProgessTask = async (id) => {
        try {
            const response = await deleteProgessTaskaApi({ _id: id })
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                console.log('sss')
            }
        } catch (error) {
            errorMessage();
        }
    }

    const serverSideProgress = (id, generateType) => {
        eventSource.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data.data.status === "completed") {
                setDuration(4000)
                setTimeout(() => { errorMessage("Payment Successful", "success") }, 1000)
                setIsAnimationText(true)
                setIsTermsCondition(false)
                await getUserPurchasePaymentHistory()
                await getUserAvailableCredit()
                setFormData({
                    ...formData,
                    amount: ""
                });
                eventSource.close();
                await handleDeleteProgessTask(id)
                setTimeout(() => {
                    setIsAnimationText(false)
                }, 3000)
            } else if (data.data.status === "failed") {
                console.log(data.data && data.data.response && data.data.response.message);
                setDuration(4000)
                errorMessage(data.data && data.data.response && data.data.response.message, "error");
                setIsAnimationText(false)
                setIsTermsCondition(false)
                setFormData({
                    ...formData,
                    amount: ""
                });
                eventSource.close();
            }
        }
    }

    const getSavedStripeList = async () => {
        try {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: true,
                isDisabledRefreshBtn: true
            });
            const response = await getSripSavedCardsAPi();
            const { data } = response;
            if (data.error) {
                errorMessage()
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            } else {
                setStripeCardList(data?.payment_methods)
                setIsLoadingSavedCard({
                    ...isLoadingSavedCard,
                    isLoadingSavedCard: false,
                    isDisabledRefreshBtn: false
                });
            }
        } catch (error) {
            setIsLoadingSavedCard({
                ...isLoadingSavedCard,
                isLoadingSavedCard: false,
                isDisabledRefreshBtn: false
            });
            errorMessage()
        }
    };

    const handleAddStripeCustomPayMethod = async () => {
        const url = window.location.href;
        const params = {
            amount: formData?.amount,
            displayamount: formData?.displayamount,
            isModal: true
        };
        const encodedParams = encodeParamsToBase64(params);
        if (encodedParams) {
            try {
                handleSetStates()
                const response = await addStripeCustPayMethodApi({ success_url: `${url}?p=${encodedParams}` })
                const { data } = response;
                if (data.error) {
                    handleReSetStates()
                    errorMessage()
                } else {
                    window.location.href = data?.checkout_url;
                    handleReSetStates()
                }
            } catch (error) {
                errorMessage()
                handleReSetStates()
            }
        } else {
            showAlert({
                message: 'Something went wrong, Please try again ',
                alertSeverity: "error"
            })
            handleReSetStates()
        }
    }

    const handleDeleteStripePaymentMethod = async () => {
        try {
            setIsDeleteModalShow(false)
            const response = await deleteStripePaymentMethodApi({ payment_method_id: cardId })
            const { data } = response;
            if (data.error) {
                errorMessage()
            } else {
                errorMessage(data.message, "success")
                await getSavedStripeList()
            }
        } catch (error) {
            errorMessage()
        }
    }

    const handleVisableDeleteConfimationModal = (id) => {
        setIsDeleteModalShow(true)
        setCardId(id)
    }

    const handleCloseDeleteModal = (id) => {
        setIsDeleteModalShow(false)
        setCardId("")
    }

    const handleReSetStates = () => {
        setIsModalStates({
            ...isModalStates,
            isDisabledPurchaseCreditButton: false
        })
    }

    const handleSetStates = () => {
        setIsModalStates({
            ...isModalStates,
            isDisabledPurchaseCreditButton: true
        })
    }

    const handleVisibileChoosePaymentModal = () => {
        if (formData.amount) {
            setIsVisibileChooseBackupPaymentMethodModal(true)
            getSavedStripeList()
        }
    }

    const handleResetStates = () => {
        setIsVisibileChooseBackupPaymentMethodModal(false)
        setSelectedCard(null)
        history.replace(window.location.pathname);
    }

    const handleCardClick = (id) => {
        setSelectedCard(id);
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleAgreeTermsConditon = (e) => {
        setIsTermsCondition(e.target.checked)
    }

    const handleOnChangeAmounts = (e) => {
        const cursorPosition = e.target.selectionStart;
        const newValue = e.target.value;
        if (newValue !== '') {
            let cleanValue = newValue.replace(/[^0-9.]/g, "");
            const decimalIndex = cleanValue.indexOf('.');

            if (decimalIndex !== -1) {
                const decimalPart = cleanValue.slice(decimalIndex + 1, decimalIndex + 3);
                cleanValue = cleanValue.substring(0, decimalIndex + 1) + decimalPart.padEnd(2, '0');
            } else {
                cleanValue += '.00';
            }
            const amountValue = parseFloat(cleanValue.replace(/,/g, ""));
            const isButtonDisabled = amountValue < 100;
            setFormData({ ...formData, amount: cleanValue, displayamount: cleanValue });

            setIsModalStates({ ...isModalStates, isButtonDisabled });
        } else {
            setFormData({ ...formData, amount: '' });
        }
        // Restore cursor position
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    const handleSetValue = (e, value) => {
        if (value) {
            const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
            const formattedValue = numericValue.toFixed(2);
            setFormData({ ...formData, amount: formattedValue });
            setIsModalStates({ ...isModalStates, isButtonDisabled: false });
        } else {
            setFormData({ ...formData, amount: '' });
        }
    }

    useEffect(() => {
        const encodedParams = urlParams.get("p")
        const decodedParams = decodeBase64ToParams(encodedParams);
        if (decodedParams) {
            setIsVisibileChooseBackupPaymentMethodModal(true)
            setFormData({
                ...formData,
                amount: decodedParams?.amount,
                displayamount: decodedParams?.displayamount
            })
        }
        getUserAvailableCredit();
        getUserPurchasePaymentHistory();
        getSavedStripeList();
    }, [])

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP' && !queryStringRemovedByUser) {
                history.replace(location.pathname);
            }
            setQueryStringRemovedByUser(false);
        });
        return () => {
            unlisten();
        };
    }, [history, queryStringRemovedByUser])

    return (
        <>
            <div className="user-dashboard-wrapper">
                <Helmet>
                    <title>Purchase Credit</title>
                </Helmet>
                <Header />
                <div className="c-app c-default-layout user-dashboard-body">
                    <Usersidebar />
                    <div className="c-wrapper">
                        <div className="c-body">
                            <main className="c-main">
                                <Grid item xs={12} className="space-top-col">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className="space-top-col">
                                            {!isLoading ? (
                                                <>
                                                    <Card
                                                        sx={{
                                                            width: "100%",
                                                            maxWidth: "100%",
                                                            color: "#000059!important",
                                                            textAlign: "left",
                                                            padding: "30px 30px 0px 30px",
                                                            backgroundColor: "#fbfbfb;",
                                                        }}
                                                    >
                                                        <div className="vendortable-header">
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    width: "100%",
                                                                    maxWidth: "14rem",
                                                                    color: "#000059!important",
                                                                    textAlign: "left",
                                                                    fontWeight: "bold",
                                                                    fontSize: "1.6rem",
                                                                    fontFamily: "Barlow!important",
                                                                    marginBottom: '47px'
                                                                }}
                                                            >
                                                                {" "}
                                                                Purchase Credit
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                sx={{ justifyContent: "end" }}
                                                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                                                columns={{ xs: 4, sm: 8, md: 8 }}
                                                                className="button-align-purchase"
                                                            >
                                                                <Grid item sx={{ position: 'relative' }}>
                                                                    <Card className="card-custom-lic-pur">
                                                                        <CardContent className="licnses-cardContent-purchase">
                                                                            <div className="account-have-img-license-purchase">
                                                                                <Grid container spacing={0}>
                                                                                    <Grid item xs={6} sm={6} md={6} lg={2}
                                                                                        sx={{
                                                                                            padding: "0px 15px!important",
                                                                                            display: "flex",
                                                                                            alignItems: "flex-end",
                                                                                            justifyContent: "flex-start",
                                                                                        }}
                                                                                    >
                                                                                        <div className="plan-prices-lic-pur card-textAlign">
                                                                                            <h2 style={{
                                                                                                width: '100%',
                                                                                                minWidth: "max-content",
                                                                                                color: '#000057 !important',
                                                                                                marginBottom: "0px",
                                                                                                marginTop: "14px"
                                                                                            }}
                                                                                            >
                                                                                                Available Credit
                                                                                            </h2>
                                                                                            <span>{changeDollarAmountformat(userAvailableCredit?.toFixed(2)) ?? "00.0"}</span>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </CardContent>
                                                                    </Card>
                                                                    {isAnimationText ? (
                                                                        <div className="css-animation">
                                                                            <div class="cssanimation sequence fadeInBottom  css-h1">
                                                                                <div className="plan-prices-lic-pur card-textAlign">
                                                                                    <span>+{(parseFloat(formData.displayamount ?? 0.00).toFixed(2))}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (null)}
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <CardContent sx={{
                                                            padding: "0px!important",
                                                            marginTop: "30px!important",
                                                        }}
                                                            className="plan-tbl vendor-outer"
                                                        >
                                                            <ValidatorForm className="filter-form mb-5" onSubmit={() => handleVisibileChoosePaymentModal()} >
                                                                <Grid container spacing={2} justify="center">
                                                                    <Grid item container direction="column" xs={12} sm={12} spacing={2}>
                                                                        <Grid item>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12} sx={{ marginTop: "-31px" }}>
                                                                                    <label className="Account_details_box">
                                                                                        Amount
                                                                                    </label>
                                                                                    <TextValidator
                                                                                        onChange={(e) => handleOnChangeAmounts(e)}
                                                                                        value={formData?.amount ?? ""}
                                                                                        variant="outlined"
                                                                                        placeholder="Enter an amount between $100.00 and $5,000.00"
                                                                                        fullWidth
                                                                                        type="text"
                                                                                        id="currencyInput"
                                                                                        name="credit"
                                                                                        autoComplete="off"
                                                                                        validators={["required"]}
                                                                                        errorMessages={["This field is required"]}
                                                                                        InputProps={{
                                                                                            startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                                                                                {formData?.amount === "" ? "" : "$"}
                                                                                            </InputAdornment>,
                                                                                            endAdornment: parseInt(formData?.amount) ? (
                                                                                                <InputAdornment
                                                                                                    onClick={() => setFormData({ ...formData, amount: "" })}
                                                                                                    position="start"
                                                                                                    sx={{ marginRight: "-2px", cursor: "pointer" }}
                                                                                                >
                                                                                                    <Close />
                                                                                                </InputAdornment>
                                                                                            ) : null
                                                                                        }}
                                                                                    />
                                                                                    <Stack direction="row" spacing={1} sx={{ paddingTop: "6px" }}>
                                                                                        <Chip label="$1,000.00" size="small" onClick={(e) => handleSetValue(e, "1000")} />
                                                                                        <Chip label="$2,500.00" size="small" onClick={(e) => handleSetValue(e, "2500")} />
                                                                                        <Chip label="$5,000.00" size="small" onClick={(e) => handleSetValue(e, "5000")} />
                                                                                    </Stack>
                                                                                    {parseFloat(formData.amount.replace(/,/g, "")) > userEnterAmount &&
                                                                                        <label className="Account_details_box" style={{ color: "red" }}>
                                                                                            Contact a Ticket Liberty administrator at sales@ticketliberty.com for custom credit purchases.
                                                                                        </label>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Paper
                                                                                        sx={{
                                                                                            backgroundColor: "#fff",
                                                                                            padding: "1rem 0.5rem",
                                                                                            height: "auto"
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                padding: "0 1rem",
                                                                                                marginBottom: "0.6rem",
                                                                                            }}
                                                                                        >
                                                                                            <h6
                                                                                                className="notifaction_tittle"
                                                                                                sx={{
                                                                                                    width: "100%",
                                                                                                    maxWidth: "14rem",
                                                                                                    color: "#000059!important",
                                                                                                    textAlign: "left",
                                                                                                    fontWeight: "bold",
                                                                                                    fontSize: "1.6rem",
                                                                                                    fontFamily: "Barlow!important",
                                                                                                    marginLeft: "-6px"
                                                                                                }}
                                                                                            >
                                                                                                Previous Credit Purchased
                                                                                            </h6>
                                                                                            <MuiLink
                                                                                                to='/history-payment'
                                                                                                underline="none"
                                                                                                component={Link}
                                                                                                sx={{
                                                                                                    textDecoration: "none!important",
                                                                                                    color: "#002986!important",
                                                                                                    fontWeight: "500",
                                                                                                    fontSize: "13px",
                                                                                                    fontFamily: "Barlow!important",
                                                                                                }}
                                                                                            >
                                                                                                View All
                                                                                            </MuiLink>
                                                                                        </div>
                                                                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                                                            <TableHead>
                                                                                                <TableRow>
                                                                                                    <StyledTableCell>
                                                                                                        Date
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell>
                                                                                                        Credit
                                                                                                    </StyledTableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {userPurchasePaymentHistory && userPurchasePaymentHistory.length > 0 ? (
                                                                                                    userPurchasePaymentHistory && userPurchasePaymentHistory.slice(0, 5).map((item, index) => {
                                                                                                        return (
                                                                                                            <StyledTableRows>
                                                                                                                <StyledTableCell component="td" scope="row">
                                                                                                                    {customDateFormatLocal(
                                                                                                                        item.created,
                                                                                                                    )}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell component="td" scope="row">
                                                                                                                    {item.entry_type === "debit"
                                                                                                                        ? "-"
                                                                                                                        : ""}
                                                                                                                    {changeDollarAmountformat(Number(item.amount).toFixed(2))}
                                                                                                                </StyledTableCell>
                                                                                                            </StyledTableRows>
                                                                                                        )
                                                                                                    })
                                                                                                ) : (
                                                                                                    <StyledTableRows>
                                                                                                        <td
                                                                                                            colSpan={13}
                                                                                                            style={{ padding: "2.6rem 1rem" }}
                                                                                                        >
                                                                                                            <Typography
                                                                                                                variant="h6"
                                                                                                                sx={{
                                                                                                                    fontSize: "1.6rem",
                                                                                                                    textAlign: "center",
                                                                                                                }}
                                                                                                            >
                                                                                                                No Credit Found
                                                                                                            </Typography>
                                                                                                        </td>
                                                                                                    </StyledTableRows>
                                                                                                )}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </Paper>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} sx={{ textAlign: 'right' }} >
                                                                        <LoadingButton
                                                                            type="submit"
                                                                            size="large"
                                                                            disabled={(parseFloat(formData.amount.replace(/,/g, "")) > userEnterAmount) || isModalStates?.isButtonDisabled}
                                                                            className={`${(parseFloat(formData.amount.replace(/,/g, "")) > userEnterAmount) || isModalStates?.isButtonDisabled ? 'theme-btn-generate-licenses-disabled-purchase-cerdit' : "theme-btn-generate-licenses-purchase-cerdit"}`}
                                                                            loadingPosition="end"
                                                                            variant="contained"
                                                                        >
                                                                            Purchase
                                                                        </LoadingButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} sx={{ textAlign: 'right' }} >
                                                                        <img style={{ width: "123px", height: "30px", marginTop: "-28px" }} src={Stripe} alt="stripe" />
                                                                    </Grid>
                                                                </Grid>
                                                            </ValidatorForm>
                                                        </CardContent>
                                                    </Card>
                                                </>
                                            ) : (
                                                <ContainerLoader />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </main>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
            {/* save card modal  */}
            <Dialog
                className="purchase-credit-modal-choose"
                onClick={handleBackdropClick}
                fullWidth={true}
                maxWidth="md"
                onClose={handleResetStates}
                aria-labelledby="customized-dialog-title"
                open={isVisibileChooseBackupPaymentMethodModal}
                TransitionComponent={Transition}
            >
                <DialogTitle id="customized-dialog-title" className="title-choose-payment-method" sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: "10px 28px 0px 23px"
                }}>
                    Choose Payment Method
                    {stripeCardList && stripeCardList?.length > 0 ? (
                        <Typography
                            variant="h5"
                        >
                            <span className="Account_details_box-lic" >
                                <LoadingButton
                                    onClick={() => getSavedStripeList()}
                                    size="small"
                                    loading={isLoadingSavedCard?.isDisabledRefreshBtn}
                                    endIcon={isLoadingSavedCard?.isDisabledRefreshBtn ? (<CircularProgress color="inherit" size={20} />) : (<Loop />)}
                                    disabled={isLoadingSavedCard?.isDisabledRefreshBtn}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Refresh
                                </LoadingButton>
                            </span>
                        </Typography>
                    ) : (null)}
                </DialogTitle>
                <DialogContent sx={{ padding: "3px 24px!important" }}>
                    <div className="scrollDiv-card-puchaseCredit">
                        <FormControl className="saved-card-details">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedCard}
                                name="radio-buttons-group"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item>
                                            <span className="Account_details_box-lic purchase-license-modal-texttwo  heading-modal-choose-payment">
                                                <span className="purchase-lic-modal-heading2"
                                                    style={{
                                                        fontSize: "15px",
                                                        padding: "0px 0px 9px 0px"
                                                    }}>
                                                    Saved Cards
                                                </span>
                                            </span>
                                            <div className="inner-wrapper-purchase">
                                                {!isLoadingSavedCard?.isLoadingSavedCard ? (
                                                    stripeCardList && stripeCardList?.length > 0 ? (
                                                        stripeCardList && stripeCardList?.map((item) => (
                                                            <div className="credit-card-inner">
                                                                <div className={`credit-card ${selectedCard === item.id ? 'selected' : ''}`} onClick={() => handleCardClick(item.id)}>
                                                                    <FormControlLabel
                                                                        key={item.id}
                                                                        value={item.id}
                                                                        control={<Radio sx={{ marginTop: "6px" }} />}
                                                                        sx={{ width: '100%' }}
                                                                        label={
                                                                            <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                                                <div className="content">
                                                                                    <div className="content-card-name">
                                                                                        <div>Card Holder : {item?.name ?? null}</div>
                                                                                        <div>{(item?.card && item?.card?.brand?.toUpperCase()) ?? null}</div>
                                                                                    </div>
                                                                                    <div className="content-card-detail">
                                                                                        <span className="card-number">XXXX XXXX XXXX {(item?.card && item?.card?.last4) ?? null}</span>
                                                                                        <span>Expiry Date : {(item?.card && item?.card?.exp_month) ?? null}/{(item?.card && item?.card?.exp_year) ?? null}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </div>
                                                                &nbsp;&nbsp;
                                                                <div className="credit-card-delete">
                                                                    <Stack direction="row" spacing={1}>
                                                                        <Tooltip title="Delete">
                                                                            <IconButton aria-label="delete" onClick={() => handleVisableDeleteConfimationModal(item.id)}>
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        ))) : (
                                                        <div>
                                                            <td
                                                                colSpan={13}
                                                                style={{ padding: "0.6rem 17rem 25px" }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "1.6rem",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    No Card Found
                                                                </Typography>
                                                            </td>
                                                        </div>
                                                    )) : (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "11rem 0",
                                                        }}
                                                    >
                                                        <div style={{ position: 'relative', top: '4px' }}>
                                                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                                        </div>
                                                    </Box>
                                                )}
                                            </div>
                                        </Item>
                                        {stripeCardList && stripeCardList?.length > 0 ? (
                                            <span className="Account_details_box-lic purchase-license-modal-texttwo">
                                                <span className="purchase-lic-modal-heading2"
                                                    style={{
                                                        fontSize: "15px",
                                                        color: "red !important"
                                                    }}>
                                                    Note: Please hit refresh above to get the latest saved cards
                                                </span>
                                            </span>
                                        ) : (null)}
                                    </Grid>
                                    <Grid item xs={12} sx={{ paddingTop: "4px!important" }}>
                                        <Item>
                                            <span className="Account_details_box-lic purchase-license-modal-texttwo  heading-modal-choose-payment">
                                                <span className="purchase-lic-modal-heading2"
                                                    style={{
                                                        fontSize: "15px",
                                                        padding: "0px 0px 9px 0px"
                                                    }}>
                                                    Add Payment Method
                                                </span>
                                            </span>
                                            <div className={`credit-card credit-card-other-option ${selectedCard === 'other' ? 'selected' : ''}`}
                                                onClick={() => {
                                                    handleCardClick('other')
                                                    handleAddStripeCustomPayMethod()
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={selectedCard === "other" ? "other" : ""}
                                                    control={<Radio sx={{ marginTop: "6px" }} />}
                                                    sx={{ width: '100%' }}
                                                    label={
                                                        <Typography variant="body5" color="textSecondary" sx={{ width: "100%" }}>
                                                            <div className="content">
                                                                <div className="content-card-name">
                                                                    <div className="other-text">Other</div>
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    }
                                                />
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12} sx={{ padding: "0px!important", paddingLeft: "16px!important" }}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => handleAgreeTermsConditon(e)}
                                                        checked={isTermsCondition}
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontWeight: "bold", color: "#321fdb" }}>
                                                        I agree to the{" "}
                                                        <Link
                                                            style={{ fontWeight: "bold", textDecoration: "none" }}
                                                            passHref
                                                            target="_blank"
                                                            to="/terms-and-conditions"
                                                        >
                                                            <span style={{ textDecoration: "underline" }}>
                                                                Terms and Conditions.
                                                            </span>
                                                        </Link>
                                                    </span>
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleResetStates}
                        variant="contained"
                        type="button"
                        size="small"
                        className={`${isModalStates?.isDisabledPurchaseCreditButton || selectedCard === "other" ?
                            'theme-btn-generate-licenses-disabled-pur-lic' : "theme-btn-generate-licenses-pur-lic"}`}
                        disabled={isModalStates?.isDisabledPurchaseCreditButton || selectedCard === "other"}
                        sx={{
                            padding: isModalStates?.isDisabledPurchaseCreditButton || selectedCard === "other" ? "4px 38px" : "",
                            fontSize: isModalStates?.isDisabledPurchaseCreditButton || selectedCard === "other" ? "large" : ""
                        }}
                    >
                        Cancel
                    </Button>
                    {/* {(selectedCard === "other" || !selectedCard) ? (
                        <LoadingButton
                            size="large"
                            loading={isModalStates?.isDisabledPurchaseCreditButton}
                            endIcon={isModalStates?.isDisabledPurchaseCreditButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                            disabled={isModalStates?.isDisabledPurchaseCreditButton || !selectedCard}
                            className={`${isModalStates?.isDisabledPurchaseCreditButton || !selectedCard ?
                                'theme-btn-generate-licenses-disabled-pur-lic' : "theme-btn-generate-licenses-pur-lic"}`}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                                padding: (selectedCard === "other" || !selectedCard) ? "4px 38px" : "",
                                fontSize: (selectedCard === "other" || !selectedCard) ? "large" : ""
                            }}
                            onClick={() => handleAddStripeCustomPayMethod()}
                        >
                            Purchase
                        </LoadingButton>
                    ) : ( */}
                    <LoadingButton
                        size="large"
                        loading={isModalStates?.isDisabledPurchaseCreditButton}
                        endIcon={isModalStates?.isDisabledPurchaseCreditButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                        disabled={isModalStates?.isDisabledPurchaseCreditButton || !selectedCard || isTermsCondition === false ? true : false}
                        className={`${isModalStates?.isDisabledPurchaseCreditButton || !selectedCard || isTermsCondition === false ? true : false ?
                            'theme-btn-generate-licenses-disabled-pur-lic' : "theme-btn-generate-licenses-pur-lic"}`}
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                            padding: (!selectedCard || isTermsCondition === false ? true : false) ? "4px 38px" : "",
                            fontSize: (!selectedCard || isTermsCondition === false ? true : false) ? "large" : "",
                            textTransform: "none"
                        }}
                        onClick={() => handlePurchaseCredit()}
                    >
                        {formData?.amount ? `Confirm Purchase of ${changeDollarAmountformat(formData?.amount)}` : "Purchase"}
                    </LoadingButton>
                    {/* )} */}
                </DialogActions>
            </Dialog >
            {/* {delete conmation modal} */}
            <ConfirmationModal
                handleAPIRequest={handleDeleteStripePaymentMethod}
                isOpen={isDeleteModalShow}
                handleClose={handleCloseDeleteModal}
                message={"Are you sure you want to delete this payment method?"}
                title={"Delete"}
                buttonText={"Delete"}
                buttonText2={"Cancel"}
            />
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                duration={duration ?? 2000}
                closeCall={hideAlert}
            />
        </>
    )
};
export default PurchaseCredit;