// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/admin",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "User & Accounts",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Communication Employees",
        to: "/communication-users",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Users",
        to: "/users-management",
      },
      {
        _tag: "CSidebarNavItem",
        name: "User Sessions",
        to: "/users-sessions",
      },
      {
        _tag: "CSidebarNavItem",
        name: "VBD Accounts",
        to: "/vbdaccounts/lists",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Add Credit",
        to: "/add-custom-credit",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Remove Credit",
        to: "/remove-custom-credit",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Vendor Lists",
        to: "/admin-vendor-lists",
      },
      {
        _tag: "CSidebarNavItem",
        name: "History of Payments",
        to: "/history-of-payment",
       
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Logs",
        to: "/manage-logs",
       
      },
      {
        _tag: "CSidebarNavItem",
        name: "Users Packages",
        to: "/users-packages",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Contacts",
    to: "/contact-management",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Interactive Management',
  //   to: '/interactive-management',
  //   // icon: 'cil-speedometer',
  //   badge: {
  //     color: 'info'
  //   }
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Interactive Inventories",
    to: "/interactive-management",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sold Inventories",
    to: "/sold-inventory-management",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Manage Links",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Links Delivered",
        to: "/links-delivered",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Links Used",
        to: "/links-used",
      }
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Instruction Guide",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Manage Pages",
        to: "/instruction-pages",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Sections",
        to: "/instruction-management",
      },
     
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Manage Affiliates",
    to: "/manage-affiliates",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Manage Split Links",
    to: "/split-links",
    // icon: 'cil-speedometer',
    badge: {
      color: "info",
    },
  },
 
  {
    _tag: "CSidebarNavDropdown",
    name: "Messages",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Send",
        to: "/message/send",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Sent List",
        to: "/messages/list",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Text recognition",
        to: "/messages/text-recognition",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Incoming Messages",
        to: "/messages/incoming-list",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Settings",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Manage Packages",
        to: "/package-management",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Credit Packages",
        to: "/credit-packages",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Auto Messaging Format",
        to: "/auto-messaging-format",
      },
      {
        _tag: "CSidebarNavItem",
        name: "VBD Ticket Price",
        to: "/vbd-ticket-price",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Scan Limit",
        to: "/manage_scan_limit",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Vendor Terms",
        to: "/vendor",
      },
      {
        _tag: "CSidebarNavItem",
        name: "League Terms",
        to: "/league",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Team Terms",
        to: "/team",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Internal Notes",
        to: "/internal-notes",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Blacklisted Numbers",
        to: "/blacklisted-numbers",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Announcement",
        to: "/announcement",
      },
      {
        _tag: "CSidebarNavItem",
        name: "What's New",
        to: "/what's-new",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Terms and conditions",
        to: "/terms_conditions",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Browser ",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Proxy Management",
        to: "/proxy-management",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Profiles",
        to: "/view-profiles",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Licenses",
        to: "/manage-licenses",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Manage Browser Stats",
        to: "/manage-browser",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Browser Pricing",
        to: "/browser-pricing",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Licenses Logs",
        to: "/admin-licenses-logs",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Browser Sessions",
        to: "/browser-sessions",
      },
      {
        _tag: "CSidebarNavItem",
        name: "User Agent",
        to: "/useragent-detail",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Fingerprint",
        to: "/fingerprint-detail",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Fonts",
        to: "/font-management",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Release Notes",
        to: "/add-release-notes",
      },
      {
        _tag: "CSidebarNavItem",
        name: "EULA",
        to: "/add-enduser-license-agreement",
      },
    ],
  },

  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Theme']
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: 'cil-drop',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: 'cil-pencil',
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Components']
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Base',
  //   route: '/base',
  //   icon: 'cil-puzzle',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Forms',
  //       to: '/base/forms',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Jumbotron',
  //       to: '/base/jumbotrons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navs',
  //       to: '/base/navs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navbars',
  //       to: '/base/navbars',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Progress',
  //       to: '/base/progress-bar',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Switches',
  //       to: '/base/switches',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tabs',
  //       to: '/base/tabs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Buttons',
  //   route: '/buttons',
  //   icon: 'cil-cursor',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Brand buttons',
  //       to: '/buttons/brand-buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Dropdowns',
  //       to: '/buttons/button-dropdowns',
  //     }
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: 'cil-chart-pie'
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Icons',
  //   route: '/icons',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Notifications',
  //   route: '/notifications',
  //   icon: 'cil-bell',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Toaster',
  //       to: '/notifications/toaster'
  //     }
  //   ]
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   _tag: 'CSidebarNavDivider'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Extras'],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Pages',
  //   route: '/pages',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Login',
  //       to: '/',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Disabled',
  //   icon: 'cil-ban',
  //   badge: {
  //     color: 'secondary',
  //     text: 'NEW',
  //   },
  //   addLinkClass: 'c-disabled',
  //   'disabled': true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Labels']
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label danger',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-danger'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label info',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-info'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label warning',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-warning'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // }
];
