import React, { Component } from "react";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import customDateFormat from "utils/customDateFormat";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontFamily: "Barlow !important",
    fontWeight: "bold",
    textAlign: "left",
    position: "relative",
    padding: "13px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    fontFamily: "Barlow !important",
    textAlign: "left",
    color: "#3C4E6A",
    borderBottom: "1px solid #CCCCCC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
export default class splitLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
      successError: "",
      openalert: false,
      alertseverity: "",
      loader: false,
      allHistory: [],
      copiedIndex: null,
      copiedUrl: false,
      link: "",
      parentIndex: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getLinkDetails = this.getLinkDetails.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("mtchRegexp", (value) => {
      // regex to check url is valid or not
      const regex = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator

        "i" // fragment locator
      );
      if (regex.test(value)) {
        return true;
      }
      return false;
    });
    this.getLinkDetails();
  }

  handleClose = () => {
    this.setState({
      openalert: false,
    });
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  copyUrl = (url, index2, index) => {
    navigator.clipboard.writeText(url);
    this.setState({ copiedUrl: true, copiedIndex: index2, parentIndex: index });
  };
  getLinkDetails = () => {
    this.setState({
      loader: true,
    });
    const api_url = process.env.REACT_APP_API_URL + "/api/admin/getLinkDetails";
    axios
      .post(
        api_url,
        {
          link: "",
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            openalert: true,
            message: "Something went wrong",
            alertseverity: "error",
          });
        } else {
          this.setState({
            loader: false,
            allHistory: data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  clearDate = () => {
    this.setState({ selectedDate: null, crossCheck: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loader: true,
    });

    const api_url = process.env.REACT_APP_API_URL + "/api/admin/getLinkDetails";
    console.log(this.state.selectedUsers);
    axios
      .post(
        api_url,
        {
          link: this.state.link,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            openalert: true,
            message: data.message,
            alertseverity: "error",
          });
        } else {
          this.setState({
            loader: false,
            allHistory: data.data,
            message: data.message,
            openalert: true,
            alertseverity: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (!this.state.loader) {
      return (
        <>
          <Helmet>
            <title>Manage Split Links</title>
          </Helmet>
          <Card
            className="instruction-box"
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "background.paper",
              boxShadow: 1,
              borderRadius: 1,
              p: 2,
              mb: 5,
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              maxWidth: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                <Typography
                  variant="p"
                  component="h4"
                  gutterBottom
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: indigo[900],
                    textAlign: "left",
                    mb: 2,
                    fontFamily: "Barlow  , sans-serif!important",
                  }}
                >
                  Manage Split Links
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ pt: "5" }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ pt: "5" }}
              >
                <ValidatorForm
                  onSubmit={this.handleSubmit}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 2, lg: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid item xs={12} sm={4} md={5} lg={5}>
                        <TextValidator
                          placeholder="Paste url here"
                          variant="outlined"
                          fullWidth
                          name="link"
                          value={this.state.link}
                          onChange={this.handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  sx={{
                                    color: indigo[900],
                                  }}
                                  aria-label="submit"
                                  type="submit"
                                >
                                  {" "}
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          // validators={["mtchRegexp:^[a-zA-Z0-9]+$"]}
                          // errorMessages={[
                          //   "Url is not valid",
                          // ]}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  spacing={0.4}
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.allHistory.map((item, index) => {
                    return (
                      <Grid item xs={12} sm={10} md={12} lg={12} key={index}>
                        <Card
                          key={index}
                          sx={{
                            marginBottom: "10px",
                            boxShadow: 2,
                          }}
                        >
                          <CardHeader
                            sx={{
                              backgroundColor: indigo[900],
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "left",
                              fontFamily: "Barlow, sans-serif!important",
                              span: {
                                fontSize: "1rem",
                                fontFamily: "Barlow, sans-serif!important",
                                fontWeight: "bold",
                              },
                            }}
                            action={
                          item.inventory.delivery_status === 11 ||
                              item.inventory.delivery_status === 12 ? (
                                <Chip
                                  label="Revoked"
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                  color="error"
                                />
                              ) : (
                                ""
                              )

                            }
                            title={
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#fff!important",
                                  textAlign: "left",
                                  fontFamily: "Barlow, sans-serif!important",
                                }}
                              >
                                {`Split Link (${item.original_link.ticketIds.length})`}
                              </Typography>
                            }
                          />
                          <CardContent>
                            <Grid item xs={12} sm={10} md={9} lg={9}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "#002986",
                                  textAlign: "left",
                                  paddingBottom: "10px",
                                }}
                              >
                                Original Link &nbsp;{item.original_link.url}
                              </Typography>
                            </Grid>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <StyledTableRow>
                                    <StyledTableCell align="right">
                                      Ticket Url
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      No of Tickets
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Splited At
                                    </StyledTableCell>

                                    {item.name !== "" && (
                                      <StyledTableCell align="right">
                                        Name
                                      </StyledTableCell>
                                    )}
                                    {item.email !== "" && (
                                      <StyledTableCell align="right">
                                        Email
                                      </StyledTableCell>
                                    )}
                                    {item.phone !== "" && (
                                      <StyledTableCell align="right">
                                        Phone
                                      </StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                      Copy Url
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                  {item.split_links.map((row, index2) => (
                                    <StyledTableRow key={index2}>
                                      <StyledTableCell>
                                        {row.url}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.ticketIds.length}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {customDateFormat(item.createdAt)}
                                      </StyledTableCell>
                                      {item.name !== "" && (
                                        <StyledTableCell align="right">
                                          {item.name}
                                        </StyledTableCell>
                                      )}
                                      {item.email !== "" && (
                                        <StyledTableCell align="right">
                                          {item.email}
                                        </StyledTableCell>
                                      )}
                                      {item.phone !== "" && (
                                        <StyledTableCell align="right">
                                          {item.phone}
                                        </StyledTableCell>
                                      )}
                                      <StyledTableCell align="right">
                                        {this.state.copiedUrl &&
                                        this.state.copiedIndex === index2 &&
                                        this.state.parentIndex === index ? (
                                          <Button
                                            variant="contained"
                                            sx={{
                                              backgroundColor:
                                                "#919191C2!important",
                                              color: "white!important",
                                              textTransform: "none",
                                              fontWeight: "bold!important",
                                              padding: "6px 8px",
                                              fontSize: "14px!important",
                                              ".css-y6rp3m-MuiButton-startIcon":
                                                {
                                                  marginRight: "3px",
                                                  marginLeft: "3px",
                                                },
                                              ".css-y6rp3m-MuiButton-startIcon > :nth-of-type(1)":
                                                {
                                                  fontSize: "20px",
                                                },
                                            }}
                                            size="small"
                                            startIcon={<LibraryAddCheckIcon />}
                                          >
                                            Copied
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            sx={{
                                              backgroundColor:
                                                "#011C58!important",
                                              color: "white!important",
                                              textTransform: "none",
                                              fontWeight: "bold!important",
                                            }}
                                            onClick={() =>
                                              this.copyUrl(
                                                row.url,
                                                index2,
                                                index
                                              )
                                            }
                                            startIcon={<ContentCopyIcon />}
                                          >
                                            Copy
                                          </Button>
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <AlertMessage
                open={this.state.openalert}
                message={this.state.message}
                severity={this.state.alertseverity}
                closeCall={this.handleClose}
              />
            </Grid>
          </Card>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
