import React from 'react';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
} from '@coreui/react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import '../common.css';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress } from '@mui/material';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
class CreditPackageView extends React.Component {
    state = {
        name: '',
        email: '',
        description: '',
        freeCredit: '',
        package_order: '',
        price: '',
        created_at: '',
        loader: false,
        UserValues: [],
        user_list: [],
        imageData: '',
        allUser: ''
    }
    async componentDidMount() {
        console.log("idprops", this.props.match.params.id)

        this.setState({
            loader: true
        })
        const data = {
            id: this.props.match.params.id
        }
        const packageList_api_url = process.env.REACT_APP_API_URL + "/api/package/creditPackageDetail"
        await axios.post(packageList_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                console.log(data)
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: 'Something went wrong',
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        name: data.data.name,
                        // email: data.data.email,
                        description: data.data.description,
                        freeCredit: data.data.free_credit,
                        package_order: data.data.package_order,
                        price: data.data.price,
                        created_at: data.data.created,
                        user_list: data.data.user,
                        allUser: data.data.all_user,
                        imageData: data.data.image,
                        open: false,
                        message: '',
                        successError: 'success'
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })
        // User List Id
        const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers"
        await axios.post(userList_api_url, '', { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {

                const { data } = response;
                console.log("User Data", data);
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: 'Something went wrong',
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        UserValues: data.data,
                        open: false,
                        message: '',
                        successError: 'success'
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })

    }
    render() {
        const { name, description, freeCredit, package_order, price, created_at, loader, imageData } = this.state
        
        // const itemList = [];
        // let my_object = {};
        // my_object.email = "All"
        // //User List Compare Iteration 
        // if (this.state.allUser === 1) {
        //     // itemList.push("All");
        // }
        // else {
        //     this.state.UserValues.map((opt) => {
        //         this.state.user_list.filter(item => {
        //             if (item === opt._id) {
        //                 itemList.push(opt.email);
        //             }
        //             return null
        //         })

        //         return null
        //     })

        //     return null

        // }

        const itemList = [];

        // If this.state.allUser is 1, push "All" into the itemList
        if (this.state.allUser === 1) {
            itemList.push("All");
        } else {
            // Iterate over this.state.UserValues and filter user_list
            this.state.UserValues.forEach((opt) => {
                const filteredUsers = this.state.user_list.filter(item => item === opt._id);
                // Check if there are any matching items
                if (filteredUsers.length > 0) {
                    // Push the email(s) into itemList
                    filteredUsers.forEach((matchingId) => {
                        const matchingUser = this.state.user_list.find(item => item === matchingId);
                        if (matchingUser) {
                            itemList.push(matchingUser.email);
                        }
                    });
                }
            });
        }

        // Now itemList contains the desired values based on your conditions


        console.log(itemList)
        return (
            <>
                <Helmet>
                    <title>
                        Credit Package Detail
                    </title>
                </Helmet>
                <CRow className="admin-table-outer">
                    <CCol sm="12" xl="12">

                        <CCard>
                            <CCardHeader>
                                <h3>Credit Package Detail</h3>
                            </CCardHeader>
                            <CCardBody>
                                {!loader ?
                                    <Table striped bordered responsive className="package-detail-table">
                                        <thead>
                                            <tr>
                                                {/* <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="lbl">Name</td>
                                                <td>{name}</td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">Price</td>
                                                <td>{price}</td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">Package Order</td>
                                                <td>{package_order}</td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">Free Credit</td>
                                                <td>{freeCredit}</td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">Created Date</td>
                                                <td>{created_at}</td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">User List</td>
                                                {/* <td>{created_at}</td> */}
                                                <td>

                                                    <ul>
                                                        {
                                                            itemList.map((data) => {

                                                                return (<li style={{ float: 'left', marginRight: '10px' }}>
                                                                    {data + ',   '}

                                                                </li>)
                                                            })
                                                        }
                                                    </ul>

                                                </td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">Description</td>
                                                <td dangerouslySetInnerHTML={{
                                                    __html: description
                                                }}>


                                                </td>

                                            </tr>
                                            <tr>
                                                <td className="lbl">
                                                    Image
                                                </td>
                                                <td>
                                                    {
                                                        imageData ?
                                                            <div className="image-show-css">

                                                                <img src={imageData} className="img-fluid" alt='' />
                                                            </div>

                                                            :
                                                            'No Image'
                                                    }


                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                    :
                                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                                        <CircularProgress sx={{ color: "#002986" }} />
                                    </Box>
                                }
                            </CCardBody>
                        </CCard>

                    </CCol>

                </CRow>

            </>
        )
    }
}

export default CreditPackageView
