import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import MomentTimezone from "moment-timezone";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import PageLoader from "components/PageLoader/PageLoader";
import { connect } from "react-redux";
import UserProfileAction from "redux/actions/UserProfielAction";
import Cookies from 'js-cookie';
import { Card, CardContent, CardHeader, Grid, } from "@mui/material";
import { ContainerLoader } from "components/common/ContainerLoader";

const posList = [
  { title: "Skybox" },
  { title: "Manual/None" },
  { title: "Other" },
];
const marketPlace = [
  { title: "VividSeats" },
  { title: "SeatGeek" },
  { title: "Manual/None" },
  { title: "Other" },
];
class Profiel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      email: "",
      phone_number: "",
      marketPlaceData: [],
      businessName: "",
      businessEmail: "",
      timezone: "",
      notification_preference: "",
      pos: [],
      error: [],
      loader: true,
      open: false,
      message: "",
      successError: "",
      profileData: [],
      ref_Code: [],
      threshold_amount: 0,
      email_for_notification: "",
      deliverymsg_time: "immediate",
      auto_scan_events: "on",
    };
  }

  componentDidMount() {
    const data = this.props.UserProfile;
    if (data.data.error) {
      this.setState({
        loader: false,
        open: true,
        message: data.message,
        successError: "error",
      });
    } else {
      this.setState({
        loader: false,
        open: false,
        message: "",
        successError: "success",
        firstName: data?.data.name,
        email: data?.data.email,
        phone_number: data?.data.phone_number,
        textArea: data?.data.address,
        ref_Code: data?.data.referral_code,
        businessEmail: data?.data?.business_email,
        businessName: data?.data?.business_name,
        threshold_amount: data?.data.threshold_amount,
        email_for_notification: data?.data.email_for_notification,
        notification_preference: data?.data.notification_preference,
        timezone: data?.data.timezone,
        deliverymsg_time: data?.data.deliverymsg_time,
        auto_scan_events: data?.data.auto_scan_events,
      });

      posList.map((data) => {
        if (data.title === this.props.UserProfile.data.preferred_pos) {
          return this.setState({ pos: data });
        }
        return null;
      });

      marketPlace.map((data) => {
        if (data.title === this.props.UserProfile.data.preferred_marketplace) {
          return this.setState({ marketPlaceData: data });
        }
        return null;
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangePos = (e, newVal) => {
    this.setState({ pos: newVal });
  };
  handleChangeMarket = (e, newVal) => {
    this.setState({ marketPlaceData: newVal });
  };
  handleChangeTimezone = (e, newVal) => {
    this.setState({ timezone: newVal });
  };
  handleChangePreference = (e, newVal) => {
    this.setState({ notification_preference: newVal });
  };

  handleSubmit = () => {
    console.log('df')
    this.setState({ loader: true }, () => {
      const data = {
        name: this.state.firstName,
        phone_number: this.state.phone_number,
        preferred_pos: this.state.pos.title,
        preferred_marketplace: this.state.marketPlaceData.title,
        threshold_amount: this.state.threshold_amount,
        email_for_notification: this.state.email_for_notification,
        timezone: this.state.timezone,
      };

      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(updateDetail_api_url, data, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  render() {
    const {
      firstName,
      email,
      phone_number,
      //  Marketplace,POS, open, message, successError, profileData, textArea, ref_Code,
      threshold_amount,
      email_for_notification,
      businessEmail,
      businessName
    } = this.state;
    var timezones = MomentTimezone.tz.names();
    var notification_preferences = ["email", "mobile", "both"];

    return (
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>Update Profile</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                {!this.state.loader ? (
                  <Grid container spacing={2}>
                    <Grid item xs="12">
                      <Card sx={{ p: 1 }}>
                        <CardHeader title="Update Profile" />
                        <AlertMessage
                          open={this.state.open}
                          message={this.state.message}
                          severity={this.state.successError}
                          closeCall={this.handleClose}
                        />

                        <CardContent>
                          <ValidatorForm onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Name
                                  </label>
                                  <TextValidator
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="firstName"
                                    // defaultValue="name"
                                    value={firstName}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    readOnly={false}
                                  />
                                </div>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Email
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    // onChange={this.handleChange}
                                    name="email"
                                    value={email}
                                    validators={["required", "isEmail"]}
                                    errorMessages={[
                                      "This field is required",
                                      "email is not valid",
                                    ]}
                                  />
                                  {this.state.error.email && (
                                    <div className="error-msg">
                                      {this.state.error.email}
                                    </div>
                                  )}
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Business Name
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    name="businessName"
                                    disabled={true}
                                    value={businessName}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Business Email
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    name="businessEmail"
                                    value={businessEmail}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Phone Number
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="phone_number"
                                    value={phone_number}
                                  />
                                </div>
                              </Grid>
                              {/*<Grid item xs={12} sm={6}>
                                                                <div className="form-group login-wrapper">
                                                                    <label>Referral code</label>
                                                                        <TextValidator
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            onChange={this.handleChange}
                                                                            name="ref_Code"
                                                                            value={ref_Code}
                                                                            validators={['required']}
                                                                            errorMessages={[('This field is required')]}
                                                                        />
                                                                </div>
                                                                
                                                            </Grid>*/}
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Low Balance Indicator
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="threshold_amount"
                                    value={threshold_amount}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group select-outer">
                                  <label className="update-info-style">
                                    Timezone
                                  </label>
                                  <Autocomplete
                                    fullWidth
                                    onChange={this.handleChangeTimezone}
                                    id="combo-box-demo"
                                    name="timezone"
                                    options={timezones.filter((tzz) =>
                                      tzz.includes("US/")
                                    )}
                                    getOptionLabel={(option) =>
                                      option.replace("US/", "")
                                    }
                                    value={this.state.timezone}
                                    renderInput={(params) => (
                                      <TextValidator
                                        value={this.state.timezone}
                                        placeholder="Select Timezone"
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group login-wrapper1">
                                  <label className="update-info-style">
                                    Email For Notification
                                  </label>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange}
                                    name="email_for_notification"
                                    value={email_for_notification}
                                    validators={["isEmail"]}
                                    errorMessages={["email is not valid"]}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <div className="form-group select-outer">
                                  <label className="update-info-style">
                                    Notification Preference
                                  </label>
                                  <Autocomplete
                                    fullWidth
                                    onChange={this.handleChangePreference}
                                    id="combo-box-demo"
                                    name="notification_preference"
                                    options={notification_preferences}
                                    getOptionLabel={(option) =>
                                      option.toUpperCase()
                                    }
                                    value={this.state.notification_preference}
                                    renderInput={(params) => (
                                      <TextValidator
                                        value={
                                          this.state.notification_preference
                                        }
                                        placeholder="Select notification preference"
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                justifyContent="center"

                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  type="submit"
                                  size="large"
                                  sx={{
                                    padding: {
                                      xs: "10px 40px",
                                      sm: "10px 40px",
                                      md: "10px 180px",
                                    },
                                    fontSize: "19px",
                                    fontFamily: "Bevan!important",
                                  }}

                                // disabled={submitted}
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </ValidatorForm>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ):(<ContainerLoader />)}
              </main>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
// to dispatch user name

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profiel);
