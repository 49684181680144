import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { connect } from "react-redux";

// mui icons and svg icons
import {
  Clear,
  Event,
  ExpandMore
} from "@mui/icons-material";
import CalendarIcon from "images/user-dashboard/calendar-icon.svg";
import TicketsIcon from "images/user-dashboard/ticket-icon.svg";
import { HiOutlineSearch } from "react-icons/hi";

// mui components
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Autocomplete,
  TextField,
  Button,
  Accordion as MuiAccordion,
  Select,
} from "@mui/material";

// import components
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Pagination from "components/pagination/pagination";
import AlertMessage from "components/message/message";
import Usersidebar from "components/user-sidebar/user-sidebar";
import customDateFormat from "utils/customDateFormat";
import UserProfileAction from "redux/actions/UserProfielAction";
import DataTable from "components/DataTable/DataTable";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";

const moment = extendMoment(originalMoment);
class InteractiveInventoryPast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      events: [],
      totalRecords: 0,
      openedevent: null,
      allvendors: "",
      vendorLabel: null,
      vendorVal: null,
      selectedDate: null,
      selectedendDate: null,
      searchPage: 0,
      formData: {
        event_names: "",
        section: "",
        row: "",
        seat: "",
        startDate: null,
        endDate: null,
        limit: 20,
        skip: 0,
        pathname: "/inventory-past",
      },
      generateTicketMsg: "",
      generateTicketMsgAlertColor: "",
      generateTicketMsgOpen: false,
      generateTicketData: {
        customerDisplayName: "",
        performer_name: "",
        invoiceExternalRef: "",
        invoiceDate: "",
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        deliverymsg_time: "immediate",
        ticketData: [],
        ticketIds: [],
      },
      generateTicketModal: false,
      crossCheck: true,
      messageopen: false,
      message: "",
      messagetype: "",
      monthsShown: 2,
      expanded: false,
    };

    this.getEvents = this.getEvents.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.chooseticket = this.chooseticket.bind(this);
    this.generateModal = this.generateModal.bind(this);
    this.submitGenerateTicket = this.submitGenerateTicket.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.handleChangeAccordian = this.handleChangeAccordian.bind(this);
    this.handleCloseAllAccordian = this.handleCloseAllAccordian.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ monthsShown: 1 });
    } else if (window.innerWidth < 768) {
      this.setState({ monthsShown: 2 });
    }
    this.vendor_list();
    if (this.props.UserProfile.data !== undefined) {
      const { formData } = this.state;
      formData.limit = this.props.UserProfile.data.per_page_limit;
      this.setState({ formData: this.state.formData }, () => {
        this.getEvents(false);
      });
      if (this.props.UserProfile.data.deliverymsg_time) {
        const { generateTicketData } = this.state;
        generateTicketData.deliverymsg_time =
          this.props.UserProfile.data.deliverymsg_time;
        this.setState({ generateTicketData });
      }
    }
  }

  handleCloseAllAccordian = () => {
    this.setState({ expanded: false, openedevent: null });
  };
  handleChangeAccordian = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  vendor_list() {
    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getAllAccounts";
    axios
      .post(
        vendorList_api_url,
        { account_type: ["Vendor"] },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.tokenExpired && data.error) {
          this.setState({
            open: true,
            message: data.message,
            successError: "error",
          });
          Cookies.remove('at')
          Cookies.remove('accessToken')
          this.props.history.push("/login")
        } else if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const newdata = data.data.map((val) => {
            return {
              label: val.username,
              id: val._id,
            };
          });
          const seen = new Set();
          const filteredArr = newdata.filter((el) => {
            const duplicate = seen.has(el.label);
            seen.add(el.label);
            return !duplicate;
          });
          this.setState({
            allvendors: filteredArr,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }

  //Function to select vendor from search
  handleSelectVendor(e, val) {
    if (val) {
      this.setState({ vendorLabel: val.label, vendorVal: val.id });
      const { formData } = this.state;
      formData["searchvendor"] = val.label;
      this.setState({ formData });
    } else {
      this.setState({ vendorLabel: null, vendorVal: null });
      const { formData } = this.state;
      formData["searchvendor"] = null;
      this.setState({ formData });
    }
  }
  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.formData.limit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ loader: false, message: data.message });
          } else {
            this.props.getUserProfile();
            this.setState({
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  handleChange(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  changepageLimit(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = parseInt(evt.target.value);
    formData["skip"] = 0;
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.updateProfile();
      this.getEvents(false);
    });
  }

  setStartDate = async (dates) => {
    const { formData } = this.state;
    const [start, end] = dates;
    this.setState({ selectedendDate: end, selectedDate: start });
    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    formData["startDate"] = datestart + ".000Z";
    if (end) {
      formData["endDate"] = dateend + ".000Z";
    }
    this.setState({ formData, crossCheck: false });
  };

  clearDate = () => {
    const { formData } = this.state;
    formData["startDate"] = null;
    formData["endDate"] = null;
    this.setState({
      formData,
      crossCheck: true,
      selectedDate: null,
      selectedendDate: null,
    });
  };

  getEvents(fetchticket) {
    const event_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getUserInteractiveEvents";
    console.log("FormData", this.state.formData);
    axios
      .post(event_api_url, this.state.formData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.setState({ totalRecords: data.total });
          this.setState({ events: data.data, loader: false });
          /*if(fetchticket) {
                    var index = this.state.openedevent;
                    var event_id = data.data[index].id;
                    var event_date = data.data[index].date;
                    var account_id = data.data[index].account_id;
                    this.getTickets(index, event_id, event_date, account_id);
                }*/
        }
      });
  }

  getTickets(index, event_ids) {
    const { events, generateTicketData } = this.state;
    var openedevent = null;
    if (this.state.openedevent !== index) {
      openedevent = index;
    }
    generateTicketData.ticketData = [];
    generateTicketData.ticketIds = [];
    this.setState({ openedevent, generateTicketData });
    if (!("tickets" in events[index])) {
      var event_only_ids = event_ids.map((ev) => {
        return ev._id;
      });
      const params = { event_id: event_only_ids };

      if (this.state.formData.section !== "") {
        params.section = this.state.formData.section;
      }
      if (this.state.formData.row !== "") {
        params.row = this.state.formData.row;
      }
      if (this.state.formData.seat !== "") {
        params.seat = this.state.formData.seat;
      }
      const event_api_url =
        process.env.REACT_APP_API_URL +
        "/api/user/getUserInteractiveEventsTickets";
      axios
        .post(event_api_url, params, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;

          if (data.success) {
            events[index].tickets = data.data;
          } else {
            events[index].tickets = [];
          }
          this.setState({ events });
        });
    }
  }

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.getEvents(false);
    });
  }

  onPageChanged(data) {
    const { currentPage } = data;
    const { formData } = this.state;
    formData["skip"] = formData.limit * (currentPage - 1);
    this.setState({ formData, loader: true }, () => {
      this.getEvents(false);
    });
  }

  getBadge = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Inactive":
        return "secondary";
      case "Pending":
        return "warning";
      case "Banned":
        return "danger";
      default:
        return "primary";
    }
  };

  chooseticket = (t, evt) => {
    console.log(t);
    console.log(evt);
    const { generateTicketData } = this.state;
    var validChoice = true;
    if (generateTicketData.ticketData.length > 0) {
      if (
        generateTicketData.ticketData[0].section !== t.section ||
        generateTicketData.ticketData[0].row !== t.row
      ) {
        this.setState({
          message: "You must select ticket from same row and section",
          messageopen: true,
          messagetype: "error",
        });
        validChoice = false;
      }
    }
    if (validChoice === true) {
      if (evt.target.checked) {
        const { ticketIds, ticketData } = generateTicketData;
        generateTicketData.ticketIds = [...ticketIds, t.id];
        generateTicketData.ticketData = [...ticketData, t];
        this.setState({ generateTicketData });
      } else {
        if (generateTicketData.ticketIds.length > 0) {
          const { ticketIds, ticketData } = generateTicketData;
          var index = ticketIds.indexOf(t.id);
          ticketIds.splice(index, 1);
          ticketData.splice(index, 1);
          if (ticketIds.length > 0) {
            generateTicketData.ticketData = ticketData;
            generateTicketData.ticketIds = ticketIds;
            this.setState({ generateTicketData });
          } else {
            generateTicketData.ticketData = [];
            generateTicketData.ticketIds = [];
            this.setState({ generateTicketData });
          }
        }
      }
    }
  };

  generateModal = (val) => {
    this.setState({ generateTicketModal: val });
  };

  submitGenerateTicket = () => {
    console.log(this.state.generateTicketData);
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, this.state.generateTicketData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error) {
          this.setState({
            generateTicketMsg: "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
          });
        } else {
          var generateTicketData = {
            invoiceExternalRef: "",
            invoiceDate: "",
            customerEmail: "",
            customerPhone: "",
            ticketData: [],
            ticketIds: [],
          };
          this.setState({ loader: true }, () => {
            this.getEvents(true);
          });
          this.setState({ generateTicketData, generateTicketModal: false });
        }
      });
  };

  handleModelChange = (e) => {
    const { generateTicketData } = this.state;
    generateTicketData[e.target.name] = e.target.value;
    this.setState({ generateTicketData });
  };

  handleModelChangeSelect = (evt, val, name) => {
    console.log(evt);
    console.log(val);
    console.log(name);
    const { generateTicketData } = this.state;
    if (val) generateTicketData[name] = val.title;
    else generateTicketData[name] = null;
    this.setState({ generateTicketData });
  };

  handleClosemessage = () => {
    this.setState({ message: "", messageopen: false });
  };

  render() {
    const {
      loader,
      events,
      crossCheck,
      formData,
      totalRecords
    } = this.state;

    const fields = [
      {
        key: "email",
        label: "Email",
      },
      {
        key: "section",
        label: "Section",
      },
      {
        key: "row",
        label: "Row",
      },
      {
        key: "seat",
        label: "Seat",
      },
      {
        key: "status",
        label: "Status",
      },

      {
        key: "view",
        label: "View",
      },
    ];

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>History of Events</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <Grid container spacin={2}>
                    <Grid item xs={12}>
                      <Card sx={{ p: 1 }}>
                        <CardHeader title="History of Events" />
                        <CardContent className="plan-tbl interactive-outer">
                          <ValidatorForm
                            onSubmit={this.handleSubmit}
                            className="filter-form mb-5"
                          >
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid
                                container
                                spacing={{ xs: 2, md: 2, lg: 2 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                              >
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                  <TextField
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="event_names"
                                    placeholder="Search Event"
                                    value={formData.event_names}
                                    className="filter-input"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2.6} lg={2.6}>
                                  <DatePicker
                                    placeholderText="Search Date"
                                    selected={this.state.selectedDate}
                                    startDate={this.state.selectedDate}
                                    endDate={this.state.selectedendDate}
                                    monthsShown={this.state.monthsShown}
                                    selectsRange
                                    isClearable={true}
                                    customInput={
                                      <TextField
                                        variant="outlined"
                                        className="filter-input"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              className="date-icon"
                                            >
                                              <div className="d-flex">
                                                {crossCheck ? (
                                                  <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                  >
                                                    <Event className="text-dark" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    edge="end"
                                                    onClick={() =>
                                                      this.clearDate()
                                                    }
                                                  >
                                                    <Clear className="text-dark" />
                                                  </IconButton>
                                                )}
                                              </div>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    }
                                    onChange={(dates) =>
                                      this.setStartDate(dates)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                  <Autocomplete
                                    fullWidth
                                    className="filter-input"
                                    name="filterstatusVal"
                                    options={this.state.allvendors}
                                    onChange={this.handleSelectVendor}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                      <TextField
                                        value={this.state.vendorVal}
                                        placeholder="Vendor Email"
                                        {...params}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4} md={2} lg={2}>
                                  <TextValidator
                                    variant="outlined"
                                    className="filter-input"
                                    onChange={this.handleChange}
                                    name="section"
                                    placeholder="Search Section"
                                    value={formData.section}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2} lg={2}>
                                  <TextValidator
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    className="filter-input"
                                    name="row"
                                    placeholder="Search Row"
                                    value={formData.row}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2} lg={2}>
                                  <TextValidator
                                    className="filter-input"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="seat"
                                    placeholder="Search Seat"
                                    value={formData.seat}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4} md={2} lg={2}>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    className="filter-btn "
                                    startIcon={<HiOutlineSearch />}
                                  >
                                    Search
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </ValidatorForm>
                          {!loader ? (
                            <>
                              {
                                events.length === 0 && (
                                  <div
                                    style={{ backgroundColor: "#ccc" }}
                                    className="text-center my-5"
                                  >
                                    <h2>
                                      No items{" "}
                                      <svg
                                        width="30"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="c-icon c-icon-custom-size text-danger mb-2"
                                        role="img"
                                      >
                                        <path
                                          fill="var(--ci-primary-color, currentColor)"
                                          d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                                          className="ci-primary"
                                        ></path>
                                      </svg>
                                    </h2>
                                  </div>
                                )
                              }
                              {events.map((event, index) => (
                                <MuiAccordion
                                  key={index}
                                  TransitionProps={{ unmountOnExit: true }}
                                  expanded={this.state.expanded === `event${index}`}
                                  onChange={this.handleChangeAccordian(
                                    `event${index}`
                                  )}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{
                                      marginBottom: "4px!important",
                                    }}
                                    className="interactive-toggle"
                                    onClick={() =>
                                      this.getTickets(index, event.events)
                                    }
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      className="align-items-center"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={5}
                                        className="barinfo"
                                      >
                                        <div className="text-left ">
                                          {event.events[0].event_name}{" "}
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            ({event.events[0].account_detail?.team})
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={8}
                                        lg={7}
                                        className="barinfo"
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          justify="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            className="barinfo"
                                          >
                                            <div
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              <img
                                                src={CalendarIcon}
                                                style={{ width: "20px" }}
                                                className="table-data-calendar-icon"
                                                alt="calendor-icon"
                                              />
                                              {customDateFormat(
                                                event.events[0].date
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sm={4}
                                            md={2}
                                            lg={2}
                                            className="barinfo"
                                          >
                                            <div
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              <img
                                                src={TicketsIcon}
                                                className="table-data-ticket-icon"
                                                alt="ticket-icon"
                                              />
                                              {event.events.reduce(function (a, b) {
                                                return a + b.alltickets.length;
                                              }, 0)}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      padding: "0px!important",
                                    }}
                                  >
                                    {!event.tickets ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          padding: "2rem",
                                        }}
                                      >
                                        <CircularProgress
                                          sx={{
                                            color: "#011C58",
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <>
                                        {this.state.openedevent === index && (
                                          <div className="d-none d-md-block generate-desktop-button">
                                            {/* {generateTicketData.ticketIds
                                              .length > 0 && (
                                              <Button
                                                sx={{
                                                  float: "left",
                                                  marginRight: "5px",
                                                  marginTop: "10px!important",
                                                  position: "absolute",
                                                  top: "0px",
                                                  right: "50px",
                                                  backgroundColor:
                                                    "#b60000!important",
                                                  border:
                                                    "1px solid #b60000!important",
                                                  color: "white!important",
                                                  textTransform:
                                                    "none!important",
                                                  fontSize: " 14px!important",
                                                  fontFamily:
                                                    "Barlow!important",
                                                  "&hover": {
                                                    backgroundColor:
                                                      "#b60000!important",
                                                    border:
                                                      "1px solid #b60000!important",
                                                    color: "white!important",
                                                    textTransform:
                                                      "none!important",
                                                    fontSize: " 14px!important",
                                                    fontFamily:
                                                      "Barlow!important",
                                                  },
                                                }}
                                                onClick={() =>
                                                  this.generateModal(
                                                    true,
                                                    event
                                                  )
                                                }
                                              >
                                                Generate
                                              </Button>
                                            )} */}
                                          </div>
                                        )}

                                        {this.state.openedevent === index && (
                                          <div className="d-block d-md-none">
                                            {/* {generateTicketData.ticketIds
                                              .length > 0 && (
                                              <Button
                                                sx={{
                                                  marginRight: "5px",
                                                  marginTop: "10px!important",
                                                  marginBottom:
                                                    "10px!important",
                                                  float: "right",
                                                  backgroundColor:
                                                    "#b60000!important",
                                                  border:
                                                    "1px solid #b60000!important",
                                                  color: "white!important",
                                                  textTransform:
                                                    "none!important",
                                                  fontSize: " 14px!important",
                                                  fontFamily:
                                                    "Barlow!important",
                                                  "&hover": {
                                                    backgroundColor:
                                                      "#b60000!important",
                                                    border:
                                                      "1px solid #b60000!important",
                                                    color: "white!important",
                                                    textTransform:
                                                      "none!important",
                                                    fontSize: " 14px!important",
                                                    fontFamily:
                                                      "Barlow!important",
                                                  },
                                                }}
                                                onClick={() =>
                                                  this.generateModal(
                                                    true,
                                                    event
                                                  )
                                                }
                                              >
                                                Generate Ticket
                                              </Button>
                                            )} */}
                                          </div>
                                        )}

                                        {event.tickets.map((ticket) => {
                                          const sorteddata = ticket.tickets
                                            .sort(
                                              (a, b) =>
                                                a?.section.localeCompare(
                                                  b?.section
                                                ) ||
                                                parseInt(a?.row ?? null) - parseInt(b?.row ?? null) ||
                                                a?.row.localeCompare(b?.row ?? null) ||
                                                parseInt(a?.seat ?? null) - parseInt(b?.seat ?? null)
                                            )
                                            
                                            .map((ticket) => {
                                              return {
                                                ...ticket,
                                                status: ticket.delivery_revoked
                                                  ? "Revoked/Available"
                                                  : ticket.is_verified
                                                    ? ticket.soldinventory
                                                      ? "Sold"
                                                      : "Available"
                                                    : "Not Verified",
                                                ticketsurl:
                                                  ticket.ticketurl.length > 0 &&
                                                  ticket.ticketurl[0],
                                                vendor:
                                                  ticket?.account_detail?.vendor[0],
                                                username:
                                                  ticket?.account_detail?.username,
                                              };
                                            });

                                          //  .sort(
                                          //     (a, b) => {
                                          //       const statusOrder = {
                                          //         Available: 1,
                                          //         Sold: 3,
                                          //       };
                                          //       const aStatus =
                                          //         statusOrder[a.status] || 0;
                                          //       const bStatus =
                                          //         statusOrder[b.status] || 0;

                                          //       if (aStatus !== bStatus) {
                                          //         return aStatus - bStatus;
                                          //       } else {
                                          //         return 0;
                                          //       }
                                          //     }
                                          //   );

                                          return (
                                            <DataTable
                                              fields={fields}
                                              itemsPerPage={sorteddata?.length}
                                              items={sorteddata || []}
                                              scopedSlots={{
                                                email: (item) => item?.username ?? null,
                                                section: (item) => item?.section ?? null,
                                                row: (item) => item?.row ?? null,
                                                seat: (item) => item?.seat ?? null,
                                                status: (item) => item?.status ?? null,
                                                view: (item) =>
                                                  item.ticketsurl && (
                                                    <>
                                                      <Button
                                                        component="a"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#002986!important",
                                                          color: "white!important",
                                                          padding:
                                                            "2px 5px!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontWeight: "bold",
                                                          fontFamily:
                                                            "Barlow!important",
                                                          marginRight: "5px",
                                                        }}
                                                        href={item.ticketsurl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        View
                                                      </Button>

                                                      <Button
                                                        component="a"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#002986!important",
                                                          color: "white!important",
                                                          padding:
                                                            "2px 5px!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontWeight: "bold",
                                                          fontFamily:
                                                            "Barlow!important",
                                                        }}
                                                        href={`/sold-inventory?refId=${item.soldinventory}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        View Sale
                                                      </Button>
                                                    </>
                                                  ),
                                              }}
                                            />
                                          );
                                        })}
                                        {event.tickets.length === 0 && (
                                          <DataTable
                                            fields={fields}
                                            items={[]}
                                            scopedSlots={{
                                              email: (item) => item.username,
                                              section: (item) => item.section,
                                              row: (item) => item.row,
                                              seat: (item) => item.seat,
                                              status: (item) => item.status,
                                              view: (item) =>
                                                item.ticketsurl && (
                                                  <>
                                                    <Button
                                                      component="a"
                                                      size="small"
                                                      variant="contained"
                                                      sx={{
                                                        backgroundColor:
                                                          "#002986!important",
                                                        color: "white!important",
                                                        padding:
                                                          "2px 5px!important",
                                                        textTransform:
                                                          "none!important",
                                                        fontWeight: "bold",
                                                        fontFamily:
                                                          "Barlow!important",
                                                        marginRight: "5px",
                                                      }}
                                                      href={item.ticketsurl}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      Ticket
                                                    </Button>

                                                    <Button
                                                      component="a"
                                                      size="small"
                                                      variant="contained"
                                                      sx={{
                                                        backgroundColor:
                                                          "#002986!important",
                                                        color: "white!important",
                                                        padding:
                                                          "2px 5px!important",
                                                        textTransform:
                                                          "none!important",
                                                        fontWeight: "bold",
                                                        fontFamily:
                                                          "Barlow!important",
                                                      }}
                                                      href={`/sold-inventory?refId=${item.soldinventory}`}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      Sale
                                                    </Button>
                                                  </>
                                                ),
                                            }}
                                          />
                                        )}

                                        { }
                                      </>
                                    )}
                                  </AccordionDetails>
                                </MuiAccordion>
                              ))}
                            </>
                          ) : (
                            <ContainerLoader />
                          )}

                          {totalRecords > 0 && (
                            <Pagination
                              key="paginationnew"
                              searchPage={this.state.searchPage}
                              SearchDisable={this.SearchDisable}
                              totalRecords={totalRecords}
                              pageLimit={formData.limit}
                              onPageChanged={this.onPageChanged}
                            />
                          )}

                          {totalRecords > 0 && (
                            <div
                              style={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <label>Items per page :</label>
                              <FormControl
                                sx={{ m: 1, minWidth: 50 }}
                                size="small"
                              >
                                <Select
                                  defaultValue={formData.limit}
                                  value={formData.limit}
                                  name="limit"
                                  sx={{
                                    "& .MuiSelect-select": {
                                      padding: "4px 14px",
                                      fontFamily: "Barlow",
                                    },
                                  }}
                                  onChange={(e) => this.changepageLimit(e)}
                                >
                                  <MenuItem value="10">10</MenuItem>
                                  <MenuItem value="20">20</MenuItem>
                                  <MenuItem value="50">50</MenuItem>
                                  <MenuItem value="100">100</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </main>
              </div>
            </div>
          </div>
          <Footer />
          <AlertMessage
            open={this.state.messageopen}
            message={this.state.message}
            severity={this.state.messagetype}
            closeCall={this.handleClosemessage}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractiveInventoryPast);
