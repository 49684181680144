import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// mui components
import {
    Autocomplete,
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { HiOutlineSearch } from 'react-icons/hi';
import { indigo } from '@mui/material/colors';

// components
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import CustomDataTable from 'utils/customDataTable';
import CustomPagination from 'utils/customPagination';
import { BiTime } from 'react-icons/bi';
import { StatusChip } from 'utils/statusChip';

const columns = [
    { field: 'user', headerName: 'Users' },
    { field: 'nickname', headerName: 'Nick Name' },
    { field: 'license_key', headerName: 'License Key' },
    { field: 'status', headerName: 'Status' },
    { field: 'lastLoggedIn', headerName: 'Last Logged In' }
];

const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const BrowserSessions = () => {
    const { getBrowserSessionsAPI, getAllUsersApi } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10,
    });
    const [formData, setFormData] = useState({
        userId: null,
        status: "all"
    });
    const [isLoading, setIsLoading] = useState(false);
    const [browserSessionsList, setBrowserSessionsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const getAllBrowserSessions = async (skip = 0, limit = 10) => {
        try {
            let params = {
                skip: skip,
                limit: limit,
                status: formData?.status === "all" ? null : formData.status,
                userId: formData?.userId?._id ?? null
            };
            setIsLoading(true);
            const response = await getBrowserSessionsAPI(params)
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error");
                setIsLoading(false);
                setBrowserSessionsList([])
            } else {
                setBrowserSessionsList(data.data ?? []);
                setTotalRecords(data?.count ?? 0);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setBrowserSessionsList([])
            setIsLoading(false);
        }
    }

    const getAllBrowserAccessUsers = async () => {
        try {
            const response = await getAllUsersApi()
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error");
            } else {
                setUsersList(data?.data)
            }
        } catch (error) {
            errorMessage();
        }
    }

    const changePageLimit = (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        getAllBrowserSessions(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setPagination({
            ...pagination,
            skip: (currentPage - 1) * pagination.pageLimit
        })
        getAllBrowserSessions((currentPage - 1) * pagination.pageLimit, pagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        })
    };

    const handleSelectUser = (e, newValue) => {
        if (newValue) {
            setFormData({
                ...formData,
                userId: newValue
            })
        } else {
            setFormData({
                ...formData,
                userId: null
            })
        }
    };

    const handleChangeActive = (event) => {
        setFormData({
            ...formData,
            status: event.target.value
        })
    }

    const handleSubmitFilter = () => {
        getAllBrowserSessions(pagination?.skip, pagination?.pageLimit)
    }

    useEffect(() => {
        getAllBrowserSessions(pagination?.skip, pagination?.pageLimit)
        getAllBrowserAccessUsers();
    }, [])

    return (
        <>
            <Helmet>
                <title>Browser Sessions</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        className="instruction-box"
                        sx={{
                            width: "100%",
                            height: "100%",
                            bgcolor: "background.paper",
                            boxShadow: 1,
                            borderRadius: 1,
                            p: 2,
                            mb: 5,
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                            maxWidth: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                                <Typography
                                    variant="p"
                                    component="h4"
                                    gutterBottom
                                    sx={{
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                        color: indigo[900],
                                        textAlign: "left",
                                        mb: 2,
                                        fontFamily: "Barlow  , sans-serif!important",
                                    }}
                                >
                                    Browser Sessions
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{ pt: "5" }}
                            >
                                <ValidatorForm
                                    onSubmit={handleSubmitFilter}
                                    className="filter-form mb-5"
                                >
                                    <Box>
                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 2, lg: 2 }}
                                        >
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                                <label className="Account_details_box-manage-license">Users</label>
                                                <Autocomplete
                                                    fullWidth
                                                    id="checkboxes-tags-demo"
                                                    options={usersList ?? []}
                                                    label="Users"
                                                    disableCloseOnSelect
                                                    onChange={(e, newValue) => handleSelectUser(e, newValue)}
                                                    value={formData.userId}
                                                    getOptionLabel={(option) => option.email}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}

                                                            sx={{
                                                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                                    color: "#C4C4C4"
                                                                },
                                                            }}
                                                            variant="outlined"
                                                            label="Users"
                                                            value={formData.userId}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                                <FormControl fullWidth>
                                                    <label className="Account_details_box-manage-license">
                                                        Status
                                                    </label>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="status"
                                                        size="small"
                                                        defaultValue="all"
                                                        onChange={(e) => handleChangeActive(e)}
                                                        value={formData.status}
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        <MenuItem value="active">Active</MenuItem>
                                                        <MenuItem value="inactive">Inactive</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4} sx={{ marginTop: "31px" }}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="filter-btn"
                                                    startIcon={<HiOutlineSearch />}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ValidatorForm>
                            </Grid>
                        </Grid>
                        {/* {custom Data table} */}
                        <CustomDataTable
                            columns={columns}
                            isLoading={isLoading}
                            data={browserSessionsList.length > 0
                                ? browserSessionsList.map((item) => {
                                    return {
                                        ...item,
                                        id: item.id,
                                        user: item.userId.email ?? "-",
                                        license_key: item.licId.license_key ?? "-",
                                        nickname: item.licId?.nickname ?? "-",
                                        status: <StatusChip status={item?.isSessionActive} />,
                                        lastLoggedIn: <>
                                            <BiTime className="m-icon" />
                                            {new Intl.DateTimeFormat('en-US', options).format(new Date(item.lastLoggedIn ?? null)) ?? null}
                                        </>
                                    };
                                })
                                : []}
                        />
                        <br />
                        {browserSessionsList && browserSessionsList.length > 0 && (
                            <>
                                {totalRecords > 0 && (
                                    <CustomPagination
                                        key="paginationnew"
                                        searchPage={pagination?.searchPage}
                                        SearchDisable={SearchDisable}
                                        totalRecords={totalRecords}
                                        pageLimit={pagination?.pageLimit}
                                        onPageChanged={onPageChanged}
                                    />
                                )}
                                <div
                                    style={{
                                        textAlign: "right",
                                        display: "flex",
                                        justifyContent: "end",
                                        alignItems: "center",
                                    }}
                                >
                                    <label>Items per page :</label>
                                    <FormControl
                                        sx={{
                                            m: 1,
                                            minWidth: 50,
                                        }}
                                        size="small"
                                    >
                                        <Select
                                            defaultValue={pagination?.pageLimit}
                                            value={pagination?.pageLimit}
                                            name="limit"
                                            sx={{
                                                "& .MuiSelect-select": {
                                                    padding: "4px 34px 4px 14px!important",
                                                    fontFamily: "Barlow",
                                                },
                                            }}
                                            onChange={(e) => changePageLimit(e)}
                                        >
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="50">50</MenuItem>
                                            <MenuItem value="100">100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default BrowserSessions;
