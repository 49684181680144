import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertMessage from "../alert/alert";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
const fields = [
  {
    key: "company_name",
    label: "Company Name",
  },
  {
    key: "first_name",
    label: "First Name",
  },
  {
    key: "last_name",
    label: "Last Name",
  },
  {
    key: "phone_number",
    label: "Phone Number",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "action",
    label: "Action",
  },
];
class Contactlist extends React.Component {
  state = {
    contactData: [],
    loader: false,
    modalShow: false,
    deleteId: "",
    index: "",
  };
  async componentDidMount() {
    this.setState({
      loader: true,
    });
    const constactList_api_url =
      process.env.REACT_APP_API_URL + "/api/contact/getAll";
    await axios
      .post(constactList_api_url)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            contactData: data.data,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  onHide = () => {
    this.setState({
      modalShow: false,
    });
  };
  setModalShow = (id, indexId) => {
    this.setState({
      modalShow: true,
      deleteId: id,
      index: indexId,
    });
  };
  callDelete = async () => {
    this.setState({
      modalShow: false,
    });
    const data = {
      id: this.state.deleteId,
    };
    const contactDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/contact/delete";
    await axios
      .post(contactDelete_api_url, data)
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
        } else {
          if (data.success) {
            // Get All data
            const conatctList_api_url =
              process.env.REACT_APP_API_URL + "/api/contact/getAll";
            axios
              .post(conatctList_api_url)
              .then((response) => {
                const { data } = response;

                if (data.error) {
                  this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                  });
                } else {
                  this.setState({
                    loader: false,
                    contactData: data.data,
                    open: false,
                    message: "",
                    successError: "success",
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loader: false,
                  open: true,
                  message: "Something went wrong",
                  successError: "error",
                });
              });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  render() {
    const { contactData, loader, modalShow } = this.state;
    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Contact List</title>
          </Helmet>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Contact List" />
                  <Divider />

                  <CardContent className="plan-tbl">
                    <DataTable
                      tableFilter
                      items={contactData}
                      fields={fields}
                      itemsPerPage={10}
                      pagination
                      scopedSlots={{
                        action: (item, index) => {
                          return (
                            <div className="flex">
                              <IconButton
                                aria-label="edit"
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                                component={Link}
                                to={`/contact-management-view/${item._id}`}
                              >
                                <VisibilityIcon />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  this.setModalShow(item._id, index)
                                }
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          );
                        },
                      }}
                    />

                    <Dialog
                      fullWidth={true}
                      scroll="body"
                      maxWidth="sm"
                      open={modalShow}
                      onClose={this.onHide}
                    >
                      <DialogTitle>Delete</DialogTitle>
                      <DialogContent>
                        <IconButton
                          aria-label="close"
                          onClick={() => this.onHide()}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <DialogContentText>
                          Are you sure you want to Delete, this action can not
                          be revert ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.onHide}
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.callDelete}
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                          }}
                        >
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </CardContent>
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </Card>
              </Grid>
            </Grid>
          </div>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default Contactlist;
