import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
class PasswordField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showpassword: false
        }

        this.changeVisibility = this.changeVisibility.bind(this)
    }

    changeVisibility (val) {
       
            this.setState({showpassword: !this.state.showpassword})
      
    }
    
    render() {
        const { props } = this
        const { showpassword } = this.state

        return (
        <div>
            
            <TextValidator 
                type={showpassword ? "text" : "password"}
                {...props} 
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.changeVisibility}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showpassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                  }}

            />

           
        </div>
        )
    }
}
 
export default PasswordField;