import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import customDateFormatLocal from "utils/customDateFormatLocal";

import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  Autocomplete,
  Chip,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { HiOutlineSearch } from "react-icons/hi";
import Pagination from "components/pagination/pagination";
import DataTable from "components/DataTable/DataTable";
import { changeDollarAmountformat } from "utils/changeDollarFormat";
import { ContainerLoader } from "components/common/ContainerLoader";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import ReceiptPDFGenerator from "utils/receipt-pdf";

const moment = extendMoment(originalMoment);

class Historyofpayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      loader: true,
      open: false,
      message: "",
      allUsers: [],
      disableValue: "All",
      crossCheck: true,
      successError: "success",
      selectedDate: null,
      selectedendDate: null,
      filterDate: {
        keyword: "",
        fromDate: null,
        toDate: null,
      },
      receipt: {},
      dateErrorMsg: "",
      pageLimit: 10,
      modalShow: false,
      pos: [],
      searchPage: 0,
      skip: 0,
      totalRecords: 0,
      testUser: "all",
      status: "",
      userVal: [
        {
          email: "All",
        },
      ],
    };

    this.changePageLimit = this.changePageLimit.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSelectStatus = this.handleSelectStatus.bind(this);
  }

  async componentDidMount() {
    this.userList();
    this.getData();
  }

  handleSelectStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  handleChangeTestUser = (event) => {
    this.setState({ testUser: event.target.value });
  }

  handleSelectUser = (event, value) => {
    if (value) {
      if (value.length === 0) {
        this.setState({ disableValue: "", userVal: [] });
      }
      value.map((item) => {
        if (item.email === "All") {
          this.setState({
            disableValue: "All",
            userVal: [
              {
                email: "All",
              },
            ],
          });
        } else {
          this.setState({
            userVal: value,
          });
        }
        return item;
      });
    }
  };

  onPageChanged = (data) => {
    const { currentPage } = data;
    this.setState(
      { loader: true, skip: this.state.pageLimit * (currentPage - 1) },
      () => {
        this.getData();
      }
    );
  };

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  getData = () => {
    const data = {
      ...this.state.filterDate,
      skip: this.state.skip,
      limit: this.state.pageLimit,
      users: this.state.userVal[0].email === "All" ? [] : this.state.userVal.map((item) => {
        return item._id
      }),
      hasTestUsers: this.state.testUser === "yes" ? true : this.state.testUser === "all" ? null : false,
      status: this.state.status === "all" ? null : this.state.status
    };
    const historyPayment_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/historyOfPayments";

    axios
      .post(historyPayment_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            usersData: data.data,
            totalRecords: data.total,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ loader: false });
      });
  };

  userList() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(
        userList_api_url,
        {},

        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {

          const sorted = data?.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });
          // push to first index of array

          sorted.unshift({
            email: "All",
          });

          this.setState({
            allUsers: sorted,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }

  changePageLimit = (evt) => {
    this.setState(
      { loader: true, searchPage: 1, pageLimit: parseInt(evt.target.value) },
      () => {
        this.getData();
      }
    );
  };

  onHandleChange = (e) => {
    const { filterDate } = this.state;
    filterDate[e.target.name] = e.target.value;
    this.setState({ filterDate });
  };

  setStartDate = async (dates) => {
    const { filterDate } = this.state;
    const [start, end] = dates;

    // const getstart = start.valueOf()+hours+mins
    this.setState({ selectedendDate: end, selectedDate: start });

    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    filterDate["fromDate"] = datestart + ".000Z";
    if (end) {
      filterDate["toDate"] = dateend + ".000Z";
    }

    this.setState({ filterDate, crossCheck: false });
  };

  clearDate = () => {
    const { filterDate } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    filterDate["fromDate"] = null;
    filterDate["toDate"] = null;
    this.setState({ filterDate, crossCheck: true });
  };


  handleSubmit = () => {
    this.setState({ loader: true, searchPage: 1, skip: 0 }, () => {
      this.getData();
    });
  };

  onHide = () => {
    this.setState({ modalShow: false });
  };

  render() {
    const { usersData, crossCheck, loader } = this.state;
    // get time zone  from the browser
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fields = [
      {
        key: "user",
        label: "User",
      },
      {
        key: "heading",
        label: "Description",
      },
      {
        key: "indicator",
        label: "-",
      },
      {
        key: "date",
        label: "Date",
      },
      {
        key: "credit",
        label: "Credit",
      },
      {
        key: "balance",
        label: "Balance",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "receipt",
        label: "Receipt",
      },
    ];

    return (
      <>
        <Helmet>
          <title>History Of Payment</title>
        </Helmet>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="History of Payment" />
              <CardContent className="plan-tbl">
                <ValidatorForm
                  onSubmit={this.handleSubmit}
                  className="filter-form mb-5"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 2, lg: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      <Grid
                        item
                        className="team"
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                      >
                        <label className="Account_details_box-manage-license">
                          Search Users
                        </label>
                        <Autocomplete
                          multiple
                          fullWidth
                          id="checkboxes-tags-demo"
                          options={this.state.allUsers ?? []}
                          disableCloseOnSelect
                          onChange={this.handleSelectUser}
                          value={this.state.userVal ?? null}
                          isOptionEqualToValue={(option, value) =>
                            option.email === value.email
                          }
                          getOptionLabel={(option) => option.email}
                          renderOption={(props, option, { selected }) => (
                            <List
                              disablePadding
                              {...props}
                              sx={{
                                pointerEvents:
                                  this.state.disableValue === "All" &&
                                    option.email !== "All"
                                    ? "none"
                                    : "auto",
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              <ListItem
                                disablePadding
                                disabled={
                                  this.state.disableValue === "All" &&
                                    option.email !== "All"
                                    ? true
                                    : false
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    icon={
                                      <CheckBoxOutlineBlank fontSize="medium" />
                                    }
                                    checkedIcon={
                                      <CheckBox fontSize="medium" />
                                    }
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={option.email} />
                              </ListItem>
                            </List>
                          )}
                          renderInput={(params) => (
                            <TextValidator
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                              {...params}

                              sx={{
                                "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                  color: "#C4C4C4"
                                },
                              }}
                              variant="outlined"
                              value={this.state.userVal}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <label className="Account_details_box-manage-license">
                          Search Date
                        </label>
                        <DatePicker
                          placeholderText="Search Date"
                          selected={this.state.selectedDate}
                          startDate={this.state.selectedDate}
                          endDate={this.state.selectedendDate}
                          monthsShown={this.state.monthsShown}
                          selectsRange
                          isClearable={true}
                          customInput={
                            <TextField
                              variant="outlined"
                              className="filter-input"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="date-icon"
                                  >
                                    <div className="d-flex">
                                      {crossCheck ? (
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                        >
                                          <EventIcon className="text-dark" />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          edge="end"
                                          onClick={() => this.clearDate()}
                                        >
                                          <ClearIcon className="text-dark" />
                                        </IconButton>
                                      )}
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                          onChange={(dates) => this.setStartDate(dates)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <label className="Account_details_box-manage-license">
                          Status
                        </label>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {this.state.status !== "" ? "" : "Status"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            onChange={(e) => {
                              this.handleSelectStatus(e);
                            }}
                            size="small"
                            value={this.state.status}
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="manually_removed"> Manually Removed By Admin </MenuItem>
                            <MenuItem value="manually_added">Manually Added by Admin</MenuItem>
                            <MenuItem value="purchased_via_stripe">Credit Purchased via Stripe</MenuItem>
                            <MenuItem value="package_purchased">Package Purchased</MenuItem>
                            <MenuItem value="auto_via_stripe">Auto Added via Stripe</MenuItem>
                            <MenuItem value="nt_tickets_charge">Non-Transferable Tickets charges</MenuItem>
                            <MenuItem value="anonymous_browser_charges">Charges for Browser Subscription </MenuItem>
                            <MenuItem value="lump_sum_charge_nttickets">Lump sum charge for Non-Transferable Tickets</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                       {/* for test user */}
                       <Grid item xs={12} sm={2.5} md={2.5} lg={2.5}>
                        <FormControl fullWidth>
                          <label className="Account_details_box-manage-license">
                            Test Users
                          </label>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            value={this.state.testUser}
                            onChange={(e) => this.handleChangeTestUser(e)}
                            size="small"
                            defaultValue="all"
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} md={2.2} lg={2.2} sx={{ marginTop: "47px!important", paddingTop: "0px!important" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="filter-btn "
                          startIcon={<HiOutlineSearch />}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </ValidatorForm>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <>
                    <DataTable
                      fields={fields}
                      items={usersData}
                      itemsPerPage={this.state.pageLimit}
                      scopedSlots={{
                        user: (item) => <>{item.user && item.user[0].email}</>,
                        date: (item) => (
                          <>{customDateFormatLocal(item.created, timezone)}</>
                        ),
                        credit: (item) => (
                          <>
                            {item.entry_type === "debit" ? "-" : ""}
                            {changeDollarAmountformat(Number(item.amount).toFixed(2))}
                          </>
                        ),
                        indicator: (item) => (
                          <>
                            {item?.isPackagePurchased ?
                              <Chip
                                label="Subscribed"
                                color="success"
                                size="small"
                                sx={{
                                  color: "#fff",
                                }}
                              />
                              :
                              null
                            }
                          </>
                        ),
                        heading: (item) => (
                          <>
                            <div className="container-tablecell">
                              {item?.heading?.length > 35 ? (
                                <section>
                                  <div className="outer-wrapper-payment">
                                    <div className="inner-wrapper">
                                      {item?.heading ?? "-"}
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                <>
                                  {item?.heading ?? "-"}
                                </>
                              )}
                            </div>
                          </>
                        ),
                        balance: (item) => (
                          <>{changeDollarAmountformat(Number(item.balance).toFixed(2))}</>
                        ),
                        status: (item) => (
                          <>
                            <Chip
                              label="Completed"
                              color="success"
                              size="small"
                              sx={{
                                color: "#fff",
                              }}
                            />
                          </>
                        ),
                        receipt: (item) => (
                          <>
                            <ReceiptPDFGenerator item_data={item} />
                          </>
                        ),
                      }}
                    />
                  </>
                )}

                <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {this.state.totalRecords > 0 && (
                      <Pagination
                        key="paginationnew"
                        searchPage={this.state.searchPage}
                        SearchDisable={this.SearchDisable}
                        totalRecords={this.state.totalRecords}
                        pageLimit={this.state.pageLimit}
                        onPageChanged={this.onPageChanged}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <label>Items per page :</label>
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 50,
                        }}
                        size="small"
                      >
                        <Select
                          defaultValue={this.state.pageLimit}
                          value={this.state.pageLimit}
                          name="limit"
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "4px 14px",
                              fontFamily: "Barlow",
                            },
                          }}
                          onChange={(e) => this.changePageLimit(e)}
                        >
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Historyofpayment;
