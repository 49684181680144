
const Api_Base = process.env.REACT_APP_API_URL;

const Api_List = {

    ScanVendorEvent : Api_Base + '/api/account/scanVendorEvent',
    RefreshVendorEvent : Api_Base + '/api/account/refreshVendorEvent',
    UserDetais : Api_Base + '/api/user/userDetail',
    UserProfile : Api_Base + '/api/user/profile',
    UserLogin : Api_Base + '/api/auth/signin',
}
export default Api_List;