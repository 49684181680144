





// npm packgaes
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// mui  component
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    tableCellClasses
} from '@mui/material';
import styled from '@emotion/styled';

// mui  icons component
import { Add, Delete, Edit } from '@mui/icons-material';

// Component
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import Pagination from 'components/pagination/pagination';
import AlertMessage from 'components/message/message';
import { ContainerLoader } from 'components/common/ContainerLoader';
import AddPackage from './AddPackage';
import UpdatePackage from './UpdatePackage';
import ConfirmationModal from 'utils/confirmationModal';
import { changeDollarAmountformat } from 'utils/changeDollarFormat';

// css for mui  table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const actionbutton = {
    backgroundColor: "#d6d7db !important",
    color: "#3A4E69!important",
    textTransform: "none!important",
    fontFamily: "Barlow!important",
    padding: "8px",
    border: "0.4px solid #d6d7db ",
    margin: "0 5px",
};

const SwitchCustom = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const PackageManage = () => {
    const {
        getAllPackagesAPI,
        getSinglePakagesAPI,
        deletePackageAPI
    } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [packageList, setPackageList] = useState([]);
    const [isVisiableAddModal, setIsVisiableAddModal] = useState(false);
    const [isVisibleDeleteModel, setIsVisibleDeleteModel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [packageId, setPackageId] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0)
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    });
    const [packageEditData, setPackageEditData] = useState({})

    const handleIsVisibleAddModal = () => {
        setIsVisiableAddModal(true);
        setIsEdit(false);
        setPackageId(null)
        setPackageEditData({})
    }

    const handleCloseAddModal = () => {
        setIsVisiableAddModal(false);
        setIsEdit(false);
    }

    const handleOpenDeleteConfirModal = (id) => {
        setIsVisibleDeleteModel(true);
        setPackageId(id)
    }

    const handleCloseDeleteConfirmModel = () => {
        setIsVisibleDeleteModel(false);
        setPackageId(null)
    }


    const changePageLimit = (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        getAllPackage(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setPagination({
            ...pagination,
            skip: (currentPage - 1) * pagination.pageLimit
        })
        getAllPackage((currentPage - 1) * pagination.pageLimit, pagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        })
    };

    const handleCapitalize = useCallback((str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }, []);

    const getAllPackage = async (pageskip = 0, limitpage) => {
        try {
            setIsLoading(true);
            const response = await getAllPackagesAPI({
                limit: limitpage ?? 10,
                skip: pageskip ?? 0,
            });
            const { data } = response
            if (data.error) {
                setIsLoading(false);
                errorMessage(data?.message, "error");
            } else {
                setPackageList(data?.data)
                setTotalRecords(data?.count)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const getSinglePakages = async (id) => {
        setIsEdit(true)
        setPackageId(id)
        try {
            setIsLoading(true);
            const response = await getSinglePakagesAPI({ id: id });
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                errorMessage(data?.message, "error");
                setIsEdit(false)
            } else {
                setPackageEditData(data?.data)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
            setIsEdit(false)
        }
    }

    const hnadleDeletePackage = async () => {
        try {
            setIsVisibleDeleteModel(false);
            const response = await deletePackageAPI({ id: packageId });
            const { data } = response
            if (data.error) {
                errorMessage(data?.message, "error");
            } else {
                getAllPackage(pagination?.skip, pagination?.pageLimit)
                errorMessage(data?.message, "success");
                setPackageId(null);
            }
        } catch (error) {
            errorMessage();
        }
    }

    useEffect(() => {
        getAllPackage(pagination?.skip, pagination?.pageLimit)
    }, [])

    return (
        <>
            <Helmet>
                <title>Manage Packages</title>
            </Helmet>
            {!isEdit ? (
                <>
                    {!isVisiableAddModal ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader
                                        title="Manage Packages"
                                        action={
                                            <Grid
                                                container
                                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                                columns={{ xs: 4, sm: 8, md: 8 }}
                                                className="button-align"
                                            >
                                                <Grid item>
                                                    <Button
                                                        disabled={isLoading}
                                                        type="button"
                                                        size="large"
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: "#002986!important",
                                                            color: "white!important",
                                                            textTransform: "none!important",
                                                            fontWeight: "bold",
                                                            fontFamily: "Barlow!important",
                                                        }}
                                                        startIcon={<Add />}
                                                        onClick={(e) => handleIsVisibleAddModal(e)}
                                                    >
                                                        Add Package
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                    <CardContent className="plan-tbl">
                                        {!isLoading ? (
                                            <>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="left">Package Name</StyledTableCell>
                                                                <StyledTableCell align="left">Price</StyledTableCell>
                                                                <StyledTableCell align="left">Tickets Count</StyledTableCell>
                                                                <StyledTableCell align="left">Additional Ticket Price</StyledTableCell>
                                                                <StyledTableCell align="left">Order</StyledTableCell>
                                                                <StyledTableCell align="left">Description</StyledTableCell>
                                                                <StyledTableCell align="left">Actions</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {packageList && packageList?.length > 0 ? (
                                                                <>
                                                                    {packageList && packageList?.map((item, index) => (
                                                                        <StyledTableRow >
                                                                            <StyledTableCell align="left">
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        minWidth: "max-content",
                                                                                    }}
                                                                                >
                                                                                    {item?.name ?? "-"}
                                                                                </div>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left">
                                                                                {changeDollarAmountformat(item?.price) ?? "-"}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left">
                                                                                {item?.tickets_count ?? "-"}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left">
                                                                                {changeDollarAmountformat(item?.additional_ticket_price) ?? "-"}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left">
                                                                                {item.order ?? "-"}
                                                                            </StyledTableCell>

                                                                            <StyledTableCell align="left">
                                                                                {item?.description?.slice(0, 30) ?? "-"}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left" >
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        minWidth: "max-content",
                                                                                    }}
                                                                                >
                                                                                    <Tooltip title="Edit">
                                                                                        <IconButton
                                                                                            onClick={() => getSinglePakages(item._id)}
                                                                                            sx={actionbutton}
                                                                                            aria-label="edit"
                                                                                            size="small">
                                                                                            <Edit fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Delete">
                                                                                        <IconButton
                                                                                            onClick={() => handleOpenDeleteConfirModal(item._id)}
                                                                                            sx={actionbutton}
                                                                                            aria-label="edit"
                                                                                            size="small">
                                                                                            <Delete fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <StyledTableRow>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        colSpan={5}
                                                                        align="center"
                                                                    >
                                                                        No Data Found
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        ) : (
                                            <ContainerLoader />
                                        )}
                                        <br />
                                        {packageList && packageList.length > 0 && (
                                            <>
                                                {totalRecords > 0 && (
                                                    <Pagination
                                                        key="paginationnew"
                                                        searchPage={pagination?.searchPage}
                                                        SearchDisable={SearchDisable}
                                                        totalRecords={totalRecords}
                                                        pageLimit={pagination?.pageLimit}
                                                        onPageChanged={onPageChanged}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        textAlign: "right",
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <label>Items per page :</label>
                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 50,
                                                        }}
                                                        size="small"
                                                    >
                                                        <Select
                                                            defaultValue={pagination?.pageLimit}
                                                            value={pagination?.pageLimit}
                                                            name="limit"
                                                            sx={{
                                                                "& .MuiSelect-select": {
                                                                    padding: "4px 34px 4px 14px!important",
                                                                    fontFamily: "Barlow",
                                                                },
                                                            }}
                                                            onChange={(e) => changePageLimit(e)}
                                                        >
                                                            <MenuItem value="10">10</MenuItem>
                                                            <MenuItem value="20">20</MenuItem>
                                                            <MenuItem value="50">50</MenuItem>
                                                            <MenuItem value="100">100</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : (
                        <AddPackage
                            packageId={packageId}
                            handleCloseAddModal={handleCloseAddModal}
                            getAllPackage={getAllPackage}
                            pagination={pagination}
                            errorMessage={errorMessage}
                        />
                    )}
                </>) : (
                <UpdatePackage
                    packageId={packageId}
                    handleCloseAddModal={handleCloseAddModal}
                    getAllPackage={getAllPackage}
                    packageEditData={packageEditData}
                    setPackageId={setPackageId}
                    isLoading={isLoading}
                    pagination={pagination}
                    errorMessage={errorMessage}
                />
            )}
            {/* {Delete package Confirmation Model} */}
            <ConfirmationModal
                handleAPIRequest={hnadleDeletePackage}
                isOpen={isVisibleDeleteModel}
                handleClose={handleCloseDeleteConfirmModel}
                message={"Are you sure you want to Delete Package?"}
                title={"Delete Package"}
                buttonText={"Yes"}
                buttonText2={"No"}
            />
            {/* alert  message  */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default PackageManage;
