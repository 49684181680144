import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// mui icons
import { Add, ArrowBack, Cancel, Delete, Edit } from '@mui/icons-material'

// mui  components
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    tableCellClasses
} from '@mui/material'
import { styled } from '@mui/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

//  components
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import { ContainerLoader } from 'components/common/ContainerLoader';
import AlertMessage from 'components/message/message';
import DeleteConfirmationModal from 'utils/deleteConfirmationModal';
import Pagination from 'components/pagination/pagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
        padding: "10px 10px !important"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const actionbutton = {
    backgroundColor: "#d9dadd!important",
    color: "#002986!important",
    textTransform: "none!important",
    fontFamily: "Barlow!important",
    margin: "0 5px",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
};

const CompanyCategory = ({ setIsCategory }) => {
    const {
        getAllAffiliateCompanyCategoryAPI,
        deleteAffiliateCompanyCategoryAPI,
        addAffiliateCompanyCategoryAPI,
        getSingleAffiliateCompanyCategoryAPI,
        updateAffiliateCompanyCategoryAPI
    } = useAdminAPICollection()
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [catData, setCatData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenAddCategoryModal, setIsOpenAddCategoryModal] = useState(false)
    const [category, setCategory] = useState("");
    const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false)
    const [deleteId, setDeleteId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [searchPage, setSearchPage] = React.useState(0);
    const [skip, setSkip] = React.useState(0);

    const handleCloseDeleteConfirmation = () => {
        setIsOpenDeleteConfirmation(false);
        setDeleteId(null);
    }

    const getAllAffiliateCompanyCategory = async (pageskip = 0, limitpage = 10) => {
        try {
            setIsLoading(true);
            const res = await getAllAffiliateCompanyCategoryAPI({
                skip: pageskip,
                limit: limitpage,
            });
            const { data } = res;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setTotalRecords(data?.count);
                setCatData(data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const handleAddCategory = async () => {
        try {
            setIsOpenAddCategoryModal(false);
            const res = await addAffiliateCompanyCategoryAPI({ category });
            const { data } = res;
            if (data.error) {
                errorMessage(data?.message ?? "", "error");
            } else {
                errorMessage(data.message, "success");
                await getAllAffiliateCompanyCategory(skip, pageLimit);
                setCategory("");
            }
        } catch (error) {
            setIsOpenAddCategoryModal(true);
            errorMessage(error.response.data.message ?? "", "error");
        }
    }

    const handleDeleteCategory = async () => {
        try {
            setIsOpenDeleteConfirmation(false);
            const res = await deleteAffiliateCompanyCategoryAPI({ id: deleteId });
            const { data } = res;
            if (data.error) {
                errorMessage(data?.message ?? "", "error");
                setDeleteId(null);
            } else {
                errorMessage(data.message, "success");
                await getAllAffiliateCompanyCategory(skip, pageLimit);
                setDeleteId(null);
            }
        } catch (error) {
            errorMessage(error.response.data.message ?? "", "error");
            setDeleteId(null);
        }
    }

    const handleUpdateCategory = async () => {
        try {
            const res = await updateAffiliateCompanyCategoryAPI({ category, id: editId });
            const { data } = res;
            if (data.error) {
                errorMessage(data?.message ?? "", "error");
            } else {
                errorMessage(data.message, "success");
                setIsOpenAddCategoryModal(false);
                await getAllAffiliateCompanyCategory(skip, pageLimit);
                setCategory("");
                setIsEdit(false);
                setEditId(null);
            }
        } catch (error) {
            setIsOpenAddCategoryModal(true);
            errorMessage(error.response.data.message ?? "", "error");
        }

    }

    const handleGetSingleCategory = async (e, id) => {
        setIsEdit(true);
        setEditId(id);
        try {
            const res = await getSingleAffiliateCompanyCategoryAPI({ id: id });
            const { data } = res;
            if (data.error) {
                errorMessage();
            } else {
                setCategory(data?.data?.category)
                setIsOpenAddCategoryModal(true)
            }
        } catch (error) {
            errorMessage();
        }
    }


    const changePageLimit = (e) => {
        setSearchPage(1);
        setPageLimit(parseInt(e.target.value));
        getAllAffiliateCompanyCategory(0, parseInt(e.target.value));
        setSearchPage(1);
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setSkip((currentPage - 1) * pageLimit);
        getAllAffiliateCompanyCategory((currentPage - 1) * pageLimit, pageLimit);
    };

    const SearchDisable = (value) => {
        setSearchPage(value);
    };

    useEffect(() => {
        getAllAffiliateCompanyCategory(skip, pageLimit);
    }, [])

    return (
        <>
            <Card>
                <CardHeader
                    title="Company Category"
                    action={
                        <>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                    paddingX: "1rem",
                                    marginRight: "0.8rem",
                                }}
                                startIcon={<Add />}
                                onClick={() => setIsOpenAddCategoryModal(true)}
                            >
                                Add Category
                            </Button>
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    textTransform: "none!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow!important",
                                    paddingX: "1rem",
                                    marginRight: "0.8rem",
                                }}
                                startIcon={<ArrowBack />}
                                onClick={() => setIsCategory(false)}
                            >
                                Back
                            </Button>
                        </>
                    }
                />
                <CardContent>
                    {isLoading ?
                        <ContainerLoader />
                        :
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    minWidth: "max-content",
                                                }}
                                            >
                                                Category
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    minWidth: "max-content",
                                                }}
                                            >
                                                CreatedAt
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    minWidth: "max-content",
                                                }}
                                            >
                                                Action
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {catData?.length > 0 ? catData && catData.map((item, index) => {
                                        const options = {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        };
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                            padding: "11px"
                                                        }}
                                                    >
                                                        {item?.category}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                            padding: "11px"
                                                        }}
                                                    >
                                                        {new Intl.DateTimeFormat('en-US', options).format(new Date(item?.createdAt ?? null)) ?? null}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                        }}
                                                    >
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                sx={actionbutton}
                                                                onClick={(e) => handleGetSingleCategory(e, item?._id)}
                                                                aria-label="edit"
                                                                size="small">
                                                                <Edit fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                sx={actionbutton}
                                                                onClick={(e) => {
                                                                    setIsOpenDeleteConfirmation(true)
                                                                    setDeleteId(item?._id)
                                                                }}
                                                                aria-label="delete"
                                                                size="small">
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : (
                                        <StyledTableRow>
                                            <td
                                                colSpan={13}
                                                style={{ padding: "2.6rem 1rem" }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontSize: "1.6rem",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Data not Found
                                                </Typography>
                                            </td>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <br />
                    {catData && catData.length > 0 && (
                        <>
                            {totalRecords > 0 && (
                                <Pagination
                                    key="paginationnew"
                                    searchPage={searchPage}
                                    SearchDisable={SearchDisable}
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    onPageChanged={onPageChanged}
                                />
                            )}
                            <div
                                style={{
                                    textAlign: "right",
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <label>Items per page :</label>
                                <FormControl
                                    sx={{
                                        m: 1,
                                        minWidth: 50,
                                    }}
                                    size="small"
                                >
                                    <Select
                                        defaultValue={pageLimit}
                                        value={pageLimit}
                                        name="limit"
                                        sx={{
                                            "& .MuiSelect-select": {
                                                padding: "4px 34px 4px 14px!important",
                                                fontFamily: "Barlow",
                                            },
                                        }}
                                        onChange={(e) => changePageLimit(e)}
                                    >
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                        <MenuItem value="50">50</MenuItem>
                                        <MenuItem value="100">100</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
            {/* add  category modal */}
            <Dialog
                maxWidth="sm"
                open={isOpenAddCategoryModal}
                onClose={(e) => setIsOpenAddCategoryModal(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "3px 24px!important" }}>{isEdit ? "Update Category" : "Add Category"}</DialogTitle>
                <ValidatorForm
                    className="filter-form1"
                    onSubmit={() => isEdit ? handleUpdateCategory() : handleAddCategory()}
                >
                    <DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={(e) => setIsOpenAddCategoryModal(false)}
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                            }}
                        >
                            <Cancel />
                        </IconButton>
                        <DialogContentText
                            sx={{
                                fontFamily: "Barlow",
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "black",
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <label>Company Category</label>
                                    <TextValidator
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={category}
                                        type="text"
                                        onChange={(e) => setCategory(e.target.value)}
                                        name="companyName"
                                        validators={["required"]}
                                        errorMessages={[
                                            "this field is required"
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type='submit'
                            sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                                marginRight: "14px"
                            }}
                        >
                            {isEdit ? "Update" : "Submit"}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            {/* delete confimation modal */}
            <DeleteConfirmationModal
                handleDelete={handleDeleteCategory}
                isOpen={isOpenDeleteConfirmation}
                handleClose={handleCloseDeleteConfirmation}
                text={"Are you sure you want to delete company category?"}
            />
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

DeleteConfirmationModal.propTypes = {
    setIsCategory: PropTypes.func.isRequired,
};

DeleteConfirmationModal.defaultProps = {
    setIsCategory: () => { },
};

export default CompanyCategory;
