// npm packgaes 
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';

// mui  component
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    tableCellClasses
} from '@mui/material';
import styled from '@emotion/styled';

// mui  icons component
import { Add, ArrowBack, Cancel, Delete, Edit } from '@mui/icons-material';
import Historylog from '../../../images/historyicon.png'

// Component
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import { ContainerLoader } from 'components/common/ContainerLoader';
import AlertMessage from 'components/message/message';
import loaderGif from "images/GIF-Loader-3.gif";
import { ValidatorForm } from 'react-material-ui-form-validator';
import ConfirmationModal from 'utils/confirmationModal';
import Pagination from 'components/pagination/pagination';
import customDateFormatLocal from 'utils/customDateFormatLocal';

// css for mui  table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableRows = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCells = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary["dark"],
        color: theme.palette.common.white,
        fontFamily: "Barlow !important",
        fontWeight: "bold",
        textAlign: "left",
        position: "relative",
        // width: "100%",
        minWidth: "max-content",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        fontFamily: "Barlow !important",
        textAlign: "left",
        color: "#3C4E6A",
        borderBottom: "1px solid #CCCCCC",
        width: "auto",
        padding: "12px 15px",
    },
}));

const actionbutton = {
    backgroundColor: "#d6d7db !important",
    color: "#3A4E69!important",
    textTransform: "none!important",
    fontFamily: "Barlow!important",
    padding: "8px",
    border: "0.4px solid #d6d7db ",
    margin: "0 5px",
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EndUserLicenseAgreement = () => {
    const {
        getAllEulaAPI,
        addEulaAPI,
        updateEulaAPI,
        getSingleEulaAPI,
        getLatestEulaAPI,
        getEulaLogsAPI
    } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [eulaContent, setEelaContent] = useState([]);
    const [isVisiableAddModal, setIsVisiableAddModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDisableButton, setIsDisableButton] = useState(false);
    const [eulaId, setEulaId] = useState(null);
    const [fromData, setFormData] = useState({
        text: ""
    });
    const [totalRecords, setTotalRecords] = useState(0)
    const [eulaPagination, setEulaPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    })
    //logs states
    const [isVisiableLogsModal, setIsVisiableLogsModal] = useState(false);
    const [logsStates, setLogsStates] = useState({
        isLoading: false,
        logsLists: [],
        isVisiableLogsModal: false,
        logId: null
    });
    const [totalRecordslogs, setTotalRecordsLogs] = useState(0)
    const [logPagination, setLogPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10
    });

    const handleIsVisibleAddModal = () => {
        getLatestEula()
        setIsVisiableAddModal(true);
    }

    const handleCloseAddModal = () => {
        setIsVisiableAddModal(false);
        setIsEdit(false);
    }

    const handleCloseLogsModal = () => {
        setIsVisiableLogsModal(false);
    }

    const SearchDisableLog = (value) => {
        setLogPagination({
            ...logPagination,
            searchPage: value,
        });
    };

    const onPageChangedLog = (data) => {
        const { currentPage } = data;
        setLogPagination({
            ...logPagination,
            skip: (currentPage - 1) * logPagination?.pageLimit
        });
        getEulaLogs((currentPage - 1) * logPagination.pageLimit, logPagination.pageLimit, logsStates.logId);
    };

    const changePageLimitLog = (e) => {
        setLogPagination({
            ...logPagination,
            searchPage: 1,
        });
        getEulaLogs(0, parseInt(e.target.value), logsStates.logId);
    };

    const changePageLimit = (e) => {
        setEulaPagination({
            ...eulaPagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        })
        getAllEULA(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setEulaPagination({
            ...eulaPagination,
            skip: (currentPage - 1) * eulaPagination.pageLimit
        })
        getAllEULA((currentPage - 1) * eulaPagination.pageLimit, eulaPagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setEulaPagination({
            ...eulaPagination,
            searchPage: value,
        })
    };

    const getAllEULA = async (pageskip = 0, limitpage) => {
        try {
            setIsLoading(true);
            const response = await getAllEulaAPI({
                limit: limitpage ?? 10,
                skip: pageskip ?? 0,
            });
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                errorMessage();
            } else {
                setEelaContent(data?.data && data?.data)
                setTotalRecords(data?.count)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const getSingleEULA = async (id) => {
        setIsEdit(true);
        setIsVisiableAddModal(true);
        setEulaId(id);
        try {
            setIsLoading(true);
            const response = await getSingleEulaAPI({ id });
            const { data } = response;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setFormData({
                    ...fromData,
                    text: data?.data?.text
                });
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const getLatestEula = async () => {
        try {
            setIsLoading(true);
            const response = await getLatestEulaAPI();
            const { data } = response;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setFormData({
                    ...fromData,
                    text: data?.data?.text
                });
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const handleAddEULA = async () => {
        setIsDisableButton(true);
        try {
            const response = await addEulaAPI({ text: fromData?.text });
            const { data } = response;
            if (data.error) {
                errorMessage(data.message, "error");
                setIsDisableButton(false);
            } else {
                errorMessage(data.message, "success");
                await getAllEULA(eulaPagination?.skip, eulaPagination?.pageLimit);
                setIsDisableButton(false);
                setIsVisiableAddModal(false);
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message, "error");
            setIsDisableButton(false);
        }
    }

    const handleUpdateEULA = async () => {
        setIsDisableButton(true);
        try {
            const response = await updateEulaAPI({ id: eulaId, text: fromData?.text });
            const { data } = response;
            if (data.error) {
                errorMessage();
                setIsDisableButton(false);
            } else {
                await getAllEULA(eulaPagination?.skip, eulaPagination?.pageLimit);
                setIsDisableButton(false);
                setIsVisiableAddModal(false);
                setIsEdit(false);
                setEulaId(null);
                errorMessage(data.message, "success");
            }
        } catch (error) {
            errorMessage();
            setIsDisableButton(false);
        }
    }

    const getEulaLogs = async (pageskip = 0, limitpage = 10, id) => {
        setIsVisiableLogsModal(true);
        setLogsStates({
            ...logsStates,
            isLoading: true,
            logId: id,
        })
        try {
            const response = await getEulaLogsAPI({
                limit: limitpage,
                skip: pageskip,
                eulaId: id
            });
            const { data } = response;
            if (data.error) {
                errorMessage();
                setLogsStates({
                    ...logsStates,
                    isLoading: false,
                })
            } else {
                setLogsStates({
                    ...logsStates,
                    isLoading: false,
                    logsLists: data?.data,
                    logId: null
                })
                setTotalRecordsLogs(data?.count ?? 0)
            }
        } catch (error) {
            errorMessage();
            setLogsStates({
                ...logsStates,
                isLoading: false,
            })
        }
    }

    useEffect(() => {
        getAllEULA(0, 10);
    }, []);

    return (
        <>
            <Helmet>
                <title>End User License Agreement</title>
            </Helmet>
            {!isVisiableAddModal ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="End User License Agreement"
                                action={
                                    <Grid
                                        container
                                        spacing={{ xs: 1, md: 1, lg: 1 }}
                                        columns={{ xs: 4, sm: 8, md: 8 }}
                                        className="button-align"
                                    >
                                        <Grid item>
                                            <Button
                                                disabled={isLoading}
                                                type="button"
                                                size="large"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#002986!important",
                                                    color: "white!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                }}
                                                startIcon={<Add />}
                                                onClick={(e) => handleIsVisibleAddModal(e)}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                            />
                            <CardContent className="plan-tbl">
                                {!isLoading ? (
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left">Created At</StyledTableCell>
                                                        <StyledTableCell align="left">EULA Content</StyledTableCell>
                                                        <StyledTableCell align="left">Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {eulaContent && eulaContent?.length > 0 ? (
                                                        <>
                                                            {eulaContent && eulaContent?.map((item, index) => (
                                                                <StyledTableRow key={index}>
                                                                    <StyledTableCell align="left">
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {new Date(item.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left">
                                                                        <p dangerouslySetInnerHTML={{ __html: item?.text.slice(0, 500) }} />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            <Tooltip title="View Log">
                                                                                <IconButton
                                                                                    onClick={() => getEulaLogs(logPagination?.skip, logPagination?.pageLimit, item._id)}
                                                                                    sx={actionbutton}
                                                                                    size="small"
                                                                                    aria-label="view-log"
                                                                                >
                                                                                    <img src={Historylog} alt="no" width={20} height={20} style={{ color: "#384E68" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton
                                                                                    sx={actionbutton}
                                                                                    onClick={() => getSingleEULA(item._id)}
                                                                                    aria-label="edit"
                                                                                    size="small">
                                                                                    <Edit fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <StyledTableRow>
                                                            <StyledTableCell
                                                                component="th"
                                                                scope="row"
                                                                colSpan={5}
                                                                align="center"
                                                            >
                                                                No Data Found
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                ) : (
                                    <ContainerLoader />
                                )}
                                <br />
                                {eulaContent && eulaContent.length > 0 && (
                                    <>
                                        {totalRecords > 0 && (
                                            <Pagination
                                                key="paginationnew"
                                                searchPage={eulaPagination?.searchPage}
                                                SearchDisable={SearchDisable}
                                                totalRecords={totalRecords}
                                                pageLimit={eulaPagination?.pageLimit}
                                                onPageChanged={onPageChanged}
                                            />
                                        )}
                                        <div
                                            style={{
                                                textAlign: "right",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <label>Items per page :</label>
                                            <FormControl
                                                sx={{
                                                    m: 1,
                                                    minWidth: 50,
                                                }}
                                                size="small"
                                            >
                                                <Select
                                                    defaultValue={eulaPagination?.pageLimit}
                                                    value={eulaPagination?.pageLimit}
                                                    name="limit"
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            padding: "4px 34px 4px 14px!important",
                                                            fontFamily: "Barlow",
                                                        },
                                                    }}
                                                    onChange={(e) => changePageLimit(e)}
                                                >
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="20">20</MenuItem>
                                                    <MenuItem value="50">50</MenuItem>
                                                    <MenuItem value="100">100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                // add Modal
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={isEdit ? "Update End User License Agreement" : "Add End User License Agreement"}
                                action={
                                    <Button
                                        type="button"
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important",
                                        }}
                                        startIcon={<ArrowBack />}
                                        onClick={(e) => handleCloseAddModal(e)}
                                    >
                                        Back
                                    </Button>
                                }
                            />
                            <CardContent>
                                <ValidatorForm
                                    className="filter-form1 mb-5"
                                    onSubmit={() => isEdit ? handleUpdateEULA() : handleAddEULA()}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left!important",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                EULA Content
                                            </Typography>
                                            <div style={{ zIndex: 0 }}>
                                                <Editor
                                                    apiKey="ox4jp745eo6k4whfbfxfk0675klim6a6i0vhupfcdmsshjcr"
                                                    onEditorChange={(e) => {
                                                        setFormData({
                                                            ...fromData,
                                                            text: e
                                                        })
                                                    }}
                                                    value={fromData.text}
                                                    init={{
                                                        height: 500,
                                                        anchor_top: false,
                                                        anchor_bottom: false,
                                                        automatic_uploads: true,
                                                        file_picker_types: "file image media",
                                                        paste_data_images: true,
                                                        image_advtab: true,
                                                        file_picker_callback: function (
                                                            callback,
                                                            value,
                                                            meta
                                                        ) {
                                                            if (meta.filetype == "image") {
                                                                var input = document.createElement("input");
                                                                input.setAttribute("type", "file");
                                                                input.setAttribute("accept", "image/*");
                                                                input.onchange = function () {
                                                                    var file = this.files[0];
                                                                    var reader = new FileReader();
                                                                    reader.onload = function () {
                                                                        callback(reader.result, {
                                                                            alt: file.name,
                                                                        });
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                };
                                                                input.click();
                                                            }
                                                        },
                                                        advlist_bullet_styles: "square",
                                                        advlist_number_styles:
                                                            "lower-alpha,lower-roman,upper-alpha,upper-r",
                                                        toolbar_mode: "sliding",
                                                    }}
                                                    menubar={[
                                                        "file edit view insert format tools table help",
                                                    ]}
                                                    plugins={[
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste code help wordcount",
                                                        "textpattern",
                                                        "image",
                                                        "imageupload",
                                                        "link",
                                                        "help",
                                                        "wordcount",
                                                        "code",
                                                        "preview",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "paste",
                                                        "charmap",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "emoticons",
                                                        "directionality",
                                                        "visualchars",
                                                        "noneditable",
                                                        "nonbreaking",
                                                        "quickbars",
                                                        "toc",
                                                        "anchor",
                                                        "pagebreak",
                                                        "importcss",
                                                        "autosave",
                                                        "save",
                                                        "template",
                                                        "help",
                                                    ]}
                                                    toolbar={[
                                                        " undo redo | bullist numlist | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons ",
                                                    ]}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} style={{ margin: "0 auto" }}>
                                            <Button
                                                fullWidth
                                                size="large"
                                                sx={{ paddingY: "0.9rem" }}
                                                variant="contained"
                                                type="submit"
                                                className={`${isDisableButton ? "theme-btn-submit-disablebutton" : "theme-btn-submit"}`}
                                                disabled={isDisableButton}
                                            >
                                                {isEdit ? "Update" : "Submit"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {/* logs modal */}
            <Dialog
                scroll="body"
                fullWidth={true}
                maxWidth="md"
                open={isVisiableLogsModal}
                className="Modal_Box custom-pop-over"
                onClose={handleCloseLogsModal}
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleCloseLogsModal()}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                        }}
                    >
                        <Cancel />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{
                            width: "100%",
                            maxWidth: "14rem",
                            color: "#000059!important",
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "1.6rem",
                            fontFamily: "Barlow!important",
                        }}
                    >
                        Logs
                    </Typography>
                    {logsStates?.isLoading ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "11rem 0",
                            }}
                        >
                            <div style={{ position: 'relative', top: '4px' }}>
                                <img className="loader-size-table" src={loaderGif} alt="no loader" />
                            </div>
                        </Box>
                    ) : (
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCells>
                                                Users Accepted
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                Accepted  Date
                                            </StyledTableCells>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logsStates?.logsLists && logsStates?.logsLists?.length > 0 ? (
                                            <>
                                                {logsStates?.logsLists && logsStates?.logsLists?.map((log, index) => {
                                                    return (
                                                        <StyledTableRows key={index}>
                                                            <StyledTableCells component="td" scope="row">
                                                                {(log?.userId && log?.userId?.email) ?? "-"}
                                                            </StyledTableCells>
                                                            <StyledTableCells component="td" scope="row">
                                                                {customDateFormatLocal(
                                                                    log?.acceptedAt ?? null,
                                                                    null
                                                                )}
                                                            </StyledTableCells>
                                                        </StyledTableRows>

                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <StyledTableRows>
                                                <td
                                                    colSpan={13}
                                                    style={{ padding: "0rem" }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontSize: "1.6rem",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        No Log yet!
                                                    </Typography>
                                                </td>
                                            </StyledTableRows>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                    <br />
                    {logsStates?.logsLists && logsStates?.logsLists?.length > 0 && (
                        <>
                            {totalRecordslogs > 0 && (
                                <div className="pagnation-lic-div-logmodal">
                                    <Pagination
                                        key="paginationnew"
                                        searchPage={logPagination?.searchPage}
                                        SearchDisable={SearchDisableLog}
                                        totalRecords={totalRecordslogs}
                                        pageLimit={logPagination.pageLimit}
                                        onPageChanged={onPageChangedLog}
                                    />
                                </div>
                            )}
                            <div className="pagnation-item-parpage">
                                <label>Items per page :</label>
                                <FormControl
                                    sx={{
                                        m: 1,
                                        minWidth: 50,
                                    }}
                                    size="small"
                                >
                                    <Select
                                        defaultValue={logPagination.pageLimit}
                                        value={logPagination.pageLimit}
                                        name="limit"
                                        sx={{
                                            "& .MuiSelect-select": {
                                                padding: "4px 34px 4px 14px!important",
                                                fontFamily: "Barlow",
                                            },
                                        }}
                                        onChange={(e) => changePageLimitLog(e)}
                                    >
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                        <MenuItem value="50">50</MenuItem>
                                        <MenuItem value="100">100</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>
            {/* alert  message  */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default EndUserLicenseAgreement
