// Reducer for ScanApi

import { ActionTypes } from "../types/ActionTypes";

const initialState = {
    scanVendorEvent: {},
}

export const ScanApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SCANVENDOREVENT:
            return {
                ...state,
                scanVendorEvent: action.payload
            }
        default:
            return state;
    }
}