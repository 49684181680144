import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, MenuItem, Modal, Accordion as MuiAccordion, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, tableCellClasses } from "@mui/material";
import loaderGif from "images/GIF-Loader-3.gif";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AlertMessage from "components/message/message";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CalendarIcon from "images/user-dashboard/calendar-icon.svg";
import TicketsIcon from "images/user-dashboard/ticket-icon.svg";
import SoldTicketsIcon from "images/user-dashboard/sold-ticket.svg";
import Pagination from "components/pagination/pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import customDateFormat from "utils/customDateFormat";
import Cookies from 'js-cookie';
import "./interactive.scss";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { HiOutlineArrowNarrowDown, HiOutlineSearch } from "react-icons/hi";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import DataTable from "components/DataTable/DataTable";
import { Cancel, SaveAlt } from "@mui/icons-material";
import styled from "@emotion/styled";
import { ContainerLoader } from "components/common/ContainerLoader";
import { LoadingButton } from "@mui/lab";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontFamily: "Barlow !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Barlow !important",
    padding: "13px!important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const moment = extendMoment(originalMoment);

// nt Modal for listing 
function ChildModalListModal({
  isOpenListCountTicket,
  handleCloseUncheckedTicketList,
  handleMarkNonTransferableConmation,
  countNonTransferableTickets,
  isCountLoading,
  totalTicketCount,
  totalLumpSumCharge,
}) {
  const [state, setState] = useState({
    currentColumn: null,
    activeasc: false,
    activedesc: false,
    styleInactive: false,
    direction: '',
  });
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [sortData, setSortData] = useState([]);

  const handleRotateIcon = async (columnname) => {
    if (state.currentColumn === null) {
      await setState({ ...state, currentColumn: columnname });
    }

    if (state.currentColumn !== columnname) {
      await setState({
        ...state,
        activeasc: false,
        activedesc: false,
        currentColumn: columnname,
        styleInactive: false,
        direction: '',
      });
    }

    if (state.currentColumn === columnname) {
      if (
        !state.styleInactive &&
        !state.activeasc &&
        !state.activedesc &&
        state.currentColumn === columnname
      ) {
        handleSortByPriceAsc();
        setState({
          ...state,
          styleInactive: true,
          currentColumn: columnname,
          direction: 'asc',
        });
      }
      if (
        state.styleInactive &&
        !state.activeasc &&
        !state.activedesc &&
        state.currentColumn === columnname
      ) {
        handleSortByPriceDsc();
        await setState({
          ...state,
          styleInactive: true,
          activeasc: true,
          activedesc: false,
          direction: 'desc',
        });
      } else if (
        state.styleInactive &&
        state.activeasc &&
        !state.activedesc &&
        state.currentColumn === columnname
      ) {
        handleSortByPriceAsc();
        await setState({
          ...state,
          currentColumn: columnname,
          activeasc: false,
          activedesc: false,
          direction: 'asc',
        });
      }
    }
  };

  const handleSort = (order) => {
    const sortOrder = order === 'asc' ? 1 : -1;
    const compare = (a, b, key, isNumeric = false) => {
      if (isNumeric) {
        return (parseFloat(a[key]) - parseFloat(b[key])) * sortOrder;
      } else {
        return a[key].localeCompare(b[key]) * sortOrder;
      }
    };
    let sortedData = [...countNonTransferableTickets];
    if (state?.currentColumn === "email") {
      sortedData.sort((a, b) => compare(a, b, "email"));
    } else if (state?.currentColumn === "ticketCount") {
      sortedData.sort((a, b) => compare(a, b, "ticketCount", true));
    } else if (state?.currentColumn === "ticketPrice") {
      sortedData.sort((a, b) => compare(a, b, "ticketPrice", true));
    } else if (state?.currentColumn === "ntTicketPrice") {
      sortedData.sort((a, b) => compare(a, b, "ntTicketPrice", true));
    } else if (state?.currentColumn === "purchaseAmount") {
      sortedData.sort((a, b) => compare(a, b, "purchaseAmount", true));
    }
    setSortData(sortedData);
  };

  const handleSortByPriceAsc = () => handleSort('asc');
  const handleSortByPriceDsc = () => handleSort('dsc');

  const validationOftheformatValue = (value) => {
    return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
  }

  const convertToCSV = (data) => {
    const dataFiltered = data.map((item, index) => {
      return {
        [`User(s) (${data?.length ?? 0})`]: validationOftheformatValue(item?.email),
        [`Ticket Count (${totalTicketCount ?? 0})`]: '\t' + validationOftheformatValue(item?.ticketCount),
        'Ticket Price': `${validationOftheformatValue(item?.ticketPrice?.toFixed(2) ?? '0.00')}`,
        'Non-Transferable Ticket Price': `${validationOftheformatValue(item?.ntTicketPrice?.toFixed(2) ?? '0.00')}`,
        [`Lump Sum Charge (${totalLumpSumCharge?.toFixed(2) ?? '0.00'})`]: `${validationOftheformatValue(item?.purchaseAmount?.toFixed(2) ?? '0.00')}`
      };
    });
    return Papa.unparse(dataFiltered);
  }

  const exportToCSV = (data) => {
    setIsExportLoading(true);
    const csv = convertToCSV(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "non-transferable-ticket-price.csv");
    setIsExportLoading(false);
  };

  useEffect(() => {
    setSortData(countNonTransferableTickets)
  }, [countNonTransferableTickets])

  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={isOpenListCountTicket}
        onClose={handleCloseUncheckedTicketList}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "1300px"
          },
        }}
      >
        <Box sx={{ padding: "0px 19px 0px 17px" }}>
          <Grid
            container
            rowSpacing={1}
            spacing={{ xs: 2, md: 2, lg: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <DialogTitle sx={{ padding: "16px 0px!important" }}>
                Mark as Non-Transferable
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end"
              }}
            >
              <LoadingButton
                onClick={() => exportToCSV(countNonTransferableTickets)}
                size="large"
                endIcon={<SaveAlt />}
                loading={isExportLoading || countNonTransferableTickets.length > 0 ? false : true}
                disabled={isExportLoading || countNonTransferableTickets.length > 0 ? false : true}
                loadingPosition="end"
                sx={{
                  fontFamily: "Bevan, cursive !important",
                  marginBottom: "10px"
                }}
                variant="contained"
              >
                <span>Export to CSV</span>
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <DialogContent sx={{ padding: "4px 14px" }} >
          <DialogContentText
            sx={{
              fontFamily: "Barlow",
              fontWeight: "500",
              fontSize: "18px",
              color: "black",
            }}
          >
            {isCountLoading ?
              <ContainerLoader />
              :
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 900 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">
                        <div style={{ width: "100%", minWidth: "max-content", }}>
                          <TableSortLabel
                            IconComponent={HiOutlineArrowNarrowDown}
                            active={
                              state.currentColumn === "email"
                            }
                            direction={
                              state.currentColumn === "email"
                                ? state.direction
                                : "asc"
                            }
                            onClick={() => handleRotateIcon("email")}
                          >
                            User(s) ({countNonTransferableTickets?.length ?? 0})
                          </TableSortLabel>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div style={{ width: "100%", minWidth: "max-content", }}>
                          <TableSortLabel
                            IconComponent={HiOutlineArrowNarrowDown}
                            active={
                              state.currentColumn === "ticketCount"
                            }
                            direction={
                              state.currentColumn === "ticketCount"
                                ? state.direction
                                : "asc"
                            }
                            onClick={() => handleRotateIcon("ticketCount")}
                          >
                            Ticket Count ({totalTicketCount ?? 0})
                          </TableSortLabel>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div style={{ width: "100%", minWidth: "max-content", }}>
                          <TableSortLabel
                            IconComponent={HiOutlineArrowNarrowDown}
                            active={
                              state.currentColumn === "ticketPrice"
                            }
                            direction={
                              state.currentColumn === "ticketPrice"
                                ? state.direction
                                : "asc"
                            }
                            onClick={() => handleRotateIcon("ticketPrice")}
                          >
                            Ticket Price
                          </TableSortLabel>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div style={{ width: "100%", minWidth: "max-content", }}>
                          <TableSortLabel
                            IconComponent={HiOutlineArrowNarrowDown}
                            active={
                              state.currentColumn === "ntTicketPrice"
                            }
                            direction={
                              state.currentColumn === "ntTicketPrice"
                                ? state.direction
                                : "asc"
                            }
                            onClick={() => handleRotateIcon("ntTicketPrice")}
                          >
                            Non-Transferable Ticket Price
                          </TableSortLabel>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div style={{ width: "100%", minWidth: "max-content", }}>
                          <TableSortLabel
                            IconComponent={HiOutlineArrowNarrowDown}
                            active={
                              state.currentColumn === "purchaseAmount"
                            }
                            direction={
                              state.currentColumn === "purchaseAmount"
                                ? state.direction
                                : "asc"
                            }
                            onClick={() => handleRotateIcon("purchaseAmount")}
                          >
                            Lump sum charge (${totalLumpSumCharge.toFixed(2) ?? 0.00})
                          </TableSortLabel>
                        </div>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortData.length > 0 ? sortData.map((item, index) => {
                      return (
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            {item?.email}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {item?.ticketCount}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            ${item?.ticketPrice.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            ${item?.ntTicketPrice.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            sx={{
                              textAlign: "left!important",
                            }}
                          >
                            ${item?.purchaseAmount.toFixed(2)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    }) :
                      <StyledTableRow>
                        <td
                          colSpan={13}
                          style={{ padding: "2.6rem 1rem" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.6rem",
                              textAlign: "center",
                            }}
                          >
                            Data not Found
                          </Typography>
                        </td>
                      </StyledTableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUncheckedTicketList}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleMarkNonTransferableConmation}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// nt Confirmation modal
function ChildModal({
  isOpenConfirmation,
  handleClose,
  handleMarkNonTransferableAPI,
  nt_type
}) {
  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        open={isOpenConfirmation}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "16px 24px!important" }} >Mark as Non-Transferable</DialogTitle>
        <DialogContent sx={{ padding: " 20px 24px" }} >
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: (theme) => theme.palette.grey[500],
              p: 1,
            }}
          >
            <Cancel />
          </IconButton>
          <DialogContentText
            sx={{
              fontFamily: "Barlow",
              fontWeight: "500",
              fontSize: "18px",
              color: "black",
            }}
          >
            Are you sure you want to choose {nt_type} option?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleMarkNonTransferableAPI}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

//  modal fro confirmation uncheck NT
function UnCheckNTChildModal({
  isOpenConfirmationUnckeckNT,
  handleCloseConfirmationUnCkeckNT,
  handleMarkNonTransferableAPI
}) {
  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        open={isOpenConfirmationUnckeckNT}
        onClose={handleCloseConfirmationUnCkeckNT}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "16px 24px!important" }} >Mark as Transferable</DialogTitle>
        <DialogContent sx={{ padding: " 20px 24px" }} >
          <IconButton
            onClick={handleCloseConfirmationUnCkeckNT}
            aria-label="close"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: (theme) => theme.palette.grey[500],
              p: 1,
            }}
          >
            <Cancel />
          </IconButton>
          <DialogContentText
            sx={{
              fontFamily: "Barlow",
              fontWeight: "500",
              fontSize: "18px",
              color: "black",
            }}
          >
            Are you sure you want to mark as transferable?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmationUnCkeckNT}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleMarkNonTransferableAPI}
            sx={{
              backgroundColor: "#002986!important",
              color: "white!important",
              fontWeight: "bold",
              fontFamily: "Barlow",
              textTransform: "none",
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

class Inventorylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      user_deliverymsg_time: "immediate",
      events: [],
      totalRecords: 0,
      openedevent: null,
      vendorLabel: null,
      vendorVal: null,
      userVal: null,
      allvendors: [],
      selectedDate: null,
      selectedendDate: null,
      dataloader: false,
      durationDate: new Date(),
      searchPage: 0,

      formData: {
        event_names: "",
        section: "",
        row: "",
        seat: "",
        user: "",
        startDate: null,
        endDate: null,
        limit: 20,
        skip: 0,
        pathname: "",
        searchvendor: "",
        data_month: moment().format("MM"),
        data_year: moment().format("YYYY"),
      },
      generateTicketMsg: "",
      generateTicketMsgAlertColor: "",
      generateTicketMsgOpen: false,
      submttingticketform: false,
      generateTicketEvent: null,
      generateTicketData: {
        customerDisplayName: "",
        performer_name: "",
        invoiceExternalRef: "",
        uploadtovivid: false,
        custfromvivid: false,
        invoiceDate: "",
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        deliverymsg_time: "immediate",
        ticketData: [],
        ticketIds: [],
      },
      generateTicketModal: false,
      copiedTicketUrl: false,
      generatedTicketUrl: null,
      generatedTicketDetailModal: false,
      crossCheck: true,
      messageopen: false,
      message: "",
      messagetype: "",
      user_names: "",
      // soldtickets: 0,
      soldtickets_total: 0,
      allUsers: [],
      expanded: false,
      is_nt_price_checked: false,
      nt_eventsId: [],
      isOpenConfirmation: false,
      nt_type: "forward-only",
      is_nt_unchecked: false,
      countNonTransferableTickets: [],
      isOpenListCountTicket: false,
      isCountLoading: false,
      totalTicketCount: 0,
      totalLumpSumCharge: 0,
      totalNTTicketPrice: 0,
      isUncheckModalTable: false,
      isLoadingUncheckNT: false,
      unCheckCountNTChargeDeducted: [],
      isOpenConfirmationUnckeckNT: false,
    };

    this.getEvents = this.getEvents.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.generateModal = this.generateModal.bind(this);
    this.submitGenerateTicket = this.submitGenerateTicket.bind(this);
    this.handleClosemessage = this.handleClosemessage.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSelectVendor = this.handleSelectVendor.bind(this);
    this.handleSelectUser = this.handleSelectUser.bind(this);
    this.selectTimeDurtion = this.selectTimeDurtion.bind(this);
    this.userList = this.userList.bind(this);
    this.SearchDisable = this.SearchDisable.bind(this);
    this.handleChangeAccordian = this.handleChangeAccordian.bind(this);
    this.handleChangeNT = this.handleChangeNT.bind(this);
    this.handleOpenConfirmation = this.handleOpenConfirmation.bind(this);
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.handleMarkNonTransferableAPI = this.handleMarkNonTransferableAPI.bind(this);
    this.handleChangeNtRadio = this.handleChangeNtRadio.bind(this)
    this.handleUncheckNTransferable = this.handleUncheckNTransferable.bind(this);
    this.handleCloseUnchecked = this.handleCloseUnchecked.bind(this);
    this.handleConfirmationModalUnCkeckNT = this.handleConfirmationModalUnCkeckNT.bind(this);
    this.handleCloseConfirmationUnCkeckNT = this.handleCloseConfirmationUnCkeckNT.bind(this);
    this.handleCloseUnchecked = this.handleCloseUnchecked.bind(this);
  }

  componentDidMount() {
    this.vendor_list();
    this.getEvents();
    this.userList();
  }
  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };
  handleChangeAccordian = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  vendor_list() {
    const vendorList_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/account/listAllAccounts";
    axios
      .get(
        vendorList_api_url,

        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const newdata = data.data.map((val) => {
            return {
              label: val.username,
              id: val._id,
            };
          });

          const seen = new Set();
          const filteredArr = newdata.filter((el) => {
            const duplicate = seen.has(el.label);
            seen.add(el.label);
            return !duplicate;
          });
          this.setState({
            allvendors: filteredArr,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  userList() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(
        userList_api_url,
        {},

        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            allUsers: data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }

  //Function to select vendor from search
  handleSelectVendor(e, val) {
    if (val) {
      this.setState({ vendorLabel: val.label, vendorVal: val });
      const { formData } = this.state;
      formData["searchvendor"] = val.label;
      this.setState({ formData });
    } else {
      this.setState({ vendorLabel: null, vendorVal: null });
      const { formData } = this.state;
      formData["searchvendor"] = null;
      this.setState({ formData });
    }
  }
  handleSelectUser(e, val) {
    if (val) {
      const { formData } = this.state;
      formData["user"] = val.email;
      this.setState({ formData });
      this.setState({ userVal: val });
    } else {
      const { formData } = this.state;
      formData["user"] = null;
      this.setState({ formData });
      this.setState({ userVal: null });
    }
  }
  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.formData.limit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ loader: false, message: data.message });
          } else {
            this.setState({
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  handleChange(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = evt.target.value;
    this.setState({ formData });
  }

  changepageLimit(evt) {
    const { formData } = this.state;
    formData[evt.target.name] = parseInt(evt.target.value);
    formData["skip"] = 0;
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.getEvents(false);
      this.updateProfile();
    });
  }

  setStartDate = async (dates) => {
    const { formData } = this.state;
    const [start, end] = dates;

    // const getstart = start.valueOf()+hours+mins
    this.setState({ selectedendDate: end, selectedDate: start });

    // fomating date to isoString
    const datestart = moment(start).format("YYYY-MM-DDTHH:mm:ss");
    const dateend = moment(end).format("YYYY-MM-DDTHH:mm:ss");
    formData["startDate"] = datestart + ".000Z";
    if (end) {
      formData["endDate"] = dateend + ".000Z";
    }

    this.setState({ formData, crossCheck: false });
  };

  clearDate = () => {
    const { formData } = this.state;
    this.setState({ selectedendDate: null, selectedDate: null });
    formData["startDate"] = null;
    formData["endDate"] = null;
    this.setState({ formData, crossCheck: true });
  };

  getEvents() {
    const event_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getAllInteractiveEvents";
    axios
      .post(event_api_url, this.state.formData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.setState({ totalRecords: data.total });
          this.setState({
            events: data.data,
            dataloader: false,
            loader: false,
          });

          var openedevent = null;
          this.setState({ openedevent });
        }
      });
  }

  getTickets(index, event_ids) {
    const { events, generateTicketData } = this.state;
    var openedevent = null;
    if (this.state.openedevent !== index) {
      openedevent = index;
    }
    generateTicketData.ticketData = [];
    generateTicketData.ticketIds = [];
    this.setState({ openedevent, generateTicketData });
    if (!("tickets" in events[index])) {
      var event_only_ids = event_ids.map((ev) => {
        return ev._id;
      });
      const params = { event_id: event_only_ids };

      if (this.state.formData.section !== "") {
        params.section = this.state.formData.section;
      }
      if (this.state.formData.row !== "") {
        params.row = this.state.formData.row;
      }
      if (this.state.formData.seat !== "") {
        params.seat = this.state.formData.seat;
      }

      const event_api_url =
        process.env.REACT_APP_API_URL +
        "/api/admin/getAdminInventoryTickets";
      axios
        .post(event_api_url, params, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.success) {
            events[index].tickets = data.data;
          } else {
            events[index].tickets = [];
          }
          this.setState({ events });
        });
    }
  }

  handleSubmit() {
    const { formData } = this.state;
    formData["skip"] = 0;
    this.setState({ searchPage: 1, formData, loader: true }, () => {
      this.getEvents();
    });
  }

  onPageChanged = (data) => {
    const { currentPage } = data;
    const { formData } = this.state;
    formData["skip"] = formData.limit * (currentPage - 1);
    this.setState({ dataloader: true, loader: true, formData }, () => {
      this.getEvents();
    });
  };

  selectTimeDurtion(dates) {
    this.setState({ durationDate: dates });
    const { formData } = this.state;
    formData["data_month"] = moment(dates).format("MM");
    formData["data_year"] = moment(dates).format("YYYY");
    this.setState({ formData, loader: true }, () => {
      this.getEvents();
    });
  }
  getBadge = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Inactive":
        return "secondary";
      case "Pending":
        return "warning";
      case "Banned":
        return "danger";
      default:
        return "primary";
    }
  };

  generateModal = (val, event) => {
    this.setState({
      generateTicketModal: val,
      generatedTicketUrl: null,
      copiedTicketUrl: false,
      generateTicketEvent: event,
    });
  };

  generatedDetailModalHide = () => {
    var generateTicketData = {
      invoiceExternalRef: "",
      invoiceDate: "",
      customerEmail: "",
      customerPhone: "",
      customerName: "",
      uploadtovivid: false,
      custfromvivid: false,
      ticketData: [],
      ticketIds: [],
      deliverymsg_time: this.state.user_deliverymsg_time,
    };

    this.setState({
      generateTicketData,
      generateTicketModal: false,
      generatedTicketUrl: null,
      copiedTicketUrl: false,
      generateTicketEvent: null,
      generatedTicketDetailModal: false,
      generateTicketMsg: null,
      generateTicketMsgOpen: false,
    });
  };

  submitGenerateTicket = () => {
    this.setState({ submttingticketform: true });
    const ticket_api_url =
      process.env.REACT_APP_API_URL + "/api/user/generateTicketUrl";
    axios
      .post(ticket_api_url, this.state.generateTicketData, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        var { data } = response;
        if (data.error) {
          this.setState({
            generateTicketMsg: data.message
              ? data.message
              : "There is issue in generating ticket",
            generateTicketMsgAlertColor: "error",
            generateTicketMsgOpen: true,
            submttingticketform: false,
          });
        } else {
          this.setState({ loader: true }, () => {
            this.getEvents();
          });

          this.setState({
            generatedTicketDetailModal: true,
            submttingticketform: false,
            generatedTicketUrl: data.ticketUrl,
          });
        }
      });
  };

  handleClosemessage = () => {
    this.setState({ message: "", messageopen: false });
  };

  copyUrl = () => {
    this.setState({ copiedTicketUrl: true });
    navigator.clipboard.writeText(this.state.generatedTicketUrl);
  };


  handleChangeNT = (e, item) => {
    if (e.target.checked) {
      const ids = item?.events?.map(item => item._id);
      const value = e.target.checked;
      this.setState({
        is_nt_price_checked: value,
        nt_eventsId: ids,
      });
      this.setState({ expanded: false });
    } else {
      this.setState({
        is_nt_price_checked: false,
        nt_eventsId: [],
      });
      this.setState({ expanded: false });
    }
  };

  handleCloseNT = () => {
    this.setState({
      is_nt_price_checked: false,
      nt_eventsId: [],
      nt_type: "forward-only",
    });
  }

  handleOpenConfirmation = () => {
    this.setState({ isOpenConfirmation: true });
  }

  handleCloseConfirmation = () => {
    this.setState({
      isOpenConfirmation: false,
      isOpenListCountTicket: this.state.nt_type === "forward-only" ? false : true
    });
  }

  handleChangeNtRadio = (event) => {
    this.setState({ nt_type: event.target.value })
  }

  handleCloseUncheckedTicketList = () => {
    this.setState({ isOpenListCountTicket: false });
  }

  handleMarkNonTransferableGetCount = async () => {
    this.setState({ isOpenListCountTicket: true })
    const params = {
      eventIds: this.state.nt_eventsId,
    }
    try {
      this.setState({ isCountLoading: true })
      const non_transferable_api = process.env.REACT_APP_API_URL + "/api/admin/countNonTransferableTickets";
      const res = await axios.post(non_transferable_api, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = res;
      if (data.error) {
        this.setState({
          messageopen: true,
          message: "Something went wrong",
          messagetype: "error",
          isCountLoading: false
        });
      } else {
        this.setState({
          countNonTransferableTickets: data?.data,
          isCountLoading: false,
          totalTicketCount: data?.data?.map(datum => datum?.ticketCount).reduce((a, b) => a + b),
          totalLumpSumCharge: data?.data?.map(datum => datum?.purchaseAmount).reduce((a, b) => a + b),
        });
      }
    } catch (error) {
      this.setState({
        messageopen: true,
        message: "Something went wrong",
        messagetype: "error",
        isCountLoading: false
      });
    }
  }

  handleMarkNonTransferableAPI = async () => {
    const params = {
      eventIds: this.state.nt_eventsId,
      type: this.state.nt_type,
      ntStatus: true
    }
    const paramsuncheck = {
      eventIds: this.state.nt_eventsId,
      ntStatus: false
    }
    try {
      const non_transferable_api = process.env.REACT_APP_API_URL + "/api/admin/markEventNonTransferable";
      const res = await axios.post(non_transferable_api, this.state.is_nt_unchecked === true ? paramsuncheck : params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = res;
      if (data.error) {
        this.setState({
          messageopen: true,
          message: "Something went wrong",
          messagetype: "error",
          is_nt_price_checked: false,
          nt_eventsId: [],
          is_nt_unchecked: false,
          nt_type: "forward-only",
          isOpenConfirmation: false
        });
      } else {
        this.getEvents();
        this.setState({
          messageopen: true,
          message: data?.message,
          messagetype: "success",
          is_nt_price_checked: false,
          nt_eventsId: [],
          nt_type: "forward-only",
          is_nt_unchecked: false,
          isOpenConfirmation: false
        });
      }
    } catch (error) {
      this.setState({
        messageopen: true,
        message: "Something went wrong",
        messagetype: "error",
        is_nt_price_checked: false,
        nt_eventsId: [],
        is_nt_unchecked: false,
        nt_type: "forward-only",
        isOpenConfirmation: false
      });
    }
  }

  handleUncheckNTransferable = (e, item) => {
    if (item) {
      const ids = item?.events?.map(item => item._id);
      this.setState({
        is_nt_unchecked: true,
      });
      this.handleUnCheckMarkNonTransferableAPI(ids)
      this.setState({ expanded: false });
    } else {
      this.setState({
        is_nt_unchecked: false,
      });
      this.setState({ expanded: false });
    }
  }

  handleCloseUnchecked = () => {
    this.setState({
      is_nt_unchecked: false,
    });
  }

  handleConfirmationModalUnCkeckNT = () => {
    this.setState({ isOpenConfirmationUnckeckNT: true })
  }

  handleCloseConfirmationUnCkeckNT = () => {
    this.setState({
      isOpenConfirmationUnckeckNT: false,
      is_nt_unchecked: true,
    });
  }

  handleUnCheckMarkNonTransferableAPI = async (nt_eventsId) => {
    const params = {
      eventIds: nt_eventsId,
    }
    try {
      this.setState({ isLoadingUncheckNT: true })
      const non_transferable_api = process.env.REACT_APP_API_URL + "/api/admin/countNTChargeDeducted";
      const res = await axios.post(non_transferable_api, params, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      const { data } = res;
      if (data.error) {
        this.setState({
          messageopen: true,
          message: data.message ?? "Something went wrong",
          messagetype: "error",
          isLoadingUncheckNT: false,
          unCheckCountNTChargeDeducted: []
        });
      } else {
        this.setState({
          unCheckCountNTChargeDeducted: data?.message ?? [],
          isLoadingUncheckNT: false
        });
      }
    } catch (error) {
      this.setState({
        messageopen: true,
        message: "Something went wrong",
        messagetype: "error",
        isLoadingUncheckNT: false,
        unCheckCountNTChargeDeducted: []
      });
    }
  }

  handleMarkNonTransferableConmation = () => {
    this.setState({
      isOpenConfirmation: true,
      isOpenListCountTicket: false
    });
  }

  validationOftheformatValue = (value) => {
    return typeof value === ('number' || 'string') ? value ?? "" : value ?? "";
  }

  convertToCSV = (data) => {
    const dataFiltered = data.map((item, index) => {
      return {
        "User(s)": this.validationOftheformatValue((item?.email) ?? "-"),
        "Forward Only": `${'\t' + this.validationOftheformatValue((item?.forward_only?.toFixed(2)) ?? '0.00')}`,
        "Normal Charge": `${'\t' + this.validationOftheformatValue((item?.normal_charge?.toFixed(2)) ?? '0.00')}`,
        "Back Track": `${'\t' + this.validationOftheformatValue((item?.back_track?.toFixed(2)) ?? '0.00')}`,
      };
    });
    return Papa.unparse(dataFiltered);
  }

  exportToCSVNTUnChecked = (data) => {
    const csv = this.convertToCSV(data);
    const file = new Blob([csv], { type: "text/csv" });
    saveAs(file, "non-transferable-ticket-price.csv");
  }

  render() {
    const {
      loader,
      events,
      crossCheck,
      formData,
      totalRecords,
      nt_eventsId,
      nt_type,
      isLoadingUncheckNT,
      unCheckCountNTChargeDeducted
    } = this.state;
    const fields = [
      {
        key: "user",
        label: "User",
      },
      {
        key: "vendor",
        label: "Vendor",
      },
      {
        key: "section",
        label: "Section",
      },
      {
        key: "row",
        label: "Row",
      },
      {
        key: "seat",
        label: "Seat",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "view",
        label: "View",
      },
    ];
    return (
      <>
        <div className="interactive-admin">
          <Helmet>
            <title> Inventory Management</title>
          </Helmet>

          <div className="c-app c-default-layout user-dashboard-body">
            <div className="c-wrapper">
              <div className="c-body">
                {/* {loader && <PageLoader />} */}
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    className="space-top-col"
                  >
                    <Card>
                      <CardHeader
                        title={
                          <Typography
                            variant="h6"
                            align="left"
                            sx={{
                              fontSize: "24px!important",
                              fontWeight: "700!important",
                              color: "#002986!important",
                            }}
                          >
                            Interactive Inventory
                          </Typography>
                        }
                      />
                      <CardContent className="plan-tbl interactive-outer">
                        <ValidatorForm
                          onSubmit={this.handleSubmit}
                          className="filter-form mb-5"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              spacing={{ xs: 2, md: 2, lg: 2 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <TextField
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="event_names"
                                  placeholder="Search Event"
                                  value={formData.event_names}
                                  className="filter-input"
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={this.state.selectedDate}
                                  startDate={this.state.selectedDate}
                                  endDate={this.state.selectedendDate}
                                  monthsShown={this.state.monthsShown}
                                  selectsRange
                                  isClearable={true}
                                  customInput={
                                    <TextField
                                      variant="outlined"
                                      className="filter-input"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="date-icon"
                                          >
                                            <div className="d-flex">
                                              {crossCheck ? (
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  edge="end"
                                                >
                                                  <EventIcon className="text-dark" />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  edge="end"
                                                  onClick={() =>
                                                    this.clearDate()
                                                  }
                                                >
                                                  <ClearIcon className="text-dark" />
                                                </IconButton>
                                              )}
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  }
                                  onChange={(dates) =>
                                    this.setStartDate(dates)
                                  }
                                  filterDate={(date) => {
                                    var tdate = new Date();
                                    tdate.setDate(tdate.getDate() - 3);
                                    var dateString = tdate
                                      .toISOString()
                                      .split("T")[0];

                                    return date >= new Date(dateString);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <Autocomplete
                                  fullWidth
                                  className="filter-input"
                                  name="filterstatusVal"
                                  value={this.state.vendorVal}
                                  options={this.state.allvendors}
                                  onChange={this.handleSelectVendor}
                                  getOptionLabel={(option) => option.label}
                                  renderInput={(params) => (
                                    <TextField
                                      value={this.state.vendorVal}
                                      placeholder="Vendor Email"
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                className="team"
                                xs={12}
                                sm={4}
                                md={3}
                                lg={3}
                              >
                                <Autocomplete
                                  fullWidth
                                  className="filter-input"
                                  name="filterstatusVal"
                                  options={this.state.allUsers}
                                  value={this.state.userVal}
                                  onChange={this.handleSelectUser}
                                  getOptionLabel={(option) => option.email}
                                  renderInput={(params) => (
                                    <TextField
                                      value={this.state.userVal}
                                      placeholder="Search User"
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                />{" "}
                              </Grid>

                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <TextValidator
                                  variant="outlined"
                                  className="filter-input"
                                  onChange={this.handleChange}
                                  name="section"
                                  placeholder="Search Section"
                                  value={formData.section}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <TextValidator
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  className="filter-input"
                                  name="row"
                                  placeholder="Search Row"
                                  value={formData.row}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={3} lg={3}>
                                <TextValidator
                                  className="filter-input"
                                  variant="outlined"
                                  onChange={this.handleChange}
                                  name="seat"
                                  placeholder="Search Seat"
                                  value={formData.seat}
                                />
                              </Grid>

                              <Grid item xs={12} sm={4} md={2} lg={2}>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className="filter-btn "
                                  startIcon={<HiOutlineSearch />}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </ValidatorForm>
                        {!loader ? (
                          <>
                            <Grid container spacing={2} className="mb-3">
                              <Grid item xs={12}>
                                <div className="timeduration">
                                  <DatePicker
                                    selected={this.state.durationDate}
                                    onChange={(date) =>
                                      this.selectTimeDurtion(date)
                                    }
                                    dateFormat="MMM/yyyy"
                                    showMonthYearPicker
                                  />
                                </div>
                              </Grid>
                            </Grid>

                            {events.length === 0 && (
                              <div
                                style={{ backgroundColor: "#ccc" }}
                                className="text-center my-5"
                              >
                                <h2>
                                  No items{" "}
                                  <svg
                                    width="30"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    className="c-icon c-icon-custom-size text-danger mb-2"
                                    role="img"
                                  >
                                    <path
                                      fill="var(--ci-primary-color, currentColor)"
                                      d="M425.706,86.294A240,240,0,0,0,86.294,425.705,240,240,0,0,0,425.706,86.294ZM256,48A207.1,207.1,0,0,1,391.528,98.345L98.345,391.528A207.1,207.1,0,0,1,48,256C48,141.309,141.309,48,256,48Zm0,416a207.084,207.084,0,0,1-134.986-49.887l293.1-293.1A207.084,207.084,0,0,1,464,256C464,370.691,370.691,464,256,464Z"
                                      className="ci-primary"
                                    ></path>
                                  </svg>
                                </h2>
                              </div>
                            )}
                            {events.length > 0 &&
                              events.map((event, index) => (
                                <MuiAccordion
                                  key={index}
                                  TransitionProps={{ unmountOnExit: true }}
                                  expanded={
                                    this.state.expanded === `event${index}`
                                  }
                                  onChange={this.handleChangeAccordian(
                                    `event${index}`
                                  )}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{
                                      marginBottom: "4px!important",
                                    }}
                                    className="interactive-toggle"
                                    onClick={() =>
                                      this.getTickets(index, event.events)
                                    }
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      className="align-items-center"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={5}
                                        className="barinfo"
                                      >
                                        <div className="text-left ">
                                          {event.events[0].event_name}{" "}
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            (
                                            {event.events[0].account_detail
                                              ?.vendor === "AXS"
                                              ? "AXS"
                                              : event.events[0].account_detail
                                                ?.team}
                                            )
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={8}
                                        lg={7}
                                        className="barinfo"
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          justify="space-between"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            className="barinfo"
                                          >
                                            <div
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              <img
                                                src={CalendarIcon}
                                                style={{ width: "20px" }}
                                                className="table-data-calendar-icon"
                                                alt="calendor-icon"
                                              />
                                              {customDateFormat(
                                                event.events[0].date
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sm={4}
                                            md={2}
                                            lg={2}
                                            className="barinfo"
                                          >
                                            <div
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              <img
                                                src={TicketsIcon}
                                                className="table-data-ticket-icon"
                                                alt="ticket-icon"
                                              />
                                              {event.events.reduce(function (
                                                a,
                                                b
                                              ) {
                                                // return (
                                                //   a +
                                                //   (b.alltickets_total -
                                                //     b.soldtickets.length)
                                                // );
                                                return (
                                                  a +
                                                  (b.alltickets_total -
                                                    (b.soldtickets_total ?? 0))
                                                );
                                              },
                                                0)}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sm={4}
                                            md={2}
                                            lg={2}
                                            className="barinfo"
                                          >
                                            <div
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "24px",
                                                  marginRight: "5px",
                                                }}
                                                src={SoldTicketsIcon}
                                                alt="location-icon"
                                              />

                                              {event.events.reduce(function (
                                                a,
                                                b
                                              ) {
                                                return a + (b.soldtickets_total ?? 0);
                                                // return a + b.soldtickets.length;
                                              },
                                                0)}
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sm={4}
                                            md={2}
                                            lg={4}
                                            className="barinfo"
                                          >
                                            {event?.events[0]?.isnonTransferable === true ? (
                                              <>
                                                <Checkbox
                                                  checked={event?.events[0]?.isnonTransferable === true}
                                                  onChange={(e) =>
                                                    this.handleUncheckNTransferable(e, event, index)
                                                  }
                                                />Non-Transferable
                                              </>
                                            ) : (
                                              <>
                                                <Checkbox
                                                  id={event?.events[0]._id}
                                                  checked={nt_eventsId?.includes(event?.events[0]._id)}
                                                  onChange={(e) =>
                                                    this.handleChangeNT(e, event, index)
                                                  }
                                                />Non-Transferable
                                              </>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      padding: "0px!important",
                                    }}
                                  >
                                    {!event.tickets ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          padding: "2rem",
                                        }}
                                      >
                                        <CircularProgress
                                          sx={{
                                            color: "#011C58",
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <>
                                        { }

                                        {event.tickets.map((ticket) => {
                                          const sorteddata = ticket.tickets
                                            .sort(
                                              (a, b) =>
                                                a.section.localeCompare(
                                                  b.section
                                                ) ||
                                                parseInt(a.row) -
                                                parseInt(b.row) ||
                                                a.row.localeCompare(b.row) ||
                                                parseInt(a.seat) -
                                                parseInt(b.seat)
                                            )
                                            .map((ticket) => {
                                              return {
                                                ...ticket,
                                                status: ticket.delivery_revoked
                                                  ? "Revoked/Available"
                                                  : ticket.is_verified
                                                    ? ticket.soldinventory
                                                      ? "Sold"
                                                      : "Available"
                                                    : "Not Verified",
                                                ticketsurl:
                                                  ticket.ticketurl.length > 0 &&
                                                  ticket.ticketurl[0],
                                                vendor:
                                                  ticket?.account_detail
                                                    ?.vendor[0],
                                                username:
                                                  ticket?.account_detail
                                                    ?.username,
                                              };
                                            });

                                          const finalData = sorteddata.sort(
                                            (a, b) => {
                                              const statusOrder = {
                                                Available: 1,
                                                Sold: 2,
                                              };
                                              const aStatus =
                                                statusOrder[a.status] || 0;
                                              const bStatus =
                                                statusOrder[b.status] || 0;

                                              if (aStatus !== bStatus) {
                                                return aStatus - bStatus;
                                              } else {
                                                return 0;
                                              }
                                            }
                                          );
                                          return (
                                            <DataTable
                                              fields={fields}
                                              itemsPerPage={finalData.length}
                                              items={finalData || []}
                                              scopedSlots={{
                                                user: (item) =>
                                                  item.user[0] ?? "",
                                                vendor: (item) => item.username,
                                                section: (item) => item.section,
                                                row: (item) => item.row,
                                                seat: (item) => item.seat,
                                                status: (item) => item.status,
                                                view: (item) =>
                                                  item.ticketsurl && (
                                                    <>
                                                      <Button
                                                        component="a"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#002986!important",
                                                          color:
                                                            "white!important",
                                                          padding:
                                                            "2px 5px!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontWeight: "bold",
                                                          fontFamily:
                                                            "Barlow!important",
                                                          marginRight: "5px",
                                                        }}
                                                        href={item.ticketsurl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        Ticket
                                                      </Button>

                                                      <Button
                                                        component="a"
                                                        size="small"
                                                        variant="contained"
                                                        sx={{
                                                          backgroundColor:
                                                            "#002986!important",
                                                          color:
                                                            "white!important",
                                                          padding:
                                                            "2px 5px!important",
                                                          textTransform:
                                                            "none!important",
                                                          fontWeight: "bold",
                                                          fontFamily:
                                                            "Barlow!important",
                                                        }}
                                                        href={`/sold-inventory-management?refId=${item.soldinventory}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        Sale
                                                      </Button>
                                                    </>
                                                  ),
                                              }}
                                            />
                                          );
                                        })}
                                      </>
                                    )}
                                  </AccordionDetails>
                                </MuiAccordion>
                              ))}
                          </>
                        )
                          : (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "21rem 0",
                                }}
                              >
                                <div style={{ position: 'relative', top: '4px' }}>
                                  <img className="loader-size-table" src={loaderGif} alt="no loader" />
                                </div>
                              </Box>
                            </>
                          )
                        }
                        {totalRecords > 0 && (
                          <Pagination
                            key="paginationnew"
                            searchPage={this.state.searchPage}
                            SearchDisable={this.SearchDisable}
                            totalRecords={totalRecords}
                            pageLimit={this.state.formData.limit}
                            onPageChanged={this.onPageChanged}
                          />
                        )}
                        {totalRecords > 0 && (
                          <div
                            style={{
                              textAlign: "right",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <label>Items per page : </label>
                            <Select
                              defaultValue={formData.limit}
                              className="form-select form-select-sm"
                              value={formData.limit}
                              name="limit"
                              sx={{
                                "& .MuiSelect-select": {
                                  padding: "4px 14px",
                                  fontFamily: "Barlow",
                                },
                              }}
                              onChange={(e) => this.changepageLimit(e)}
                            >
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="20">20</MenuItem>
                              <MenuItem value="50">50</MenuItem>
                              <MenuItem value="100">100</MenuItem>
                            </Select>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          {/* nt price for choose option  */}
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={this.state.is_nt_price_checked}
            onClose={this.handleCloseNT}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <DialogTitle sx={{ padding: "16px 24px!important" }}>Mark as Non-Transferable</DialogTitle>
            <DialogContent sx={{ padding: " 20px 24px" }}>
              <IconButton
                onClick={this.handleCloseNT}
                aria-label="close"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <Cancel />
              </IconButton>
              <DialogContentText
                sx={{
                  fontFamily: "Barlow",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "black",
                }}
              >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Select an option:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={nt_type}
                    onChange={this.handleChangeNtRadio}
                  >
                    <FormControlLabel value="forward-only" control={<Radio />} label="Forward Only" />
                    <FormControlLabel value="back-track" control={<Radio />} label="Back-Track" />
                  </RadioGroup>
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseNT}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.state.nt_type === "forward-only" ?
                  this.handleOpenConfirmation :
                  this.handleMarkNonTransferableGetCount
                }
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Next
              </Button>
            </DialogActions>
            {this.state.isOpenListCountTicket === false ?
              <ChildModal
                isOpenConfirmation={this.state.isOpenConfirmation}
                handleClose={this.handleCloseConfirmation}
                handleMarkNonTransferableAPI={this.handleMarkNonTransferableAPI}
                nt_type={this.state.nt_type}
              />
              :
              <>
                {this.state.nt_type === "forward-only" ?
                  <ChildModal
                    isOpenConfirmation={this.state.isOpenConfirmation}
                    handleClose={this.handleCloseConfirmation}
                    handleMarkNonTransferableAPI={this.handleMarkNonTransferableAPI}
                    nt_type={this.state.nt_type}
                  />
                  :
                  <ChildModalListModal
                    isOpenListCountTicket={this.state.isOpenListCountTicket}
                    countNonTransferableTickets={this.state.countNonTransferableTickets}
                    handleMarkNonTransferableAPI={this.handleMarkNonTransferableAPI}
                    handleCloseUncheckedTicketList={this.handleCloseUncheckedTicketList}
                    isCountLoading={this.state.isCountLoading}
                    handleMarkNonTransferableConmation={this.handleMarkNonTransferableConmation}
                    totalTicketCount={this.state.totalTicketCount}
                    totalLumpSumCharge={this.state.totalLumpSumCharge}
                  />
                }
              </>
            }
          </Dialog>
          {/* un checkbox Nt price  modal*/}
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={this.state.is_nt_unchecked}
            onClose={this.handleCloseUnchecked}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "1000px"
              },
            }}
          >
            <Box sx={{ padding: "0px 16px 0px 16px" }}>
              <Grid
                container
                rowSpacing={1}
                spacing={{ xs: 2, md: 2, lg: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <DialogTitle sx={{ padding: "16px 0px!important" }}>
                    Mark as Transferable
                  </DialogTitle>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "end"
                  }}
                >
                  <LoadingButton
                    onClick={() => this.exportToCSVNTUnChecked(unCheckCountNTChargeDeducted)}
                    size="large"
                    endIcon={<SaveAlt />}
                    loading={unCheckCountNTChargeDeducted.length > 0 ? false : true}
                    disabled={unCheckCountNTChargeDeducted.length > 0 ? false : true}
                    loadingPosition="end"
                    sx={{
                      fontFamily: "Bevan, cursive !important",
                      marginBottom: "10px"
                    }}
                    variant="contained"
                  >
                    <span>Export to CSV</span>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
            <DialogContent sx={{ padding: " 10px 13px" }}>
              <DialogContentText
                sx={{
                  fontFamily: "Barlow",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "black",
                }}
              >
                {isLoadingUncheckNT ? (
                  <ContainerLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 900 }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            <div style={{ width: "100%", minWidth: "max-content", }}>
                              User(s)
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div style={{ width: "100%", minWidth: "max-content", }}>
                              Forward Only
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div style={{ width: "100%", minWidth: "max-content", }}>
                              Normal Charge
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <div style={{ width: "100%", minWidth: "max-content", }}>
                              Back Track
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {unCheckCountNTChargeDeducted.length > 0 ?
                          unCheckCountNTChargeDeducted.map((item, index) => {
                            return (
                              <StyledTableRow>
                                <StyledTableCell align="left">
                                  {item?.email ?? "-"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  ${item?.forward_only?.toFixed(2) ?? "-"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  ${item?.normal_charge?.toFixed(2) ?? "-"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  ${item?.back_track?.toFixed(2) ?? "-"}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          }) : (
                            <StyledTableRow>
                              <td
                                colSpan={13}
                                style={{ padding: "2.6rem 1rem" }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "1.6rem",
                                    textAlign: "center",
                                  }}
                                >
                                  Data not Found
                                </Typography>
                              </td>
                            </StyledTableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseUnchecked}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.handleConfirmationModalUnCkeckNT()}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Next
              </Button>
            </DialogActions>
            <UnCheckNTChildModal
              handleCloseConfirmationUnCkeckNT={this.handleCloseConfirmationUnCkeckNT}
              isOpenConfirmationUnckeckNT={this.state.isOpenConfirmationUnckeckNT}
              handleMarkNonTransferableAPI={this.handleMarkNonTransferableAPI}
            />
          </Dialog>
          {/* alert  message */}
          <AlertMessage
            open={this.state.messageopen}
            message={this.state.message}
            severity={this.state.messagetype}
            closeCall={this.handleClosemessage}
          />
        </div >
      </>
    );
  }
}

export default Inventorylist;
