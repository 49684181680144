import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import AlertMessage from "../alert/alert";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

const Label = (props) => (
  <Typography
    variant="body2"
    color="textSecondary"
    {...props}
    sx={{
      textAlign: "left",
      marginBottom: "10px",
    }}
  />
);

class EditCommunicationUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      c_password: "",
      showpassword: "hidepassword",
      error: [],
      loader: true,
      open: false,
      message: "",
      successError: "",
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    const data = { user_id: this.props.match.params.id };
    const userView_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/userDetail";
    axios
      .post(userView_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            name: data.data.name,
            email: data.data.email,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });

    if (!ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
        if (value !== this.state.password) {
          return false;
        }
        return true;
      });
    }
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }
  }
  componentWillUnmount() {
    if (ValidatorForm.hasValidationRule("isPasswordMatch")) {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    }
    if (ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.removeValidationRule("PasswordStrength");
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = async (e) => {
    this.setState({ loader: true }, () => {
      const data = {
        user_id: this.props.match.params.id,
        name: this.state.name,
        email: this.state.email,
        password:
          this.state.showpassword === "changepassword"
            ? this.state.password
            : "",
      };

      const eddUser_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/updateUser";
      axios
        .post(eddUser_api_url, data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;

          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
            this.props.history.push("/communication-users");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { name, email, password, c_password, loader } = this.state;
    return (
      <>
        <Helmet>
          <title>Update Communication Employee</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title=" Update Communication Employee"
                action={
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, lg: 1 }}
                    columns={{ xs: 4, sm: 8, md: 8 }}
                    className="button-align"
                  >
                    <Grid item>
                      <Button
                        onClick={() => this.props.history.push("/communication-users")}
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow!important",
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <CardContent>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <ValidatorForm onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Label>Name</Label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="name"
                          value={name}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label>Email address</Label>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="email"
                          value={email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "This field is required",
                            "email is not valid",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          sx={{
                            float: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <RadioGroup
                            aria-label="deliverymsg_time"
                            name="showpassword"
                            value={this.state.showpassword}
                            className="scan-condition-opts"
                            onChange={this.handleChange}
                          >
                            <FormControlLabel
                              value="changepassword"
                              control={<Radio color="primary" />}
                              label="Change Password"
                            />
                            <FormControlLabel
                              value="hidepassword"
                              control={<Radio color="primary" />}
                              label="Hide Password"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {this.state.showpassword === "changepassword" && (
                        <>
                          <Grid item xs={12}>
                            <Label>Password</Label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="password"
                              name="password"
                              value={password}
                              validators={["required", "PasswordStrength"]}
                              errorMessages={[
                                "This field is required",
                                "password must be at least 6 character",
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Label>Confirm Password</Label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              onChange={this.handleChange}
                              type="password"
                              name="c_password"
                              value={c_password}
                              validators={["required", "isPasswordMatch"]}
                              errorMessages={[
                                "This field is required",
                                "password mismatch",
                              ]}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={4} style={{ margin: "0 auto" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          size="large"
                          sx={{ paddingY: "1rem" }}
                          className="theme-btn-submit"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                )}
                <AlertMessage
                  open={this.state.open}
                  message={this.state.message}
                  severity={this.state.successError}
                  closeCall={this.handleClose}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default EditCommunicationUser;
