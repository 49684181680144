import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { Col, Modal } from "react-bootstrap";
import { CCard, CCardBody, CCol, CDataTable, CRow } from "@coreui/react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import customDateFormat from "utils/customDateFormat";
import customDateFormatLocal from "utils/customDateFormatLocal";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import PageLoader from "components/PageLoader/PageLoader";
import "./userdashboard.scss";
import "react-datepicker/dist/react-datepicker.css";
import UserProfileAction from "redux/actions/UserProfielAction";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Paper,
  TableContainer,
} from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class HistoryAnonymousTransfer extends React.Component {
  state = {
    totalCountries: "",
    problemeticorder: null,
    soldList: [],
    loader: false,
    allCountries: [],
    currentCountries: [],
    currentPage: 1,
    totalPages: 200,
    pageLimit: 20,
    total: "",
    index: 0,
    event: "",
    section: "",
    row: "",
    seat: "",
    dateVal: "",
    startDate: null,
    endDate: null,
    crossCheck: true,
    endcrossCheck: true,
    modalShow: false,
    userData: "",
    email: "",
    phone: "",
    modelError: "",
    modelSuccess: "",
    id: "",
    ticketurl: null,
    twiliomessages: [],
    tmModel: false,
    timezone: null,
    pos: [],
  };

  async componentDidMount() {
    this.setState({ loader: true }, () => {
      this.getTimezone();
    });
  }

  getTimezone = () => {
    if (this.props.UserProfile.data !== undefined) {
      this.setState(
        {
          pageLimit: this.props.UserProfile.data.per_page_limit,
          timezone: this.props.UserProfile.data.timezone,
        },
        () => {
          this.getLists();
        }
      );
    }
  };

  getLists = () => {
    const params = {
      index: this.state.index,
      totalRecord: 10,
      event_name: this.state.event,
      section: this.state.section,
      row: this.state.row,
      seat: this.state.seat,
      startDate:
        this.state.startDate != null
          ? Moment(this.state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.endDate != null
          ? Moment(this.state.endDate).format("YYYY-MM-DD")
          : "",
    };

    const inventoryList_api_url =
      process.env.REACT_APP_API_URL + "/api/inventory/getHistoryOfUsage";
    axios
      .post(inventoryList_api_url, params, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            soldList: data.data.filter(
              (sl) => sl.ticketurl && sl.soldMessageSent
            ),
            totalCountries: data.data.filter(
              (sl) => sl.ticketurl && sl.soldMessageSent
            ).length,
            problemeticorder: data.data.filter(
              (sl) =>
                sl.delivery_status &&
                (sl.delivery_status === 5 ||
                  sl.delivery_status === 7 ||
                  sl.delivery_status === 8 ||
                  sl.delivery_status === 9 ||
                  sl.delivery_status === 10)
            ).length,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  updateProfile() {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(
          updateDetail_api_url,
          { per_page_limit: this.state.pageLimit },
          {
            headers: {
              authorization: "Bearer " + Cookies.get("accessToken"),
            },
          }
        )
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  }

  onPageChanged = async (data) => {
    const { currentPage, pageLimit } = data;

    this.setState(
      { loader: true, index: (currentPage - 1) * pageLimit },
      () => {
        this.getLists();
      }
    );
  };

  handleChangeEvent = (data) => {
    this.setState({ event: data.target.value });
  };
  handleSearchSection = (data) => {
    this.setState({ section: data.target.value });
  };
  handleSearchRow = (data) => {
    this.setState({ row: data.target.value });
  };
  handleSeat = (data) => {
    this.setState({ seat: data.target.value });
  };
  handleChangeDate = (data) => {
    this.setState({ dateVal: data.target.value });
  };
  setStartDate = async (dates) => {
    const [start, end] = dates;

    this.setState({ startDate: start, endDate: end, crossCheck: false });
  };
  clearDate = () => {
    if (this.state.startDate != null) {
      this.setState({ startDate: null, endDate: null, crossCheck: true });
    } else {
      this.setState({ crossCheck: true });
    }
  };
  handleModelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  openModal = (data) => {
    this.setState({
      modalShow: true,
      userData: data,
      modelSuccess: "",
      modalError: "",
      email: typeof data !== "undefined" ? data.customerEmail : "",
      phone: typeof data !== "undefined" ? data.customerPhone : "",
      id: typeof data !== "undefined" ? data.id : "",
      ticketurl: data.ticketurl ? data.ticketurl : null,
    });
  };
  handleSubmitModal = async (data) => {
    const param = {
      id: this.state.id,
      customer_email: this.state.email,
      customer_phone: this.state.phone,
      ticketurl: this.state.ticketurl,
    };
    const updateSoldInventory_api_url =
      process.env.REACT_APP_API_URL +
      "/api/inventory/updateSoldInventoryCustomerInfo";
    await axios
      .post(updateSoldInventory_api_url, param, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: response.data.message,
            successError: "error",
            modelError: response.data.message,
          });
        } else {
          if (data.success) {
            this.getLists();
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };
  onHide = () => {
    this.setState({ modalShow: false });
  };
  handleSubmit = async () => {
    this.setState({ loader: true, totalCountries: "" }, () => {
      this.getLists();
    });
  };
  changePageLimit = (e) => {
    this.setState({ pageLimit: parseInt(e.target.value) }, () => {
      this.handleSubmit();
      this.updateProfile();
    });
  };
  opentmModel = (data) => {
    this.setState({ tmModel: true, twiliomessages: data.twiliomessages });
  };
  tmModelonHide = () => {
    this.setState({ tmModel: false, twiliomessages: [] });
  };

  render() {
    const { loader, soldList, pageLimit, problemeticorder } = this.state;

    const fields = [
      { label: "Date", key: "invoiceDate" },
      { label: "Team", key: "team" },
      { label: "Section", key: "section" },
      { label: "Row", key: "row" },
      { label: "Seat", key: "seatNumbers" },
      { label: "Marketplace", key: "customerDisplayName" },
      { label: "External Ref", key: "invoiceExternalRef" },
      { label: "Event", key: "eventName" },
      { label: "Event Date", key: "eventDate" },
      { label: "Status", key: "status" },
    ];
    const tablestyle = {
      backgroundColor: "#3858A0!important",
      color: "white",
      fontWeight: "bold",
      fontFamily: "Barlow",
      fontSize: "0.9rem",
    };
    const totaltickets = soldList.reduce(
      (accumulator, current) => accumulator + parseInt(current.quantity),
      0
    );

    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>History Of Anonymous Transfer</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  {loader && <PageLoader />}
                  {!loader && (
                    <CRow>
                      <CCol
                        xs="12"
                        lg="12"
                        md="12"
                        sm="12"
                        className="space-top-col"
                      >
                        <div className="notification-outer">
                          <CCard>
                            <CCardBody className="plan-tbl sold-invent-outer">
                              <div className="usage-tbl-header">
                                <h3>History Of Anonymous Transfer</h3>
                              </div>

                              <ValidatorForm
                                // ref="form"
                                className="filter-form mb-5"
                                onSubmit={this.handleSubmit}
                              >
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                      <TextField
                                        variant="outlined"
                                        onChange={this.handleChangeEvent}
                                        value={this.state.event}
                                        name="event_names"
                                        placeholder="Search Event"
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={3} lg={3}>
                                      <DatePicker
                                        placeholderText="Search Date"
                                        selected={this.state.startDate}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        monthsShown={this.state.monthsShown}
                                        selectsRange
                                        isClearable={true}
                                        customInput={
                                          <TextField
                                            variant="outlined"
                                            className="filter-input"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  className="date-icon"
                                                >
                                                  <div className="d-flex">
                                                    {this.state.crossCheck ? (
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="end"
                                                      >
                                                        <EventIcon className="text-dark" />
                                                      </IconButton>
                                                    ) : (
                                                      <IconButton
                                                        edge="end"
                                                        onClick={() =>
                                                          this.clearDate()
                                                        }
                                                      >
                                                        <ClearIcon className="text-dark" />
                                                      </IconButton>
                                                    )}
                                                  </div>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        }
                                        onChange={(dates) =>
                                          this.setStartDate(dates)
                                        }
                                      />
                                      {/* <DatePicker
                                                                    selectsRange
                                                                    selected={this.state.startDate}
                                                                    placeholderText="Select Date"
                                                                    startDate={this.state.startDate} 
                                                                    endDate={this.state.endDate}
                                                                    onChange={dates => this.setStartDate(dates)}
                                                                />
                                                                {this.state.crossCheck ? "" :<div className="cross-icon" onClick={this.clearDate}>x</div>}  */}
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                      <TextValidator
                                        variant="outlined"
                                        onChange={this.handleSearchSection}
                                        value={this.state.section}
                                        name="section"
                                        placeholder="Search Section"
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                      <TextValidator
                                        variant="outlined"
                                        onChange={this.handleSearchRow}
                                        value={this.state.row}
                                        name="row"
                                        placeholder="Search Row"
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                      <TextValidator
                                        variant="outlined"
                                        onChange={this.handleSeat}
                                        value={this.state.seat}
                                        name="seat"
                                        placeholder="Search Seat"
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                      <Button
                                        variant="contained"
                                        type="submit"
                                        className="filter-btn "
                                        startIcon={<HiOutlineSearch />}
                                      >
                                        Search
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </ValidatorForm>

                              <CDataTable
                                responsive
                                items={soldList}
                                fields={fields}
                                sorter
                                pagination
                                itemsPerPage={pageLimit}
                                hover
                                border
                                scopedSlots={{
                                  status: (itemrow) => {
                                    return (
                                      <td className="py-2 icon-outer-font">
                                        <div>
                                          {itemrow.confirmMessageSent ? (
                                            <div className="icon-space-left">
                                              Confirmation message sent
                                              <br />
                                            </div>
                                          ) : (
                                            <>
                                              {itemrow.deliveryMessageSent ? (
                                                <div className="icon-space-left">
                                                  Delivery message sent
                                                  <br />
                                                </div>
                                              ) : (
                                                <>
                                                  {itemrow.soldMessageSent ? (
                                                    <div className="icon-space-left">
                                                      Sold message sent
                                                      <br />
                                                    </div>
                                                  ) : (
                                                    <div className="icon-space-left">
                                                      No message sent
                                                      <br />
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {itemrow.twiliomessages.length >
                                            0 && (
                                            <div>
                                              <Button
                                                size="small"
                                                variant="contained"
                                                sx={{
                                                  backgroundColor:
                                                    "#002986!important",
                                                  color: "white!important",
                                                  padding: "2px 5px!important",
                                                  textTransform:
                                                    "none!important",
                                                  fontWeight: "bold",
                                                  fontFamily:
                                                    "Barlow!important",
                                                }}
                                                onClick={() =>
                                                  this.opentmModel(itemrow)
                                                }
                                              >
                                                View
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    );
                                  },
                                  team: (itemrow) => {
                                    return (
                                      <td>
                                        {itemrow.event !== "undefined"
                                          ? itemrow.event[0].performer?.name
                                          : ""}
                                      </td>
                                    );
                                  },
                                  eventName: (itemrow) => {
                                    return (
                                      <td>
                                        {itemrow.event !== "undefined"
                                          ? itemrow.event[0].name
                                          : ""}
                                      </td>
                                    );
                                  },
                                  eventDate: (itemrow) => {
                                    return (
                                      <td>
                                        {itemrow.event !== "undefined"
                                          ? customDateFormat(
                                              itemrow.event[0].date
                                            )
                                          : ""}
                                      </td>
                                    );
                                  },
                                  invoiceDate: (itemrow) => {
                                    return (
                                      <td>
                                        {Moment(
                                          itemrow.invoiceDate
                                            .split("T")
                                            .join("\n")
                                            .substr(
                                              0,
                                              itemrow.invoiceDate
                                                .split("T")
                                                .join("\n")
                                                .indexOf("\n")
                                            )
                                        ).format("MM/DD/YYYY") ===
                                        "Invalid date"
                                          ? Moment(itemrow.invoiceDate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : Moment(
                                              itemrow.invoiceDate
                                                .split("T")
                                                .join("\n")
                                                .substr(
                                                  0,
                                                  itemrow.invoiceDate
                                                    .split("T")
                                                    .join("\n")
                                                    .indexOf("\n")
                                                )
                                            ).format("MM/DD/YYYY")}
                                      </td>
                                    );
                                  },
                                }}
                              />

                              {/* {totalCountries > 0 &&
                                                    <Pagination totalRecords={totalCountries} pageLimit={this.state.pageLimit} onPageChanged={this.onPageChanged}/>
                                                } */}
                              <div style={{ textAlign: "right" }}>
                                <label>Items per page: </label>
                                <select
                                  placeholder="items per page"
                                  defaultValue={this.state.pageLimit}
                                  class="form-select form-select-sm"
                                  onChange={(e) => this.changePageLimit(e)}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="50">50</option>
                                </select>
                              </div>

                              <div
                                style={{ float: "right", textAlign: "right" }}
                              >
                                <strong>Total Tickets: {totaltickets}</strong>
                                <br />
                                <strong>
                                  Problemetic Orders: {problemeticorder}
                                </strong>
                              </div>

                              <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={this.state.modalShow}
                                className="Modal_Box custom-pop-over"
                                onHide={this.onHide}
                              >
                                <Modal.Body>
                                  {console.log(
                                    "userDatasssssssss",
                                    typeof this.state.userData !== "undefined"
                                      ? this.state.userData.customerData !==
                                        "undefined"
                                        ? this.state.userData.customerData
                                          ? this.state.userData.customerData
                                              .email
                                            ? "Helo"
                                            : ""
                                          : ""
                                        : ""
                                      : ""
                                  )}
                                  <div className="user-info">
                                    <div
                                      style={{ color: "red", fontSize: "16px" }}
                                    >
                                      {this.state.modelError}
                                    </div>
                                    <div
                                      style={{
                                        color: "green",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {this.state.modelSuccess}
                                    </div>

                                    <ValidatorForm
                                      // ref="form"
                                      onSubmit={this.handleSubmitModal}
                                    >
                                      <div className="custom-pop-over-row">
                                        <div className="form-group login-wrapper">
                                          <label>Ticket: </label>
                                          <TextValidator
                                            variant="outlined"
                                            onChange={this.handleModelChange}
                                            name="ticketurl"
                                            value={this.state.ticketurl}
                                          />
                                          {this.state.ticketurl && (
                                            <a
                                              href={this.state.ticketurl}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              click to view ticket
                                            </a>
                                          )}
                                        </div>
                                      </div>

                                      <div className="custom-pop-over-row">
                                        <div className="form-group login-wrapper">
                                          <label>Email</label>
                                          <TextValidator
                                            variant="outlined"
                                            onChange={this.handleModelChange}
                                            name="email"
                                            value={this.state.email}
                                          />
                                        </div>
                                      </div>

                                      <div className="custom-pop-over-row">
                                        <div className="form-group login-wrapper">
                                          <label>Phone</label>
                                          <TextValidator
                                            variant="outlined"
                                            onChange={this.handleModelChange}
                                            name="phone"
                                            value={this.state.phone}
                                          />
                                        </div>
                                      </div>
                                      <CRow className="custom-pop-over-row">
                                        <Col md="6">
                                          <Button
                                            variant="contained"
                                            type="submit"
                                            // disabled={submitted}
                                            className="full-width login-btn"
                                          >
                                            Update
                                          </Button>
                                        </Col>
                                        <Col md="6">
                                          <div
                                            onClick={this.onHide}
                                            className="cancelbillingModal"
                                          >
                                            Cancel
                                          </div>
                                        </Col>
                                      </CRow>
                                      <div className="row"></div>
                                    </ValidatorForm>
                                  </div>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                                        <div onClick={this.onHide} className="cancelbillingModal">Cancel</div> */}
                                {/* <div onClick={()=>this.onUpdate(this.state.userData)} className="cancelbillingModal">Update</div> */}
                                {/* </Modal.Footer> */}
                              </Modal>

                              <Dialog
                                maxWidth="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                open={this.state.tmModel}
                                scroll="body"
                                onClose={this.tmModelonHide}
                              >
                                <DialogContent>
                                  <IconButton
                                    aria-label="close"
                                    onClick={() => this.tmModelonHide()}
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,

                                      color: (theme) => theme.palette.grey[500],
                                      p: 1,
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                  {this.state.twiliomessages.length > 0 ? (
                                    <TableContainer
                                      component={Paper}
                                      sx={{
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <Table
                                        stickyHeader
                                        sx={{ minWidth: 700 }}
                                        aria-label="customized table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <StyledTableCell sx={tablestyle}>
                                              Status
                                            </StyledTableCell>
                                            <StyledTableCell
                                              sx={{
                                                backgroundColor:
                                                  "#3858A0!important",
                                                color: "white",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow",
                                                fontSize: "0.9rem",
                                                width: "fit-content",
                                              }}
                                              align="left"
                                            >
                                              Phone Number
                                            </StyledTableCell>
                                            <StyledTableCell
                                              sx={tablestyle}
                                              align="left"
                                            >
                                              Message
                                            </StyledTableCell>
                                            <StyledTableCell
                                              sx={tablestyle}
                                              align="left"
                                            >
                                              Sent Date
                                            </StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.twiliomessages
                                            .sort(
                                              (a, b) =>
                                                new Date(b.sentat) -
                                                new Date(a.sentat)
                                            )
                                            .map((tm) => (
                                              <StyledTableRow key={tm}>
                                                <StyledTableCell
                                                  component="th"
                                                  align="right"
                                                >
                                                  {tm.sms_type
                                                    ? tm.sms_type
                                                    : "delivered"}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  {tm.sms_type === "received"
                                                    ? tm.sentby
                                                    : tm.phone_number}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  {tm.message_body}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  {customDateFormatLocal(
                                                    tm.sentat,
                                                    this.state.timezone
                                                  )}
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  ) : (
                                    <p>No message yet!</p>
                                  )}
                                </DialogContent>
                              </Dialog>
                            </CCardBody>
                          </CCard>
                        </div>
                      </CCol>
                    </CRow>
                  )}
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryAnonymousTransfer);
