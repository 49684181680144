import React from "react";
import dayjs from "dayjs";
import AlertMessage from "components/message/message";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

class AddEvent extends React.Component {
  state = {
    loader: false,
    open: false,
    message: "",
    successError: "",
    formdata: {
      eventName: "",
      eventDate: dayjs(new Date()).format("YYYY-MM-DD"),
      location: "",
      orderId: "",
      gameId: "",
    },
  };

  handleAddEvent = () => {
    this.setState({
      loader: true,
    });

    const addEventapi =
      process.env.REACT_APP_API_URL + "/api/account/addVbdAccountEvents";

    const { formdata } = this.state;
    const data = {
      account_id: this.props.paramsId,
      game_id: formdata.gameId,
      event_date: dayjs(formdata.eventDate).format("YYYY-MM-DD||HH:mm:ss"),
      event_name: formdata.eventName,
      location: formdata.location,
      order_id: formdata.orderId,
    };

    axios
      .post(addEventapi, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        this.setState({
          loader: false,
        });
        if (response.data.error) {
          this.setState({
            open: true,
            message: response.data.message,
            successError: "error",
            loader: false,
          });
        } else if (response.data.success) {
          this.setState({
            open: true,
            message: response.data.message,
            successError: "success",
            loader: false,
            formdata: {
              eventName: "",
              eventDate: dayjs(new Date()).format("YYYY-MM-DD"),
              location: "",
              orderId: "",
              gameId: "",
            },
          });
          setTimeout(() => {
            this.props.history.goBack();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };

  handleChange = (e) => {
    const { formdata } = this.state;
    formdata[e.target.name] = e.target.value;
    this.setState({ formdata });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount() {
    // add valiation if the value is empty space or not
    ValidatorForm.addValidationRule("isWhitespace", (value) => {
      if (value.trim().length > 0) {
        return true;
      }
      return false;
    });
  }




  render() {
    const inputStyle = { textAlign: "left", paddingTop: "20px " };

    const { loader } = this.state;
    const labelStyle = {
      fontWeight: "500",
      fontFamily: "Barlow",
      color: "#002986",
    };
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {!loader ? (
            <Grid container spacing={2} sx={{ marginTop: '16px' }}>
              <Grid item={true} xs={12} className="space-top-col">
                <div className="add-account-wrapper-add-event">
                  <Grid container spacing={3}>
                    <Grid item={true} xs={12} className="space-top-col">
                      <Card
                        sx={{
                          width: "100%",
                          boxShadow:
                            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                          borderRadius: "4px",
                          height: "100%",
                          padding: "20px",
                        }}
                      >
                        <CardHeader
                          sx={{ padding: "7px 9px 1px 1px" }}
                          action={<Button
                            size="large"
                            variant="contained"
                            sx={{
                              backgroundColor: "#002986!important",
                              color: "white!important",
                              textTransform: "none!important",
                              fontWeight: "bold",
                              fontFamily: "Barlow!important",
                            }}
                            type="button"
                            startIcon={<KeyboardBackspaceIcon />}
                            onClick={this.props.handleAddEventPageUnVisiable}
                          >
                            Back
                          </Button>}
                          title={<Typography
                            sx={{
                              fontSize: "24px",
                              fontWeight: "700",
                              color: "#000059",
                              textAlign: "left",
                            }}
                          >
                            Add Event
                          </Typography>}
                        />
                        <div className="usage-tbl-header add-account-title">
                          <AlertMessage
                            open={this.state.open}
                            message={this.state.message}
                            severity={this.state.successError}
                            closeCall={this.handleClose}
                          />
                        </div>
                        <Grid container spacing={3}>
                          <Grid item={true} xs={12}>
                            <ValidatorForm
                              onSubmit={() => this.handleAddEvent()}
                            >
                              <Grid container spacing={2}>
                                <Grid item={true} xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>Name</label>

                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      name="eventName"
                                      value={
                                        this.state.formdata.eventName
                                      }
                                      validators={[
                                        "required",
                                        "isWhitespace",
                                      ]}
                                      placeholder="Enter Name"
                                      onChange={this.handleChange}
                                      errorMessages={[
                                        "Name is required",
                                        "Name is required",
                                      ]}
                                    />
                                  </div>
                                </Grid>

                                <Grid item={true} xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>Date</label>

                                    <MobileDateTimePicker
                                      inputFormat="MM/DD/YYYY hh:mm A"
                                      value={
                                        this.state.formdata.eventDate
                                      }
                                      onChange={(newValue) => {
                                        console.log(newValue);
                                        this.setState({
                                          formdata: {
                                            ...this.state.formdata,
                                            eventDate: newValue.$d,
                                          },
                                        });
                                      }}
                                      defaultValue={new Date()}
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          fullWidth
                                          variant="outlined"
                                          placeholder="MM/DD/YYYY  HH:MM AM/PM"
                                          validators={["required"]}
                                          value={
                                            this.state.formdata.eventDate
                                          }
                                          errorMessages={[
                                            "Date is required",
                                          ]}
                                        />
                                      )}
                                    />
                                    {/* <DatePicker
                                          inputFormat="MM/DD/YYYY"
                                          value={this.state.formdata.eventDate}
                                          onChange={(newValue) => {
                                            this.setState({
                                              formdata: {
                                                ...this.state.formdata,
                                                eventDate: newValue,
                                              },
                                              isOpen: false,
                                              
                                            });
                                          }}
                                          renderInput={(params) => (
                                            <TextValidator
                                              {...params}
                                              fullWidth
                                              variant="outlined"
                                              placeholder="MM/DD/YYYY"
                                              validators={["required"]}
                                              value = {this.state.formdata.eventDate}
                                              errorMessages={[
                                                "Date is required",
                                              ]}
                                            />
                                          )}
                                        /> */}
                                  </div>
                                </Grid>

                                <Grid item={true} xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>
                                      Location
                                    </label>
                                    <TextValidator
                                      name="location"
                                      value={this.state.formdata.location}
                                      validators={[
                                        "required",
                                        "isWhitespace",
                                      ]}
                                      placeholder="Enter Location"
                                      onChange={this.handleChange}
                                      errorMessages={[
                                        "Location is required",
                                        "Location is required",
                                      ]}
                                      fullWidth
                                    />
                                  </div>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>
                                      Order Id
                                    </label>
                                    <TextValidator
                                      name="orderId"
                                      value={this.state.formdata.orderId}
                                      placeholder="Enter Order Id"
                                      onChange={this.handleChange}
                                      fullWidth
                                    />
                                  </div>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                  <div style={inputStyle}>
                                    <label style={labelStyle}>
                                      Game Id
                                    </label>
                                    <TextValidator
                                      name="gameId"
                                      value={this.state.formdata.gameId}
                                      validators={[
                                        "required",
                                        "isWhitespace",
                                      ]}
                                      placeholder="Xx00xxX00X0xx0XX0xx"
                                      onChange={this.handleChange}
                                      errorMessages={[
                                        " Game Id is required",
                                        " Game Id is required",
                                      ]}
                                      fullWidth
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item={true} xs={12} sm={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    size="large"
                                    className="theme-btn"
                                    sx={{
                                      margin: "17px auto",
                                      fontSize: "1rem",
                                      textTransform:
                                        "capitalize!important",
                                      letterSpacing: "0.5px",
                                      "&:hover": {
                                        textTransform:
                                          "capitalize!important",
                                      },
                                    }}
                                  >
                                    Add Event
                                  </Button>
                                </Grid>
                              </Grid>
                            </ValidatorForm>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ) : (
            <ContainerLoader />
          )}
        </LocalizationProvider>
      </>
    );
  }
}
export default AddEvent;
