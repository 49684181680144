import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer'
import '../welcome/welcome.css';

class Cancel extends React.Component {
    componentDidMount() {
      
    }
    render() {
        return (
            <div>
                <Header />
                <Container>
                    <div className="welcome-wrapper welcome-center">
                        <Row style={{ marginTop: '80px', marginBottom: '20px' }}>
                            <Col lg="12" md="12">
                                <h1 className="">Something went wrong</h1>
                            </Col>
                        </Row>
                    </div>

                </Container>


                <Footer className="welcome-footer" />
            </div>

        )
    }
}
export default Cancel;