import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import axios from 'axios';
import AlertMessage from '../alert/alert';
import { Row, Col, } from 'react-bootstrap';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
} from '@coreui/react'
import '../common.css';
import { Helmet } from "react-helmet";
import { Box, CircularProgress } from '@mui/material';
// import { Helmet, HelmetProvider } from 'react-helmet-async';

class ChangeComPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            invalidTokenBtn: true,
            o_password: '',
            password: '',
            c_password: '',
            error: [],
            loader:false,
            open:false,
            message:'',
            successError:'',
        }

    }
   
    componentDidMount() {
        if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
                if (value !== this.state.password) {
                    return false;
                }
                return true;
            });
        }
        if (!ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.addValidationRule('PasswordStrength', (value) => {
                // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
                var re = /.{6,}/;
                // @".{6,}"
                return re.test(value);
            });
        }
    }
    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
        if (ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.removeValidationRule('PasswordStrength');
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit =async () => {
        this.setState({
            loader: true
        })
        const data = {
            old_password: this.state.o_password,
            new_password: this.state.password
        }
        const changeUserPwd_api_url = process.env.REACT_APP_API_URL + "/api/commpanel/changePassword"
        await axios.post(changeUserPwd_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenCommunication') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open:true,
                        message:data.message,
                        successError:'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        open:true,
                        message:response.data.message,
                        successError:'success',
                    })  
                    this.setState({
                        o_password:'',
                        password:'',
                        c_password:''
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open:true,
                    message:"Something went wrong",
                    successError:'error',
                })
            })

    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    }; 
    render(){
        const {  o_password, c_password, password, loader } = this.state
        if(!loader){return(
            <>
                    <Helmet>
                        <title>
                            Change Password
                        </title>
                    </Helmet>
                            <CRow>
                        <CCol xs="12" sm="12">
                            <CCard>
                                <CCardHeader>
                                <h3>Change Password</h3>
                                </CCardHeader>
                                <CCardBody>
                                <ValidatorForm
                                    // ref="form"
                                    onSubmit={this.handleSubmit}
                                >
                                                        <Row>
                                                                    <Col md={12}>
                                                                        <div className="form-group login-wrapper">
                                                                            <label>Old password</label>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                onChange={this.handleChange}
                                                                                name="o_password"
                                                                                type="password"
                                                                                value={o_password}
                                                                                validators={['required']}
                                                                                errorMessages={('this field is required')}
                                                                            />
                                                                            {this.state.error.o_password &&
                                                                                <div className="error-msg">{this.state.error.o_password}</div>
                                                                            }
                                                                        </div>
                                                                     </Col>

                                                                    <Col md={12}>
                                                                        <div className="form-group login-wrapper">
                                                                            <label>New Password</label>
                                                                            <TextValidator
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                onChange={this.handleChange}
                                                                                name="password"
                                                                                type="password"
                                                                                value={password}
                                                                                validators={['required', 'PasswordStrength']}
                                                                                errorMessages={[('this field is required'), ('password must be at least 6 character')]}
                                                                            />
                                                                            {this.state.error.password && 
                                                                            <div className="error-msg">{this.state.error.password}</div>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div className="form-group login-wrapper">
                                                                            <label>Confirm New Password</label>
                                                                                <TextValidator
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    onChange={this.handleChange}
                                                                                    name="c_password"
                                                                                    type="password"
                                                                                    value={c_password}
                                                                                    validators={['isPasswordMatch', 'required']}
                                                                                    errorMessages={[('password mismatch'), ('this field is required')]}
                                                                                />
                                                                                {this.state.error.c_password && 
                                                                        <div className="error-msg">{this.state.error.c_password}</div>
                                                                        }
                                                                        </div>
                                                                    </Col>
                                                     
                                                                    <Col sm={12}>
                                                                        <Button 
                                                                            variant="contained"
                                                                            type="submit"
                                                                            className="full-width login-btn update-btn"
                                                                        >
                                                                            Change password
                                                                    
                                                                        </Button>
                                                                        
                                                                    </Col>
                                                        </Row>
                                </ValidatorForm>
                                   <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose} />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
            </>
        )}
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }  
}
export default ChangeComPassword;