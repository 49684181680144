import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CustomDataTable from "utils/customDataTable";
import { HiOutlineSearch } from "react-icons/hi";
import CustomPagination from "utils/customPagination";
import AlertMessage from "components/message/message";

const columns = [
  { field: "user", headerName: "User" },
  { field: "username", headerName: "Username" },
];

class VbdAccounts extends React.Component {
  state = {
    usersData: [],
    userList: [],
    modalShow: false,
    deleteId: "",
    deleting: false,
    index: "",
    newUserData: [],
    loader: false,
    totalRecords: 10,
    pageLimit: 10,
    searchPage: 0,
    vendorVal: null,
    userListfilter: null,
    filterData: {
      skip: 0,
      limit: 10,
      user: null
    },
  };

  componentDidMount() {
    this.getallrecords();
    this.getAllUser()
  }

  async getallrecords() {
    this.setState({ loader: true });
    const packageList_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/listAttachVbdUser";
    await axios
      .post(packageList_api_url, this.state.filterData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
            usersData: [],
            userList: [],
            totalRecords: 0 
          });
        } else {
          this.setState({
            loader: false,
            usersData: data.data,
            userList: data.data,
            totalRecords: data.count ?? 0, // Assuming the API returns totalRecords
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
          usersData: [],
          userList: [],
          totalRecords: 0 
        });
      });
  }

  handleDelete = (item, index) => {
    this.setModalShow(item._id, index);
  };

  handleView = (item, index) => {
    this.props.history.push(`/vbdaccounts/edit/${item._id}`);
  };

  actions = [
    { label: "Edit", callback: this.handleView },
    { label: "Delete", callback: this.handleDelete },
  ];

  setModalShow = (id, indexId) => {
    this.setState({
      modalShow: true,
      deleteId: id,
      index: indexId,
    });
  };

  onHide = () => {
    this.setState({
      modalShow: false,
    });
  };

  callDelete = async () => {
    const data = {
      _id: this.state.deleteId,
    };
    this.setState({
      deleting: true,
    });
    const packageDelete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteAttachVbdUser";
    await axios
      .post(packageDelete_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            successError: "error",
          });
          this.setState({
            modalShow: false,
            deleting: false,
          });
        } else {
          if (data.success) {
            this.getallrecords();
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              successError: "error",
            });
          }
          this.setState({
            modalShow: false,
            deleting: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
          modalShow: false,
          deleting: false,
        });
      });
  };

  getAllUser = () => {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response.data;
        if (data) {
          this.setState({ userListfilter: data, })
        }
      })
      .catch((error) => {
        this.setState({
          message: "Something went wrong while accessing users list",
          alertseverity: "error",
          openalert: true,
        });
      });
  }

  onPageChanged = (data) => {
    const { currentPage, pageLimit } = data;
    const { filterData } = this.state;
    filterData.skip = (currentPage - 1) * pageLimit;
    this.setState({ filterData }, () => {
      this.getallrecords()
    });
  }

  SearchDisable = (value) => {
    this.setState({ searchPage: value });
  };

  changePageLimit = (e) => {
    const { filterData } = this.state;
    filterData.limit = parseInt(e.target.value);
    filterData.skip = 0;
    this.setState(
      {
        pageLimit: parseInt(e.target.value),
        searchPage: 1,
        loader: true,
        filterData,
      },
      () => {
        this.getallrecords();
      }
    );
  };

  handleSelectUser = (e, val) => {
    if (val) {
      this.setState({ vendorVal: val });
      const { filterData } = this.state;
      filterData["user"] = val._id;
      this.setState({ filterData });
    } else {
      this.setState({ vendorVal: null });
      const { filterData } = this.state;
      filterData["user"] = null;
      this.setState({ filterData });
    }
  }

  handleSearch = () => {
    this.getallrecords();
  };

  handleClose = (event, reason) => {
    this.setState({
      openalert: false
    })
  };

  render() {
    const { modalShow, loader, usersData, userListfilter, totalRecords } = this.state;
    return (
      <>
        <Helmet>
          <title>VBD Accounts</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ padding: "30px" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "100%",
                        maxWidth: "265px",
                        color: "#000059!important",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "1.6rem",
                        fontFamily: "Barlow!important",
                      }}
                    >
                      {" "}
                      VBD Account
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: {
                          xs: "flex-start",
                          sm: "flex-start",
                          md: "flex-end",
                          lg: "flex-end",
                          xl: "flex-end",
                        },
                      }}
                    >
                      <Grid item>
                        <Button
                          component={Link}
                          size="large"
                          variant="contained"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            textTransform: "none!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow!important",
                          }}
                          to="/vbdaccounts/add"
                        >
                          Attach VBD Account to User
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          component={Link}
                          size="large"
                          variant="contained"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            textTransform: "none!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow!important",
                          }}
                          to="/vbdaccounts/accounts"
                        >
                          VBD Accounts
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={12} className="flex vbd-btn-filter align-items-center px-3">
                    <Grid item xs={12} sm={12} md={8} lg={4} sx={{ paddingTop: "44px !important" }}>
                      <div>
                        <label style={{ color: "#000059" }}>Users</label>
                        <Autocomplete
                          fullWidth
                          className="filter-input"
                          name="vendorVal"
                          value={this.state.vendorVal}
                          options={userListfilter ?? []}
                          onChange={this.handleSelectUser}
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => (
                            <TextField
                              value={this.state.vendorVal}
                              placeholder="Users"
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} className="filter-btn-grid" sx={{ position: 'relative', top: '36px', left: "12px" }}>
                      <Button
                        sx={{ padding: "14px 26px !important" }}
                        variant="contained"
                        type="submit"
                        className="filter-btn"
                        startIcon={<HiOutlineSearch />}
                        onClick={this.handleSearch}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomDataTable
                      columns={columns}
                      isLoading={loader}
                      data={
                        usersData
                          ? usersData.map((item) => {
                            return {
                              ...item,
                              user: item?.user?.email,
                              username: item?.vbdaccount?.username,
                            };
                          })
                          : []
                      }
                      actions={this.actions}
                    />
                  </Grid>
                  {usersData && usersData.length > 0 ?
                    <>
                      <Grid item xs={12}>
                        {totalRecords > 0 && (
                          <CustomPagination
                            totalRecords={totalRecords}
                            searchPage={this.state.searchPage}
                            SearchDisable={this.SearchDisable}
                            pageLimit={this.state.filterData.pageLimit}
                            onPageChanged={this.onPageChanged}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <label>Items per page :</label>
                          <Select
                            defaultValue={this.state.pageLimit}
                            value={this.state.pageLimit}
                            name="limit"
                            sx={{
                              "& .MuiSelect-select": {
                                padding: "4px 14px",
                                fontFamily: "Barlow",
                              },
                            }}
                            onChange={this.changePageLimit}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                          </Select>
                        </div>
                      </Grid>
                    </> : null}
                </Grid>
                <Dialog
                  fullWidth={true}
                  scroll="body"
                  maxWidth="sm"
                  open={modalShow}
                  onClose={this.onHide}
                >
                  <DialogTitle>Delete</DialogTitle>
                  <DialogContent>
                    <IconButton
                      aria-label="close"
                      onClick={() => this.onHide()}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                    <DialogContentText>
                      Are you sure you want to Delete, this action can not be
                      reverted ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.onHide}
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                      }}
                    >
                      Cancel
                    </Button>
                    {this.state.deleting ? (
                      <Button
                        sx={{
                          backgroundColor: "#7a87a4!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                        disabled={true}
                      >
                        Deleting...
                      </Button>
                    ) : (
                      <Button
                        onClick={this.callDelete}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* alert message  */}
        <AlertMessage
          open={this.state.openalert}
          message={this.state.message}
          severity={this.state.alertseverity}
          closeCall={this.handleClose}
        />
      </>
    );
  }
}

export default VbdAccounts;