import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Link } from "react-router-dom";

// mui components
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Button,
    Card,
    CardContent,
    Grid,
    TableHead,
    Typography,
    Autocomplete,
    Box,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { HiOutlineSearch } from "react-icons/hi";

// components
import AlertMessage from "components/message/message";
import { ContainerLoader } from "components/common/ContainerLoader";
import Pagination from "components/pagination/pagination";
import { useHistory } from "react-router-dom";

// custom style  for data table of the Table Cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};
const AdminLicensesLogs = () => {
    const [message, setMessage] = useState({
        alertseverity: '',
        message: '',
        openalert: false
    });
    const [filterData, setFilterData] = useState({
        user: null,
        skip: 0,
        limit: 10,
        licenseKey: '',
        status: ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const [licensesLogs, setLicensesLogs] = useState([]);
    const [usersList, setUsersList] = useState([]);
    //pagination sate
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [searchPage, setSearchPage] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    const [queryStringRemovedByUser, setQueryStringRemovedByUser] = useState(false);
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const encodedParams = urlParams.get("k")

    const getAllLicensesLogs = async (pageskip = 0, limitpage = 10) => {
        setPageLimit(limitpage);
        setSearchPage(1);
        const params = {
            skip: pageskip,
            limit: limitpage,
            userId: filterData?.user?.id ?? "",
            licenseKey: filterData?.licenseKey ? filterData?.licenseKey ?? "" : encodedParams ?? "",
            status: filterData?.status ?? ""
        }
        try {
            setIsLoading(true);
            const license_log_api = process.env.REACT_APP_API_URL + "/api/v2/admin/getLicenseLogsAdmin";
            const response = await axios.post(license_log_api, params, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                });
                setIsLoading(false);
            } else {
                setLicensesLogs(data?.data ?? [])
                setTotalRecords(data?.total ?? 10)
                setIsLoading(false);
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            });
            setIsLoading(false);
        }
    }

    const getUsersList = async () => {
        try {
            const get_userlist_api = process.env.REACT_APP_API_URL + "/api/user/getAllUsers?accessbrowser=yes";
            const response = await axios.post(get_userlist_api, {}, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
            } else {
                const newdata = data?.data?.map((val) => {
                    return {
                        email: val.email,
                        id: val?._id,
                    };
                });
                const seen = new Set();
                const filteredArr = newdata?.filter((el) => {
                    const duplicate = seen.has(el?.email);
                    seen.add(el.email);
                    return !duplicate;
                });
                setUsersList(filteredArr)
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            });
        }
    }

    const handleChangeVendorFilterInput = (newVal) => {
        if (newVal) {
            setFilterData({ ...filterData, user: newVal });
        } else {
            setFilterData({ ...filterData, user: null });
        }
    };

    const handleOnChangeLicenseKey = (e) => {
        const value = e?.target?.value?.trim();
        history.replace(window.location.pathname);
        if (value) {
            setFilterData({ ...filterData, licenseKey: value });
        } else {
            setFilterData({ ...filterData, licenseKey: "" });
        }
    }

    const changePageLimit = (e) => {
        setSearchPage(1);
        setPageLimit(parseInt(e.target.value));
        getAllLicensesLogs(0, parseInt(e.target.value));
        setSearchPage(1);
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setSkip((currentPage - 1) * pageLimit);
        getAllLicensesLogs((currentPage - 1) * pageLimit, pageLimit);
    };

    const SearchDisable = (value) => {
        setSearchPage(value);
    };

    const handleSubmitFilter = async () => {
        await getAllLicensesLogs(skip, pageLimit);
    };

    const handleClose = () => {
        setMessage({
            ...message,
            openalert: true,
        });
    };

    const handleOnChnagestatus = (e) => {
        setFilterData({
            ...filterData,
            status: e?.target?.value
        })
    }

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (action === 'POP' && !queryStringRemovedByUser) {
                history.replace(location.pathname);
            }
            setQueryStringRemovedByUser(false);
        });
        return () => {
            unlisten();
        };
    }, [history, queryStringRemovedByUser]);

    useEffect(() => {
        getAllLicensesLogs(skip, pageLimit)
        getUsersList()
        if (encodedParams) {
            setFilterData({
                ...filterData,
                licenseKey: encodedParams
            })
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Licenses Logs</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} className="space-top-col">
                    <Card
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            color: "#000059!important",
                            textAlign: "left",
                            padding: "30px",
                            backgroundColor: "#fbfbfb;",
                        }}
                    >
                        <div className="vendortable-header">
                            <Typography
                                variant="h6"
                                sx={{
                                    width: "100%",
                                    maxWidth: "14rem",
                                    color: "#000059!important",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "1.6rem",
                                    fontFamily: "Barlow!important",
                                }}
                            >
                                {" "}
                                Licenses Logs
                            </Typography>
                            <Grid
                                container
                                spacing={{ xs: 1, md: 1, lg: 1 }}
                                columns={{ xs: 4, sm: 8, md: 8 }}
                                className="button-align"
                            >
                                <Grid item>
                                    <Button
                                        component={Link}
                                        size="large"
                                        variant="contained"
                                        to='/manage-licenses'
                                        sx={{
                                            backgroundColor: "#002986!important",
                                            color: "white!important",
                                            textTransform: "none!important",
                                            fontWeight: "bold",
                                            fontFamily: "Barlow!important",
                                        }}
                                        startIcon={<ArrowBack />}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <CardContent
                            sx={{
                                padding: "0px!important",
                                marginTop: "30px!important",
                            }}
                            className="plan-tbl vendor-outer"
                        >
                            <ValidatorForm
                                onSubmit={() => handleSubmitFilter()}
                                className="filter-form mb-5"
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid
                                        container
                                        spacing={{ xs: 2, md: 2, lg: 2 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{ textAlign: "left" }}
                                        >
                                            <Autocomplete
                                                onChange={(event, newValue) => handleChangeVendorFilterInput(newValue)}
                                                fullWidth
                                                className="filter-input"
                                                id="combo-box-demo"
                                                name="user"
                                                options={usersList ?? []}
                                                getOptionLabel={(option) => option.email}
                                                renderInput={(params) => (
                                                    <TextValidator
                                                        {...params}
                                                        value={(filterData?.user && filterData?.user?.email) ?? null}
                                                        placeholder="Users"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{ textAlign: "left" }}
                                        >
                                            <TextValidator
                                                onChange={(e) => handleOnChangeLicenseKey(e)}
                                                variant="outlined"
                                                placeholder="Enter License key"
                                                fullWidth
                                                type="text"
                                                name="licensekey"
                                                value={filterData.licenseKey}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{ textAlign: "left" }}
                                        >
                                            <TextValidator
                                                onChange={(e) => handleOnChnagestatus(e)}
                                                variant="outlined"
                                                placeholder="Search by Status"
                                                fullWidth
                                                type="text"
                                                name="status"
                                                value={filterData.status}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                display: "flex",
                                                alignItems: "end",
                                                justifyContent: "start",
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="filter-btn"
                                                startIcon={<HiOutlineSearch />}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ValidatorForm>
                            {!isLoading ? (
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                        }}
                                                    >
                                                        Email
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minWidth: "max-content",
                                                        }}
                                                    >
                                                        License key
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Status
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    Date
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {licensesLogs && licensesLogs.length > 0 ? (
                                                <>
                                                    {licensesLogs && licensesLogs.map((item, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {(item?.userId && item?.userId?.email) ?? "-"}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {(item?.referenceId && item?.referenceId?.license_key) ?? "-"}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {(item && item?.status) ?? "-"}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {new Intl.DateTimeFormat('en-US', options).format(new Date((item && item?.createdAt) ?? "-")) ?? "-"}
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                                </>
                                            ) : (
                                                <StyledTableRow>
                                                    <td
                                                        colSpan={13}
                                                        style={{ padding: "2.6rem 1rem" }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontSize: "1.6rem",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            No License Logs Found
                                                        </Typography>
                                                    </td>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <ContainerLoader />
                            )}
                            <br />
                            {licensesLogs && licensesLogs.length > 0 && (
                                <>
                                    {totalRecords > 0 && (
                                        <Pagination
                                            key="paginationnew"
                                            searchPage={searchPage}
                                            SearchDisable={SearchDisable}
                                            totalRecords={totalRecords}
                                            pageLimit={pageLimit}
                                            onPageChanged={onPageChanged}
                                        />
                                    )}
                                    <div
                                        style={{
                                            textAlign: "right",
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label>Items per page :</label>
                                        <FormControl
                                            sx={{
                                                m: 1,
                                                minWidth: 50,
                                            }}
                                            size="small"
                                        >
                                            <Select
                                                defaultValue={pageLimit}
                                                value={pageLimit}
                                                name="limit"
                                                sx={{
                                                    "& .MuiSelect-select": {
                                                        padding: "4px 34px 4px 14px!important",
                                                        fontFamily: "Barlow",
                                                    },
                                                }}
                                                onChange={(e) => changePageLimit(e)}
                                            >
                                                <MenuItem value="10">10</MenuItem>
                                                <MenuItem value="20">20</MenuItem>
                                                <MenuItem value="50">50</MenuItem>
                                                <MenuItem value="100">100</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
            <AlertMessage
                open={message.openalert}
                message={message.message}
                severity={message.alertseverity}
                closeCall={handleClose}
            />
        </>
    )

}
export default AdminLicensesLogs;