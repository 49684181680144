 import { ActionTypes } from "../types/ActionTypes";

const initialState =  {
    "vendorList": 5,
    "vendorEvents": 10,
}

const PageLimitReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PAGELIMIT:
            return {
                ...state,
                [action.payload.pageName]: action.payload.pageLimit,
            };
        default:
            return state;
    }
}

export default PageLimitReducer;
;
