import React, { useCallback } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { HiOutlineDownload } from 'react-icons/hi';
import customDateFormatLocal from './customDateFormatLocal';
import customDateFormat from './customDateFormat';
import { changeDollarAmountformat } from './changeDollarFormat';

const ReceiptPDFGenerator = ({ item_data }) => {

    const handleCapitalize = useCallback((str) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }, []);

    const handleClick = useCallback((item) => {
        const doc = new jsPDF("p", "mm", "a4")
        addLogo(doc)
        addHeader(doc, item)
        if (item.platform === "anonymous_browser_license") {
            generateAnonymousBrowserLicenseReceipt(doc, item)
        } else if (item.platform === "ticket_liberty") {
            generateTicketLibertyReceipt(doc, item)
        }
        doc.save(item.created ? `payment-receipt ${customDateFormat(item.created)}.pdf` : `payment-receipt.pdf` )
    },[]);

    const addLogo = (doc) => {
        const logoImg = require("images/logo.png")
        doc.addImage(logoImg, "PNG", 70, 10, 60, 60)
    }

    const addHeader = (doc, item) => {
        doc.setFontSize(24)
        doc.setFont("cursive", "bold")
        doc.text("Receipt", 86, 80)
        doc.setFontSize(16)
        doc.setFont("cursive", "bold")
        doc.text("Heading:", 20, 100)
        doc.text("Credit:", 20, 110)
        doc.text("Balance:", 20, 120)
        doc.text("Date time:", 20, 130)
        doc.setFontSize(14)
        doc.setFont("cursive", "normal")
        doc.text(item.heading, 60, 100)
        doc.text("$" + item.amount, 60, 110)
        doc.text("$" + item.balance, 60, 120)
        doc.text(customDateFormatLocal(item.created), 60, 130)
    }

    const generateAnonymousBrowserLicenseReceipt = (doc, item) => {
        const header = ["License Key", "License Type", "Generated Date"]
        const data = item.license_ids?.map((row) => [
            row.license_key ?? null,
            handleCapitalize(row.license_type ?? null),
            customDateFormatLocal(row.createdAt ?? null)
        ]) ?? []
        addTable(doc, header, data, 140, true) // Added 'true' parameter for full width
    }

    const generateTicketLibertyReceipt = (doc, item) => {
        if (item.heading === "Package Purchased") {
            generatePackagePurchasedReceipt(doc, item)
        } else {
            generateTicketPurchaseReceipt(doc, item)
        }
    }

    const generatePackagePurchasedReceipt = (doc, item) => {
        const header = [
            "Package Name",
            "Price",
            "Tickets Included",
            "Price Per Additional Ticket"
        ]
        const data = item.soldinventory ? [[
            item.soldinventory.name,
            `${changeDollarAmountformat(item.soldinventory.price)}/month`,
            item.soldinventory.tickets_count,
            `${changeDollarAmountformat(item.soldinventory.additional_ticket_price)}`
        ]] : []
        const descriptionY = addDescription(doc, item)
        addTable(doc, header, data, descriptionY, false)
    }

    const generateTicketPurchaseReceipt = (doc, item) => {
        const header = [
            "Event Name",
            "Event Date",
            "Vendor Email",
            "Section",
            "Row",
            "Seat"
        ]
        let data = []
        if (item?.sold_ids?.length > 0) {
            data = item?.sold_ids.map((row) => [
                row.event[0].name,
                customDateFormatLocal(row?.event[0]?.date),
                row.vendor.username,
                row.section,
                row.row,
                row.seatNumbers
            ])
        } else if (item.soldinventory) {
            data = [[
                item.soldinventory?.event[0]?.name,
                customDateFormatLocal(item.soldinventory?.event[0]?.date),
                item.soldinventory.vendor.username,
                item.soldinventory.section,
                item.soldinventory.row,
                item.soldinventory.seatNumbers
            ]]
        }
        const descriptionY = addDescription(doc, item)
        if (data?.length > 0) {
            addTable(doc, header, data, descriptionY, false)
        }
    }

    const addDescription = (doc, item) => {
        if (item.description) {
            doc.setFontSize(16)
            doc.setFont("cursive", "bold")
            doc.text("Description:", 20, 140)
            doc.setFont("cursive", "normal")
            return addWrappedTextWithOverflow(doc, item.description, 60, 140, 130)
        }
        return 140
    }

    const addWrappedTextWithOverflow = (doc, text, x, y, maxWidth) => {
        const lineHeight = 8
        const pageHeight = doc.internal.pageSize.height
        const marginBottom = 10
        doc.setFontSize(13)
        const wrappedText = doc.splitTextToSize(text, maxWidth)
        wrappedText.forEach((line) => {
            if (y + lineHeight > pageHeight - marginBottom) {
                doc.addPage()
                y = 20
            }
            doc.text(line, x, y)
            y += lineHeight
        })
        return y
    }

    const addTable = (doc, header, data, startY, fullWidth = false) => {
        doc.autoTable({
            startY,
            head: [header],
            body: data,
            theme: "grid",
            styles: {
                fontSize: 10,
                cellPadding: 2,
                overflow: "linebreak",
            },
            columnStyles: fullWidth
                ? {}
                : {
                    0: { cellWidth: 40, fontSize: 10 },
                    1: { cellWidth: 40, fontSize: 10 },
                    2: { cellWidth: 40, fontSize: 10 },
                },
            tableWidth: fullWidth ? 'auto' : 180,
            rowPageBreak: "auto",
            headStyles: {
                fillColor: [56, 88, 160],
            },
            alternateRowStyles: {
                fillColor: [245, 245, 245],
            },
            rowHeight: 10,
            margin: fullWidth ? { left: 10, right: 10 } : {},
        })
    }

    return (
        <IconButton onClick={() => handleClick(item_data)} aria-label="download" size="small" >
            <HiOutlineDownload style={{ color: "#B90F0F", fontSize: "24px", }} />
        </IconButton>
    )
}

ReceiptPDFGenerator.propTypes = {
    item_data: PropTypes.arrayOf(PropTypes.object)
}
ReceiptPDFGenerator.defaultProps = {
    item_data: []
};

export default ReceiptPDFGenerator;
