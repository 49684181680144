export const SaveTotalTimeAction = (name, time) => {
    return {
        type: "STORE_TOTALTIME",
        payload: {
          name: name,
          time: time,
        },
      };
    };
  
  