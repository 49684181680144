import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import Logo from '../../images/logo.png';
import {Helmet} from "react-helmet";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';
import './Changepass.css';
import AlertMessage from '../../components/message/message';
import {
    Link
} from "react-router-dom";
import { Button } from '@mui/material';

class Changepass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password_token:'',
            password: '',
            c_password: '',
            error: [],
            invalidTokenBtn:true,
            loader:false,
            open:false,
            message:'',
            successError:''
        }
    }
   async componentDidMount() {
        if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
              
                if (value !== this.state.password) {
                    return false;
                }
                return true;
            });
        }
        if (!ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.addValidationRule('PasswordStrength', (value) => {
                // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
                var re = /.{6,}/;
                // @".{6,}"
                return re.test(value);
            });
        }
        // validate Password Token Api
        // console.log("historyProps", this.props.match.params.userId);
        const token={
            password_token:this.props.match.params.userId
        }
        // console.log("password_token", token);
        const validate_password_api_url = process.env.REACT_APP_API_URL + "/api/user/validatePasswordToken"
        await axios.post(validate_password_api_url, token)
          .then((response)=>{
            
              const { data } = response;
                if(data.error){
                    this.setState({
                        invalidTokenBtn:false,
                        open:true,
                        message:data.message,
                        successError:'error'
                    })
                }else 
                {   
                    this.setState({
                        invalidTokenBtn:false,
                        open:true,
                        message:data.message,
                        successError:'success'
                    })
                }
          })
          .catch((error)=>{
            this.setState({
                invalidTokenBtn:false,
                open:true,
                message:"Something went wrong, please try again",
                successError:'error'
            })
          })


    }
    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
        if (ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.removeValidationRule('PasswordStrength');
        }
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit=()=>{
        this.setState({
            loader:true
        })
        const data={
            password:this.state.password,
            password_token:this.props.match.params.userId
        }
      
     
        const change_password_api_url = process.env.REACT_APP_API_URL + "/api/user/updateUserPassword"
         axios.post(change_password_api_url, data)
          .then((response)=>{
            
              const { data } = response;
                if(data.error){
                   this.setState({
                       loader:false,
                       open:true,
                       message:data.message,
                       successError:'error'
                   })
                }else 
                {
                    this.setState({
                        loader:false,
                        open:true,
                        message:data.message,
                        successError:'success'
                    })
                    this.props.history.push("/login");
                }
          })
          .catch((error)=>{
            
            this.setState({
                loader:false,
                open:true,
                message:"Something went wrong",
                successError:'error',
            })
          })
    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    }; 
    render() {
      
        const { password, c_password, invalidTokenBtn, } = this.state;
        return (
            <div>
                {
                    this.state.invalidTokenBtn ?  <div className="loader-min"><div className="loader"></div></div>  : ''
                }
                <div className="Login-Outer SignUp-Outer">
                    <Helmet>
                        <title>Change Password</title>
                        <body className="Login-Bg" />
                    </Helmet>

                    <Container>
                        <div className="Login-Outer-Padding pt-5 pb-5">
                            <Row>
                                <Col lg="6">
                                    <div className=" text-center pt-5">
                                    <Link to="/"> <img src={Logo} className="logo-size" alt=''/></Link>

                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="Login-Right-Css">
                                        <div className="center"> <h2 className="Login-Heading">Change your password</h2></div>

                                        <ValidatorForm onSubmit={this.handleSubmit} >
                                            <Row>
                                                <Col md="12">
                                                    <div className="form-group login-wrapper">
                                                        <label>New password</label>
                                                        <TextValidator
                                                            variant="outlined"
                                                            fullWidth
                                                            onChange={this.handleChange}
                                                            name="password"
                                                            type="password"
                                                            value={password}
                                                            validators={['required', 'PasswordStrength']}
                                                            errorMessages={[('This field is required'), ('password must be at least 6 character')]}
                                                        />
                                                        {this.state.error.password &&
                                                            <div className="error-msg">{this.state.error.password}</div>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                        <div className="form-group login-wrapper">
                                                            <label>Confirm new password</label>
                                                                <TextValidator
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    onChange={this.handleChange}
                                                                    name="c_password"
                                                                    type="password"
                                                                    value={c_password}
                                                                    validators={['isPasswordMatch', 'required']}
                                                                    errorMessages={[('password mismatch'), ('This field is required')]}
                                                                />
                                                                {this.state.error.c_password && 
                                                        <div className="error-msg">{this.state.error.c_password}</div>
                                                        }
                                                        </div>
                                                    </Col>
                                                    <Col md="12">
                                                        {
                                                            invalidTokenBtn ? 
                                                            <Button 
                                                            variant="contained"
                                                            className="theme-btn-submit"
                                                            
                                                            disabled={true}
                                                        >
                                                            Change my password
                                                        </Button> 
                                                        :
                                                        <Button 
                                                            variant="contained"
                                                            type="submit"
                                                            className="theme-btn-submit"
                                                            
                                                            
                                                        >
                                                            Change my password
                                                    
                                                        </Button>
                                                        }
                                                        
                                                    
                                                    </Col>
                                            </Row>
                                        </ValidatorForm>
                                      
                                         <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/>
                                      
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}
export default Changepass;