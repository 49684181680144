import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

// mui components
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import {
    Card,
    Grid,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    CircularProgress,
    InputAdornment,
} from "@mui/material";

// components
import AlertMessage from "components/message/message";
import { ContainerLoader } from "components/common/ContainerLoader";

const BrowserLicensesPricing = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(false)
    const [message, setMessage] = useState({
        isOpenAlert: false,
        message: "",
        alertSeverity: "",
    })
    const [formData, setFormData] = useState({
        id: "",
        primaryLicense: "",
        secondaryLicense: ""
    })

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setMessage({
            ...message,
            isOpenAlert: false,
            message: "",
            alertSeverity: "",
        })
    }

    const handleSubmit = async () => {
        try {
            setIsDisabledSubmitButton(true)
            const parmas = {
                id: formData?.id,
                license_price: {
                    primary: parseInt(formData?.primaryLicense),
                    secondary: parseInt(formData?.secondaryLicense)
                }
            }
            const update_licenses_price = process.env.REACT_APP_API_URL + "/api/setting/update";
            const response = await axios.post(update_licenses_price, parmas, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data?.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    isOpenAlert: true,
                    alertSeverity: "error"
                });
                setIsDisabledSubmitButton(false)
            } else {
                setMessage({
                    ...message,
                    message: data?.message,
                    isOpenAlert: true,
                    alertSeverity: "success"
                });
                await getLicensePrice()
                setIsDisabledSubmitButton(false)
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                isOpenAlert: true,
                alertSeverity: "error"
            });
            setIsDisabledSubmitButton(false)
        }
    }

    const getLicensePrice = async () => {
        try {
            setIsLoading(true)
            const datas = {
                id: "60633a028f70d97537accede",
            };
            const account_api_url =
                process.env.REACT_APP_API_URL + "/api/setting/detail";
            const response = await axios.post(account_api_url, datas, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data?.error) {
                setIsLoading(false)
                setMessage({
                    ...message,
                    message: "Something went wrong while accessing users list",
                    isOpenAlert: true,
                    alertSeverity: "error"
                });
            } else {
                setFormData({
                    ...formData,
                    id: data?.data && data?.data?._id,
                    primaryLicense: data?.data && data?.data?.license_price && data?.data?.license_price?.primary,
                    secondaryLicense: data?.data && data?.data?.license_price && data?.data?.license_price?.secondary
                })
                setIsLoading(false)
            }
        } catch (error) {
            setMessage({
                ...message,
                message: "Something went wrong while accessing users list",
                isOpenAlert: true,
                alertSeverity: "error"
            });
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getLicensePrice()
    }, [])

    return (
        <>
            <Helmet>
                <title>Browser Pricing</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                    <Card>
                        <CardHeader
                            title="Anonymous Browser Pricing"
                        />
                        <Divider />
                        {!isLoading ? (
                            <CardContent>
                                <ValidatorForm
                                    onSubmit={handleSubmit}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    paddingBottom: "0.5rem",
                                                }}
                                            >
                                                Primary License
                                            </Typography>
                                            <TextValidator
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => handleOnChange(e)}
                                                name="primaryLicense"
                                                type="number"
                                                value={formData?.primaryLicense}
                                                validators={["required"]}
                                                errorMessages={["This field is required"]}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                                        $
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    textAlign: "left",
                                                    paddingBottom: "0.5rem",
                                                }}
                                            >
                                                Secondary License
                                            </Typography>
                                            <TextValidator
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => handleOnChange(e)}
                                                name="secondaryLicense"
                                                type="number"
                                                value={formData?.secondaryLicense}
                                                validators={["required"]}
                                                errorMessages={["This field is required"]}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" sx={{ marginRight: "-2px" }}>
                                                        $
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{ margin: "0 auto" }}>
                                            <Button
                                                size="large"
                                                variant="contained"
                                                sx={{ paddingY: "0.9rem" }}
                                                type="submit"
                                                disabled={isDisabledSubmitButton}
                                                fullWidth
                                                className="theme-btn-submit"
                                            >
                                                Update Setting
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </CardContent>
                        ) : (
                            <ContainerLoader />
                        )}
                    </Card>
                </Grid>
            </Grid>
            <AlertMessage
                open={message?.isOpenAlert}
                message={message?.message}
                severity={message?.alertSeverity}
                closeCall={handleClose}
            />
        </>
    )
};
export default BrowserLicensesPricing;