import React from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
} from "@coreui/react";
import Button from "@mui/material/Button";
import "./browserLoginDetail.css";
import { FaDownload } from "react-icons/fa";
import { FcMultipleDevices } from "react-icons/fc";
import { FcGlobe } from "react-icons/fc";
import { BiTime } from "react-icons/bi";
import Usersidebar from "../../components/user-sidebar/user-sidebar";
import Header from "../../components/header/header";
import Cookies from "js-cookie";


class BrowserLoginDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        lists: [],
        loader: true
    }
  }

  componentDidMount() {
    this.setState({ loader: true }, () => {
      this.getlists()
    })
  }

  getlists = () => {
    const list_api_url = process.env.REACT_APP_API_URL + "/api/auth/mybrowserlogins"
    axios.get(list_api_url, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })
    .then((response) => {
        const { data } = response;
        if (data.error) {
            this.setState({loader: false})
        }
        else {
            this.setState({loader: false, lists: data.data})
        }
    }).catch((error) => {
        this.setState({loader: false})
    })
  }

  logoutfromBrowser = (item) => {
    this.setState({loader: true})
    const logout_apiurl = process.env.REACT_APP_API_URL + "/api/auth/browserlogout"
    axios.post(logout_apiurl, {token: item.token})
    .then((res) => {
      this.getlists()
    })
  }

  render() {
    const { lists, loader } = this.state;

    const fields = [
      {key: 'pcname', label: 'Device'},
      {key: 'ipaddr', label: 'IP'},
      {key: 'created', label: 'Login On'},
      {key: 'action', label: 'Action'},
    ];

    return (
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>Browser Logins</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body">
          <Usersidebar />
          <div className="c-wrapper">
            <CRow>
              <CCol xs="12" lg="12">
                <CCard>
                  <CCardHeader className="m-header">
                    <h3 className="h-3">Browser Sessions</h3>
                    <Button className="download-button">
                      <FaDownload className="m-icon" />
                      Download
                    </Button>
                  </CCardHeader>
                  {loader && 
                    <div className="loaderadmin-min"><div className="loaderadmin"></div></div>
                  }

                  {!loader &&
                  <CCardBody className="plan-tbl">
                    <CDataTable
                      className="m-table"
                      responsive
                      items={lists}
                      fields={fields}
                      scopedSlots={{
                        "pcname": (item) => {
                          return (
                            <td className="py-2 icon-outer-font">
                              <div className="flex">
                                <div className="icon-space-left">
                                  <FcMultipleDevices className="m-icon" />
                                  {item.pcname}
                                  {item.macaddr && <><br />Mac Addr: {item.macaddr}</>}
                                </div>
                              </div>
                            </td>
                          );
                        },
                        "ipaddr": (item) => {
                          return (
                            <td className="py-2 icon-outer-font">
                              <div className="flex">
                                <div className="icon-space-left">
                                  <FcGlobe className="m-icon" />
                                  {item.ipaddr}
                                </div>
                              </div>
                            </td>
                          );
                        },
                        "created": (item) => {
                          return (
                            <td className="py-2 icon-outer-font">
                              <div className="flex">
                                <div className="icon-space-left">
                                  <BiTime className="m-icon" />
                                  {item.created}
                                </div>
                              </div>
                            </td>
                          );
                        },
                        "action": (item) => {
                          return (
                            <td className="py-2 icon-outer-font">
                              {(item.status && item.status === "loggedin") && (
                                <Button className="m-button" onClick={() => this.logoutfromBrowser(item)}>Log out</Button>
                              )}
                            </td>
                          );
                        },
                      }}
                    />
                  </CCardBody>
                  }
                </CCard>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
    );
  }
}

export default BrowserLoginDetail;
