import axios from 'axios';
import history from '../../history';
export const SIGNUPREQUEST = 'SIGNUPREQUEST';
export const SIGNUPLOADING = 'SIGNUPLOADING';
export const SIGNUPSUCCESS = 'SIGNUPSUCCESS';

const signup_api_url = process.env.REACT_APP_API_URL + "/api/auth/signup"

export default function SignupAct(data){
       
    return  async function (dispatch){
        try{
              await axios.post(signup_api_url, data)
                .then((res)=>{
                  
                    if(res.status == 200){

                         dispatch(setType(res.data));
                         dispatch(setProps(SIGNUPSUCCESS, true))
                        // alert(res.data.message)
                        // window.location="/Verification";
                      
                        history.push('/verification')
                    }
                    else{
                        console.log("Not data Get");
                        // alert("Something went wrong, please try again")
                    }
                }).catch((error)=>{
                    console.log("Something went wrong, please try again")
                })
        }
        catch(e){
            console.log("error", e);
        }
    }
}

const setType=(data)=>{
    return{
        type:SIGNUPREQUEST,
        payload:data
    }
}

const setProps =(type=SIGNUPLOADING, status=false)=>{
    return{
        type:type,
        isLoading:status
    }
}