import React, { useCallback, useRef, useState } from "react";
import {
  Header,
  Features,
  Hero,
  Footer,
  // Roadmap,
} from "components/LandingPage";

const Home = () => {
  const scrollRefHero = useRef(null);
  const scrollRefPrint = useRef(null);
  const scrollRefFooter = useRef(null);
  // const scrollRefRoad = useRef(null);
  const [isActive, setIsActive] = useState(0);
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleClose = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const scrollToRef = useCallback((ref, index) => {
    setIsActive(index);
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const handleScrollDiv = useCallback(
    (e, index) => {
      e.stopPropagation();
      switch (index) {
        case 0:
          scrollToRef(scrollRefHero, index);
          break;
        case 1:
          scrollToRef(scrollRefPrint, index);
          break;
          case 2:
            scrollToRef(scrollRefFooter, index);
            break;
            // case 2:
            //   scrollToRef(scrollRefRoad, index);
            //   break;
        default:
          break;
      }
      handleClose();
    },
    [scrollToRef, handleClose]
  );

  return (
    <div
      style={{
        backgroundColor: "#242040",
        overflow:"hidden"
      }}
    >
      <Header
        handleScrollDiv={handleScrollDiv}
        isActive={isActive}
        setAnchorElNav={setAnchorElNav}
        anchorElNav={anchorElNav}
        handleClose={handleClose}
      />
      <Hero scrollRef={scrollRefHero} />
      <Features scrollRef={scrollRefPrint} />
      {/* <Roadmap scrollRef={scrollRefRoad} /> */}
      <Footer scrollRef={scrollRefFooter} />
    </div>
  );
};

export default Home;
