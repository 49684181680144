import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useEffect } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }
    return range;
};

const CustomPagination = (props) => {
    let parmasSearchPage = 1;
    const params = new URLSearchParams(window && window?.location?.search);
    parmasSearchPage = params.get('searchPage');
    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;
    const [currentPage, setCurrentPage] = React.useState(parmasSearchPage === null ? 1 : Number(parmasSearchPage));
    const totalPages = Math.ceil(totalRecords / pageLimit);

    const gotoPage = page => {
        const currentPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            currentPage,
            totalPages,
            pageLimit,
            totalRecords
        };
        props.onPageChanged(paginationData);
        props.SearchDisable(0);
        setCurrentPage(currentPage);
    }

    const handleClick = (page, evt) => {
        evt.preventDefault();
        gotoPage(page);
    }

    const handleMoveLeft = evt => {
        evt.preventDefault();
        gotoPage(currentPage - pageNeighbours * 2 - 1);
    }

    const handleMoveRight = evt => {
        evt.preventDefault();
        gotoPage(currentPage + pageNeighbours * 2 + 1);
    }

    const fetchPageNumbers = () => {
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    }

    useEffect(() => {
        if (props.searchPage === 1) {
            setCurrentPage(props.searchPage);
            props.SearchDisable(0);
        }

    }, [props]);

    if (!totalRecords || totalPages === 1) return null;
    const pages = fetchPageNumbers();

    return (
        <Fragment>
            <nav aria-label="Countries Pagination">
                <ul className="pagination">
                    {pages && pages.map((page, index) => {
                        if (page === LEFT_PAGE) return (
                            <li key={index} className="page-item">
                                <button className="page-link" aria-label="Previous" onClick={handleMoveLeft}>
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </button>
                            </li>
                        );
                        if (page === RIGHT_PAGE) return (
                            <li key={index} className="page-item">
                                <button className="page-link" aria-label="Next" onClick={handleMoveRight}>
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </button>
                            </li>
                        );
                        return (
                            <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                                <button className="page-link" onClick={(evt) => handleClick(page, evt)} disabled={currentPage === page}>{page}</button>
                            </li>
                        );
                    }
                    )}
                </ul>
            </nav>
        </Fragment>
    );
}

CustomPagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func
};


export default memo(CustomPagination);
