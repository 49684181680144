import Dashboard from "images/user-dashboard/dashboard.svg";
import Accounts from "images/user-dashboard/accounts.svg";
import Inventory from "images/user-dashboard/interactive.svg";
import Soldinv from "images/user-dashboard/sold-inventory.svg";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { FaRegCalendarCheck } from 'react-icons/fa';
import Affiliates from "images/affiliate-icon.svg";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const navigation = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/user-dashboard",
    isHide:false,
    icon: (
      <div>
        <img src={Dashboard} className="sidebar-icon" alt="sidebaricons" />
      </div>
    ),
    badge: {
      color: "info",
    },
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Account",
    // to: '/user-account',
    isHide:false,
    icon: (
      <div>
        <img src={Accounts} className="sidebar-icon" alt="account" />
      </div>
    ),

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Vendor",
        to: "/vendor-list",
        // icon: 'cil-speedometer',
        badge: {
          color: "info",
        },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Event Management",
        to: "/event-management",
        // icon: 'cil-speedometer',
        badge: {
          color: "info",
        },
      },
      {
        _tag: "CSidebarNavItem",
        name: "TM Password Manager",
        to: "/tm-password-manager",
        // icon: 'cil-speedometer',
        badge: {
          color: "info",
        },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Marketplaces/POS",
        to: "/market-pos-list",
        // icon: 'cil-speedometer',
        badge: {
          color: "info",
        },
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Interactive Inventory",
    to: "/inventory",
    isHide:false,
    icon: (
      <div>
        <img src={Inventory} className="sidebar-icon" alt="inventory" />
      </div>
    ),
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sold Inventory",
    to: "/sold-inventory",
    isHide:false,
    icon: (
      <div>
        <img src={Soldinv} className="sidebar-icon" alt="soldinventory" />
      </div>
    ),
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Attendance",
    to: "/attendance",
    icon: (
      <div>
        <FaRegCalendarCheck className="sidebar-icon" style={{ opacity: "0.7" }} />
      </div>
    ),
    badge: {
      color: "info",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Affiliates",
    isHide: false,
    to: "/affiliates",
    icon: (
      <div>
          <img src={Affiliates} className="sidebar-icon" alt="soldinventory" />
      </div>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Packages",
    isHide: false,
    to: "/packages",
    icon: (
      <div>
        <Inventory2OutlinedIcon className="sidebar-icon" alt="packages" />
      </div>
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Split Links",
    to: "/verifiedticket",
    target: "_blank",
    isHide:false,
    icon: (
      <div>
        <InsertLinkIcon className="sidebar-icon" style={{ opacity: "0.7" }} />
      </div>
    ),
    badge: {
      color: "info",
    },
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Add Credit",
  //   to: "/add-credit",
  //   icon: (
  //     <div>
  //       <img src={Addcredit} className="sidebar-icon" alt="addcredit" />
  //     </div>
  //   ),
  //   badge: {
  //     color: "info",
  //   },
  // },


  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Browser Session",
  //   to: "/user-browser-login-detail",
  //   icon: (
  //     <div>
  //       <FcGlobe className="m-icon" />
  //       {/* <img src={FcGlobe} className="sidebar-icon" /> */}
  //     </div>
  //   ),
  //   badge: {
  //     color: "info",
  //   },
  // },
  // {
  //   _tag: "CSidebarNavDropdown",
  //   name: "Browser ",
  //   _children: [
  //     {
  //       _tag: "CSidebarNavItem",
  //       name: "Browser Session",
  //       to: "/user-browser-login-detail",
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Credit Packages',
  //   to: '/custom-credit-packages',
  //   icon: <div>
  //   <img src={Creditpackages} className="sidebar-icon"/>
  // </div>,
  //   badge: {
  //     color: 'info',
  //   }
  // },

  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Choose Vendors',
  //   route: '/user-dashboard',
  //   icon: 'cil-cursor',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Dashboard',
  //       to: '/user-dashboard',
  //       // icon: 'cil-speedometer',
  //       badge: {
  //         color: 'info',
  //       }
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Accounts',
  //       to: '/user-account',
  //       // icon: 'cil-speedometer',
  //       badge: {
  //         color: 'info',
  //       }
  //     },
  //   ]
  // }
  
];

export default navigation;