import React from 'react';
import axios from 'axios';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
} from '@coreui/react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import AlertMessage from '../alert/alert';
import { Row, Col, } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import * as ReactQuill from 'react-quill';
import '../common.css';
import { Helmet } from 'react-helmet';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Box, CircularProgress } from '@mui/material';

class CreditPackageEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            names: '',
            price: '',
            free_credit: '',
            description: '',
            package_order: '',
            error: [],
            loader: false,
            open: false,
            message: '',
            successError: '',
            user: [],
            userExistList: [],
            UserValues: [],
            image: null,
            formData: {
                image: '',
                files: null,

            },
            fixedOptions: [],
            value: [],
            staticInitialVa: [
                { title: 'The Shawshank Redemption' },
                { title: 'The Godfather' }
            ],
            chooseFile: false,
        }
        this.inputRef = React.createRef();
    }
    async componentDidMount() {
        this.setState({
            loader: true
        })
        const data = {
            id: this.props.match.params.id
        }
        // User List Id
        const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers"
        await axios.post(userList_api_url, '', { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: 'Something went wrong',
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        UserValues: data.data !== undefined ? data.data : '',
                        open: false,
                        message: '',
                        successError: 'success'
                    })
                    
                    let my_object = {};
                    my_object.email = "All"
                    my_object._id = "1"
                    if (this.state.UserValues.indexOf(my_object.email) === -1) {
                        this.state.UserValues.push(my_object);

                    }
                    else {
                        console.log("No Getting All");
                    }
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })

        //    Package Detail Api
        const planEdit_api_url = process.env.REACT_APP_API_URL + "/api/package/creditPackageDetail"
        await axios.post(planEdit_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: 'Something went wrong',
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        names: data.data.name,
                        price: data.data.price,
                        description: data.data.description,
                        free_credit: data.data.free_credit,
                        package_order: data.data.package_order,
                        image: data.data.image,
                        // value:data.data.user,
                        open: false,
                        message: '',
                        successError: 'success'
                    })
                    var arr = []
                    let my_object = {};
                    my_object.email = "All"
                    my_object._id = "1"
                    if (response.data.data !== undefined) {
                        if (response.data.data.all_user === 1) {
                            arr.push(my_object);
                        }
                        else {
                            this.state.UserValues.map((data) => {
                                response.data.data.user.filter(items => {

                                    if (data._id === items) {
                                        arr.push(data);
                                    }
                                    return null
                                })

                                return null
                            })

                        }

                    }
                    this.setState({
                        value: arr
                    })
                }
            }).catch((error) => {

                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeQuill = (value) => {
        this.setState({ description: value })
    }
    onChanges = (e) => {
        this.setState({
            chooseFile: true
        })
        var files = e.target.files;
        var kk = files[0];
        if (!files.length) {
            return;
        }
        else {
            if (kk.type === "image/png" || kk.type === "image/jpg" || kk.type === "image/jpeg") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    // console.log("ttttttt", event.target.result);
                    // const {formData} = this.state
                    // formData['image'] = event.target.result;
                    this.setState({
                        image: event.target.result
                    })
                };
                reader.readAsDataURL(kk);
            }
            else {
                alert("Only image can be uploaded");
            }
        }
    }
    handleChangeMulti = (event, newValue) => {
        this.setState({
            value: [
                ...this.state.fixedOptions,
                ...newValue.filter((option) => this.state.fixedOptions.indexOf(option) === -1),
            ]
        })
    }
    //Static data testing
    handleChangeMultiStatic = (event, newValue) => {
        this.setState({
            staticInitialVa: [
                ...this.state.fixedOptions,
                ...newValue.filter((option) => this.state.fixedOptions.indexOf(option) === -1),
            ]
        })
    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    };
    handleSubmit = async (e) => {
        const arr = [];
        this.state.value.map(data => {
            arr.push(data._id);
            return null
        })

        this.setState({
            loader: true
        })
        if (this.state.chooseFile) {
            const data = {
                id: this.props.match.params.id,
                user: arr,
                name: this.state.names,
                price: this.state.price,
                free_credit: this.state.free_credit,
                description: this.state.description,
                package_order: this.state.package_order,
                image: this.state.image,
            }
            // console.log("dataaaaaaaaaa", data);
            const editPackage_api_url = process.env.REACT_APP_API_URL + "/api/package/updateCreditPackage"
            await axios.post(editPackage_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
                .then((response) => {
                    const { data } = response;
                    if (data.error) {
                        this.setState({
                            loader: false,
                            open: true,
                            message: data.message,
                            successError: 'error'
                        })
                    }
                    else {

                        this.setState({
                            loader: false,
                            open: true,
                            message: data.message,
                            successError: 'success'
                        })
                        this.props.history.push("/credit-packages");
                    }
                }).catch((error) => {

                    this.setState({
                        loader: false,
                        open: true,
                        message: "Something went wrong, please try again",
                        successError: 'error'
                    })
                })

        }
        else {
            const data = {
                id: this.props.match.params.id,
                user: arr,
                name: this.state.names,
                price: this.state.price,
                free_credit: this.state.free_credit,
                description: this.state.description,
                package_order: this.state.package_order,
                image: "",
            }
            // console.log("dataaaaaaaaaa", data);
            const editPackage_api_url = process.env.REACT_APP_API_URL + "/api/package/updateCreditPackage"
            await axios.post(editPackage_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
                .then((response) => {
                    const { data } = response;
                    if (data.error) {
                        this.setState({
                            loader: false,
                            open: true,
                            message: data.message,
                            successError: 'error'
                        })
                    }
                    else {

                        this.setState({
                            loader: false,
                            open: true,
                            message: data.message,
                            successError: 'success'
                        })
                        this.props.history.push("/credit-packages");
                    }
                }).catch((error) => {

                    this.setState({
                        loader: false,
                        open: true,
                        message: "Something went wrong, please try again",
                        successError: 'error'
                    })
                })

        }

    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    render() {
        const { loader, value, price, free_credit, package_order, names } = this.state;

       if(!loader){ return (
            <>
                <Helmet>
                    <title>
                        Edit Credit Package
                    </title>
                </Helmet>
                <CRow>
                    <CCol xs="12" sm="12">
                        <CCard>
                            <CCardHeader>
                                <h3>Edit Credit Package</h3>
                            </CCardHeader>
                            <CCardBody>
                                <ValidatorForm onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group select-outer">
                                                <label>User</label>

                                                <Autocomplete
                                                    multiple
                                                    id="fixed-tags-demo"
                                                    value={value}
                                                    onChange={this.handleChangeMulti}
                                                    options={this.state.UserValues !== undefined ? this.state.UserValues : ''}
                                                    getOptionLabel={(option) => option.email}

                                                    renderTags={(tagValue, getTagProps) =>
                                                        tagValue.map((option, index) => (
                                                            <Chip
                                                                label={option.email}
                                                                {...getTagProps({ index })}
                                                                disabled={this.state.fixedOptions.indexOf(option) !== -1}
                                                            />
                                                        ))
                                                    }

                                                    renderInput={(params) => (
                                                        <TextValidator value={value} {...params} variant="outlined" placeholder="Favorites" validators={['required']}
                                                            errorMessages={('This field is required')} />
                                                    )}
                                                />


                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group login-wrapper">
                                                <label>Name</label>
                                                <TextValidator
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={this.handleChange}
                                                    name="names"
                                                    value={names}
                                                    validators={['required']}
                                                    errorMessages={[('This field is required')]}
                                                />

                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group login-wrapper">
                                                <label>Price</label>
                                                <TextValidator
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={this.handleChange}
                                                    type="number"
                                                    name="price"
                                                    value={price}
                                                    validators={['required']}
                                                    errorMessages={[('This field is required', 'Only number')]}
                                                />

                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group login-wrapper">
                                                <label>Free Credit</label>
                                                <TextValidator
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={this.handleChange}
                                                    name="free_credit"
                                                    value={free_credit}
                                                    validators={['required']}
                                                    errorMessages={[('This field is required')]}
                                                />

                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="form-group select-outer">
                                                <label>Notes</label>
                                                <ReactQuill name="description" value={this.state.description}
                                                    onChange={this.handleChangeQuill}
                                                    modules={this.modules}
                                                    formats={this.formats}
                                                />
                                            </div>
                                        </Col>


                                        <Col md="12" style={{ marginTop: "20px" }}>
                                            <div className="form-group login-wrapper">
                                                <label>Package Order</label>
                                                <TextValidator
                                                    variant="outlined"
                                                    fullWidth
                                                    type="number"
                                                    onChange={this.handleChange}
                                                    name="package_order"
                                                    value={package_order}
                                                    validators={['required']}
                                                    errorMessages={[('This field is required', 'Only number')]}
                                                />

                                            </div>
                                        </Col>
                                        <Col className="file-upload-outer">
                                            <label>
                                                <input type="file" name="image" accept="image/jpg, image/png" onChange={this.onChanges} />
                                                {
                                                    this.state.image ?
                                                        <div className="image-show-css">
                                                            <img className="img-fluid" src={this.state.image} alt="" />
                                                        </div>

                                                        : 'No Image Selected'
                                                }
                                            </label>
                                        </Col>

                                        <Col md="12">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="submit-btn login-btn update-btn"
                                            >
                                                Update

                                            </Button>
                                        </Col>


                                    </Row>
                                </ValidatorForm>

                                <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose} />
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </>
        )}

        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}


export default CreditPackageEdit;