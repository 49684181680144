import React from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Link } from 'react-router-dom';
import {  CCardBody, CCardHeader } from '@coreui/react';
import { Row, Col, Modal, Table, Nav } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';

import AlertMessage from './alert/alert';
import { Box, Card, CircularProgress } from '@mui/material';


class AutoMessagingMailFormat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                id: '',
                sold_mail_templete: '',
                confimation_mail_templete: '',
                followup_mail_templete: '',
                ticket_price: 0
            },
            loader: true,
            showform: false,
            message: '',
            formSubmit: false,
            error: [],
            openalert: false,
            alertseverity: '',
            showModal: false,
            insertPlaceholderFiled: ""
        }
        this.inputRef = React.createRef();
    }

    async componentDidMount() {
        const account_api_url = process.env.REACT_APP_API_URL + "/api/setting/detail";
        const data = {
            "id": "60633a028f70d97537accede"
        };
        await axios.post(account_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({ loader: false, message: 'Something went wrong while accessing users list', alertseverity: 'error', openalert: true });
                }
                else {
                    const formData = {
                        id: data.data._id,
                        sold_mail_templete: data.data.sold_mail_templete,
                        confimation_mail_templete: data.data.confimation_mail_templete,
                        followup_mail_templete: data.data.followup_mail_templete,
                        ticket_price: data.data.ticket_price
                    };
                    this.setState({ loader: false, formData, showform: true });
                }
            }).catch((error) => {
                this.setState({ loader: false, message: 'Something went wrong while accessing users list', alertseverity: 'error', openalert: true });
            })
    }

    handleChange = (e) => {
        const { formData } = this.state;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    }

    handleSubmit = async (e) => {

        this.setState({ formSubmit: true });

        const addUser_api_url = process.env.REACT_APP_API_URL + "/api/setting/update"
        axios.post(addUser_api_url, this.state.formData, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({ formSubmit: false, message: data.message, alertseverity: 'error', openalert: true })
                }
                else {
                    this.setState({ formSubmit: false, message: data.message, alertseverity: 'success', openalert: true });
                }
            }).catch((error) => {
                this.setState({ formSubmit: false, message: 'Something went wrong while submitting form', alertseverity: 'error', openalert: true });
            })
    }

    handleClose = (event, reason) => {
        this.setState({
            openalert: false
        })
    };

    openModal = (field) => {
        this.setState({ showModal: true, insertPlaceholderFiled: field })
    }
    closeModal = () => {
        this.setState({ showModal: false })
    }

    selectPlacehodler(placeholder) {
        const { formData, insertPlaceholderFiled } = this.state;
        if (insertPlaceholderFiled === "sold_mail_templete") {
            formData[insertPlaceholderFiled] = formData.sold_mail_templete.concat(placeholder)
        }
        if (insertPlaceholderFiled === "confimation_mail_templete") {
            formData[insertPlaceholderFiled] = formData.confimation_mail_templete.concat(placeholder)
        }
        if (insertPlaceholderFiled === "followup_mail_templete") {
            formData[insertPlaceholderFiled] = formData.followup_mail_templete.concat(placeholder)
        }
        this.setState({ formData });
    }

    render() {
        const { formData, loader, showform } = this.state;

        const placeholders = [
            { placeholder: "{customerDisplayName}", description: "Customer display name" },
            { placeholder: "{customerEmail}", description: "Customer email id" },
            { placeholder: "{customerPhone}", description: "Customer phone" },
            { placeholder: "{invoiceDate}", description: "Invoice Date" },
            { placeholder: "{invoiceExternalRef}", description: "invoice External Reference" },
            { placeholder: "{quantity}", description: "Quantity" },
            { placeholder: "{row}", description: "Row for ex. H" },
            { placeholder: "{seatNumbers}", description: "Seat Numbers for ex. 12,13,14,15" },
            { placeholder: "{section}", description: "Section for ex. 212" },
            { placeholder: "{ticketurl}", description: "Ticket Url created from VBD API" },
            { placeholder: "{event.name}", description: "Event name" },
            { placeholder: "{event.performer.name}", description: "Event performer name for ex. Memphis Grizzlies" },
            { placeholder: "{event.date}", description: "Event date" },
            { placeholder: "{event.venue.address}", description: "Event venue detail" },
            { placeholder: "{event.venue.city}", description: "" },
            { placeholder: "{event.venue.name}", description: "" },
            { placeholder: "{event.venue.phone}", description: "" },
            { placeholder: "{event.venue.postalCode}", description: "" },
            { placeholder: "{event.venue.state}", description: "" },
            { placeholder: "{confirm_order_link}", description: "Link for confirmation of Order" },
            { placeholder: "{problem_order_link}", description: "Link for Problem in Order" }
        ]

        if (!loader) {
            return (
                <>
                    <Helmet>
                        <title>Settings</title>
                    </Helmet>
                    {showform &&
                        <Row>
                            <Col xs={12} sm={12}>
                                <Card style={{width:"100%"}}>
                                    <Row   style={{width:"100%"}}  className='px-4  pt-3'>
                                        <h3>Auto Messaging Format</h3>
                                    </Row>
                                    <div>
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Link className="nav-link" to="/auto-messaging-format">SMS Format</Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Link className="nav-link active" to="/auto-messaging-mail-format">MAIL Format</Link>
                                            </Nav.Item>
                                        </Nav>

                                        <ValidatorForm style={{ marginTop: "15px" }} onSubmit={this.handleSubmit} >
                                            <Row>
                                                <Col md="12">
                                                    <div className="form-group login-wrapper " style={{padding:"0px 14px"}}>
                                                        <label>Sold Mail Template</label>
                                                        <TextValidator
                                                            variant="filled"
                                                            multiline
                                                            fullWidth
                                                            rows={8}
                                                            onChange={this.handleChange}
                                                            name="sold_mail_templete"
                                                            value={formData.sold_mail_templete}
                                                            validators={['required']}
                                                            errorMessages={[('This field is required')]}
                                                        />
                                                        <span style={{ cursor: "pointer", fontSize: "10px" }} onClick={() => this.openModal("sold_mail_templete")}>view available placeholder</span>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="form-group login-wrapper"  style={{padding:"0px 14px"}}>
                                                        <label>Delivery Mail Templete</label>
                                                        <TextValidator
                                                            variant="filled"
                                                            multiline
                                                            fullWidth
                                                            rows={8}
                                                            onChange={this.handleChange}
                                                            name="confimation_mail_templete"
                                                            value={formData.confimation_mail_templete}
                                                            validators={['required']}
                                                            errorMessages={[('This field is required')]}
                                                        />
                                                        <span style={{ cursor: "pointer", fontSize: "10px" }} onClick={() => this.openModal("confimation_mail_templete")}>view available placeholder</span>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="form-group login-wrapper"  style={{padding:"0px 14px"}}>
                                                        <label>Confirmation Mail Templete</label>
                                                        <TextValidator
                                                            variant="filled"
                                                            multiline
                                                            fullWidth
                                                            rows={8}
                                                            onChange={this.handleChange}
                                                            name="followup_mail_templete"
                                                            value={formData.followup_mail_templete}
                                                            validators={['required']}
                                                            errorMessages={[('This field is required')]}
                                                        />
                                                        <span style={{ cursor: "pointer", fontSize: "10px" }} onClick={() => this.openModal("followup_mail_templete")}>view available placeholder</span>
                                                    </div>
                                                </Col>
                                                <Col md="4" style={{ margin: '0 auto' }}>
                                                    <Button
                                                        size='large'
                                                        variant="contained"
                                                        sx={{ paddingY: "0.9rem" }}
                                                        type="submit"
                                                        fullWidth
                                                        className="theme-btn-submit"
                                                    >
                                                        Update Setting
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ValidatorForm>
                                        <AlertMessage open={this.state.openalert} message={this.state.message} severity={this.state.alertseverity} closeCall={this.handleClose} />
                                        <Modal show={this.state.showModal} onHide={() => this.closeModal()}>

                                            <Modal.Header closeButton>
                                                <Modal.Title>Available Placeholder</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th><th>Placeholder</th><th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {placeholders.map((placeholder, index) => (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td><span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.selectPlacehodler(placeholder.placeholder)}>{placeholder.placeholder}</span></td>
                                                                <td>{placeholder.description}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            )
        }

        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default AutoMessagingMailFormat;