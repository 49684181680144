import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Textarea from "@mui/material/TextareaAutosize";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import "pages/userdashboard/userdashboard.scss";
import AlertMessage from "components/message/message";
import { connect } from "react-redux";
import UserProfileAction from "redux/actions/UserProfielAction";
import { Box } from "@mui/system";
import Cookies from 'js-cookie';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from "@mui/material";

class BillingInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
      },
      loader: false,
      open: false,
      message: "",
      successError: "",
    };
  }

  componentDidMount() {
    const data = this.props.UserProfile;
    if (data.error) {
      this.setState({
        loader: false,
        open: true,
        message: data.message,
        successError: "error",
      });
    } else {
      const formData = {
        address: data.data.address,
        country: data.data.country,
        state: data.data.state,
        city: data.data.city,
        postal_code: data.data.postal_code,
      };
      this.setState({ loader: false, formData });
    }
  }

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    this.setState({ loader: true }, () => {
      const updateDetail_api_url =
        process.env.REACT_APP_API_URL + "/api/user/updateUser";
      axios
        .post(updateDetail_api_url, this.state.formData, {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.props.getUserProfile();
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
            });
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
        });
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  render() {
    const { formData, open, message, loader, successError } = this.state;

    if (!loader) {
      return (
        <>
          <div className="user-dashboard-wrapper">
            <Helmet>
              <title>Update Billing Information</title>
            </Helmet>
            <Header />
            <div className="c-app c-default-layout user-dashboard-body">
              <Usersidebar />
              <div className="c-wrapper">
                <div className="c-body">
                  <main className="c-main">
                    <Grid container spacing={2}>
                      <Grid item xs="12">
                        <Card className="notification-outer add-account-wrapper">
                          <CardHeader title="Update Billing Information" />

                          <AlertMessage
                            open={open}
                            message={message}
                            severity={successError}
                            closeCall={this.handleClose}
                          />

                          <CardContent>
                            <ValidatorForm onSubmit={this.handleSubmit}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                  <div className="form-group login-wrapper">
                                    <label className="billing-info-style">
                                      Address
                                    </label>
                                    <Textarea
                                      value={formData.address}
                                      name="address"
                                      className="text-box-height billing_input"
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper1 billing-info-style">
                                    <label className="billing-info-style">
                                      Country
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="country"
                                      value={formData.country}
                                      validators={["required"]}
                                      errorMessages={["This field is required"]}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper1">
                                    <label className="billing-info-style">
                                      State
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="state"
                                      value={formData.state}
                                      validators={["required"]}
                                      errorMessages={["This field is required"]}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper1">
                                    <label className="billing-info-style">
                                      City
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="city"
                                      value={formData.city}
                                      validators={["required"]}
                                      errorMessages={["This field is required"]}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper1">
                                    <label className="billing-info-style">
                                      Country
                                    </label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="postal_code"
                                      value={formData.postal_code}
                                      validators={["required"]}
                                      errorMessages={["This field is required"]}
                                    />
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    className="theme-btn-submit"
                                    sx={{
                                      padding: {
                                        xs: "12px 100px",
                                        sm: "14px 140px",
                                      },
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Grid>
                              </Grid>
                            </ValidatorForm>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </main>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(UserProfileAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInformation);
