import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// mui  component
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses
} from '@mui/material';

// mui icons 
import { Add, Delete, Edit } from '@mui/icons-material';

// Component
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import AddAffiliates from './AddAffiliates';
import CompanyCategory from './CompanyCategory';
import { ContainerLoader } from 'components/common/ContainerLoader';
import DeleteConfirmationModal from 'utils/deleteConfirmationModal';
import Pagination from 'components/pagination/pagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const actionbutton = {
    backgroundColor: "#d9dadd!important",
    color: "#002986!important",
    textTransform: "none!important",
    fontFamily: "Barlow!important",
    margin: "0 5px",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
};

const ManageAffiliates = () => {
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const { getAllAffiliateAPI, deleteCompanyAffiliateAPI, getSingleAffiliateAPI } = useAdminAPICollection();
    const [isLoading, setIsLoading] = useState(true);
    const [isAddAffiliatesForm, setIsAddAffiliatesForm] = useState(false);
    const [isCategory, setIsCategory] = useState(false);
    const [isEediting, setIsEediting] = useState(false);
    const [isLoadingSingle, setIsLoadingSingle] = useState(false);
    const [companyAffiliates, setCompanyAffiliates] = useState([]);
    const [singleAffiliate, setSingleAffiliate] = useState({});
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [searchPage, setSearchPage] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    const [isOpenDeleteConfirmationModel, setIsOpenDeleteConfirmationModel] = useState({
        isOpenDeleteConfirmation: false,
        affiliateId: ''
    });

    const handleVisableCategory = () => {
        setIsCategory(true);
    }

    const handleAffiliatesForm = () => {
        setIsAddAffiliatesForm(true);
        setSingleAffiliate({});
        setIsEediting(false);
    }

    const handleOpenDeleteConfirmation = (e, item) => {
        setIsOpenDeleteConfirmationModel({
            ...isOpenDeleteConfirmationModel,
            isOpenDeleteConfirmation: true,
            affiliateId: item?._id
        });
    }

    const handleCloseDeleteConfirmation = () => {
        setIsOpenDeleteConfirmationModel({
            ...isOpenDeleteConfirmationModel,
            isOpenDeleteConfirmation: false,
            affiliateId: null
        });
    }
    
    const getAllAffiliate = async (pageskip = 0, limitpage = 10) => {
        try {
            setIsLoading(true);
            const res = await getAllAffiliateAPI({
                skip: pageskip,
                limit: limitpage,
            });
            const { data } = res;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setTotalRecords(data?.count);
                setCompanyAffiliates(data?.data);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    }

    const handleDeleteCompanyAffiliate = async () => {
        setIsOpenDeleteConfirmationModel({
            ...isOpenDeleteConfirmationModel,
            isOpenDeleteConfirmation: false,
        });
        try {
            const res = await deleteCompanyAffiliateAPI({ id: isOpenDeleteConfirmationModel?.affiliateId });
            const { data } = res;
            if (data.error) {
                errorMessage(data?.message ?? "", "error");
                handleCloseDeleteConfirmation();
            } else {
                errorMessage("Affiliate company delete successfully!", "success");
                getAllAffiliate(skip, pageLimit);
            }
        } catch (error) {
            errorMessage(error.response.data.message ?? "", "error");
            handleCloseDeleteConfirmation();
        }
    }

    const handelgetSingleAffiliate = async (id) => {
        try {
            setIsLoadingSingle(true);
            const res = await getSingleAffiliateAPI({ id });
            const { data } = res;
            if (data.error) {
                errorMessage();
                setIsLoadingSingle(false);
            } else {
                setSingleAffiliate(data?.data);
                setIsAddAffiliatesForm(true);
                setIsEediting(true);
                setIsLoadingSingle(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoadingSingle(false);
        }
    }

    const changePageLimit = (e) => {
        setSearchPage(1);
        setPageLimit(parseInt(e.target.value));
        getAllAffiliate(0, parseInt(e.target.value));
        setSearchPage(1);
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setSkip((currentPage - 1) * pageLimit);
        getAllAffiliate((currentPage - 1) * pageLimit, pageLimit);
    };

    const SearchDisable = (value) => {
        setSearchPage(value);
    };

    useEffect(() => {
        getAllAffiliate(skip, pageLimit);
    }, [])

    return (
        <>
            <Helmet>
                <title>Manage Affiliates</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {isCategory ? (
                        <CompanyCategory
                            setIsCategory={setIsCategory}
                        />
                    ) : (
                        isAddAffiliatesForm ? (
                            <AddAffiliates
                                setIsAddAffiliatesForm={setIsAddAffiliatesForm}
                                singleAffiliate={singleAffiliate}
                                isLoading={isLoadingSingle}
                                isEediting={isEediting}
                                setIsEediting={setIsEediting}
                                getAllAffiliate={getAllAffiliate}
                                errorMessage={errorMessage}
                            />
                        ) : (
                            <Card>
                                <CardHeader
                                    title="Manage Affiliates"
                                    action={
                                        <>
                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#002986!important",
                                                    color: "white!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                    paddingX: "1rem",
                                                    marginRight: "0.8rem",
                                                }}
                                                onClick={() => handleVisableCategory()}
                                            >
                                                Company Category
                                            </Button>

                                            <Button
                                                size="medium"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#002986!important",
                                                    color: "white!important",
                                                    textTransform: "none!important",
                                                    fontWeight: "bold",
                                                    fontFamily: "Barlow!important",
                                                    paddingX: "1rem",
                                                    marginRight: "0.8rem",
                                                }}
                                                startIcon={<Add />}
                                                onClick={() => handleAffiliatesForm()}
                                            >
                                                Add Affiliates
                                            </Button>
                                        </>
                                    }
                                />
                                <CardContent>
                                    {isLoading ? (
                                        <ContainerLoader />
                                    ) : (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    minWidth: "max-content",
                                                                }}
                                                            >
                                                                Company Name
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    minWidth: "max-content",
                                                                }}
                                                            >
                                                                Category
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            Text
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            Image
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    minWidth: "max-content",
                                                                }}
                                                            >

                                                                Link
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <div
                                                                style={{
                                                                    width: "100%",
                                                                    minWidth: "max-content",
                                                                }}
                                                            >
                                                                Action
                                                            </div>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {companyAffiliates.length > 0 ? companyAffiliates && companyAffiliates.map((item) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {item.companyName}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {item?.categoryId && item?.categoryId?.category}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div className="container-tablecell">
                                                                        {item?.text?.length > 100 ? (
                                                                            <section>
                                                                                <div class="outer-wrapper">
                                                                                    <div class="inner-wrapper" style={{ paddingBottom: "0px" }}>
                                                                                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        ) : (
                                                                            <div class="outer-wrapper" style={{ width: '260px' }}>
                                                                                <span>
                                                                                    <p dangerouslySetInnerHTML={{ __html: item.text }} />
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        <img width={50} height={50} src={item.image} alt="logo" />
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        {item.link}
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            minWidth: "max-content",
                                                                        }}
                                                                    >
                                                                        <Tooltip title="Edit">
                                                                            <IconButton
                                                                                sx={actionbutton}
                                                                                onClick={(e) => handelgetSingleAffiliate(item?._id)}
                                                                                aria-label="edit"
                                                                                size="small">
                                                                                <Edit fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title="Delete">
                                                                            <IconButton
                                                                                sx={actionbutton}
                                                                                onClick={(e) => handleOpenDeleteConfirmation(e, item)}
                                                                                aria-label="delete"
                                                                                size="small">
                                                                                <Delete fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    }) : (
                                                        <StyledTableRow>
                                                            <td
                                                                colSpan={13}
                                                                style={{ padding: "2.6rem 1rem" }}
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    sx={{
                                                                        fontSize: "1.6rem",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    Data not Found
                                                                </Typography>
                                                            </td>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                    <br />
                                    {companyAffiliates && companyAffiliates.length > 0 && (
                                        <>
                                            {totalRecords > 0 && (
                                                <Pagination
                                                    key="paginationnew"
                                                    searchPage={searchPage}
                                                    SearchDisable={SearchDisable}
                                                    totalRecords={totalRecords}
                                                    pageLimit={pageLimit}
                                                    onPageChanged={onPageChanged}
                                                />
                                            )}
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <label>Items per page :</label>
                                                <FormControl
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 50,
                                                    }}
                                                    size="small"
                                                >
                                                    <Select
                                                        defaultValue={pageLimit}
                                                        value={pageLimit}
                                                        name="limit"
                                                        sx={{
                                                            "& .MuiSelect-select": {
                                                                padding: "4px 34px 4px 14px!important",
                                                                fontFamily: "Barlow",
                                                            },
                                                        }}
                                                        onChange={(e) => changePageLimit(e)}
                                                    >
                                                        <MenuItem value="10">10</MenuItem>
                                                        <MenuItem value="20">20</MenuItem>
                                                        <MenuItem value="50">50</MenuItem>
                                                        <MenuItem value="100">100</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                </Grid>
            </Grid>
            {/* delete confimation modal */}
            <DeleteConfirmationModal
                handleDelete={handleDeleteCompanyAffiliate}
                isOpen={isOpenDeleteConfirmationModel?.isOpenDeleteConfirmation}
                handleClose={handleCloseDeleteConfirmation}
                text={"Are you sure you want to delete company affiliate?"}
            />
            {/* Alert Message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}

export default ManageAffiliates;