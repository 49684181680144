import React, { Component } from 'react';
import { Row, Col, Container, } from 'react-bootstrap';
import Logo from '../../images/logo.png';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import './Forget.css';
// import {Helmet} from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import AlertMessage from '../../components/message/message';
import { Link } from "react-router-dom";
import { Box, CircularProgress } from '@mui/material';

class Forgetpassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            loader:false,
            formData: {
              email:''
            },
            submitted: false,
            open:false,
            message:'',
            successError:''
        }
    }
    handleChange=(event)=>{
        const { formData } = this.state;
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }
    handleSubmit=()=>{
        this.setState({
            loader:true
        })
        const data={
            email:this.state.formData.email
        }
        const forget_api_url = process.env.REACT_APP_API_URL + "/api/user/forgotPassword"
         axios.post(forget_api_url, data)
          .then((response)=>{
              const { data } = response;
                if(data.error){
                   this.setState({
                       loader:false,
                       open:true,
                       message:data.message,
                       successError:'error'
                   })
                    
                }
                else 
                {
                    this.setState({
                        loader:false,
                        open:true,
                        message:data.message,
                        successError:'success'
                    })
                }
          })
          .catch((error)=>{
            this.setState({
                loader:false,
                open:true,
                successError:'error',
                message:"Something went wrong, please try again",
               
            })
           
          })
    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    }; 
    render(){
        const { formData, loader } = this.state;
       if(!loader){ return(
            <HelmetProvider>
                <div className="Login-Outer login_background">
                    
                    <Helmet>
                        <title>Forget Password</title>
                            <body className="Login-Bg" />
                    </Helmet>
                    <Container>
                        <div className="Login-Outer-Padding login_background">
                            <Row className="vertical-center animation_box">
                                <Col md={6} className="animation_wrap">
                                    <div className="Login-Left-Text center">
                                        <Link to="/">
                                            <video width="100%" height="100%" autoPlay>
                                                <source src={require("../../images/login_vedio.mp4").default} type="video/mp4"></source>
                                            </video>
                                        </Link>
                                    </div>
                                </Col>
                                <Col md={6}>
                                        <div className="Login-Right-Css">
                                            
                                            <div className="center">
                                            <div className="Email_left_logo">
                                            <Link to="/"><img src={Logo} alt="Logo" className="logo-size" /></Link>
                                            </div>
                                                <h2 className="Welcome-Heading">Forgot Password</h2>
                                            </div>
                                    
                                            <ValidatorForm onSubmit={this.handleSubmit}
                                                >
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="form-group login-wrapper error-msg-fix Email_box login-input-border">
                                                                <label>Email</label>
                                                                <TextValidator
                                                                    variant="outlined"
                                                                    onChange={this.handleChange}
                                                                    name="email"
                                                                    value={formData.email}
                                                                    validators={['required', 'isEmail']}
                                                                    errorMessages={['this field is required', 'email is not valid']}
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <Button
                                                                
                                                                variant="contained"
                                                                type="submit"
                                                                // disabled={submitted}
                                                                className="full-width login-btn"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                            </ValidatorForm>
                                           
                                             <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/>
                                           
                                            
                                            <div className="left forget">Back to <Link to="/login">Login</Link> </div>
                                        </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </HelmetProvider>
        )}
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default Forgetpassword;