import React from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer'
import '../welcome/welcome.css';
import AlertMessage from '../../components/message/message';
import axios from 'axios';
import queryString from 'query-string'
import { Box, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';
class Success extends React.Component{
    state={
        planData: [],
        open:false,
        message:'',
        successError:'',
        loader:false,
        paymentId:'',
        token:'',
        PayerID:'',
        package:'',

    }
   async componentDidMount(){
        this.setState({
            loader:true
        })
  
        var dataQuery =  queryString.parse(this.props.location.search);
        
         
            this.setState({
                paymentId:dataQuery.paymentId,
                token:dataQuery.token,
                PayerID:dataQuery.PayerID,
                package:localStorage.getItem("package_id")
            })
        
            const param ={
                paymentId:dataQuery.paymentId,
                token:dataQuery.token,
                PayerID:dataQuery.PayerID,
                package:localStorage.getItem("package_id")
            }
        
            
            const executePayment_api_url = process.env.REACT_APP_API_URL + "/api/payment/executePayment"
            await axios.post(executePayment_api_url, param, {headers: { authorization: 'Bearer '+ Cookies.get('accessToken')}})
                .then((response) => {
                
                    const { data } = response;
                
                    if (data.error) {
                        this.setState({
                            loader: false,
                            open:true,
                            message:data.message,
                            successError:'error'
                        })
                      
                    }
                    else {
                        this.setState({
                            loader: false,
                            planData: data,
                            open:true,
                            message:data.message,
                            successError:'success'
                        })
                        console.log("Success execute Payment");
                        this.props.history.push({
                            pathname: '/thanks',
                            state: { response: data }
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        loader: false,
                        open:true,
                        message:"Something went wrong",
                        successError:'error',
                    })
                })
    }
    render(){
        const {loader} = this.state;
        if(!loader){return(
            <div>
                 <Header />
                 <Container>
                    <div className="welcome-wrapper welcome-center">
                        <Row style={{ marginTop: '80px', marginBottom:'20px' }}>
                            <Col lg="12" md="12">
                                <h1 className="">Payment Is Successfully Completed</h1>
                            </Col>
                            <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/>
                        </Row>
                    </div>
                
                 </Container>
                

                 <Footer className="welcome-footer" />
            </div>

        )}
        return (
            <Box   sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default Success;