import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AddIcon from "@mui/icons-material/Add";
import AlertMessage from "components/message/message";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";
import { Link } from "react-router-dom";
import axios from "axios";
export default class fontManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFonts: [],
      modalShow: false,
      profiledetail: null,
      loader: true,
      open: false,
      message: "",
      severity: "",
      deleteModalShow: false,
    };
  }

  callDeleteApi = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/admin/deleteOSFonts";
    const { deleteId } = this.state;
    const data = {
      _id: deleteId,
    };
    axios.post(apiurl, data, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
      },
    })
    .then((response) => {
      const data = response.data;
      this.setState({ loader: false });
      if (!data.error) {
        this.setState({
          deleteModalShow: false,
          deleteId: null,
          open: true, message: data.message, severity: "success" });
        this.getAllFonts();
      }else{
        this.setState({ open: true, message: data.message, severity: "error" });
      }
      console.log(response.data);
    })
    .catch((error) => {
      this.setState({ loader: false });
      console.log(error);
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenDeleteModal = (id) => {
    this.setState({ deleteModalShow: true, deleteId : id});
  };

  handleCloseDeleteModal = () => {
    this.setState({ deleteModalShow: false ,deleteId : null });
  };

  componentDidMount() {
    this.getAllFonts();
  }

  getAllFonts = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/browser/getOSFonts";
    axios
      .get(apiurl)
      .then((response) => {
        const data = response.data;
        this.setState({ loader: false });
        if (!data.error) {
          this.setState({ allFonts: data.data });
        } else {
          this.setState({
            open: true,
            message: data.message,
            severity: "error",
          });
        }
        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };

  render() {
    const fields = [
      {
        key: "os",
        label: "Operating System",
      },
      {
        key: "fonts",
        label: "Fonts",
      },
      {
        key: "action",
        label: "Action",
      },
    ];
    if (!this.state.loader) {
      return (
        <>
          <Helmet>
            <title>Font Management</title>
          </Helmet>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Fonts"
                  action={
                    <Button
                      variant="contained"
                      aria-label="settings"
                      startIcon={<AddIcon />}
                      component={Link}
                      to="/add-font"
                      sx={{
                        backgroundColor: "#002986",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#B90F0F",
                          color: "#fff",
                        },
                      }}
                    >
                      Add Font
                    </Button>
                  }
                />
                <Divider />
                <CardContent className="plan-tbl">
                  <DataTable
                    fields={fields}
                    items={this.state.allFonts}
                    pagination
                    paginationLimit
                    scopedSlots={{
                      os: (item) => {
                        return item.os.name;
                      },
                      fonts: (item) => {
                        return item.fonts.map((font) => font).join(", ");
                      },

                      action: (item) => {
                        return (
                          <div className="flex">
                            <IconButton
                              aria-label="edit"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                              component={Link}
                              to={`/edit-font/${item._id}`}
                              
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => this.handleOpenDeleteModal(item._id)}
                              aria-label="delete"
                              sx={{
                                color: "#002986",
                                "&:hover": {
                                  color: "#B90F0F",
                                },
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      },
                    }}
                  />
                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={this.state.deleteModalShow}
                    onClose={this.handleCloseDeleteModal}
                  >
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.onDeleteHide()}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: (theme) => theme.palette.grey[500],
                          p: 1,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                      <DialogContentText>
                        Are you sure you want to Delete, this action can not be
                        revert ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.handleCloseDeleteModal}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.callDeleteApi}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.severity}
                    closeCall={this.handleClose}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
