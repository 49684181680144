export default [
    {
        _tag: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/communication-panel',
        badge: {
            color: 'info',
        }
    }
]
  
  