import React from 'react';
import {  Row, Col, Container,  } from 'react-bootstrap';

import './common-header.css'
class Commonheader extends React.Component{

    render(){
        return(
            <Container>
                <Row>
                    <Col>
                       <div className="center common-header">
                           <span>
                               {this.props.title}
                           </span>
                           <h2>
                               {/* Effective & flexible pricing that <br/> adapt your needs! */}
                               {this.props.description}
                               <br/>
                               {this.props.nextDes}
                           </h2>
                           {/* <img src={Pattern} className=""/> */}
                       </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Commonheader;