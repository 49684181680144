import { Chip } from "@mui/material";

export const StatusChip = ({ status }) => {
    const statusMap = {
        active: 'Active',
        inactive: 'Inactive',
        expired: 'Expired',
    };
    const colorMap = {
        active: "#00811D",
        inactive: "#FF6B23",
        expired: "#BC0000",
    };
    return (
        status ?
            <Chip
                label={statusMap[status]}
                size="small"
                sx={{
                    padding: `${status === 'active' ? '0 5px' : '0px'}`,
                    backgroundColor: colorMap[status] || "#808080",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "12px",
                }}
            />
            : '-')
}