import React from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
import { Helmet } from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import './subscription.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Commonheader from '../../components/common-header/common-headre';
import { Link} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import AlertMessage from '../../components/message/message';
import Usersidebar from '../../components/user-sidebar/user-sidebar';
import PageLoader from '../../components/PageLoader/PageLoader'
import { Cookie } from '@mui/icons-material';
import Cookies from 'js-cookie';

class CreditPackages extends React.Component {
    state = {
        planData: [],
        planDataAll:[],
        open:false,
        message:'',
        successError:'',
        planChooseLoader: false,
        loader: false,
        loaderUser: false,
        currency:'USD',
        env:'sandbox',
        onError:'',
        onSuccess:'',
        onCancel:'',
        amount:1,
        client: {
            sandbox:'AXY7B6p28RTOBOGsXRcA5Yh2ZAGa8Y99cfX7XY6PKoVyhMDcGpxGzpGuYi8NUrGtJFoSw5ebcv11rGZv',
            production: 'YOUR-PRODUCTION-APP-ID',
        },

    }
    async componentDidMount() {
        this.setState({
            loader:true,
            loaderUser: true
        })
        const listCreditPackage_api_url = process.env.REACT_APP_API_URL + "/api/user/listCreditPackages"
        await axios.post(listCreditPackage_api_url, '', { headers: { authorization: 'Bearer '+ Cookies.get('accessToken')} })
            .then((response) => {
                const { data } = response;
            
                if (data.error) {
                    this.setState({
                        loader: false,
                        open:true,
                        message:'Something went wrong',
                        successError:'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        planData: data,
                        open:false,
                        message:'',
                        successError:'success'
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open:true,
                    message:"Something went wrong",
                    successError:'error',
                })
            })
            const listCreditPackageForAllUser_api_url = process.env.REACT_APP_API_URL + "/api/user/listCreditPackagesForAllUsers"
            await axios.post(listCreditPackageForAllUser_api_url, '')
                .then((response) => {
                  
                    const { data } = response;
                
                    if (data.error) {
                        this.setState({
                            loaderUser: false,
                            open:true,
                            message:'Something went wrong',
                            successError:'error'
                        })
                    }
                    else {
                        this.setState({
                            loaderUser: false,
                            planDataAll:data.data,
                            open:false,
                            message:'',
                            successError:'success'
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        loaderUser: false,
                        open:true,
                        message:"Something went wrong",
                        successError:'error',
                    })
                })
     
    }
    callSubscriptionPlans = async (e) => {
        this.setState({
            planChooseLoader: true
        })
        localStorage.setItem('package_name', e.name);
        localStorage.setItem('package_id', e._id);
        localStorage.setItem('package_price', e.price);
    }
    //PayPall Process
    onError=(err)=>{
        console.log("Error", err)
    }
    onSuccess=(payment)=>{
        if(payment.paid){
            // this.props.history.push('/welcome')
            this.props.history.push({
                pathname: '/welcome',
                // search: '?query=abc',
                state: { response: payment }
            })
        }
        else{
            alert("Payment Fail");
        }

    }
    onCancel=(data)=>{
        console.log("Cancel", data)
    }
  
    render() {
        const { planData, planDataAll, loader, loaderUser, planChooseLoader } = this.state;
      
        return (
            <>
                <div className="user-dashboard-wrapper">
                    <Helmet>
                        <title>Credit Packages</title>
                    </Helmet>

                    <Header />
                        <div className="c-app c-default-layout user-dashboard-body">
                            
                            <Usersidebar/>
                                <div className="c-wrapper">
                                    <div className="c-body">
                           
                                        <main className="c-main">
                                            {loader && <PageLoader />}
                                            {planChooseLoader && <PageLoader />}
                                                
                                            <Container>
                                                <div className="subscription-wrapper ">
                                                      
                                                    <Commonheader title="" description="Effective & flexible pricing that adapt your needs!" />
                                                    <Nav variant="pills" className="justify-content-center " style={{marginTop: "20px"}}>
                                                        <Nav.Item>
                                                            <Link className="nav-link active one-time-btn" to="/add-credit">One Time Packages</Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Link className="nav-link   Anually_btn" to="/subscription-list">Anually Packages</Link>
                                                        </Nav.Item>
                                                    </Nav>

                                                    {(!loader && !loaderUser) &&
                                                    <Row style={{ marginTop: '20px' }}>
                                                        {
                                                            (planData.data !== undefined  && planData.data.length > 0) || (planDataAll.length > 0)
                                                               ? 
                                                               <>
                                                                   {
                                                                    planData.data !== undefined ? 
                                                                    planData.data.length > 0 ?
                                                                    planData.data.map((data, key) => {
                                                                     
                                                                        return (
                                                                            <Col md={4} sm={6} className="flex-container subscription-flex" key={data._id}>
                                                                                <div className="plan-outer" >
                                                                                    
                                                                                        {/*<div className="plan-img-outer">
                                                                                             {
                                                                                                data.name == "Premium Package" ? <img src={Premium} className="plan-img" /> :
                                                                                                    data.name == "Starter Package" ? <img src={Basic} className="plan-img" /> : data.name == "Pro Package" ?
                                                                                                        <img src={Regular} className="plan-img" /> : <img src={Standard} className="plan-img" />
                                                                                            } 
                                                                                            <img src={data.image} className="plan-img" />
                                                                                        </div>*/}
                                                                                        <h2>
                                                                                            {data.name}
                                                                                        </h2>
    
                                                                                        <div className="plan-price">
                                                                                            {
                                                                                                data.price != "" ? 
                                                                                                <div className="flex">
                                                                                                    $<div>
                                                                                                    
                                                                                                        <NumberFormat value={data.price} displayType={'text'} thousandSeparator={true}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
    
                                                                                                '-'
                                                                                            }
                                                                                        
                                                                                            {/* ${data.price} */}
                                                                                            {/* {
                                                                                                            data.price == '' ? '' :
                                                                                                            <div>
                                                                                                                {data.price} 
                                                                                                                <span class="per-month">/year</span>
                                                                                                            </div>
                                                                                                        } */}
                                                                                        </div>
                                                                                    
                                                                                        <div dangerouslySetInnerHTML={{
                                                                                            __html: data.description
                                                                                        }} />
                            
                                                                                        <div className="plan-btn">
                                                                                        
                                                                                        
                                                                                            {
                                                                                                data.price != "" ? 
                                                                                                // <Button variant="primary" className="full-width login-btn" onClick={() => this.callSubscriptionPlans(data)}>
                                                                                                // Choose Plan
                                                                                                // </Button>
                                                                                                <Link className="full-width login-btn" to="/billing" onClick={() => this.callSubscriptionPlans(data)}>Choose Plan</Link>  
                                                                                                :
                                                                                                    <Link className="full-width login-btn" to="/contact">Contact Us</Link>            
                                                                                            }
                                                                                        </div>
                                                                                    
                                                                                </div>
                                                                                <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                    :  
                                                                    // <div className="text-center w-100">
                                                                       
                                                                    //     <h2 className="font-weight-bold">
                                                                    //         No Items111
                                                                    //     </h2>
                                                                    // </div>
                                                                    ""
                                                                    :
                                                                        <div className="text-center w-100">
                                                                       
                                                                        <h2 className="font-weight-bold">
                                                                            {/* No Items */}
                                                                        </h2>
                                                                    </div>
                                                                    }
                                                                            

                                                                       {
                                                                            planDataAll !== undefined ?
                                                                            planDataAll.map((data, key) => {
                                                                            
                                                                                return (

                                                                                    <Col md={4} sm={6} className="flex-container subscription-flex" key={data._id}>
                                                                                        <div className="plan-outer " >
                                                                                            <div>
                                                                                                {/* <div className="plan-img-outer">
                                                                                                     {
                                                                                                        data.name == "Premium Package" ? <img src={Premium} className="plan-img" /> :
                                                                                                            data.name == "Starter Package" ? <img src={Basic} className="plan-img" /> : data.name == "Pro Package" ?
                                                                                                                <img src={Regular} className="plan-img" /> : <img src={Standard} className="plan-img" />
                                                                                                    } 
                                                                                                    <img src={data.image} className="plan-img" />
                                                                                                </div> */}
                                                                                                <div className="package_wrap">
                                                                                                <h2>
                                                                                                    {data.name}
                                                                                                </h2>
                                                                                                <span className='shado'></span>
                                                                                                </div>

                                                                                                <div className="plan-price">
                                                                                                    {
                                                                                                        data.price != "" ? 
                                                                                                        <div className="flex">
                                                                                                            $<div>
                                                                                                            
                                                                                                                <NumberFormat value={data.price} displayType={'text'} thousandSeparator={true}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :

                                                                                                        '-'
                                                                                                    }
                                                                                                
                                                                                                    {/* ${data.price} */}
                                                                                                    {/* {
                                                                                                                    data.price == '' ? '' :
                                                                                                                    <div>
                                                                                                                        {data.price} 
                                                                                                                        <span class="per-month">/year</span>
                                                                                                                    </div>
                                                                                                                } */}
                                                                                                </div>
                                                                                            
                                                                                                <div dangerouslySetInnerHTML={{
                                                                                                    __html: data.description
                                                                                                }} />
                                                                                                
                                                                                                <div className="plan-btn">
                                                                                                
                                                                                                
                                                                                                    {
                                                                                                        data.price != "" ? 
                                                                                                        // <Button variant="primary" className="full-width login-btn" onClick={() => this.callSubscriptionPlans(data)}>
                                                                                                        // Choose Plan
                                                                                                        // </Button>
                                                                                                        <Link className="full-width login-btn" to="/billing" onClick={() => this.callSubscriptionPlans(data)}>Choose Plan</Link>  
                                                                                                        :
                                                                                                            <Link className="full-width login-btn" to="/contact">Contact Us</Link>            
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <AlertMessage open={this.state.open} message={this.state.message} severity={this.state.successError} closeCall={this.handleClose}/>
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                            : 
                                                                            // <div className="text-center w-100">
                                                                            
                                                                            // <h2 className="font-weight-bold">
                                                                            //     No Items222
                                                                            // </h2>
                                                                            // </div>
                                                                            ""
                                                                     
                                                                        }
                                                                   </>   
                                                                   
                                                                
                                                            : 
                                                            <div className="text-center w-100">
                                                                            
                                                             <h2 className="font-weight-bold">
                                                                 No Items
                                                             </h2>
                                                             </div>                                                          
                                                        }
                                                    </Row>
                                                    }
                                                </div>
                                            </Container>
                                        </main>
                                    </div>
                                </div>
                        </div>
                    <Footer />
                </div>
            </>
        )
    }
}
export default CreditPackages;