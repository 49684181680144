import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// mui components
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Button,
    Card,
    CardContent,
    Grid,
    TableHead,
    Chip,
    Typography,
    CircularProgress,
    Autocomplete,
    Box,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    RadioGroup,
    Radio,
    Switch,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { ArrowBack, Cancel, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";

// components
import AlertMessage from "components/message/message";
import { ContainerLoader } from "components/common/ContainerLoader";
import { HiOutlineSearch } from "react-icons/hi";
import Pagination from "components/pagination/pagination";
import "./manageLicenses.scss"
import ConfirmationModal from "utils/confirmationModal";
import useAlertMessage from "Hooks/useAlertMessage";
import { useAdminAPICollection } from "Hooks/useAdminAPICollection";

// custom style  for data table of the Table Cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#002583",
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

// of  the  table Row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const actionbutton = {
    backgroundColor: "#002986!important",
    color: "white!important",
    padding: "2px 5px!important",
    textTransform: "none!important",
    fontWeight: "bold",
    fontFamily: "Barlow!important",
    margin: "0 5px",
};

const SwitchCustom = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const validity = [
    { name: 'Days' },
    { name: 'Months' },
    { name: 'Years' }
]
const ManageLicenses = () => {
    const { renewalLicenseAPI } = useAdminAPICollection()
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [formData, setFormData] = useState({
        user: {},
        product_code: "",
        quantity: ""
    });
    const [filterData, setFilterData] = useState({
        user: null,
        skip: 0,
        limit: 10
    });
    const [isVisibleModel, setIsVisibleModel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState({ name: 'Days' });
    const [licenseValidity, setLicenseValidity] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [allLicensesList, setAllLicensesList] = useState([]);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [unitLabel, setUnitLabel] = useState('')
    const [productName, setProductName] = useState('');
    //pageantion state 
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [searchPage, setSearchPage] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    // detete state
    const [isVisibleDeleteModel, setIsVisibleDeleteModel] = useState(false);
    const [deleteId, setDeleteId] = useState();
    //sync state 
    const [isVisibleSyncModel, setIsVisibleSyncModel] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState('recurring')
    // filter states 
    const [activeValue, setActiveValue] = useState('all');
    const [autoRenewal, setAutoRenewal] = useState('all');
    const [sortingOrder, setSortingOrder] = useState('desccreated');
    // Renewal
    const [isVisiableRenewalConfirModal, setIsVisiableRenewalConfirModal] = useState(false);


    const handleChangeAutoRenewal = (event) => {
        setAutoRenewal(event.target.value);
    }

    const handleChangeActive = (event) => {
        setActiveValue(event.target.value);
    }

    const handleChangesortingOrder = (event) => {
        setSortingOrder(event.target.value);
        getAllLicenses(skip, pageLimit, event.target.value, searchPage);
    }

    const getAllLicenses = async (pageskip = 0, limitpage = 10, sort = sortingOrder, searchPages) => {
        const sortOrderMapping = {
            'asccreated': ['asc', 'created'],
            'desccreated': ['desc', 'created'],
            'ascexpireDate': ['asc', 'expireDate'],
            'descexpireDate': ['desc', 'expireDate']
        };
        const [sortOrder, sortBy] = sortOrderMapping[sort] || [null, null];
        setPageLimit(limitpage);
        setSearchPage(searchPages);
        try {
            setIsLoading(true)
            const get_all_licenses_api_url = process.env.REACT_APP_API_URL + "/api/v2/admin/getAllLicenses";
            const response = await axios.post(get_all_licenses_api_url, {
                ...filterData,
                user: filterData.user,
                skip: pageskip,
                limit: limitpage,
                sortBy: sortBy,
                sortByOrder: sortOrder,
                status: activeValue === "all" ? null : activeValue,
                autoRenewal: autoRenewal === "all" ? null : autoRenewal,
            }, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (response.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                setAllLicensesList(data?.data);
                setTotalRecords(data?.count ?? 10);
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    };

    const handleSubmitGenerateLicense = async () => {
        const params = {
            ...formData,
            validity_time: subscriptionType === "recurring" ? "30d" : `${licenseValidity + unitLabel}`,
            subscription_type: "recurring"
        }
        try {
            setDisableSubmitButton(true)
            const generate_licenses_api_url = process.env.REACT_APP_API_URL + "/api/v2/admin/generateLicense";
            const response = await axios.post(generate_licenses_api_url, params, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (response.error) {
                errorMessage();
                setDisableSubmitButton(false)
            } else {
                errorMessage(data.message, "success");
                await getAllLicenses();
                handleCloseModel()
                setIsVisibleModel(false);
                setDisableSubmitButton(false)
            }
        } catch (error) {
            errorMessage();
            setDisableSubmitButton(false)
        }
    };

    const getAllProducts = async () => {
        try {
            const get_all_products_api_url = process.env.REACT_APP_API_URL + "/api/v2/admin/getAllProducts";
            const response = await axios.get(get_all_products_api_url, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (response.error) {
                errorMessage();
            } else {
                setAllProducts(data.data);
            }
        } catch (error) {
            errorMessage();
        }
    };

    const getAllUsers = async () => {
        try {
            const userList_api_url = process.env.REACT_APP_API_URL + "/api/user/getAllUsers?accessbrowser=yes";
            const response = await axios.post(userList_api_url, {}, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data.error) {
                errorMessage();
            } else {
                const newdata = data.data.map((val) => {
                    return {
                        email: val.email,
                        id: val._id,
                    };
                });
                const seen = new Set();
                const filteredArr = newdata.filter((el) => {
                    const duplicate = seen.has(el.email);
                    seen.add(el.email);
                    return !duplicate;
                });
                setAllUsers(filteredArr)
            }
        } catch (error) {
            errorMessage();
        }
    }

    const handleDeleteLicense = async () => {
        setIsVisibleDeleteModel(false)
        try {
            setIsLoading(true)
            const delete_license_api = process.env.REACT_APP_API_URL + "/api/v2/admin/deleteLicense";
            const response = await axios.post(delete_license_api, { _id: deleteId }, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                errorMessage(data.message, "success");
                getAllLicenses();
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    };

    const handleSyncLicenses = async () => {
        setIsVisibleSyncModel(false);
        try {
            setIsLoading(true)
            const sync_licenses_api_admin = process.env.REACT_APP_API_URL + "/api/v2/admin/syncLicenses";
            const response = await axios.get(sync_licenses_api_admin, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin")
                },
            })
            const { data } = response;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                errorMessage(data.message, "success");
                await getAllLicenses();
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    };

    const subscriptionOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const handleVisibleSyncConfirmationModel = () => {
        setIsVisibleSyncModel(true);
    }

    const handleCloseSyncConfirmationModel = () => {
        setIsVisibleSyncModel(false);
    }

    const handleVisibleDeleteConfirmationModel = (id) => {
        setIsVisibleDeleteModel(true);
        setDeleteId(id)
    }

    const handleCloseDeleteConfirmationModel = () => {
        setIsVisibleDeleteModel(false);
    }

    const handleVisibleModel = () => {
        setIsVisibleModel(true);
    };

    const handleCloseModel = () => {
        setIsVisibleModel(false);
        setFormData({
            ...formData,
            user: {},
            product_code: null,
            quantity: null
        });
        setLicenseValidity(null);
        setSelectedOption({ name: 'Days' });
        setUnitLabel(null);
    };

    const handleChangeVendor = (newVal) => {
        if (newVal) {
            setFormData({ ...formData, user: newVal });
        } else {
            setFormData({ ...formData, user: '' });
        }
    };

    const handleChangeProduct = (newVal) => {
        if (newVal) {
            setFormData({ ...formData, product_code: newVal.product_code });
            setProductName(newVal.product_name);
        } else {
            setFormData({ ...formData, product_code: '' });
        }
    };

    const handleOnChangeNoOfLicense = (e) => {
        const newValue = e.target.value;
        if ((/^(?!0$)(?!-)\d+$/.test(newValue) || newValue === '')) {
            setFormData({ ...formData, quantity: parseInt(newValue) });
        }
    };

    const handleOnChangeLicenseValidity = (e) => {
        const newValue = e.target.value;
        let unitLabel = "";
        if (
            (
                (/^(?!0$)(?!-)\d+$/.test(newValue) || newValue === '') &&
                (/^\d{0,2}$/.test(newValue))
            ) &&
            (
                (selectedOption?.name === "Days" && newValue <= 31) ||
                (selectedOption?.name === "Months" && newValue <= 12) ||
                (selectedOption?.name === "Years" && newValue)
            )
        ) {
            setLicenseValidity(newValue);
            if (selectedOption?.name === "Days") {
                unitLabel = "d";
            } else if (selectedOption?.name === "Months") {
                unitLabel = "m";
            } else if (selectedOption?.name === "Years") {
                unitLabel = "y";
            }
            setUnitLabel(unitLabel);
        }
    };

    const handleCapitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const calculateDays = useCallback((endDate, startDate) => {
        if (!endDate || !startDate) return null;
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(differenceInMilliseconds / millisecondsPerDay);
        return daysDifference + 1;
    }, []);

    const handleChangeVendorFilterInput = (newVal) => {
        if (newVal) {
            setFilterData({ ...filterData, user: newVal.id });
        } else {
            setFilterData({ ...filterData, user: null });
        }
    };

    const changePageLimit = (e) => {
        setSearchPage(1);
        setPageLimit(parseInt(e.target.value));
        getAllLicenses(0, parseInt(e.target.value), sortingOrder);
        setSearchPage(1);
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setSkip((currentPage - 1) * pageLimit);
        getAllLicenses((currentPage - 1) * pageLimit, pageLimit, sortingOrder);
    };

    const SearchDisable = (value) => {
        setSearchPage(value);
    };

    const handleSubmitFilter = async () => {
        await getAllLicenses(0, pageLimit, sortingOrder, 1);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Inactive":
                return "#BC0000";
            case "Active":
                return "green";
            case "Expired":
                return "#808080";
            case "Disabled":
                return "#b3b3b3";
            default:
                return null;
        }
    }

    const handleChangeOptionValue = (e) => {
        setSubscriptionType(e.target.value);
    };

    const handleOpenRenewalConfirmationModal = (id) => {
        setDeleteId(id)
        setIsVisiableRenewalConfirModal(true)
    }

    const handleCloseRenewalConfirmationModal = () => {
        setIsVisiableRenewalConfirModal(false)
        setDeleteId(null)
    }

    const handleRenewalLicenseAPI = async () => {
        setIsVisiableRenewalConfirModal(false)
        try {
            const parmas = {
                licId: deleteId
            }
            const response = await renewalLicenseAPI(parmas);
            const { data } = response;
            if (data.error) {
                setDeleteId(null)
                errorMessage();
            } else {
                errorMessage(data.message, "success");
                getAllLicenses(skip, pageLimit, sortingOrder);
                setDeleteId(null)
            }
        } catch (error) {
            setDeleteId(null)
            errorMessage();
        }
    }

    useEffect(() => {
        getAllLicenses(skip, pageLimit, sortingOrder);
        getAllUsers()
        getAllProducts()
    }, []);

    return (
        <>
            <Helmet>
                <title>Manage Licenses</title>
            </Helmet>
            {!isVisibleModel ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="space-top-col-manage-license">
                        <Card
                            sx={{
                                width: "100%",
                                maxWidth: "100%",
                                color: "#000059!important",
                                textAlign: "left",
                                padding: "30px",
                                backgroundColor: "#fbfbfb;",
                            }}
                        >
                            <div className="vendortable-header-manage-license">
                                <Typography
                                    variant="h6"
                                    sx={{
                                        width: "100%",
                                        maxWidth: "14rem",
                                        color: "#000059!important",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.6rem",
                                        fontFamily: "Barlow!important",
                                    }}
                                >
                                    {" "}
                                    Manage Licenses
                                </Typography>

                                <Grid
                                    container
                                    spacing={{ xs: 1, md: 1, lg: 1 }}
                                    columns={{ xs: 4, sm: 8, md: 8 }}
                                    className="button-align-manage-license"
                                >
                                    <Grid item>
                                        <Button
                                            component={Link}
                                            to="/admin-licenses-logs"
                                            type="button"
                                            size="large"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#002986!important",
                                                color: "white!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            Licenses Logs
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            size="large"
                                            variant="contained"
                                            onClick={(e) => handleVisibleSyncConfirmationModel(e)}
                                            sx={{
                                                backgroundColor: "#002986!important",
                                                color: "white!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            Sync Licenses
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            size="large"
                                            variant="contained"
                                            onClick={(e) => handleVisibleModel(e)}
                                            sx={{
                                                backgroundColor: "#002986!important",
                                                color: "white!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                            }}
                                        >
                                            Generate Licenses
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>


                            <CardContent
                                sx={{
                                    padding: "0px!important",
                                    marginTop: "30px!important",
                                }}
                                className="plan-tbl vendor-outer"
                            >
                                <ValidatorForm
                                    onSubmit={() => handleSubmitFilter()}
                                    className="filter-form mb-5"
                                >
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 2, lg: 2 }}
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={3}
                                                lg={2}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <label className="Account_details_box-manage-license">
                                                    Email
                                                </label>
                                                <Autocomplete
                                                    onChange={(event, newValue) => handleChangeVendorFilterInput(newValue)}
                                                    fullWidth
                                                    className="filter-input"
                                                    id="combo-box-demo"
                                                    name="user"
                                                    options={allUsers}
                                                    getOptionLabel={(option) => option.email}
                                                    renderInput={(params) => (
                                                        <TextValidator
                                                            {...params}
                                                            value={formData.user.email ?? null}
                                                            placeholder="Email"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={3}
                                                lg={2}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <FormControl fullWidth>
                                                    <label className="Account_details_box-manage-license">
                                                        Auto Renewal
                                                    </label>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="status"
                                                        size="small"
                                                        value={autoRenewal}
                                                        onChange={(e) => handleChangeAutoRenewal(e)}
                                                        defaultValue="all"
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        <MenuItem value="on">On</MenuItem>
                                                        <MenuItem value="off">Off</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={3}
                                                lg={2}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <FormControl fullWidth>
                                                    <label className="Account_details_box-manage-license">
                                                        License Status
                                                    </label>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="status"
                                                        value={activeValue}
                                                        onChange={(e) => handleChangeActive(e)}
                                                        size="small"
                                                        defaultValue="all"
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        <MenuItem value="active">Active</MenuItem>
                                                        <MenuItem value="inactive">Inactive</MenuItem>
                                                        <MenuItem value="expired">Expired</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={1.4}
                                                lg={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "end",
                                                    justifyContent: "center",
                                                    marginTop: "29px"
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="filter-btn"
                                                    startIcon={<HiOutlineSearch />}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ValidatorForm>
                                <Grid
                                    container
                                    spacing={{ xs: 1, md: 1, lg: 1 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                    className="button-align"
                                    sx={{ marginBottom: "10px" }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3.5}
                                        lg={2}
                                        sx={{ textAlign: "left" }}
                                    >
                                        <FormControl fullWidth>
                                            <label className="Account_details_box-manage-license">
                                                Sort By
                                            </label>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="status"
                                                size="small"
                                                onChange={(e) => handleChangesortingOrder(e)}
                                                value={sortingOrder}
                                                defaultValue="all"
                                                sx={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                <MenuItem value="desccreated">License Generated Date (Newest to Oldest)</MenuItem>
                                                <MenuItem value="asccreated">License Generated Date (Oldest to Newest)</MenuItem>
                                                <MenuItem value="ascexpireDate">License Expiry Date (Newest to Oldest)</MenuItem>
                                                <MenuItem value="descexpireDate">License Expiry Date (Oldest to Newest)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {!isLoading ? (
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            Name of Product
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            Nick name
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Email
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        License Key
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            License Type
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            License Status
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            Auto Renewal
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            No. of Activations
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            License Generated Date
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            Days Left
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            License Expiry Date
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                minWidth: "max-content",
                                                            }}
                                                        >
                                                            Action
                                                        </div>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allLicensesList && allLicensesList.length > 0 ? (
                                                    <>
                                                        {allLicensesList && allLicensesList.map((item, index) => {
                                                            const options = {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                                hour12: true
                                                            };
                                                            const days = calculateDays(new Date(item?.validUpto ?? null), new Date());
                                                            return (
                                                                <StyledTableRow key={index}>
                                                                    <StyledTableCell component="td" scope="row">
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item?.license_data
                                                                                && item?.license_data?.product
                                                                                && (item?.license_data?.product?.product_name
                                                                                    ?? null)}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item.nickname ?? "-"}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item.userId
                                                                                && (item.userId.email
                                                                                    ?? null)}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                to={`admin-licenses-logs?k=${item.license_key}`}
                                                                                style={{ color: "#4D5765", textDecoration: "none" }}
                                                                            >
                                                                                {item
                                                                                    && (item.license_key
                                                                                        ?? null)}
                                                                            </Link>
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {handleCapitalize(item?.license_type) ?? null}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell component="td" scope="row">
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                        >
                                                                            {item.license_data && item?.license_data && (
                                                                                <>
                                                                                    {item.license_data && item.license_data.status ? (
                                                                                        <Chip
                                                                                            label={item.license_data && item.license_data.status}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                padding: `${item.license_data && item.license_data.status === 'Active' ? '0 5px' : '0px'}`,
                                                                                                backgroundColor: `${getStatusColor(item.license_data && (item.license_data.status ?? null))}`,
                                                                                                color: "#fff",
                                                                                                fontWeight: "bold",
                                                                                                fontSize: "12px",
                                                                                            }}
                                                                                        />
                                                                                    ) : (null)}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item?.auto_renewal ?
                                                                                (item?.auto_renewal === "on") ? (
                                                                                    <SwitchCustom
                                                                                        checked={item?.auto_renewal === "on" && true}
                                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                ) : (item?.auto_renewal === "off") ? (
                                                                                    <SwitchCustom
                                                                                        checked={item?.auto_renewal === "off" && false}
                                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                ) : ("-") : ("-")}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {`${item.license_data && (item?.license_data?.times_activated ?? null)} of ${item.license_data && (item?.license_data?.max_activations ?? null)}`}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {new Intl.DateTimeFormat('en-US', options).format(new Date(item?.createdAt ?? null)) ?? null}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {/* {item.license_data
                                                                                && (handleValidityOnDate(item?.license_data?.valid_duration ?? null) ?? null)} */}
                                                                            {item?.validUpto ?
                                                                                (days === null || days > 0) ? (
                                                                                    (calculateDays(new Date(item?.validUpto ?? null), new Date() ?? null) + "  Days")
                                                                                ) : ("Expired")
                                                                                : ("-")}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item?.validUpto ? `${item?.validUpto ? new Intl.DateTimeFormat('en-US', subscriptionOptions).format(new Date(item?.validUpto ?? null)) : '-'}` : "-"}
                                                                        </div>
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                minWidth: "max-content",
                                                                            }}
                                                                        >
                                                                            {item?.license_data?.status === "Expired" ?
                                                                                (<Button
                                                                                    onClick={() => handleOpenRenewalConfirmationModal(item._id)}
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    sx={actionbutton}
                                                                                >
                                                                                    Renew
                                                                                </Button>) : (null)}
                                                                            <Button
                                                                                size="small"
                                                                                variant="contained"
                                                                                sx={actionbutton}
                                                                                onClick={(e) => handleVisibleDeleteConfirmationModel(item?._id)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </div>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        })}
                                                    </>
                                                ) : (
                                                    <StyledTableRow>
                                                        <td
                                                            colSpan={13}
                                                            style={{ padding: "2.6rem 1rem" }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    fontSize: "1.6rem",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                No License Found
                                                            </Typography>
                                                        </td>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <ContainerLoader />
                                )}
                                <br />
                                {allLicensesList && allLicensesList.length > 0 && (
                                    <>
                                        {totalRecords > 0 && (
                                            <Pagination
                                                key="paginationnew"
                                                searchPage={searchPage}
                                                SearchDisable={SearchDisable}
                                                totalRecords={totalRecords}
                                                pageLimit={pageLimit}
                                                onPageChanged={onPageChanged}
                                            />
                                        )}
                                        <div
                                            style={{
                                                textAlign: "right",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <label>Items per page :</label>
                                            <FormControl
                                                sx={{
                                                    m: 1,
                                                    minWidth: 50,
                                                }}
                                                size="small"
                                            >
                                                <Select
                                                    defaultValue={pageLimit}
                                                    value={pageLimit}
                                                    name="limit"
                                                    sx={{
                                                        "& .MuiSelect-select": {
                                                            padding: "4px 34px 4px 14px!important",
                                                            fontFamily: "Barlow",
                                                        },
                                                    }}
                                                    onChange={(e) => changePageLimit(e)}
                                                >
                                                    <MenuItem value="10">10</MenuItem>
                                                    <MenuItem value="20">20</MenuItem>
                                                    <MenuItem value="50">50</MenuItem>
                                                    <MenuItem value="100">100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="space-top-col">
                        <Card
                            sx={{
                                width: "100%",
                                maxWidth: "100%",
                                color: "#000059!important",
                                textAlign: "left",
                                padding: "30px",
                                backgroundColor: "#fbfbfb;",
                            }}
                        >
                            <div className="vendortable-header">
                                <Typography
                                    variant="h6"
                                    sx={{
                                        width: "100%",
                                        maxWidth: "14rem",
                                        color: "#000059!important",
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.6rem",
                                        fontFamily: "Barlow!important",
                                    }}
                                >
                                    {" "}
                                    Generate Licenses
                                </Typography>
                                <Grid
                                    container
                                    spacing={{ xs: 1, md: 1, lg: 1 }}
                                    columns={{ xs: 4, sm: 8, md: 8 }}
                                    className="button-align"
                                >
                                    <Grid item>
                                        <Button
                                            type="button"
                                            size="large"
                                            variant="contained"
                                            onClick={(e) => handleCloseModel(e)}
                                            sx={{
                                                backgroundColor: "#002986!important",
                                                color: "white!important",
                                                textTransform: "none!important",
                                                fontWeight: "bold",
                                                fontFamily: "Barlow!important",
                                            }}
                                            startIcon={<ArrowBack />}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <CardContent
                                sx={{
                                    padding: "0px!important",
                                    marginTop: "30px!important",
                                }}
                                className="plan-tbl vendor-outer"
                            >
                                {!isLoading ? (
                                    <>
                                        <ValidatorForm
                                            onSubmit={() => handleSubmitGenerateLicense()}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="form-group select-outer">
                                                        <label className="Account_details_box">
                                                            Select Product
                                                        </label>
                                                        <Autocomplete
                                                            onChange={(event, newValue) => handleChangeProduct(newValue)}
                                                            fullWidth
                                                            name="productName"
                                                            options={allProducts}
                                                            getOptionLabel={(option) => option.product_name}
                                                            getOptionDisabled={(option) => option.is_active === 0
                                                            }
                                                            renderInput={(params) => (
                                                                <TextValidator
                                                                    value={productName}
                                                                    placeholder="Select Product"
                                                                    {...params}
                                                                    variant="outlined"
                                                                    validators={["required"]}
                                                                    errorMessages={
                                                                        "This field is required"
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="form-group select-outer">
                                                        <label className="Account_details_box">
                                                            No. of Licenses
                                                        </label>
                                                        <TextValidator
                                                            onChange={(e) => handleOnChangeNoOfLicense(e)}
                                                            variant="outlined"
                                                            placeholder="Enter No. of License"
                                                            fullWidth
                                                            type="number"
                                                            name="quantity"
                                                            value={formData.quantity}
                                                            validators={["required"]}
                                                            errorMessages={
                                                                "This field is required"
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="form-group select-outer">
                                                        <FormControl>
                                                            <label className="Account_details_box">
                                                                License Validity
                                                            </label>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={subscriptionType}
                                                                onChange={(e) => handleChangeOptionValue(e)}
                                                            >
                                                                <FormControlLabel
                                                                    value="recurring"
                                                                    control={<Radio />}
                                                                    label="One month"
                                                                />
                                                                <FormControlLabel
                                                                    value="custom"
                                                                    control={<Radio />}
                                                                    label="Custom"
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                {subscriptionType === "custom" && (
                                                    <Grid item xs={12} sm={6}>
                                                        <div className="form-group select-outer">
                                                            <label className="Account_details_box">
                                                                Select Validity
                                                            </label>
                                                            <Grid item xs sx={{ display: "flex" }}>
                                                                <Grid item xs sm={9}>
                                                                    <TextValidator
                                                                        fullWidth
                                                                        type="number"
                                                                        placeholder={`Enter ${selectedOption.name}`}
                                                                        name="licenseValidity"
                                                                        value={licenseValidity}
                                                                        onChange={(e) => handleOnChangeLicenseValidity(e)}
                                                                        validators={["required"]}
                                                                        errorMessages={
                                                                            "This field is required"
                                                                        }
                                                                    />
                                                                </Grid>
                                                                &nbsp;
                                                                <Grid item xs sm={3}>
                                                                    <Autocomplete
                                                                        disableClearable={true}
                                                                        fullWidth
                                                                        options={validity}
                                                                        getOptionLabel={(option) => option.name}
                                                                        value={selectedOption}
                                                                        onChange={(event, newValue) => {
                                                                            setSelectedOption(newValue);
                                                                            setLicenseValidity('');
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextValidator
                                                                                {...params}
                                                                                placeholder="Select License Validity"
                                                                                variant="outlined"
                                                                                value={selectedOption}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                )}
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6} sx={{ marginLeft: "16px" }}>
                                                        <div className="form-group select-outer">
                                                            <label className="Account_details_box">
                                                                Assign License to User
                                                            </label>
                                                            <Autocomplete
                                                                onChange={(event, newValue) => handleChangeVendor(newValue)}
                                                                fullWidth
                                                                name="vendor"
                                                                options={allUsers}
                                                                getOptionLabel={(option) => option.email}
                                                                getOptionDisabled={(option) => option.is_active === 0
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextValidator
                                                                        {...params}
                                                                        value={formData.user.email ?? null}
                                                                        placeholder="Assign License to User"
                                                                        variant="outlined"
                                                                        validators={["required"]}
                                                                        errorMessages={
                                                                            "This field is required"
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12}
                                                sx={{
                                                    textAlign: "center",
                                                    marginTop: '20px'
                                                }}
                                            >
                                                <LoadingButton
                                                    type="submit"
                                                    size="large"
                                                    loading={disableSubmitButton}
                                                    endIcon={disableSubmitButton ? (<CircularProgress color="inherit" size={20} />) : ('')}
                                                    disabled={disableSubmitButton}
                                                    className={`${disableSubmitButton ? 'theme-btn-generate-licenses-disabled' : "theme-btn-generate-licenses"}`}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                >
                                                    <span>Generate Licenses</span>
                                                </LoadingButton>
                                            </Grid>
                                        </ValidatorForm>
                                    </>
                                ) : (
                                    <ContainerLoader />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {/* alert  message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
            {/* {Sync Licenese Confirmation Model} */}
            <ConfirmationModal
                handleAPIRequest={handleSyncLicenses}
                isOpen={isVisibleSyncModel}
                handleClose={handleCloseSyncConfirmationModel}
                message={"Are you sure you want to Sync Licenses?"}
                title={"Sync Licenses"}
                buttonText={"Sync"}
                buttonText2={"Cancel"}
            />
            {/* {Delete Licenese Confirmation Model} */}
            <ConfirmationModal
                handleAPIRequest={handleDeleteLicense}
                isOpen={isVisibleDeleteModel}
                handleClose={handleCloseDeleteConfirmationModel}
                message={"Are you sure you want to Delete License?"}
                title={"Delete License"}
                buttonText={"Continue"}
                buttonText2={"Cancel"}
            />
            {/* {Renew Licenese Confirmation Model} */}
            <ConfirmationModal
                handleAPIRequest={handleRenewalLicenseAPI}
                isOpen={isVisiableRenewalConfirModal}
                handleClose={handleCloseRenewalConfirmationModal}
                message={"Are you sure you want to Renew License?"}
                title={"Renew License"}
                buttonText={"Renew"}
                buttonText2={"Cancel"}
            />
        </>
    )

}
export default ManageLicenses;