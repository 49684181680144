import React from 'react';
import { Row, Container, } from 'react-bootstrap';
import { Helmet } from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './whatWeDo.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { Link } from 'react-router-dom';
import anonymous from '../../images/anonymous_gif.gif';
import hand_icon from '../../images/hand_icon.png';
import user_icon from '../../images/user_icon.png';
import phone_icon from '../../images/phone_icon.png';
import union_icon from '../../images/union_icon.png';
import image_14 from '../../images/image_14.png';
import mark from '../../images/mark.png';
import frame from '../../images/frame.png';
import ticket_master from '../../images/ticket_master.png';
import seatgeek_icon from '../../images/seatgeek_icon.png';
import skybox_icon from '../../images/skybox_icon.png';
import semi from '../../images/semi.png';
import test_user from '../../images/test_user.png';
import elispe from '../../images/elispe.png';
import vector from '../../images/vector.png';
import Vendor_1 from '../../images/Vendor_1.png';
import stubhub_1 from '../../images/stubhub_1.png';
import seatgeek_1 from '../../images/seatgeek_1.png';
import axs_1 from '../../images/axs_1.png';
import Ballpark from '../../images/Ballpark.png';
import ticket_network from '../../images/ticket_network.png';
import Ticket_education from '../../images/Ticket_education.png';
import Tickpick_1 from '../../images/Tickpick_1.png';
import Gametime_1 from '../../images/Gametime_1.png';
import Viago from '../../images/viago.png';
import fanexchange_1 from '../../images/fanexchange_1.png';
import vividseats_1 from '../../images/vividseats_1.png';
import { Box, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';





class Whatwedo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            openPageCheck: true,
            loader: false,
            open: false,
            message: '',
            successError: ''
        }

    }


    componentDidMount() {
        if (Cookies.get('accessToken')) {
            this.setState({
                openPageCheck: true
            })
        }
        else {
            const value = "TicketLiberty";
            if (value === this.state.password) {
                this.setState({
                    openPageCheck: true
                })
            }
            else {
                this.setState({
                    openPageCheck: false
                })
            }
        }

        // const value = "TicketLiberty";
        // if (value == this.state.password) {
        //     this.setState({
        //         openPageCheck: true
        //     })
        // }
        // else {
        //     this.setState({
        //         openPageCheck: false
        //     })
        // }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = () => {
        this.setState({
            loader: true
        })
        const value = "TicketLiberty";
        if (value === this.state.password) {
            this.setState({
                openPageCheck: true,
                loader: false,
                open: true,
                message: 'Password Validated Successfully',
                successError: 'success'
            })
        }
        else {
            this.setState({
                openPageCheck: false,
                loader: false,
                open: true,
                message: 'Password not validated',
                successError: 'error'
            })
        }
    }
    render() {
        const { loader } = this.state;
      if(!loader){  return (
            <>

                <Helmet>
                    <title>What We Do</title>
                </Helmet>

                <Header />
                    <div>


                        <section className="anonymous">
                            <Container>
                                <Row>
                                    <div className='anonymous_img'><img src={anonymous} alt=''/></div>
                                    <div className='anonymous_left_panel'>
                                        <h2>Anonymous Transfers<sup>TM</sup></h2>
                                        <p>The Most Advanced Delivery Service in the Industry. Utilizing web-based Anonymous Transfers™, Ticket Owners Can Send Rotating Barcodes While Retaining Ownership.The Best Way to Keep You Under the Radar, Safeguard Your Digital Assets, and Free You From Oppressive Ticketing Restrictions.</p>
                                        <button><a href='#ghg' style={{ scrollBehavior: "smooth" }}>Vendors we support</a></button>
                                    </div>
                                    <div className='anonymous_right_panel'>
                                        <img class='anonymous_bottom_img' src={elispe} alt='' />
                                        <img class='anonymous_right_img' src={elispe} alt='' />
                                    </div>
                                </Row>

                            </Container>
                        </section>
                        <section className="anonymous_steps">
                            <Container>
                                <Row>
                                    <div className='anonymous_steps_panel'>
                                        <div className='anonymous_left_img'><img src={vector} alt=''/></div>
                                        <div className='anonymous_right_img'><img src={Vendor_1} alt=''/></div>
                                        <div class='anonymous_steps_panel_row'>
                                            <span className="anonymous_img_tag"><img src={user_icon} alt=''/></span>
                                            <h2>Exclusive Signup</h2>
                                            <p>Be One of the Few Ticket Owners to be able to Anonymously Transfer Tickets using Ticket Liberty</p>
                                        </div>
                                        <div class='anonymous_steps_panel_row'>
                                            <span className="anonymous_img_tag"><img src={hand_icon} alt=''/></span>
                                            <h2>Easy to use</h2>
                                            <p>New and Improved User Interface and Efficient Automation for Smooth and Reliable Deliveries</p>
                                        </div>
                                        <div class='anonymous_steps_panel_row'>
                                            <span className="anonymous_img_tag"><img src={union_icon} alt=''/></span>
                                            <h2>Inventory Tracking</h2>
                                            <p>Track All Your Inventory and Deliveries on Ticket Liberty</p>
                                        </div>
                                        <div class='anonymous_steps_panel_row'>
                                            <span className="anonymous_img_tag"><img src={phone_icon} alt=''/></span>
                                            <h2>24/7 Support</h2>
                                            <p>Trained Customer Service Available 24/7 for Delivery Customer Support & Reliable Client Support</p>
                                        </div>
                                    </div>
                                </Row>

                            </Container>
                        </section>
                        <section className="deliver_service">
                            <Container>
                                <Row>
                                    <div className='deliver_service_panel'>
                                        <div className='deliver_service_left_panel'>
                                            <h2>All-In-One Delivery Service</h2>
                                            <p>Ticket Liberty works closely with all clients to design our technology specifically tailored to your needs.</p>
                                            <ul>
                                                <li><span><img src={mark} alt=''/></span> Add bulk vendor accounts and create an interactive inventory</li>
                                                <li><span><img src={mark} alt=''/></span> Track each order and receive a delivery confirmation</li>
                                                <li><span><img src={mark} alt=''/></span> Automate deliveries by connecting POS & Marketplace</li>
                                            </ul>
                                            <button><Link to="/login">Login</Link></button>
                                        </div>
                                        <div className='deliver_service_right_panel'>
                                            <img src={image_14} alt=''/>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </section>
                        <section className="automated_panel">
                            <Container>
                                <Row>
                                    <div className='deliver_service_panel'>
                                        <div className='deliver_service_right_panel automated_img'>
                                            <img src={frame} alt=''/>
                                        </div>
                                        <div className='deliver_service_left_panel automated_txt'>
                                            <h2>Automated Password Updater</h2>
                                            <p>Ticket Liberty works continuously to make our platform more feature-rich and easy to use. Our newest features include the ability to forward emails to allow our system to reset your password upon request</p>
                                            <ul>
                                                <li><span><img src={mark} alt='' /></span> Increase delivery success rate</li>
                                                <li><span><img src={mark} alt=''/></span> Ability to track all of your account credentials</li>
                                                <li><span><img src={mark} alt=''/></span> OTP forwarding ability for vendor authentication</li>
                                            </ul>
                                            <button><Link to="/contact">Contact Us</Link></button>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </section>
                        <section className="vendor_panel" id="ghg" >
                            <Container>
                                <Row>
                                    <h2>Vendors We Support</h2>
                                    <div className='vendor_panel_imgs'>
                                        <div className='vendor_panel_img_link first'><span><img src={ticket_master} alt=''/></span></div>
                                        <div className='vendor_panel_img_link'><span><img src={stubhub_1} alt=''/></span></div>
                                        <div className='vendor_panel_img_link'><span><img src={seatgeek_1} alt='' /></span></div>
                                        <div className='vendor_panel_img_link'><span><img src={axs_1} alt='' /></span></div>
                                        <div className='vendor_panel_img_link'><span><img src={Ballpark} alt=''/></span></div>
                                    </div>
                                </Row>
                            </Container>
                        </section>
                        <section className="vMarketplaces_panel">
                            <Container>
                                <Row>
                                    <h2>Marketplaces We Support</h2>
                                    <div className='vendor_panel_imgs'>
                                        <div className='vendor_panel_img_link first_a'><img src={vividseats_1} alt=''/></div>
                                        <div className='vendor_panel_img_link'><img src={seatgeek_icon} alt=''/></div>
                                        <div className='vendor_panel_img_link'><img src={ticket_network} alt=''/></div>
                                        <div className='vendor_panel_img_link'><img src={Ticket_education} alt=''/></div>

                                    </div>
                                    <div className='vendor_panel_imgs market_double'>
                                        <div className='vendor_panel_img_link'><img src={Tickpick_1} alt='' /></div>
                                        <div className='vendor_panel_img_link'><img src={Gametime_1} alt='' /></div>
                                        <div className='vendor_panel_img_link vendor-Img'><img src={Viago} alt=''/></div>
                                        <div className='vendor_panel_img_link'><img src={fanexchange_1} alt=''/></div>

                                    </div>
                                </Row>
                            </Container>
                        </section>

                        <section className="vendor_panel pos_panel">
                            <Container>
                                <Row>
                                    <h2>POS We Support</h2>
                                    <div className='vendor_panel_imgs'>
                                        <div className='vendor_panel_img_link'><img alt='' src={skybox_icon}  /></div>
                                    </div>
                                </Row>
                            </Container>
                        </section>
                        <section className="testimonial_panel">
                            <Container>
                                <Row>
                                    <h2>Our Client Testimonials</h2>
                                    <p>We are dedicated to working closely onevery order to ensure smooth transactions to safeguard your digital assets</p>
                                    <div className='testimonial_panel_group'>
                                        <div className='vendor_panel_img_link'>
                                            <img alt='' src={semi} />
                                            <p>Ticket Liberty has been a blessing to my season tickets</p>
                                            <img alt='' className='user_p' src={test_user} />
                                            <strong>Worried Ticket Owner</strong>
                                        </div>
                                        <div className='vendor_panel_img_link'>
                                            <img alt='' src={semi} />
                                            <p>I am able to confidently purchase valuable ticket I normally would stay away from  before I started using Ticket Liberty</p>
                                            <img alt='' className='user_p' src={test_user} />
                                            <strong>Experienced Ticket purchaser</strong>
                                        </div>
                                        <div className='vendor_panel_img_link'>
                                            <img alt='' src={semi} />
                                            <p>AnonymousTransfers<sup>TM</sup> saved my seasons ticketing account from being revoked</p>
                                            <img alt='' className='user_p' src={test_user} />
                                            <strong>NBA Seasons Ticket Holder</strong>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </section>
                        <section className="welcome_panel">
                            <Container>
                                <Row>
                                    <img alt='' class='welcome_left_img' src={elispe} />
                                    <img alt='' class='welcome_left_img_1' src={elispe} />
                                    <h2>Welcome To Ticket Liberty</h2>
                                    <button> <Link to="/contact">Request a demo</Link></button>
                                    <img alt='' class='welcome_right_img' src={elispe} />
                                    <img alt='' class='welcome_right_img_1' src={elispe} />
                                </Row>
                            </Container>
                        </section>










                        <Footer style={{ position: "absolute" }} />
                    </div>
                </>
        )}
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default Whatwedo;