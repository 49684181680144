import React from "react";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import ReactTypingEffect from "react-typing-effect";
import Typography from "@mui/material/Typography";
import Proxy from "images/landingpage/Group217.png";
import Unique from "images/landingpage/image68.png";
import Profile from "images/landingpage/Group218.png";
import Integrate from "images/landingpage/Group222.png";
import Queue from "images/landingpage/queue.svg";
import Account from "images/landingpage/Group225.png";
import AI from "images/landingpage/Group226.png";
import DropChecker from "images/landingpage/DropChecker.png";
import MapAnalytics from "images/landingpage/mapan.svg";
import Delivery from "images/landingpage/Delivery.png";
import Password from "images/landingpage/PasswordManagement.png";
import AutoPO from "images/landingpage/AutoPO.png";


import "./index.scss";
import { memo } from "react";

const Print = ({ scrollRef }) => {
  return (
    <Box className="PrintSection" >
      <Container sx={{ padding: "40px" }}>
        <Box className="txt-style" >
          <span className="txt_style" style={{ color: "#FFFFFFCC" }} ref={scrollRef} >
            Protect your Digital {' '}
            <span className="txt_style">
              <ReactTypingEffect
                eraseSpeed={100}
                speed={100}
                eraseDelay={1000}
                cursorClassName="cursor"
                text={["Fingerprint", "Assets", "Data", "Identity"]}
              />
            </span>
          </span>
        </Box>

        <Box sx={{ padding: "0 30px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={Proxy} alt={Proxy} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Proxy Management
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={Unique} alt={Unique} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Unique Fingerprints
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={Profile} alt={Profile} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Profile & Session Manager
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={DropChecker} alt={DropChecker} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Drop Checker
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={MapAnalytics} alt={MapAnalytics} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Map Analytics
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card className="fingerPrintGrid">
                <CardContent>
                  <img src={Queue} alt={Queue} style={{ paddingBottom: "17px" }} />
                  <Typography
                    variant="body1"
                    className="fingerSubTitle"
                    color="white"
                  >
                    Queue Tracker
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box className="coming-soon-wrapper">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="coming-soon-row"
          >
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={Integrate} alt={Integrate} />
              <Typography variant="body1" color="white">
                Integrated 2FA
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={Account} alt={Account} />
              <Typography variant="body1" color="white">
                Account Shield
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={Delivery} alt={Delivery} />
              <Typography variant="body1" color="white">
                Integrated Delivery
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={Password} alt={Password} />
              <Typography variant="body1" color="white">
                Password Management
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={AutoPO} alt={AutoPO} />
              <Typography variant="body1" color="white">
                Auto - PO
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} className="coming-soon-col">
              <img src={AI} alt={AI} />
              <Typography variant="body1" color="white">
                AI-Powered Assistant
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body1" color="white" sx={{ fontSize: "35px" }}>
            More Coming Soon...
          </Typography>
        </Box>
      </Container>
      <div
        style={{
          height: "100%",
          overflow: "hidden",
        }}
      >
        <span className="centered-radial"></span>
      </div>
    </Box>
  );
};

export default memo(Print);
