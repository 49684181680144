import React from 'react';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../redux/types/ActionTypes';
import Cookies from 'js-cookie';

const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(Cookies.get('accessToken') !== null){
            Cookies.remove("accessToken");
            dispatch({
                type: ActionTypes.USERPROFILE,
                payload: {
                    data: undefined,
                    message:"User Logged Out"
                }
            })
        }

        history.push('/login');
    });
    return (
        <div className="loader-min"><div className="loader"></div></div>
    );
}

export default Logout;

