import React, { Component } from "react";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import loaderGif from "images/GIF-Loader-3.gif"
import {
  Autocomplete,
  Button,
  Card,
  DialogActions,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { indigo, red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./instructions.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FormControl from "@mui/material/FormControl";
import { Editor } from "@tinymce/tinymce-react";
import { AiOutlineStop } from "react-icons/ai";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3858A0",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class InstructionSections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      section: "",
      open: false,
      message: "",
      successError: "",
      video: "",
      page: "",
      sectionList: [],
      openadd: false,
      pagename: "",
      pagestatus: true,
      openalert: false,
      alertseverity: "",
      allPages: [],
      openedit: false,
      editpagename: "",
      editpagestatus: true,
      editpageid: "",
      opendelete: false,
      deleteid: "",

      contentAdd: "",
      sectionAdd: "",
      openAdd: false,
      messageAdd: "",
      videoAdd: "",
      pageAdd: "",
      pageEdit: "",
      pageEditLabel: "",
      sectionEdit: "",
      sectionEditLabel: "",
      sectionListAdd: [],
      pagelistAdd: [],
      allSectionsListing: [],
      videoEdit: "",
      EditId: "",
      errMsg: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
    this.handleSelectPage = this.handleSelectPage.bind(this);
    this.handleToggleAdd = this.handleToggleAdd.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleAddPage = this.handleAddPage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getPages = this.getPages.bind(this);
    this.handleOpenEdit = this.handleOpenEdit.bind(this);
    this.handleToggleEdit = this.handleToggleEdit.bind(this);
    this.handleUpdatePage = this.handleUpdatePage.bind(this);
    this.handleDeletePage = this.handleDeletePage.bind(this);
    this.handleToggleDelete = this.handleToggleDelete.bind(this);
    this.editSection = this.editSection.bind(this);
  }

  handleClose = () => {
    this.setState({
      openalert: false,
    });
  };

  editSection = () => {
    const data = {
      name: this.state.sectionEditLabel,
      page: this.state.pageEdit,
      video: this.state.videoEdit,
      description: this.state.sectionEditDescription,
      _id: this.state.EditId,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/updateSection", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        this.setState({
          // openAdd: true,
          openedit: false,
        });
        this.getSectionsListing();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleOpenEdit = (event, item) => {
    this.setState({
      openedit: true,
      pageEdit: item.page._id,
      pageEditLabel: item.page.name,
      // editpagestatus: item.is_active ? true : false,
      editpageid: item._id,
      sectionEdit: item.id,
      sectionEditLabel: item.name,
      sectionEditDescription: item.description,
      videoEdit: item.video,
      EditId: item._id,
    });
  };
  handleUpdatePage = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.editpagename,
      is_active: this.state.editpagestatus ? 1 : 0,
      _id: this.state.editpageid,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/updatePage", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            editpagename: "",
            editpagestatus: true,
            // openedit: false
          });
          this.getPages();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleAddPage = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.pagename,
      is_active: this.state.pagestatus ? 1 : 0,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/addPage", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            pagename: "",
            pagestatus: true,
            openadd: false,
          });
          this.getPages();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getSectionsListing();
    this.getPages();
  }
  componentDidUpdate() { }
  handleToggleDelete = (id) => {
    this.setState({ opendelete: !this.state.opendelete });
    if (id) {
      this.setState({ deleteid: id });
    } else {
      this.setState({ deleteid: "" });
    }
  };

  handleDeletePage = () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/admin/deleteSection",
        { _id: this.state.deleteid },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            opendelete: false,
            deleteid: "",
          });
          this.getSectionsListing();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSectionsListing = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/user/listSections", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        this.setState({
          allSectionsListing: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPages = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/user/listPages", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        this.state.pagelistAdd = res?.data?.data?.map((itm) => ({
          ...this.state.pagelistAdd,
          label: itm.name,
          id: itm._id,
        }));
        this.setState({
          allPages: this.state.pagelistAdd,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleToggleEdit = () => {
    this.setState({
      openedit: !this.state.openedit,
      editpagename: "",
      editpagestatus: true,
      editpageid: "",
    });
  };
  handleToggleAdd = () => {
    this.setState({ openAdd: !this.state.openAdd });
  };
  handleChangeStatus = (event) => {
    console.log("event", event.target.checked);
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSelectPage = (event) => {
    console.log(event.explicitOriginalTarget);
    console.log(event.target);
    // const item = event.target.value;
    this.setState({ page: event.target.value });
    // if(item.child.length > 0){
    //     this.setState({ sectionList:item.child });
    // }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleSubmitAdd = (event) => {
    event.preventDefault();
    const data = {
      page: this.state.pageAdd,
      name: this.state.sectionAdd,
      description: this.state.contentAdd,
      video: this.state.videoAdd,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "/api/admin/addSection", data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("err");
          this.setState({
            openalert: true,
            alertseverity: "error",
            message: res.data.message,
          });
        } else if (!res.data.error) {
          this.handleToggleAdd();
          this.setState({
            openalert: true,
            alertseverity: "success",
            message: res.data.message,
            pagename: "",
            pagestatus: true,
            openadd: false,
            pageAdd: "",
            errMsg: false,
            sectionAdd: "",
            videoAdd: "",
          });
          this.getSectionsListing();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const modalstyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth: 600,
      minWidth: 100,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 3,
    };
    const Android12Switch = styled(Switch)(({ theme }) => ({
      padding: 8,
      "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
          content: '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: 16,
          height: 16,
        },
        "&:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          left: 12,
        },
        "&:after": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M19,13H5V11H19V13Z" /></svg>')`,
          right: 12,
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
      },
    }));

    const tablehead = {
      fontWeight: "bold",
      fontSize: "1rem",
      fontFamily: "Barlow  , sans-serif!important",
    };
    const tablecell = {
      fontSize: "1rem!important",
      fontFamily: "Barlow  , sans-serif!important",
    };
    return (
      <>
        <Helmet>
          <title>Manage Instruction Sections</title>
        </Helmet>
        {this.state.openedit === false ? (
          <div>
            {this.state.openAdd ? (
              <Card
                className="instruction-box"
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor: "background.paper",
                  boxShadow: 1,
                  borderRadius: 1,
                  p: 2,
                  mb: 5,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  maxWidth: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ pt: "5" }}
                  >
                    <Typography
                      variant="p"
                      component="h4"
                      gutterBottom
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: indigo[900],
                        textAlign: "left",
                        mb: 2,
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                    >
                      Add Section
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ pt: "5" }}
                  >
                    <ValidatorForm
                      onSubmit={this.handleSubmitAdd}
                      className="instruction-form"
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className="ins-form text-left"
                        >
                          <FormControl
                            sx={{ m: 1, width: "100%", height: "auto" }}
                            className="page-select"
                          >
                            <Autocomplete
                              disablePortal
                              id="combo-box"
                              onChange={(event, newValue) => {
                                this.setState({ pageAdd: newValue.id });
                                this.setState({ errMsg: false });
                              }}
                              options={this.state.pagelistAdd}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  placeholder="Select Page"
                                />
                              )}
                            />
                            {this.state.errMsg ? (
                              <FormHelperText sx={{ color: "#d32f2f" }}>
                                This field is required
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                          className="ins-form text-left"
                        >
                          <FormControl sx={{ m: 1, width: "100%" }}>
                            <TextValidator
                              id="outlined-basic"
                              label=""
                              placeholder="Add Section"
                              sx={{ width: "100%" }}
                              value={this.state.sectionAdd}
                              variant="outlined"
                              onChange={(e) =>
                                this.setState({ sectionAdd: e.target.value })
                              }
                              validators={["required"]}
                              errorMessages={["This field is required"]}
                            />
                            {/* <FormHelperText>Without label</FormHelperText> */}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          className="ins-form back-add-section"
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            startIcon={
                              <ArrowBackIcon
                                sx={{ fontSize: "1.5rem!important" }}
                              />
                            }
                            onClick={() => {
                              this.handleToggleAdd();
                              this.setState({ pageAdd: "", errMsg: false });
                            }}
                            size="medium"
                            sx={{
                              fontSize: "0.9rem!important",
                              fontWeight: "bold",
                              color: "#fff",
                              bgcolor: indigo[900],
                              float: "right",
                              textTransform: "capitalize",
                              py: 1,
                              fontFamily: "Barlow  , sans-serif!important",
                              "&:hover": {
                                bgcolor: indigo[900],
                                color: "#fff",
                              },
                            }}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Editor
                            apiKey="ox4jp745eo6k4whfbfxfk0675klim6a6i0vhupfcdmsshjcr"
                            onEditorChange={(e) => {
                              this.setState({ contentAdd: e });
                            }}
                            init={{
                              height: 500,
                              anchor_top: false,
                              anchor_bottom: false,
                              automatic_uploads: true,
                              file_picker_types: "file image media",
                              paste_data_images: true,
                              image_advtab: true,
                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                if (meta.filetype == "image") {
                                  var input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*");
                                  input.onchange = function () {
                                    var file = this.files[0];
                                    var reader = new FileReader();
                                    reader.onload = function () {
                                      callback(reader.result, {
                                        alt: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };
                                  input.click();
                                }
                              },
                              advlist_bullet_styles: "square",
                              advlist_number_styles:
                                "lower-alpha,lower-roman,upper-alpha,upper-r",
                              /* and here's our custom image picker*/
                              toolbar_mode: "sliding",
                            }}
                            // value={this.state.content}
                            menubar={[
                              "file edit view insert format tools table help",
                            ]}
                            plugins={[
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                              "textpattern",
                              "image",
                              "imageupload",
                              "link",
                              "help",
                              "wordcount",
                              "code",
                              "preview",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "paste",
                              "charmap",
                              "searchreplace",
                              "visualblocks",
                              "emoticons",
                              "directionality",
                              "visualchars",
                              "noneditable",
                              "nonbreaking",
                              "quickbars",
                              "toc",
                              "anchor",
                              "pagebreak",
                              "importcss",
                              "autosave",
                              "save",
                              "template",
                              "help",
                            ]}
                            toolbar={[
                              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TextValidator
                            variant="outlined"
                            fullWidth
                            name="video"
                            placeholder="Video Link"
                            value={this.state.videoAdd}
                            onChange={(e) =>
                              this.setState({ videoAdd: e.target.value })
                            }
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextValidator
                                            variant="outlined"
                                            fullWidth
                                            name="video"
                                            placeholder='Video'
                                            value={this.state.video}
                                            onChange={this.handleChange}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        />
                                    </Grid> */}
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Button
                            type="submit"
                            className="theme-btn"
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{
                              mt: 3,
                              mb: 2,
                              width: "100%",
                              padding: 4,
                              "@media screen and (max-width: 899px)": {
                                padding: "10px 50px !important",
                              },
                            }}
                            onClick={() => {
                              if (this.state.pageAdd === "") {
                                this.setState({ errMsg: true });
                              }
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                  </Grid>
                </Grid>
              </Card>
            ) : (
              <Card
                className="instruction-box"
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor: "background.paper",
                  boxShadow: 1,
                  borderRadius: 1,
                  p: 2,
                  mb: 5,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                  maxWidth: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ pt: "5" }}
                  >
                    <Typography
                      variant="p"
                      component="h4"
                      gutterBottom
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: indigo[900],
                        textAlign: "left",
                        mb: 2,
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                    >
                      Manage Instruction Sections
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ pt: "5" }}
                  >
                    <Button
                      variant="contained"
                      startIcon={
                        <AddIcon sx={{ fontSize: "1.5rem!important" }} />
                      }
                      onClick={() => this.handleToggleAdd()}
                      size="medium"
                      sx={{
                        fontSize: "0.9rem!important",
                        fontWeight: "bold",
                        color: "#fff",
                        bgcolor: indigo[900],
                        float: "right",
                        textTransform: "capitalize",
                        py: 1,
                        fontFamily: "Barlow  , sans-serif!important",
                        "&:hover": {
                          bgcolor: indigo[900],
                          color: "#fff",
                        },
                      }}
                    >
                      Add Section
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ pt: "5" }}
                  >
                    {this.state.allSectionsListing.length > 0 ? (
                      <>
                        <TableContainer
                          component={Paper}
                          sx={{
                            mb: 5,
                          }}
                        >
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  width="5%"
                                  component="th"
                                  scope="row"
                                  sx={tablehead}
                                >
                                  S.no
                                </StyledTableCell>
                                <StyledTableCell sx={tablehead}>
                                  Section Name
                                </StyledTableCell>
                                <StyledTableCell sx={tablehead}>
                                  Page Name
                                </StyledTableCell>
                                <StyledTableCell sx={tablehead}>
                                  Status
                                </StyledTableCell>
                                <StyledTableCell sx={tablehead}>
                                  Actions
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.allSectionsListing.map((row, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell
                                    width="5%"
                                    component="th"
                                    scope="row"
                                    sx={tablecell}
                                  >
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell sx={tablecell}>
                                    {row.name}
                                  </StyledTableCell>
                                  <StyledTableCell sx={tablecell}>
                                    {row.page.name}
                                  </StyledTableCell>
                                  <StyledTableCell sx={tablecell}>
                                    Active
                                    {/* {row.is_active === 1 ? "Active" : "Inactive"} */}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton
                                      aria-label="edit"
                                      onClick={(e) => this.handleOpenEdit(e, row)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() =>
                                        this.handleToggleDelete(row._id)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                              }

                              {this.state.allSectionsListing.length === 0 && (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={5} align="center">
                                    <div
                                      className="no-data-found"
                                      style={{
                                        padding: "20px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <h3
                                        style={{
                                          margin: "0",
                                        }}
                                      >
                                        No Items
                                      </h3>
                                      <AiOutlineStop
                                        style={{
                                          color: "rgb(227, 20, 20)",
                                          marginLeft: "10px",
                                          fontSize: "1.5rem",
                                        }}
                                      />
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "16rem 0",
                          }}
                        >
                          <div style={{ position: 'relative', top: '4px' }}>
                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                          </div>
                        </Box>
                      </>
                    )}

                    {/* Add Section */}
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={this.state.openadd}
                      onClose={() => this.handleToggleAdd()}
                      closeAfterTransition
                    >
                      <Fade in={this.state.openadd}>
                        <Box sx={modalstyle}>
                          <IconButton
                            aria-label="close"
                            onClick={() => this.handleToggleAdd()}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              color: (theme) => theme.palette.grey[500],
                              p: 1,
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <Typography
                            id="transition-modal-title"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "#1A237E",
                              textAlign: "left",
                              mb: 2,
                              fontFamily: "Barlow  , sans-serif!important",
                            }}
                            variant="h6"
                            component="h2"
                          >
                            Add Section
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <ValidatorForm
                                ref="form"
                                onSubmit={this.handleAddPage}
                              >
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <TextValidator
                                      label="Page Name"
                                      onChange={this.handleChange}
                                      name="pagename"
                                      value={this.state.pagename}
                                      variant="outlined"
                                      fullWidth
                                      validators={["required"]}
                                      errorMessages={["Page name is required"]}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <FormControlLabel
                                      // value={this.state.pagestatus}
                                      checked={this.state.editpagestatus}
                                      name="editpagestatus"
                                      onChange={(e) =>
                                        this.handleChangeStatus(e)
                                      }
                                      control={<Android12Switch />}
                                      label="Status"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <Button
                                      variant="contained"
                                      startIcon={<SaveIcon />}
                                      type="submit"
                                      size="medium"
                                      sx={{
                                        fontSize: "0.9rem!important",
                                        fontWeight: "bold",
                                        color: "#fff",
                                        bgcolor: indigo[900],
                                        float: "left",
                                        textTransform: "capitalize",
                                        py: 1,
                                        fontFamily:
                                          "Barlow  , sans-serif!important",
                                        "&:hover": {
                                          bgcolor: indigo[900],
                                          color: "#fff",
                                        },
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ValidatorForm>
                            </Grid>
                          </Grid>
                        </Box>
                      </Fade>
                    </Modal>
                    {/* Add Section End  */}
                    {/* Edit Section */}

                    {/* Edit Section End  */}
                    {/* Delete Section */}
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={this.state.opendelete}
                      onClose={() => this.handleToggleDelete()}
                      closeAfterTransition
                    >
                      <Fade in={this.state.opendelete}>
                        <Box sx={modalstyle}>
                          <IconButton
                            aria-label="close"
                            onClick={() => this.handleToggleDelete()}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              color: (theme) => theme.palette.grey[500],
                              p: 1,
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                          <Typography
                            id="transition-modal-title"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "#1A237E",
                              textAlign: "left",
                              mb: 2,
                              fontFamily: "Barlow  , sans-serif!important",
                            }}
                            variant="h6"
                            component="h2"
                          >
                            Delete Section
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography
                                variant="body1"
                                component="p"
                                sx={{
                                  fontSize: "1.1rem",
                                  textAlign: "left",
                                  fontFamily: "Barlow  , sans-serif!important",
                                }}
                              >
                                Are you sure you want to delete this section?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <DialogActions>
                                <Button
                                  sx={{
                                    fontSize: "0.9rem!important",
                                    fontWeight: "bold",
                                    color: "#fff",
                                    bgcolor: indigo[900],
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      bgcolor: indigo[900],
                                      color: "#fff",
                                    },

                                    fontFamily:
                                      "Barlow  , sans-serif!important",
                                  }}
                                  autoFocus
                                  onClick={() => this.handleToggleDelete()}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  sx={{
                                    fontSize: "0.9rem!important",
                                    fontWeight: "bold",
                                    color: "#fff",
                                    bgcolor: indigo[900],
                                    textTransform: "capitalize",
                                    "&:hover": {
                                      bgcolor: indigo[900],
                                      color: "#fff",
                                    },

                                    fontFamily:
                                      "Barlow  , sans-serif!important",
                                  }}
                                  onClick={() => this.handleDeletePage()}
                                  autoFocus
                                >
                                  Yes
                                </Button>
                              </DialogActions>
                            </Grid>
                          </Grid>
                        </Box>
                      </Fade>
                    </Modal>
                  </Grid>
                  <AlertMessage
                    open={this.state.openalert}
                    message={this.state.message}
                    severity={this.state.alertseverity}
                    closeCall={this.handleClose}
                  />
                </Grid>
              </Card>
            )}
          </div >
        ) : null
        }

        {/* Edit */}
        {
          this.state.openedit && this.state.openAdd === false ? (
            <Card
              className="instruction-box"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "background.paper",
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                mb: 5,
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                maxWidth: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ pt: "5" }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      id="transition-modal-title"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#1A237E",
                        textAlign: "left",
                        mb: 2,
                        fontFamily: "Barlow  , sans-serif!important",
                      }}
                      variant="h6"
                      component="h2"
                    >
                      Edit Section
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                      type="submit"
                      size="medium"
                      sx={{
                        fontSize: "0.9rem!important",
                        fontWeight: "bold",
                        color: "#fff",
                        bgcolor: indigo[900],
                        float: "left",
                        textTransform: "capitalize",
                        py: 1,
                        fontFamily: "Barlow  , sans-serif!important",
                        height: "40px",
                        "&:hover": {
                          bgcolor: indigo[900],
                          color: "#fff",
                        },
                      }}
                      onClick={() =>
                        this.setState({
                          openedit: false,
                        })
                      }
                    >
                      Back
                    </Button>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ValidatorForm ref="form" onSubmit={this.editSection}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControl
                              sx={{ m: 1, width: "100%" }}
                              className="page-select"
                            >
                              <Autocomplete
                                disablePortal
                                id="combo-box"
                                onChange={(event, newValue) => {
                                  this.setState({
                                    pageEdit: newValue.id,
                                    pageEditLabel: newValue.label,
                                  });
                                }}
                                value={this.state.pageEditLabel}
                                options={this.state.pagelistAdd}
                                sx={{ width: "100%" }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Page name" />
                                )}
                              />
                              {/* <FormHelperText>Without label</FormHelperText> */}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                              <TextValidator
                                id="outlined-basic"
                                label="Section name"
                                sx={{ width: "100%" }}
                                value={this.state.sectionEditLabel}
                                variant="outlined"
                                onChange={(e) =>
                                  this.setState({
                                    sectionEditLabel: e.target.value,
                                  })
                                }
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                              {/* <FormHelperText>Without label</FormHelperText> */}
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="text-left"
                            sx={{ ml: 2 }}
                          >
                            <FormControlLabel
                              // value={this.state.pagestatus}
                              checked={this.state.editpagestatus}
                              name="editpagestatus"
                              onChange={(e) => this.handleChangeStatus(e)}
                              control={<Android12Switch />}
                              label="Status"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Editor
                              value={this.state.sectionEditDescription}
                              onEditorChange={(e) => {
                                this.setState({ sectionEditDescription: e });
                              }}
                              init={{
                                height: 500,
                                automatic_uploads: true,
                                file_picker_types: "file image media",
                                paste_data_images: true,
                                image_advtab: true,
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    var input = document.createElement("input");
                                    input.setAttribute("type", "file");
                                    input.setAttribute("accept", "image/*");
                                    input.onchange = function () {
                                      var file = this.files[0];
                                      var reader = new FileReader();
                                      reader.onload = function () {
                                        callback(reader.result, {
                                          alt: file.name,
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    };
                                    input.click();
                                  }
                                },
                                advlist_bullet_styles: "square",
                                advlist_number_styles:
                                  "lower-alpha,lower-roman,upper-alpha,upper-r",
                                /* and here's our custom image picker*/
                                toolbar_mode: "sliding",
                              }}
                              // value={this.state.content}
                              menubar={[
                                "file edit view insert format tools table help",
                              ]}
                              plugins={[
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "textpattern",
                                "image",
                                "imageupload",
                                "link",
                                "help",
                                "wordcount",
                                "code",
                                "preview",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "paste",
                                "charmap",
                                "searchreplace",
                                "visualblocks",
                                "emoticons",
                                "directionality",
                                "visualchars",
                                "noneditable",
                                "nonbreaking",
                                "quickbars",
                                "toc",
                                "anchor",
                                "pagebreak",
                                "importcss",
                                "autosave",
                                "save",
                                "template",
                                "help",
                              ]}
                              toolbar={[
                                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | link imageupload | fontselect | fontsizeselect |fullscreen | insertdatetime | media | wordcount | charmap | searchreplace | visualblocks |  table | code | preview |emoticons |",
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              name="video"
                              placeholder="Video Link"
                              value={this.state.videoEdit}
                              onChange={(e) =>
                                this.setState({ videoEdit: e.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button
                              variant="contained"
                              startIcon={<SaveIcon />}
                              type="submit"
                              size="medium"
                              sx={{
                                fontSize: "0.9rem!important",
                                fontWeight: "bold",
                                color: "#fff",
                                bgcolor: indigo[900],
                                float: "left",
                                textTransform: "capitalize",
                                py: 1,
                                fontFamily: "Barlow  , sans-serif!important",
                                "&:hover": {
                                  bgcolor: indigo[900],
                                  color: "#fff",
                                },
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          ) : null
        }

        {/*  */}
      </>
    );
  }
}
