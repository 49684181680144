import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
import "react-quill/dist/quill.snow.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

const Label = (props) => (
  <Typography
    variant="body2"
    color="textSecondary"
    {...props}
    sx={{
      textAlign: "left",
      marginBottom: "10px",
      fontSize: "16px",
    }}
  />
);

const modalbtnStyle = {
  minWidth: "6rem!important",
  backgroundColor: "#001D7D!important",
  color: "white!important",
  fontFamily: "Barlow!important",
  textTransform: "none!important",
  fontSize: "1.1rem",
  fontWeight: "bold",
}

class Removecustomcredit extends React.Component {
  state = {
    loader: false,
    UserValues: [],
    user: [],
    credit: "",
    notes: "",
    userVal: null,
    isModalVisiable: false
  };
  async componentDidMount() {
    console.log("Iddddd", this.props.match.params.id);
    // User List Id
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    await axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        // console.log("User Data", data);
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const sorted = data.data.sort((a, b) => {
            if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
              return -1;
            if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
              return 1;
            return 0;
          });

          this.setState({
            loader: false,
            UserValues: sorted,
            open: false,
            message: "",
            successError: "success",
          });
          this.state.UserValues.map((data) => {
            if (data._id === this.props.match.params.id) {
              this.setState({
                userVal: data,
              });
            }
            return null;
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeNotes = (e) => {
    this.setState({
      notes: e.target.value,
    });
  };
  handleUserList = (e, val) => {
    val.map((data) => {
      // console.log("UserData", data);
      this.setState({
        user: [...this.state.user, data._id],
      });

      return null;
    });
  };
  handleChangeVendor = (e, val) => {
    this.setState({
      userVal: val,
    });
  };
  handleClose = (event, reason) => {
    this.setState({
      open: false,
    });
  };

  handleSubmit = async () => {
    this.setState({
      loader: true,
    });
    const data = {
      user: this.state.userVal._id,
      credit: this.state.credit,
      notes: this.state.notes,
    };

    const removeCustom_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/removeCredit";

    await axios
      .post(removeCustom_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            isModalVisiable: true
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };



  handleBackdropClick = (event) => {
    event.stopPropagation();
  };


  handleClosePaymentSuccessfull = () => {
    this.setState({ isModalVisiable: false })
  }

  render() {
    const { credit, loader } = this.state;
    // console.log("propsssss", this.props.match.params.id)
    // this.state.UserValues.map((data)=>{
    //     if(data._id === this.props.match.params.id){
    //         this.state.userVal = data.email + '' + data._id
    //     }
    // })
    return (
      <>
        <Helmet>
          <title>Remove Credit</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Remove Credit"
                action={
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, lg: 1 }}
                    columns={{ xs: 4, sm: 8, md: 8 }}
                    className="button-align"
                  >
                    <Grid item>
                      <Button
                        onClick={() => this.props.history.push(`/user-management-view/${this.props.match.params.id}`)}
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow!important",
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                {loader ? (
                  <ContainerLoader />
                ) : (
                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Label>User</Label>
                        {/* <Autocomplete
                                                                                    // onChange={this.handleChangeVendor}
                                                                                    fullWidth
                                                                                    name="vendorTypeVal" 
                                                                                    options={ticketMasterType} 
                                                                                    value={this.state.vendorTypeVal}
                                                                                    getOptionLabel={(option) => option.title}                                                                                
                                                                                    renderInput={(params) => 
                                                                                    <TextValidator   value={this.state.vendorTypeVal} {...params} variant="outlined" validators={['required']}
                                                                                    errorMessages={('This field is required')}/>}
                                                                                /> */}
                        <Autocomplete
                          onChange={this.handleChangeVendor}
                          fullWidth
                          name="userVal"
                          value={this.state.userVal}
                          options={this.state.UserValues}
                          // getOptionLabel={(option) => option.name + ' ( ' + option.email + ' ) '}
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => (
                            <TextValidator
                              value={this.state.userVal}
                              placeholder="Select Vendor"
                              {...params}
                              variant="outlined"
                              validators={["required"]}
                              errorMessages={"This field is required"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label>Remove Credit</Label>

                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="credit"
                          type="text"
                          value={credit}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "This field is required",
                            "only number allowed",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Label>User Note</Label>
                        <TextareaAutosize
                          name="notes"
                          className="text-area-cls"
                          onChange={this.handleChangeNotes}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ margin: "10px auto" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          fullWidth
                          size="large"
                          // disabled={submitted}
                          sx={{ paddingY: "0.9rem" }}
                          className="theme-btn-submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                )}
                <AlertMessage
                  open={this.state.open}
                  message={this.state.message}
                  severity={this.state.successError}
                  closeCall={this.handleClose}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          onClick={this.handleBackdropClick}
          aria-labelledby="customized-dialog-title"
          open={this.state.isModalVisiable}
        >
          <DialogContent>
            <Typography sx={{
              color: "#000059!important",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "25px",
              marginLeft: '1px',
              lineHeight: '17.4px',
              fontFamily: "Barlow!important",
            }}>
              <CheckCircle sx={{ width: '371px', height: "103px", color: 'green' }} />
            </Typography>
            <Typography sx={{
              color: "#000059!important",
              textAlign: "center",
              fontWeight: "500",
              fontSize: "20px",
              marginLeft: '1px',
              lineHeight: '26.4px',
              fontFamily: "Barlow!important",
            }}>
              Credit Removed successfully.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', marginBottom: '8px' }}>
            <Button sx={modalbtnStyle} onClick={this.handleClosePaymentSuccessfull}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Removecustomcredit;
