import React, { Component } from "react";
import loaderGif from "images/GIF-Loader-3.gif";
import "./DataLoader.scss";
export default class DataLoader extends Component {
  render() {
    return (
      <div
        className="dataloader"
        style={{
          textAlign: "center",
        }}
      >
        <img
          style={{ width: "9rem", paddingTop: "8rem", paddingBottom: "14rem" }}
          src={loaderGif}
          alt="no loader"
        />
      </div>
    );
  }
}
