import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// mui  icons components
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ArrowBack } from "@mui/icons-material";

// mui components
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material/";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";

//custom components
import AlertMessage from "../alert/alert";
import { ContainerLoader } from "components/common/ContainerLoader";

class AddProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: "",
        username_val: "",
        password_val: "",
        hostname_val: "",
        port_val: "",
        userselection: "all",
        user: [],
      },
      error: [],
      loader: true,
      open: false,
      message: "",
      successError: "",
      showPassword: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response.data;

        if (data) {
          this.setState({ loader: false, users: data, showform: true });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          message: "Something went wrong while accessing users list",
          alertseverity: "error",
          openalert: true,
        });
      });
  }
  handleTogglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleUserList = (e, newValue) => {
    const { formData } = this.state;
    if (newValue) {
      formData["user"] = [newValue._id];
    } else {
      formData["user"] = [];
    }
    this.setState({ formData });
  };

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    if (e.target.name === "sharewith") {
      if (e.target.value === "all") {
        formData["user"] = [];
      }
    }
    this.setState({ formData });
  };

  handleSubmit = async (e) => {
    this.setState({ loader: true }, () => {
      const add_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/addBrowserProxy";
      const header = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      };

      axios
        .post(add_api_url, this.state.formData, header)
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
              formData: {
                name: "",
                username_val: "",
                password_val: "",
                hostname_val: "",
                port_val: "",
                userselection: "all",
                user: [],
              },
            });
            this.props.history.push("/proxy-management");
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong, please try again",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { loader, open, message, successError, formData } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Add Proxy"
                action={
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, lg: 1 }}
                    columns={{ xs: 4, sm: 8, md: 8 }}
                    className="button-align"
                  >
                    <Grid item>
                      <Button
                        component={Link}
                        to="/proxy-management"
                        type="button"
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow!important",
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                {!loader ? (
                  <ValidatorForm onSubmit={this.handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left!important",
                            padding: "10px 0px",
                          }}
                        >
                          Name
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          placeholder="Enter Name"
                          onChange={this.handleChange}
                          name="name"
                          value={formData.name}
                          validators={["required"]}
                          errorMessages={["Name is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left!important",
                            padding: "10px 0px",
                          }}
                        >
                          Username
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          name="username_val"
                          placeholder="Enter Username"
                          value={formData.username_val}
                          validators={["required"]}
                          errorMessages={["User Name is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left!important",
                            padding: "10px 0px",
                          }}
                        >
                          Password
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          placeholder="Enter Password"
                          name="password_val"
                          type={this.state.showPassword ? "text" : "password"}
                          value={formData.password_val}
                          validators={["required"]}
                          errorMessages={[
                            "Password is required",
                            "Password must be at least 6 characters",
                          ]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleTogglePasswordVisibility}
                                  edge="end"
                                  aria-label="toggle password visibility"
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left!important",
                            padding: "10px 0px",
                          }}
                        >
                          Host
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          placeholder="Enter Host"
                          onChange={this.handleChange}
                          name="hostname_val"
                          value={formData.hostname_val}
                          validators={["required"]}
                          errorMessages={["Host is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            textAlign: "left!important",
                            padding: "10px 0px",
                          }}
                        >
                          Port
                        </Typography>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange}
                          placeholder="Enter Port"
                          name="port_val"
                          value={formData.port_val}
                          validators={["required"]}
                          errorMessages={["Port is required"]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="all"
                          name="userselection"
                          className="user_radio_dv"
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="Share to All"
                            onChange={(evt) => this.handleChange(evt)}
                          />
                          <FormControlLabel
                            value="particular"
                            control={<Radio />}
                            label="Share to Particular User"
                            onChange={(evt) => this.handleChange(evt)}
                          />
                        </RadioGroup>
                      </Grid>
                      {formData.userselection === "particular" && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                textAlign: "left!important",
                                padding: "10px 0px",
                              }}
                            >
                              User
                            </Typography>
                            <Autocomplete
                              id="tags-outlined"
                              name="user"
                              options={this.state.users || []}
                              getOptionLabel={(option) => option.email}
                              getOptionSelected={(option) => ({
                                label: option.email,
                                value: option.email,
                              })}
                              onChange={this.handleUserList}
                              renderInput={(params) => (
                                <TextValidator
                                  {...params}
                                  variant="outlined"
                                  name="user_select"
                                  value={formData.user}
                                  placeholder="Select User"
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={4} style={{ margin: "0 auto" }}>
                        <Button
                          fullWidth
                          size="large"
                          sx={{ paddingY: "0.9rem" }}
                          variant="contained"
                          type="submit"
                          className="theme-btn-submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                ) : (
                  <ContainerLoader />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* alert message */}
        <AlertMessage
          open={open}
          message={message}
          severity={successError}
          closeCall={this.handleClose}
        />
      </>
    );
  }
}

export default AddProxy;
