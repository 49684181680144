import React from "react";
import loaderGif from "images/GIF-Loader-3.gif";
import { Box } from "@mui/material";

export const ContainerLoader = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "16rem 0",
            }}
        >
            <div style={{ position: 'relative', top: '4px' }}>
                <img className="loader-size-table" src={loaderGif} alt="no loader" />
            </div>
        </Box>
    );
};
