import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
    Card,
    Grid,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    Box,
    CircularProgress,
} from "@mui/material";
import AlertMessage from "components/message/message";
import { useState } from "react";
import { useEffect } from "react";
const ManageScanLimit = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowform, setIsShowform] = useState(false);
    const [message, setMessage] = useState('');
    const [openalert, setOpenalert] = useState(false);
    const [alertseverity, setAlertseverity] = useState('');
    const [formData, setFormData] = useState({
        event_management_scan_limit: 0
    })


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: parseInt(e.target.value) })
    }


    const handleClose = (event, reason) => {
        setOpenalert(false)
    };
    const getSettingDetail = async () => {
        const account_api_url = process.env.REACT_APP_API_URL + "/api/setting/detail";
        try {
            const parms = {
                id: "60633a028f70d97537accede",
            };
            const response = await axios.post(account_api_url, parms, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                },
            })
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                setMessage("Something went wrong while accessing users list");
                setAlertseverity("error");
                setOpenalert(true);
            } else {
                setIsLoading(false);
                setIsShowform(true);
                setFormData({ event_management_scan_limit: data.data.event_management_scan_limit })
            }
        } catch (error) {
            setIsLoading(false);
            setMessage("Something went wrong while accessing users list");
            setAlertseverity("error");
            setOpenalert(true);
        }
    }

    useEffect(() => {
        getSettingDetail()
    }, [])

    const handleSubmitScanLimit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const addUser_api_url = process.env.REACT_APP_API_URL + "/api/setting/update";
        console.log(formData.event_management_scan_limit, 'formData')
        const parms = {
            id: "60633a028f70d97537accede",
            event_management_scan_limit: formData.event_management_scan_limit
        };
        try {
            const response = await axios.post(addUser_api_url, parms, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem('accessTokenAdmin'),
                },
            })
            const { data } = response;
            if (data.error) {
                setIsLoading(false);
                setMessage(data.message);
                setAlertseverity("error");
                setOpenalert(true);
            } else {
                setIsLoading(false);
                setMessage(data.message);
                setAlertseverity("success");
                setOpenalert(true);
                getSettingDetail()
            }
        } catch (error) {
            setIsLoading(false);
            setMessage("Something went wrong while submitting form");
            setAlertseverity("error");
            setOpenalert(true);
        }
    }

    if (!isLoading) {
        return (
            <>
                <Helmet>
                    <title>Settings</title>
                </Helmet>
                {isShowform && (
                    <Grid container spacing={2}>
                        <Grid item={true} xs={12}>
                            <Card>
                                <CardHeader
                                    title="Manage Scan Limit"
                                />
                                <Divider />
                                <CardContent>
                                    <ValidatorForm onSubmit={handleSubmitScanLimit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        textAlign: "left",
                                                        paddingBottom: "0.5rem",
                                                    }}
                                                >
                                                    Scan Limit
                                                </Typography>
                                                <TextValidator
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    name="event_management_scan_limit"
                                                    type="number"
                                                    value={formData.event_management_scan_limit}
                                                    validators={["required"]}
                                                    errorMessages={["Scan Limit  is required"]}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sx={{ margin: "0 auto" }}>
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    sx={{ paddingY: "0.9rem" }}
                                                    type="submit"
                                                    fullWidth
                                                    className="theme-btn-submit"
                                                >
                                                    Update Setting
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                    <AlertMessage
                                        open={openalert}
                                        message={message}
                                        severity={alertseverity}
                                        closeCall={handleClose}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "13rem 0",
            }}
        >
            <CircularProgress sx={{ color: "#002986" }} />
        </Box>
    );
}
export default ManageScanLimit;
