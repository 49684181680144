import moment from "moment";

const customDateFormat = (datestring) => {
  if (!datestring) {
    return "";
  }

  if (
    datestring.includes("undefined||undefined") ||
    datestring === "undefined||undefined"
  ) {
    return "Invalid date";
  }

  const containsTBD = datestring.includes("TBD");
  if (containsTBD) {
    return datestring === "" ? "" : "TBD";
  }

  const hasUndefined = datestring.includes("||undefined");
  if (hasUndefined) {
    datestring = datestring.replace("||undefined", "");
  }

  const hasUTC = datestring.includes("Coordinated Universal Time");
  if (hasUTC) {
    datestring = new Date(datestring.replace("||", "T")).toISOString();
  } else if (
    datestring.includes("||") &&
    !datestring.includes("T") &&
    !datestring.includes("Z")
  ) {
    datestring = datestring.replace("||", "T") + "Z";
  }

  // Remove unwanted text
  datestring = datestring
    .replace(" OFFSALE", "")
    .replace(" CANCELED", "")
    .replace(" ONSALE", "")
    .replace(" RESCHEDULED", "")
    .trim();
  const hasNoTime = /^\d{4}-\d{2}-\d{2}(?![T\d])/i.test(datestring);
  if (hasNoTime) {
    if (datestring.includes(".")) {
      const index = datestring.indexOf(".");
      datestring = datestring.substring(0, index).trim();
      const year = datestring.substring(0, 4);
      const month = datestring.substring(5, 7);
      const day = datestring.substring(8, 10);

      const formattedDate = `${month}-${day}-${year}`;
      return formattedDate;
    }
    return moment(`${datestring}T00:00:00`).format("MM-DD-YYYY");
  }

  var datetime = datestring.split("T");
  var retDate = "";
  if (datetime[0]) {
    retDate = moment(`${datetime[0]}T00:00:00Z`).tz('Asia/Kolkata').format("MM-DD-YYYY");
  }
  if (datetime[1]) {
    var timesplit = datetime[1].split(":");
    if (timesplit[0] > 11) {
      if (timesplit[0] > 12) {
        retDate += " " + (timesplit[0] - 12) + ":" + timesplit[1] + "PM";
      } else {
        retDate += " " + timesplit[0] + ":" + timesplit[1] + "PM";
      }
    } else {
      retDate += " " + timesplit[0] + ":" + timesplit[1] + "AM";
    }
  }

  return retDate;
};

export default customDateFormat;
