import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

// react-bootstrap and mui components
import { Row, Col, Container } from "react-bootstrap";
import Logo from "images/logo.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// custom components
import PasswordField from "components/passwordfield/passwordfield";
import "./Login.scss";
import AlertMessage from "components/message/message";
import { encodeParamsToBase64 } from "utils/base64";
import { getPublicIpAddress } from "utils/getIpAddress";
import UserProfileAction from "redux/actions/UserProfielAction";
import { useDispatch } from "react-redux";

const Login = (props) => {
  // const [clientId] = useState("744065062899-48kkdf6jnf1b5up756d9o60m6dkc98l0.apps.googleusercontent.com");
  const [formData, setFormData] = useState({
    password: "",
    email: "",
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [successError, setSuccessError] = useState("");
  const [loginTextCheck, setLoginTextCheck] = useState(false);
  const [redirectLoggedin, setRedirectLoggedin] = useState("/user-dashboard");
  const token = Cookies.get("accessToken");
  const ipAddress = localStorage.getItem("publicIPAddress");
  const [failedAttempts, setFailedAttempts] = useState(parseInt(localStorage.getItem(`attempts_${ipAddress}`)) || 0);
  const [lockoutTime, setLockoutTime] = useState(null);
  const dispatch = useDispatch()
  useEffect(() => {
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        var re = /.{6,}/;
        return re.test(value);
      });
    }
    if (token) {
      props.history.push("/user-dashboard");
    } else {
      props.history.push("/login");
    }
    if (props.location && props.location.state && props.location.state.from) {
      setRedirectLoggedin(props.location.state.from.pathname);
    }
    return () => {
      if (ValidatorForm.hasValidationRule("PasswordStrength")) {
        ValidatorForm.removeValidationRule("PasswordStrength");
      }
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Function to calculate lockout duration based on the number of failed attempts
  const getLockoutDuration = (failedAttempts) => {
    if (failedAttempts >= 10) {
      return 2 * 60 * 60 * 1000;  // 2 hours
    } else if (failedAttempts >= 8) {
      return 10 * 60 * 1000; // 10 minutes
    } else if (failedAttempts >= 4) {
      return 2 * 60 * 1000; // 2 minutes
    } else {
      return 0;
    }
  };

  const handleSubmit = async () => {
    setLoginTextCheck(true);
    const login_api_url = process.env.REACT_APP_API_URL + "/api/auth/signin";
    const parmas = {
      ...formData,
      ipAddress: ipAddress ?? null,
    }
    try {
      const response = await axios.post(login_api_url, parmas);
      const { data } = response;
      if (data.error) {
        setLoginTextCheck(false);
        const attempts = failedAttempts + 1;
        setFailedAttempts(attempts);
        localStorage.setItem(`attempts_${ipAddress}`, attempts);
        const attemptsFailed = [4, 8, 10];
        if (attemptsFailed.includes(attempts)) {
          const duration = getLockoutDuration(attempts);
          if (duration > 0) {
            const lockoutEnd = new Date(new Date().getTime() + duration);
            setLockoutTime(lockoutEnd);
            updateLockoutMessage(lockoutEnd);
            localStorage.setItem(`lockout_${ipAddress}`, lockoutEnd.getTime());
          }
        } else {
          setOpen(true);
          setMessage(data.message);
          setSuccessError("error");
        }
      } else {
        setOpen(true);
        setMessage(data.message);
        setSuccessError("success");
        setLoginTextCheck(true);
        Cookies.set('accessToken', data.accessToken, { expires: 30 });
        Cookies.set("user_login_Id", data.id);
        localStorage.setItem("user_login_Id", data.id)
        dispatch(UserProfileAction())
        const lastToken = { lastToken: data.accessToken, expriedToken: new Date() }
        const encodedTokenData = encodeParamsToBase64(JSON.stringify(lastToken));
        Cookies.set('at', encodedTokenData);
        localStorage.setItem(`attempts_${ipAddress}`, "0");
        localStorage.setItem(`lockout_${ipAddress}`, 0);
        localStorage.removeItem("isDropdownToggle");
        props.history.push(redirectLoggedin);
      }
    } catch (error) {
      setOpen(true);
      setMessage("Something went wrong, please try again");
      setSuccessError("error");
      setLoginTextCheck(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (ipAddress) {
      const storedLockoutTime = localStorage.getItem(`lockout_${ipAddress}`);
      const storedFailedAttempts = localStorage.getItem(`attempts_${ipAddress}`);
      const lockoutEnd = new Date(parseInt(storedLockoutTime, 10));
      if (storedFailedAttempts) {
        setFailedAttempts(parseInt(storedFailedAttempts, 10));
      }
      if (!(lockoutEnd && new Date() < lockoutEnd) && parseInt(storedFailedAttempts) === 11) {
        localStorage.setItem(`attempts_${ipAddress}`, "0");
        localStorage.setItem(`lockout_${ipAddress}`, 0);
        setLockoutTime(null);
        setMessage("");
        setOpen(false);
        setSuccessError("");
      } else {
        if (storedLockoutTime) {
          if (new Date() < lockoutEnd) {
            setLockoutTime(lockoutEnd);
            updateLockoutMessage(lockoutEnd);
          } else {
            localStorage.setItem(`attempts_${ipAddress}`, storedFailedAttempts);
          }
        }
      }
    } else {
      getPublicIpAddress();
    }
  }, [ipAddress]);

  // Update lockout message to show hours and minutes without a countdown
  const updateLockoutMessage = (lockoutEnd) => {
    if (lockoutEnd) {
      const now = new Date();
      const remainingTime = lockoutEnd - now;
      if (remainingTime > 0) {
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        setMessage(`Too many failed attempts. Please try again after ${hours ? `${hours} hours` : ''
          } ${minutes ? `${minutes} minutes` : seconds ? `${seconds} minutes` : ""}`);
        setSuccessError("error");
        setOpen(true);
      } else {
        setLockoutTime(null);
        setMessage("");
        setOpen(false);
        setSuccessError("");
      }
    }
  };

  useEffect(() => {
    getPublicIpAddress();
  }, []);


  // const responseGoogleSuccess = async (data) => {
  //   const datas = {
  //     email: data.profileObj.email,
  //     name: data.profileObj.name,
  //     google_id: data.profileObj.googleId,
  //     roles: ["user"],
  //   };
  //   const gmail_api_url = process.env.REACT_APP_API_URL + "/api/auth/googleSignin";

  //   try {
  //     const response = await axios.post(gmail_api_url, datas);
  //     const { data } = response;

  //     if (data.error) {
  //       setLoader(false);
  //       setOpen(true);
  //       setMessage(data.message);
  //       setSuccessError("error");
  //     } else {
  //       setLoader(false);
  //       setOpen(true);
  //       setMessage(data.message);
  //       setSuccessError("success");

  //       Cookies.set("accessToken", data.accessToken);
  //       Cookies.set("loginId", data.id);
  //       Cookies.set("accessToken", data.accessToken);
  //       Cookies.set("loginId", data.id);
  //       props.history.push("/");
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     setOpen(true);
  //     setMessage("Something went wrong, please try again");
  //     setSuccessError("error");
  //   }
  // };

  // const responseGoogleFailure = (data) => {
  //   console.log("Failure", data);
  // };

  return (
    <>
      <Helmet>
        <title>Login</title>
        <body className="Login-Bg" />
      </Helmet>
      <div className="Login-Outer login_background">
        <Container>
          <div className="Login-Outer-Padding login_background">
            <Row className="vertical-center justify-center animation_box">
              <Col md={6} className="Login-Right-boxs">
                <div className="Login-Right-Css">
                  <div className="center">
                    <div className="Email_left_logo">
                      <Link to="/">
                        <img src={Logo} alt="Logo" className="logo-size" />
                      </Link>
                    </div>
                    <h2 className="Welcome-Heading">Welcome to Ticket Liberty!</h2>
                    <h6>Please login to your account</h6>
                  </div>
                  <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <div className="login-input">
                          <label className="Email_wrap">E-mail</label>
                          <TextValidator
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            name="email"
                            value={formData.email}
                            validators={["required", "isEmail"]}
                            errorMessages={["Email is required", "Email is not valid"]}
                          />
                        </div>
                      </Col>

                      <Col md="12">
                        <div className="login-input">
                          <label className="Password_wrap">Password</label>
                          <PasswordField
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            name="password"
                            sx={{ backgroundColor: "transparent" }}
                            value={formData.password}
                            validators={["required", "PasswordStrength"]}
                            errorMessages={["Password is required", "Password must be at least 6 characters"]}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <Row className="align-items-center keep-login-label">
                          <Col md="7" sm="7" className="text-left keep-login-col">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleChange}
                                  name="checkedB"
                                  color="primary"
                                  className="check"
                                />
                              }
                              label="Remember me"
                            />
                          </Col>
                          <Col md="5" sm="5" className="forgot-col">
                            <div className="right forget">
                              {" "}
                              <Link to="/contact">Forgot Password ?</Link>{" "}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12" className="login_btn_box">
                        {loginTextCheck ? (
                          <Button variant="contained" className="disable-btn">
                            Processing......
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={lockoutTime && new Date() < lockoutTime}
                            className={lockoutTime && new Date() < lockoutTime ? "disable-btn" : "login-btn login_btn_border"}
                          >
                            Login
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </ValidatorForm>
                  <Row>
                    <Col md="12">
                      <div className="google-btn-outer">
                        {/* <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          disabled={submitted}
                          className="full-width google-btn"
                          onClick={this.loginSubmit}
                        >
                          <img src={google} className="google-logo-size" />
                          Sign in with Google
                        </Button> */}
                        {/*
                        <GoogleLogin
                          clientId={clientId}
                          buttonText="Sign in with Google"
                          onSuccess={responseGoogleSuccess}
                          onFailure={responseGoogleFailure}
                          cookiePolicy={'single_host_origin'}
                          className="gmail-login"
                        />
                        */}
                      </div>
                      <AlertMessage
                        open={open}
                        message={message}
                        severity={successError}
                        closeCall={handleClose}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Login;
