import React from 'react';
import { Navbar, Nav, Col, } from 'react-bootstrap';
import './header.scss';
import Logo from "images/ticket_liberty_logo.png";
import './announcement.scss'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LockResetIcon from '@mui/icons-material/LockReset';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserProfileAction from 'redux/actions/UserProfielAction';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import Cookies from 'js-cookie';
import AlertMessage from 'components/message/message';
import { Cancel, Visibility, VisibilityOff } from '@mui/icons-material';
import NegativeCreditModal from 'utils/negativeCreditModal';
import PropTypes from "prop-types";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({

    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}))

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginCheck: false,
            announcementText: "",
            status: "",
            readmore: false,
            userName: "",
            anchorEl: null,
            open: false,
            message: "",
            successError: "",
            isVisiableModal: false,
            whats_new_lat_text: "",
            anchorEl: null,
            invalidTokenBtn: true,
            o_password: "",
            password: "",
            c_password: "",
            error: [],
            loader: false,
            showPassword: false,
            newShowPassword: false,
            confirShowPassword: false,
            is_diabledBtn: false,
            errors: {
                o_password: [],
                password: [],
                confirmPassword: []
            },
            isFormValid: false,
            userAvailableCredit: 0,
            isOpenNegativeModal: false,
            userEnterAmount: 5000.00

        }

        this.getAnnouncement = this.getAnnouncement.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.logoutToggle = this.logoutToggle.bind(this)
        this.openMenu = this.openMenu.bind(this)
        this.handleBackdropClick = this.handleBackdropClick.bind(this);
        this.handleSubmitUpdatePassword = this.handleSubmitUpdatePassword.bind(this);
    }

    toggleMenu = (event) => {
        this.setState({ anchorEl: null })
    }

    logoutToggle = async (event) => {
        try {
            const logout_api_url = process.env.REACT_APP_API_URL + "/api/auth/userLogout"
            const res = await axios.get(logout_api_url, {
                headers: {
                    authorization: 'Bearer ' + Cookies.get('accessToken')
                }
            });
            const { data } = res;
            if (data?.error) {
                this.setState({
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                });
            } else {
                this.setState({ anchorEl: null })
                Cookies.remove('at')
                Cookies.remove('accessToken')
                Cookies.remove("user_login_Id");
                localStorage.removeItem("user_login_Id")
            }
        } catch (error) {
            this.setState({
                open: true,
                message: "Something went wrong",
                successError: "error",
            });
        }
    }

    openMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    componentDidMount() {
        this.getUserAvailableCredit()
        Cookies.get("accessToken")

        if (Cookies.get("accessToken")) {
            if (this.props.UserProfile?.data === undefined) {
                this.props.getUserProfile()
            }

            this.setState({
                loginCheck: true
            })
        }
        else {
            this.setState({
                loginCheck: false
            })
        }
        this.getAnnouncement()
    }

    getAnnouncement = () => {
        const account_api_url = process.env.REACT_APP_API_URL + "/api/setting/announcement";
        const settingId = {
            "id": "60633a028f70d97537accede"
        };
        axios.post(account_api_url, settingId, { headers: { authorization: 'Bearer ' + Cookies.get('accessToken') } })
            .then((response) => {
                const { data } = response;
                if (data.tokenExpired && data.error) {
                    Cookies.remove('at')
                    Cookies.remove('accessToken')
                    this.props.history.push("/login")
                } else if (data.success) {
                    this.setState({
                        announcementText: data.data.announcement_text,
                        status: data.data.announcement_status,
                        isVisiableModal: data.data.show_whats_new,
                        whats_new_lat_text: data.data.whats_new_lat_text
                    })
                }
            }).catch((error) => {
                console.log(error, 'error')
            })
    }

    handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    handleCloseModal = async () => {
        this.setState({
            isVisiableModal: false
        })
        try {
            const updata_user_api = process.env.REACT_APP_API_URL + "/api/user/updateUser";
            const res = await axios.post(updata_user_api, { read_whats_new: false }, {
                headers: {
                    authorization: 'Bearer ' + Cookies.get('accessToken')
                }
            });
            const { data } = res;
            if (data.error) {
                this.setState({
                    open: true,
                    message: "Something went wrong",
                    successError: "error",
                });
            } else {
                this.getAnnouncement()
            }
        } catch (error) {
            this.setState({
                open: true,
                message: "Something went wrong",
                successError: "error",
            });
        }
    }

    handleClose = (event, reason) => {
        this.setState({
            open: false,
        });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    handleClickNewShowPassword = () => {
        this.setState({ newShowPassword: !this.state.newShowPassword });
    }

    handleClickConfirShowPassword = () => {
        this.setState({ confirShowPassword: !this.state.confirShowPassword });
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleBackdropClick = (event) => {
        event.stopPropagation();
    };


    validatePassword = (password) => {
        const errors = [];
        if (password.length < 6) {
            errors.push("New Password length should have at least 6 characters");
        }
        return errors;
    };

    validateOldPassword = (o_password) => {
        const errors = [];
        if (o_password.length < 6) {
            errors.push("Old password length should have at least 6 characters");
        }
        return errors;
    };

    validate = () => {
        const errors = {
            o_password: this.validateOldPassword(this.state.o_password),
            password: this.validatePassword(this.state.password),
            confirmPassword: []
        };
        if (this.state.c_password) {
            if (this.state.password !== this.state.c_password) {
                errors.confirmPassword.push("Password didn't match");
            }
        } else {
            errors.confirmPassword.push("Confirm New Password  length should have at least 6 characters");
        }
        this.setState({ errors });
        return (
            errors.o_password.length === 0 &&
            errors.password.length === 0 &&
            errors.confirmPassword.length === 0
        );
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === 'o_password' || name === 'password' || name === 'c_password') {
                this.validate();
            }
        });
    };


    handleSubmitUpdatePassword = async (event) => {
        event.preventDefault();
        this.setState({ isFormValid: false });
        if (!this.validate()) {
            return;
        }
        this.setState({
            is_diabledBtn: true
        });
        try {
            const parmas = {
                old_password: this.state.o_password,
                new_password: this.state.password,
            };
            const changeUserPwd_api_url = process.env.REACT_APP_API_URL + "/api/user/changePassword";
            const response = await axios.post(changeUserPwd_api_url, parmas, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            })
            const { data } = response;
            if (data.error) {
                this.setState({
                    open: true,
                    message: "Something went wrong, Please try again!",
                    successError: "error",
                    is_diabledBtn: false
                });
            } else {
                this.setState({
                    open: true,
                    message: data.message,
                    successError: "success",
                });
                this.props.getUserProfile()
            }
        } catch (error) {
            this.setState({
                open: true,
                message: "Something went wrong, Please try again!",
                successError: "error",
                is_diabledBtn: false
            });
        }
    };

    getUserAvailableCredit = async () => {
        try {
            const get_user_available_credit = `${process.env.REACT_APP_API_URL}/api/user/availableCredit`;
            const res = await axios.post(get_user_available_credit, {}, {
                headers: {
                    authorization: "Bearer " + Cookies.get("accessToken"),
                },
            });
            const { data } = res;
            if (data?.error) {
                this.setState({
                    open: true,
                    message: "Something went wrong, Please try again!",
                    successError: "error",
                });
            } else {
                this.setState({
                    userAvailableCredit: parseFloat(data?.data)
                })
            }
        } catch (error) {
            this.setState({
                open: true,
                message: "Something went wrong, Please try again!",
                successError: "error",
            });
        }
    }

    render() {
        const { showPassword, newShowPassword, confirShowPassword, o_password, password, c_password, loginCheck, isOpenNegativeModal } = this.state;
        const { pathname } = this.props;
        const linkdesign = {
            color: "#002986!important",

            fontFamily: "Barlow",
            fontWeight: "500",
            "svg": {
                color: "#002986!important",
                fontSize: "1.3rem!important"
            },
            ":hover": {
                color: "#b60000!important",
                "svg": {
                    color: "#b60000!important"
                },
            },
        }


        return (
            <>
                {
                    this.state.status ? (
                        <div className='announcement-user'>
                            <h5>Announcement :
                            </h5>
                            <span>  {this.state.announcementText}</span>
                        </div>
                    ) : ""
                }
                <header>
                    <div style={{ width: "100%", paddingRight: "15px", paddingLeft: "15px" }}>
                        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="custom-header">
                            <div className="header-logo">
                                <Link to="/"><img src={Logo} alt="logo" className="logo-size" /></Link>
                            </div>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                </Nav>
                                <Nav>
                                    {loginCheck ? '' : (<Link className="nav-link" to="/">Home</Link>)}
                                    {loginCheck ? '' : <Link className="nav-link" to="/contact">
                                        Contact Us
                                    </Link>}
                                    {loginCheck ?
                                        <Box sx={{ textAlign: "left" }} className="navmenu">
                                            <Typography
                                                id="demo-customized-button"
                                                aria-controls={Boolean(this.state.anchorEl) ? 'demo-customized-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                                                className="navmenu-align"
                                                sx={{
                                                    backgroundColor: "transparent!important",
                                                    boxShadow: "none",
                                                    color: "#002986!important",
                                                    fontWeight: "500",
                                                    fontFamily: "Barlow!important",
                                                    textTransform: "capitalize",

                                                    cursor: "pointer",

                                                    textAlign: "left",
                                                    fontSize: "1.2rem",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    ":hover": {
                                                        boxShadow: "none!important",
                                                        color: "#b60000!important"
                                                    }
                                                }}
                                                onClick={(event) => this.openMenu(event)}
                                            >
                                                {this.props.UserProfile?.data?.name}
                                                <KeyboardArrowDownIcon />
                                            </Typography>
                                            <StyledMenu
                                                id="demo-customized-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'demo-customized-button',
                                                }}
                                                anchorEl={this.state.anchorEl}
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.toggleMenu}
                                            >
                                                <MenuItem onClick={this.toggleMenu}
                                                    component={Link}
                                                    sx={linkdesign}
                                                    to="/user-dashboard"
                                                    disableRipple
                                                >
                                                    <SpeedIcon
                                                    />
                                                    Dashboard
                                                </MenuItem>
                                                <MenuItem sx={linkdesign} component={Link} to="/notification" onClick={this.toggleMenu} disableRipple>
                                                    <NotificationsActiveIcon />
                                                    Notifications
                                                </MenuItem>
                                                <MenuItem sx={linkdesign} to="/profile" component={Link} onClick={this.toggleMenu} disableRipple>
                                                    <PersonIcon />
                                                    My Profile
                                                </MenuItem>
                                                <MenuItem sx={linkdesign} component={Link} to="/change-user-password" onClick={this.toggleMenu} disableRipple>
                                                    <LockResetIcon />
                                                    Change Password
                                                </MenuItem>
                                                <MenuItem sx={linkdesign} component={Link} to="/billing-info" onClick={this.toggleMenu} disableRipple>
                                                    <PaymentIcon />
                                                    My Billing Info
                                                </MenuItem>
                                                <MenuItem sx={linkdesign} component={Link} to="/logout" onClick={this.logoutToggle} disableRipple >
                                                    <LogoutIcon />
                                                    Log Out
                                                </MenuItem>
                                            </StyledMenu>
                                        </Box>
                                        :
                                        <Link className="nav-link" to="/login" >Log In</Link>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div >
                </header>
                {/* negative credit modal */}
                <NegativeCreditModal
                    isVisiableModal={
                        pathname === "/terms-and-conditions" ? false :
                            (this.state.userAvailableCredit < 0 ? true : false) &&
                            (this.props.UserProfile?.data?.negativeCreditBalance ?? false)
                    }
                    getNotifications={this.props.getNotifications}
                    getUserDetails={this.props.getUserDetails}
                    userAvailableCredit={this.state.userAvailableCredit}
                    userEnterAmount={this.state?.userEnterAmount}
                    getUserAvailableCredit={this.getUserAvailableCredit}
                />
                {/* change password modal */}
                <Dialog
                    maxWidth="sm"
                    onClick={this.handleBackdropClick}
                    open={(this.props.UserProfile?.data?.is_reset_password) === false ? true : false}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "100%",
                        },
                    }}
                >
                    <DialogTitle sx={{ padding: "6px 15px" }}>Change Password</DialogTitle>
                    <DialogContent sx={{ padding: "10px 2px" }}>
                        <DialogContentText>
                            <Col lg={12} md={12} className='text-justify'>
                                <Grid container spacing={2}>
                                    <Grid item xs="12">
                                        <form onSubmit={this.handleSubmitUpdatePassword}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={12} sx={{ paddingLeft: "15px!important" }}>
                                                    <div className="login-wrapper-changePassword">
                                                        <label>Old password</label>
                                                        <TextField
                                                            type={showPassword ? "text" : "password"}
                                                            name="o_password"
                                                            value={o_password}
                                                            onChange={this.handleChange}
                                                            error={!!this.state.errors.o_password.length}
                                                            helperText={this.state.errors.o_password.join(', ')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onMouseDown={this.handleMouseDownPassword}
                                                                            onClick={this.handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} sx={{ paddingLeft: "15px!important" }} >
                                                    <label>New Password</label>
                                                    <div className="login-wrapper-changePassword">
                                                        <TextField
                                                            type={newShowPassword ? "text" : "password"}
                                                            name="password"
                                                            value={password}
                                                            onChange={this.handleChange}
                                                            error={!!this.state.errors.password.length}
                                                            helperText={this.state.errors.password.join(', ')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onMouseDown={this.handleMouseDownPassword}
                                                                            onClick={this.handleClickNewShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {newShowPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} sx={{ paddingLeft: "15px!important" }}>
                                                    <div className="form-group login-wrapper-changePassword">
                                                        <label>Confirm New Password</label>
                                                        <TextField
                                                            type={confirShowPassword ? "text" : "password"}
                                                            name="c_password"
                                                            value={c_password}
                                                            onChange={this.handleChange}
                                                            error={!!this.state.errors.confirmPassword.length}
                                                            helperText={this.state.errors.confirmPassword.join(', ')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onMouseDown={this.handleMouseDownPassword}
                                                                            onClick={this.handleClickConfirShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {confirShowPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12}
                                                    sx={{
                                                        paddingTop: "0px!important",
                                                        display: "flex",
                                                        justifyContent: "end",
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        size="large"
                                                        disabled={this.state?.is_diabledBtn}
                                                        sx={{
                                                            background: this.state?.is_diabledBtn ? "#c7c7c7" : "#002583",
                                                            padding: {
                                                                xs: "8px 24px",
                                                                sm: "8px 24px",
                                                                md: "10px 35px",
                                                            },
                                                            textTransform: "capitalize!important",
                                                            fontFamily: "Bevan !important",
                                                            letterSpacing: "1px!important",
                                                            fontSize: "16px!important",
                                                        }}
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Col>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/* what's new password modal */}
                <Dialog
                    maxWidth="sm"
                    onClick={this.handleBackdropClick}
                    open={this.state.isVisiableModal}
                    onClose={this.handleCloseModal}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "100%",
                        },
                    }}
                >
                    <DialogTitle sx={{ padding: "16px 33px" }}>What's new</DialogTitle>
                    <DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={this.handleCloseModal}
                            sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                color: (theme) => theme.palette.grey[500],
                                p: 1,
                            }}
                        >
                            <Cancel />
                        </IconButton>
                        <DialogContentText>
                            <Col lg={12} md={12} className='text-justify'>
                                <p dangerouslySetInnerHTML={{ __html: this.state.whats_new_lat_text }} />
                            </Col>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseModal}
                            sx={{
                                backgroundColor: "#002986!important",
                                color: "white!important",
                                fontWeight: "bold",
                                fontFamily: "Barlow",
                                textTransform: "none",
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* alert message */}
                <AlertMessage
                    key={`alertbtn`}
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        UserData: state.User,
        UserProfile: state.UserProfile
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUserProfile: () => dispatch(UserProfileAction())
    }
}

Header.propTypes = {
    pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
    pathname: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);