import React from 'react'
import PropTypes from "prop-types";

// mui icons component
import { Cancel } from '@mui/icons-material'

// mui components
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from '@mui/material'

const DeleteConfirmationModal = ({
    handleDelete,
    isOpen,
    handleClose,
    text
}) => {
    return (
        <Dialog
            maxWidth="sm"
            open={isOpen}
            onClose={(e) => handleClose(e)}
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                },
            }}
        >
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={(e) => handleClose(e)}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: (theme) => theme.palette.grey[500],
                        p: 1,
                    }}
                >
                    <Cancel />
                </IconButton>
                <DialogContentText
                    sx={{
                        fontFamily: "Barlow",
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "black",
                    }}
                >
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    No
                </Button>
                <Button
                    onClick={(e) => handleDelete(e)}
                    sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow",
                        textTransform: "none",
                    }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DeleteConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

DeleteConfirmationModal.defaultProps = {
    isOpen: false,
    text: "Delete",
    handleDelete: () => { },
    handleClose: () => { },
};
export default DeleteConfirmationModal;
