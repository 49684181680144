import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import axios from 'axios';
import AlertMessage from '../alert/alert';
import { Row, Col, } from 'react-bootstrap';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
} from '@coreui/react'
import '../common.css';
import { Helmet } from "react-helmet";
import { Box, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class Changepassadmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidTokenBtn: true,
            o_password: '',
            password: '',
            c_password: '',
            error: [],
            loader: false,
            open: false,
            message: '',
            successError: '',
            showPassword: false,
            newShowPassword: false,
            confirShowPassword: false,
        }

    }

    componentDidMount() {
        if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
                if (value !== this.state.password) {
                    return false;
                }
                return true;
            });
        }
        if (!ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.addValidationRule('PasswordStrength', (value) => {
                var re = /.{6,}/;
                return re.test(value);
            });
        }
    }
    componentWillUnmount() {
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
        if (ValidatorForm.hasValidationRule('PasswordStrength')) {
            ValidatorForm.removeValidationRule('PasswordStrength');
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = async () => {
        this.setState({
            loader: true
        })
        const data = {
            old_password: this.state.o_password,
            new_password: this.state.password
        }
        const changeUserPwd_api_url = process.env.REACT_APP_API_URL + "/api/admin/changePassword"
        await axios.post(changeUserPwd_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({
                        loader: false,
                        open: true,
                        message: data.message,
                        successError: 'error'
                    })
                }
                else {
                    this.setState({
                        loader: false,
                        open: true,
                        message: response.data.message,
                        successError: 'success',
                    })
                    this.setState({
                        o_password: '',
                        password: '',
                        c_password: ''
                    })
                }
            }).catch((error) => {
                this.setState({
                    loader: false,
                    open: true,
                    message: "Something went wrong",
                    successError: 'error',
                })
            })

    }
    handleClose = (event, reason) => {
        this.setState({
            open: false
        })
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    handleClickNewShowPassword = () => {
        this.setState({ newShowPassword: !this.state.newShowPassword });
    }

    handleClickConfirShowPassword = () => {
        this.setState({ confirShowPassword: !this.state.confirShowPassword });
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    render() {
        const { o_password, c_password, password, loader } = this.state
        if (!loader) {
            return (
                <>
                    <Helmet>
                        <title>
                            Change Password
                        </title>
                    </Helmet>
                    <CRow>
                        <CCol xs="12" sm="12">
                            <CCard style={{ width: "100%" , paddingTop:"10px"}}>
                                <CCardHeader>
                                    <h3 style={{marginLeft:"17px"}}>Change Password</h3>
                                </CCardHeader>
                                <CCardBody>
                                    <ValidatorForm
                                        onSubmit={this.handleSubmit}
                                    >
                                        <Row>
                                            <Col md={12}>
                                                <div className="form-group ">
                                                    <label>Old password</label>
                                                    <TextValidator
                                                        className='old-password'
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{
                                                            // removing backgorund color 
                                                            backgroundColor: "transparent !important"
                                                        }}
                                                        onChange={this.handleChange}
                                                        name="o_password"
                                                        value={o_password}
                                                        validators={['required']}
                                                        errorMessages={('this field is required')}
                                                        type={this.state.showPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickShowPassword}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    {this.state.error.o_password &&
                                                        <div className="error-msg">{this.state.error.o_password}</div>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-group ">
                                                    <label>New Password</label>
                                                    <TextValidator
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        name="password"
                                                        type={this.state.newShowPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickNewShowPassword}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.newShowPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        value={password}
                                                        validators={['required', 'PasswordStrength']}
                                                        errorMessages={[('this field is required'), ('password must be at least 6 character')]}
                                                    />
                                                    {this.state.error.password &&
                                                        <div className="error-msg">{this.state.error.password}</div>
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-group">
                                                    <label>Confirm New Password</label>
                                                    <TextValidator
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        name="c_password"
                                                        type={this.state.confirShowPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={this.handleClickConfirShowPassword}
                                                                        onMouseDown={this.handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.confirShowPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        value={c_password}
                                                        validators={['isPasswordMatch', 'required']}
                                                        errorMessages={[('password mismatch'), ('this field is required')]}
                                                    />
                                                    {this.state.error.c_password &&
                                                        <div className="error-msg">{this.state.error.c_password}</div>
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    className="full-width login-btn update-btn"
                                                >
                                                    Change password
                                                </Button>
                                            </Col>
                                        </Row>
                                    </ValidatorForm>
                                    <AlertMessage
                                        open={this.state.open}
                                        message={this.state.message}
                                        severity={this.state.successError}
                                        closeCall={this.handleClose}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </>
            )
        }
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default Changepassadmin;