import React from "react";
import { Helmet } from "react-helmet";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Cookies from "js-cookie";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

class Setuserpassword extends React.Component {
  state = {
    invalidTokenBtn: true,
    o_password: "",
    password: "",
    c_password: "",
    error: [],
    loader: false,
    showPassword: false,
    newShowPassword: false,
    confirShowPassword: false,
  };

  componentDidMount() {
    ValidatorForm?.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('PasswordStrength', (value) => {
      var re = /.{6,}/;
      return re.test(value);
    });
  }

  componentWillUnmount() {
    if (ValidatorForm?.hasValidationRule("isPasswordMatch")) {
      ValidatorForm?.removeValidationRule("isPasswordMatch");
    }
    if (ValidatorForm?.hasValidationRule("PasswordStrength")) {
      ValidatorForm?.removeValidationRule("PasswordStrength");
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async () => {
    this.setState({
      loader: true,
    });
    const data = {
      old_password: this.state.o_password,
      new_password: this.state.password,
    };
    const changeUserPwd_api_url =
      process.env.REACT_APP_API_URL + "/api/user/changePassword";

    await axios
      .post(changeUserPwd_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
          });
          Cookies.remove('at')
          Cookies.remove('accessToken')
          this.props.history.push("/login")
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong, please try again",
          successError: "error",
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickNewShowPassword = () => {
    this.setState({ newShowPassword: !this.state.newShowPassword });
  }

  handleClickConfirShowPassword = () => {
    this.setState({ confirShowPassword: !this.state.confirShowPassword });
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { o_password, c_password, password, loader } = this.state;
    return (
      <>
        <div className="user-dashboard-wrapper">
          <Helmet>
            <title>Change Password</title>
          </Helmet>
          <Header />
          <div className="c-app c-default-layout user-dashboard-body">
            <Usersidebar />
            <div className="c-wrapper">
              <div className="c-body">
                <main className="c-main">
                  <Grid container spacing={2}>
                    <Grid item xs="12">
                      <Card
                        sx={{
                          p: 1,
                        }}
                      >
                        <CardHeader title=" Change Password" />
                        <CardContent>
                          {loader ? (
                            <ContainerLoader />
                          ) : (
                            <ValidatorForm onSubmit={this.handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper-changePassword">
                                    <label>Old password</label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="o_password"
                                      value={o_password}
                                      validators={["required"]}
                                      errorMessages={"Old password is required"}
                                      type={this.state.showPassword ? 'text' : 'password'}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                              onMouseDown={this.handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                    {this.state.error.o_password && (
                                      <div className="error-msg">
                                        {this.state.error.o_password}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={0} sm={6}></Grid>
                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper-changePassword">
                                    <label>New Password</label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="password"
                                      value={password}
                                      validators={[
                                        "required",
                                        "PasswordStrength",
                                      ]}
                                      errorMessages={[
                                        "New password is required",
                                        "password must be at least 6 character",
                                      ]}
                                      type={this.state.newShowPassword ? 'text' : 'password'}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickNewShowPassword}
                                              onMouseDown={this.handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {this.state.newShowPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                    {this.state.error.password && (
                                      <div className="error-msg">
                                        {this.state.error.password}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={0} sm={6}></Grid>
                                <Grid item xs={12} sm={6}>
                                  <div className="form-group login-wrapper-changePassword">
                                    <label>Confirm New Password</label>
                                    <TextValidator
                                      variant="outlined"
                                      fullWidth
                                      onChange={this.handleChange}
                                      name="c_password"
                                      value={c_password}
                                      validators={['isPasswordMatch', 'required']}
                                      errorMessages={['Password mismatch', 'Confirm password is required']}
                                      type={this.state.confirShowPassword ? 'text' : 'password'}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={this.handleClickConfirShowPassword}
                                              onMouseDown={this.handleMouseDownPassword}
                                              edge="end"
                                            >
                                              {this.state.confirShowPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                    {this.state.error.c_password && (
                                      <div className="error-msg">
                                        {this.state.error.c_password}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={0} sm={6}></Grid>
                                <Grid item xs={12} sm={6}
                                  sx={{
                                    textAlign: "center"
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    sx={{
                                      padding: {
                                        xs: "8px 24px",
                                        sm: "8px 24px",
                                        md: "10px 35px",
                                      },
                                      textTransform: "capitalize!important",
                                      fontFamily: "Bevan !important",
                                      letterSpacing: "1px!important",
                                      fontSize: "16px!important",
                                    }}
                                  >
                                    Change Password
                                  </Button>
                                </Grid>
                              </Grid>
                            </ValidatorForm>
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <AlertMessage
                    open={this.state.open}
                    message={this.state.message}
                    severity={this.state.successError}
                    closeCall={this.handleClose}
                  />
                </main>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
export default Setuserpassword;
