import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import customDateFormat from "utils/customDateFormat";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class AdminVendorEventTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      open: false,
      message: "",
      alertcolor: "",
      event: {},
      lists: [],
      filterlists: [],
      generateTicketMsg: "",
      generateTicketMsgAlertColor: "",
      generateTicketMsgOpen: false,
      generateTicketData: {
        customerDisplayName: "",
        performer_name: "",
        invoiceExternalRef: "",
        invoiceDate: "",
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        deliverymsg_time: "immediate",
        ticketData: [],
        ticketIds: [],
      },
      generateTicketModal: false,
    };
  }

  componentDidMount() {
    this.getEventTickets();
  }

  getEventTickets = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL +
      "/api/admin/account/getAccountEventTickets";
    axios
      .post(
        list_api_url,
        { id: this.props.match.params.id },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data.data,
            filterlists: data.data,
            event: data.vbdevent,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  render() {
    const { filterlists, event, loader } = this.state;
    const fields = [
      { key: "section", label: "Section" },
      { key: "row", label: "Row" },
      { key: "seat", label: "Seat" },
      { key: "is_verified", label: "Verified" },
      { key: "status", label: "Status" },
      { key: "view", label: "View" },
    ];

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Vendor Events Tickets</title>
          </Helmet>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Vendor Events Tickets" />
                <Divider />
                <CardContent>
                  <div className="flex">
                    {event.event_name && (
                      <span style={{ paddingRight: "20px" }}>
                        <strong>Event Title: </strong>
                        {event.event_name}
                      </span>
                    )}

                    {event.location && (
                      <span style={{ paddingRight: "20px" }}>
                        <strong>Location: </strong>
                        {event.location}
                      </span>
                    )}

                    {event.date && (
                      <span style={{ paddingRight: "20px" }}>
                        <strong>Date: </strong>
                        {customDateFormat(event.date)}
                      </span>
                    )}
                    <br />
                    {event.status && (
                      <span>
                        {event.status.includes("Scan Completed: ") &&
                        event.fetch_tickets_scheduler === "start" ? (
                          <>
                            <strong>Status: </strong>Scan Completed:{" "}
                            {filterlists.length > 0
                              ? "loading more tickets"
                              : "loading tickets"}
                          </>
                        ) : (
                          <>
                            <strong>Status: </strong>
                            {event.status}
                          </>
                        )}
                      </span>
                    )}
                  </div>

                  <DataTable
                    responsive
                    items={filterlists.sort(
                      (a, b) =>
                        a.section.localeCompare(b.section) ||
                        a.row.localeCompare(b.row) ||
                        parseInt(a.seat) - parseInt(b.seat)
                    )}
                    fields={fields}
                    pagination
                    className="vendor-td"
                    scopedSlots={{
                      is_verified: (item) => {
                        return <td>{item.is_verified ? "Yes" : "No"}</td>;
                      },
                      status: (item) => {
                        return (
                          <td>
                            {item.soldinventory ? (
                              <span>Sold</span>
                            ) : item.delivery_revoked ? (
                              <span>Revoked/Available</span>
                            ) : (
                              <>
                                {item.is_verified ? (
                                  <span>Available</span>
                                ) : (
                                  <span>Not Available</span>
                                )}
                              </>
                            )}
                          </td>
                        );
                      },
                      view: (item) => {
                        return (
                          <td>
                            {item.sold_detail && (
                              <a
                                href={item.sold_detail.ticketurl}
                                target="_blank"
                                class="blue-btn"
                                rel="noreferrer"
                              >
                                Ticket
                              </a>
                            )}{" "}
                            &nbsp;
                            {item.soldinventory && (
                              <a
                                href={`/sold-inventory-management?refId=${item.soldinventory}`}
                                target="_blank"
                                class="blue-btn"
                                rel="noreferrer"
                              >
                                Sale
                              </a>
                            )}
                          </td>
                        );
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default AdminVendorEventTickets;
