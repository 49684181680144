import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import { Helmet } from "react-helmet";
import "../common.css";
import {
    Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "components/DataTable/DataTable";
class TextRecoginition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      modalShow: false,
      // modal:false,
      deleteId: "",
      deleting: false,
      loader: true,
      loadervendor: true,
      open: false,
      message: "",
      alertcolor: "",
      modalform: {
        show: false,
        id: "",
        title: "",
        name: "",
        type: "",
        submitting: false,
        formalert: false,
        formmessage: "",
        formalertcolor: "",
      },
      textForm: {
        _id: "",
        title: "",
        response: "",
      },
      textTitle: "Add Text",
    };
  }

  componentDidMount() {
    this.getLists();
  }

  getLists = () => {
    const list_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/getTextRecognitions";
    axios
      .get(list_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            alertcolor: "error",
          });
        } else {
          console.log(data);
          let reco = data.data.map((e) => {
            if (e.response === "positive") {
              e.response = "Positive";
              return e;
            } else if (e.response === "negative") {
              e.response = "Negative";
              return e;
            } else if (e.response === "") {
              e.response = "No Response";
              return e;
            }
            return null;
          });
          this.setState({
            loader: false,
            lists: reco,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  setModalShow = (id) => {
    this.setState({ modalShow: true, deleteId: id });
  };

  onHide = () => {
    this.setState({ modalShow: false, deleteId: "" });
  };

  callDelete = () => {
    console.log(this.state.deleteId);
    this.setState({ deleting: true });
    const data = { _id: this.state.deleteId };
    const delete_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/deleteTextRecognition";
    axios
      .post(delete_api_url, data, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.data.message,
            alertcolor: "error",
          });
        } else {
          if (data.success) {
            this.setState({
              modalShow: false,
              open: true,
              message: "Deleted successfully!",
              alertcolor: "success",
              deleting: false,
            });
            this.getLists();
          } else {
            this.setState({
              loader: false,
              open: true,
              message: "Something went wrong",
              alertcolor: "error",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          alertcolor: "error",
        });
      });
  };

  setModalFormShow = (id, title, response, editTitle) => {
    const modalform = { show: true };
    const textForm = { title: title, response: response, _id: id };
    this.setState({ modalform, textForm, textTitle: editTitle });
  };

  onHideForm = () => {
    const modalform = {
      show: false,
      id: "",
      title: "",
      name: "",
      type: "",
      submitting: false,
    };
    this.setState({ modalform });
  };

  handleSubmit = () => {
    let text_form = {
      title: this.state.textForm.title,
      response:
        this.state.textForm.response === ""
          ? "positive"
          : this.state.textForm.response,
    };
    let form_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/addTextRecognition";
    let successmsg = "Added successfully!";

    if (this.state.textForm._id !== "") {
      text_form = {
        title: this.state.textForm.title,
        _id: this.state.textForm._id,
        response: this.state.textForm.response.toLocaleLowerCase(),
      };
      form_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/updateTextRecognition";
      successmsg = "Updated successfully!";
    }

    this.setState({ loader: true }, () => {
      axios
        .post(form_api_url, text_form, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            console.log(response);
            let { modalform } = this.state;
            this.setState({
              modalShow: false,
              open: true,
              message: successmsg,
              alertcolor: "success",
            });
            this.getLists();
            modalform = { show: false, submitting: false };
            this.getLists();
            const modalform_new = {
              _id: "",
              title: "",
              response: "",
            };
            this.setState({
              modalShow: false,
              textForm: modalform_new,
              modalform,
              open: true,
              message: successmsg,
              alertcolor: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            modalShow: false,
            open: true,
            message: "Something went wrong!",
            alertcolor: "error",
            loader: false,
          });
        });
    });
  };

  handleChange = (evt) => {
    const { textForm } = this.state;
    textForm[evt.target.name] = evt.target.value;
    this.setState({ textForm: this.state.textForm });
  };

  render() {
    const { lists, loader, modalform, textForm } = this.state;
    const fields = [
      { key: "text" , label: "Text" },
      { key: "response type" , label: "Response Type" },
      {
        key: "action",
        label: "Action",
        sorter: false,
        filter: false,
      },
    ];

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>Text Recognition</title>
          </Helmet>
          <Collapse in={this.state.open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ open: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={this.state.alertcolor}
              sx={{ mb: 2 }}
            >
             {this.state.message}
            </Alert>
          </Collapse>
          

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader
                  title= "Text Recognition"
                  action={
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      onClick={() =>
                        this.setModalFormShow("", "", "", "Add text")
                      }
                      startIcon={<AddIcon />}
                    >
                      Add Text
                    </Button>
                  }
                />
                <Divider />

                <CardContent>
                  <DataTable
                    tableFilter
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    paginationLimit
                    scopedSlots={{
                      text: (item) => {
                        return item.title;
                      },
                      "response type": (item) => {
                        return item.response;
                      },
                      action: (item, index, val) => {
                        return (
                        
                            <div className="flex">
                              <IconButton
                                aria-label="edit"
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                                onClick={() => {
                                  this.setModalFormShow(
                                    item._id,
                                    item.title,
                                    item.response,
                                    "Edit text"
                                  );
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => this.setModalShow(item._id)}
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                         
                        );
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={this.state.modalShow}
            onClose={this.onHide}
          >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={() => this.onHide()}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: (theme) => theme.palette.grey[500],
                  p: 1,
                }}
              >
                <CancelIcon />
              </IconButton>
              <DialogContentText>
                Are you sure you want to Delete, this action can not be revert ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onHide}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={this.callDelete}
                sx={{
                  backgroundColor: "#002986!important",
                  color: "white!important",
                  fontWeight: "bold",
                  fontFamily: "Barlow",
                  textTransform: "none",
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth={true}
            scroll="body"
            maxWidth="sm"
            open={modalform.show}
            onClose={this.onHideForm}
          >
            <DialogTitle>{this.state.textTitle}</DialogTitle>
            <ValidatorForm onSubmit={this.handleSubmit}>
              <DialogContent>
                <IconButton
                  aria-label="close"
                  onClick={() => this.onHideForm()}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>

                <div>
                  <label>Text</label>
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    name="title"
                    onChange={this.handleChange}
                    value={textForm.title}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>

                <div style={{ marginTop: "0.7rem" }}>
                  <label>Response</label>
                  <FormControl fullWidth>
                    <Select
                      defaultValue={
                        textForm.response === ""
                          ? "positive"
                          : textForm.response.toLocaleLowerCase()
                      }
                      onChange={this.handleChange}
                    >
                      <MenuItem value="positive">Positive</MenuItem>
                      <MenuItem value="negative">Negative</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.onHideForm}
                  size="large"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                    paddingX: "1.1rem",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={modalform.submitting}
                  size="large"
                  sx={{
                    backgroundColor: "#002986!important",
                    color: "white!important",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textTransform: "none",
                    paddingX: "1.1rem",
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default TextRecoginition;
