import React from "react";
import AdminDrawer from "components/AdminDrawer/AdminDrawer";

const DashboardLayout = ({ children }) => {
  return (
    <AdminDrawer childern={children} />
  );
};

export default DashboardLayout;
