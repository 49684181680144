import { SaveAlt } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Grid,
    Typography,
    Card,
    TableContainer,
    TableCell,
    tableCellClasses,
    Table,
    TableHead,
    TableBody,
    Paper,
    TableRow,
    Button,
    InputAdornment,
    IconButton,
    Box,
    TableSortLabel
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { styled } from "@mui/styles";
import axios from "axios";
import loaderGif from "images/GIF-Loader-3.gif";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { HiOutlineArrowNarrowDown, HiOutlineSearch } from "react-icons/hi";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePicker from "react-datepicker";
import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import moment from 'moment-timezone'
import AlertMessage from "components/message/message";
import { changeDollarAmountformat } from "utils/changeDollarFormat";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3858A0",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontFamily: "Barlow !important",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableCells = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3858A0",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontFamily: "Barlow !important",
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 14,
        fontWeight: "bold",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const CreditUsage = () => {
    const [usersBaseMonthsBalances, setUserBaseMonthsBalances] = useState([]);
    const [totalMonthlyBalances, setTotalMonthlyBalances] = useState([])
    const [calculatorMonthlyActiveUsers, setCalculatorMonthlyActiveUsers] = useState([])
    const [totalYearBalance, setTotalYearBalance] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(true);
    const [crossCheck, setCrossCheck] = useState(true)
    const [message, setMessage] = useState({
        alertseverity: '',
        message: '',
        openalert: false
    });
    const [state, setState] = useState({
        currentColumn: null,
        activeasc: false,
        activedesc: false,
        styleInactive: false,
        direction: '',
    });

    const getUserBaseData = async () => {
        try {
            const get_user_base_data = `${process.env.REACT_APP_API_URL}/api/admin/creditUsageUserbasis`;
            setIsLoading(true);
            const response = await axios.post(get_user_base_data, {
                year: moment(selectedDate).format("YYYY"),
            }, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
                }
            });
            const { data } = response;
            if (data.error) {
                setMessage({
                    ...message,
                    message: 'Something went wrong',
                    openalert: true,
                    alertseverity: "error"
                })
                setIsLoading(false);
            } else {
                const monthNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];

                const filteredUsers = data?.users?.filter((userItem) => {
                    const userId = userItem._id;
                    const dataIds = data?.data?.flatMap((item) => Object.keys(item?.data));
                    return dataIds.includes(userId);
                });
                const usersBalances = [];
                for (const userItem of filteredUsers) {
                    const balance = {
                        name: userItem.name,
                        email: userItem.email,
                        totalMonthlyBalance: 0,
                        user_code: userItem.user_code,
                        data: {},
                    };
                    for (const monthData of data.data) {
                        const month = monthData._id;
                        const userData = monthData.data[userItem._id];
                        if (userData) {
                            const balanceValue = parseFloat(userData); // Use parseFloat to handle decimal values
                            balance.data[monthNames[month - 1]] = balanceValue;
                            balance.totalMonthlyBalance += balanceValue;
                        }
                    }
                    // Find the user's balance from userBalancesss array and add it to the balance object
                    const userBalanceData = data.balance.find((balanceItem) =>
                        balanceItem._id.includes(userItem._id)
                    );
                    if (userBalanceData) {
                        balance.remainingBalance = parseFloat(userBalanceData.balance);
                    }
                    usersBalances.push(balance);
                }

                // calculator  total  balance  one year
                const totalYearBalance = usersBalances?.map(datum => datum?.totalMonthlyBalance).reduce((a, b) => a + b)
                // const totalRemainingBalance = usersBalances.map(datum => datum.remainingBalance).reduce((a, b) => a + b)

                // calculator monthly total balances 
                const usersTotalsMonthlyBalances = data?.data?.map(item => {
                    const userData = item.data;
                    const total = Object.values(userData)
                        .filter(value => typeof value === ('string' || 'number') && !isNaN(parseFloat(value)))
                        .map(parseFloat)
                        .reduce((acc, val) => acc + val, 0);
                    return {
                        _id: item._id,
                        total: total.toFixed(2)
                    };
                });

                // calculator monthly active users 
                const calculatorMonthlyActiveUser = data?.data?.map((item) => {
                    const userData = item.data;
                    const dataLength = Object.keys(userData)?.length;

                    return {
                        _id: item._id,
                        activeUsersMonthly: dataLength
                    };
                });

                // sorting data a to  z  by user name 
                const sorted = usersBalances.sort((a, b) => {
                    if (a.user_code < b.user_code)
                        return -1;
                    if (a.user_code > b.user_code)
                        return 1;
                    return 0;
                });
                setTotalYearBalance(totalYearBalance)// set data user monthly base
                setCalculatorMonthlyActiveUsers(calculatorMonthlyActiveUser) // set data active user monthly
                setTotalMonthlyBalances(usersTotalsMonthlyBalances) // set data user monthly total Balances
                setUserBaseMonthsBalances(sorted)
                setIsLoading(false)
            }
        } catch (error) {
            setMessage({
                ...message,
                message: 'Something went wrong',
                openalert: true,
                alertseverity: "error"
            })
            setIsLoading(false);
        }
    }

    const exportToCSV = (data, totalMonthlyBalances, totalYearBalance) => {
        const csv = convertToCSV(data, totalMonthlyBalances, totalYearBalance);
        const file = new Blob([csv], { type: "text/csv" });
        saveAs(file, "data.csv");
    }

    const formatMonthValue = (monthData) => {
        return typeof monthData === 'number' ? monthData ?? 0 : 0;
    }

    const formatMonthValueRow = (monthData) => {
        return typeof monthData ? monthData ?? 0 : 0;
    }

    const convertToCSV = (data, totalMonthlyBalancess, totalYearBalance) => {
        const dataFiltered = data.map((item) => {
            const user_code = formatMonthValue(item.user_code);
            const January = formatMonthValue(item.data.January);
            const February = formatMonthValue(item.data.February);
            const March = formatMonthValue(item.data.March);
            const April = formatMonthValue(item.data.April);
            const May = formatMonthValue(item.data.May);
            const June = formatMonthValue(item.data.June);
            const July = formatMonthValue(item.data.July);
            const August = formatMonthValue(item.data.August);
            const September = formatMonthValue(item.data.September);
            const October = formatMonthValue(item.data.October);
            const November = formatMonthValue(item.data.November);
            const December = formatMonthValue(item.data.December);
            const totalMonthlyBalance = formatMonthValue(item.totalMonthlyBalance);
            const remainingBalance = formatMonthValue(item.remainingBalance);
            return {
                "User Code": '\t' + user_code,
                "User Name": item.name,
                "Email": item.email,
                "January": '\t' + January,
                "February": '\t' + February,
                "March": '\t' + March,
                "April": '\t' + April,
                "May": '\t' + May,
                "June": '\t' + June,
                "July": '\t' + July,
                "August": '\t' + August,
                "September": '\t' + September,
                "October": '\t' + October,
                "November": '\t' + November,
                "December": '\t' + December,
                "Total": '\t' + totalMonthlyBalance,
                "Remaining (Balance)": '\t' + remainingBalance
            };
        });
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        // Concatenate the data and footer active users
        const objActiveUsers = {};
        calculatorMonthlyActiveUsers?.forEach((item) => {
            const monthIndex = item._id - 1; // Adjust for 0-based index
            if (monthIndex >= 0 && monthIndex < monthNames.length) {
                objActiveUsers[monthNames[monthIndex]] = `${item.activeUsersMonthly}`;
            }
        });
        const footerActiveUsers = [{
            "User Code": "Active Users",
            "January": '\t' + formatMonthValueRow(objActiveUsers.January),
            "February": '\t' + formatMonthValueRow(objActiveUsers.February),
            "March": '\t' + formatMonthValueRow(objActiveUsers.March),
            "April": '\t' + formatMonthValueRow(objActiveUsers.April),
            "May": '\t' + formatMonthValueRow(objActiveUsers.May),
            "June": '\t' + formatMonthValueRow(objActiveUsers.June),
            "July": '\t' + formatMonthValueRow(objActiveUsers.July),
            "August": '\t' + formatMonthValueRow(objActiveUsers.August),
            "September": '\t' + formatMonthValueRow(objActiveUsers.September),
            "October": '\t' + formatMonthValueRow(objActiveUsers.October),
            "November": '\t' + formatMonthValueRow(objActiveUsers.November),
            "December": '\t' + formatMonthValueRow(objActiveUsers.December),
        }];

        const combinedDataActiveUsers = dataFiltered.concat(footerActiveUsers);

        // Concatenate the data and footer
        const obj = {};
        totalMonthlyBalancess?.forEach((item) => {
            const monthIndex = item._id - 1; // Adjust for 0-based index
            if (monthIndex >= 0 && monthIndex < monthNames.length) {
                obj[monthNames[monthIndex]] = `${item.total}`;
            }
        });
        const footer = [{
            "User Code": "Total",
            "January": '\t' + formatMonthValueRow(obj.January),
            "February": '\t' + formatMonthValueRow(obj.February),
            "March": '\t' + formatMonthValueRow(obj.March),
            "April": '\t' + formatMonthValueRow(obj.April),
            "May": '\t' + formatMonthValueRow(obj.May),
            "June": '\t' + formatMonthValueRow(obj.June),
            "July": '\t' + formatMonthValueRow(obj.July),
            "August": '\t' + formatMonthValueRow(obj.August),
            "September": '\t' + formatMonthValueRow(obj.September),
            "October": '\t' + formatMonthValueRow(obj.October),
            "November": '\t' + formatMonthValueRow(obj.November),
            "December": '\t' + formatMonthValueRow(obj.December),
            "Total": '\t' + formatMonthValueRow(totalYearBalance),
        }];
        // Concatenate the data and footer
        const combinedData = combinedDataActiveUsers.concat(footer);
        return Papa.unparse(combinedData);
    };

    const hnadleOnChangeSetStartDate = (date) => {
        setSelectedDate(date)
        setCrossCheck(false)
    };

    const handleClearDate = () => {
        setSelectedDate(null)
        setCrossCheck(true)
    };

    const handleClose = () => {
        setMessage({
            ...message,
            openalert: true,
        })
    };

    const handleRotateIcon = (columnname) => {
        if (state.currentColumn === columnname) {
            if (!state.styleInactive) {
                sortDataByMonths({ column: columnname, asc: true });
                setState({
                    ...state,
                    styleInactive: true,
                    activeasc: false,
                    activedesc: false,
                    direction: 'asc',
                });
            } else if (state.styleInactive && !state.activeasc) {
                sortDataByMonths({ column: columnname, asc: false });
                setState({
                    ...state,
                    activeasc: true,
                    activedesc: false,
                    direction: 'desc',
                });
            } else if (state.styleInactive && state.activeasc) {
                sortDataByMonths({ column: columnname, asc: true });
                setState({
                    ...state,
                    activeasc: false,
                    activedesc: false,
                    direction: 'asc',
                });
            }
        } else {
            setState({
                ...state,
                currentColumn: columnname,
                activeasc: false,
                activedesc: false,
                styleInactive: false,
                direction: '',
            });
            sortDataByMonths({ column: columnname, asc: true });
            setState({
                ...state,
                styleInactive: true,
                currentColumn: columnname,
                direction: 'asc',
            });
        }
    };

    const sortDataByMonths = (parmes) => {
        const sortedData = [...usersBaseMonthsBalances];
        sortedData.sort((a, b) => parmes.asc === true ? compareByMonthAsc(a, b, parmes.column) : compareByMonthDsc(a, b, parmes.column));
        setUserBaseMonthsBalances(sortedData);
    }

    const compareByMonthAsc = (a, b, month) => {
        if (month === "remainingBalance") {
            const aValue = a[month] || 0;
            const bValue = b[month] || 0;
            return aValue - bValue;
        } else {
            const aValue = a.data[month] || 0;
            const bValue = b.data[month] || 0;
            return aValue - bValue;
        }
    };

    const compareByMonthDsc = (a, b, month) => {
        if (month === "remainingBalance") {
            const aValue = a[month] || 0;
            const bValue = b[month] || 0;
            return bValue - aValue;
        } else {
            const aValue = a.data[month] || 0;
            const bValue = b.data[month] || 0;
            return bValue - aValue;
        }
    };

    const userCodeFormattedNumber = (number) => {
        return String(number).padStart(3, "0");
    };

    useEffect(() => {
        getUserBaseData();
    }, [])

    return (
        <>
            <Helmet>
                <title>Credit Usage User Basis</title>
            </Helmet>
            <Card
                className="instruction-box"
                sx={{
                    width: "100%",
                    height: "100%",
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 2,
                    mb: 5,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    maxWidth: "100%",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ pt: "5" }}>
                        <Typography
                            variant="p"
                            component="h4"
                            gutterBottom
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                color: indigo[900],
                                textAlign: "left",
                                mb: 2,
                                fontFamily: "Barlow  , sans-serif!important",
                            }}
                        >
                            Credit Usage User Basis
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ pt: "5" }}
                    >
                        <ValidatorForm
                            onSubmit={getUserBaseData}
                            className="filter-form mb-5"
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 2, lg: 2 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3}
                                        lg={3}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                height: "100%",
                                            },
                                        }}
                                    >
                                        <DatePicker
                                            placeholderText="Select Year"
                                            selected={selectedDate}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            isClearable={true}
                                            yearItemNumber={9}
                                            customInput={
                                                <TextValidator
                                                    validators={["required"]}
                                                    sx={{
                                                        "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                            color: "#C4C4C4"
                                                        },
                                                    }}
                                                    errorMessages={["This field is required"]}
                                                    variant="outlined"
                                                    className="filter-input"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                className="date-icon"
                                                            >
                                                                <div className="d-flex">
                                                                    {crossCheck ? (
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            edge="end"
                                                                        >
                                                                            <EventIcon className="text-dark" />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton
                                                                            edge="end"
                                                                            onClick={() => handleClearDate()}
                                                                        >
                                                                            <ClearIcon className="text-dark" />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            }
                                            onChange={(dates) => hnadleOnChangeSetStartDate(dates)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={2} lg={2}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            className="filter-btn "
                                            startIcon={<HiOutlineSearch />}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </ValidatorForm>
                    </Grid>
                </Grid>

                {!isLoading ? (
                    <>
                        <Grid
                            contaier
                            spacing={1}
                            className="usage-tbl-header  mt-3"
                        >
                            <Grid
                                item
                                xs={12}
                                className="usage-tbl-header text-right"
                            >
                                <Grid item >
                                    <LoadingButton
                                        onClick={() =>
                                            exportToCSV(usersBaseMonthsBalances, totalMonthlyBalances, totalYearBalance)
                                        } size="large"
                                        endIcon={<SaveAlt />}
                                        loading={false}
                                        disabled={false}
                                        loadingPosition="end"
                                        sx={{
                                            fontFamily: "Bevan, cursive !important",
                                            marginBottom: "10px"
                                        }}
                                        variant="contained"
                                    >
                                        <span>Export to CSV</span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {' '}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ pt: "5" }}
                        >
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 900, }} aria-label="customized table" >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">
                                                <div style={{ width: "100%", minWidth: "max-content", }}>
                                                    User Code
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <div style={{ width: "100%", minWidth: "max-content", }}>
                                                    User Name
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">Email</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={12}>
                                                <div style={{ width: "100%", minWidth: "100px", }}>
                                                    Credit Usage
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">Total</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div style={{ width: "100%", minWidth: "max-content", }}>
                                                    <TableSortLabel
                                                        IconComponent={HiOutlineArrowNarrowDown}
                                                        active={
                                                            state.currentColumn === "remainingBalance"
                                                        }
                                                        direction={
                                                            state.currentColumn === "remainingBalance"
                                                                ? state.direction
                                                                : "asc"
                                                        }
                                                        onClick={() => handleRotateIcon("remainingBalance")}
                                                    >
                                                        Remaining (Balance)
                                                    </TableSortLabel>
                                                </div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCells></StyledTableCells>
                                            <StyledTableCells></StyledTableCells>
                                            <StyledTableCells></StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "January"
                                                    }
                                                    direction={
                                                        state.currentColumn === "January"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("January")}
                                                >
                                                    January
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "February"
                                                    }
                                                    direction={
                                                        state.currentColumn === "February"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("February")}
                                                >
                                                    February
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "March"
                                                    }
                                                    direction={
                                                        state.currentColumn === "March"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("March")}
                                                >
                                                    March
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "April"
                                                    }
                                                    direction={
                                                        state.currentColumn === "April"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("April")}
                                                >
                                                    April
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "May"
                                                    }
                                                    direction={
                                                        state.currentColumn === "May"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("May")}
                                                >
                                                    May
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "June"
                                                    }
                                                    direction={
                                                        state.currentColumn === "June"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("June")}
                                                >
                                                    June
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "July"
                                                    }
                                                    direction={
                                                        state.currentColumn === "July"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("July")}
                                                >
                                                    July
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "August"
                                                    }
                                                    direction={
                                                        state.currentColumn === "August"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("August")}
                                                >
                                                    August
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "September"
                                                    }
                                                    direction={
                                                        state.currentColumn === "September"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("September")}
                                                >
                                                    September
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "October"
                                                    }
                                                    direction={
                                                        state.currentColumn === "October"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("October")}
                                                >
                                                    October
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "November"
                                                    }
                                                    direction={
                                                        state.currentColumn === "November"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("November")}
                                                >
                                                    November
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells>
                                                <TableSortLabel
                                                    IconComponent={HiOutlineArrowNarrowDown}
                                                    active={
                                                        state.currentColumn === "December"
                                                    }
                                                    direction={
                                                        state.currentColumn === "December"
                                                            ? state.direction
                                                            : "asc"
                                                    }
                                                    onClick={() => handleRotateIcon("December")}
                                                >
                                                    December
                                                </TableSortLabel>
                                            </StyledTableCells>
                                            <StyledTableCells></StyledTableCells>
                                            <StyledTableCells></StyledTableCells>
                                        </TableRow>
                                    </TableBody>
                                    <TableBody>
                                        {usersBaseMonthsBalances && usersBaseMonthsBalances.length > 0 ? (
                                            <>
                                                {usersBaseMonthsBalances && usersBaseMonthsBalances?.map((user, index) => (
                                                    <>
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left">{userCodeFormattedNumber(user.user_code) ?? ''}</StyledTableCell>
                                                            <StyledTableCell align="left" >
                                                                <div style={{ width: "100%", minWidth: "max-content", }}>
                                                                    {user.name ?? ''}
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">{user.email ?? ''}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.January ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.February ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.March ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.April ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.May ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.June ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.July ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.August ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.September ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.October ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.November ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{user.data.December ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{changeDollarAmountformat(user.totalMonthlyBalance.toFixed(2)) ?? 0}</StyledTableCell>
                                                            <StyledTableCell align="center">{changeDollarAmountformat(user.remainingBalance) ?? 0}</StyledTableCell>
                                                        </StyledTableRow>
                                                    </>
                                                ))}
                                                <StyledTableRow >
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">
                                                        <div style={{ width: "100%", minWidth: "max-content", }}>
                                                            Active Users
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    {calculatorMonthlyActiveUsers && calculatorMonthlyActiveUsers.map((activeUsers) => (
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">{activeUsers.activeUsersMonthly ?? 0}</StyledTableCell>
                                                    ))}
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow >
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="left">Total</StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    {totalMonthlyBalances && totalMonthlyBalances.map((totalMonthlyBalances) => (
                                                        <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">{changeDollarAmountformat(totalMonthlyBalances.total) ?? 0}</StyledTableCell>
                                                    ))}
                                                    <StyledTableCell sx={{ fontWeight: 'bold' }} align="center">{changeDollarAmountformat(totalYearBalance) ?? 0}</StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    colSpan={20}
                                                    style={{ padding: "2.6rem 1rem", textAlign: "center" }}
                                                >
                                                    <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                                                        No Records Found
                                                    </Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "21rem 0",
                        }}
                    >
                        <div style={{ position: 'relative', top: '4px' }}>
                            <img className="loader-size-table" src={loaderGif} alt="no loader" />
                        </div>
                    </Box>
                )}
                <AlertMessage
                    open={message.openalert}
                    message={message.message}
                    severity={message.alertseverity}
                    closeCall={handleClose}
                />
            </Card>
        </>
    );
}

export default CreditUsage;