import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// custom components
import AlertMessage from "../alert/alert";
import { ContainerLoader } from "components/common/ContainerLoader";

// mui icons components
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControlLabel from "@mui/material/FormControlLabel";

// mui components
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Radio,
  RadioGroup,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  InputAdornment,
  Autocomplete,
  Button
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
class EditProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        _id: this.props.match.params.id,
        username_val: "",
        password_val: "",
        hostname_val: "",
        port_val: "",
        userselection: "",
        user: [],
        name: "",
      },
      error: [],
      loader: true,
      open: false,
      message: "",
      successError: "",
      showPassword: false,
      users: [],
      lableValue: null
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.getusers();
    this.getlists();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleTogglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  getusers = () => {
    this.setState({ loader: true });
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response.data;
        this.setState({ loader: false });
        if (data) {
          this.setState({ loader: false, users: data, showform: true });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          message: "Something went wrong while accessing users list",
          alertseverity: "error",
          openalert: true,
        });
      });
  };

  getlists = () => {
    const browser_proxys_api_url =
      process.env.REACT_APP_API_URL + "/api/browser/getBrowserProxys";
    axios
      .get(browser_proxys_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          const id = this.props.match.params.id;
          const item = data.data.filter((item) => item._id === id);
          this.setState({
            // loader: false,
            open: false,
            message: "",
            successError: "success",
            formData: {
              _id: this.props.match.params.id,
              username_val: item[0].username_val,
              password_val: item[0].password_val,
              hostname_val: item[0].hostname_val,
              port_val: item[0].port_val,
              userselection: item[0].userselection,
              user: item[0].user.length > 0 ? [item[0].user[0]?._id] : [],
              name: item[0].name,
            },
            lableValue: item[0].user.length > 0 ? item[0].user[0] : [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  handleUserList = (e, newValue) => {
    const { formData } = this.state;
    if (newValue) {
      formData["user"] = [newValue._id];
      this.setState({ lableValue: newValue });
    } else {
      formData["user"] = [];
      this.setState({ lableValue: null })
    }
    this.setState({ formData });
  };

  handleChange = (e) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    if (e.target.name === "sharewith") {
      if (e.target.value === "all") {
        formData["user"] = [];
      }
    }
    this.setState({ formData });
  };

  handleSubmit = async (e) => {
    this.setState({ loader: true }, () => {
      const edit_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/updateBrowserProxy";
      const header = {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      };

      axios
        .post(edit_api_url, this.state.formData, header)
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "error",
            });
          } else {
            this.setState({
              loader: false,
              open: true,
              message: data.message,
              successError: "success",
              formData: {
                _id: this.state.formData._id,
                username_val: "",
                password_val: "",
                hostname_val: "",
                port_val: "",
                userselection: "all",
                user: [],

              },
            });
            this.props.history.push("/proxy-management");
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong, please try again",
            successError: "error",
          });
        });
    });
  };

  render() {
    console.log()
    const { loader, open, message, successError, formData } = this.state;

    return (
      <Grid conatainer spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Edit Proxy"
              action={
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, lg: 1 }}
                  columns={{ xs: 4, sm: 8, md: 8 }}
                  className="button-align"
                >
                  <Grid item>
                    <Button
                      component={Link}
                      to="/proxy-management"
                      type="button"
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                      }}
                      startIcon={<ArrowBack />}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              {!loader ? (
                <ValidatorForm onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "Barlow!important",
                          textAlign: "left",
                        }}
                      >
                        Name
                      </Typography>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="name"
                        placeholder="Enter Name"
                        value={formData.name}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "Barlow!important",
                          textAlign: "left",
                        }}
                      >
                        Username
                      </Typography>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        placeholder="Enter Username"
                        name="username_val"
                        value={formData.username_val}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "Barlow!important",
                          textAlign: "left",
                        }}
                      >
                        Password
                      </Typography>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        placeholder="Enter Password"
                        name="password_val"
                        type={this.state.showPassword ? "text" : "password"}
                        value={formData.password_val}
                        validators={["required"]}
                        errorMessages={[
                          "Password is required",
                          "Password must be at least 6 characters",
                        ]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.handleTogglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "Barlow!important",
                          textAlign: "left",
                        }}
                      >
                        Host
                      </Typography>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="hostname_val"
                        value={formData.hostname_val}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "Barlow!important",
                          textAlign: "left",
                        }}
                      >
                        Port
                      </Typography>
                      <TextValidator
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        name="port_val"
                        value={formData.port_val}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={formData.userselection}
                        name="userselection"
                        className="user_radio_dv"
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="Share to All"
                          onChange={(evt) => this.handleChange(evt)}
                        />
                        <FormControlLabel
                          value="particular"
                          control={<Radio />}
                          label="Share to Select User only"
                          onChange={(evt) => this.handleChange(evt)}
                        />
                      </RadioGroup>
                    </Grid>
                    {formData.userselection === "particular" && (
                      <Grid item={true} xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            textAlign: "left!important",
                            fontFamily: "Barlow!important",
                            padding: "10px 0px",
                          }}
                        >
                          Users
                        </Typography>
                        <Autocomplete
                          id="tags-outlined"
                          name="lableValue"
                          options={this.state.users ?? []}
                          value={formData.lableValue}
                          getOptionLabel={(option) => option.email || ""}
                          isOptionEqualToValue={(option, value) => option.email === value.email}
                          onChange={(e, newValue) => {
                            this.handleUserList(e, newValue);
                          }}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              variant="outlined"
                              name="user_select"
                              value={formData.lableValue || ""}
                              placeholder="Select User"
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid xs="4" style={{ margin: "0 auto", padding: "20px" }}>
                      <Button
                        fullWidth
                        size="large"
                        sx={{ paddingY: "0.9rem" }}
                        variant="contained"
                        type="submit"
                        className="theme-btn-submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              ) : (
                <ContainerLoader />
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* alert  message */}
        <AlertMessage
          open={open}
          message={message}
          severity={successError}
          closeCall={this.handleClose}
        />
      </Grid>
    );
  }
}

export default EditProxy;
