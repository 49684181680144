import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import DataTable from "components/DataTable/DataTable";

class UserAgents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      modalFormShow: false,
      deleteModalShow: false,
      loader: true,
      formData: {
        _id: null,
        agentdetail: "",
        os: "",
        name: "",
      },
      deleteId: "",
      osList: [],
    };
  }

  componentDidMount() {
    this.setState({ loader: true }, () => {
      this.getlists();
      this.getOsList();
    });
  }

  getlists = () => {
    const fp_api_url =
      process.env.REACT_APP_API_URL + "/api/getuseragentsAdmin";
    axios
      .get(fp_api_url, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else {
          this.setState({
            loader: false,
            lists: data,
            open: false,
            message: "",
            successError: "success",
          });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong",
          successError: "error",
        });
      });
  };

  setModalFormShow = (item) => {
    var formdata = { _id: null, agentdetail: "" };
    if (item) {
      formdata = {
        _id: item._id,
        agentdetail: item.agentdetail,
        name: item.name ? item.name : "",
        os: item.os ? item.os._id : "",
      };
    }
    this.setState({ modalFormShow: true, formData: formdata });
  };

  onModalFormHide = () => {
    const formdata = { _id: null, agentdetail: "" };
    this.setState({ modalFormShow: false, formdata: formdata });
  };

  getOsList = () => {
    this.setState({ loader: true });
    const apiurl = process.env.REACT_APP_API_URL + "/api/browser/getBrowserOS";

    axios
      .get(apiurl)
      .then((response) => {
        const data = response.data;
        this.setState({ loader: false });
        if (!data.error) {
          this.setState({ osList: data.data });
        }

        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };
  setDeleteModalShow = (_id) => {
    this.setState({ deleteModalShow: true, deleteId: _id });
  };

  onDeleteHide = () => {
    this.setState({ deleteModalShow: false });
  };

  handleChange = (e) => {
    const { formData } = this.state;
    const { name, value } = e.target;
    formData[name] = value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    const { formData } = this.state;
    this.setState({ loader: true }, () => {
      const url = process.env.REACT_APP_API_URL + "/api/addUserAgent";

      axios
        .post(url, formData)
        .then((response) => {
          console.log(response);
          this.getlists();
          this.onModalFormHide();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  callDelete = () => {
    this.setState({ loader: true }, () => {
      const data = { _id: this.state.deleteId };
      console.log(data);
      const userDelete_api_url =
        process.env.REACT_APP_API_URL + "/api/admin/deleteUserAgent";

      axios
        .post(userDelete_api_url, data, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.error) {
            this.setState({ deleteModalShow: false });
            this.setState({
              loader: false,
              open: true,
              message: data.data.message,
              successError: "error",
            });
          } else {
            this.setState({ deleteModalShow: false });
            if (data.success) {
              this.getlists();
              this.onDeleteHide();
            } else {
              this.setState({
                loader: false,
                open: true,
                message: "Something went wrong",
                successError: "error",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        });
    });
  };

  render() {
    const { loader, lists, modalFormShow, formData, deleteModalShow } =
      this.state;
    const fields = [
      { key: "name", label: "Name" },
      { key: "agentdetail", label: "User Agent" },
      { key: "os", label: "Operating System" },
      { key: "action", label: "Action" },
    ];

    if (!loader) {
      return (
        <>
          <Helmet>
            <title>User Agents</title>
          </Helmet>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="User Agents"
                  action={
                    <Button
                      size="medium"
                      variant="contained"
                      sx={{
                        backgroundColor: "#002986!important",
                        color: "white!important",
                        textTransform: "none!important",
                        fontWeight: "bold",
                        fontFamily: "Barlow!important",
                        paddingX: "1rem",
                        marginRight: "0.8rem",
                      }}
                      startIcon={<AddIcon />}
                      onClick={() => this.setModalFormShow(null)}
                    >
                      Add User Agent
                    </Button>
                  }
                ></CardHeader>

                <CardContent
                  sx={{
                    paddingTop: "0px!important",
                  }}
                >
                  <DataTable
                    responsive
                    items={lists}
                    fields={fields}
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      os: (item) => {
                        return <>{item.os !== undefined && item.os.name}</>;
                      },
                      action: (item) => {
                        return (
                          <td className="py-2 icon-outer-font">
                            <div className="flex">
                              <IconButton
                                aria-label="edit"
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                                onClick={() => this.setModalFormShow(item)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  this.setDeleteModalShow(item._id)
                                }
                                sx={{
                                  color: "#002986",
                                  "&:hover": {
                                    color: "#B90F0F",
                                  },
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </td>
                        );
                      },
                    }}
                  />

                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={modalFormShow}
                    onClose={this.onModalFormHide}
                  >
                    <DialogTitle>{formData._id ? "Update" : "Add"}</DialogTitle>
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <DialogContent>
                        <IconButton
                          aria-label="close"
                          onClick={() => this.onModalFormHide()}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: (theme) => theme.palette.grey[500],
                            p: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        <Grid container spacing={2}>
                          <Grid xs={12} item>
                            <label>Name</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              placeholder="Name"
                              onChange={this.handleChange}
                              name="name"
                              value={formData.name}
                              validators={["required"]}
                              errorMessages={["Name is required"]}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            <label>User Agent</label>
                            <TextValidator
                              variant="outlined"
                              fullWidth
                              placeholder="User Agent"
                              onChange={this.handleChange}
                              name="agentdetail"
                              value={formData.agentdetail}
                              validators={["required"]}
                              errorMessages={["User Agent is required"]}
                            />
                          </Grid>
                          <Grid xs={12} item>
                            <label>Operating System</label>

                            <SelectValidator
                              fullWidth
                              onChange={this.handleChange}
                              name="os"
                              type="text"
                              value={formData.os}
                              placeholder="Operating System"
                              sx={{
                                fontFamily: "Barlow",
                                textAlign: "left",
                              }}
                              validators={["required"]}
                              errorMessages={["Operating System is required"]}
                            >
                              {this.state.osList.map((os, index) => (
                                <MenuItem value={os._id}>{os.name}</MenuItem>
                              ))}
                            </SelectValidator>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.onModalFormHide}
                          type="button"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          sx={{
                            backgroundColor: "#002986!important",
                            color: "white!important",
                            fontWeight: "bold",
                            fontFamily: "Barlow",
                            textTransform: "none",
                          }}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </ValidatorForm>
                  </Dialog>

                  <Dialog
                    fullWidth={true}
                    scroll="body"
                    maxWidth="sm"
                    open={deleteModalShow}
                    onClose={this.onDeleteHide}
                  >
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                      <IconButton
                        aria-label="close"
                        onClick={() => this.onDeleteHide()}
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          color: (theme) => theme.palette.grey[500],
                          p: 1,
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                      <DialogContentText>
                        Are you sure you want to Delete, this action can not be
                        revert ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={this.onDeleteHide}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.callDelete}
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          fontWeight: "bold",
                          fontFamily: "Barlow",
                          textTransform: "none",
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "13rem 0",
        }}
      >
        <CircularProgress sx={{ color: "#002986" }} />
      </Box>
    );
  }
}
export default UserAgents;
