import Moment from 'moment';

export default function invoiceDateFormat(datestring) {
    var retDate = Moment( datestring.split("T").join("\n").substr(0, datestring.split("T").join("\n").indexOf('\n'))).format('MM/DD/YYYY');

    if(!(retDate instanceof Date && !isNaN(retDate))) {
        retDate = Moment(datestring).format('MM/DD/YYYY');
    }

    return retDate;
}