import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import axios from "axios";
import AlertMessage from "../alert/alert";
import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CardContent,
  CardHeader,
  Grid,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { ContainerLoader } from "components/common/ContainerLoader";

class AddVbdAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        user: "",
        user_select: "",
        vbdaccount: "",
        vbdaccount_select: "",
      },
      users: [],
      vbdaccounts: [],
      loader: true,
      showform: false,
      message: "",
      formSubmit: false,
      error: [],
      openalert: false,
      alertseverity: "",
      lowerEmailCheck: false,
      lowerEmailMsg: "",
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.getAllUsersVbdList();
  }

  getAllUsersVbdList = async () => {
    const userList_api_url =
      process.env.REACT_APP_API_URL + "/api/user/getAllUsers";
    axios
      .post(userList_api_url, "", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            message: "Something went wrong while accessing users list",
            alertseverity: "error",
            openalert: true,
          });
        } else {
          const userList_api_url =
            process.env.REACT_APP_API_URL + "/api/admin/listVbdAccount";
          axios
            .get(userList_api_url, {
              headers: {
                authorization:
                  "Bearer " + localStorage.getItem("accessTokenAdmin"),
              },
            })
            .then((response1) => {
              const data1 = response1.data;
              if (data1.error) {
                this.setState({
                  loader: false,
                  message:
                    "Something went wrong while accessing vbd account list",
                  alertseverity: "error",
                  openalert: true,
                });
              } else {
                this.setState({
                  loader: false,
                  users: data.data,
                  vbdaccounts: data1.data,
                  showform: true,
                });
              }
            })
            .catch((error) => {
              this.setState({
                loader: false,
                message:
                  "Something went wrong while accessing vbd account list",
                alertseverity: "error",
                openalert: true,
              });
            });
        }
      })
      .catch((error) => {
        this.setState({
          loader: false,
          message: "Something went wrong while accessing users list",
          alertseverity: "error",
          openalert: true,
        });
      });
  }

  handleUserList = (e, newValue) => {
    const { formData } = this.state;
    if (newValue) {
      formData["user"] = newValue._id;
      formData["user_select"] = newValue._id;
    } else {
      formData["user"] = newValue;
      formData["user_select"] = "";
    }
    this.setState({ formData });
  };

  handleVBDList = (e, newValue) => {
    const { formData } = this.state;
    if (newValue) {
      formData["vbdaccount"] = newValue._id;
      formData["vbdaccount_select"] = newValue._id;
    } else {
      formData["vbdaccount"] = newValue;
      formData["vbdaccount_select"] = "";
    }
    this.setState({ formData });
  };

  handleSubmit = async (e) => {
    this.setState({ formSubmit: true });
    const addUser_api_url =
      process.env.REACT_APP_API_URL + "/api/admin/attachVbdToUser";
    axios
      .post(addUser_api_url, this.state.formData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("accessTokenAdmin"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            formSubmit: false,
            message: data.message,
            alertseverity: "error",
            openalert: true,
          });
        } else {
          this.setState({
            formSubmit: false,
            message: data.message,
            alertseverity: "success",
            openalert: true,
          });
          this.props.history.push("/vbdaccounts/lists");
        }
      })
      .catch((error) => {
        this.setState({
          formSubmit: false,
          message: "Something went wrong while submitting form",
          alertseverity: "error",
          openalert: true,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({
      openalert: false,
    });
  };

  render() {
    const { formData, loader, showform } = this.state;
    return (
      <>
        <Helmet>
          <title>Attach VBD Account to User</title>
        </Helmet>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Attach VBD Account to User"
                action={
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{
                      justifyContent: {
                        xs: "flex-start",
                        sm: "flex-start",
                        md: "flex-end",
                        lg: "flex-end",
                        xl: "flex-end",
                      },
                    }}
                  >
                    <Grid item sx={{ paddingLeft: "8px!important" }} >
                      <Button
                        onClick={() => this.props.history.push(`/vbdaccounts/lists`)}
                        size="large"
                        variant="contained"
                        sx={{
                          backgroundColor: "#002986!important",
                          color: "white!important",
                          textTransform: "none!important",
                          fontWeight: "normal",
                          padding: "7px 22px!important",
                          fontFamily: "Barlow!important",
                          marginLeft: "9px !important"
                        }}
                        startIcon={<ArrowBack />}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              {loader ? (
                <ContainerLoader />
              ) : (
                <CardContent>
                  {showform && (
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "10px",
                            }}
                          >
                            User
                          </Typography>
                          <Autocomplete
                            id="tags-outlined"
                            name="user"
                            options={this.state.users}
                            getOptionLabel={(option) => option.email}
                            getOptionSelected={(option) => {
                              return option._id === formData.user;
                            }}
                            onChange={this.handleUserList}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                variant="outlined"
                                name="user_select"
                                value={formData.user_select}
                                placeholder="Select User"
                                validators={["required"]}
                                errorMessages={"This field is required"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              paddingBottom: "10px",
                            }}
                          >
                            Vbd Account
                          </Typography>
                          <Autocomplete
                            id="tags-outlined-1"
                            name="vbdaccount"
                            options={this.state.vbdaccounts}
                            getOptionLabel={(option) => option.username}
                            getOptionSelected={(option) => {
                              return option._id === formData.vbdaccount;
                            }}
                            onChange={this.handleVBDList}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                variant="outlined"
                                name="vbdaccount_select"
                                value={formData.vbdaccount_select}
                                placeholder="Select VBD Account"
                                validators={["required"]}
                                errorMessages={"This field is required"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            margin: "0 auto",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            className="theme-btn"
                          >
                            Add Account
                          </Button>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                  )}
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
        <AlertMessage
          open={this.state.openalert}
          message={this.state.message}
          severity={this.state.alertseverity}
          closeCall={this.handleClose}
        />
      </>
    );
  }
}

export default AddVbdAccount;
