import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Autocomplete from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import '../common.css';
import DataTable from 'components/DataTable/DataTable';

class InternalNotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lists: [],
            notetypes: ["anonymous transfer", "mobile transfer"],
            modalShow: false,
            deleteId: "",
            deleting: false,
            loader: true,
            loadervendor: true,
            open: false,
            message: "",
            alertcolor: "",
            modalform: {
                show: false,
                id: "",
                title: "",
                name: "",
                type: null,
                submitting: false,
                formalert: false,
                formmessage: "",
                formalertcolor: ""
            }
        }
    }
    actions = [
        {
            label: "Edit",
            callback: (item) => this.setModalFormShow(item._id, 'Update Internal Notes', item.name, item.type),

        },
        {
            label: "Delete",
            callback: (item) => this.setModalShow(item._id),

        }
    ];
    componentDidMount() {
        this.getLists()
    }

    getLists = () => {
        const list_api_url = process.env.REACT_APP_API_URL + "/api/admin/getAllInternalnotes";
        axios.get(list_api_url, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then(response => {
                const { data } = response;
                if (data.error) {
                    this.setState({ loader: false, open: true, message: 'Something went wrong', alertcolor: 'error' })
                }
                else {
                    this.setState({
                        loader: false, lists: data.data
                    })
                }
            })
            .catch(err => console.log(err))
    }

    setModalShow = (id) => {
        this.setState({ modalShow: true, deleteId: id })
    }

    onHide = () => {
        this.setState({ modalShow: false, deleteId: '' })
    }

    callDelete = () => {
        this.setState({ deleting: true })
        const data = { _id: this.state.deleteId }
        const delete_api_url = process.env.REACT_APP_API_URL + "/api/admin/deleteInternalnotes"
        axios.post(delete_api_url, data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
            .then((response) => {
                const { data } = response;
                if (data.error) {
                    this.setState({ loader: false, open: true, message: data.data.message, alertcolor: 'error' })
                }
                else {
                    if (data.success) {
                        this.getLists()
                        this.setState({ modalShow: false, open: true, message: "Deleted successfully!", alertcolor: 'success', deleting: false })
                    } else {
                        this.setState({
                            loader: false, open: true, message: "Something went wrong", alertcolor: 'error'
                        })
                    }
                }
            }).catch((error) => {
                this.setState({ loader: false, open: true, message: "Something went wrong", alertcolor: 'error' })
            })
    }

    setModalFormShow = (id, title, name, type) => {
        const modalform = { show: true, id: id, title: title, name: name, type: type, submitting: false }
        console.log(modalform)
        this.setState({ modalform })
    }

    onHideForm = () => {
        const modalform = { show: false, id: "", title: "", name: "", type: "", submitting: false }
        this.setState({ modalform })
    }

    handleSubmit = () => {
        const { modalform } = this.state
        modalform.submitting = true
        this.setState({ modalform }, () => {
            let form_data = { name: modalform.name, type: modalform.type }
            let form_api_url = process.env.REACT_APP_API_URL + "/api/admin/addInternalnotes"
            let successmsg = "Added successfully!"
            if (modalform.id !== "") {
                form_api_url = process.env.REACT_APP_API_URL + "/api/admin/updateInternalnotes"
                form_data = { name: modalform.name, _id: modalform.id, type: modalform.type }
                successmsg = "Updated successfully!"
            }

            axios.post(form_api_url, form_data, { headers: { authorization: 'Bearer ' + localStorage.getItem('accessTokenAdmin') } })
                .then((response) => {
                    const { data } = response;
                    if (data.error) {
                        modalform.submitting = false
                        modalform.formalert = true
                        modalform.formmessage = data.data.message
                        modalform.formalertcolor = 'error'
                        this.setState({ modalform })
                    }
                    else {
                        if (data.success) {
                            this.getLists()
                            const modalform_new = {
                                show: false,
                                id: "",
                                title: "",
                                name: "",
                                type: "",
                                submitting: false,
                                formalert: false,
                                formmessage: "",
                                formalertcolor: ""
                            }
                            this.setState({ modalform: modalform_new, open: true, message: successmsg, alertcolor: 'success' })
                        } else {
                            this.setState({ formalert: true, formmessage: data.data.message, formalertcolor: 'error' })
                        }
                    }
                }).catch((error) => {
                    modalform.submitting = false
                    modalform.formalert = true
                    modalform.formmessage = "Something went wrong"
                    modalform.formalertcolor = 'error'
                    this.setState({ modalform })
                })
        })
    }

    handleChange = (e) => {
        const { modalform } = this.state
        modalform.name = e.target.value
        this.setState({ modalform })
    }

    handleChangeType = (e, newVal) => {
        const { modalform } = this.state
        if (newVal) {
            modalform.type = newVal;
        } else {
            modalform.type = '';
        }
        this.setState({ modalform });
    }

    render() {
        const { lists, modalShow, loader, modalform } = this.state

        const fields = [
            { key: 'name' ,label: "Name" },
            { key: 'type'  ,label: "Type"},
            {
                key: "action",
                label: "Action",
                sorter: false,
                filter: false,
              }
        ]

        if (!loader) {
            return (
                <>
                    <Helmet>
                        <title>
                            Internal Notes List
                        </title>
                    </Helmet>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent sx={{ padding: "30px" }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    width: "100%",
                                                    maxWidth: "265px",
                                                    color: "#000059!important",
                                                    textAlign: "left",
                                                    fontWeight: "bold",
                                                    fontSize: "1.6rem",
                                                    fontFamily: "Barlow!important",
                                                }}
                                            >
                                                {" "}
                                                Internal Notes List
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                sx={{
                                                    justifyContent: {
                                                        xs: "flex-start",
                                                        sm: "flex-start",
                                                        md: "flex-end",
                                                        lg: "flex-end",
                                                        xl: "flex-end",
                                                    },
                                                }}
                                            >
                                                <Grid item>
                                                    <Button
                                                        size="medium"
                                                        variant="contained"
                                                        onClick={() => this.setModalFormShow('', 'Add Internal Notes', '', null)}
                                                        sx={{
                                                            backgroundColor: "#002986!important",
                                                            color: "white!important",
                                                            textTransform: "none!important",
                                                            fontWeight: "bold",
                                                            fontFamily: "Barlow!important",

                                                        }}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add Internal Notes
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>

                                            <DataTable
                                                responsive
                                                tableFilter
                                                items={lists}
                                                fields={fields}
                                                itemsPerPage={10}
                                                pagination

                                                scopedSlots={{
                                                    action: (item) => {
                                                      return (
                                                        <div className="flex">
                                                          <IconButton
                                                            aria-label="edit"
                                                            sx={{
                                                              color: "#002986",
                                                              "&:hover": {
                                                                color: "#B90F0F",
                                                              },
                                                            }}
                                                            onClick={() =>
                                                               this.setModalFormShow(item._id, 'Update Internal Notes', item.name, item.type)
                                                            }
                                                          >
                                                            <EditIcon />
                                                          </IconButton>
                                                          <IconButton
                                                            onClick={(item) => this.setModalShow(item._id)}
                                                            sx={{
                                                              color: "#002986",
                                                              "&:hover": {
                                                                color: "#B90F0F",
                                                              },
                                                            }}
                                                          >
                                                            <DeleteIcon />
                                                          </IconButton>
                                                        </div>
                                                      );
                                                    },
                                                    timezone: (item) => {
                                                      return item.timezone?.replace("US/", "");
                                                    },
                                                  }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Dialog
                        fullWidth={true}
                        scroll='body'
                        maxWidth="sm"
                        open={modalShow}
                        onClose={this.onHide}

                    >

                        <DialogTitle>Delete</DialogTitle>
                        <DialogContent>
                            <IconButton
                                aria-label="close"
                                onClick={() => this.onHide()}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    color: (theme) => theme.palette.grey[500],
                                    p: 1,
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                            <DialogContentText>
                                Are you sure you want to Delete, this action can not be revert ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onHide}
                                sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none"
                                }}
                            >Cancel</Button>
                            <Button onClick={this.callDelete}
                                sx={{
                                    backgroundColor: "#002986!important",
                                    color: "white!important",
                                    fontWeight: "bold",
                                    fontFamily: "Barlow",
                                    textTransform: "none"
                                }}
                            >Delete</Button>
                        </DialogActions>

                    </Dialog>


                    <Dialog
                        fullWidth={true}
                        scroll='body'
                        maxWidth="sm"
                        open={modalform.show}
                        onClose={this.onHideForm}

                    >

                        <DialogTitle>{modalform.title}</DialogTitle>
                        <ValidatorForm onSubmit={this.handleSubmit}>
                            <DialogContent>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => this.onHideForm()}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        color: (theme) => theme.palette.grey[500],
                                        p: 1,
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        <label>League name</label>
                                        <TextValidator
                                            variant="outlined"
                                            fullWidth
                                            name="names"
                                            onChange={this.handleChange}
                                            value={modalform.name}
                                            validators={['required']}
                                            errorMessages={[('This field is required')]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label>Vendor</label>
                                        <Autocomplete
                                            onChange={this.handleChangeType}
                                            id="tags-outlined"
                                            options={this.state.notetypes}
                                            defaultValue={modalform.type}
                                            value={this.state.modalform.type}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextValidator
                                                    {...params}
                                                    value={modalform.type}
                                                    variant="outlined"
                                                    placeholder="Select Type"
                                                    validators={['required']}
                                                    errorMessages={('This field is required')}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.onHideForm}
                                    type="button"
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none"
                                    }}
                                >Cancel</Button>
                                <Button
                                    disabled={modalform.submitting} type="submit"
                                    sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow",
                                        textTransform: "none"
                                    }}
                                >Submit</Button>
                            </DialogActions>
                        </ValidatorForm>

                    </Dialog>


                </>
            )
        }
        return (
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: "13rem 0" }}>
                <CircularProgress sx={{ color: "#002986" }} />
            </Box>
        )
    }
}
export default InternalNotes;