import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// mui components
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { HiOutlineSearch } from 'react-icons/hi';

// components
import { useAdminAPICollection } from 'Hooks/useAdminAPICollection';
import useAlertMessage from 'Hooks/useAlertMessage';
import AlertMessage from 'components/message/message';
import CustomPagination from 'utils/customPagination';
import CustomDataTable from 'utils/customDataTable';
import ConfirmationModal from 'utils/confirmationModal';
import { BiTime } from 'react-icons/bi';
import { DateTimeFormat } from 'utils/dateTimeFormatOptions';

const UsersSessions = () => {
    const { getAllUsersAPI, getUsersSessionsAPI, deleteAllSessionsAPI } = useAdminAPICollection();
    const { alertMessage, errorMessage, hideAlert } = useAlertMessage();
    const [pagination, setPagination] = useState({
        searchPage: 0,
        skip: 0,
        pageLimit: 10,
    });
    const [formData, setFormData] = useState({
        userId: null,
        status: "all"
    });
    const [userSessions, setUserSessions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionId, setSessionId] = useState(null);
    const [isopenConfimModal, setIsopenConfimModal] = useState(false);

    const changePageLimit = (e) => {
        setPagination({
            ...pagination,
            searchPage: 1,
            pageLimit: parseInt(e.target.value)
        });
        getUsersSessions(0, parseInt(e.target.value));
    };

    const onPageChanged = (data) => {
        const { currentPage } = data;
        setPagination({
            ...pagination,
            skip: (currentPage - 1) * pagination.pageLimit
        });
        getUsersSessions((currentPage - 1) * pagination.pageLimit, pagination.pageLimit);
    };

    const SearchDisable = (value) => {
        setPagination({
            ...pagination,
            searchPage: value,
        });
    };

    const handleSelectUser = (event, value) => {
        if (value) {
            setFormData({
                ...formData,
                users: value
            });
        } else {
            setFormData({
                ...formData,
                users: null
            });
        }
    };

    const handleOpenConfimModal = (item) => {
        setSessionId(item?._id)
        setIsopenConfimModal(true);
    }

    const handleCloseConfimModal = () => {
        setIsopenConfimModal(false);
        setSessionId(null);
    }

    const getAllUsers = async () => {
        try {
            const respones = await getAllUsersAPI();
            const { data } = respones;
            if (data.error) {
                errorMessage();
            } else {
                const sorted = data.data.sort((a, b) => {
                    if (a.email.toLowerCase().trim() < b.email.toLowerCase().trim())
                        return -1;
                    if (a.email.toLowerCase().trim() > b.email.toLowerCase().trim())
                        return 1;
                    return 0;
                });
                setUserList(sorted ?? []);
            }
        } catch (error) {
            errorMessage();
        }
    };

    const getUsersSessions = async (pageskip = 0, limitpage) => {
        try {
            const parmas = {
                userId: formData?.users?._id ?? null,
                limit: limitpage ?? 10,
                skip: pageskip ?? 0
            }
            setIsLoading(true);
            const respones = await getUsersSessionsAPI(parmas);
            const { data } = respones;
            if (data.error) {
                errorMessage();
                setIsLoading(false);
            } else {
                console.log(data?.data);
                setUserSessions(data?.data ?? []);
                setTotalRecords(data?.total ?? 0)
                setIsLoading(false);
            }
        } catch (error) {
            errorMessage();
            setIsLoading(false);
        }
    };

    const deleteAllSessions = async () => {
        try {
            setIsopenConfimModal(false);
            const respones = await deleteAllSessionsAPI({ userId: sessionId });
            const { data } = respones;
            if (data.error) {
                errorMessage(data.message, "error");
            } else {
                errorMessage(data.message, "success");
                await getUsersSessions(pagination?.skip, pagination?.pageLimit);
                setSessionId(null);
            }
        } catch (error) {
            errorMessage();
        }
    };

    const handleSearch = () => {
        getUsersSessions(pagination?.skip, pagination?.pageLimit);
    }

    const actions = [
        { label: "Logout", callback: handleOpenConfimModal },
    ];

    const columns = [
        { field: "user", headerName: "Users" },
        { field: "session_count", headerName: "Sessions Count" },
        { field: "last_login", headerName: "Last Logged In" },
    ];

    useEffect(() => {
        getAllUsers();
        getUsersSessions(pagination?.skip, pagination?.pageLimit)
    }, [])

    return (
        <>
            <Helmet>
                <title>User Sessions</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent sx={{ padding: "15px" }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: "100%",
                                            maxWidth: "265px",
                                            color: "#000059!important",
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            fontSize: "1.6rem",
                                            fontFamily: "Barlow!important",
                                        }}
                                    >
                                        {" "}
                                        User Sessions
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={12} md={12} lg={12} className="flex vbd-btn-filter align-items-center px-3">
                                    <Grid item xs={12} sm={12} md={8} lg={4} sx={{ paddingTop: "44px !important" }}>
                                        <div>
                                            <label style={{ color: "#000059" }}>Users</label>
                                            <Autocomplete
                                                fullWidth
                                                id="checkboxes-tags-demo"
                                                options={userList ?? []}
                                                label="Users"
                                                disableCloseOnSelect
                                                onChange={handleSelectUser}
                                                value={formData.users}
                                                getOptionLabel={(option) => option.email}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{
                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error ": {
                                                                color: "#C4C4C4"
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        label="Users"
                                                        value={formData.users}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} className="filter-btn-grid" sx={{ position: 'relative', top: '36px', left: "12px" }}>
                                        <Button
                                            sx={{ padding: "14px 26px !important" }}
                                            variant="contained"
                                            type="submit"
                                            className="filter-btn"
                                            startIcon={<HiOutlineSearch />}
                                            onClick={handleSearch}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomDataTable
                                        columns={columns}
                                        isLoading={isLoading}
                                        data={userSessions ? userSessions?.map((item) => {
                                            return {
                                                ...item,
                                                user: item?.userDetails?.email,
                                                last_login:
                                                    <>
                                                        <BiTime className="m-icon" />
                                                        <DateTimeFormat date={item.lastLogin} />
                                                    </>,
                                                session_count: item?.sessionCount
                                            };
                                        }) : []}
                                        actions={actions}
                                    />
                                </Grid>
                                {userSessions && userSessions.length > 0 ?
                                    <>
                                        <Grid item xs={12}>
                                            {totalRecords > 0 && (
                                                <CustomPagination
                                                    key="paginationnew"
                                                    searchPage={pagination?.searchPage}
                                                    SearchDisable={SearchDisable}
                                                    totalRecords={totalRecords}
                                                    pageLimit={pagination?.pageLimit}
                                                    onPageChanged={onPageChanged}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <label>Items per page :</label>
                                                <FormControl
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 50,
                                                    }}
                                                    size="small"
                                                >
                                                    <Select
                                                        defaultValue={pagination?.pageLimit}
                                                        value={pagination?.pageLimit}
                                                        name="limit"
                                                        sx={{
                                                            "& .MuiSelect-select": {
                                                                padding: "4px 34px 4px 14px!important",
                                                                fontFamily: "Barlow",
                                                            },
                                                        }}
                                                        onChange={(e) => changePageLimit(e)}
                                                    >
                                                        <MenuItem value="10">10</MenuItem>
                                                        <MenuItem value="20">20</MenuItem>
                                                        <MenuItem value="50">50</MenuItem>
                                                        <MenuItem value="100">100</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </> : null}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* delete confrm modal */}
            <ConfirmationModal
                handleAPIRequest={deleteAllSessions}
                isOpen={isopenConfimModal}
                handleClose={handleCloseConfimModal}
                message={"Are you sure you want to logout user from all sessions?"}
                title={"User Sessions"}
                buttonText={"Logout"}
                buttonText2={"Cancel"}
            />
            {/* alert message */}
            <AlertMessage
                open={alertMessage.openAlert}
                message={alertMessage.message}
                severity={alertMessage.alertSeverity}
                closeCall={hideAlert}
            />
        </>
    )
}
export default UsersSessions;