// npm packages
import React from 'react';
import PropTypes from 'prop-types';

// material-ui style and icons components
import {
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import CustomDataTable from './customDataTable';
import CustomPagination from './customPagination';
import { DateTimeFormat } from './dateTimeFormatOptions';

const DataTableModal = ({
    isOpen,
    item = [],
    closeModal,
    isLoading = false,
    pagination = { searchPage: 1, pageLimit: 10 },
    changePageLimit,
    onPageChanged,
    totalRecords = 0,
    SearchDisable = false,
    columns = [],
    title = "User Sessions"
}) => (
    <Dialog
        scroll="body"
        fullWidth
        maxWidth="md"
        open={isOpen}
        sx={{
            '& .MuiPaper-root': {
                maxWidth: title ? "1060px" : "900px",
            }
        }}
        className="Modal_Box custom-pop-over"
        onClose={closeModal}
    >
        <DialogContent sx={{ padding: "8px 24px" }}>
            <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: (theme) => theme.palette.grey[500],
                    p: 1,
                }}
            >
                <Cancel />
            </IconButton>
            <Typography
                variant="h6"
                sx={{
                    width: "100%",
                    maxWidth: "14rem",
                    color: "#000059!important",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "1.6rem",
                    fontFamily: "Barlow!important",
                }}
            >
                {title}
            </Typography>
            <DialogContentText>
                <CustomDataTable
                    columns={columns}
                    isLoading={isLoading}
                    data={item.map((i) => ({
                        ...i,
                        user: i?.userId?.email,
                        createdAt: <DateTimeFormat date={i?.createdAt} />
                    }))}
                    isMaxWidthScroll
                />
                {item.length > 0 && totalRecords > 0 && (
                    <>
                        <br />
                        <CustomPagination
                            searchPage={pagination.searchPage}
                            SearchDisable={SearchDisable}
                            totalRecords={totalRecords}
                            pageLimit={pagination.pageLimit}
                            onPageChanged={onPageChanged}
                        />
                        <div
                            style={{
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                            }}
                        >
                            <label htmlFor="itemsPerPage" >Items per page :</label>
                            <FormControl
                                sx={{ m: 1, minWidth: 50 }}
                                size="small"
                            >
                                <Select
                                    id="itemsPerPage"
                                    value={pagination.pageLimit}
                                    onChange={changePageLimit}
                                    sx={{
                                        "& .MuiSelect-select": {
                                            padding: "4px 34px 4px 14px!important",
                                            fontFamily: "Barlow",
                                        },
                                    }}
                                >
                                    {[10, 20, 50, 100].map((limit) => (
                                        <MenuItem key={limit} value={limit}>{limit}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </>
                )}
            </DialogContentText>
        </DialogContent>
    </Dialog>
);

DataTableModal.propTypes = {
    isOpen: PropTypes.bool,
    item: PropTypes.array,
    closeModal: PropTypes.func,
    isLoading: PropTypes.bool,
    pagination: PropTypes.shape({
        searchPage: PropTypes.number,
        pageLimit: PropTypes.number,
    }),
    changePageLimit: PropTypes.func,
    onPageChanged: PropTypes.func,
    totalRecords: PropTypes.number,
    SearchDisable: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

DataTableModal.defaultProps = {
    isOpen: false,
    item: [],
    closeModal: () => { },
    isLoading: false,
    pagination: {
        searchPage: 1,
        pageLimit: 10,
    },
    totalRecords: 0,
    SearchDisable: false,
    title: "",
};

export default DataTableModal;
