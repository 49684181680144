
export const DateTimeFormat = ({ date }) => {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    const formattedDate = date ? new Intl.DateTimeFormat('en-US', options).format(new Date(date)) : null;
    return formattedDate;
}