import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import { useParams } from "react-router-dom";
import Usersidebar from "components/user-sidebar/user-sidebar";
import AlertMessage from "components/message/message";
import {
  Card,
  CardContent,
  Grid,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  Button
} from "@mui/material";
import axios from "axios";
import DataTable from "components/DataTable/DataTable";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Pagination from "components/pagination/pagination";
import { useDispatch, useSelector } from "react-redux";
import UserProfileAction from "redux/actions/UserProfielAction";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router";
const AccountLogs = () => {
  // State variables
  const history = useHistory()
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [accountLogs, setAccountLogs] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [pageLimit, setPageLimit] = React.useState(parseInt(10));
  const [searchPage, setSearchPage] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const [venderAccountDetail, setVenderAccountDetail] = useState({})
  const dispatch = useDispatch();
  const getUserProfile = useSelector((state) => state.UserProfile);

  const updateProfile = (limit) => {
    const updateDetail_api_url =
      process.env.REACT_APP_API_URL + "/api/user/updateUser";
    axios
      .post(
        updateDetail_api_url,
        { per_page_limit: limit },
        {
          headers: {
            authorization: "Bearer " + Cookies.get("accessToken"),
          },
        }
      )
      .then((response) => {
        const { data } = response;
        if (data.error) {
          setLoader(false);
          setMessage(data.message);
          setAlertColor("error");
          setOpen(true);
        } else {
          dispatch(UserProfileAction());
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setMessage("Something went wrong!");
        setAlertColor("error");
        setOpen(true);
      });
  };

  const changepageLimit = (e) => {
    setPageLimit(parseInt(e.target.value));

    if (e.target.value) {
      updateProfile(parseInt(e.target.value));
    }
    setSkip(0);
    getAccountLogs(0, parseInt(e.target.value));
    setSearchPage(1);
  };

  const onPageChanged = (data) => {
    const { currentPage } = data;
    setSearchPage(currentPage - 1);
    setSkip((currentPage - 1) * pageLimit);
    getAccountLogs((currentPage - 1) * pageLimit, pageLimit);
  };

  const SearchDisable = () => {
    setSearchPage(0);
    setSkip(0);
  };

  // Accessing the route parameter
  const { id } = useParams();

  // Function to close the alert message
  const handleClose = () => {
    setOpen(false);
  };

  const getAccountLogs = (skippage, limit) => {
    setLoader(true);
    const logsApiUrl = `${process.env.REACT_APP_API_URL}/api/account/getSingleAccountLog`;

    const param = {
      _id: id,
      skip: skippage,
      limit: limit,
    };

    axios
      .post(logsApiUrl, param, {
        headers: {
          authorization: `Bearer ${Cookies.get("accessToken")}`,
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          setMessage(data.error);
          setAlertColor("error");
          setOpen(true);
        } else {
          setAccountLogs(data.data);
          setVenderAccountDetail(data.account)
          setLoader(false);
          setTotalRecords(data.totalrecords);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setMessage("Something went wrong!");
        setAlertColor("error");
        setOpen(true);
      });
  };

  // Fetch account logs when the component mountsconst apiUrl = `${process.env.REACT_APP_API_URL}/api/account/getSingleAccountLog`;

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/account/getSingleAccountLog`;
    const fetchData = async () => {
      setLoader(true);
      if (getUserProfile?.data?.per_page_limit) {
        const limit = parseInt(getUserProfile.data.per_page_limit);
        console.log(limit);
        setPageLimit(limit);
        setLoader(true);

        const param = {
          _id: id,
          skip: skip,
          limit: getUserProfile?.data?.per_page_limit,
        };

        try {
          const response = await axios.post(apiUrl, param, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });

          const { data } = response;

          setLoader(false);

          if (data.error) {
            setMessage(data.error);
            setAlertColor("error");
            setOpen(true);
          } else {
            setAccountLogs(data.data);
            setVenderAccountDetail(data.account)
            setTotalRecords(data.totalrecords);
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
          setMessage("Something went wrong!");
          setAlertColor("error");
          setOpen(true);
        }
      }
    };

    fetchData();
  }, []);

  // DataTable fields configuration
  const fields = [
    {
      key: "status",
      label: "Status",
    },
    {
      key: "date",
      label: "Date/Time",
    },
  ];
  // Component JSX
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="user-dashboard-wrapper">
        <Helmet>
          <title>Account Logs</title>
        </Helmet>
        <Header />
        <div className="c-app c-default-layout user-dashboard-body vendorlist">
          <Usersidebar />
          <div className="c-wrapper">
            <div className="c-body">
              <main className="c-main">
                <AlertMessage
                  key="alertbtn"
                  open={open}
                  message={message}
                  severity={alertColor}
                  closeCall={handleClose}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12} className="space-top-col">
                    <Card
                      sx={{
                        height: "100%",
                        minHeight: "20rem",
                      }}
                    >
                      <CardHeader
                        title="Account Logs"
                        action={
                          <Grid
                            container
                            spacing={{ xs: 1, md: 1, lg: 1 }}
                            columns={{ xs: 4, sm: 8, md: 8 }}
                            className="button-align"
                          >
                            <Grid item>
                              <Button
                                type="button"
                                onClick={() => history.goBack()}
                                size="large"
                                variant="contained"
                                sx={{
                                  backgroundColor: "#002986!important",
                                  color: "white!important",
                                  textTransform: "none!important",
                                  fontWeight: "bold",
                                  fontFamily: "Barlow!important",
                                }}
                                startIcon={<ArrowBack />}
                              >
                                Back
                              </Button>
                            </Grid>
                          </Grid>
                        }
                      />
                      <CardContent
                      >
                        {!loader ? (
                          <>
                            <div
                              className="row"
                              style={{ marginLeft: '1px' }}
                            >
                              {venderAccountDetail?.league && (
                                <span style={{ paddingRight: "20px" }}>
                                  <strong>League: </strong>
                                  {venderAccountDetail?.league}
                                </span>
                              )}
                              {venderAccountDetail?.team && (
                                <span style={{ paddingRight: "20px" }}>
                                  <strong>Team: </strong>
                                  {venderAccountDetail?.team}
                                </span>
                              )}
                              {venderAccountDetail?.username && (
                                <span style={{ paddingRight: "20px" }}>
                                  <strong>Email: </strong>
                                  {venderAccountDetail?.username}
                                </span>
                              )}
                              <br />
                              {venderAccountDetail?.event && (
                                <span>
                                  {venderAccountDetail?.event?.status.includes(
                                    "Scan Completed: Events"
                                  ) ? (
                                    <>
                                      <strong>Status: </strong>Scan Completed:{" "}
                                      {accountLogs.length > 0
                                        ? "loading more events"
                                        : "loading events"}
                                    </>
                                  ) : (
                                    <>
                                      <strong>Status: </strong>
                                      {venderAccountDetail?.event?.status}
                                    </>
                                  )}
                                </span>
                              )}
                            </div>
                            <DataTable
                              fields={fields}
                              items={accountLogs}
                              itemsPerPage={totalRecords}
                              scopedSlots={{
                                status: (item) => <>{item.status}</>,
                                date: (item) => {
                                  const date = new Date(item.createdAt);
                                  const timeZone =
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone;
                                  return (
                                    <>
                                      {moment(date)
                                        .tz(timeZone)
                                        .format("MM/DD/YYYY hh:mm A")}
                                    </>
                                  );
                                },
                              }}
                            />
                          </>
                        ) : (
                          <ContainerLoader />
                        )}
                        {totalRecords > 0 && (
                          <Pagination
                            key="paginationnew"
                            searchPage={searchPage}
                            SearchDisable={SearchDisable}
                            totalRecords={totalRecords}
                            pageLimit={pageLimit}
                            onPageChanged={onPageChanged}
                          />
                        )}

                        {totalRecords > 0 && (
                          <div
                            style={{
                              textAlign: "right",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <label>Items per page :</label>
                            <FormControl
                              sx={{
                                m: 1,
                                minWidth: 50,
                              }}
                              size="small"
                            >
                              <Select
                                defaultValue={pageLimit}
                                value={pageLimit}
                                name="limit"
                                sx={{
                                  "& .MuiSelect-select": {
                                    padding: "4px 14px",
                                    fontFamily: "Barlow",
                                  },
                                }}
                                onChange={(e) => changepageLimit(e)}
                              >
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                                <MenuItem value="50">50</MenuItem>
                                <MenuItem value="100">100</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </main>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </LocalizationProvider>
  );
};

export default AccountLogs;
