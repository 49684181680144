import React from "react";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import Header from "components/header/header";
import Footer from "components/footer/footer";
import AlertMessage from "components/message/message";
import axios from "axios";
import Usersidebar from "components/user-sidebar/user-sidebar";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import PageLoader from "components/PageLoader/PageLoader";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dateTimeFormat from "utils/dateTimeFormat";
import Cookies from "js-cookie";
import { ContainerLoader } from "components/common/ContainerLoader";
import { ArrowBack } from "@mui/icons-material";

class EditVendorEvents extends React.Component {
  state = {
    loader: false,
    open: false,
    message: "",
    successError: "",
    vendorType: "",
    formdata: {
      eventName: "",
      eventDate: "",
      location: "",
      orderId: "",
      gameId: "",
    },
  };

  async componentDidMount() {
    if (!ValidatorForm.hasValidationRule("PasswordStrength")) {
      ValidatorForm.addValidationRule("PasswordStrength", (value) => {
        // var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        var re = /.{6,}/;
        // @".{6,}"
        return re.test(value);
      });
    }

    this.getEventDetails();
  }

  handleChange = (e) => {
    const { formdata } = this.state;
    formdata[e.target.name] = e.target.value;
    this.setState({ formdata });
  };

  componentWillUnmount() {
    ValidatorForm.addValidationRule("isWhitespace", (value) => {
      if (value.trim().length > 0) {
        return true;
      }
      return false;
    });
  }

  getEventDetails = async () => {
    this.setState({
      loader: true,
    });
    const data = {
      _id: this.props.match.params.id,
    };
    const getevent_api_url =
      process.env.REACT_APP_API_URL + "/api/account/getVbdeventDetails";
    await axios
      .post(getevent_api_url, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;

        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: "Something went wrong",
            successError: "error",
          });
        } else if (data.success) {
          var eventdate = "";
          if (data.data.date.includes("||")) {
            eventdate = data.data.date.replace("||", "T");
          } else {
            eventdate = dateTimeFormat(data.data.date);
          }

          const formdata = {
            eventName: data.data.event_name,
            location: data.data.location,
            orderId: data.data.order_id,
            gameId: data.data.game_id,
            eventDate: eventdate,
          };
          this.setState({
            loader: false,
            open: false,
            formdata,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleUpdateEvent = async () => {
    this.setState({
      loader: true,
    });

    const updateEventApi =
      process.env.REACT_APP_API_URL + "/api/account/updateVbdAccountEvents";
    const { formdata } = this.state;
    const data = {
      _id: this.props.match.params.id,
      game_id: formdata.gameId,
      event_date: dayjs(formdata.eventDate).format("YYYY-MM-DD||HH:mm:ss"),
      event_name: formdata.eventName,
      location: formdata.location,
      order_id: formdata.orderId === null ? "" : formdata.orderId,
    };
    axios
      .post(updateEventApi, data, {
        headers: {
          authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.error) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "error",
          });
        } else if (data.success) {
          this.setState({
            loader: false,
            open: true,
            message: data.message,
            successError: "success",
          });
          setTimeout(() => {
            this.props.history.goBack();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
          open: true,
          message: "Something went wrong please try again",
          successError: "error",
        });
      });
  };

  render() {
    const inputStyle = { textAlign: "left", paddingTop: "20px " };
    const { loader } = this.state;
    const labelStyle = {
      fontWeight: "500",
      fontFamily: "Barlow",
      color: "#002986",
    };
    console.log(this.state.formdata.eventDate);
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="user-dashboard-wrapper">
            <Helmet>
              <title>Edit Event</title>
            </Helmet>
            <Header />

            <div className="c-app c-default-layout user-dashboard-body">
              <Usersidebar />

              <div className="c-wrapper">
                <div className="c-body">
                  <main className="c-main">
                    <Grid container spacing={2}>
                      <Grid item={true} xs={12} className="space-top-col">
                        <div className="add-account-wrapper">
                          <Card
                            sx={{
                              width: "100%",
                              // padding: "20px",
                              height: "100%",
                            }}
                          // className="vendorlist"
                          >
                            {/* <div className="usage-tbl-header add-account-title"> */}
                            {/* <h3>Edit Event</h3> */}
                            <CardHeader
                              title="Events Tickets"
                              action={
                                <Grid
                                  container
                                  spacing={{ xs: 1, md: 1, lg: 1 }}
                                  columns={{ xs: 4, sm: 8, md: 8 }}
                                  className="button-align"
                                >
                                  <Grid item>
                                    <Button
                                      onClick={() => this.props.history.goBack()}
                                      type="button"
                                      size="large"
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#002986!important",
                                        color: "white!important",
                                        textTransform: "none!important",
                                        fontWeight: "bold",
                                        fontFamily: "Barlow!important",
                                      }}
                                      startIcon={<ArrowBack />}
                                    >
                                      Back
                                    </Button>
                                  </Grid>
                                </Grid>
                              }
                            />
                            <CardContent>
                              <AlertMessage
                                open={this.state.open}
                                message={this.state.message}
                                severity={this.state.successError}
                                closeCall={this.handleClose}
                              />
                              {/* </div> */}
                              {!loader ?
                                <>
                                  <Grid container spacing={3}>
                                    <Grid item={true} xs={12}>
                                      <ValidatorForm
                                        onSubmit={this.handleUpdateEvent}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item={true} xs={12} sm={6}>
                                            <div style={inputStyle}>
                                              <label style={labelStyle}>Name</label>

                                              <TextValidator
                                                variant="outlined"
                                                fullWidth
                                                name="eventName"
                                                value={
                                                  this.state.formdata.eventName
                                                }
                                                validators={["required"]}
                                                placeholder="Enter Name"
                                                onChange={this.handleChange}
                                                errorMessages={["Name is required"]}
                                              />
                                            </div>
                                          </Grid>

                                          <Grid item={true} xs={12} sm={6}>
                                            <div style={inputStyle}>
                                              <label style={labelStyle}>Date</label>

                                              <MobileDateTimePicker
                                                defualtValue={new Date()}
                                                value={
                                                  this.state.formdata.eventDate
                                                }
                                                onChange={(newValue) => {
                                                  console.log(newValue);
                                                  this.setState({
                                                    formdata: {
                                                      ...this.state.formdata,
                                                      eventDate: newValue.$d,
                                                    },
                                                  });
                                                }}
                                                defaultValue={new Date()}
                                                renderInput={(params) => (
                                                  <TextValidator
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="MM/DD/YYYY  HH:MM AM/PM"
                                                    validators={["required"]}
                                                    value={
                                                      this.state.formdata.eventDate
                                                    }
                                                    errorMessages={[
                                                      "Date is required",
                                                    ]}
                                                  />
                                                )}
                                              />
                                              {/* <DatePicker
                                          inputFormat="MM/DD/YYYY"
                                          value={this.state.formdata.eventDate}
                                          onChange={(newValue) => {
                                            this.setState({
                                              formdata: {
                                                ...this.state.formdata,
                                                eventDate: newValue,
                                              },
                                              isOpen: false,
                                              
                                            });
                                          }}
                                          renderInput={(params) => (
                                            <TextValidator
                                              {...params}
                                              fullWidth
                                              variant="outlined"
                                              placeholder="MM/DD/YYYY"
                                              validators={["required"]}
                                              value = {this.state.formdata.eventDate}
                                              errorMessages={[
                                                "Date is required",
                                              ]}
                                            />
                                          )}
                                        /> */}
                                            </div>
                                          </Grid>

                                          <Grid item={true} xs={12} sm={6}>
                                            <div style={inputStyle}>
                                              <label style={labelStyle}>
                                                Location
                                              </label>
                                              <TextValidator
                                                name="location"
                                                value={this.state.formdata.location}
                                                validators={["required"]}
                                                placeholder="Enter Location"
                                                onChange={this.handleChange}
                                                errorMessages={[
                                                  "Location is required",
                                                ]}
                                                fullWidth
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item={true} xs={12} sm={6}>
                                            <div style={inputStyle}>
                                              <label style={labelStyle}>
                                                Order Id
                                              </label>
                                              <TextValidator
                                                name="orderId"
                                                value={this.state.formdata.orderId}
                                                onChange={this.handleChange}
                                                fullWidth
                                                placeholder="Enter Order Id"
                                              />
                                            </div>
                                          </Grid>
                                          <Grid item={true} xs={12} sm={6}>
                                            <div style={inputStyle}>
                                              <label style={labelStyle}>
                                                Game Id
                                              </label>
                                              <TextValidator
                                                name="gameId"
                                                value={this.state.formdata.gameId}
                                                validators={["required"]}
                                                placeholder="Xx00xxX00X0xx0XX0xx"
                                                onChange={this.handleChange}
                                                errorMessages={[
                                                  " Game Id is required",
                                                ]}
                                                fullWidth
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item={true} xs={12} sm={4}>
                                            <Button
                                              variant="contained"
                                              fullWidth
                                              type="submit"
                                              size="large"
                                              className="theme-btn"
                                              sx={{
                                                margin: "17px auto",
                                                fontSize: "1rem",
                                                textTransform:
                                                  "capitalize!important",
                                                letterSpacing: "0.5px",
                                                "&:hover": {
                                                  textTransform:
                                                    "capitalize!important",
                                                },
                                              }}
                                            >
                                              Edit Event
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </ValidatorForm>
                                    </Grid>
                                  </Grid>
                                </>
                                :
                                <ContainerLoader />
                              }
                            </CardContent>
                          </Card>
                        </div>
                      </Grid>
                    </Grid>
                  </main>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </LocalizationProvider>
      </>
    );
  }
}
export default EditVendorEvents;
